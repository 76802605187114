type Props = {
  jobId?: string
  shiftId?: string
  jobAdId?: string
}

export const getRedirectionLink = (target: string, props: Props): string => {
  switch (target) {
    case 'staffers://managestaffers':
    case 'staffers://viewjobs': {
      const { jobId, shiftId } = props
      return `/jobs/${jobId}/${shiftId}`
    }
    case 'staffers://viewjobad': {
      const { jobAdId } = props
      return `/job-ads/${jobAdId}`
    }
    case 'staffers://myjobs': {
      return '/jobs?view=temporary'
    }
    case 'staffers://transferrequest': {
      return '/'
    }
    default: {
      return ''
    }
  }
}
