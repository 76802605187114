import { Badge, ButtonBase, Tooltip } from '@material-ui/core'
import { memo } from 'react'
// @ts-ignore
import { connectFirestore } from 'react-firestore-connect'
import { HashLoader } from 'react-spinners'

import type { BusinessType } from '../../../../../src/types/business'
import { getBusinessById } from '../../../staffers/api/getters/business.legacy'

import type { Firestore } from '../../../../../src/types/firebase'
import type { ManagerTypeWithId } from '../../../../../src/types/manager'
import { TooltipDelays } from '../../../constants/tooltips'
import styles from './BusinessIcon.module.css'

type Props = {
  badge?: string | number
  business?: BusinessType
  businessId: string
  color?: string
  isDashboardSelected: boolean
  manager: ManagerTypeWithId
  onClick: (businessId: string) => Promise<any>
  switchingTo: string
}

// Exception: memo component
// eslint-disable-next-line react/display-name
const BusinessIcon = memo((props: Props) => {
  const { badge, business, businessId, color, isDashboardSelected, manager, onClick, switchingTo } = props
  const isSelectedFn = (selectedBusinessId: string): boolean => selectedBusinessId === manager.businessId
  const isSelected = isSelectedFn(businessId) && !isDashboardSelected
  const isProcessing = businessId === switchingTo || !!(switchingTo && isSelectedFn(businessId))
  return (
    <Tooltip
      leaveDelay={TooltipDelays.WITHOUT_LINKS}
      title={business ? business.businessName : '...'}
      placement="right"
    >
      <ButtonBase onClick={() => onClick(businessId)} disabled={isProcessing}>
        <div
          className={
            (isSelected && styles.buttonOutlineSelected) ||
            (isProcessing && styles.buttonOutlineProcessing) ||
            styles.buttonOutline
          }
        >
          <Badge
            badgeContent={badge}
            color="error"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div className={styles.button} style={color ? { backgroundColor: color } : {}}>
              {business ? (business.businessName || '?')[0] : <HashLoader size={16} color="#363e48" />}
            </div>
          </Badge>
        </div>
      </ButtonBase>
    </Tooltip>
  )
})

export default connectFirestore(
  (db: Firestore, { businessId }: Props) => ({
    business: getBusinessById(db, businessId),
  }),
  BusinessIcon
)
