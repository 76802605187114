import type { ButtonProps, Theme } from '@material-ui/core'
import { Button as ButtonMUI, withStyles } from '@material-ui/core'
import type { Styles } from '@material-ui/core/styles/withStyles'

const styles: Styles<Theme, ButtonProps> = {
  root: {
    borderRadius: '8px',
    padding: '8px 32px',
    textTransform: 'none',
  },
}

// Custom button component for elastic search
const Button = withStyles(styles)(ButtonMUI)

export default Button
