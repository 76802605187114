import { Box, Button, Grid } from '@material-ui/core'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import type { ChangeEvent } from 'react'
import React, { Component } from 'react'
// @ts-ignore react-google-places-autocomplete has no exported @types/ module
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { loadGoogleMaps } from '../../helpers/googleMaps'
import { errorToast } from '../../helpers/toast'
import modalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import TextField from '../textInputField/TextInputField'
import styles from './modals.module.css'

type State = {
  city: string
  coordinates: firebase.firestore.GeoPoint | null
  country: string
  gmapsLoaded: boolean
  saveInProgress: boolean
  size: number
}

type Props = {
  closeModal: () => any
}

class CreateRegion extends Component<Props, State> {
  state = {
    city: '',
    coordinates: null,
    country: '',
    gmapsLoaded: false,
    saveInProgress: false,
    size: 50,
  }

  componentDidMount() {
    loadGoogleMaps(() => {
      this.setState({
        gmapsLoaded: true,
      })
    })
  }

  onLocationChange = async (place: { place_id: string }) => {
    const [details] = await geocodeByPlaceId(place.place_id)
    const getLoc = <T extends number | (() => number)>({ lat, lng }: { lat: T; lng: T }) =>
      typeof lat === 'function' && typeof lng === 'function' ? { lat: lat(), lng: lng() } : { lat, lng }
    const city =
      details.address_components.find((component: { types: string[] }) =>
        component.types.find((type: string) => type === 'locality')
      ) ||
      details.address_components.find((component: { types: string[] }) =>
        component.types.find((type: string) => type === 'sublocality')
      )
    const country = details.address_components.find((component: { types: string[] }) =>
      component.types.find((type: string) => type === 'country')
    )

    this.setState({
      city: city && city.long_name,
      coordinates: details && getLoc(details.geometry.location),
      country: country && country.long_name,
    })
  }

  onSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      size: Math.floor(+event.target.value),
    })
  }

  handleSave = async () => {
    const { city, coordinates, country, saveInProgress, size } = this.state
    const { closeModal } = this.props

    if (saveInProgress) {
      return
    }
    if (!city || !coordinates || !country) {
      errorToast('The address is in incorrect format')
      return
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(size) || !(size > 0 && size < 1000)) {
      errorToast('Size must be a number greater than 0 and less than 1000!')
      return
    }
    const { lat, lng } = coordinates
    try {
      const newRegionRef = firebase.firestore().collection('regions').doc()
      await newRegionRef.set({
        cityName: city.trim(),
        coordinates: new firebase.firestore.GeoPoint(lat, lng),
        countryName: country.trim(),
        enabled: false,
        isTestingEnabled: false,
        id: newRegionRef.id,
        size,
      })

      // Close the modal
      closeModal()

      toast.success('New region was created successfully.')
    } catch (error) {
      this.setState({ saveInProgress: false })
      errorToast('There was error while creating new region.')
      console.error((error as Error)?.message ?? error)
    }
  }

  render() {
    const { closeModal } = this.props
    const { saveInProgress, gmapsLoaded, size } = this.state

    if (saveInProgress) {
      return (
        <Box p={3} className={modalStyles.modalContent} textAlign="center">
          <BeatLoader />
        </Box>
      )
    }
    return (
      <React.Fragment>
        <ModalHeader close={closeModal}>Create new region</ModalHeader>
        <Box p={3} className={modalStyles.modalContent}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              Enter central city of the region:
              {gmapsLoaded && (
                <div>
                  <GooglePlacesAutocomplete onSelect={this.onLocationChange} />
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              Enter size of the region:
              <TextField
                className={styles.descriptionTextArea}
                type="number"
                onChange={this.onSizeChange}
                value={size}
              />
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={3}>
              <Button fullWidth variant="contained" onClick={closeModal}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button fullWidth color="primary" variant="contained" onClick={this.handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    )
  }
}

export default CreateRegion
