import { BeatLoader } from 'react-spinners'
import { business } from '../../../staffers/qman/business'
import styles from './StafferPopover.module.css'

type Props = {
  invitedByBusinessId: string
}

const StafferPopoverInvites = ({ invitedByBusinessId }: Props) => {
  const invitedByBusinessName = business.get('businessById', [invitedByBusinessId])?.businessName || null

  if (invitedByBusinessName === undefined) {
    return <BeatLoader />
  }

  return (
    <div className={styles.contentRow}>
      <div className={styles.contentColumn}>
        <span className={styles.greyText}>Invited by</span>
        <span className={styles.infoText}>{invitedByBusinessName || 'Deleted business'}</span>
      </div>
    </div>
  )
}

export default StafferPopoverInvites
