import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import type { BusinessType } from '../../../../../src/types/business'
import type { Firestore } from '../../../../../src/types/firebase'
import { getCurrentUserId, getServerTimestamp } from './util.legacy'

export const isBusinessProfileComplete = (profileData: BusinessType) =>
  profileData.address &&
  profileData.address.city &&
  profileData.address.country &&
  profileData.address.postalCode &&
  profileData.address.street &&
  profileData.businessName &&
  profileData.businessType &&
  profileData.orgNumber &&
  profileData.regionId

export const updateBusinessProfile = async (db: Firestore, businessId: string, profileData: Record<string, any>) =>
  db
    .collection('business')
    .doc(businessId)
    .set(
      {
        ...profileData,
        updatedBy: getCurrentUserId(),
      },
      { merge: true }
    )
// For business, phone is part of profile => use the same function

export const updateLastVisited = async (db: Firestore, businessId: string) =>
  db.collection('business').doc(businessId).update({
    lastVisited: getServerTimestamp(),
  })

export const markActivityAsSeen = async (db: Firestore, businessId: string, activityId: string) =>
  db
    .collection('business')
    .doc(businessId)
    .collection('activities')
    .doc(activityId)
    .update({
      unseenBy: firebase.firestore.FieldValue.arrayRemove(getCurrentUserId()),
    })

export const markActivityAsFinished = async (
  db: Firestore,
  businessId: string,
  activityId: string,
  // Only disable activity if explicitly stated (all managers saw, action is one-time)
  disable?: boolean
) =>
  db
    .collection('business')
    .doc(businessId)
    .collection('activities')
    .doc(activityId)
    .update({
      ...(disable ? { actionToBeTaken: false } : {}),
      showTo: firebase.firestore.FieldValue.arrayRemove(getCurrentUserId()),
      unseenBy: firebase.firestore.FieldValue.arrayRemove(getCurrentUserId()),
    })

export const deleteActivity = async (businessId: string, activityId: string) =>
  firebase.firestore().collection('business').doc(businessId).collection('activities').doc(activityId).delete()

export const makeInviteOnlyPublic = async (jobId: string) =>
  firebase.firestore().collection('jobs').doc(jobId).update({
    limitedToInvited: false,
    limitedToInvitedUntil: firebase.firestore.FieldValue.delete(),
  })
