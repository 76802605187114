import type { FieldValue } from 'firebase/firestore'
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  getDoc,
  getDocs,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import type { GroupType } from '../../../../../src/types/groups'
import type { PoolType } from '../../../../../src/types/pools'
import { errorToast } from '../../../helpers/toast'
import { createEmptyBusinessProfile } from '../auth/businessProfile'
import { getBusinessById } from '../getters/business'
import { getGroupById } from '../getters/groups'
import { getGroupManagers, getManagerById, getManagerInviteByInviteId } from '../getters/managers'
import { getCollectionRef, getDocumentRef } from '../wrappers'
import { firestoreHttpsCallable } from './https/util'

type SelectBusinessType = {
  businessId: string
  businessName: string
  isDisabled?: boolean
}

type GroupData = {
  name: string
  businessesIds: Array<string>
  managerIds: Array<string>
  oldBusinessIds: Array<string>
}

export const updatePositionStaffers = async (data: {
  name: string
  assigned: Array<Record<string, any>>
  group: GroupType
  includeSkillLevels: boolean
  skillLevels: { [stafferId: string]: string }
  newlyAdded: string[]
  removed: string[]
  sendNotification: boolean
  updateOnly?: string[] // override - instead of updating group updates only select businesses
}) => firestoreHttpsCallable('updatePositionStaffers', data)

export const removeBusinessFromGroupManager = async (managerId: string, businessId: string) => {
  const managerRef = getManagerById(managerId)

  await updateDoc(managerRef, {
    groupBusinesses: arrayRemove(businessId),
  })
}

export const removeBusinessFromGroupManagerInvite = async (inviteId: string, businessId: string) => {
  const managerRef = getManagerInviteByInviteId(inviteId)

  await updateDoc(managerRef, {
    groupBusinesses: arrayRemove(businessId),
  })
}

export const removeGroupManagerInvite = (managerId: string) => deleteDoc(getDocumentRef('managersInvites', managerId))

export const addGroupManager = async (managerId: string, groupId: string, businessesIds: Array<string>) =>
  updateDoc(getDocumentRef('managers', managerId), {
    groupId,
    groupBusinesses: businessesIds,
  })

export const removeGroupManager = async (managerId: string) =>
  updateDoc(getDocumentRef('managers', managerId), {
    groupId: deleteField(),
    groupBusinesses: deleteField(),
  })

export const updateGroupManagers = async (
  groupId: string,
  managerIds: Array<string>,
  businessesIds: Array<string>,
  addToExistingManagers = false
) => {
  const managers = await getDocs(getGroupManagers(groupId))
  await Promise.all(
    // remove old managers
    managers.docs.filter(({ id }) => !managerIds.includes(id)).map((doc) => removeGroupManager(doc.id))
  )
  const managersArray = addToExistingManagers
    ? managerIds
    : managerIds // do not update already added managers
        .filter((managerId) => managers.docs.every(({ id }) => id !== managerId))
  await Promise.all(
    // add new managers
    managersArray.map((id) => addGroupManager(id, groupId, businessesIds))
  )
}

export const createGroup = async (data: GroupData) => {
  const { name, businessesIds, managerIds } = data

  // determine group region based on the first business
  const firstBusinessDoc = businessesIds && businessesIds[0] && (await getDoc(getBusinessById(businessesIds[0])))
  const firstBusinessData = firstBusinessDoc && firstBusinessDoc.exists() && firstBusinessDoc.data()
  const country = (firstBusinessData && firstBusinessData.address && firstBusinessData.address.country) || 'Norway'

  const newGroup = await addDoc(getCollectionRef('groups'), {
    name: name.trim(),
    businesses: businessesIds,
    country,
  })

  const groupId = newGroup.id

  // add managers
  await Promise.all(managerIds.map((id) => addGroupManager(id, groupId, businessesIds)))

  // update businesses
  await Promise.all(
    businessesIds.map((businessId) =>
      updateDoc(getBusinessById(businessId), {
        groupId,
      })
    )
  )

  await firestoreHttpsCallable('addGroupStaffers', {
    groupId,
    businessIds: businessesIds,
  })
}

export const updateGroup = async (
  groupId: string,
  data: GroupData & { addToExistingManagers: boolean },
  group: GroupType,
  selectedBusinesses: SelectBusinessType[],
  email: string
) => {
  const { name, businessesIds, managerIds, oldBusinessIds, addToExistingManagers } = data
  // businessIds - all businesses which are *CURRENTLY* in the group
  // oldBusinessIds - all businesses which were in the group *BEFORE* the edits
  // Therefore:
  // newBusinessIds = businessIds - oldBusinessIds (only leave newly added businesses)
  // removedBusinessIds = oldBusinessIds - businessIds (only leave businesses which are no longer
  // in businessIds - were removed from them)
  const newBusinessIds = businessesIds.filter((businessId) => !oldBusinessIds.includes(businessId))
  const removedBusinessIds = oldBusinessIds.filter((businessId) => !businessesIds.includes(businessId))

  await Promise.all([
    // update the group
    await updateDoc(getDocumentRef('groups', groupId), {
      name: name?.trim(),
      businesses: businessesIds,
    }),
    // update  managers
    await updateGroupManagers(groupId, managerIds, businessesIds, addToExistingManagers),
    // remove old businesses
    ...removedBusinessIds.map((businessId) =>
      updateDoc(getBusinessById(businessId), {
        groupId: deleteField(),
      })
    ),
    // add new businesses - could be left on `newBusinessIds.map..`, but we should use all
    // businessIds to self regenerate the db, as we had bug which caused them to lose this flag
    ...businessesIds.map((businessId) =>
      updateDoc(getBusinessById(businessId), {
        groupId,
      })
    ),
  ])
  // Check whether we actually need to update the pools (if there are any new/removed
  // businesses from the group)
  if (group.type === 'interconnected' && (newBusinessIds.length || removedBusinessIds.length)) {
    // Do this on background as this is pretty heavy operation, causing the entire function to take
    // almost a minute
    Promise.all(
      businessesIds.map(async (businessId: string) => {
        const poolRef = getDocumentRef('pools', businessId)
        const poolDoc = await getDoc(poolRef)
        if (poolDoc.exists()) {
          await updateDoc(poolRef, {
            connectedBusinesses: businessesIds.filter((busId) => businessId !== busId),
          })
        } else {
          // If pool doesn't exist yet, create pool for the business
          const business = selectedBusinesses.find((bus: SelectBusinessType) => bus.businessId === businessId)
          const poolData: Omit<PoolType, 'createdAt'> & { createdAt: FieldValue } = {
            businessId,
            businessName: business ? business.businessName : group.name,
            createdAt: serverTimestamp(),
            createdBy: 'superadmin',
            // Filter out business ID for which we create the pool
            connectedBusinesses: businessesIds.filter((busId) => businessId !== busId),
            employees: [],
            previousEmployees: [],
            name: group.name.trim(),
            staffers: [],
          }
          await setDoc(poolRef, poolData, { merge: true })
        }
      })
    ).catch(() =>
      errorToast(
        'Updating group partly failed (Creating/Updating pools). Contact development team to avoid database inconsistencies.'
      )
    )
  }

  if (newBusinessIds.length > 0) {
    // Add new groupStaffers or new businessId entries
    await firestoreHttpsCallable('addGroupStaffers', { groupId, businessIds: newBusinessIds }).catch(() =>
      errorToast(
        'Updating group partly failed (Adding group staffers). Contact development team to avoid database inconsistencies.'
      )
    )
  }
  if (email) {
    await firestoreHttpsCallable('inviteManagerToGroup', { groupId, email }).catch(() =>
      errorToast(
        'Updating group partly failed (Invite manager to group). Contact development team to avoid database inconsistencies.'
      )
    )
  }
}

// Only use for interconnected groups
export const addNewEmptyBusinessToInterconnectedGroup = async (
  adminId: string,
  group: GroupType,
  businessName: string,
  businessIds: Array<string>,
  country: string,
  grantAccess?: boolean
) => {
  const { id: groupId, name: groupName } = group
  const businessId = await createEmptyBusinessProfile(adminId, businessName, groupId, country, grantAccess)
  await Promise.all(
    businessIds.map(async (connectedBusinessId: string) => {
      const connectedPoolRef = getDocumentRef('pools', connectedBusinessId)
      const connectedPoolDoc = await getDoc(connectedPoolRef)
      if (connectedPoolDoc.exists()) {
        const connectedPool = connectedPoolDoc.data() || { connectedBusinesses: [] } // Just in case
        await updateDoc(connectedPoolRef, {
          connectedBusinesses: [
            ...connectedPool.connectedBusinesses, // maintain current connected businesses
            businessId, // add new business into connected pools
          ],
        })
      }
    })
  )

  // Create new pool
  const poolRef = getDocumentRef('pools', businessId)
  const poolData: Omit<PoolType, 'createdAt'> & { createdAt: FieldValue } = {
    businessId,
    businessName,
    createdAt: serverTimestamp(),
    createdBy: 'superadmin',
    // Filter out business ID for which we create the pool
    connectedBusinesses: businessIds,
    employees: [],
    previousEmployees: [],
    name: groupName,
    staffers: [],
  }

  // update the group
  await updateDoc(getDocumentRef('groups', groupId), {
    businesses: arrayUnion(businessId),
  })

  await setDoc(poolRef, poolData, { merge: true })
}

export const deleteGroup = async (groupId: string) => {
  // remove managers
  await updateGroupManagers(groupId, [], [])

  const groupRef = getGroupById(groupId)
  const group = await getDoc(groupRef)
  if (!group.exists) {
    throw Error(`Group with id ${groupId} does not exist`)
  }
  const groupData: GroupType = group.data() as GroupType

  // update businesses
  await Promise.all(
    groupData.businesses.map((businessId) =>
      updateDoc(getBusinessById(businessId), {
        groupId: deleteField(),
      })
    )
  )

  // delete groups/$groupId/data subcollection documents
  const groupDataCollection = await getDocs(collection(getFirestore(), 'groups', groupId, 'data'))
  await Promise.all(
    groupDataCollection.docs.map(async (groupDataDoc) => {
      await deleteDoc(groupDataDoc.ref)
    })
  )

  // delete groups/$groupId/staffers subcollection documents
  const groupStaffersCollection = await getDocs(collection(getFirestore(), 'groups', groupId, 'staffers'))
  await Promise.all(
    groupStaffersCollection.docs.map(async (groupStaffer) => {
      await deleteDoc(groupStaffer.ref)
    })
  )

  // delete the group
  await deleteDoc(groupRef)
}
