import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { Component } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { BusinessType } from '../../../../src/types/business'
import type { PoolType } from '../../../../src/types/pools'
import { errorToast } from '../../helpers/toast'
import { logEvent } from '../../staffers/api/firestore/https/bigQuery'
import { StaffersJobsAPI } from '../../staffers/api/firestore/https/jobs'
import { accessBlock, accessGrant } from '../../staffers/api/firestore/https/onboarding'
import { stafferAccountDeny } from '../../staffers/api/firestore/https/stafferAccount'
import { firestoreHttpsCallable } from '../../staffers/api/firestore/https/util'
import { demoteStaffer, promoteStaffer } from '../../staffers/api/firestore/superadmin.legacy'
import { getUnpublishedDetailById } from '../../staffers/api/getters/jobs.legacy'
import Button from '../button/Button'
import Modal from '../mui/Modal'
import NotificationSender from '../notificationBuilder/NotificationSender'
import ConfirmationModal from './ConfirmationModal'
import CreateEditGroup from './CreateEditGroup/CreateEditGroup'
import CreateGroupTypeSelector from './CreateEditGroup/CreateGroupTypeSelector'
import CreateEditPool from './CreateEditPool/CreateEditPool'
import CreateRegion from './CreateRegion'
import EditCompanyProfile from './EditCompanyProfile'
import EditStafferPermissions from './EditStafferPermissions'
import InviteBusiness from './InviteBusiness'
import InviteStaffer from './InviteStaffer'
import EditJob from './JobVariantSelector'
import SendNotifications from './SendNotifications'
import ShareJob from './ShareJob'
import StafferPaymentDetails from './StafferPaymentDetails'
import styles from './modals.module.css'

export const ModalTypes = {
  ARCHIVE_BUSINESS: 'archiveBusiness',
  BLOCK_BUSINESS: 'blockCompany',
  BLOCK_STAFFER: 'blockStaffer',
  CREATE_GROUP: 'createGroup',
  CREATE_JOB: 'createJob',
  CREATE_INTERNAL_JOB: 'createInternalJob',
  CREATE_EXTERNAL_JOB: 'createExternalJob',
  CREATE_REGION: 'createRegion',
  DEMOTE_STAFFER: 'demoteStaffer',
  DENY_ACCESS_COMPANY: 'denyCompany',
  DENY_ACCESS_STAFFER: 'denyStaffer',
  EDIT_BUSINESS: 'editCompanyProfile',
  EDIT_JOB: 'editJob',
  EDIT_JOB_AD: 'editJobAd',
  EDIT_STAFFER_PERMISSIONS: 'editStafferPermissions',
  EDIT_UNPUBLISHED_JOB: 'editUnpubJob',
  EDIT_UNPUBLISHED_JOB_AD: 'editUnpubJobAd',
  EDIT_GROUP: 'editGroup',
  GRANT_ACCESS_COMPANY: 'grantCompany',
  GRANT_ACCESS_STAFFER: 'grantStaffer',
  INVITE_STAFFER: 'inviteStaffers',
  INVITE_BUSINESS: 'inviteBusiness',
  NOTIFY_BUSINESS: 'notifyBusiness',
  NOTIFY_JOB: 'newJobNotif',
  NOTIFY_JOB_AD: 'newJobAdNotif',
  PAYMENT_DETAILS: 'paymentDetails',
  PROMOTE_STAFFER: 'promoteStaffer',
  PUBLISH_JOB: 'publishJob',
  PUBLISH_JOB_AD: 'publishJobAd',
  REMOVE_JOB: 'removeJob',
  REMOVE_JOB_AD: 'removeJobAd',
  SHARE_JOB_AD: 'shareJobAd',
  REMOVE_STAFFER: 'removeStaffer',
  SEND_NOTIFICATION: 'sendNotification',
  UNARCHIVE_BUSINESS: 'unarchiveBusiness',
  CREATE_EDIT_POOL: 'createEditPool',
  CS_PROFILE: 'csProfile',
}

type Props = {
  buttonType: string
  currentLocation?: string
  customCallback?: () => any
  title?: string
  getCustomTitle?: () => Promise<string>
  modalType?: string
  pool?: PoolType
  business?: BusinessType
  selectedCountry: string
} & RouteComponentProps

type State = {
  title: string
  modalIndex: number
  showModal: boolean
  isLoading: boolean
  buttonLoading: boolean
}

class MenuModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { title } = props
    this.state = {
      modalIndex: 0,
      showModal: false,
      isLoading: false,
      buttonLoading: false,
      title: title || '',
    }
  }

  async componentDidMount() {
    const { getCustomTitle } = this.props
    if (getCustomTitle instanceof Function) {
      getCustomTitle().then((title) => {
        this.setState({ title })
      })
    }
  }

  handleButtonClick = async () => {
    const { customCallback, getCustomTitle } = this.props
    if (customCallback) {
      this.setState({ buttonLoading: true })
      await customCallback()
      this.setState({ buttonLoading: false })
      if (getCustomTitle) {
        const title = await getCustomTitle()
        this.setState({ title })
      }
    } else {
      this.handleOpenModal()
    }
  }

  getCurrentModal = () => {
    const { modalIndex } = this.state
    const { modalType, business } = this.props

    switch (modalType) {
      case ModalTypes.NOTIFY_BUSINESS: {
        const { currentLocation } = this.props
        const businessId = currentLocation && currentLocation.replace('/companies/', '')
        // Just add it into set, so in the future we can send notifications in bulk; also check
        // whether we have businessId correctly parsed, so flow don't scream.
        const selectedUsers: Set<string> = businessId ? new Set([businessId]) : new Set()
        return {
          modal: <NotificationSender closeModal={this.closeModal} selectedUsers={selectedUsers} isBusiness />,
          style: styles.editCompanyModal,
        }
      }
      case ModalTypes.CREATE_EDIT_POOL: {
        const { currentLocation, pool } = this.props
        const businessId =
          currentLocation && currentLocation.replace('/companies/', '')
            ? currentLocation.replace('/companies/', '')
            : business && business.businessId
        return {
          modal: <CreateEditPool onClose={this.closeModal} businessId={businessId || ''} pool={pool} />,
          style: styles.MuiModal,
        }
      }
      case ModalTypes.EDIT_BUSINESS: {
        const { currentLocation } = this.props
        const businessId =
          currentLocation && currentLocation.match('/companies/')
            ? currentLocation.replace('/companies/', '')
            : business && business.id
        return {
          modal: <EditCompanyProfile closeModal={this.closeModal} businessId={businessId} isSuperAdmin />,
          style: undefined,
        }
      }
      case ModalTypes.CREATE_GROUP: {
        return {
          modal: <CreateGroupTypeSelector onClose={this.closeModal} />,
          style: styles.editJobModal,
        }
      }
      case ModalTypes.EDIT_GROUP: {
        const { currentLocation } = this.props
        const groupId = currentLocation && currentLocation.replace('/groups/', '')
        return {
          modal: <CreateEditGroup groupId={groupId} onClose={this.closeModal} />,
          style: styles.editJobModal,
        }
      }
      case ModalTypes.CREATE_INTERNAL_JOB: {
        const { currentLocation } = this.props
        const businessId =
          currentLocation && currentLocation.match('/companies/')
            ? currentLocation.replace('/companies/', '')
            : business && business.id
        return {
          modal: (
            <EditJob
              business={business}
              businessId={businessId || null}
              jobId={null}
              closeModal={this.closeModal}
              onSave={this.handleCreateOrEditJob}
              isAdmin
              preSelectedPoolAccess="PRIVATE"
            />
          ),
          style: styles.editJobModal,
        }
      }
      case ModalTypes.CREATE_EXTERNAL_JOB: {
        const { currentLocation } = this.props
        const businessId =
          currentLocation && currentLocation.match('/companies/')
            ? currentLocation.replace('/companies/', '')
            : business && business.id
        return {
          modal: (
            <EditJob
              business={business}
              businessId={businessId || null}
              jobId={null}
              closeModal={this.closeModal}
              onSave={this.handleCreateOrEditJob}
              isAdmin
              preSelectedPoolAccess="PUBLIC"
            />
          ),
          style: styles.editJobModal,
        }
      }
      case ModalTypes.EDIT_JOB: {
        const { currentLocation } = this.props
        const jobId = currentLocation && currentLocation.split('/')[2]
        return {
          modal: (
            <EditJob
              business={business}
              jobId={jobId}
              closeModal={this.closeModal}
              onSave={this.handleCreateOrEditJob}
              isAdmin
            />
          ),
          style: styles.editJobModal,
        }
      }
      case ModalTypes.EDIT_JOB_AD: {
        const { currentLocation } = this.props
        const jobAdId = currentLocation && currentLocation.replace('/job-ads/', '')
        return {
          modal: (
            <EditJob
              business={business}
              jobAdId={jobAdId}
              closeModal={this.closeModal}
              onSave={this.handleCreateOrEditJob}
              isAdmin
            />
          ),
          style: styles.editJobModal,
        }
      }
      case ModalTypes.EDIT_UNPUBLISHED_JOB: {
        const { currentLocation } = this.props
        const jobId = currentLocation && currentLocation.replace('/jobs-unpublished/', '')
        return {
          modal: (
            <EditJob
              business={business}
              jobId={jobId}
              closeModal={this.closeModal}
              onSave={this.handleCreateOrEditJob}
              unpublished
              isAdmin
            />
          ),
          style: styles.editJobModal,
        }
      }
      case ModalTypes.EDIT_UNPUBLISHED_JOB_AD: {
        const { currentLocation } = this.props
        const jobAdId = currentLocation && currentLocation.replace('/unpublished-ads/', '')
        return {
          modal: (
            <EditJob
              business={business}
              jobAdId={jobAdId}
              closeModal={this.handleCreateOrEditJob}
              defaultCloseModal={this.closeModal}
              unpublished
              isAdmin
            />
          ),
          style: styles.editJobModal,
        }
      }
      case ModalTypes.REMOVE_JOB: {
        const { currentLocation } = this.props
        const jobId =
          (currentLocation && currentLocation.startsWith('/jobs/') && currentLocation.replace('/jobs/', '')) ||
          (currentLocation &&
            currentLocation.startsWith('/jobs-unpublished/') &&
            currentLocation.replace('/jobs-unpublished/', '')) ||
          undefined
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to delete this job?"
              isLoading={this.state.isLoading}
              onClick={this.handleRemoveJob(jobId)}
            />
          ),
        }
      }
      case ModalTypes.REMOVE_JOB_AD: {
        const { currentLocation } = this.props
        const jobAdId =
          (currentLocation && currentLocation.startsWith('/job-ads/') && currentLocation.replace('/job-ads/', '')) ||
          (currentLocation &&
            currentLocation.startsWith('/unpublished-ads/') &&
            currentLocation.replace('/unpublished-ads/', '')) ||
          undefined
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to delete this job ad?"
              isLoading={this.state.isLoading}
              onClick={this.handleRemoveJobAd(jobAdId)}
            />
          ),
        }
      }
      case ModalTypes.SHARE_JOB_AD: {
        const { currentLocation } = this.props
        const jobAdId = currentLocation ? currentLocation.replace('/job-ads/', '') : ''
        return {
          modal: <ShareJob closeModal={this.closeModal} jobAdId={jobAdId} />,
          style: styles.editJobModal,
        }
      }
      case ModalTypes.EDIT_STAFFER_PERMISSIONS: {
        const { currentLocation } = this.props
        const stafferId = currentLocation && currentLocation.replace('/staffers/', '')
        return {
          modal: <EditStafferPermissions stafferId={stafferId} closeModal={this.closeModal} />,
          style: styles.editJobModal,
        }
      }
      case ModalTypes.GRANT_ACCESS_COMPANY: {
        const { currentLocation } = this.props
        const businessId = currentLocation && currentLocation.replace('/company-requests/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to grant access to this company?"
              isLoading={this.state.isLoading}
              onClick={this.handleGrantCompany(businessId)}
            />
          ),
        }
      }
      case ModalTypes.GRANT_ACCESS_STAFFER: {
        const { currentLocation } = this.props
        const stafferId = currentLocation && currentLocation.replace('/staffer-requests/', '')
        if (modalIndex === 1) {
          return {
            modal: (
              <EditStafferPermissions
                stafferId={stafferId}
                closeModal={this.closeModal}
                onEditPermissionSuccess={this.handleGrantStaffer(stafferId)}
                isLoading={this.state.isLoading}
              />
            ),
          }
        }
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to grant access to this staffer?"
              isLoading={this.state.isLoading}
              onClick={this.handleNextModal}
            />
          ),
        }
      }
      case ModalTypes.DENY_ACCESS_COMPANY: {
        const { currentLocation } = this.props
        const businessId = currentLocation && currentLocation.replace('/company-requests/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to deny access to this business? The manager account will be unlinked from the bussiness. (This action cannot be undone. This action may need a few minutes to take effect.)"
              isLoading={this.state.isLoading}
              onClick={this.handleDenyBusiness(businessId)}
            />
          ),
        }
      }
      case ModalTypes.DENY_ACCESS_STAFFER: {
        const { currentLocation } = this.props
        const stafferId = currentLocation && currentLocation.replace('/staffer-requests/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to deny access to this staffer? (This action cannot be undone. This action may need a few minutes to take effect.)"
              isLoading={this.state.isLoading}
              onClick={this.handleDenyStaffer(stafferId)}
            />
          ),
        }
      }
      case ModalTypes.BLOCK_BUSINESS: {
        const { currentLocation } = this.props
        const businessId = currentLocation && currentLocation.replace('/companies/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to block / unblock this business?"
              isLoading={this.state.isLoading}
              onClick={this.handleBlockCompany(businessId)}
            />
          ),
        }
      }
      case ModalTypes.ARCHIVE_BUSINESS: {
        const { currentLocation } = this.props
        const businessId = currentLocation && currentLocation.replace('/companies/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to archive this business?"
              isLoading={this.state.isLoading}
              onClick={this.handleArchiveBusiness(businessId)}
            />
          ),
        }
      }
      case ModalTypes.UNARCHIVE_BUSINESS: {
        const { currentLocation } = this.props
        const businessId = currentLocation && currentLocation.replace('/companies/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to unarchive this business and all its managers?"
              isLoading={this.state.isLoading}
              onClick={this.handleUnarchiveBusiness(businessId)}
            />
          ),
        }
      }
      case ModalTypes.PROMOTE_STAFFER: {
        const { currentLocation } = this.props
        const stafferId = currentLocation && currentLocation.replace('/staffers/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to promote this staffer?"
              isLoading={this.state.isLoading}
              onClick={this.handlePromoteStaffer(stafferId)}
            />
          ),
        }
      }
      case ModalTypes.DEMOTE_STAFFER: {
        const { currentLocation } = this.props
        const stafferId = currentLocation && currentLocation.replace('/staffers/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to demote this staffer?"
              isLoading={this.state.isLoading}
              onClick={this.handleDemoteStaffer(stafferId)}
            />
          ),
        }
      }
      case ModalTypes.BLOCK_STAFFER: {
        const { currentLocation } = this.props
        const stafferId = currentLocation && currentLocation.replace('/staffers/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to block / unblock this staffer?"
              isLoading={this.state.isLoading}
              onClick={this.handleBlockStaffer(stafferId)}
            />
          ),
        }
      }
      case ModalTypes.REMOVE_STAFFER: {
        const { currentLocation } = this.props
        const stafferId = currentLocation && currentLocation.replace('/staffers/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to delete this staffer account and all associated data? (CANNOT BE REVERTED)"
              isLoading={this.state.isLoading}
              onClick={this.handleRemoveStaffer(stafferId)}
            />
          ),
        }
      }
      case ModalTypes.INVITE_STAFFER: {
        return {
          modal: <InviteStaffer closeModal={this.closeModal} />,
        }
      }
      case ModalTypes.INVITE_BUSINESS: {
        const { selectedCountry } = this.props
        return {
          modal: <InviteBusiness currentCountry={selectedCountry} onClose={this.closeModal} />,
        }
      }
      case ModalTypes.PAYMENT_DETAILS: {
        const { currentLocation, selectedCountry } = this.props
        const stafferId = currentLocation && currentLocation.replace('/staffers/', '')
        return {
          modal: (
            <StafferPaymentDetails
              currentCountry={selectedCountry}
              stafferId={stafferId}
              onRequestClose={this.closeModal}
            />
          ),
        }
      }
      case ModalTypes.NOTIFY_JOB: {
        const { currentLocation } = this.props
        const jobId = currentLocation && currentLocation.replace('/jobs/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to send the job notification to staffers again? This may cause duplicates."
              isLoading={this.state.isLoading}
              onClick={this.sendNewJobNotification(jobId)}
            />
          ),
        }
      }
      case ModalTypes.NOTIFY_JOB_AD: {
        const { currentLocation } = this.props
        const jobAdId = currentLocation && currentLocation.replace('/job-ads/', '')
        console.warn('jobAdID', jobAdId)
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to send the job notification to staffers again? This may cause duplicates."
              isLoading={this.state.isLoading}
              onClick={this.sendNewJobAdNotification(jobAdId)}
            />
          ),
        }
      }
      case ModalTypes.SEND_NOTIFICATION: {
        const { currentLocation } = this.props
        const stafferId = currentLocation && currentLocation.replace('/staffer-requests/', '')
        return {
          modal: <SendNotifications stafferId={stafferId} closeModal={this.closeModal} />,
        }
      }
      case ModalTypes.CREATE_REGION: {
        return {
          modal: <CreateRegion closeModal={this.closeModal} />,
        }
      }
      case ModalTypes.PUBLISH_JOB: {
        const { currentLocation } = this.props
        const jobId = currentLocation && currentLocation.replace('/jobs-unpublished/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to publish this job for staffers?"
              isLoading={this.state.isLoading}
              onClick={this.publishJob(jobId)}
            />
          ),
        }
      }
      case ModalTypes.PUBLISH_JOB_AD: {
        const { currentLocation } = this.props
        const jobAdId = currentLocation && currentLocation.replace('/unpublished-ads/', '')
        return {
          modal: (
            <ConfirmationModal
              closeModal={this.closeModal}
              confirmationTitle="Are you sure you want to publish this job ad for staffers?"
              isLoading={this.state.isLoading}
              onClick={this.publishJobAd(jobAdId)}
            />
          ),
        }
      }
      default: {
        return {}
      }
    }
  }

  publishJob = (jobId?: string) => async () => {
    try {
      this.setState({ isLoading: true })
      if (jobId) {
        const jobDoc = await getUnpublishedDetailById(firebase.firestore(), jobId).get()
        const job = jobDoc.data()
        const response = await StaffersJobsAPI.jobPublish(jobId)
        this.closeModal()
        const newJobId = response && response.data && response.data.jobId
        await this.props.history.push({
          pathname: newJobId ? `/jobs/${newJobId}` : '/jobs/',
          state: {
            jobRemoved: true,
            businessId: job && job.businessId,
          },
        })
      }
    } catch (e) {
      console.error(e)
      errorToast('There was a problem with publishing this job.')
      this.closeModal()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  publishJobAd = (jobAdId?: string) => async () => {
    try {
      this.setState({ isLoading: true })
      if (jobAdId) {
        const response = await StaffersJobsAPI.jobAdPublish(jobAdId)
        this.closeModal()
        const newJobAdId = response && response.data && response.data.jobAdId
        await this.props.history.push({
          pathname: newJobAdId ? `/job-ads/${newJobAdId}` : '/jobs/',
          state: {
            jobRemoved: true,
          },
        })
      }
    } catch (e) {
      console.error(e)
      errorToast('There was a problem with publishing this job ad.')
      this.closeModal()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleOpenModal = () => {
    this.setState({ showModal: true, modalIndex: 0 })
  }

  handleNextModal = () => {
    const { modalIndex } = this.state
    this.setState({ modalIndex: modalIndex + 1 })
  }

  closeModal = () => {
    this.setState({ showModal: false, modalIndex: 0 })
  }

  handleGrantCompany = (businessId?: string) => async () => {
    if (businessId) {
      try {
        this.setState({ isLoading: true })
        await accessGrant('business', businessId, 'web')
        toast.success('Access was granted successfully.')
        await this.props.history.replace({
          pathname: `/companies/${businessId}`,
        })
      } catch (error) {
        errorToast((error as Error)?.message ?? error)
      } finally {
        this.closeModal()
        this.setState({ isLoading: false })
      }
    }
  }

  handleGrantStaffer = (stafferId?: string) => async () => {
    if (stafferId) {
      try {
        this.setState({ isLoading: true })
        await accessGrant('staffer', stafferId)
        toast.success('Access was granted successfully.')
        await this.props.history.replace({
          pathname: `/staffers/${stafferId}`,
        })
        const { uid } = firebase.auth().currentUser || {}
        await logEvent({
          eventName: 'staffer_access_granted',
          eventData: {
            stafferId,
            grantedBy: uid,
            grantedDate: new Date().getTime() / 1000,
          },
        })
      } catch (error) {
        errorToast((error as Error)?.message ?? error)
      } finally {
        this.closeModal()
        this.setState({ isLoading: false })
      }
    }
  }

  handleDenyBusiness = (businessId?: string) => async () => {
    if (businessId) {
      try {
        this.setState({ isLoading: true })
        await firestoreHttpsCallable('denyAccessBusiness', {
          businessId,
        })
        toast.success('Acces was denied successfully.')
        await this.props.history.replace({
          pathname: '/company-requests',
        })
      } catch (error) {
        console.error(error)
        errorToast((error as Error)?.message ?? error)
      } finally {
        this.closeModal()
        this.setState({ isLoading: false })
      }
    }
  }

  handleDenyStaffer = (stafferId?: string) => async () => {
    if (stafferId) {
      try {
        this.setState({ isLoading: true })
        await stafferAccountDeny(stafferId)
        toast.success('Access was denied successfully.')
        await this.props.history.replace({
          pathname: '/staffer-requests',
        })
      } catch (error) {
        errorToast((error as Error)?.message ?? error)
      } finally {
        this.closeModal()
        this.setState({ isLoading: false })
      }
    }
  }

  handleBlockCompany = (businessId?: string) => async () => {
    if (businessId) {
      try {
        this.setState({ isLoading: true })
        await accessBlock('business', businessId)
        toast.success('Access was blocked/unblocked successfully.')
      } catch (error) {
        errorToast((error as Error)?.message ?? error)
      } finally {
        this.closeModal()
        this.setState({ isLoading: false })
      }
    }
  }

  handleArchiveBusiness = (businessId?: string) => async () => {
    if (businessId) {
      try {
        this.setState({ isLoading: true })
        await firestoreHttpsCallable('archiveBusiness', {
          businessId,
        })
        toast.success('Business was archived successfully.')
      } catch (error) {
        errorToast((error as Error)?.message ?? error)
      } finally {
        this.closeModal()
        this.setState({ isLoading: false })
      }
    }
  }

  handleUnarchiveBusiness = (businessId?: string) => async () => {
    if (businessId) {
      try {
        this.setState({ isLoading: true })
        await firestoreHttpsCallable('unArchiveBusiness', {
          businessId,
        })
        toast.success('Business was unarchived successfully.')
      } catch (error) {
        errorToast((error as Error)?.message ?? error)
      } finally {
        this.closeModal()
        this.setState({ isLoading: false })
      }
    }
  }

  handlePromoteStaffer = (stafferId?: string) => async () => {
    if (stafferId) {
      try {
        this.setState({ isLoading: true })
        await promoteStaffer(firebase.firestore(), stafferId)
        toast.success('Staffer was promoted successfully.')
      } catch (error) {
        errorToast((error as Error)?.message ?? error)
      } finally {
        this.closeModal()
        this.setState({ isLoading: false })
      }
    }
  }

  handleDemoteStaffer = (stafferId?: string) => async () => {
    if (stafferId) {
      try {
        this.setState({ isLoading: true })
        await demoteStaffer(firebase.firestore(), stafferId)
        toast.success('Staffer was demoted successfully.')
      } catch (error) {
        errorToast((error as Error)?.message ?? error)
      } finally {
        this.closeModal()
        this.setState({ isLoading: false })
      }
    }
  }

  handleBlockStaffer = (stafferId?: string) => async () => {
    if (stafferId) {
      try {
        this.setState({ isLoading: true })
        await accessBlock('staffer', stafferId)
        toast.success('Access was blocked/unblocked successfully.')
      } catch (error) {
        errorToast((error as Error)?.message ?? error)
      } finally {
        this.closeModal()
        this.setState({ isLoading: false })
      }
    }
  }

  handleRemoveStaffer = (stafferId?: string) => async () => {
    try {
      if (stafferId) {
        this.setState({ isLoading: true })
        await firestoreHttpsCallable('deleteUser', { userId: stafferId })
        this.setState({ isLoading: false })
      }
      this.closeModal()
      await this.props.history.push({
        pathname: '/staffers',
        state: {
          stafferRemoved: true,
        },
      })
    } catch (error) {
      errorToast((error as Error)?.message ?? error)
      this.closeModal()
    }
  }

  handleRemoveJob = (jobId?: string) => async () => {
    try {
      this.setState({ isLoading: true })
      if (jobId) {
        await StaffersJobsAPI.jobRemove(jobId)
      }
      this.closeModal()
      await this.props.history.push({
        pathname: '/jobs',
        state: {
          jobRemoved: true,
        },
      })
    } catch (e) {
      console.error(e)
      errorToast('You can not remove this job')
      this.closeModal()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleRemoveJobAd = (jobAdId?: string) => async () => {
    try {
      this.setState({ isLoading: true })
      if (jobAdId) {
        await StaffersJobsAPI.jobAdRemove(jobAdId)
      }
      this.closeModal()
      await this.props.history.push({
        pathname: '/jobs',
        state: {
          jobRemoved: true,
        },
      })
    } catch (e) {
      console.error(e)
      errorToast('You can not remove this job ad')
      this.closeModal()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleCreateOrEditJob = async (jobId?: string, isJobAd?: boolean) => {
    try {
      if (!jobId) {
        return
      }
      const { currentLocation } = this.props
      // Easiest check whether we should not redirect
      if (!(currentLocation && currentLocation.includes('unpublished'))) {
        await this.props.history.push({
          pathname: isJobAd ? `/job-ads/${jobId}` : `/jobs/${jobId}`,
        })
      }
    } catch (error) {
      errorToast((error as Error)?.message ?? error)
    } finally {
      this.closeModal()
    }
  }

  sendNewJobNotification = (jobId?: string) => async () => {
    try {
      if (jobId) {
        this.setState({ isLoading: true })
        await firestoreHttpsCallable('sendNewJobNotification', { jobId, enforce: true })
        toast.success('Notifications were sent successfully.')
        this.setState({ isLoading: false })
      }
    } catch (error) {
      errorToast((error as Error)?.message ?? error)
    } finally {
      this.closeModal()
    }
  }

  sendNewJobAdNotification = (jobAdId?: string) => async () => {
    try {
      if (jobAdId) {
        this.setState({ isLoading: true })
        await firestoreHttpsCallable('sendNewJobAdNotification', { jobAdId, enforce: true })
        toast.success('Notifications were sent successfully.')
        this.setState({ isLoading: false })
      }
    } catch (error) {
      errorToast((error as Error)?.message ?? error)
    } finally {
      this.closeModal()
    }
  }

  render() {
    const { buttonType } = this.props

    const { title, showModal, buttonLoading } = this.state

    const { modal } = this.getCurrentModal()

    return (
      <div>
        <Button buttonType={buttonType} title={title} onClick={this.handleButtonClick} disabled={buttonLoading} />
        <Modal isOpen={showModal} onRequestClose={this.closeModal} ariaHideApp={false}>
          {modal}
        </Modal>
      </div>
    )
  }
}

export default withRouter(MenuModal)
