/* eslint-disable react/jsx-one-expression-per-line */
// contractor (freelance) work agreement for Norway region (both English and Swedish language)
import { Typography } from '@material-ui/core'

import { Fragment } from 'react'
import style from '../modals/modals.module.css'

type Values = {
  today: string
  description: string
  jobType: string
  registeredName?: string
  businessName: string
  orgNumber: string
  businessAddress: string
  stafferNameFull: string
  identityNumber: string
  endDate: string
  jobDateStart: string
  jobDateEnd: string
  jobHourlyWage: string
  jobRequirementsTranslated: Record<'en' | 'no', Array<string>>
  jobTypeTranslated: Record<'en' | 'no', string>
  salaryHourly: number
}

const English = ({
  today,
  description,
  jobType,
  jobRequirementsTranslated,
  registeredName,
  businessName,
  orgNumber,
  businessAddress,
  stafferNameFull,
  identityNumber,
  jobDateStart,
  jobDateEnd,
  jobHourlyWage,
}: Values) => (
  <Fragment>
    <Typography component="h6" variant="h6" className={style.agreementHeader}>
      Contractor agreement
    </Typography>
    <div className={style.agreementWrapper}>
      <p>
        This contractor agreement (the &quot;<strong>Agreement</strong>&quot;) is signed on {today} between the
        Principal which is apparent in <strong>appendix 1</strong> and the Contractor which is apparent in{' '}
        <strong>appendix 2</strong>, according to, at all time, applicable terms, apparent in{' '}
        <strong> appendix 3</strong>.
        <br />
        <br />
        {description && (
          <p>
            <strong>Introductory comment</strong>
            <br />
            {description}
            <br />
            <br />
          </p>
        )}
        <strong>The Scope of the Agreement and Relationship with the Principal</strong>
        <br />
        The Contractor has by agreeing to this Agreement, agreed to assign the individual apparent in Appendix 2 (the
        &quot;<strong>Contracted Employee</strong>&quot;) to complete the assignment of {jobType} for the Principal
        under the terms and conditions of this Agreement. The Contracted Employee’s relationship with the Principal
        shall be that of an independent contractor. The Contracted Employee remains an employee of the Contractor and
        shall not act as an employee or a representative of the Principal.
        <br />
        <br />
        {jobRequirementsTranslated.en && jobRequirementsTranslated.en.length > 0 && (
          <Fragment>
            The assignment involves that the Contractor is going to perform the following services (the &quot;
            <strong>Services</strong>&quot;) for the Principal:
            <br />
            {jobRequirementsTranslated.en.map((text: string) => `\t - ${text} \n`)}
            <br />
            <br />
          </Fragment>
        )}
        The Contractor commits to deliver the Services in this agreement in accordance with best practice for similar
        services and under the terms of this Agreement. The Contractor confirms that the Contracted Employee has the
        necessary qualifications and relevant experience to deliver theServices.The Contractor shall ensure that the
        Services are performed by the Contracted Employee personally to the extent possible. Without the written consent
        of the Principal, the Contractor may not appoint another person, company or organization to perform the
        Services.
        <br />
        <br />
        <strong>The Term of the Agreement and Termination</strong>
        <br />
        This Agreement is effective from and including {jobDateStart} (the &quot;
        <strong>Start Date</strong>&quot;) to and including {jobDateEnd} (the &quot;
        <strong>End Date</strong>&quot;), alternatively until the Services are delivered. If the any of the parties
        wishes to terminate the Agreement at an earlier time, this has to be done at least 24 hours by giving a written
        notice to the other party.
        <br />
        <br />
        If one of the parties commits a material breach of the Agreement, neglects or refuses to perform the Services
        hereunder, or acts in a manner which substantially adversely affects the interest of the Principal or the
        interests of any of its affiliates,the other party can terminate the Agreement with immediate effect.
        <br />
        <br />
        This Agreement is conditioned upon the Contractor at any time being able to present the Principal with a valid
        F-tax certificate (Sw. F-skattsedel) stating that the Principal is relieved from the obligation to withhold tax
        and to pay social security contributions with reference to the Services. If a valid F-tax certificate cannot be
        presented by the Contractor, it is considered as a material breach of this Agreement.
        <br />
        <br />
        <strong>Fees/invoicing</strong>
        <br />
        Fees for the Services according to this Agreement are paid after time worked and accounted for and hourly wage
        per hour / excluding Value Added Tax, based on the specified hours for each day of the contract. The
        compensation is not to be regulated within the agreement period, and includes all costs of the contractor unless
        otherwise is specified.
        <br />
        <br />
        The Contractor is responsible to submit an invoice in respect for the their hours worked and expenses incurred.
        Invoicing should take place when the assignment is completed with a maturity date of 14 days from the receipt of
        the invoice.
        <br />
        <br />
        <strong>Insurance</strong>
        <br />
        The Contractor is himself responsible for taking out the necessary insurances - including insurances related to
        the execution of the Assignment. The Contractor thus does not take part in any insurance arrangements with the
        Principal.
        <br />
        <br />
        <strong>Expenses</strong>
        <br />
        The Principal shall reimburse the Contractor’s direct necessary and approved expenses incurred in connection
        with the Contractor’s performance of Services hereunder. For expenses in excess of SEK 5,000 the Principal’s
        prior written approval is required. Expenses shall be verified by documentation evidencing the costs.
        <br />
        <br />
        <strong>Taxes</strong>
        <br />
        The Contractor is responsible for the payment of any taxes, insurances and other fees accrued on payments from
        the Principal. The Contractor shall accordingly indemnify and hold the Principal harmless against costs, claims,
        expenses or proceedings arising out of or in connection with such payments.
        <br />
        <br />
        <strong>Confidentiality</strong>
        <br />
        The Contractor shall not, either during the term hereof or thereafter, be entitled to use (other than as
        necessary for the performance by the Contractor of the Services hereunder), publish or disclose, or permit any
        third party to use, publish or disclose any confidential information or trade secret relating to the business of
        the Principal or any of its affiliates.
        <br />
        <br />
        <strong>Amendments</strong>
        <br />
        Amendments and additions to this Agreement shall be in writing and signed by both parties in order to be valid
        <br />
        <br />
        <strong>Governing law and venue</strong>
        <br />
        This Agreement is governed by Swedish law. Any controversy arising out of this Agreement shall be solved in
        Swedish general courts with the Stockholm City Court as the court of first instance.
        <br />
        <br />
        This agreement is formed and signed in 2 copies, on to each party.
        <br />
        <br />
        <strong>Appendix 1</strong>
        <br />
        <br />
        &lt;The Principal&gt; <strong>{registeredName || businessName}</strong>
        <br />
        &lt;Org. no.&gt; <strong>{orgNumber}</strong>
        <br />
        &lt;Address&gt; <strong>{businessAddress}</strong>
        <br />
        <br />
        <strong>Appendix 2</strong>
        <br />
        <br />
        &lt;The Contractor&gt; <strong>{stafferNameFull}</strong>
        <br />
        &lt;Personal identity no.&gt; <strong>{identityNumber}</strong>
        <br />
        <br />
        <strong>Appendix 3</strong>
        <br />
        <br />
        Job information:
        <br />
        &lt;Location&gt; <strong>{businessAddress}</strong>
        <br />
        &lt;Start time&gt; <strong>{jobDateStart}</strong>
        <br />
        &lt;End time&gt; <strong>{jobDateEnd}</strong>
        <br />
        &lt;Hourly fee&gt; <strong>{jobHourlyWage}</strong>
        <br />
        &lt;Position&gt; <strong>{jobType}</strong>
      </p>
    </div>
  </Fragment>
)

// today,
// description,
// jobType,
// jobRequirementsTranslated,
// jobTypeTranslated,
// registeredName,
// businessName,
// orgNumber,
// businessAddress,
// stafferNameFull,
// identityNumber,
// endDate,
// jobDateStart,
// jobDateEnd,
// jobHourlyWage,
// salaryHourly,
const Swedish = () => (
  <Fragment>
    <Typography component="h6" variant="h6" className={style.agreementHeader}>
      OPPDRAGSAVTALE
    </Typography>
    <strong>Please Select English</strong>
    {/* TODO @Draho - add swedish translation */}
    {/* <div className={style.agreementWrapper}>
      <p>
        Denne oppdragsavtalen (Avtalen) er inngått den
        {' '}
        {today}
        {' '}
        mellom
        <br />
        <br />
        Oppdragsgiver som fremgår av
        {' '}
        <strong>vedlegg 1</strong>
        {' '}
        og
        <br />
        <br />
        Oppdragstaker som fremgår av
        {' '}
        <strong>vedlegg 2</strong>
        ,
        <br />
        <br />
        i henhold til enhver tid gjeldende vilkår som fremgår av
        <strong> vedlegg 3</strong>
        .
        <br />
        <br />

        {
          description && (
            <p>
              <strong>Innledende kommentar</strong>
              <br />
              {description}
              <br />
              <br />
            </p>
          )
        }

        <strong>Avtalens omfang</strong>
        <br />
        Oppdragstaker har ved inngåelse av Avtalen akseptert å utføre oppdrag
        om
        {' '}
        {jobTypeTranslated.no}
        {' '}
        for Oppdragsgiver.
        <br />
        <br />
        {
          jobRequirementsTranslated.no && jobRequirementsTranslated.no.length > 0 && (
            <Fragment>
              Oppdragetinnebærer at Oppdragstaker skalutførefølgende oppgaverfor
              Oppdragsgiver:
              <br />
              {
                jobRequirementsTranslated.no.map(text => `\t - ${text} \n`)
              }
              <br />
            </Fragment>
          )
        }
        Oppdragstaker forplikter seg til å levere tjenestene under denne avtale
        i samsvar med beste praksis for tilsvarende tjenester. Oppdragstaker bekrefter
        å innehade nødvendige kvalifikasjoner og relevant erfaring for
        levering av tjenestene.
        <br />
        <br />

        <strong>Avtalens varighet og opphør</strong>
        <br />
        Denne avtalen gjelder frem til
        {' '}
        {endDate}
        , alternativt til tjenestene
        er levert og rett oppgjør er mottatt. Dersom noen av partene ønsker å
        si opp avtalen på et tidligere tidspunkt, skal dette skje med 14
        dagers skriftlig varsel. Oppdragstaker har i tilfelle avbestilling rett
        til ethvert opptjent vederlag eller pådratt utlegg i forbindelse med avtalen,
        frem til oppsigelsestidens utløp. Eventuelt: Dersom en av partene
        vesentlig misligholder sine plikter etter Avtalen,
        kanden andre parten umiddelbart si oppAvtalen med skriftlig varsel.
        <br />
        <br />

        <strong>Pris/fakturering</strong>
        <br />
        Oppdragi henhold til denne avtalen avregnes etter medgått tid
        med
        {' '}
        {salaryHourly}
        {' '}
        kr/t ekskl. mva,
        basert på spesifiserte timelister for hver dag det er arbeidet.
        Honorarsatsen er ikke gjenstand for regulering i den planlagte avtaleperioden,
        og inkluderer alle-kostnader for Oppdragstaker med mindre annet er særskilt
        spesifisert nedenfor.
        <br />
        [eventuelt Oppdragetfaktureres i henhold til medgått tid].
        <br />
        <br />
        Avtalte utlegg som dekkes av Oppdragsgiver:
        <br />
        <strong>a)</strong>
        {'\t'}
        Dokumenterte utlegg og reise-/oppholdskostnader relatert til oppdraget
        refunderes uten påslag.
        <br />
        <strong>b)</strong>
        {'\t'}
        Bruk og parkering av egen bil godtgjøres etter Statens satser og
        registrert parkeringsavgift.Fakturering skal skje når oppdraget er sluttført med
        betalingsfrist 14 dager fra fakturadato.
        Ved betaling etter forfall beregnes 12% rente p.a.
        [evt. sett inn formulering omfakturering én gang pr. måned eller annen avtale].
        <br />
        <br />

        <strong>Skattemessige-og avgiftsmessige forhold</strong>
        <br />
        Oppdragstaker er selv ansvarlig for å oppfylle lovmessige plikter ved utførelse av
        Oppdraget, herunder betale lovpålagte skatter og avgifter.
        <br />
        <br />

        <strong>Forsikring</strong>
        <br />
        Oppdragstaker er selv ansvarlig for å tegne nødvendige forsikringer – herunder
        forsikringer knyttet til utføringen av Oppdraget. Oppdragstaker tar således ikke
        del av noen forsikringsordninger hos Oppdragsgiver.
        <br />
        <br />

        <strong>Konfidensialitet</strong>
        <br />
        Partene har gjensidig konfidensialitetsplikt med hensyn til all informasjon
        de erverver om den annen part i forbindelse med inngåelse og utførelse av denne
        avtale. Konfidensialitetsplikten gjelder ikke forhold som er alminnelig kjent.
        Konfidensialitetsplikten gjelder også etter utløpet av avtalen.
        <br />
        <br />

        <strong>Force Majeure</strong>
        <br />
        Partene er ikkeansvarligefor forsinkelser eller ikke-utførtehandlinger
        som skyldes forhold utenfor partneres kontroll.
        <br />
        <br />

        <strong>Passivitet</strong>
        <br />
        Ingen krav knyttet til Avtalen kan fremsettes mer enn to år etter at grunnen
        for å fremsette kravet oppstod.
        <br />
        <br />

        <strong>Lovvalg og verneting</strong>
        <br />
        Ved eventuelle tvister som oppstår i relasjon til Avtalen, gjelder norsk rett,
        og tvisten skal avgjøres ved norskedomstoler.
        <br />
        <br />

        Denne avtalen er utformet og signert i 2 eksemplarer; ett til hver av partene.
        <br />
        <br />
        <strong>Appendix 1</strong>
        <br />
        <br />
        &lt;Arbeidsgiver&gt;
        {' '}
        <strong>{registeredName || businessName}</strong>
        <br />
        &lt;Org. nr.&gt;
        {' '}
        <strong>{orgNumber}</strong>
        <br />
        &lt;Adresse&gt;
        {' '}
        <strong>{businessAddress}</strong>
        <br />
        <br />
        <strong>Appendix 2</strong>
        <br />
        <br />
        &lt;Arbeidstaker&gt;
        {' '}
        <strong>{stafferNameFull}</strong>
        <br />
        &lt;Presonnr.&gt;
        {' '}
        <strong>{identityNumber}</strong>
        <br />
        <br />
        <strong>Appendix 3</strong>
        <br />
        <br />
        Jobb informasjon:
        <br />
        &lt;Jobbsted&gt;
        {' '}
        <strong>{businessAddress}</strong>
        <br />
        &lt;Starttid&gt;
        {' '}
        <strong>{jobDateStart}</strong>
        <br />
        &lt;Sluttid&gt;
        {' '}
        <strong>{jobDateEnd}</strong>
        <br />
        &lt;Lønn&gt;
        {' '}
        <strong>{jobHourlyWage}</strong>
        <br />
        &lt;Tittel&gt;
        {' '}
        <strong>{jobType}</strong>
      </p>
    </div> */}
  </Fragment>
)

export default {
  English,
  Swedish,
}
