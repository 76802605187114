import { Button, Tooltip } from '@material-ui/core'
import moment from 'moment'
import * as R from 'ramda'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import type { JobType, ShiftType } from '../../../../../src/types/jobs'
import type { StafferRatingsType } from '../../../../../src/types/staffer'
import { colors } from '../../../constants/styles'
import EditIcon from '../../../img/EditIcon.svg'
import ClockIcon from '../../../img/algoliaIcons/ClockIcon.svg'
import MailOpenIcon from '../../../img/algoliaIcons/MailOpenIcon.svg'
import MessageIcon from '../../../img/algoliaIcons/MessageIcon.svg'
import PublicIcon from '../../../img/algoliaIcons/PublicIcon.svg'
import StarOutlineIcon from '../../../img/algoliaIcons/StarOutlineIcon.svg'
import UserIcon from '../../../img/algoliaIcons/UserIcon.svg'
import WageIcon from '../../../img/algoliaIcons/WageIcon.svg'
import profileAvatar from '../../../img/profileAvatar.png'
import { business as businessSchema } from '../../../staffers/qman/business'
import { jobs as jobsSchema } from '../../../staffers/qman/jobs'
import EditShiftRating from '../../modals/EditShiftRating'
import Modal from '../../mui/Modal'
import { default as ButtonElastic } from '../../mui/redesignedComponents/Button'
import { ModalHeader } from '../modal/Modal'
import styles from './StafferPopover.module.css'

type Props = {
  stafferId: string
  completedJobs: JobType[]
  stafferRatings: StafferRatingsType[]
}

type ListingShift = ShiftType & {
  jobType: string
  businessName: string
  businessId: string
  wage: number
  isPublic: boolean
  job: JobType
}

type ListingCardProps = {
  shift: ListingShift
  stafferId: string
  stafferRatings: StafferRatingsType[]
}

const sortTimeStart = R.sortBy(R.compose((a: any) => -a, R.prop('timeStart')))

const ListingCard = ({ shift, stafferRatings, stafferId }: ListingCardProps) => {
  const [isOpenEditShiftModal, setIsOpenEditShiftModal] = useState(false)
  const business = businessSchema.get('businessById', [shift.businessId])
  const jobStafferDetail = jobsSchema.get('jobStafferDetailById', [shift.job.id, stafferId])

  if (!business) {
    return (
      <div className={styles.jobCard}>
        <div className={styles.jobCardContent}>
          <span style={{ fontStyle: 'italic', padding: 4 }}>Business data unavailable</span>
        </div>
      </div>
    )
  }

  const stafferShiftInfo = jobStafferDetail?.shifts.find((stafferShift) => stafferShift.shiftId === shift.shiftId)
  const isInvoiced = !!stafferShiftInfo?.invoicedOn || !!shift.job.sentToDipps

  const stafferRatingBusiness = stafferRatings.find((rating) => rating.businessId === shift.businessId) || null
  const stafferRating = stafferRatingBusiness?.ratings.find((rating) => rating.shiftId === shift.shiftId) || null

  const { timeStart, timeEnd } = shift

  const day = moment(timeStart.toMillis()).format('DD')
  const month = moment(timeStart.toMillis()).format('MMM')
  const startDate = moment(timeStart.toMillis()).format('ddd HH:mm')
  const endDate = moment(timeEnd.toMillis()).format('HH:mm')
  const timeDiff = Math.round(moment(timeEnd.toMillis()).diff(moment(timeStart.toMillis()), 'hours'))
  const rating = stafferRating?.value?.toFixed(1) || '-'

  return (
    <div className={styles.jobCard}>
      <div className={styles.jobCardContent}>
        <Link to={`/jobs/${shift.job.id}`} className={styles.jobCardContentInfo}>
          <div className={styles.jobDateBadge}>
            <span style={{ fontSize: 24 }}>{day}</span>
            <span className={styles.greyText} style={{ fontSize: 14 }}>
              {month}
            </span>
          </div>
          <div className={styles.cardColumn}>
            <span style={{ color: colors.primary, fontSize: 18, fontWeight: 600 }}>{shift.jobType}</span>
            <span style={{ color: colors.neutral800, paddingTop: 6 }}>{shift.businessName}</span>
          </div>
        </Link>
        <div className={styles.jobCardLinks}>
          {/* Show this icon only if the it was sent to manymore */}
          {isInvoiced && (
            <Tooltip title="Invoice was already sent to Payout Partner.">
              <div className={styles.jobCardLinksItem}>
                <img src={MailOpenIcon} alt="manymore" />
                <span>Payout Partner</span>
              </div>
            </Tooltip>
          )}
          <Tooltip title={business.ratingDisabled ? 'Business has disabled rating.' : ''}>
            <ButtonElastic
              onClick={() => (!business.ratingDisabled ? setIsOpenEditShiftModal(true) : null)}
              startIcon={<img src={EditIcon} alt="edit rating/comment" />}
              style={{ paddingInline: 8 }}
              disableRipple={business.ratingDisabled}
            >
              Edit rating/comment
            </ButtonElastic>
          </Tooltip>
        </div>
      </div>
      <div className={styles.jobCardFooter}>
        <div className={styles.contentRow}>
          <div className={styles.contentRow}>
            <img src={UserIcon} alt="staffer positions" className={styles.smallIcon} />
            <span>
              {shift.staffersConfirmed.length}/{shift.positions} staffers
            </span>
            <img src={ClockIcon} alt="time of job" className={styles.smallIcon} style={{ marginLeft: 12 }} />
            <span>
              {startDate} - {endDate}
            </span>
            <img src={StarOutlineIcon} alt="ragin" className={styles.smallIcon} style={{ marginLeft: 12 }} />
            <span>{rating}</span>
            <div className={styles.wageChip} style={{ marginLeft: 12 }}>
              <img src={WageIcon} alt="wage" className={styles.smallIcon} />
              <span>
                {Math.round(shift.wage)} / {timeDiff}h
              </span>
            </div>
          </div>
          {shift.isPublic && (
            <div className={styles.contentRow} style={{ justifyContent: 'flex-end' }}>
              <img src={business.coverImageUrl || profileAvatar} alt="business" className={styles.largeIcon} />
              <img src={PublicIcon} alt="public" style={{ width: 28, height: 28 }} />
            </div>
          )}
        </div>
        {stafferRating?.comment && (
          <div className={styles.contentRow} style={{ marginTop: 4 }}>
            <img src={MessageIcon} alt="comment" className={styles.smallIcon} />
            <span>{stafferRating.comment}</span>
          </div>
        )}
      </div>
      <Modal enforcedWidth="large" hasRoundedCorners isOpen={isOpenEditShiftModal} zIndex={2001}>
        <ModalHeader title="" onClose={() => setIsOpenEditShiftModal(false)} />
        <EditShiftRating
          onClose={() => setIsOpenEditShiftModal(false)}
          businessId={business.id}
          stafferId={stafferId}
          shiftId={shift.shiftId}
          timeStart={timeStart}
          timeEnd={timeEnd}
          currentRating={stafferRating?.value}
          currentComment={stafferRating?.comment}
          job={shift.job}
          business={business}
        />
      </Modal>
    </div>
  )
}

const StafferPopoverJobsListing = ({ stafferId, completedJobs, stafferRatings }: Props) => {
  const [jobsLimit, setJobsLimit] = useState(4)

  if (!completedJobs || !stafferRatings) {
    return <BeatLoader />
  }

  const completedShifts = completedJobs.flatMap((job) =>
    job.shifts
      .filter((shift) => shift.staffersConfirmed.includes(stafferId))
      .map((shift) => ({
        ...shift,
        jobType: job.jobType,
        businessName: job.businessName,
        businessId: job.businessId,
        wage: job.calculatedCost.total.salary,
        isPublic: job.isPublic,
        job: job,
      }))
  )

  const sortedCompletedShifts = sortTimeStart(completedShifts) as ListingShift[]

  const onViewAll = () => {
    if (completedShifts && jobsLimit <= completedShifts.length) {
      setJobsLimit(jobsLimit + 5)
    }
  }

  return (
    <div className={styles.jobsWrapper}>
      {sortedCompletedShifts.map(
        (completedShift, index) =>
          index <= jobsLimit && (
            <ListingCard key={index} shift={completedShift} stafferRatings={stafferRatings} stafferId={stafferId} />
          )
      )}
      {completedShifts.length > 5 && jobsLimit < completedShifts.length - 1 && (
        <Button
          onClick={onViewAll}
          variant="outlined"
          color="primary"
          style={{ marginTop: 20, borderRadius: 8, textTransform: 'unset' }}
        >
          See more
        </Button>
      )}
    </div>
  )
}

export default StafferPopoverJobsListing
