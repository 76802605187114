import { query, schema } from 'qman'
import getStaffersListingData from '../api/getters/getStafferListingData'
import {
  fetchOneAppliedJobForBusiness,
  fetchOneRecentJobForBusiness,
  fetchOneUpcomingJobForBusiness,
  getAllEventJobs,
  getAllFreelanceJobs,
  getAllFreelanceJobsByBusinessId,
  getAllFreelanceJobsDeleted,
  getAllInternalJobsByBusinessId,
  getAllJobAdStaffers,
  getAllJobAds,
  getAllJobAdsByBusinessId,
  getAllJobs,
  getAllJobsAfterManymore,
  getAllJobsByBusinessId,
  getAllJobsByBusinessIdStartingFrom,
  getAllJobsByBusinessIdWithinRelativeTimeFrame,
  getAllJobsByPosition,
  getAllJobsStartingFrom,
  getAllNonFreelanceJobs,
  getAllNonFreelanceJobsByBusinessId,
  getAllNonFreelanceJobsDeleted,
  getAllUnpublishedJobsByBusinessId,
  getAppliedJobAdsByStafferBusinessId,
  getAvailableJobAds,
  getAvailableJobs,
  getCompletedJobsByStafferAndBusinessId,
  getCompletedJobsByStafferId,
  getContactedJobAdsByStafferBusinessId,
  getDeletedJobDetailById,
  getHiredJobAdsByStafferBusinessId,
  getJobAdById,
  getJobAdStafferById,
  getJobAdsSinceLastMonth,
  getJobById,
  getJobDetailByBusinessId,
  getJobDetailById,
  getJobDetailByIdPromise,
  getJobDraftsByBusinessId,
  getJobDraftsByManagerId,
  getJobStafferDetailById,
  getJobsByStafferBusinessId,
  getJobsByStafferId,
  getJobsByStatus,
  getJobsByStatusAndStafferId,
  getLastYearJobsByBusinessId,
  getPendingOrCompletedJobsByBusiness,
  getPoolJobsByBusinessId,
  getPoolPublicJobs,
  getRecentJobsByBusIdPromise,
  getSimilarJobs,
  getSingleEventJobByBusiness,
  getSingleExternalJobByBusiness,
  getSingleTemporaryJobByBusiness,
  getStaffersByJobId,
  getTotalHoursWorked,
  getUnavailableJobs,
  getUnpublishedDetailById,
  getUnpublishedEventJobs,
  getUnpublishedEventJobsByBusinessId,
  getUnpublishedJobAds,
  getUnpublishedJobAdsByBusinessId,
  getUnpublishedNonEventJobs,
  getUnpublishedNonEventJobsByBusinessId,
  getUnseenRequestJobsByManagerId,
  getUpcJobsByStafferAndBusinessIdStartingFrom,
  getUpcomingFreelanceJobs,
  getUpcomingFreelanceJobsByBusinessId,
  getUpcomingJobsByBusIdPromise,
  getUpcomingJobsByStafferId,
} from '../api/getters/jobs'
import { documentFn, promiseFn, queryFn } from './schemaFunctions'

export const jobs = schema(
  'jobs',
  query('jobById', getJobById, documentFn),
  query('unseenRequestJobsByManagerId', getUnseenRequestJobsByManagerId, promiseFn),
  query('similarJobs', getSimilarJobs, queryFn),
  query('allJobs', getAllJobs, queryFn),
  query('allJobsAfterManymore', getAllJobsAfterManymore, queryFn),
  query('allNonFreelanceJobsDeleted', getAllNonFreelanceJobsDeleted, queryFn),
  query('allFreelanceJobsDeleted', getAllFreelanceJobsDeleted, queryFn),
  query('allNonFreelanceJobs', getAllNonFreelanceJobs, queryFn),
  query('allFreelanceJobs', getAllFreelanceJobs, queryFn),
  query('upcomingFreelanceJobs', getUpcomingFreelanceJobs, queryFn),
  query('upcomingFreelanceJobsByBusinessId', getUpcomingFreelanceJobsByBusinessId, queryFn),
  query('availableJobs', getAvailableJobs, queryFn),
  query('unavailableJobs', getUnavailableJobs, queryFn),
  query('allJobsByPosition', getAllJobsByPosition, queryFn),
  query('jobDetailById', getJobDetailById, documentFn),
  query('deletedJobDetailById', getDeletedJobDetailById, documentFn),
  query('unpublishedDetailById', getUnpublishedDetailById, documentFn),
  query('jobDraftsByBusinessId', getJobDraftsByBusinessId, queryFn),
  query('jobDraftsByManagerId', getJobDraftsByManagerId, promiseFn),
  query('jobDetailByIdPromise', getJobDetailByIdPromise, promiseFn),
  query('upcJobsByStafferAndBusinessIdStartingFrom', getUpcJobsByStafferAndBusinessIdStartingFrom, queryFn),
  query('completedJobsByStafferId', getCompletedJobsByStafferId, queryFn),
  query('fetchOneRecentJobForBusiness', fetchOneRecentJobForBusiness, queryFn),
  query('upcomingJobsByStafferId', getUpcomingJobsByStafferId, queryFn),
  query('fetchOneUpcomingJobForBusiness', fetchOneUpcomingJobForBusiness, queryFn),
  query('fetchOneAppliedJobForBusiness', fetchOneAppliedJobForBusiness, queryFn),
  query('completedJobsByStafferAndBusinessId', getCompletedJobsByStafferAndBusinessId, queryFn),
  query('jobsByStatus', getJobsByStatus, queryFn),
  query('allJobsByBusinessId', getAllJobsByBusinessId, queryFn),
  query('lastYearJobsByBusinessId', getLastYearJobsByBusinessId, queryFn),
  query('allNonFreelanceJobsByBusinessId', getAllNonFreelanceJobsByBusinessId, queryFn),
  query('allFreelanceJobsByBusinessId', getAllFreelanceJobsByBusinessId, queryFn),
  query('upcomingJobsByBusIdPromise', getUpcomingJobsByBusIdPromise, promiseFn),
  query('recentJobsByBusIdPromise', getRecentJobsByBusIdPromise, promiseFn),
  query('jobsByStafferId', getJobsByStafferId, queryFn),
  query('jobsByStafferBusinessId', getJobsByStafferBusinessId, queryFn),
  query('jobsByStatusAndStafferId', getJobsByStatusAndStafferId, queryFn),
  query('jobDetailByBusinessId', getJobDetailByBusinessId, queryFn),
  query('staffersByJobId', getStaffersByJobId, queryFn),
  query('jobStafferDetailById', getJobStafferDetailById, documentFn),
  query('allJobsByBusinessIdStartingFrom', getAllJobsByBusinessIdStartingFrom, queryFn),
  query('allJobsByBusinessIdWithinRelativeTimeFrame', getAllJobsByBusinessIdWithinRelativeTimeFrame, queryFn),
  query('allJobsStartingFrom', getAllJobsStartingFrom, queryFn),
  query('jobAdById', getJobAdById, documentFn),
  query('allJobAds', getAllJobAds, queryFn),
  query('jobAdsSinceLastMonth', getJobAdsSinceLastMonth, queryFn),
  query('availableJobAds', getAvailableJobAds, queryFn),
  query('allJobAdStaffers', getAllJobAdStaffers, queryFn),
  query('jobAdStafferById', getJobAdStafferById, documentFn),
  query('allJobAdsByBusinessId', getAllJobAdsByBusinessId, queryFn),
  query('allEventJobs', getAllEventJobs, queryFn),
  query('appliedJobAdsByStafferBusinessId', getAppliedJobAdsByStafferBusinessId, queryFn),
  query('contactedJobAdsByStafferBusinessId', getContactedJobAdsByStafferBusinessId, queryFn),
  query('hiredJobAdsByStafferBusinessId', getHiredJobAdsByStafferBusinessId, queryFn),
  query('allUnpublishedJobsByBusinessId', getAllUnpublishedJobsByBusinessId, queryFn),
  query('unpublishedNonEventJobs', getUnpublishedNonEventJobs, queryFn),
  query('unpublishedNonEventJobsByBusinessId', getUnpublishedNonEventJobsByBusinessId, queryFn),
  query('unpublishedEventJobs', getUnpublishedEventJobs, queryFn),
  query('unpublishedEventJobsByBusinessId', getUnpublishedEventJobsByBusinessId, queryFn),
  query('unpublishedJobAds', getUnpublishedJobAds, queryFn),
  query('unpublishedJobAdsByBusinessId', getUnpublishedJobAdsByBusinessId, queryFn),
  query('poolJobsByBusinessId', getPoolJobsByBusinessId, queryFn),
  query('poolPublicJobs', getPoolPublicJobs, queryFn),
  query('allInternalJobsByBusinessId', getAllInternalJobsByBusinessId, queryFn),
  query('totalHoursWorked', getTotalHoursWorked, promiseFn),
  query('singleExternalJobByBusiness', getSingleExternalJobByBusiness, queryFn),
  query('singleEventJobByBusiness', getSingleEventJobByBusiness, queryFn),
  query('singleTemporaryJobByBusiness', getSingleTemporaryJobByBusiness, queryFn),
  query('pendingOrCompletedJobsByBusiness', getPendingOrCompletedJobsByBusiness, queryFn),
  query('staffersListingData', getStaffersListingData, promiseFn)
)
