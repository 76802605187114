import { Box, Card, CardContent, Chip, Typography } from '@material-ui/core'
import { Fragment } from 'react'
import type { ManagerTypeWithId } from '../../../../../src/types/manager'
import type { SelectBusinessType } from './CreateEditGroup'

type Props = {
  emailToBeInvited: string
  groupName: string
  selectedBusinesses: Array<SelectBusinessType>
  selectedGroupManagers: Array<ManagerTypeWithId>
}

const GroupPreview = (props: Props) => {
  const { emailToBeInvited, groupName, selectedBusinesses, selectedGroupManagers } = props

  return (
    <Fragment>
      <Typography component="h1">Summary</Typography>
      <Box my={2}>
        <Card>
          <CardContent>
            <Typography component="h2">Group name:</Typography>
            <Typography>{groupName}</Typography>
          </CardContent>
        </Card>
      </Box>

      <Box my={2}>
        <Card>
          <CardContent>
            <Typography component="h2">Group managers:</Typography>
            {selectedGroupManagers.map((manager) => (
              <Typography key={manager.id}>{manager.email}</Typography>
            ))}
          </CardContent>
        </Card>
      </Box>
      {emailToBeInvited && (
        <Box my={2}>
          <Card>
            <CardContent>
              <Typography component="h2">Manager to be invited:</Typography>
              <Typography>{emailToBeInvited}</Typography>
            </CardContent>
          </Card>
        </Box>
      )}

      <Box my={2}>
        <Card>
          <CardContent>
            <Typography component="h2">Businesses in the group:</Typography>
            <Box display="flex" flexWrap="wrap">
              {selectedBusinesses.map((business) => (
                <Box p={1} key={business.businessId}>
                  <Chip
                    // icon={<LinkIcon />}
                    label={business.businessName}
                    size="small"
                  />
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  )
}

export default GroupPreview
