export default {
  // https://www.officeholidays.com/countries/norway/2020
  // Note 12/24 is 'De facto holiday' - we don't count that
  // fixed
  'Christmas Day': {
    date: '12/25',
  },
  'Second Day of Christmas': {
    date: '12/26',
  },
  "New Year's Day": {
    date: '1/1',
  },
  'Labour Day': {
    date: '5/1',
  },
  'Constitution Day': {
    date: '5/17',
  },
  // floating (all based on easter)
  'Maundy Thursday': {
    date: 'easter-3', // Thursday before Easter Sunday
  },
  'Good Friday': {
    date: 'easter-2', // Friday before Easter Sunday
  },
  'Easter Sunday': {
    date: 'easter', // First Sunday after first spring fullmoon (non-astronomical magic)
  },
  'Easter Monday': {
    date: 'easter+1', // Day after Easter
  },
  'Ascension Day': {
    date: 'easter+39', // 39 days after Easter
  },
  'Pentecost Sunday': {
    date: 'easter+49', // 49 days after Easter
  },
  'Whit Monday': {
    // 7th monday after Easter
    date: 'easter+50',
  },
}
