import { Box, Button, FormControl, FormHelperText, FormLabel, Input, Tab, Tabs } from '@material-ui/core/'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import type { ChangeEvent, FormEventHandler, MouseEventHandler } from 'react'
import { Component } from 'react'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { config } from '../../config'
import { currentVersion } from '../../constants'
import { errorToast } from '../../helpers/toast'
import { isValidEmail } from '../../helpers/validator'
import { createManagerProfile } from '../../staffers/api/auth/managerProfile'
import { isAdminEmailPromise } from '../../staffers/api/getters/admins.legacy'
import { confirm } from '../modals/ConfirmationModal'
import { pick } from '../modals/OptionPickerModal'
import TabPanel from '../mui/TabPanel'
import styles from './LoginCart.module.css'

const MIN_PASSWORD_CHAR = 6

const { adminTenantId } = config

// allow non 2FA admin login on beta for now
// this prevents locking super admin access before this is deployed to live
const enforce2FA = !global.location.hostname.includes('beta') // TODO @Draho Remove this in 1.51

type State = {
  email: string
  emailError: string
  emailIsNew: boolean
  isAdminTenant: boolean
  loading: boolean
  password: string
  passwordError: string
  tabvalue: number
  validation: string
  validationError: string
  verificationCode: string
  verificationId: string
  wasFormTouched: boolean
  isForgottenPassword: boolean
}

type Props = {
  email: string
  recalculateAppStatus: () => Promise<void>
}

class LoginCart extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      email: props.email || '',
      emailError: '',
      emailIsNew: false,
      isAdminTenant: false,
      loading: false,
      password: '',
      passwordError: '',
      tabvalue: 0, // always show register form as default
      validation: '',
      validationError: '',
      verificationCode: '',
      verificationId: '',
      wasFormTouched: false,
      isForgottenPassword: false,
    }
  }

  recaptcha: HTMLElement | null = null
  recaptchaVerifier: firebase.auth.RecaptchaVerifier | null = null
  signInResolver: null | ((cred: any) => Promise<any>) = null

  componentDidMount(): void {
    this.checkEmailStatus()

    // Keep recaptcha first, so we are sure it loads correctly
    try {
      // Rendering recaptcha
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha, {
        size: 'invisible', // captcha appears after the user tries to send the sms
      })
      this.recaptchaVerifier.render().then((widgetId) => {
        // @ts-ignore
        window.recaptchaWidgetId = widgetId
      })
    } catch (error) {
      console.error(error)
      console.error('Unable to load recaptcha')
    }
  }

  handleEmailChange = (event: any) => {
    this.setState(
      {
        email: event.target.value.toLowerCase(),
        wasFormTouched: true,
      },
      this.checkEmailStatus
    )
  }

  onResetPassword = async () => {
    const { email } = this.state

    if (email) {
      try {
        try {
          await firebase.auth().sendPasswordResetEmail(email)
        } catch (e) {
          if ((e as firebase.auth.Error).code === 'auth/user-not-found') {
            firebase.auth().tenantId = adminTenantId
            await firebase.auth().sendPasswordResetEmail(email)
          } else {
            throw e
          }
        } finally {
          firebase.auth().tenantId = null
        }
        toast(
          "You will receive an email with instructions shortly. If you haven't got the email within 5 minutes then try one more time.",
          {
            autoClose: 12000,
          }
        )
      } catch (error) {
        errorToast((error as Error).message)
      }
    } else {
      this.setState({
        emailError: this.getEmailError(email, false),
        isForgottenPassword: true,
      })
    }
  }

  handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    await this.checkEmailStatus()
    const { email, password, emailIsNew, tabvalue, isAdminTenant, verificationCode, verificationId } = this.state

    firebase.auth().tenantId = isAdminTenant ? adminTenantId : null // default tenant (regular non-admin users)

    if (verificationId) {
      // handle 2FA case
      if (!verificationCode) {
        toast.warn('Please enter the code from SMS')
        return
      }
      if (!this.signInResolver) {
        errorToast('signInResolver not set')
        return
      }
      try {
        this.setState({ loading: true })
        const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode)
        const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
        await this.signInResolver(multiFactorAssertion) // finish the signing in

        // keep showing loader until auto redirection triggered by auth change
        this.setState({
          verificationCode: '',
          verificationId: '',
        })
        this.signInResolver = null
      } catch (e) {
        if ((e as firebase.auth.Error).code === 'auth/invalid-verification-code') {
          errorToast('Wrong verification code, please try again.')
        } else {
          errorToast((e as Error).message ?? e)
        }
        this.setState({
          verificationCode: '',
          verificationId: '',
          loading: false,
        })
        console.error(e)
      }
      return
    }

    if (!emailIsNew) {
      const db = firebase.firestore()
      try {
        this.setState({ loading: true })
        const userCredential = await firebase.auth().signInWithEmailAndPassword(email.trim(), password)
        const isSuperAdmin = await isAdminEmailPromise(db, email)

        // check whether admins have 2FA enabled and show toast reminder if not
        if (isSuperAdmin) {
          if (!userCredential.user) {
            console.error('no user in userCredential')
            return
          }
          const { multiFactor, tenantId } = userCredential.user
          // @ts-ignore
          if (!multiFactor?.user.emailVerified) {
            // this field will be flipped to true by a migration for current users
            const msg = 'This superadmin email address is not verified'
            console.warn(msg)
            toast.warn(msg, { autoClose: 60000 })
            const ok = await confirm('Send email verification?', [
              'We need to verify your email address in order to set 2FA.',
              'Contact tech support if you have a question.',
            ])
            if (ok) {
              await userCredential.user.sendEmailVerification({
                url: global.location.href,
              })
              toast.success('Verification link sent - check your mailbox')
              await firebase.auth().signOut()
            } else if (enforce2FA) {
              await firebase.auth().signOut()
            }
          } else if (!multiFactor.enrolledFactors.length) {
            const msg = `Two factor auth for this admin account (${email}) is not set - contact tech support`
            console.warn(msg)
            toast.warn(msg, { autoClose: 60000 })
            if (enforce2FA) {
              await firebase.auth().signOut()
            }
          }
          if (tenantId !== adminTenantId && enforce2FA) {
            const msg = `This admin account (${email}) is not yet migrated to new tenant - contact tech support`
            console.error(msg)
            errorToast(msg, { autoClose: 60000 })
            await firebase.auth().signOut()
          }
          return
        }
      } catch (error) {
        if ((error as firebase.auth.Error).code === 'auth/multi-factor-auth-required') {
          // The user is a multi-factor user. Second factor challenge is required.
          await this.handle2FA((error as firebase.auth.MultiFactorError).resolver)
        } else {
          console.error(error)
          errorToast((error as Error).message ?? error)
        }
        this.setState({ loading: false })
      } finally {
        firebase.auth().tenantId = null
      }
    }

    // new email on login screen
    if (emailIsNew && tabvalue === 0) {
      errorToast('You have entered an incorrect email, please correct it or create a new account instead')
    }

    if (emailIsNew && tabvalue === 1) {
      try {
        this.setState({ loading: true })
        await firebase.auth().createUserWithEmailAndPassword(email.trim(), password)
        await createManagerProfile()
        await this.props.recalculateAppStatus()
      } catch (error) {
        this.setState({ loading: false })
        errorToast((error as Error).message)
      }
    }
  }

  handle2FA = async (resolver: firebase.auth.MultiFactorResolver): Promise<void> => {
    const { hints, session, resolveSignIn } = resolver
    const phoneHints = hints.filter(
      ({ factorId }: firebase.auth.MultiFactorInfo) => factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
    )
    const selectedHint =
      phoneHints.length === 1
        ? phoneHints[0]
        : await pick(
            'Select your phone number',
            ['SMS with verification code will be sent to the selected phone number'],
            phoneHints.map((hint: firebase.auth.MultiFactorInfo) => ({
              // @ts-ignore
              name: hint.phoneNumber,
              value: hint,
            }))
          )
    if (selectedHint) {
      const { phoneNumber } = selectedHint
      toast.info(`Verification code sent to ${phoneNumber}`)
    } else {
      errorToast('No supported second factor auth set for this account - contact tech support')
      return
    }

    const phoneInfoOptions = {
      multiFactorHint: selectedHint,
      session,
    }
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
    // Send SMS verification code.
    try {
      // @ts-ignore
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
      this.setState({
        verificationId,
      })
      this.signInResolver = resolveSignIn.bind(resolver)
    } catch (e) {
      console.error('Sending SMS failed', e)
      errorToast('Sending SMS failed')
    }
  }

  checkEmailStatus = async () =>
    // TODO: Refactor - Promise executor should not be async
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve) => {
      const { email } = this.state
      let isNew = false
      let isAdminTenant = false
      try {
        if (!isValidEmail(email)) {
          return
        }
        // try admin tenant first
        firebase.auth().tenantId = adminTenantId
        const adminSignUpMethods = await firebase.auth().fetchSignInMethodsForEmail(email).catch()
        firebase.auth().tenantId = null
        isNew = adminSignUpMethods.length === 0
        if (!isNew) {
          isAdminTenant = true
        } else {
          // regular users
          const signUpMethods = await firebase.auth().fetchSignInMethodsForEmail(email)
          isNew = signUpMethods.length === 0
        }

        this.setState(
          {
            emailIsNew: isNew,
            isAdminTenant,
          },
          resolve as () => void
        )
      } catch (error) {
        console.error(error)
        this.setState(
          {
            emailIsNew: false,
          },
          resolve as () => void
        )
      } finally {
        this.setState(
          {
            emailError: this.getEmailError(email, isNew),
          },
          resolve as () => void
        )
      }
    })

  getEmailError = (email: string, isNew: boolean): string => {
    const { tabvalue } = this.state
    if (!email) {
      return 'An e-mail address is required'
    }
    if (!isValidEmail(email)) {
      return 'Please enter a valid e-mail format'
    }
    if (!isNew && tabvalue === 1) {
      return 'This e-mail address is already taken'
    }

    return ''
  }

  // eslint-disable-next-line class-methods-use-this
  getPasswordError = (password: string): string => {
    if (password.length < MIN_PASSWORD_CHAR) {
      return `Passwords should be at least ${MIN_PASSWORD_CHAR} characters.`
    }

    return ''
  }

  // eslint-disable-next-line class-methods-use-this
  getValidationError = (password: string, validation: string): string => {
    if (password !== validation) {
      return 'The password validation does not match.'
    }

    return ''
  }

  onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const { validation } = this.state
    const passwordError = this.getPasswordError(e.target.value)
    const validationError = this.getValidationError(e.target.value, validation)
    this.setState({
      password: e.target.value,
      passwordError,
      validationError,
      wasFormTouched: true,
    })
  }

  onChangeVerificationCode = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      verificationCode: e.target.value,
    })
  }

  onChangeValidation = (e: ChangeEvent<HTMLInputElement>) => {
    const { password } = this.state
    const passwordError = this.getPasswordError(password)
    const validationError = this.getValidationError(password, e.target.value)
    this.setState({
      passwordError,
      validation: e.target.value,
      validationError,
      wasFormTouched: true,
    })
  }

  handleTabChange = (event: ChangeEvent<Record<string, unknown>>, newValue: number) => {
    this.setState(
      {
        tabvalue: newValue,
      },
      this.getAllErrors
    )
  }

  // technically tabs are made only for <Tab> switching, so we need to create separate handler
  // but we should use the handleTabChange whenever possible
  buttonTabToggle = () => {
    const { tabvalue } = this.state
    const newValue = tabvalue === 0 ? 1 : 0
    this.setState(
      {
        tabvalue: newValue,
      },
      this.getAllErrors
    )
  }

  getAllErrors = () => {
    const { password, validation, email, emailIsNew } = this.state

    const emailError = this.getEmailError(email, emailIsNew)
    const passwordError = this.getPasswordError(password)
    const validationError = this.getValidationError(password, validation)

    this.setState({
      emailError,
      passwordError,
      validationError,
    })
  }

  render() {
    const {
      email,
      emailError,
      emailIsNew,
      loading,
      password,
      passwordError,
      tabvalue,
      validation,
      validationError,
      verificationCode,
      verificationId,
      wasFormTouched,
      isForgottenPassword,
    } = this.state

    const showVerificationCodeInput = !!verificationId

    const isSignupScreen = tabvalue === 1

    // const resetPasswordDisabled = loading || emailIsNew || !!emailError

    if (loading) {
      return (
        <Box className={styles.loaderWrapper}>
          <div
            ref={(ref) => {
              this.recaptcha = ref
            }}
          />
          <BeatLoader color="gray" />
        </Box>
      )
    }

    return (
      <Box className={styles.container}>
        <div
          ref={(ref) => {
            this.recaptcha = ref
          }}
        />
        {/* Tab Navigation */}
        <Tabs
          classes={{
            indicator: styles.indicator,
          }}
          className={styles.tabPanel}
          value={tabvalue}
          onChange={this.handleTabChange}
          aria-label="Tabs for login and registration"
        >
          <Tab disableRipple className={tabvalue === 0 ? styles.activeTabOption : styles.tabOption} label="Login" />
          <Tab disableRipple className={tabvalue === 1 ? styles.activeTabOption : styles.tabOption} label="Register" />
        </Tabs>
        <Box className={styles.formContainer}>
          {/* Login Tab */}
          <TabPanel value={tabvalue} index={0}>
            <form className={styles.formWrapper} onSubmit={this.handleSubmit}>
              {!showVerificationCodeInput && (
                <>
                  <FormControl className={styles.formField} required>
                    <FormLabel htmlFor="email" component="legend">
                      Email
                    </FormLabel>
                    <Input
                      id="email"
                      name="email"
                      value={email}
                      onChange={this.handleEmailChange}
                      placeholder="name@email.com"
                      aria-describedby="Email address"
                    />
                  </FormControl>
                  {!!emailError && (wasFormTouched || isForgottenPassword) && (
                    <FormHelperText error={!!emailError}>{emailError}</FormHelperText>
                  )}
                  <FormControl className={styles.formField} required>
                    <FormLabel htmlFor="password" component="legend">
                      Password
                    </FormLabel>
                    <Input
                      id="password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={this.onChangePassword}
                      placeholder="Enter your password"
                      aria-describedby="Password"
                    />
                  </FormControl>
                </>
              )}
              {showVerificationCodeInput && (
                <FormControl className={styles.formField} required>
                  <FormLabel htmlFor="password" component="legend">
                    Verification code
                  </FormLabel>
                  <Input
                    id="verificationCode"
                    type="text"
                    value={verificationCode}
                    onChange={this.onChangeVerificationCode}
                    placeholder="code"
                  />
                </FormControl>
              )}
              {!showVerificationCodeInput && (
                <Box my={2} display="flex" justifyContent="center">
                  <button type="button" className={styles.activeTextButton} onClick={this.onResetPassword}>
                    Have you forgotten your password?
                  </button>
                </Box>
              )}
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={this.handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
                disabled={loading || !!emailError}
                type="submit"
              >
                {!showVerificationCodeInput ? 'Login' : 'Verify'}
              </Button>
            </form>
          </TabPanel>
          {/* Registration Tab */}
          <TabPanel value={tabvalue} index={1}>
            <form className={styles.formWrapper} onSubmit={this.handleSubmit}>
              <FormControl className={styles.formField} required>
                <FormLabel htmlFor="email" component="legend">
                  Email
                </FormLabel>
                <Input
                  id="email"
                  value={email}
                  onChange={this.handleEmailChange}
                  placeholder="name@email.com"
                  aria-describedby="Email address"
                />
                {!!emailError && wasFormTouched && <FormHelperText error={!!emailError}>{emailError}</FormHelperText>}
              </FormControl>
              <FormControl className={styles.formField} required>
                <FormLabel htmlFor="password" component="legend">
                  Password
                </FormLabel>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={this.onChangePassword}
                  placeholder="Enter your password"
                  aria-describedby="Password"
                />
                {emailIsNew && password && !!passwordError && wasFormTouched && (
                  <FormHelperText error={!!passwordError}>{passwordError}</FormHelperText>
                )}
              </FormControl>
              <FormControl className={styles.formField} required>
                <FormLabel htmlFor="validation" component="legend">
                  Confirm Password
                </FormLabel>
                <Input
                  id="validation"
                  type="password"
                  value={validation}
                  onChange={this.onChangeValidation}
                  placeholder="Re-enter your password"
                  aria-describedby="Repeat password"
                />
                {emailIsNew && validation && !!validationError && wasFormTouched && (
                  <FormHelperText error={!!validationError}>{validationError}</FormHelperText>
                )}
              </FormControl>
              <Box my={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={this.handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
                  disabled={loading || !!passwordError || !!emailError || !!validationError}
                >
                  Register
                </Button>
              </Box>
            </form>
          </TabPanel>
        </Box>
        <Box my={3}>
          <Button
            disabled={loading}
            className={styles.navigationButton}
            variant="contained"
            onClick={this.buttonTabToggle}
          >
            {isSignupScreen ? (
              <div className={styles.buttonContent}>
                <MdArrowBack />
                Go back to Login
              </div>
            ) : (
              <div className={styles.buttonContent}>
                Create a new account
                <MdArrowForward />
              </div>
            )}
          </Button>
        </Box>
        <Box style={{ opacity: 0.5, fontSize: '75%' }}>Version {currentVersion}</Box>
      </Box>
    )
  }
}

export default LoginCart
