import { Box, Button, Typography } from '@material-ui/core'
import { Clear as ClearIcon, CloudUpload as CloudUploadIcon } from '@material-ui/icons'
import type { LegacyRef } from 'react'
import React from 'react'
import { CSVReader } from 'react-papaparse'
import type { ParseError, ParseResults } from '../../../../src/types/parsing'
import { errorToast } from '../../helpers/toast'
import styles from './Uploaders.module.css'

type Props = {
  isProcessing?: boolean // in case there is ongoing async function on handleResults
  handleResults: (results: ParseResults) => any
  handleFileRemoval?: (data: null) => any
  onError?: (error: ParseError, file: any, inputElem: any, reason: string) => any
}

const csvReaderRef: LegacyRef<CSVReader<string>> = React.createRef()

const Importer = (props: Props) => {
  const { handleResults, handleFileRemoval, onError, isProcessing = false } = props

  const openDialog = (event: React.MouseEvent<HTMLElement>) => {
    // can be null, since ref is async
    if (csvReaderRef.current) {
      csvReaderRef.current.open(event)
    }
  }

  // in docs its (data: any) => any, but if you access data it's just null
  const handleFileRemovalInternal = (data: null) => data

  const onErrorInternal = (error: ParseError, file: any, inputElem: any, reason: any) => {
    errorToast(`Failed to parse file ${file.name}. ${reason}`)
  }

  const onFileRemoval = () => {
    // can be null, since ref is async
    if (csvReaderRef.current) {
      csvReaderRef.current.removeFile()
    }
  }

  return (
    <Box className={styles.draggableFileUploadContainer}>
      <CSVReader
        ref={csvReaderRef}
        config={{ encoding: 'macintosh' }}
        onDrop={handleResults}
        onError={onError || onErrorInternal}
        onRemoveFile={handleFileRemoval || handleFileRemovalInternal}
        progressBarColor="#2ECC71"
      >
        {
          // eslint-disable-next-line react/no-unused-prop-types
          ({ file }: { file: File }) => (
            // TODO: Waiting for customizable progress bar proposal: https://github.com/Bunlong/react-papaparse/issues/39
            <Box className={styles.fileUpload}>
              {!file ? (
                <React.Fragment>
                  <CloudUploadIcon color="primary" fontSize="large" />
                  <Typography variant="h4" component="h4">
                    Drag and drop a file here
                  </Typography>
                  <Typography>or</Typography>
                  <Button onClick={openDialog} disabled={isProcessing} variant="outlined" color="primary">
                    Upload file
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box display="flex" alignItems="center" my={1}>
                    <Typography variant="h4" component="h4">
                      <b>File:</b> &nbsp;
                    </Typography>
                    <Typography variant="h5" component="h5">
                      {file.name}
                    </Typography>
                  </Box>
                  <Button
                    startIcon={<ClearIcon />}
                    onClick={onFileRemoval}
                    disabled={isProcessing}
                    variant="outlined"
                    color="secondary"
                  >
                    Upload a different file
                  </Button>
                </React.Fragment>
              )}
            </Box>
          )
        }
      </CSVReader>
    </Box>
  )
}

export default Importer
