import styles from './regionSelector.module.css'

type OptionsObject = {
  label: string
  value: string
}

type Props = {
  menuTitle?: string
  selectedValue?: string
  container?: string
  titleContainer?: string
  onValueChange?: (value: any) => any
  options: Array<OptionsObject>
}

const RegionSelector = (props: Props) => {
  const { container, menuTitle, options, onValueChange, selectedValue, titleContainer } = props
  return (
    <div>
      <div className={styles[container || 'container']}>
        <div className={styles.innerButtonContainer}>
          <div className={styles[titleContainer || 'titleContainer']}>{menuTitle}</div>
          <div>
            <select className={styles.select} onChange={onValueChange} value={selectedValue}>
              {options &&
                options.map(({ label, value }, index) => (
                  <option className={styles.option} value={value} key={index}>
                    {label}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegionSelector
