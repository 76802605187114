import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import type { AddressType, RegionBasicType } from '../../../../../../src/types/common'
import type { JobAdStafferType, JobAdType } from '../../../../../../src/types/jobAds'
import type { ShiftType } from '../../../../../../src/types/jobs'
import logEvent from '../../../../helpers/logEvent'
import { getJobAdById, getJobAdStafferById } from '../../getters/jobs.legacy'
import { firestoreHttpsCallable } from './util'

type ActivitiesToFetchType = 'action-to-be-taken' | 'all' | 'all-unreviewed' | 'unseen' | string

export const StaffersJobsAPI = {
  // Called by manager to post a new job
  jobPost: async (
    jobData: {
      address: AddressType | null
      useCustomAddress?: boolean
      allNewDuties: string[]
      coordinates?: { lat: number; lng: number }
      description?: string
      duties: string[]
      employmentReason: string
      isFreelanceJob?: boolean
      isHolidayJob?: boolean
      isIdRequired?: boolean
      isBatchedInvoice?: boolean
      isTestJob: boolean
      invites?: string[]
      limitedToInvitedUntil: boolean | Date
      options: string[]
      location?: string
      placeId?: string
      jobType: string
      region?: RegionBasicType
      salaryCurrency: string
      salaryHourly: number
      shifts: ShiftType[]
      tipsPickupDay?: string
      transport?: string
      fulltimeWanted: boolean
      notifyOnlyManager: string | string[] | null
      isPublic: boolean
      publicSince?: Date
      uniform: string
      usePermanentContractWage?: boolean
      informationMessage?: string
      customLabel?: string
    },
    // used for determining selected business from group job post or on when posted by superadmin
    businessId?: string
  ) => {
    const response = await firestoreHttpsCallable('jobPostCreate', {
      ...jobData,
      businessId,
      isBatchedInvoice: jobData.isFreelanceJob,
      publicSince: jobData.publicSince && jobData.publicSince.toISOString(),
      limitedToInvitedUntil:
        typeof jobData.limitedToInvitedUntil !== 'boolean'
          ? jobData.limitedToInvitedUntil.toISOString()
          : jobData.limitedToInvitedUntil,
      // Convert shift dates into ISOStrings - RN-firebase doesn't like date objects
      shifts: jobData.shifts.map((shift) => ({
        ...shift,
        timeStart: (shift.timeStart instanceof Date ? shift.timeStart : shift.timeStart.toDate()).toISOString(),
        timeEnd: (shift.timeEnd instanceof Date ? shift.timeEnd : shift.timeEnd.toDate()).toISOString(),
      })),
    })
    if (!jobData.isPublic) {
      logEvent('job_posted_internal', {
        jobId: response.data.jobId,
        jobType: jobData.jobType,
      })
    } else {
      logEvent('job_posted', {
        jobId: response.data.jobId,
        jobType: jobData.jobType,
      })
    }
    return response
  },

  // Called by manager (or superadmin) to edit an existing job
  jobEdit: async (
    jobId: string,
    jobData: {
      address?: AddressType | null
      useCustomAddress?: boolean
      allNewDuties?: string[]
      coordinates?: { lat: number; lng: number }
      description?: string
      duties?: string[]
      options?: string[]
      employmentReason?: string
      isHolidayJob?: boolean
      invites?: string[]
      jobType?: string
      location?: string
      limitedToInvitedUntil?: boolean | Date
      placeId?: string
      positions?: number
      region?: RegionBasicType
      salaryCurrency?: string
      salaryHourly?: number
      shifts: ShiftType[]
      tipsPickupDay?: string
      fulltimeWanted?: boolean
      notifyOnlyManager?: string[] | null
      isPublic?: boolean // optional for legacy, by default all older jobs isPublic = true
      publicSince?: Date
      uniform?: string
      usePermanentContractWage?: boolean
      informationMessage?: string
      customLabel?: string
      oldWage?: number | null
      oldAddress?: string
    },
    // used for determining selected business from group job post or on when posted by superadmin
    businessId?: string
  ) => {
    const response = await firestoreHttpsCallable('jobPostEdit', {
      ...jobData,
      jobId,
      businessId,
      publicSince: jobData.publicSince && jobData.publicSince.toISOString(), // convert date to ISO
      limitedToInvitedUntil:
        typeof jobData.limitedToInvitedUntil !== 'boolean'
          ? jobData.limitedToInvitedUntil?.toISOString()
          : jobData.limitedToInvitedUntil,
      // Convert shift dates into ISOStrings - RN-firebase doesn't like date objects
      shifts:
        jobData.shifts &&
        jobData.shifts.map(({ positions, timeStart, timeEnd, breakDuration }) => ({
          breakDuration,
          positions,
          timeStart: (timeStart instanceof Date ? timeStart : timeStart.toDate()).toISOString(),
          timeEnd: (timeEnd instanceof Date ? timeEnd : timeEnd.toDate()).toISOString(),
        })),
    })
    if (response.data.events.salary_changed) {
      logEvent(`salary_changed`)
    }
    return response
  },

  jobRequestReview: async (jobId: string, shiftId: string, stafferId: string) =>
    firestoreHttpsCallable('jobRequestManagerReview', { jobId, shiftId, stafferId }),

  // Called by manager to cancel an existing job
  jobRemove: async (jobId: string) => firestoreHttpsCallable('jobPostRemove', { jobId }),

  // Called by staffer to request a job
  jobRequestCreate: async (jobId: string) => firestoreHttpsCallable('jobRequestCreate', { jobId }),

  // Called by staffer to withdraw from a job request
  jobRequestCancel: async (jobId: string) => firestoreHttpsCallable('jobRequestCancel', { jobId }),

  // Called by manager to reject a job request
  jobRequestRemove: async (jobId: string, stafferId: string) =>
    firestoreHttpsCallable('jobRequestCancel', { jobId, stafferId }),

  jobRequestDeny: async (jobId: string, stafferId: string, shiftId: string) =>
    firestoreHttpsCallable('jobRequestDeny', {
      jobId,
      stafferId,
      shiftId,
    }),

  // Called by manager to accept a job request
  jobRequestAccept: async (
    jobId: string,
    stafferId: string,
    shiftId: string,
    isUrgent = false,
    isEmployee?: boolean
  ) => {
    await firestoreHttpsCallable('jobRequestAccept', {
      jobId,
      stafferId,
      shiftId,
      isUrgent,
    })
    // Logging event: "Accepted for shift (with shift ID)"
    if (isEmployee) {
      logEvent('accepted_employee_to_shift', {
        stafferId,
        jobId,
        shiftId,
      })
    } else {
      logEvent('accepted_staffer_to_shift', {
        stafferId,
        jobId,
        shiftId,
      })
    }
  },

  // Called by staffer to confirm a job
  jobRequestConfirm: async (jobId: string) => firestoreHttpsCallable('jobRequestConfirm', { jobId }),

  // Called by staffer to clock in
  jobStafferClockIn: async (jobId: string, manually: boolean) =>
    firestoreHttpsCallable('jobStafferClockIn', {
      clockMode: manually ? 'manual' : 'qr',
      jobId,
    }),

  // Called by staffer to clock out
  jobStafferClockOut: async (jobId: string, manually: boolean) =>
    firestoreHttpsCallable('jobStafferClockOut', {
      clockMode: manually ? 'manual' : 'qr',
      jobId,
    }),

  // Called by manager to request cancellation (web)
  jobShiftCancelRequest: async (jobId: string, shiftId: string, stafferId: string, reason: string) =>
    firestoreHttpsCallable('jobShiftCancelRequest', {
      jobId,
      shiftId,
      stafferId,
      reason,
    }),

  // Called by manager to respond to cancellation (web)
  jobShiftCancelResponse: async (jobId: string, stafferId: string, shiftId: string, agreement: boolean) =>
    firestoreHttpsCallable('jobShiftCancelResponse', {
      jobId,
      shiftId,
      stafferId,
      agreement,
    }),

  // Called by manager to approve a shift
  jobStafferShiftApprove: async (jobId: string, stafferId: string, shiftIndex: number) =>
    firestoreHttpsCallable('jobShiftApprove', {
      jobId,
      shiftIndex,
      stafferId,
    }),

  // Called by manager to edit a shift
  jobStafferShiftEdit: async (jobId: string, stafferId: string, shiftIndex: number, timeIn?: Date, timeOut?: Date) =>
    firestoreHttpsCallable('jobShiftEditTime', {
      jobId,
      shiftIndex,
      stafferId,
      timeIn: timeIn && timeIn.toISOString(),
      timeOut: timeOut && timeOut.toISOString(),
    }),

  // Called by manager to end job
  jobEnd: async (jobId: string) => firestoreHttpsCallable('jobEnd', { jobId }),

  // Called by superadmin to publish unpublished job
  jobPublish: async (jobId: string) => firestoreHttpsCallable('jobPostPublish', { jobId }),

  // JOB ADS

  // Called by staffer to apply for a job ad
  jobAdRequestRequest: async (jobAdId: string) => firestoreHttpsCallable('jobAdRequestRequest', { jobAdId }),

  // Called by admin/manager contact applicant for a job ad
  jobAdRequestContact: async (jobAdId: string, stafferId: string) => {
    await firestoreHttpsCallable('jobAdRequestContact', { jobAdId, stafferId })
    logEvent('contacted_staffer', {
      jobAdId,
      stafferId,
    })
  },

  // Called by staffer to cancel application for a job ad
  jobAdRequestCancel: async (jobAdId: string) => firestoreHttpsCallable('jobAdRequestCancel', { jobAdId }),

  // Called by admin or manager to publish/unpublish the job ad from the listing
  jobAdSetIsAvailable: async (jobAdId: string, isAvailable: boolean, businessId?: string) =>
    firestoreHttpsCallable('jobAdSetIsAvailable', { jobAdId, isAvailable, businessId }),

  jobAdCreate: async (jobAdData: JobAdType & { timeStartDate: Date | null }) => {
    const response = await firestoreHttpsCallable('jobAdCreate', {
      ...jobAdData,
      timeStart:
        jobAdData.timeStartDate instanceof Date ? jobAdData.timeStartDate.toISOString() : jobAdData.timeStartDate,
    })
    logEvent('job_ad_posted', {
      jobAdId: response.data.jobAdId,
      businessId: jobAdData.businessId,
      jobType: jobAdData.jobType,
    })
    return response
  },

  jobAdEdit: async (jobAdId: string, jobAdData: Partial<JobAdType> & { timeStartDate?: Date }) =>
    firestoreHttpsCallable('jobAdEdit', {
      ...jobAdData,
      jobAdId,
      ...(jobAdData.timeStartDate
        ? {
            timeStart:
              jobAdData.timeStartDate instanceof Date ? jobAdData.timeStartDate.toISOString() : jobAdData.timeStartDate,
          }
        : jobAdData.timeStartDate === null
        ? { timeStart: null }
        : {}),
    }),

  // Called by superadmin to publish unpublished job ad
  jobAdPublish: async (jobAdId: string) => firestoreHttpsCallable('jobAdPublish', { jobAdId }),

  // Called by manager to cancel an existing job ad
  jobAdRemove: async (jobAdId: string) => firestoreHttpsCallable('jobAdRemove', { jobAdId }),

  setFitness: async (jobAdId: string, stafferId: string, fitness: 'good' | 'maybe' | 'not') => {
    const db = firebase.firestore()
    // assign to correct jobAd staffer array
    const jobAdRef = getJobAdById(db, jobAdId)
    const jobAd = await jobAdRef.get()
    const jobAdData = jobAd.data() as JobAdType
    jobAdData.staffersNew = jobAdData.staffersNew.filter((id) => id !== stafferId)
    jobAdData.staffersHired = (jobAdData.staffersHired ?? []).filter((id) => id !== stafferId)
    jobAdData.staffersContacted = (jobAdData.staffersContacted ?? []).filter((id) => id !== stafferId)
    jobAdData.staffersRejected = (jobAdData.staffersRejected ?? []).filter((id) => id !== stafferId)
    if (fitness === 'good') {
      jobAdData.staffersHired.push(stafferId)
    }
    if (fitness === 'maybe') {
      jobAdData.staffersContacted.push(stafferId)
    }
    if (fitness === 'not') {
      jobAdData.staffersRejected.push(stafferId)
    }
    const jobAdStafferRef = getJobAdStafferById(db, jobAdId, stafferId)
    const jobStafferData = (await jobAdStafferRef.get()).data() as JobAdStafferType
    if (jobStafferData.rejectionMessage) {
      // forbid change if the rejection letter was sent
      return
    }
    await jobAdRef.update(jobAdData)
    await jobAdStafferRef.update({
      status: 'evaluated',
      fitness,
    })
    // markJobAdRequestAsFinished
    const jobAdActivities = await firebase
      .firestore()
      .collection('business')
      .doc(jobAdData.businessId)
      .collection('activities')
      .where('type', '==', 'job_request')
      .where('jobAdId', '==', jobAdId)
      .where('stafferId', '==', stafferId)
      .get()

    await Promise.all(
      jobAdActivities.docs.map((doc) =>
        doc.ref.update({
          actionToBeTaken: false,
          unseenBy: [],
        })
      )
    )
  },

  sendRejectionLetter: async (jobAdId: string, stafferId: string, message: string) =>
    firestoreHttpsCallable('sendRejectionLetter', { jobAdId, stafferId, message }),

  forbidRejectionLetters: async (jobAdId: string) => {
    const db = firebase.firestore()
    const jobAdRef = getJobAdById(db, jobAdId)
    await jobAdRef.update({
      forbidRejectionLetters: true,
    })
  },

  // Called by superadmin, saves xlsx file to storage and returns formatted csv output data
  generateEventData: async (jobId: string) => firestoreHttpsCallable('generateEventData', { jobId }),

  generateSingleLinesEventData: async (jobId: string) =>
    firestoreHttpsCallable('generateSingleLinesEventData', { jobId }),

  saveJobTemplate: async (
    jobType: string,
    templateName: string,
    jobData: {
      coordinates?: { lat: number; lng: number }
      description?: string
      duties: string[]
      employmentReason: string
      isFreelanceJob?: boolean
      isHolidayJob?: boolean
      isIdRequired?: boolean
      isBatchedInvoice?: boolean
      isTestJob?: boolean
      options: string[]
      location?: string
      placeId?: string
      jobType: string
      region?: RegionBasicType
      salaryCurrency: string
      salaryHourly: number
      shifts: ShiftType[]
      tipsPickupDay?: string
      transport?: string
      fulltimeWanted: boolean
      notifyOnlyManager: string | string[] | null
      uniform: string
      usePermanentContractWage?: boolean
      customLabel?: string
    }
  ) =>
    firestoreHttpsCallable('jobTemplateSave', {
      ...jobData,
      jobType,
      templateName,
      // Convert shift dates into ISOStrings - RN-firebase doesn't like date objects
      shifts: jobData.shifts.map((shift) => ({
        ...shift,
        timeStart: shift.timeStart instanceof Date ? shift.timeStart.toISOString() : shift.timeStart,
        timeEnd: shift.timeEnd instanceof Date ? shift.timeEnd.toISOString() : shift.timeEnd,
      })),
    }),

  removeJobTemplate: async (jobType: string, templateName: string) =>
    firestoreHttpsCallable('jobTemplateRemove', {
      jobType,
      templateName,
    }),

  getGroupDashboardJobs: async () => firestoreHttpsCallable('getGroupDashboardJobs', {}),
  getGroupActivities: async (activitiesToFetch: ActivitiesToFetchType) =>
    firestoreHttpsCallable('getGroupActivities', {
      activitiesToFetch,
    }),
}

export const getInternalShifts = async () => {
  const response = await firestoreHttpsCallable('getInternalShifts', {})
  if (response.data) {
    const shifts = response.data.map((shift: ShiftType & { timeStart: number; timeEnd: number; jobType: string }) => ({
      ...shift,
      timeEnd: firebase.firestore.Timestamp.fromDate(new Date(shift.timeEnd)),
      timeStart: firebase.firestore.Timestamp.fromDate(new Date(shift.timeStart)),
    }))
    return shifts
  }
  return null
}

export const jobShiftTagForExternal = async (shiftsToUpdate: { [jobId: string]: Array<string> }) =>
  firestoreHttpsCallable('jobShiftTagForExternal', { shiftsToUpdate })
