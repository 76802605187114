import AwesomeDebouncePromise from 'awesome-debounce-promise'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import type { ChangeEvent } from 'react'
import React, { Component } from 'react'
// @ts-ignore react-firestore-connect has no @types/ export
import { connectFirestore } from 'react-firestore-connect'
import { BeatLoader } from 'react-spinners'
// @ts-ignore react-phone-input-2 has no @types/ export
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'
import type { CountryData } from 'react-phone-input-2'
import PhoneInput from 'react-phone-input-2'
import { toast } from 'react-toastify'
import type { BusinessType } from '../../../../src/types/business'
import type { InviteType } from '../../../../src/types/common'
import type { Firestore } from '../../../../src/types/firebase'
import type { EnumJobType } from '../../../../src/types/jobTypes'
import type { PermissionsType } from '../../../../src/types/pools'
import type { StafferType } from '../../../../src/types/staffer'
import { TooltipDelays } from '../../constants/tooltips'
import { fixCountryDialCode, standardized } from '../../helpers/phoneNumbers'
import { errorToast } from '../../helpers/toast'
import { firestoreHttpsCallable } from '../../staffers/api/firestore/https/util'
import { getBusinessById } from '../../staffers/api/getters/business.legacy'
import { getJobTypes } from '../../staffers/api/getters/common.legacy'
import { getInvitedStafferByCode } from '../../staffers/api/getters/pools.legacy'
import { getStafferPermissions, getStaffersByPhone } from '../../staffers/api/getters/staffer.legacy'
import muiModalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import PoolPermissionsItem from './EditPermissions/PoolPermissionItem'

export type StafferInfo = {
  name: string
  phone: string
  positions: string[]
  email?: string
  existingStaffer?: string
  isNewImport?: boolean
  stafferId?: string
}

type EditingStaffer = {
  isEditingStaffer: boolean
  stafferInfo: StafferInfo
}

type CreatingStaffer = {
  isEditingStaffer?: never
  stafferInfo?: never
}

type Props = {
  business?: BusinessType
  closeModal: VoidFunction
  code?: string
  existingInvite: InviteType | null
  jobTypes?: {
    id: 'jobTypes'
    values: Array<EnumJobType>
  } | null
  isAlreadyInvited?: boolean
  isExternalHire?: boolean
  loadingCallback: (value: boolean) => void
  businessId: string
  stafferPhone?: string
  stafferName?: string
} & (CreatingStaffer | EditingStaffer)

type State = {
  customMessage: string
  email: string
  existingStaffer?: string // name of staffer with given phone number already in system (if any)
  hasConfirmedDialog: boolean
  isConfirmationDialogOpen: boolean
  isFormValid: boolean
  isProcessing: boolean
  multipleStaffers: Array<{
    existingStaffer: string
    skillLevels?: Record<string, string> | undefined
    customWage?: number
    contractPercentage?: number
    selectedPositions?: string[]
  } | null> | null
  name: string
  phone: string
  selectedPositions: Array<string>
  sendSms: boolean
  stafferId: string | null
  useCustomMessage: boolean
} & Partial<StafferType>

class InviteToFavoritePool extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { existingInvite, stafferInfo, stafferPhone, stafferName } = props

    const existingStaffer = (stafferInfo && stafferInfo.existingStaffer) || undefined
    const name = (stafferInfo && stafferInfo.name) || (existingInvite && existingInvite.name) || ''
    const phone = (stafferInfo && stafferInfo.phone) || (existingInvite && existingInvite.phone) || ''
    const isFormValid = !!(existingStaffer || name.length > 3) && standardized(phone).length > 9

    this.state = {
      customMessage: '',
      email: (stafferInfo && stafferInfo.email) || (existingInvite && existingInvite.email) || '',
      existingStaffer,
      hasConfirmedDialog: false,
      isConfirmationDialogOpen: false,
      isFormValid,
      isProcessing: false,
      multipleStaffers: null,
      name: stafferName || '',
      phone: stafferPhone || stafferInfo?.phone || '',
      selectedPositions: stafferInfo ? stafferInfo.positions : existingInvite ? existingInvite.positions : [],
      sendSms: false,
      stafferId: (stafferInfo && stafferInfo.stafferId) || null,
      useCustomMessage: false,
    }
  }

  toggleConfirmationDialog = () =>
    this.setState((prevState) => ({
      isConfirmationDialogOpen: !prevState.isConfirmationDialogOpen,
    }))

  confirmEmployeePositionsAssignment = () =>
    this.setState(
      {
        hasConfirmedDialog: true,
      },
      async () => {
        this.toggleConfirmationDialog()
        await this.addStafferToFavoritePool()
      }
    )

  toggleSendSms = () =>
    this.setState((prevState) => ({
      sendSms: !prevState.sendSms,
    }))

  toggleCustomMessage = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ useCustomMessage: e.target.checked })
  }

  handleCustomMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ customMessage: e.target.value })
  }

  changeName = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    this.setState(
      {
        name: value,
      },
      this.validate
    )
  }

  changePhone = async (phone: string, countryData: Partial<CountryData>) => {
    const realPhone = fixCountryDialCode(phone, countryData.dialCode)
    if (phone) {
      this.setState(
        {
          phone: realPhone,
        },
        this.validate
      )
      this.checkExistingStafferWithPhone(realPhone)
    }
  }

  // check whether staffer with this phone is already registered
  checkExistingStafferWithPhone = AwesomeDebouncePromise(async (phone: string) => {
    const database = firebase.firestore() as Firestore
    const existingStaffers = await getStaffersByPhone(database, standardized(phone)).get()
    if (existingStaffers.docs.length > 0) {
      if (existingStaffers.docs.length === 1) {
        const staffer = existingStaffers.docs[0].data() as StafferType
        const stafferPermissionsDoc = await getStafferPermissions(database, staffer.userId).get()
        const stafferPermissions = stafferPermissionsDoc.data() as PermissionsType | null
        const { nameFirst, nameLast } = staffer
        if (stafferPermissions) {
          const { positions } = stafferPermissions
          this.setState(
            {
              existingStaffer: `${nameFirst} ${nameLast}`,
              selectedPositions: positions || [],
              stafferId: existingStaffers.docs[0].id,
            },
            this.validate
          )
        } else {
          this.setState(
            {
              existingStaffer: `${nameFirst} ${nameLast}`,
            },
            this.validate
          )
        }
        // Corner case - staffers with same phone (shouldn't be possible anymore, but legacy)
      } else {
        const multipleStaffers = await Promise.all(
          existingStaffers.docs.map(async (doc) => {
            const staffer = doc.data() as StafferType
            const { nameFirst, nameLast } = staffer
            const stafferPermissions = (await getStafferPermissions(database, doc.id)
              .get()
              .then((snap) => snap.data())) as PermissionsType
            if (stafferPermissions) {
              const { positions } = stafferPermissions
              return {
                existingStaffer: `${nameFirst} ${nameLast}`,
                selectedPositions: positions || [],
                stafferId: doc.id,
              }
            }
            return null
          })
        ).then((result) => result.filter((staffer) => !!staffer))
        if (multipleStaffers.length > 0) {
          toast.warn(`Found multiple staffers with the phone: ${phone}. Please select the one you want to invite`)
          this.setState({
            multipleStaffers,
          })
        } else {
          errorToast(
            'Found multiple profiles, but missing permission docs for all of them. Likely a testing DB inconsistency'
          )
        }
      }
    }
  }, 500)

  validate = () => {
    const { phone, name, existingStaffer } = this.state
    this.setState({
      isFormValid: !!(existingStaffer || name.length > 3) && standardized(phone).length > 9,
    })
  }

  getTooltip = () => {
    const { phone, name, existingStaffer } = this.state
    if (!existingStaffer && name.length <= 3) {
      return 'Please fill the name first'
    }
    if (standardized(phone).length <= 9) {
      return 'Please fill the phone first'
    }
    return ''
  }

  togglePosition = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target
    this.setState(
      (prevState) => ({
        selectedPositions: prevState.selectedPositions.includes(name)
          ? prevState.selectedPositions.filter((position) => position !== name)
          : [...prevState.selectedPositions, name],
      }),
      this.validate
    )
  }

  saveEditedStaffer = async () => {
    const { closeModal, stafferInfo } = this.props
    this.setState({ isProcessing: true })
    try {
      await firestoreHttpsCallable('editStafferPermissions', {
        stafferId: this.state.stafferId,
        positions: this.state.selectedPositions,
      })

      // Success toast
      toast.success(`${stafferInfo?.name} has been updated!`)
      closeModal()
    } catch (error: unknown) {
      errorToast((error as Error).message)
      console.error('[editFavoriteStaffer] edit error: ', (error as Error).message)
    } finally {
      this.setState({ isProcessing: false })
    }
  }

  addStafferToFavoritePool = async () => {
    const { businessId, closeModal, code, loadingCallback, existingInvite } = this.props

    const {
      customMessage,
      email,
      existingStaffer,
      hasConfirmedDialog,
      name,
      phone,
      selectedPositions,
      sendSms,
      stafferId,
      useCustomMessage,
    } = this.state

    if (selectedPositions.length === 0 && !hasConfirmedDialog) {
      this.toggleConfirmationDialog()
      return
    }

    this.setState({
      isProcessing: true,
    })

    // just in case invoking like this to prevent unwanted early return
    const invokeLoadingCallback = (value: boolean) => {
      if (loadingCallback) {
        loadingCallback(value)
      }
    }

    try {
      invokeLoadingCallback(true)
      await firestoreHttpsCallable('inviteFavoriteStaffer', {
        code,
        customMessage: useCustomMessage && !!customMessage ? customMessage : '',
        email,
        name,
        positions: [...(selectedPositions || [])],
        phone: standardized(phone),
        sendSms,
        stafferId,
        businessId,
      })

      // Success toast
      const successMessage = existingInvite
        ? `${existingStaffer ?? name}'s info has been updated!`
        : `${existingStaffer ?? name} was ${!existingStaffer ? 'invited' : 'added to the favorite pool'}!`
      toast.success(successMessage)
      closeModal()
    } catch (error: unknown) {
      errorToast((error as Error).message)
      console.error('[inviteStafferToPool] invite error: ', (error as Error).message)
    } finally {
      this.setState({ isProcessing: false })
      invokeLoadingCallback(false)
    }
  }

  renderPhoneInput = () => (
    <PhoneInput
      inputProps={{
        autoFocus: true,
      }}
      autoFormat
      country={this.props.business?.region?.countryName === 'Sweden' ? 'se' : 'no'}
      disabled={!!this.props.existingInvite || this.props.isEditingStaffer}
      enableAreaCodes
      enableLongNumbers
      inputStyle={{ width: '100%', border: 'none' }}
      key="phone"
      onChange={(phone, countryData) => this.changePhone(phone, countryData)}
      value={this.state.phone}
    />
  )

  onSelectStaffer = (event: { target: { value: StafferType } }) => {
    const { value: staffer } = event.target
    this.setState(
      {
        ...staffer,
        multipleStaffers: null,
      },
      this.validate
    )
  }

  render() {
    const {
      closeModal,
      existingInvite,
      jobTypes,
      isAlreadyInvited = false,
      isExternalHire,
      isEditingStaffer,
      stafferPhone,
      stafferName,
    } = this.props

    const {
      customMessage,
      existingStaffer,
      isConfirmationDialogOpen,
      isFormValid,
      isProcessing,
      multipleStaffers,
      name,
      phone,
      selectedPositions,
      sendSms,
      useCustomMessage,
    } = this.state

    const jobTypePositions = (jobTypes && jobTypes.values) || []
    const isMultipleMatches = !!(multipleStaffers && multipleStaffers.length > 0)
    const disabledFullName = !!existingStaffer || !!existingInvite || !!isExternalHire || isMultipleMatches

    if (stafferPhone && !existingStaffer) {
      this.checkExistingStafferWithPhone(stafferPhone)
      return <BeatLoader className={muiModalStyles.beatLoader} />
    }

    return (
      <React.Fragment>
        <ModalHeader close={closeModal}>
          {isEditingStaffer ? 'Edit staffer' : 'Invite to your favorite pool'}
        </ModalHeader>
        {isAlreadyInvited && (
          <Box p={3}>
            <Alert severity="warning">{`Staffer ${name} with phone: ${phone} has already been invited`}</Alert>
          </Box>
        )}
        <Box p={3} className={muiModalStyles.modalContent}>
          <Grid container spacing={2}>
            {isMultipleMatches && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  <AlertTitle>Multiple staffers found</AlertTitle>
                  Found multiple staffers with the same phone numbers. Please{' '}
                  <strong>select which one you want to invite!</strong>
                  <FormControl fullWidth>
                    <InputLabel>Select who to invite</InputLabel>
                    {/* @ts-ignore MUI Select has incorrectly typed onChange */}
                    <Select onChange={this.onSelectStaffer}>
                      {multipleStaffers.map((staffer) => (
                        // MUI MenuItem is typed to string | number | readonly string[] | undefined
                        // @ts-ignore
                        <MenuItem key={staffer?.existingStaffer || ''} value={staffer}>
                          {staffer?.existingStaffer || ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Alert>
              </Grid>
            )}
            {!stafferPhone && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Add phone number, full name, and positions that match their experience.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={!!isExternalHire || isMultipleMatches || isEditingStaffer}
                    fullWidth
                    label="Phone"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: this.renderPhoneInput,
                    }}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
            {!stafferName && (
              <Grid item xs={12}>
                <TextField
                  disabled={disabledFullName || isEditingStaffer}
                  fullWidth
                  label="Name"
                  onChange={this.changeName}
                  placeholder="Full name"
                  value={existingStaffer || name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                disabled={isEditingStaffer}
                control={<Switch checked={sendSms} onChange={this.toggleSendSms} name="sendSms" color="primary" />}
                label="Send sms to the newly invited staffer"
              />
              {!sendSms && (
                <Box mt={1}>
                  <Alert severity="info">
                    Activate toggle to send out an invite SMS. By having the toggle off, you can add positions to your
                    employee now and invite them later.
                  </Alert>
                </Box>
              )}
              {sendSms && (
                <Box display="flex" flexDirection="column">
                  <FormControlLabel
                    control={<Checkbox checked={useCustomMessage} onChange={this.toggleCustomMessage} />}
                    label="Use a custom message"
                  />
                  {useCustomMessage && (
                    <Box textAlign="center">
                      <TextareaAutosize
                        aria-label="custom-sms"
                        onChange={this.handleCustomMessageChange}
                        placeholder="Write custom message here..."
                        style={{ width: 300, height: 80 }}
                        value={customMessage}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
          <Box mt={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h3">
                  What jobs do you recommend for this person?
                </Typography>
              </Grid>
              {jobTypePositions.length > 0 && (
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>Public positions</Typography>
                  </Grid>
                  <Grid container item spacing={3}>
                    {jobTypePositions.map((position, index) => (
                      <PoolPermissionsItem
                        currentSkillLevel=""
                        index={index}
                        isChecked={selectedPositions.includes(position.name)}
                        isProcessing={isProcessing}
                        key={index}
                        position={position.name}
                        togglePosition={this.togglePosition}
                      />
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <Box p={3}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={4}>
              {isProcessing ? (
                <Box textAlign="center">
                  <BeatLoader />
                </Box>
              ) : (
                <Tooltip
                  disableFocusListener={isFormValid}
                  disableHoverListener={isFormValid}
                  disableTouchListener={isFormValid}
                  leaveDelay={TooltipDelays.WITHOUT_LINKS}
                  title={this.getTooltip()}
                >
                  <div>
                    <Button
                      color="primary"
                      disabled={!isFormValid || isProcessing || isMultipleMatches}
                      fullWidth
                      onClick={isEditingStaffer ? this.saveEditedStaffer : this.addStafferToFavoritePool}
                      variant="contained"
                    >
                      {(isEditingStaffer && 'Save edited staffer') ||
                        (existingStaffer && 'Add to favorite pool') ||
                        (!sendSms && 'Save invitation') ||
                        `${existingInvite ? 'Resend' : 'Send'} invitation`}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={isConfirmationDialogOpen}
          onClose={this.toggleConfirmationDialog}
          aria-label="Confirm employee position assignment"
        >
          <DialogTitle>{`Allow position assignment for ${name}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`You have not assigned any positions to ${name}.
                This means upon accepting the invite the employee will be able to manually select which positions he/she will have access to.

                Are you sure you want to continue?
              `}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleConfirmationDialog} color="secondary">
              Go back
            </Button>
            <Button onClick={this.confirmEmployeePositionsAssignment} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default connectFirestore(
  (db: Firestore, { businessId, code }: Props) => ({
    business: getBusinessById(db, businessId),
    existingInvite: code ? getInvitedStafferByCode(db, code) : null,
    jobTypes: getJobTypes(db),
  }),
  (props: Props) => {
    if (props.existingInvite === undefined || props.jobTypes === undefined) {
      return (
        <Box p={3} textAlign="center">
          <BeatLoader color="gray" />
        </Box>
      )
    }
    return <InviteToFavoritePool {...props} />
  }
)
