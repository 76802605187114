/* eslint-disable react/no-multi-comp */
import React, { type ReactNode } from 'react'
import type { Integration } from '../../../../src/types/integrations'
import { errorToast } from '../../helpers/toast'
import integrationsAPI from '../../staffers/api/integrations'
import IntegrationReauth from '../modals/IntegrationReauth'

export type IntegrationsCtx = {
  list?: Array<Integration> | null // undefined means not fetched yet, null is bad usage of the provier
  loading: boolean
  refetchList: () => Promise<any>
  get: (endpoint: string, payload?: Record<string, any>) => Promise<any>
  post: (endpoint: string, payload?: Record<string, any>) => Promise<any>
  put: (endpoint: string, payload?: Record<string, any>) => Promise<any>
}

const IntegrationsContext = React.createContext<IntegrationsCtx>({
  list: null,
  loading: false,
  refetchList: async () => {
    console.error('Integrations context not provided')
  },
  get: async () => {
    console.error('Integrations context not provided')
  },
  post: async () => {
    console.error('Integrations context not provided')
  },
  put: async () => {
    console.error('Integrations context not provided')
  },
})

type Props = {
  businessId?: string
  children: ReactNode
}
type State = {
  list?: Array<Integration>
  integrationToReauth?: Integration
  reauthReason: string
  loading: boolean
}

class IntegrationsProvider extends React.Component<Props, State> {
  state = {
    list: undefined,
    integrationToReauth: undefined,
    reauthReason: '',
    loading: false,
  }

  refetchList = async (supressErrorToast?: boolean) => {
    this.setState({ loading: true })
    try {
      const { integrations, status, title, detail } = await integrationsAPI.get('/integrations/')
      if (status !== 200) {
        throw Error(`${title || ''} ${detail}`)
      }
      this.setState({
        list: integrations,
      })
    } catch (e) {
      if (!supressErrorToast) {
        errorToast(`Failed to fetch integrations: ${(e as Error)?.message || e}`)
      }
      console.error('Failed to fetch integrations', e)
    } finally {
      this.setState({ loading: false })
    }
  }

  openReauthModal = (name: string, detail: string) => {
    const { list = [] } = this.state
    const integrationToReauth = list.find((integration: Integration) => integration.name === name)
    this.setState({
      integrationToReauth,
      reauthReason: detail,
    })
  }

  closeReauthModal = () => {
    this.setState({
      integrationToReauth: undefined,
      reauthReason: '',
    })
  }

  apiWrapper = (method: 'get' | 'post' | 'put') => async (endpoint: string, payload?: Record<string, any>) => {
    const response = await integrationsAPI[method](endpoint, payload)
    const [prefix, intName] = endpoint.replace(/^\//, '').split('/')
    if (prefix === 'integrations') {
      if (
        (response?.status === 401 && response.code.startsWith('unauthorized_to_')) || // eg. 'unauthorized_to_planday'
        (response?.status === 403 && response.code === 'missing_scopes')
      ) {
        this.openReauthModal(intName, response.detail)
      }
    }
    if (response?.status >= 400) {
      const detailError =
        response.code === 'invalid'
          ? Object.entries((response.errors as Record<string, string[]>) || {})
              .map(([key, msgs]) => `${key}: ${msgs.join(' ')}`)
              .join('\n')
          : response.detail
      throw Error(`${response.title ? `${response.title}: ` : ''}${detailError}`)
    }
    return response
  }

  apiGet = this.apiWrapper('get')
  apiPost = this.apiWrapper('post')
  apiPut = this.apiWrapper('put')

  componentDidMount() {
    this.refetchList(true)
  }

  componentDidUpdate({ businessId: prevBusinessId }: Props) {
    if (prevBusinessId !== this.props.businessId) {
      this.refetchList()
    }
  }

  render() {
    const { children } = this.props
    const { list, integrationToReauth, reauthReason, loading } = this.state

    const integrationsCtx = {
      list,
      loading,
      refetchList: this.refetchList,
      get: this.apiGet,
      post: this.apiPost,
      put: this.apiPut,
    }

    return (
      <IntegrationsContext.Provider value={integrationsCtx}>
        {children}
        {integrationToReauth && (
          <IntegrationReauth
            integration={integrationToReauth}
            reason={reauthReason}
            onClose={this.closeReauthModal}
            integrationsCtx={integrationsCtx}
          />
        )}
      </IntegrationsContext.Provider>
    )
  }
}

export default IntegrationsProvider

// wrapper which provides list of integrations as prop for the component
// must be used nested in the IntegrationsProvider
// eslint-disable-next-line react/display-name
export const withIntegrations = (Component: React.ComponentType<any>) => (props: any) => (
  <IntegrationsContext.Consumer>
    {(integrationsCtx: IntegrationsCtx) =>
      integrationsCtx.list === null ? (
        (console.error('withIntegrations() must be used within the <IntegrationsProvider>'), null)
      ) : (
        <Component integrationsCtx={integrationsCtx} {...props} />
      )
    }
  </IntegrationsContext.Consumer>
)
