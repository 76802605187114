import type { Dayjs } from 'dayjs'
import type { Moment } from 'moment'
import _moment from 'moment'
import type { Timestamp } from '../../../src/types/firebase'

export const toMillis = (time: Date | Timestamp | number | Dayjs): number =>
  (time as Timestamp).toMillis ? (time as Timestamp).toMillis() : +(time as Date)

// This wrapper just ensures that we don't pass Timestamp directly to moment
// null is intentionally not allowed
// We should always import this instead of 'moment'
const moment = (time?: Timestamp | Date | number | undefined | string | Moment | Dayjs, ...rest: any): Moment =>
  _moment.isMoment(time)
    ? _moment(time as Moment)
    : _moment(time && (typeof time === 'string' ? time : toMillis(time)), ...rest)

moment.duration = _moment.duration
moment.utc = _moment.utc
moment.isDate = _moment.isDate

export default moment
