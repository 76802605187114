import type { SvgIcon } from '@material-ui/core'
import {
  Add,
  Assignment,
  Create,
  DeviceHub,
  Email,
  Extension,
  Money,
  People,
  PeopleOutline,
  PersonAdd,
  Public,
  Settings,
  StarOutline,
  StarRate,
  Timer,
  WorkOutline,
} from '@material-ui/icons'
import type { ReactElement, ReactNode } from 'react'
import type { BusinessType } from '../../../../../src/types/business'
import type { GroupJobTypesType } from '../../../../../src/types/groups'
import type { PoolType } from '../../../../../src/types/pools'
import { SIDEBAR_PATHS } from '../../../constants/routes'
import { hasPermission } from '../../../helpers/permissions'
import SidebarActionItem from './SidebarActionItem'
import SidebarItem from './SidebarItem'

export type IconType = ReactElement<typeof SvgIcon>

/* -------------------------------------------------------------------------- */
/*                        1. Default sidebar item type                        */
/* -------------------------------------------------------------------------- */

export type ItemType = {
  name: string // rendered title + state reference
  icon: IconType // icon of the button
  path?: string // redirect url
  altPaths?: string[] // akternative paths the active item can have
  badge?: number | boolean // number shown in the badge (eg. unseen requests)
  collapsibleItems?: ItemType[] // items that can be collapsed
  hidden?: boolean // override, if condition is met does not render
}

/* eslint-disable dot-notation */
/**
 * Generates item objects to help with the render and state
 */
export const getSidebarItems = (
  badges: {
    unseenTemporary: number
    unseenFreelance: number
    unseenJobAds: number
    unreadMessages: number
    unresolvedFreelanceJobs: number
    unseenTemporaryCancellations: number
    unseenFreelanceCancellations: number
    unresolvedTempJobs: number
    integrations?: boolean
  },
  pool: PoolType | null,
  business: BusinessType,
  hasExternalJob?: boolean,
  hasTempJob?: boolean,
  hasEventJob?: boolean
): ItemType[] => [
  {
    name: SIDEBAR_PATHS['Company Profile'].name,
    path: SIDEBAR_PATHS['Company Profile'].path,
    altPaths: SIDEBAR_PATHS['Company Profile'].altPaths,
    icon: <WorkOutline />,
  },
  {
    name: SIDEBAR_PATHS['People'].name,
    icon: <PeopleOutline />,
    path: SIDEBAR_PATHS['People'].path,
    altPaths: SIDEBAR_PATHS['People'].altPaths,
    collapsibleItems: pool
      ? [
          {
            name: SIDEBAR_PATHS['Internal'].name,
            path: SIDEBAR_PATHS['Internal'].path,
            icon: <People />,
          },
          {
            name: SIDEBAR_PATHS['Invites'].name,
            path: SIDEBAR_PATHS['Invites'].path,
            icon: <Email />,
          },
          {
            name: SIDEBAR_PATHS['External'].name,
            path: SIDEBAR_PATHS['External'].path,
            icon: <Public />,
            hidden: !hasExternalJob,
          },
          {
            name: SIDEBAR_PATHS['Favorites'].name,
            path: SIDEBAR_PATHS['Favorites'].path,
            icon: <StarOutline />,
          },
          {
            name: SIDEBAR_PATHS['Contracts'].name,
            path: SIDEBAR_PATHS['Contracts'].path,
            icon: <Create />,
          },
        ]
      : [
          // {
          //   name: SIDEBAR_PATHS['People'].name,
          //   path: SIDEBAR_PATHS['People'].path,
          //   icon: <PeopleOutline />,
          // },
          {
            name: SIDEBAR_PATHS['External'].name,
            path: SIDEBAR_PATHS['External'].path,
            icon: <Public />,
            hidden: !hasExternalJob,
          },
          {
            name: SIDEBAR_PATHS['Favorites'].name,
            path: SIDEBAR_PATHS['Favorites'].path,
            icon: <StarOutline />,
          },
        ],
  },
  {
    name: SIDEBAR_PATHS['Contracts'].name,
    path: SIDEBAR_PATHS['Contracts'].path,
    icon: <Create />,
    hidden: !!pool,
  },
  {
    name: SIDEBAR_PATHS['Jobs'].name,
    icon: <Assignment />,
    badge:
      badges.unseenTemporary +
      badges.unseenFreelance +
      badges.unseenJobAds +
      badges.unresolvedTempJobs +
      badges.unresolvedFreelanceJobs +
      badges.unseenTemporaryCancellations +
      badges.unseenFreelanceCancellations,
    altPaths: SIDEBAR_PATHS['Jobs'].altPaths,
    collapsibleItems: [
      {
        name: 'Shifts',
        path: SIDEBAR_PATHS['Shifts'].path,
        icon: <Timer />,
        badge:
          badges.unseenTemporary +
          badges.unresolvedTempJobs +
          badges.unseenTemporaryCancellations +
          badges.unseenFreelance +
          badges.unresolvedFreelanceJobs +
          badges.unseenFreelanceCancellations,
        hidden:
          !pool &&
          !hasTempJob &&
          !hasPermission(business, 'postTemporaryJob') &&
          !hasEventJob &&
          !hasPermission(business, 'postFreelanceJob'),
      },
      {
        name: SIDEBAR_PATHS['Job ads'].name,
        path: SIDEBAR_PATHS['Job ads'].path,
        icon: <DeviceHub />,
        badge: badges.unseenJobAds,
        hidden: !hasPermission(business, 'postJobAd'),
      },
    ],
  },
  {
    name: pool ? SIDEBAR_PATHS['Payrolls'].name : 'Invoice',
    icon: <Money />,
    path: SIDEBAR_PATHS['Payrolls'].path,
    altPaths: SIDEBAR_PATHS['Payrolls'].altPaths,
  },
  {
    name: SIDEBAR_PATHS['Settings'].name,
    icon: <Settings />,
    path: SIDEBAR_PATHS['Settings'].path,
  },
  {
    name: SIDEBAR_PATHS['Integrations'].name,
    icon: <Extension />,
    badge: badges.integrations,
    path: SIDEBAR_PATHS['Integrations'].path,
    altPaths: SIDEBAR_PATHS['Integrations'].altPaths,
  },
]

/**
 * Renders individual sidebar items
 * @param {*} items Individual items from getSidebarItems()
 * @param {*} activeButton state - currently active item
 * @param {*} isSidebarOpen state - if sidebar is collapsed
 * @param {*} expandedItems state - currently expanded items
 * @param {*} toggleExpandableItem method - toggle handler
 * @param {*} shouldCompleteProfile boolean - decides whether all actions open complete profile modal
 * @param {*} toggleCompleteProfileModal method - toggle handler
 * @param {*} restProps optional - some custom props you might wish to pass to all items
 * @returns Array of rendered `<SidebarItem />`s
 */
export const renderSidebarItems = (
  items: ItemType[], // contains fields required per item to render
  activeItem: { activeParent: string; activeChild?: string }, // state - currently active item
  isSidebarOpen: boolean, // state - if sidebar is collapsed
  expandedItems: { [buttonName: string]: boolean }, // state - currently expanded items
  toggleExpandableItem?: (buttonName: string) => void, // method - toggle handler
  isCollapsibleChild?: boolean
): ReactNode[] =>
  items
    .filter(({ hidden }) => !hidden)
    .map(({ name, icon, path, collapsibleItems, badge }) => (
      <SidebarItem
        key={name}
        name={name}
        icon={icon}
        path={path}
        collapsibleItems={collapsibleItems}
        badge={badge}
        toggleExpandableItem={toggleExpandableItem}
        activeItem={activeItem}
        isSidebarOpen={isSidebarOpen}
        expandedItems={expandedItems}
        isCollapsibleChild={isCollapsibleChild}
      />
    ))

/* -------------------------------------------------------------------------- */
/*                         2. Action sidebar item type                        */
/* -------------------------------------------------------------------------- */

export type ActionItemType = {
  modalId: string // should match the isModalOpen in Sidebar.js
  name: string // displayed text
  icon?: IconType // Icon to be displayed in collapsed items
  isHighlighted?: boolean // Display green color border to highlight the item for user
  hidden?: boolean // override - does not render if condition is met
}

export const getActionItems = (
  pool: PoolType | null,
  activeItem: { activeParent: string; activeChild?: string },
  business: BusinessType,
  groupId?: string,
  groupJobTypes?: GroupJobTypesType
): ActionItemType[] => {
  const customPositions = (groupJobTypes && groupJobTypes.values) || []
  return [
    {
      modalId: 'createInternalJob',
      name: 'Create Internal Job',
      icon: <Add color="primary" />,
      isHighlighted: true,
      hidden: !!(
        pool === null ||
        (business.businessType === 'Other' &&
          ((groupId && !customPositions) || (groupId && customPositions && customPositions.length === 0)))
      ),
    },
    // Fake variant of create job - requires you to create position
    // displays when businessType is Other and has no custom position set up
    {
      modalId: 'createCustomPosition',
      name: 'Create Internal Job',
      icon: <Add color="primary" />,
      isHighlighted: true,
      // Do not display the button until the groupJobTypes are fetched (either something or null)
      hidden: !!(
        pool === null ||
        groupJobTypes === undefined ||
        business.businessType !== 'Other' ||
        !groupId ||
        (groupId && customPositions && customPositions.length > 0)
      ),
    },
    {
      modalId: 'createExternalJob',
      name: pool === null ? 'Create Job' : 'Create External Job',
      icon: <Add color="primary" />,
      isHighlighted: true,
      hidden: !(
        // we dont check 'postTemporaryJob' here, because those no longer can be external
        (hasPermission(business, 'postFreelanceJob') || hasPermission(business, 'postJobAd'))
      ),
    },
    {
      modalId: 'inviteToPool',
      name: 'Invite an Employee',
      icon: <PersonAdd color="primary" />,
      hidden: pool === null || activeItem.activeParent !== 'People',
    },
    {
      modalId: 'inviteToFavoritePool',
      name: 'Invite to Favorite Pool',
      icon: <StarRate color="primary" />,
      hidden: activeItem.activeParent !== 'People',
    },
  ]
}

export const renderActionItems = (
  actionItems: ActionItemType[],
  isSidebarOpen: boolean,
  shouldCompleteProfile: boolean,
  toggleModal: (modalId: string) => void
): ReactNode[] =>
  actionItems
    .filter(({ hidden }) => !hidden)
    .map(({ name, modalId, icon, isHighlighted }) => (
      <SidebarActionItem
        key={name}
        name={name}
        modalId={modalId}
        // Job postings etc
        isHighlighted={!!isHighlighted}
        isSidebarOpen={isSidebarOpen}
        icon={icon}
        shouldCompleteProfile={shouldCompleteProfile}
        toggleModal={toggleModal}
      />
    ))
