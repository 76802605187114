import * as R from 'ramda'
import { toast } from 'react-toastify'
import { findBestMatch } from 'string-similarity'
import type { ImportHeaderMatch, ImportHeaderObject, ImportStafferType, ParseResults } from '../../../src/types/parsing'
import { LEGACY_POSITION_TRANSLATIONS, STAFFER_IMPORT_TRANSLATIONS } from '../constants/importing'
import { standardized } from './phoneNumbers'
import { errorToast } from './toast'

type HeaderMatchType = {
  key: string
  translations: string | string[]
  rating: number
  value: string
}

/**
 * Parses imported csv data for staffers and generates formatted staffer objects
 * @param {ParseResults} csv - CSV Parsing results
 * @param {boolean} enforceLength - Optional (true), makes sure fields are same length as header
 * @returns {Array<Object> | null} - Null if parsing failed, otherwise array of formatted objects
 */
export const parseCsv = (csv: ParseResults, enforceLength = false): Array<Record<string, any>> | null => {
  try {
    if (!csv || csv.length === 0) {
      throw Error("Parsing failed: Didn't receive any data. Check if you uploaded correctly file")
    }

    // gets header array and arrays of imported staffer data
    const [possibleHeader, ...possibleData] = csv.map((csvLine) => csvLine.data)
    const isTamigoImport = !!(possibleHeader && possibleHeader.length === 13)
    // Special corner case - Tamigo imports contain two unnecessary rows we slice out
    const header = isTamigoImport ? possibleData[2] : possibleHeader
    const data = isTamigoImport ? R.takeLast(possibleData.length - 3, possibleData) : possibleData
    // transforms data to Array<{ [header[i]]: value }>
    const formattedOutput = data.map((dataLine) =>
      R.mergeAll(
        dataLine.map((value, index) => ({
          [String(header[index] || '').toLowerCase()]: typeof value === 'string' ? R.trim(value) : value,
        }))
      )
    )

    // We enforce that every data line has same amount of data required by header
    if (enforceLength) {
      const dataLengthsValidity = formattedOutput.map((dataObject, index) => {
        const isSameLength = R.keys(dataObject).length === header.length
        if (!isSameLength) {
          // index + 2 because of indexing from 0 (+1) & header row (+1)
          errorToast(`Line [${index + 2}] has incorrect amount of data. Check console for more info`)
          console.error(`Error parsing line ${index + 2}, provided data: `, dataObject)
          return false
        }
        return true
      })

      if (!dataLengthsValidity.every((checkResult) => checkResult)) {
        throw Error('Parsing Failed: Incorrect amount of data')
      }
    }

    return formattedOutput
  } catch (error: any) {
    console.error(error.message)
    return null
  }
}

/**
 * Takes parsed csv data and transforms into to { name, phone, email, isNewImport } invite docs
 * @param {*} parsedData - Output from parseCsv function
 * @returns null if missing mandatory parameters, otherwise formatted inviteDoc
 */
export const getStafferInviteData = (parsedData: Array<Record<string, any>>): Array<ImportStafferType> | null => {
  // eslint-disable-line max-len
  const isTamigoImport = !!(parsedData[0] && parsedData[0].navn)

  const stafferInviteDocs = (isTamigoImport ? R.takeLast(parsedData.length, parsedData) : parsedData).map(
    (stafferData, index): ImportStafferType | null => {
      // This will filter out last empty line added by invalid editing
      if (Object.keys(stafferData).length <= 2) {
        return null
      }
      const name = isTamigoImport ? stafferData.navn : `${stafferData.fornavn} ${stafferData.etternavn}`
      const phone = standardized(stafferData.mobil)
      const email: string = (stafferData[isTamigoImport ? 'e-post' : 'e-mail'] || '').toLowerCase()
      const permittedPositions = isTamigoImport
        ? stafferData.type in LEGACY_POSITION_TRANSLATIONS
          ? [LEGACY_POSITION_TRANSLATIONS[stafferData.type]]
          : null
        : null
      return {
        name,
        email,
        id: `${index}${phone}${email}`, // helps with exact indexing for handlers
        isNewImport: true, // so its more convenient when filtering for check with exisitng staffers
        permittedPositions,
        phone,
      }
    }
  )

  const noMissingParams = stafferInviteDocs.filter((inviteDoc) => {
    // This will filter out last empty line added by invalid editing
    if (inviteDoc === null) {
      return false
    }
    const hasAllRequiredFields = R.keys(inviteDoc).every(
      (key) => inviteDoc[key] || (key === 'permittedPositions' && inviteDoc[key] === null)
    )
    if (!hasAllRequiredFields) {
      toast.info(
        `Skipping line on "${
          inviteDoc.name || inviteDoc.email || inviteDoc.phone || 'unknown staffer'
        }", missing one (or more) of mandatory parameters. Check console for more info`
      )
      console.warn('Skipped result:', inviteDoc)
    }
    return hasAllRequiredFields
  }) as Array<ImportStafferType>

  if (noMissingParams.length !== 0) {
    return noMissingParams
  }

  console.error(
    'File contains no staffer data to import. Make sure mandatory fields: [email, telephone no. mobile, first name, last name] are provided'
  )
  return null
}

/**
 * Stringifies importheader object so it contains a partialmatch info if it had any
 * @param {ImportHeaderObject} match
 */
export const originColumnName = (match: ImportHeaderObject) =>
  match.partial ? `${match.value}[${match.partial}]` : match.value

/**
 * Checks whether string was created by `originColumnName` function
 * @param {string} headerName
 */
export const isPartialColumnName = (headerName: string) =>
  ['start', 'end'].some((partName) => headerName && headerName.includes(`[${partName}]`))

/**
 * Removes the partialmatch info from the string if any
 * @param {string} headerName
 */
export const nonPartialColumnName = (headerName: string) =>
  isPartialColumnName(headerName) ? headerName.substr(0, headerName.indexOf('[')) : headerName

export const valueByColumnName = (row: Record<string, string>, columnName: string): any => {
  if (!row) {
    return undefined
  }
  if (isPartialColumnName(columnName)) {
    const [startVal, ...rest] = (row[nonPartialColumnName(columnName)] || '').split(' ')
    const endVal = rest.join(' ')
    if (columnName.endsWith('[start]')) {
      return startVal
    }
    if (columnName.endsWith('[end]')) {
      return endVal
    }
  }
  return row[columnName]
}

/**
 * Sets value under columnName kye to object, but respect parialcolumns keys
 */
export const setValueByColumnName = (row: Record<string, string>, columnName: string, value: string) => {
  let newVal: string
  if (isPartialColumnName(columnName)) {
    const [start, ...rest] = (row[nonPartialColumnName(columnName)] || '').split(' ')
    let startVal = start
    let endVal = rest.join(' ')
    if (columnName.endsWith('[start]')) {
      startVal = value
    }
    if (columnName.endsWith('[end]')) {
      endVal = value
    }
    newVal = [startVal, endVal].join(' ')
  } else {
    newVal = value
  }
  return {
    ...row,
    [nonPartialColumnName(columnName)]: newVal,
  }
}

/**
 * Takes csv data and finds most likely headers match
 * @param {Object[]} parseData Result from parseCsv() function
 */
export const getHeaderMatches = (
  parsedData: Record<string, any>[],
  translations: { [field: string]: string[] }
): ImportHeaderMatch => {
  const allTranslations = R.unnest([...Object.values(translations)])
  /**
   * Matches the column value to the most likely translation
   * @param {string} input column value to attempt to match
   *  */
  const columnMatch = (input: string): HeaderMatchType => {
    const { bestMatch } = findBestMatch(input, allTranslations)
    const match = Object.entries(translations).find((entry) => entry[1].includes(bestMatch.target)) || ['', '']
    return {
      key: match[0],
      translations: match[1],
      rating: bestMatch.rating,
      value: input,
    }
  }

  const matchedHeaders = R.keys(parsedData[0]).map(columnMatch)
  // Groups parsed data by all matched columns
  let groupImportedFields = R.groupBy((matchedHeader) => matchedHeader.key, matchedHeaders)

  // Check if some colum is possible compound
  // eg. values originated from column 'navn' might be splitted into 'namefirst' and 'nameLast'
  const possibleCompoundColumns = [['nameFirst', 'nameLast']]

  const isMultipleWords = (value: string) => value && !!value.match(/[^ ]+ [^ ]+/)
  const getCompoundRatio = (values: Array<string>) => values.filter(isMultipleWords).length / values.length

  possibleCompoundColumns.forEach(([firstKey, secondKey]) => {
    const matchedHalf = groupImportedFields[firstKey] || groupImportedFields[secondKey]
    // we do partial column split only if we dont have both of the fields in the compound column
    // (otherwise we presume both fields were matched correctly)
    if (!(firstKey in groupImportedFields && secondKey in groupImportedFields) && matchedHalf) {
      matchedHalf.forEach((match: HeaderMatchType) => {
        const compoundRatio = getCompoundRatio(parsedData.map((record) => record[match.value]))
        if (compoundRatio > 0.6) {
          groupImportedFields = R.assoc(
            firstKey,
            [
              ...(groupImportedFields[firstKey] || []),
              { ...match, rating: Math.min(match.rating + 0.1 * compoundRatio, 1), partial: 'start' },
            ],
            groupImportedFields
          )
          groupImportedFields = R.assoc(
            secondKey,
            [...(groupImportedFields[secondKey] || []), { ...match, key: secondKey, partial: 'end' }],
            groupImportedFields
          )
        }
      })
    }
  })

  // Sorts grouped parsed data by match likelyhood for each key
  const sortByMatchRating: Record<string, HeaderMatchType[]> = R.map(
    (matches) => R.sort(R.descend(R.prop('rating')), matches),
    groupImportedFields
  )
  const isPartial = (match: Record<string, any>) => 'partial' in match

  // in case of compound column partial matches, we prioritize the splitted results
  const prioritizePartialFields = (matches: HeaderMatchType[]): HeaderMatchType[] =>
    matches.some(isPartial)
      ? [R.find(isPartial, matches) as HeaderMatchType, ...R.remove(R.findIndex(isPartial, matches), 1, matches)]
      : matches
  const prioritizedPartialFields: Record<keyof typeof STAFFER_IMPORT_TRANSLATIONS, HeaderMatchType[]> = R.map(
    prioritizePartialFields,
    sortByMatchRating
  )

  return prioritizedPartialFields
}

/**
 * Async handler for updating import data for the import wizard
 * @param {ParseResults} csv Csv data to be passed to parseCsv function
 */
export const importWizardHandle = async (csv: ParseResults) => {
  const parsedData = parseCsv(csv)
  const headerMatches = getHeaderMatches(parsedData || [], STAFFER_IMPORT_TRANSLATIONS)
  /**
   * Dev note, how to add new columns to import wizard
   * --------------------------------------------------
   * 1. Open constants/importing.js
   * 2. Add column name to FIELD_LABELS and translations array to STAFFER_IMPORT_TRANSLATIONS
   * 3. (Optional) If the field is required add it to REQUIRED_IMPORT_FIELDS
   * 4. (Optional) If some form of validation is required, open WizardStep3.js
   *    4.1 Write validator in validateField() method
   *    4.2 Write error message in validateRow() method
   */
  return {
    headerMatches,
    data: parsedData as Record<string, string | string>[],
  }
}
