import type { Moment } from 'moment'
import type { BusinessType } from '../../../../../src/types/business'
import type { CountryNameType } from '../../../../../src/types/common'
import type { Firestore } from '../../../../../src/types/firebase'
import moment from '../../../util/moment'
import { getManagerByIdPromise } from './managers.legacy'

type PossibleFirebaseType = 'web' | 'app'

export const getRecentBusinessRequests = (db: Firestore) =>
  db
    .collection('business')
    .where('accessRequested', '>', moment().subtract(7, 'days').toDate())
    .where('accessGranted', '==', false)

export const getBusinessById = <T extends PossibleFirebaseType>(db: Firestore<T>, businessId: string) =>
  db.collection('business').doc(businessId)

export const getBusinessesByGroupId = <T extends PossibleFirebaseType>(db: Firestore<T>, groupId: string) =>
  db.collection('business').where('groupId', '==', groupId)

export const getBusinessOnboarding = <T extends PossibleFirebaseType>(db: Firestore<T>, businessId: string) =>
  db.collection('businessOnboarding').doc(businessId)

export const getAllBusinesses = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db
    .collection('business')
    .where('accessGranted', '==', true)
    // Not orderby to avoid businesses with empty businessNames on dev
    .where('businessName', '>', '')

export const getAllActiveBusinesses = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db
    .collection('business')
    .where('accessGranted', '==', true)
    .where('isArchived', '==', false)
    // Not orderby to avoid businesses with empty businessNames on dev
    .where('businessName', '>', '')

export const getUnclaimedBusinesses = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db.collection('business').where('unclaimed', '==', true).where('isArchived', '==', false)
// Not orderby to avoid businesses with empty businessNames on dev
// .where('businessName', '>', '')

export const getClaimedBusinesses = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db.collection('business').where('unclaimed', '==', false).where('isArchived', '==', false)

export const getBusinessRating = <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  businessId: string,
  stafferId: string
) => db.collection('business').doc(businessId).collection('ratings').doc(stafferId)

export const getAllBusinessRequests = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db
    .collection('business')
    .where('accessGranted', '==', false)
    .where('accessRequested', '>', new Date(0))
    .orderBy('accessRequested', 'desc')

export const getUnresolvedBusinessRequests = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db.collection('business').where('accessRequested', '==', false).orderBy('createdAt', 'desc')

export const getBusinessesByLastActivity = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db
    .collection('business')
    .where('accessGranted', '==', true)
    .where('isArchived', '==', false)
    .orderBy('lastVisited', 'desc')

export const getBusinessesByTotalPosted = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db.collection('business').where('totalPosted', '>=', 1).orderBy('totalPosted', 'desc')

export const getBusinessesByTotalShifts = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db
    .collection('business')
    .where('accessGranted', '==', true)
    .where('isArchived', '==', false)
    .orderBy('totalShifts', 'desc')

export const getBusinessesByTotalStaffers = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db
    .collection('business')
    .where('accessGranted', '==', true)
    .where('isArchived', '==', false)
    .orderBy('totalStaffers', 'desc')

export const getBusinessesByAccessGrantedAndCountry = <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  country: string
) =>
  db
    .collection('business')
    .where('accessGranted', '==', true)
    .where('isArchived', '==', false)
    .where('region.countryName', '==', country)
    .orderBy('accessGrantedAt', 'desc')

export const getBusinessesByRegion = <T extends PossibleFirebaseType>(db: Firestore<T>, country: CountryNameType) =>
  db.collection('business').where('region.countryName', '==', country)

export const getBusinessesByCountry = <T extends PossibleFirebaseType>(db: Firestore<T>, country: string) =>
  db.collection('business').where('address.country', '==', country)

export const getJobsByLastUnapproved = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db
    .collection('jobs')
    .where('lastUnapproved', '<', moment().subtract(8, 'hours').toDate())
    .orderBy('lastUnapproved', 'asc')

export const getStaffersByBusinessId = <T extends PossibleFirebaseType>(db: Firestore<T>, businessId: string) =>
  db.collection('business').doc(businessId).collection('staffers')

export const getStaffersByBusinessIdSortedByLastShift = <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  businessId: string
) => getStaffersByBusinessId(db, businessId).orderBy('timeStart', 'desc')

export const getFavoriteStaffersByBusinessId = <T extends PossibleFirebaseType>(db: Firestore<T>, businessId: string) =>
  db.collection('business').doc(businessId).collection('favoriteStaffers')

export const getLogsByBusinessId = <T extends PossibleFirebaseType>(db: Firestore<T>, businessId: string) =>
  db.collection('business').doc(businessId).collection('logs').orderBy('time', 'desc').limit(15)

export const getBusinessByIdPromise = async <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  businessId: string
): Promise<BusinessType | null> =>
  db
    .collection('business')
    .doc(businessId)
    .get()
    .then((doc) => (doc.exists ? (doc.data() as BusinessType) : null))

export const getBusinessByManagerIdPromise = async <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  userId: string | null
) => {
  const managerData = await getManagerByIdPromise(db, userId)
  // eslint-disable-next-line no-nested-ternary
  return !managerData
    ? null
    : managerData.businessId
    ? db
        .collection('business')
        .doc(managerData.businessId)
        .get()
        .then((doc) => (doc.exists ? doc.data() : null))
    : null
}

export const getBusinessByManagerId = async <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  userId: string | null
) => {
  const managerData = await getManagerByIdPromise(db, userId)
  return managerData && managerData.businessId ? getBusinessById(db, managerData.businessId) : []
}

export const getBusinessActivityById = <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  businessId: string,
  activityId: string
) => db.collection('business').doc(businessId).collection('activities').doc(activityId)

export const getUnseenBusinessActivitiesById = <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  businessId: string,
  managerId: string | null
) =>
  db
    .collection('business')
    .doc(businessId)
    .collection('activities')
    .where('unseenBy', 'array-contains', managerId)
    .where('notifyUntil', '>', new Date())

export const getBusinessActivitiesWithActionToBeTakenById = <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  businessId: string,
  managerId: string | null
) =>
  db
    .collection('business')
    .doc(businessId)
    .collection('activities')
    .where('showTo', 'array-contains', managerId) // Only show to managers which should handle it
    .where('actionToBeTaken', '==', true) // Only take actions with remaining actions
    .orderBy('createdAt', 'desc') // Order by when they were created

export const getUnresolvedRequestsByBusinessId = <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  businessId: string,
  managerId: string | null
) =>
  db
    .collection('business')
    .doc(businessId)
    .collection('activities')
    .where('unseenBy', 'array-contains', managerId)
    .where('type', '==', 'unresolved')

export const getUnseenRequestsByJobId = <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  businessId: string,
  managerId: string | null,
  jobId: string
) =>
  db
    .collection('business')
    .doc(businessId)
    .collection('activities')
    .where('jobId', '==', jobId)
    .where('unseenBy', 'array-contains', managerId)

export const getSpecificActivitiesByJobShiftStafferId = <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  businessId: string,
  jobId: string,
  shiftId: string,
  stafferId: string,
  type: string
) =>
  db
    .collection('business')
    .doc(businessId)
    .collection('activities')
    .where('actionToBeTaken', '==', true)
    .where('jobId', '==', jobId)
    .where('shiftId', '==', shiftId)
    .where('stafferId', '==', stafferId)
    .where('type', '==', type)

export const getUnseenRequestsByJobAdId = <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  businessId: string,
  managerId: string | null,
  jobId: string
) =>
  db
    .collection('business')
    .doc(businessId)
    .collection('activities')
    .where('jobAdId', '==', jobId)
    .where('unseenBy', 'array-contains', managerId)

export const getUnseenActivitiesByManagerId = async <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  userId: string | null
) => {
  const managerData = await getManagerByIdPromise(db, userId)
  return managerData && managerData.businessId
    ? getUnseenBusinessActivitiesById(db, managerData.businessId, userId)
    : []
}

export const getAllBusinessesByRegionId = <T extends PossibleFirebaseType>(db: Firestore<T>, regionId: string) =>
  db.collection('business').where('regionId', '==', regionId)

export const getBusinessesByOrgNumberPromise = async <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  orgNumber: string
) =>
  db
    .collection('business')
    .where('orgNumber', '==', orgNumber)
    .get()
    .then((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({
        id: doc.id,
      }))
    )

export const getAllBusinessesSince =
  (from: Moment) =>
  async <T extends PossibleFirebaseType>(db: Firestore<T>) =>
    db.collection('business').where('accessGranted', '==', true).where('accessGrantedAt', '>', from.toDate())

export const getBusinessesOnCallingList = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db.collection('business').where('callingList', '==', true)

export const getActiveBusinessesOnCallingList = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db.collection('business').where('callingList', '==', true).where('isArchived', '==', false)

export const getArchivedBusinesses = <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db.collection('business').where('isArchived', '==', true)

export const getTimeouts = async <T extends PossibleFirebaseType>(db: Firestore<T>) =>
  db.collection('enums').doc('timeouts')

export const getCustomTypedBusinessesByCountry = async <T extends PossibleFirebaseType>(
  db: Firestore<T>,
  country: string
) => db.collection('business').where('address.country', '==', country).orderBy('customBusinessType')

export const getPreviousDuties = async <T extends PossibleFirebaseType>(db: Firestore<T>, businessId: string) => {
  const businessDoc = await db.collection('business').doc(businessId).get()
  const business = businessDoc.data() as BusinessType | undefined
  return business?.duties
}
