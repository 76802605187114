import { Link } from '@material-ui/core'
import type { StafferDocumentsType, StafferDocumentType } from '../../../../../src/types/staffer'
import AwaitingVerificationIcon from '../../../img/algoliaIcons/AwaitingVerificationIcon.svg'
import DocumentThumbnail from '../../../img/algoliaIcons/DocumentThumbnail.svg'
import VerifiedIcon from '../../../img/algoliaIcons/VerifiedIcon.svg'
import styles from './StafferPopover.module.css'

type DocumentCardProps = {
  title: string
  documents?: StafferDocumentType[]
  documentsVerified: boolean | null
  style?: React.CSSProperties
}

type Props = {
  stafferDocuments: StafferDocumentsType
  documentsVerified: boolean | null
}

const DocumentCard = ({ title, documents, documentsVerified, style }: DocumentCardProps) => {
  if (!documents || documents.length < 1) {
    return null
  }

  return (
    <div className={styles.documentCard} style={style}>
      <div className={styles.documentRowContent}>
        <img src={documents[0].url ?? DocumentThumbnail} alt={title} className={styles.documentImg} />
      </div>
      <div className={styles.documentColumnContent}>
        <span className={styles.textBold}>{title}</span>
        {!documentsVerified && (
          <Link href={documents[0].url} className={styles.link} target="_blank">
            View detail
          </Link>
        )}
      </div>
      {documentsVerified ? (
        <div className={styles.documentRowContent}>
          <img src={VerifiedIcon} alt="Verified" style={{ marginRight: 8 }} />
          <span className={styles.greenText}>Verified</span>
        </div>
      ) : (
        <div className={styles.documentRowContent}>
          <img src={AwaitingVerificationIcon} alt="Awaiting verification" style={{ marginRight: 8 }} />
          <span className={styles.awaitingText}>Awaiting verification</span>
        </div>
      )}
    </div>
  )
}

const StafferPopoverVerification = ({ stafferDocuments, documentsVerified }: Props) => {
  const { drivinglicense, passport, taxcard } = stafferDocuments

  if ((!drivinglicense && !passport) || !taxcard) {
    return <div>Documents data unavailable</div>
  }

  return (
    <div className={styles.contentColumn}>
      <DocumentCard
        title="Passport or driving license"
        documents={drivinglicense && drivinglicense.length > 0 ? drivinglicense : passport}
        documentsVerified={documentsVerified}
      />
      <DocumentCard
        title="Tax Card"
        documents={taxcard}
        documentsVerified={documentsVerified}
        style={{ marginTop: 16 }}
      />
    </div>
  )
}

export default StafferPopoverVerification
