import type { Moment } from 'moment'
import { infiniteQuery, query, schema } from 'qman'
import {
  getActiveBusinessesOnCallingList,
  getAllActiveBusinesses,
  getAllBusinessRequests,
  getAllBusinesses,
  getAllBusinessesByRegionId,
  getAllBusinessesSince,
  getArchivedBusinesses,
  getBusinessActivitiesWithActionToBeTakenById,
  getBusinessActivityById,
  getBusinessById,
  getBusinessByIdPromise,
  getBusinessByManagerId,
  getBusinessByManagerIdPromise,
  getBusinessOnboarding,
  getBusinessRating,
  getBusinessesByAccessGrantedAndCountry,
  getBusinessesByCountry,
  getBusinessesByGroupId,
  getBusinessesByLastActivity,
  getBusinessesByOrgNumberPromise,
  getBusinessesByRegion,
  getBusinessesByTotalPosted,
  getBusinessesByTotalShifts,
  getBusinessesByTotalStaffers,
  getBusinessesOnCallingList,
  getClaimedBusinesses,
  getCustomTypedBusinessesByCountry,
  getJobsByLastUnapproved,
  getLogsByBusinessId,
  getPreviousDuties,
  getRecentBusinessRequests,
  getSpecificActivitiesByJobShiftStafferId,
  getStaffersByBusinessId,
  getStaffersByBusinessIdSortedByLastShift,
  getTimeouts,
  getUnclaimedBusinesses,
  getUnresolvedBusinessRequests,
  getUnresolvedRequestsByBusinessId,
  getUnseenBusinessActivitiesById,
  getUnseenRequestsByJobAdId,
  getUnseenRequestsByJobId,
} from '../api/getters/business'
import { collectionFn, documentFn, infiniteFn, promiseFn, queryFn } from './schemaFunctions'

export const business = schema(
  'business',
  query('recentBusinessRequests', getRecentBusinessRequests, queryFn),
  query('businessById', getBusinessById, documentFn),
  query('businessesByGroupId', getBusinessesByGroupId, queryFn),
  query('businessOnboarding', getBusinessOnboarding, documentFn),
  query('allBusinesses', getAllBusinesses, queryFn),
  query('allActiveBusinesses', getAllActiveBusinesses, queryFn),
  query('unclaimedBusinesses', getUnclaimedBusinesses, queryFn),
  query('claimedBusinesses', getClaimedBusinesses, queryFn),
  query('businessRating', getBusinessRating, documentFn),
  query('allBusinessRequests', getAllBusinessRequests, queryFn),
  query('unresolvedBusinessRequests', getUnresolvedBusinessRequests, queryFn),
  query('businessesByLastActivity', getBusinessesByLastActivity, queryFn),
  query('businessesByTotalPosted', getBusinessesByTotalPosted, queryFn),
  query('businessesByTotalShifts', getBusinessesByTotalShifts, queryFn),
  query('businessesByTotalStaffers', getBusinessesByTotalStaffers, queryFn),
  query('businessesByAccessGrantedAndCountry', getBusinessesByAccessGrantedAndCountry, queryFn),
  query('businessesByRegion', getBusinessesByRegion, queryFn),
  query('businessesByCountry', getBusinessesByCountry, queryFn),
  query('jobsByLastUnapproved', getJobsByLastUnapproved, queryFn),
  query('staffersByBusinessId', getStaffersByBusinessId, collectionFn),
  query('staffersByBusinessIdSortedByLastShift', getStaffersByBusinessIdSortedByLastShift, queryFn),
  query('logsByBusinessId', getLogsByBusinessId, queryFn),
  query('businessByIdPromise', getBusinessByIdPromise, promiseFn),
  query('businessByManagerIdPromise', getBusinessByManagerIdPromise, promiseFn),
  query('businessByManagerId', getBusinessByManagerId, promiseFn),
  query('businessActivityById', getBusinessActivityById, documentFn),
  query('unseenBusinessActivitiesById', getUnseenBusinessActivitiesById, queryFn),
  query('businessActivitiesWithActionToBeTakenById', getBusinessActivitiesWithActionToBeTakenById, queryFn),
  query('unresolvedRequestsByBusinessId', getUnresolvedRequestsByBusinessId, queryFn),
  query('unseenRequestsByJobId', getUnseenRequestsByJobId, queryFn),
  query('specificActivitiesByJobShiftStafferId', getSpecificActivitiesByJobShiftStafferId, queryFn),
  query('unseenRequestsByJobAdId', getUnseenRequestsByJobAdId, queryFn),
  query('allBusinessesByRegionId', getAllBusinessesByRegionId, queryFn),
  query('businessesByOrgNumberPromise', getBusinessesByOrgNumberPromise, promiseFn),
  query('allBusinessesSince', (from: Moment) => getAllBusinessesSince(from)(), queryFn),
  query('businessesOnCallingList', getBusinessesOnCallingList, queryFn),
  query('activeBusinessesOnCallingList', getActiveBusinessesOnCallingList, queryFn),
  query('archivedBusinesses', getArchivedBusinesses, queryFn),
  query('timeouts', getTimeouts, documentFn),
  query('customTypedBusinessesByCountry', getCustomTypedBusinessesByCountry, queryFn),
  query('previousDuties', getPreviousDuties, promiseFn),
  infiniteQuery('allBusinessRequestsInfinite', getAllBusinessRequests, infiniteFn)
)
