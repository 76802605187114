import { Link } from 'react-router-dom'
import styles from './menu.module.css'

type Props = {
  title: string
  isSelected: boolean
  routeTo: string
}

const MenuButton = (props: Props) => {
  const { title, isSelected, routeTo } = props
  const btnClass = isSelected ? 'buttonHighlighted' : 'buttonContainer'

  return (
    <div className={styles.innerButtonContainer}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link className={styles[btnClass]} to={routeTo}>
        {title}
      </Link>
    </div>
  )
}

export default MenuButton
