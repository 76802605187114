import { arrayRemove, deleteDoc, deleteField, setDoc, updateDoc } from 'firebase/firestore'
import type { BusinessType } from '../../../../../src/types/business'
import { getDocumentRef } from '../wrappers'
import { getCurrentUserId, getServerTimestamp } from './util.legacy'

export const isBusinessProfileComplete = (profileData: BusinessType) =>
  profileData.address &&
  profileData.address.city &&
  profileData.address.country &&
  profileData.address.postalCode &&
  profileData.address.street &&
  profileData.businessName &&
  profileData.businessType &&
  profileData.orgNumber &&
  profileData.regionId

export const updateBusinessProfile = async (businessId: string, profileData: Record<string, any>) =>
  setDoc(
    getDocumentRef<BusinessType>('business', businessId),
    {
      ...profileData,
      updatedBy: getCurrentUserId(),
    },
    { merge: true }
  )
// For business, phone is part of profile => use the same function

export const updateLastVisited = async (businessId: string) =>
  updateDoc(getDocumentRef<BusinessType>('business', businessId), { lastVisited: getServerTimestamp() })

export const markActivityAsSeen = async (businessId: string, activityId: string) =>
  updateDoc(getDocumentRef('business', businessId, 'activities', activityId), {
    unseenBy: arrayRemove(getCurrentUserId()),
  })

export const markActivityAsFinished = async (
  businessId: string,
  activityId: string,
  // Only disable activity if explicitly stated (all managers saw, action is one-time)
  disable?: boolean
) =>
  updateDoc(getDocumentRef('business', businessId, 'activities', activityId), {
    ...(disable ? { actionToBeTaken: false } : {}),
    showTo: arrayRemove(getCurrentUserId()),
    unseenBy: arrayRemove(getCurrentUserId()),
  })

export const deleteActivity = async (businessId: string, activityId: string) =>
  deleteDoc(getDocumentRef('business', businessId, 'activities', activityId))

export const makeInviteOnlyPublic = async (jobId: string) =>
  updateDoc(getDocumentRef('jobs', jobId), {
    limitedToInvited: false,
    limitedToInvitedUntil: deleteField(),
  })
