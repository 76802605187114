import { Box, Button } from '@material-ui/core'
import type { ChangeEvent } from 'react'
import type { CustomPositionType } from '../../../../../src/types/groups'
import CreateEditPositionFields from '../CreateEditPosition/CreateEditPositionFields'

type Props = {
  onConfirm: (positionIndex: number | null) => void
  onChangePosition: (event: ChangeEvent<HTMLInputElement>, index?: number) => void
  position: CustomPositionType
}

const DEFAULT_MINIMUM_AGE = 18

const CreateEditPosition = (props: Props) => {
  const { onConfirm, onChangePosition, position } = props

  const { defaultWage, includeSkillLevels, minAge, name } = position

  return (
    <div style={{ overflow: 'scroll' }}>
      <CreateEditPositionFields
        checkForErrors={() => {}}
        country="Norway"
        defaultWage={defaultWage}
        DEFAULT_MINIMUM_AGE={DEFAULT_MINIMUM_AGE}
        includeSkillLevels={!!includeSkillLevels}
        minAge={minAge}
        name={name}
        onInputChange={onChangePosition}
      />
      <Box width={1} textAlign="center" mb={3}>
        <Button
          color="primary"
          // disabled={!isFormValid}
          onClick={() => onConfirm(null)}
          variant="contained"
        >
          Save changes
        </Button>
      </Box>
    </div>
  )
}

export default CreateEditPosition
