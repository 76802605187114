import { getAuth } from 'firebase/auth'
import { getFirestore, type DocumentData } from 'firebase/firestore'
import { query, schema } from 'qman'
import type { FC } from 'react'
import type { CollectionReference, DocumentReference, Firestore, Query } from '../../../../src/types/firebase'
import { entries, keys } from '../../util/object'
import { collectionFn, documentFn, promiseFn, queryFn as querySchemaFn } from './schemaFunctions'

export type Refs = CollectionReference<DocumentData> | DocumentReference<DocumentData> | Query<DocumentData>

export type Props = Record<string, any>

export type QueryMap = {
  [key: string]:
    | Refs
    | DocumentReference<DocumentData>[]
    | Promise<unknown>
    | undefined
    | null
    | []
    | string
    | Record<string, any>
    | boolean
}

type QueryFn = (db: Firestore, passProps: any, uid: string) => QueryMap

export const isRef = (x: Promise<unknown> | Refs): x is Refs => !!((x as Refs)?.firestore && (x as Refs)?.type)

export const applyCorrectGetter = <F extends QueryFn>([key, refOrPromise]: [keyof ReturnType<F>, unknown]) => {
  if (Array.isArray(refOrPromise)) {
    // handle array of refs
    if (refOrPromise.every(isRef)) {
      return refOrPromise.map((ref, i) => {
        // evaluate each ref whether it is a collection, query or document
        if (ref.type === 'document') {
          return query(`${String(key)}.${i}`, () => ref, documentFn)
        }
        if (ref.type === 'query') {
          return query(`${String(key)}.${i}`, () => ref, querySchemaFn)
        }
        return query(`${String(key)}.${i}`, () => ref as CollectionReference<DocumentData>, collectionFn)
      })
    }
    // else handle array of promises
    return [query(key as string, () => Promise.all(refOrPromise) as Promise<unknown>, promiseFn)]
  }
  if (isRef(refOrPromise as Refs)) {
    switch ((refOrPromise as Refs).type) {
      case 'collection':
        return [query(key as string, () => refOrPromise as CollectionReference<DocumentData>, collectionFn)]
      case 'query':
        return [query(key as string, () => refOrPromise as Query<DocumentData>, querySchemaFn)]
      case 'document':
        return [query(key as string, () => refOrPromise as DocumentReference<DocumentData>, documentFn)]
    }
  }
  return [query(key as string, () => refOrPromise as Promise<unknown>, promiseFn)]
}

export const connectFirestore = <F extends QueryFn, E>(queryFn: F, Component: E) => {
  let db: Firestore | null = null
  let uid: string | null = null
  try {
    db = (getFirestore() as unknown as Firestore) || null
    uid = getAuth().currentUser?.uid || null
  } catch (e) {
    console.warn('Unable to retrieve non-compat connectFirestore. Using old compat version until fully migrated')
  }
  const Provider = (props: Props) => {
    if (!uid) {
      uid = getAuth().currentUser?.uid || null
      if (!uid) {
        console.error('Could not retrieve uid from firebase auth')
        return null
      }
    }
    const queryMap = queryFn(db as Firestore, props, uid || '') as ReturnType<F>
    // these are [key, fn] pairs - individual items from QueryMap
    const preparedQueries = entries(queryMap).map(applyCorrectGetter)
    const schemaName = 'connectFirestore' + ((props.schemaKey || props.id) ?? '')
    // WARNING
    // This is important part of this function, it specifies cacheKey and therefore need to be specified in lists (map)
    const queriesSchema = schema(schemaName, ...preparedQueries.flat())
    const queryResults = keys(queriesSchema.schema).reduce(
      (res, queryKey) => {
        if (String(queryKey).includes('.')) {
          // key is part of list
          const objKey = String(queryKey).split('.')[0]
          const list = res[objKey] ?? []
          return {
            ...res,
            [objKey]: [...list, queriesSchema.get(queryKey, [])],
          }
        }
        return {
          ...res,
          [queryKey]: queriesSchema.get(queryKey, []),
        }
      },
      {} as Record<string, any>
    )
    const Constructable = Component as FC
    return <Constructable {...props} {...queryResults} />
  }

  return Provider
}
