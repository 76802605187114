import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import type { ChangeEvent } from 'react'

type Props = {
  index: number
  isChecked: boolean
  isProcessing: boolean
  position: string
  availableSkillLevels?: string[] // available skill levels for specific position
  currentSkillLevel: string
  togglePosition: (event: ChangeEvent<HTMLInputElement>) => void
  updateSkillLevel?: (event: ChangeEvent<HTMLSelectElement>) => void
}

const PoolPermissionsItem = (props: Props) => {
  const {
    index,
    isChecked,
    isProcessing,
    position,
    availableSkillLevels,
    currentSkillLevel,
    togglePosition,
    updateSkillLevel,
  } = props

  return (
    <Grid item container xs={6}>
      <Grid item xs>
        <FormControlLabel
          label={position}
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Checkbox
              checked={isChecked}
              color="primary"
              disabled={isProcessing}
              name={position}
              onChange={togglePosition}
            />
          }
        />
      </Grid>
      {availableSkillLevels && availableSkillLevels.length > 0 && (
        <Grid item xs>
          <FormControl style={{ minWidth: '200px' }}>
            <InputLabel shrink id={`permission-item-${index}`} style={{ marginTop: '-8px' }}>
              {position} experience level
            </InputLabel>
            <Select
              disabled={!isChecked}
              labelId={`permission-item-${index}`}
              placeholder="Select position experience level"
              name={position}
              value={currentSkillLevel}
              // @ts-ignore MUI Select has incorrectly typed change event (ChangeEvent does not even accept two type arguments)
              onChange={updateSkillLevel}
            >
              {availableSkillLevels.map((skillLevel) => (
                <MenuItem key={skillLevel} value={skillLevel}>
                  {skillLevel}
                </MenuItem>
              ))}
              <MenuItem value="">
                <span style={{ color: 'silver', fontStyle: 'italic' }}>Leave experience level as undefined</span>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  )
}

export default PoolPermissionsItem
