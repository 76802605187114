import { Box, Button, Grid } from '@material-ui/core'
import * as R from 'ramda'
import type { ChangeEvent } from 'react'
import React, { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import type { CustomPositionType } from '../../../../../src/types/groups'
import { errorToast } from '../../../helpers/toast'
import { firestoreHttpsCallable } from '../../../staffers/api/firestore/https/util'
import styles from '../../mui/Modal.module.css'
import ModalHeader from '../../mui/ModalHeader'
import CreatePositionsStep from './CreatePositionsStep'
import EmptyBusinessesStep from './EmptyBusinessesStep'
import EmptyGroupPreview from './EmptyGroupPreview'
import GroupDetails from './GroupDetails'

type Props = {
  onClose: () => void
}

type State = {
  country: string
  currentStep: number
  editingPositionIndex: number | null
  numberOfBusinesses: number | string
  isProcessing: boolean
  groupName: string
  positions: Partial<CustomPositionType>[]
  testingOn: boolean
}

class CreateGroupWithEmpty extends Component<Props, State> {
  state: State = {
    country: 'Norway',
    currentStep: 1,
    editingPositionIndex: null,
    groupName: '',
    numberOfBusinesses: 0,
    isProcessing: false,
    positions: [
      {
        defaultWage: 0,
        minAge: 18,
        name: '',
        includeSkillLevels: false,
        isArchived: false,
      },
    ],
    testingOn: false,
  }

  /* ---- METHODS ---- */
  // GROUP NAME INPUT
  onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ groupName: event.target.value })
  }

  // POSITIONS METHODS
  onChangePosition = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
    // @ts-ignore can be also used for checkbox checking, inconsistent type
    const { checked, name, value } = event.target
    const { positions } = this.state
    const updatedPositions = [...positions]
    if (name === 'includeSkillLevels') {
      // @ts-ignore correctly raised error, but working functionality so rather not touching
      updatedPositions[index].includeSkillLevels = checked
    } else {
      // @ts-ignore dynamic state indexing
      updatedPositions[index][name] = value
    }
    this.setState({ positions: updatedPositions })
  }

  // Adjusting the method to be usable from a different component
  onChangePositionHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
    const { editingPositionIndex } = this.state
    const id = editingPositionIndex !== null ? editingPositionIndex : index
    this.onChangePosition(event, id)
  }

  onToggleEditPosition = (editingPositionIndex: number | null) => {
    this.setState({ editingPositionIndex })
  }

  onAddPosition = () => {
    this.setState((prevState) => ({
      positions: [
        ...prevState.positions,
        {
          defaultWage: 0,
          minAge: 18,
          name: '',
          isArchived: false,
        },
      ],
    }))
  }

  onRemovePosition = (positionIndex: number) => {
    const { positions } = this.state
    const updatedPositions = positions.filter((position, index) => index !== positionIndex)
    this.setState({
      positions: updatedPositions,
    })
  }

  // EMPTY BUSINESS COUNT INPUT
  onCountryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const country = event.target.value
    this.setState({ country })
  }

  onEmptyBusinessesCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const onlyNum = event.target.value.replace(/[^0-9]/g, '')
    this.setState({ numberOfBusinesses: onlyNum })
  }

  toggleTestingOn = () => {
    this.setState((prevState: State) => ({ testingOn: !prevState.testingOn }))
  }

  /* ---- RENDER STEP METHODS ---- */
  renderStepName = () => {
    const { currentStep, groupName } = this.state
    switch (currentStep) {
      case 1: {
        return ' - Name'
      }
      case 2: {
        return `: ${groupName} - Positions`
      }
      case 3: {
        return `: ${groupName} - Add Companies Loop`
      }
      case 4: {
        return `: ${groupName} - Summary`
      }
      default: {
        return null
      }
    }
  }

  renderStep = () => {
    const { country, currentStep, editingPositionIndex, numberOfBusinesses, groupName, positions } = this.state

    switch (currentStep) {
      case 1: {
        return (
          // @ts-ignore Partial props ignore
          <GroupDetails createGroupType="empty" groupName={groupName} onNameChange={this.onNameChange} />
        )
      }
      case 2: {
        return (
          <CreatePositionsStep
            editingPositionIndex={editingPositionIndex}
            onAddPosition={this.onAddPosition}
            onChangePosition={this.onChangePositionHandler}
            onRemovePosition={this.onRemovePosition}
            onToggleEditPosition={this.onToggleEditPosition}
            positions={positions as CustomPositionType[]}
          />
        )
      }
      case 3: {
        return (
          <EmptyBusinessesStep
            country={country}
            groupName={groupName}
            numberOfBusinesses={numberOfBusinesses as number}
            onCountryChange={this.onCountryChange}
            onEmptyBusinessesCountChange={this.onEmptyBusinessesCountChange}
            testingOn={this.state.testingOn}
            toggleTestingOn={this.toggleTestingOn}
          />
        )
      }
      case 4: {
        return (
          <EmptyGroupPreview
            country={country}
            groupName={groupName}
            numberOfBusinesses={numberOfBusinesses}
            positions={positions as CustomPositionType[]}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  onNextStep = () => {
    this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }))
  }

  onPreviousStep = () => {
    this.setState((prevState) => ({ currentStep: prevState.currentStep - 1 }))
  }

  saveGroup = async () => {
    const { country, groupName, positions, numberOfBusinesses, testingOn } = this.state
    const { onClose } = this.props
    try {
      this.setState({ isProcessing: true })

      // Prepare businesses (in case there should be 0 empty businesses)
      const businessNumArray = numberOfBusinesses ? R.range(1, parseInt(numberOfBusinesses as string, 10) + 1) : []
      const businesses = businessNumArray.map((businessNum) => `${groupName} ${businessNum}`)

      // Prepare positions
      const formattedPositions = positions
        .filter((position) => !!position.name)
        .map((position) => {
          const { defaultWage, minAge, name, includeSkillLevels } = position

          return {
            defaultWage: parseInt(defaultWage as unknown as string, 10),
            isArchived: false,
            minAge: parseInt(minAge as unknown as string, 10),
            name,
            skillLevels: includeSkillLevels ? ['Junior', 'Medium', 'Senior'] : null,
          }
        })

      await firestoreHttpsCallable('createGroupWithBusinesses', {
        businesses,
        country,
        name: groupName,
        positions: formattedPositions,
        testingOn,
      })
      onClose()
    } catch (e) {
      errorToast('There was a problem with saving the group. Check the console for more info.')
      console.error(e)
    } finally {
      this.setState({ isProcessing: false })
    }
  }

  isCurrentStepValid = () => {
    const { numberOfBusinesses, currentStep, groupName, positions } = this.state
    switch (currentStep) {
      // Group groupName
      case 1:
        return !!groupName && groupName.length >= 2
      // Positions - can be left with empty
      case 2:
        if (positions) {
          const positionNames = new Set(positions.map(({ name }) => name))
          const arePositionsUnique = Array.from(positionNames).length === positions.length
          return arePositionsUnique
        }
        return true
      // Businesses - can be left with 0/nothing
      case 3:
        return !numberOfBusinesses || (+numberOfBusinesses >= 0 && +numberOfBusinesses <= 500)
      // Shouldn't really check anything in the summary since everything has been checked before
      case 4:
        return true
      default:
        return false
    }
  }

  render() {
    const { currentStep, editingPositionIndex, isProcessing } = this.state

    const { onClose } = this.props

    return (
      <React.Fragment>
        <ModalHeader close={onClose}>
          Create Internal Group
          {this.renderStepName()}
        </ModalHeader>
        <Box className={styles.modalContent} p={3}>
          {this.renderStep()}
        </Box>
        <Box py={3}>
          {currentStep === 4 && isProcessing && (
            <Box pb={3} display="flex" justifyContent="center">
              <BeatLoader color="grey" />
            </Box>
          )}
          {editingPositionIndex === null && (
            <Grid container spacing={2} justify="center">
              {currentStep > 1 && (
                <Grid item xs={4}>
                  <Button fullWidth variant="contained" color="default" onClick={this.onPreviousStep}>
                    Previous step
                  </Button>
                </Grid>
              )}
              {currentStep < 4 && (
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    disabled={!this.isCurrentStepValid()}
                    variant="contained"
                    color="primary"
                    onClick={this.onNextStep}
                  >
                    Next step
                  </Button>
                </Grid>
              )}
              {currentStep === 4 && (
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    disabled={isProcessing}
                    variant="contained"
                    color="primary"
                    onClick={this.saveGroup}
                  >
                    Create the group
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </React.Fragment>
    )
  }
}

export default CreateGroupWithEmpty
