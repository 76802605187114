import { IconButton, MenuItem, Tooltip } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { getAuth } from 'firebase/auth'
import { arrayUnion, updateDoc } from 'firebase/firestore'
import { useState } from 'react'
import type { Props as ModalProps } from 'react-modal'
import { toast } from 'react-toastify'
import type { Shortlist } from '../../../../../src/types/shortlist'
import { errorToast } from '../../../helpers/toast'
import { getShortlistById } from '../../../staffers/api/getters/shortlists'
import MuiModal from '../../mui/Modal'
import Button from '../../mui/redesignedComponents/Button'
import { CustomSelect } from '../../mui/redesignedComponents/Select'
import type { ModalHeaderProps } from '../modal/Modal'
import { ModalHeader } from '../modal/Modal'
import styles from './AddToShortlistModal.module.css'

type AddToShortlistModalBaseProps = {
  shortlists: Shortlist[]
  onAdd: (selectedShortlistId: Shortlist['id']) => void
  onRedirectToCreate: VoidFunction
  onDeleteShortlist: (selectedShortlistId: Shortlist) => void
  isProcessing: boolean
} & Pick<ModalHeaderProps, 'onClose'>

type AddToShortlistModal = { isProcessing: boolean } & Pick<ModalHeaderProps, 'onClose'>

export const AddToShortlistModalBase = ({
  onClose,
  onAdd,
  onRedirectToCreate,
  shortlists,
  onDeleteShortlist,
  isProcessing,
}: AddToShortlistModalBaseProps) => {
  const [selectedShortlist, setSelectedShortlist] = useState<Shortlist['id'] | undefined>(shortlists?.[0]?.id)

  const hasShortlists = shortlists?.length > 0

  return (
    <div className={styles.modal}>
      {/* Header */}
      <ModalHeader title="Add to shortlist" onClose={onClose} />
      {/* Content */}
      <div className={styles.content}>
        <span className={styles.inputTitle}>{'Select shortlist'}</span>
        <CustomSelect
          fullWidth
          variant="outlined"
          value={selectedShortlist}
          onChange={({ target }) => setSelectedShortlist(target.value as Shortlist['id'])}
        >
          {shortlists.map((item) => (
            <MenuItem className={styles.menuItem} key={item.id} value={item.id} dense>
              <span>{item.name}</span>
              {item.id !== selectedShortlist ? (
                <Tooltip title="Delete Shortlist">
                  <IconButton onClick={() => onDeleteShortlist(item)} color="secondary">
                    <Cancel />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </MenuItem>
          ))}
        </CustomSelect>
        {/* Actions */}
        <div className={styles.actions}>
          <Button disabled={isProcessing} color="primary" variant="outlined" onClick={onRedirectToCreate}>
            {/* opens create new shortlist */}
            Create new shortlist
          </Button>
          <Button
            disabled={isProcessing || !hasShortlists}
            onClick={() => onAdd(selectedShortlist ?? '')}
            color="primary"
            variant="contained"
          >
            {/* opens create new modal */}
            Done
          </Button>
        </div>
      </div>
    </div>
  )
}

const AddToShortlistModal = ({
  isOpen,
  onClose,
  stafferIds,
  onDeleteShortlist,
  ...props
}: {
  stafferIds?: string | Array<string>
  onDeleteShortlist: (shortList: Shortlist) => void
} & Pick<ModalProps, 'isOpen'> &
  Pick<AddToShortlistModalBaseProps, 'shortlists' | 'onRedirectToCreate' | 'onClose'>) => {
  const [isProcessing, setIsProcessing] = useState(false)

  const addMemberToShortlist = async (shortlistId: Shortlist['id']) => {
    const adminId = getAuth().currentUser?.uid
    if (!adminId || !shortlistId || !stafferIds) return

    // assume that shortlistId and stafferId are valid and documents exist
    try {
      setIsProcessing(true)
      const shortlistRef = getShortlistById(shortlistId)
      // check if already there, if yes display different message
      await updateDoc(shortlistRef, {
        members: arrayUnion(stafferIds),
      })
      onClose()
      toast.success('Jobseeker has been added to your shortlist')
    } catch (error) {
      errorToast("Jobseeker couldn't be added to your shortlist")
    } finally {
      setIsProcessing(false)
    }
  }

  const deleteShortlist = async (shortlist: Shortlist) => {
    const adminId = getAuth().currentUser?.uid
    if (!adminId || !shortlist || !stafferIds) {
      errorToast('Error: Admin ID, Shortlist ID or Staffer ID is missing')
      return
    }

    try {
      setIsProcessing(true)
      onDeleteShortlist(shortlist)
    } catch (error) {
      errorToast("Couldn't delete shortlist")
    } finally {
      setIsProcessing(false)
    }
    return
  }

  return (
    <MuiModal isOpen={isOpen} hasRoundedCorners enforcedWidth="medium">
      <AddToShortlistModalBase
        {...props}
        isProcessing={isProcessing}
        onAdd={addMemberToShortlist}
        onDeleteShortlist={deleteShortlist}
        onClose={onClose}
      />
    </MuiModal>
  )
}

export default AddToShortlistModal
