import * as R from 'ramda'
import type { BusinessType } from '../../../src/types/business'
import type { JobType, ShiftType } from '../../../src/types/jobs'
import { CUSTOM_ADDRESS_BUSINESS_TYPES } from '../constants/permissions'
import { hasPermission } from './permissions'

const jobsNumber = R.prop('jobsNumber') as (obj: Record<string, any>) => number

const sortByJobsNumber = R.sortWith([R.descend(jobsNumber)])

export const getTopFiveStaffers = (stafferIds: Array<string>) =>
  R.compose(
    R.take(5),
    sortByJobsNumber,
    R.map(([id, jobsOfStaffer]: [string, Array<string>]) => ({ id, jobsNumber: jobsOfStaffer.length })),
    R.toPairs,
    R.groupBy((stafferId: string) => stafferId)
  )(stafferIds)

export const getTopFiveCompanies = (businessIds: Array<string>) =>
  R.compose(
    R.take(5),
    sortByJobsNumber,
    R.map(([id, jobsOfBusiness]: [string, Array<string>]) => ({ id, jobsNumber: jobsOfBusiness.length })),
    R.toPairs,
    R.groupBy((businessId: string) => businessId)
  )(businessIds)

export const getAllStafferIdsFromJobs = (jobs: Array<JobType>): Array<string> =>
  jobs.reduce((staffers: Array<string>, job) => staffers.concat(job.staffersConfirmed), [])

export const getAllStafferIdsFromShifts = (shifts: Array<ShiftType>): Array<string> =>
  shifts.reduce((staffers: Array<string>, shift) => staffers.concat(shift.staffersConfirmed), [])

// Can post jobs with custom location based on permissions or a business type
export const canPostCustomLocationJobs = (business: BusinessType) => {
  if (business && business.permissions) {
    // Check whether there are permissions for this business to post custom location jobs
    return (Object.keys(business.permissions) || []).includes('postWithCustomAddress')
      ? hasPermission(business, 'postWithCustomAddress')
      : CUSTOM_ADDRESS_BUSINESS_TYPES.includes(business.businessType)
  }
  return false
}

// TODO This should be in database! Not hardcoded!
export const getSelectableBusinessSubtypes = (businessType: string): string[] => {
  switch (businessType) {
    case 'Bar':
      return ['Beer bar', 'Cocktail bar', 'Pub']
    case 'Restaurant':
      return ['Casual dining', 'Casual fine dining', 'Fine dining']
    default:
      return []
  }
}

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

export const getFirstWord = (sentence: string) =>
  sentence.indexOf(' ') === -1
    ? sentence // For one word sentences, return the entire string
    : sentence.substr(0, sentence.indexOf(' '))

/**
 * Checks if string contains number
 */
export const containsNumber = (input: string): boolean => /\d/.test(input)

export const stableSort = <T>(arr: Array<T>, compare: (a: T, b: T) => number) =>
  arr
    .map((item: T, index: number) => ({ item, index }))
    .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
    .map(({ item }) => item)
export const compareBusinesses = (a: BusinessType, b: BusinessType) => {
  // check for number
  // eslint-disable-next-line no-restricted-globals
  const numA = !isNaN(Number(a.businessName[0]))
  // eslint-disable-next-line no-restricted-globals
  const numB = !isNaN(Number(b.businessName[0]))

  if (numA && numB) {
    return Number(a.businessName[0]) - Number(b.businessName[0])
  }

  if (numA) return -1
  if (numB) return 1

  // check for word
  const wordA = /^[a-zA-Z]+$/.test(a.businessName[0])
  const wordB = /^[a-zA-Z]+$/.test(b.businessName[0])

  if (wordA && wordB) {
    return a.businessName[0].localeCompare(b.businessName[0])
  }

  if (wordA) return -1
  if (wordB) return 1

  return 1
}

export const sortBusinesses = (businesses: BusinessType[]) => stableSort(businesses, compareBusinesses)

export const swedenOrgNumRegex = /\d{6}-\d{4}/g

// returns a number derived from the string
// which can be used for lexical ordering of those string values
// case insensitive
export const toOrdinalVal = (str: string) =>
  [...str, ...Array.from({ length: 10 }).map(() => ' ')]
    .slice(0, 10) // pad with spaces and slice to ensure all strings are same length
    .map((c) => c.charCodeAt(0) % 32)
    .reduce((sum, val) => sum * 32 + val, 0)
