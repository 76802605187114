import RegionSelector from '../regionSelector/RegionSelector'

type Props = {
  selectableCountries: Array<string>
  onCountryChange: (coutnryName: string) => any
  selectedCountry: string
}

const RegionSelectorContainer = (props: Props) => {
  const { onCountryChange, selectedCountry, selectableCountries } = props

  return (
    <div>
      <RegionSelector
        menuTitle="Country"
        options={selectableCountries.map((countryName) => ({
          label: countryName,
          value: countryName,
        }))}
        selectedValue={selectedCountry}
        onValueChange={onCountryChange}
      />
    </div>
  )
}

export default RegionSelectorContainer
