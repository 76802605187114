// Get sorted list of selectable countries
import * as R from 'ramda'
import type { BusinessType } from '../../../src/types/business'
import type { StafferTypePaymentDetails } from '../../../src/types/staffer'

export const getSelectableCountries = (regions: Array<Record<string, any>>) =>
  R.filter(Boolean, R.uniq(R.map(R.prop('countryName'), regions))).sort()

// Get sorted list of selectable regions in a country
export const getSelectableRegionsForCountry = (regions: Array<Record<string, any>>, country: string) =>
  R.filter(Boolean, R.map(R.prop('cityName'), R.filter(R.propEq('countryName', country), regions))).sort()

// Return currency based on country
export const getCurrencyByCountry = (country: string) => {
  switch (country) {
    case 'Norway': {
      return 'NOK'
    }
    case 'Sweden': {
      return 'SEK'
    }
    default:
      return 'NOK'
  }
}

// Return currency based on business object or stafferDetails object
export const getCurrencyByBusiness = (business: BusinessType | undefined | null) => {
  if (business && business.region) {
    return getCurrencyByCountry(business.region.countryName)
  }
  if (business && business.address && business.address.country) {
    return getCurrencyByCountry(business.address.country)
  }
  return 'NOK'
}

// Return currency based on stafferDetails object (differs from business because we default to Kr)
export const getCurrencyByStafferDetails = (stafferDetails?: StafferTypePaymentDetails) => {
  if (stafferDetails) {
    const { address } = stafferDetails
    if (address) {
      const hasAddressFilled = !!(address.city && address.country)
      return hasAddressFilled ? getCurrencyByCountry(address.country) : 'Kr'
    }
  }
  return 'Kr'
}

export const getCountryAdjective = (country: string) => {
  switch (country) {
    case 'Norway':
      return 'Norwegian'
    case 'Sweden':
      return 'Swedish'
    default:
      return ''
  }
}
