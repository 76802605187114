import { Avatar, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import type { ChangeEvent } from 'react'
import React, { Component } from 'react'
import type { BusinessType } from '../../../src/types/business'
import type { ManagerType } from '../../../src/types/manager'
import { isCompanyProfileComplete } from '../helpers/business'

export type LocationOptionType = {
  title: string // businessName,
  avatar?: string // photoUrl
  value: string // businessId
  address: string // stringified address
  isIncomplete: boolean
}

type Props = {
  manager: ManagerType
  isAdmin?: boolean
  groupBusinesses: BusinessType[]
  onChangeBusiness: (businessId?: string) => Promise<void>
  selectedBusiness: BusinessType
  disabled: boolean
}

type State = {
  searchText: string
  selectedBusiness: LocationOptionType | null
}

class GroupSelectLocation extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { manager, selectedBusiness } = props
    const options = this.createOptions()
    this.state = {
      searchText: '',
      selectedBusiness: options.find(({ value }) => value === (selectedBusiness.id || manager.businessId)) || null,
    }
  }

  createOptions = (): LocationOptionType[] => {
    const { groupBusinesses, manager, isAdmin } = this.props
    return groupBusinesses
      .filter(({ businessId }) => (isAdmin ? !!businessId : (manager.groupBusinesses || []).includes(businessId)))
      .map((business) => {
        const { businessName, businessId, photoUrl, address } = business

        return {
          title: businessName,
          value: businessId,
          avatar: photoUrl,
          address: `${address.street}, ${address.postalCode} ${address.city}, ${address.country}`,
          isIncomplete: !isCompanyProfileComplete(business),
        }
      })
  }

  selectLocation = async (_e: ChangeEvent<any>, selectedBusiness: LocationOptionType | null) => {
    const { onChangeBusiness } = this.props
    this.setState(
      {
        selectedBusiness,
      },
      async () => {
        await onChangeBusiness((selectedBusiness || {}).value)
      }
    )
  }

  onInputChange = (_e: ChangeEvent<any>, searchText: string) => {
    this.setState({
      searchText,
    })
  }

  render() {
    const { disabled } = this.props
    const { selectedBusiness, searchText } = this.state
    const options = this.createOptions()

    return (
      <React.Fragment>
        <Autocomplete
          value={selectedBusiness}
          onChange={this.selectLocation}
          inputValue={searchText}
          disabled={disabled}
          onInputChange={this.onInputChange}
          options={options}
          getOptionDisabled={(option) => !!option.isIncomplete}
          getOptionSelected={(option) => option.title === selectedBusiness?.title}
          getOptionLabel={(option) => option.title}
          renderOption={(option) => (
            <ListItem>
              <ListItemAvatar>
                <Avatar src={option.avatar || ''} />
              </ListItemAvatar>
              <ListItemText
                primary={option.title}
                secondary={option.isIncomplete ? 'This profile is incomplete.' : option.address}
              />
            </ListItem>
          )}
          // eslint-disable-next-line max-len
          renderInput={(params) => <TextField {...params} label="Select location" variant="outlined" />}
        />
        {disabled && (
          <Typography style={{ color: 'silver' }}>Cannot change business after you have confirmed staffers</Typography>
        )}
      </React.Fragment>
    )
  }
}

export default GroupSelectLocation
