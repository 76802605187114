import dayjs from 'dayjs'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import type { Image } from '../../../../../../src/types/common'
import styles from './ImageList.module.css'
import ImageListItem from './ImageListItem'

type Props = {
  imagesData: Image[]
  onRemoveItem: (imageName: string, index: number) => void
  onImageDataChange: (imagesData: Image[], galleryBlobs: (Blob | string)[]) => void
  galleryList?: boolean
  galleryBlobs: (Blob | string)[] | null
  coverImageUrl?: string
  businessCoverImageUrl?: string
}

const ImageList: FC<Props> = ({
  galleryList = false,
  imagesData,
  onRemoveItem,
  onImageDataChange,
  galleryBlobs,
  coverImageUrl,
}) => {
  const [items, setItems] = useState(imagesData)
  const [blobItems, setBlobItems] = useState(galleryBlobs)

  useEffect(() => {
    setItems(imagesData)
  }, [imagesData])

  useEffect(() => {
    setBlobItems(galleryBlobs)
  }, [galleryBlobs])

  const onDragEnd = (result: any) => {
    const { source, destination } = result

    // Dropped outside the list
    if (!destination) {
      return
    }

    const reorderedItems = Array.from(items)
    const reorderedBlobs = Array.from(blobItems || [])
    const [removed] = reorderedItems.splice(source.index, 1)
    const [removedBlob] = reorderedBlobs.splice(source.index, 1)
    reorderedItems.splice(destination.index, 0, removed)
    reorderedBlobs.splice(destination.index, 0, removedBlob)

    setItems(reorderedItems)
    onImageDataChange(reorderedItems, reorderedBlobs)
  }

  return galleryList ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={{ position: 'relative' }}>
            {items.map((item, index) => (
              <Draggable key={item.imageName} draggableId={item.imageName} index={index}>
                {(providedDrag) => (
                  <div
                    key={item.imageName}
                    className={styles.imageListItem}
                    ref={providedDrag.innerRef}
                    {...providedDrag.draggableProps}
                    {...providedDrag.dragHandleProps}
                    style={{ ...providedDrag.draggableProps.style, left: 'inherit' }}
                  >
                    <ImageListItem
                      key={index}
                      imageUrl={item.imageUrl}
                      imageName={item.imageName}
                      imageSize={item.imageSize}
                      imageFormat={item.imageFormat}
                      createdAt={dayjs(item.createdAt)}
                      galleryItem={galleryList}
                      onRemoveItem={() => onRemoveItem(item.imageName, index)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    items.map((item, index) => (
      <div key={item.imageName} className={styles.imageListItem}>
        <ImageListItem
          key={index}
          imageUrl={coverImageUrl || item.imageUrl}
          imageName={item.imageName}
          imageSize={item.imageSize}
          imageFormat={item.imageFormat}
          createdAt={dayjs(item.createdAt)}
          galleryItem={galleryList}
          onRemoveItem={() => onRemoveItem(item.imageName, index)}
        />
      </div>
    ))
  )
}

export default ImageList
