import type { Props as ModalProps } from 'react-modal'
import { colors } from '../../../constants/styles'
import MuiModal from '../../mui/Modal'
import Button from '../../mui/redesignedComponents/Button'
import styles from './DeleteConfirmationModal.module.css'

type DeleteConfirmationModalBaseProps = {
  shortlistName: string
  onConfirm: VoidFunction
  onClose: VoidFunction
  // to disable buttons while deleting is in progress
  isProcessing: boolean
}

const DeleteConfirmationModalBase = ({
  onConfirm,
  onClose,
  shortlistName,
  isProcessing,
}: DeleteConfirmationModalBaseProps) => (
  <div className={styles.content}>
    <span className={styles.title}>Are you sure?</span>
    <p className={styles.description}>Do you really want to remove the &quot;{shortlistName}&quot; shortlist?</p>
    <div className={styles.actions}>
      <Button
        onClick={onConfirm}
        variant="contained"
        fullWidth
        style={{
          backgroundColor: isProcessing ? colors.gray500 : colors.red500,
          color: 'white',
        }}
        disabled={isProcessing}
      >
        Delete
      </Button>
      <Button onClick={onClose} variant="text" fullWidth disabled={isProcessing}>
        Cancel
      </Button>
    </div>
  </div>
)

const DeleteConfirmationModal = ({
  isOpen,
  ...props
}: DeleteConfirmationModalBaseProps & Pick<ModalProps, 'isOpen'>) => (
  <MuiModal isOpen={isOpen} zIndex={5} hasRoundedCorners enforcedWidth="small">
    <DeleteConfirmationModalBase {...props} />
  </MuiModal>
)

export default DeleteConfirmationModal
