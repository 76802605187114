import moment from 'moment'
import { BeatLoader } from 'react-spinners'
import StarIcon from '../../../img/StarIcon.svg'
import VerifiedUserIcon from '../../../img/VerifiedUserIcon.svg'
import profileAvatar from '../../../img/profileAvatar.png'
import { staffers as staffersSchema } from '../../../staffers/qman/staffers'
import styles from './StafferPopover.module.css'

type Props = {
  referralIdOrPhone: string
  isPhone: boolean
}

const StafferPopoverReferralsListing = ({ referralIdOrPhone, isPhone }: Props) => {
  const referredStafferById = !isPhone ? staffersSchema.get('stafferById', [referralIdOrPhone]) : null
  const referredStaffersByPhone = isPhone ? staffersSchema.get('staffersByPhone', [referralIdOrPhone]) : null

  const referredStaffer = referredStafferById || referredStaffersByPhone?.[0] || null

  if (referredStaffer === undefined) {
    return <BeatLoader />
  }

  return referredStaffer ? (
    <div className={styles.referralWrapper}>
      <div className={styles.contentRow}>
        <div className={styles.referralAvatarWrapper}>
          <img src={referredStaffer?.photoUrl || profileAvatar} className={styles.referralAvatar} />
          {(!referredStaffer.documentsVerified || !referredStaffer.bankId) && (
            <img src={VerifiedUserIcon} className={styles.referralVerifiedUser} />
          )}
        </div>
        <div className={styles.cardColumn}>
          <span style={{ fontSize: 14, marginBottom: 6 }}>
            {referredStaffer.nameFirst} {referredStaffer.nameLast}
          </span>
          <div className={styles.contentRow}>
            <img src={StarIcon} style={{ width: 12, height: 12, marginRight: 4 }} />
            <span className={styles.greenTextSmall}>
              {referredStaffer.ratingsCount > 0
                ? (referredStaffer.ratingsTotal / referredStaffer.ratingsCount).toFixed(2)
                : 'No ratings'}
            </span>
            <span className={styles.dot}>·</span>
            <span className={styles.textSmall}>
              {referredStaffer.totalShifts && referredStaffer.totalShifts > 0
                ? `${referredStaffer.totalShifts} jobs`
                : 'No jobs'}
            </span>
          </div>
        </div>
      </div>
      <span className={styles.textSmall}>{moment(referredStaffer.createdAt.toMillis()).format('DD.MM.YYYY')}</span>
    </div>
  ) : (
    <div className={styles.referralWrapper}>
      <span style={{ fontStyle: 'italic' }}>Staffer data unavailable</span>
    </div>
  )
}

export default StafferPopoverReferralsListing
