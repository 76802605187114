export const STAFFER_IMPORT_TRANSLATIONS = {
  nameFirst: ['fornavn', 'förnamn', 'first name'],
  nameLast: ['etternavn', 'efternamn', 'last name'],
  email: ['email', 'e-mail', 'e-post', 'epost adresse', 'mail'],
  phone: ['mobile', 'mobile phone', 'mobil', 'telefon', 'mobiltelefon', 'telefonnr.'],
  birthday: ['birthdate', 'birthday', 'fødselsdato', 'fodseldato'],
  identityNumber: ['socialsecuritynumber', 'fødselsnr', 'fødselsnummer', 'personnummer', 'identity number'],
  accountNumber: ['kontonummer', 'bankkontonummer', 'bank account number', 'account number', 'bankkonto'],
  address: ['addresse', 'addresse1', 'address', 'adresselinje'],
  city: ['poststed', 'sted', 'by', 'city'],
  postalCode: ['postal code', 'postnr', 'post nr', 'postcode', 'postnummer'],
  positions: ['position', 'positions', 'job access', 'stilling', 'roller'],
  customWage: [
    'cutom wage',
    'permanent wage',
    'contract wage',
    'hourly wage',
    'wage / hour',
    'hourly salary',
    'tilpasset lønn',
    'sett lønn',
    'legg til lønn',
  ],
}

export const FIELD_LABELS: { [key: string]: string } = {
  nameFirst: 'First name',
  nameLast: 'Last name',
  email: 'E-mail',
  phone: 'Phone',
  birthday: 'Date of birth',
  identityNumber: 'Identity number',
  accountNumber: 'Bank account number',
  address: 'Street Address',
  city: 'City',
  postalCode: 'Postal code',
  positions: 'Positions',
  customWage: 'Permanent contract wage',
  contractPercentage: 'Contract in percentage',
}

export const REQUIRED_IMPORT_FIELDS: (keyof typeof STAFFER_IMPORT_TRANSLATIONS)[] = [
  'nameFirst',
  'nameLast',
  'email',
  'phone',
  'positions',
]

export const POSITION_TRANSLATIONS: { [key: string]: string[] } = {
  Barback: ['Barback'],
  Barista: ['Barista'],
  Bartender: ['Bartender'],
  Cashier: ['Cashier', 'Kassemedarbeider'],
  Chef: ['Chef', 'Kokk'],
  Cleaner: ['Cleaner', 'Rydd', 'Vaskehjelp'],
  Cover: ['Cover'],
  Dishwasher: ['Dishwasher', 'Oppvsak', 'Oppvsakhjelp'],
  'Event Bartender': ['Event Bartender'],
  'Host/Hostess': ['Host', 'Hostess', 'Host/Hostess', 'Vert', 'Vertine', 'Vert/Vertine'],
  'Event Crew': ['Event Crew'],
  Housekeeper: ['Housekeeper'],
  'Kitchen Assistant': ['Kitchen Assistant', 'Kjøkken assistent'],
  Pizzaiolo: ['Pizzaiolo'],
  Runner: ['Runner'],
  Waiter: ['Waiter', 'Servitør'],
  Wardrobe: ['Wardrobe', 'Garderobe'],
}

// legacy format, kept for compatibility reasons
// TODO: Rewrite funcitons to use new format instead and delete
export const LEGACY_POSITION_TRANSLATIONS: Record<string, string> = {
  Barback: 'Barback',
  Barista: 'Barista',
  Bartender: 'Bartender',
  Kassemedarbeider: 'Cashier',
  Kokk: 'Chef',
  Rydd: 'Cleaner', // Tamigo style
  Vaskehjelp: 'Cleaner',
  Cover: 'Cover',
  Oppvask: 'Dishwasher', // Tamigo style
  Oppvaskhjelp: 'Dishwasher',
  'Event bartender': 'Event Bartender',
  'Event crew': 'Event Crew',
  'Vert/vertine': 'Host/Hostess',
  Housekeeper: 'Housekeeper',
  'Kjøkken assistent': 'Kitchen Assistant',
  Pizzaiolo: 'Pizzaiolo',
  Runner: 'Runner',
  Servitør: 'Waiter',
  Garderobe: 'Wardrobe',
}
