export const isInternationalFormat = (phone: string): boolean =>
  phone.trim().startsWith('+') || phone.trim().startsWith('00')

// Returns a standardized version of a phone number, without formatting
// If the phone number does not have an international prefix, it defaults to Norway (+47)
export const standardized = (phone: string): string => {
  const phoneInternational = isInternationalFormat(phone) ? phone : `+47${phone}`
  return `+${phoneInternational.replace(/\D/g, '').replace(/\s/g, '').replace(/^00/, '')}`
}

// Returns tru if given string is possibly phone number (or part of it)
export const isPhoneNumber = (txt: string): boolean => !!txt.match('^[+]?[0-9 ]{0,20}$')

// Compares two phone numbers, regardless of their formatting
export const isSamePhone = (phoneA: string, phoneB: string): boolean => standardized(phoneA) === standardized(phoneB)

// For some reason, in recent versions, the phoneInput omits + when storing the number into state
export const fixCountryDialCode = (phone: string, dialCode?: string) => {
  if (dialCode) {
    const usesDialCode = dialCode ? dialCode === phone.slice(0, dialCode.length) : false
    return usesDialCode ? `+${phone}` : phone
  }
  return phone
}
