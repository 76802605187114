import { Box, Button, Typography } from '@material-ui/core'
import { LaunchTwoTone as ExternalLinkIcon } from '@material-ui/icons'
import React from 'react'
import { toast } from 'react-toastify'
import type { Integration } from '../../../../src/types/integrations'
import { errorToast } from '../../helpers/toast'
import integrationsAPI from '../../staffers/api/integrations'
import { connectFirestore } from '../../staffers/qman/connectFirestore'
import Modal from '../mui/Modal'
import ModalHeader from '../mui/ModalHeader'
import type { IntegrationsCtx } from '../providers/IntegrationsProvider'
import styles from './modals.module.css'

type Props = {
  integration: Integration
  integrationsCtx: IntegrationsCtx
  managerId: string
  onClose: () => any
  reason: string
}

class IntegrationReauth extends React.Component<Props> {
  auth = async () => {
    const { integrationsCtx, integration, managerId, onClose } = this.props
    try {
      const ok = await integrationsAPI.auth(integration, managerId)
      if (ok) {
        await integrationsCtx.refetchList()
        onClose()
        toast.success('Sucessfully authorized')
        toast.info('You can repeat your action now')
      }
    } catch (e) {
      errorToast(String(e))
      console.error(e)
    }
  }

  render() {
    const { integration, reason, onClose } = this.props
    return (
      <Modal isOpen>
        <ModalHeader>Unauthorized</ModalHeader>
        <Box p={2}>
          <Typography>Your request could not be finished.</Typography>
          <Typography>{reason}</Typography>
          <Box className={styles.buttonWrapperColumn}>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={this.auth}
              variant="contained"
              color="primary"
              endIcon={<ExternalLinkIcon fontSize="small" />}
            >
              Login to {integration.name}
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  }
}

export default connectFirestore(
  (db, props: Record<string, any>, uid: string) => ({
    managerId: uid,
  }),
  IntegrationReauth
)
