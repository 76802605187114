import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import type { Firestore } from '../../../../../src/types/firebase'
import type { StafferRatingsType, StafferRatingType, StafferType } from '../../../../../src/types/staffer'
import storage = firebase.storage

export const updateStafferProfile = async (
  db: Firestore,
  stafferId: string | null,
  stafferData: Partial<StafferType>
): Promise<void> =>
  db
    .collection('staffers')
    .doc(stafferId || undefined)
    .update(stafferData)

export const updateStafferRating = async (
  stafferId: string,
  businessId: string,
  jobId: string,
  shiftId: string,
  timeStart: firebase.firestore.Timestamp | undefined,
  timeEnd: firebase.firestore.Timestamp | undefined,
  value: number,
  comment: string,
  businessName: string,
  jobType: string
): Promise<void> => {
  const database = firebase.firestore()
  const ratingsRef = database.collection('staffers').doc(stafferId).collection('ratings').doc(businessId)

  return database.runTransaction(async (transaction) => {
    const ratingsDoc = await transaction.get(ratingsRef)
    const { ratings = [] } = ratingsDoc.data() || ({} as StafferRatingsType)

    const previousRating = ratings.find(
      (rating: StafferRatingType) => rating.jobId === jobId && rating.shiftId === shiftId
    )

    if (previousRating) {
      previousRating.comment = comment
      previousRating.value = value
      previousRating.jobType = jobType
    } else {
      ratings.push({
        jobId,
        shiftId,
        comment,
        value,
        jobType,
        timeStart,
        timeEnd,
      })
    }

    const ratingsCount = ratings.length
    const ratingsTotal = ratings.reduce((total: number, rating: StafferRatingType) => total + rating.value, 0)

    await transaction.set(
      ratingsRef,
      {
        ratingsCount,
        ratingsTotal,
        ratings,
        businessId,
        businessName,
      },
      { merge: true }
    )
  })
}

export const getStafferJobAdCv = async (stafferId: string, jobAdId: string, cvName: string) => {
  try {
    const initializedStorage = storage()

    const storageRef = initializedStorage.ref().child(`staffers/${stafferId}/cv/${jobAdId}/${cvName}`)
    const downloadUrl = await storageRef.getDownloadURL()
    const metaData = await storageRef.getMetadata()
    const { name } = storageRef

    return {
      [stafferId]: {
        name,
        downloadUrl,
        metaData,
      },
    }
  } catch (error) {
    console.warn(`Skipping fetch of removed cv of deleted staffer profile ${stafferId} (gdpr)`)
    return {}
  }
}
