import { TextField as MuiTextField, withStyles } from '@material-ui/core'

export const TextField = withStyles({
  root: {},
  select: {
    padding: '12px',
    borderRadius: '8px',
  },
})(MuiTextField)

export default TextField
