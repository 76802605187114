import type { Firestore, PossibleFirestores } from '../../../../../src/types/firebase'
import type { ManagerType } from '../../../../../src/types/manager'

export const getManagerById = (db: Firestore, userId: string | null) =>
  db.collection('managers').doc(userId || undefined)

export const getManagersByPhone = (db: Firestore, phone: string) =>
  db.collection('managers').where('phone', '==', phone)

export const getAllBusinessManagers = (db: Firestore, businessId: string | null) =>
  db.collection('managers').where('businessId', '==', businessId)

export const getGroupManagers = (db: Firestore, groupId: string) =>
  db.collection('managers').where('groupId', '==', groupId)

export const getGroupBusinesses = (db: Firestore, groupId: string) =>
  db.collection('business').where('groupId', '==', groupId)

export const getInvitedGroupManagers = (db: Firestore, groupId: string) =>
  db.collection('managersInvites').where('groupId', '==', groupId).where('used', '==', false)

export const getManagerByIdPromise = async (db: PossibleFirestores, userId: string | null) =>
  db
    .collection('managers')
    .doc(userId || undefined)
    .get()
    .then((doc) => (doc.exists ? (doc.data() as ManagerType) : null))

// Most of the time we care more about the business data than the manager
// This function returns both at the same time in an array
export const getManagerAndBusinessByManagerId = async (db: Firestore, userId: string) => {
  const managerData = await getManagerByIdPromise(db, userId)

  return [
    managerData,
    managerData && managerData.businessId ? await db.collection('business').doc(managerData.businessId).get() : null,
  ]
}

export const getManagersInvites = async (db: Firestore) => db.collection('managersInvites').where('used', '==', false)

export const getManagersInviteByBusinessId = async (db: Firestore, businessId: string) =>
  db.collection('managersInvites').where('businessId', '==', businessId).where('used', '==', false)

export const getManagerInviteById = async (db: Firestore, uid: string) => {
  const managerData = await getManagerByIdPromise(db, uid)
  if (managerData) {
    return db.collection('managersInvites').where('email', '==', managerData.email)
  }
  return null
}

export const getManagerInviteByInviteId = (db: Firestore, inviteId: string) =>
  db.collection('managersInvites').doc(inviteId)

export const getManagersReferralsOrdered = (db: Firestore) => db.collection('managersReferrals').orderBy('due', 'desc')

export const getManagerReferrals = (db: Firestore, uid: string | null) =>
  db.collection('managersReferrals').doc(uid || undefined)

export const getManagerReferralsPromise = (db: Firestore, uid: string | null) =>
  db
    .collection('managersReferrals')
    .doc(uid || undefined)
    .get()
    .then((doc) => (doc.exists ? doc.data() : null))

export const getManagersByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('managers').where('businessId', '==', businessId)

export const getGroupManagersByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('managers').where('groupBusinesses', 'array-contains', businessId)
