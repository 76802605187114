import Gleap from 'gleap'
import React, { Component } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
// @ts-ignore package with no exported types
import { connectFirestore } from 'react-firestore-connect'
import type { Firestore } from '../../../src/types/firebase'
import type { ManagerTypeWithId } from '../../../src/types/manager'
import Header from '../components/header/header'
import LoginCart from '../components/loginCart/LoginCart'
import EditCompanyProfile from '../components/modals/EditCompanyProfile'
import VerifyCart from '../components/verifyCart/VerifyCart'
import { getManagerById } from '../staffers/api/getters/managers.legacy'
import styles from './logIn.module.css'

type Props = {
  userId?: string
  signUpStep?: string
  recalculateAppStatus: () => Promise<void>
  businessId?: string | null
  manager?: ManagerTypeWithId | null
} & RouteComponentProps

type State = {
  phone: string
  email: string
}

class LogIn extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { location } = props
    if (location && location.search) {
      const { search } = location
      const params = {} as Record<string, string>
      search.split('&').forEach((kv) => {
        const [key, value] = kv.split('=')
        const stringValue = decodeURIComponent(value)
        let realValue: string | boolean = stringValue
        // We need to have a look whether the value actually isn't boolean
        if (stringValue === 'false') {
          realValue = false
        } else if (stringValue === 'true') {
          realValue = true
        }
        // This typecast is technically incorrect, but since it works, we're leaving as is for now
        params[decodeURIComponent(key.replace('?', '')) as keyof typeof params] = realValue as string
      })
      this.state = {
        email: params.email || '',
        phone: params.phone || '',
      }
    } else {
      this.state = {
        email: '',
        phone: '',
      }
    }
  }

  componentDidUpdate() {
    const { manager } = this.props
    if (manager) {
      const { email, phone } = manager
      Gleap.identify(manager.userId, { email, phone })
    }
  }

  render() {
    const { recalculateAppStatus, signUpStep } = this.props
    const { email, phone } = this.state

    // Switches the sign up step based on which the user left last
    const currentSignUpStep = () => {
      if (signUpStep) {
        switch (signUpStep) {
          case 'verifyPhone':
            return (
              <React.Fragment>
                <VerifyCart phone={phone} />
              </React.Fragment>
            )
          case 'setupBusiness':
            return (
              <React.Fragment>
                <EditCompanyProfile businessId={this.props.businessId} isNewCompany />
              </React.Fragment>
            )
          default:
            return (
              <div className={styles.loaderWrapper}>
                <BeatLoader />
              </div>
            )
        }
      }
      return <LoginCart email={email} recalculateAppStatus={recalculateAppStatus} />
    }

    return (
      <div className={styles.container}>
        <Header explicitTitle="Login / Sign Up" creatingProfile={!!signUpStep} />
        <div className={styles.wrapper}>
          {/* We don't display logo on last step of setting up company profile
              in order to save screen space (bette UX than having to scroll) */}
          {signUpStep !== 'setupBusiness' && (
            // eslint-disable-next-line global-require
            <img src={require('../img/logoWithName.png')} alt="Staffers Logo" className={styles.logo} />
          )}
          <div className={signUpStep === 'setupBusiness' ? styles.setupBusiness : styles.body}>
            {currentSignUpStep()}
          </div>
        </div>
      </div>
    )
  }
}

export default connectFirestore(
  (db: Firestore, props: Props, uid: string) => ({
    manager: uid ? getManagerById(db, uid) : null,
  }),
  withRouter(LogIn)
)
