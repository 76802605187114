import { Box, Card } from '@material-ui/core'
import type { ReactNode } from 'react'
import type { Props as ModalProps } from 'react-modal'
import ReactModal from 'react-modal'
import styles from './Modal.module.css'

type Props = {
  children: ReactNode
  zIndex?: number
  // by default the width is 90vw
  enforcedWidth?: 'small' | 'medium' | 'large' | 'extraLarge'
  hasRoundedCorners?: boolean
} & ModalProps

const WIDTH_CLASSES = {
  small: styles.enforcedSmallWidthModal,
  medium: styles.enforcedMediumWidthModal,
  large: styles.enforcedLargeWidthModal,
  extraLarge: styles.enforcedExtraLargeWidthModal,
} as const

// Actually uses ReactModal implementation but with MUI design.
const MuiModal = ({ children, hasRoundedCorners = false, ...props }: Props) => (
  <ReactModal
    className={`${styles.modal} ${props.enforcedWidth ? WIDTH_CLASSES[props.enforcedWidth] : ''}`}
    style={{
      overlay: {
        zIndex: props.zIndex || 4,
      },
      // This is to prevent blurry text in modals
      // - see https://github.com/reactjs/react-modal/issues/188
      content: {
        borderRadius: hasRoundedCorners ? 16 : 0,
      },
    }}
    ariaHideApp={false}
    {...props}
  >
    <Card
      square // this also prevents the blurry bug
      elevation={0}
      className={styles.card}
    >
      <Box display="flex" flexDirection="column" flexGrow={1}>
        {children}
      </Box>
    </Card>
  </ReactModal>
)

export default MuiModal
