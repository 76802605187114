import { Box, Button, Grid, Typography } from '@material-ui/core'
import { Component, Fragment } from 'react'
// @ts-ignore react-firestore-connect has no exported @types/module
import { connectFirestore } from 'react-firestore-connect'
import { BeatLoader } from 'react-spinners'
import type { BusinessType } from '../../../../src/types/business'
import type { SuperAdminType } from '../../../../src/types/common'
import type { Firestore } from '../../../../src/types/firebase'
import type { JobType } from '../../../../src/types/jobs'
import type { ManagerType } from '../../../../src/types/manager'
import type { PermissionsType, PoolType } from '../../../../src/types/pools'
import type { StafferType, StafferTypePaymentDetails } from '../../../../src/types/staffer'
import { getCurrencyByBusiness } from '../../helpers/regionSelector'
import { formatHours } from '../../helpers/time'
import { getAdminById } from '../../staffers/api/getters/admins.legacy'
import { getBusinessById } from '../../staffers/api/getters/business.legacy'
import { getJobsByStafferId } from '../../staffers/api/getters/jobs.legacy'
import { getManagerById } from '../../staffers/api/getters/managers.legacy'
import { getPoolById } from '../../staffers/api/getters/pools.legacy'
import { getStafferById, getStafferDetailsById, getStafferPermissions } from '../../staffers/api/getters/staffer.legacy'
import BillingBadge from '../badges/BillingBadge'
import modalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import styles from './modals.module.css'

type StafferRowDetailType = {
  beautifulDate: string
  duration: number
  hourlyWage: number
  isFreelanceJob: boolean
  isHolidayJob: boolean
  stafferName: string
  wage: number
}

type Props = {
  business?: BusinessType
  currentCountry: string
  jobs?: Array<JobType>
  manager?: ManagerType
  markAsPaid?: () => void
  onRequestClose: () => void
  pool?: PoolType
  staffer: StafferType
  stafferDetails: StafferTypePaymentDetails
  stafferId: string
  stafferPermissions?: PermissionsType
  stafferRowDetails?: Array<StafferRowDetailType>
  superAdmin: SuperAdminType
  totalWage: number
}

class StafferPaymentDetails extends Component<Props> {
  render() {
    const {
      business,
      currentCountry,
      markAsPaid,
      onRequestClose,
      pool,
      staffer,
      stafferDetails,
      stafferId,
      stafferPermissions,
      stafferRowDetails,
      superAdmin,
      totalWage,
    } = this.props

    const isSuperAdmin = !!superAdmin

    if (
      (!isSuperAdmin && (business === undefined || pool === undefined)) ||
      !stafferDetails ||
      !staffer ||
      !stafferPermissions
    ) {
      return (
        <Box p={3} textAlign="center">
          <BeatLoader />
        </Box>
      )
    }

    const { haveFrikort, nameFirst, nameLast, regionalDetails } = stafferDetails
    const { accessGranted, accessRequested, billingType, email } = staffer

    const { accountNumber, address, identityNumber } = (regionalDetails || {})[currentCountry] || {}
    const { city, country, extra, postalCode, street } = address || {}

    // get custom wage from permissions
    const { businessData } = stafferPermissions
    // Wage can be left empty for superadmins
    let wage = isSuperAdmin ? null : ((businessData || {})[(business && business.businessId) || ''] || {}).customWage
    let isWageFromConnectedBusiness = false
    if (!wage && !isSuperAdmin) {
      // get from connected business
      wage = (
        Object.entries(businessData || {})
          .filter(
            (
              [id] // only connected businesses
            ) => pool && pool.connectedBusinesses.includes(id)
          )
          .map(([, data]) => data)
          .find(({ customWage }) => !!customWage) || {}
      ).customWage
      if (wage) {
        isWageFromConnectedBusiness = true
      }
    }

    const canShow = isSuperAdmin || this.hasConfirmedJobs()

    // User is only employee if he has access granted but never requested it - we should not expose
    // payment details of internal employees in our system
    const isOnlyEmployee = accessGranted && !accessRequested
    if (!canShow || (isOnlyEmployee && isSuperAdmin)) {
      return (
        <div>
          <h1 className={styles.paymentDetails}>
            {`You are not able to see payment details of ${isOnlyEmployee ? 'an internal employee' : 'this staffer'}.`}
          </h1>
        </div>
      )
    }

    const currency = getCurrencyByBusiness(business)

    const holidayJobsCount = (stafferRowDetails || []).filter(({ isHolidayJob }) => isHolidayJob).length

    return (
      <Fragment>
        <ModalHeader close={onRequestClose}>
          Payment details of {nameFirst} {nameLast}
        </ModalHeader>
        <Box p={3} className={modalStyles.modalContent}>
          <div>{superAdmin && billingType && <BillingBadge billingType={billingType} />}</div>
          <Grid container>
            <Grid item xs={6}>
              {identityNumber && !markAsPaid && <h2>Identity number</h2>}
              {nameFirst && <h2>First name</h2>}
              {nameLast && <h2>Last name</h2>}
              {email && <h2>Email</h2>}
              {!isSuperAdmin && city && <h2>City</h2>}
              {country && <h2>Country</h2>}
              {postalCode && <h2>Postal Code</h2>}
              {street && <h2>Street</h2>}
              {extra && <h2>{extra}</h2>}
              {accountNumber && <h2>Account number</h2>}
              {!isSuperAdmin && <h2>Fikort / free tax card</h2>}
              {!isSuperAdmin && !!wage && <h2>Hourly wage</h2>}
              {stafferId && <h2>Unique ID</h2>}
              {stafferRowDetails && <h2>Dates / hours / hourly pay</h2>}
            </Grid>
            <Grid item xs={6}>
              {!markAsPaid && <h2 className={styles.paymentDetails}>{identityNumber}</h2>}
              <h2 className={styles.paymentDetails}>{nameFirst}</h2>
              <h2 className={styles.paymentDetails}>{nameLast}</h2>
              <h2 className={styles.paymentDetails}>{email}</h2>
              {!isSuperAdmin && <h2 className={styles.paymentDetails}>{city}</h2>}
              <h2 className={styles.paymentDetails}>{country}</h2>
              {<h2 className={styles.paymentDetails}>{postalCode}</h2>}
              {<h2 className={styles.paymentDetails}>{street}</h2>}
              {extra && <h2 className={styles.paymentDetails}>{extra}</h2>}
              {accountNumber && <h2 className={styles.paymentDetails}>{accountNumber || 'no bank account number'}</h2>}
              {!isSuperAdmin && <h2 className={styles.paymentDetails}>{haveFrikort ? 'Yes' : 'No'}</h2>}
              {!isSuperAdmin && !!wage && (
                <h2 className={styles.paymentDetails}>
                  {' '}
                  {wage} {isWageFromConnectedBusiness && '*'}
                </h2>
              )}
              {stafferId && <h2 className={styles.paymentDetails}>{stafferId}</h2>}
              {stafferRowDetails &&
                stafferRowDetails
                  .filter(({ isFreelanceJob }) => !isFreelanceJob)
                  .map(({ beautifulDate, duration, hourlyWage, isHolidayJob }, index) => (
                    <h2 key={index} className={styles.paymentDetails}>
                      {`${beautifulDate === null ? '-' : beautifulDate} / `}
                      {`${duration === null ? '-' : formatHours(duration)} / `}
                      {`${hourlyWage} ${currency} ${isHolidayJob ? '*' : ''}`}
                    </h2>
                  ))}
            </Grid>
          </Grid>
          <hr />
          {isWageFromConnectedBusiness && (
            <Box pt={1} pb={3}>
              <Typography variant="caption">* This wage is given by connected business</Typography>
            </Box>
          )}
          <Grid container>
            <Grid item xs={6}>
              {!!totalWage && !markAsPaid && (
                <Fragment>
                  <h2>
                    Total amount to be paid <br />
                  </h2>
                  {holidayJobsCount > 0 && (
                    <div>
                      {`* This is the total amount for the job${
                        holidayJobsCount > 1 ? 's' : ''
                      }, no extra percentages should be added.`}
                    </div>
                  )}
                </Fragment>
              )}
              {!!totalWage && markAsPaid && (
                <h2>
                  Total amount to be paid <br />
                  *For referrals
                </h2>
              )}
            </Grid>
            {!!totalWage && (
              <Grid item xs={6} className={styles.columnRight}>
                <h2 className={styles.paymentDetails}>
                  {Math.round(totalWage)} {currency}
                </h2>
              </Grid>
            )}
          </Grid>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={3}>
              <Button color="default" variant="contained" onClick={onRequestClose}>
                Close
              </Button>
            </Grid>
            {markAsPaid && (
              <Grid item xs={3}>
                <Button color="primary" variant="contained" onClick={markAsPaid}>
                  Mark as paid
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Fragment>
    )
  }

  hasConfirmedJobs = () => {
    const { jobs, manager } = this.props
    return (
      jobs &&
      manager &&
      jobs.some(
        ({ shifts, businessId }) =>
          businessId === manager.businessId && shifts.some(({ staffersConfirmed }) => staffersConfirmed.length > 0)
      )
    )
  }
}

export default connectFirestore(
  (db: Firestore, props: Props, uid: string) => ({
    jobs: getJobsByStafferId(db, props.stafferId),
    manager: uid && getManagerById(db, uid),
    staffer: getStafferById(db, props.stafferId),
    stafferDetails: getStafferDetailsById(db, props.stafferId),
    stafferPermissions: getStafferPermissions(db, props.stafferId),
    superAdmin: uid && getAdminById(db, uid),
  }),
  connectFirestore(
    (db: Firestore, props: Props) => ({
      business: props.manager && props.manager.businessId ? getBusinessById(db, props.manager.businessId) : undefined,
      pool: props.manager && props.manager.businessId ? getPoolById(db, props.manager.businessId) : undefined,
    }),
    StafferPaymentDetails
  )
)
