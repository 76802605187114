import { Box, Button, Grid } from '@material-ui/core'
import * as R from 'ramda'
import type { ChangeEvent } from 'react'
import { Component, Fragment } from 'react'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import type { BusinessTypeWithId } from '../../../../../src/types/business'
import type { GroupType } from '../../../../../src/types/groups'
import type { ManagerTypeWithId } from '../../../../../src/types/manager'
import { errorToast } from '../../../helpers/toast'
import { createGroup, updateGroup } from '../../../staffers/api/firestore/groups'
import { getGroupById } from '../../../staffers/api/getters/groups'
import { getGroupBusinesses, getGroupManagers } from '../../../staffers/api/getters/managers'
import { connectFirestore } from '../../../staffers/qman/connectFirestore'
import styles from '../../mui/Modal.module.css'
import ModalHeader from '../../mui/ModalHeader'
import GroupDetails from './GroupDetails'
import GroupPreview from './GroupPreview'
import InviteManagerToGroup from './InviteManagerToGroup'
import SelectGroupManagers from './SelectGroupManagers'

export type SelectBusinessType = {
  businessId: string
  businessName: string
  isDisabled?: boolean
}

type Props = {
  group: GroupType
  // eslint-disable-next-line react/no-unused-prop-types
  groupId: string // used in CF
  groupManagers: Array<ManagerTypeWithId>
  groupBusinesses: Array<BusinessTypeWithId>
  onClose: () => void
}

type State = {
  currentStep: number
  email: string
  emailError: string
  name: string
  addBusinessToExistingManagers: boolean
  selectedBusinesses: Array<SelectBusinessType>
  selectedGroupManagers: Array<ManagerTypeWithId>
  isProcessing: boolean
}

class CreateEditGroup extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    if (props.group) {
      const { group, groupManagers, groupBusinesses } = props
      const { name } = group

      this.state = {
        currentStep: 1,
        email: '',
        emailError: '',
        name,
        selectedBusinesses: groupBusinesses.map(({ id, businessName }) => ({ businessId: id, businessName })),
        selectedGroupManagers: groupManagers,
        addBusinessToExistingManagers: true,
        isProcessing: false,
      }
    } else {
      this.state = {
        currentStep: 1,
        email: '',
        emailError: '',
        name: '',
        selectedBusinesses: [],
        selectedGroupManagers: [],
        addBusinessToExistingManagers: true,
        isProcessing: false,
      }
    }
  }

  toggleAddBusinessToExistingManagers = () =>
    this.setState((prevState) => ({
      addBusinessToExistingManagers: !prevState.addBusinessToExistingManagers,
    }))

  onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: event.target.value })
  }

  onToggleBusiness = (selectedBusiness: SelectBusinessType) => {
    const { selectedBusinesses, selectedGroupManagers: prevSelectedManagers } = this.state
    const { businessId } = selectedBusiness

    if (selectedBusinesses.some((businessEntry) => businessEntry.businessId === businessId)) {
      const selectedGroupManagers = prevSelectedManagers.filter((manager) => manager.businessId !== businessId)

      R.difference(prevSelectedManagers, selectedGroupManagers).forEach((manager) => {
        toast.info(
          `Removed group admin ${manager.email}, manager of ${selectedBusiness.businessName}. Please assign a new group admin in the next step`
        )
      })

      this.setState({
        selectedBusinesses: selectedBusinesses.filter((business) => business.businessId !== businessId),
        selectedGroupManagers,
      })
    } else {
      this.setState({
        selectedBusinesses: [...selectedBusinesses, selectedBusiness],
      })
    }
  }

  selectGroupManager = (manager: ManagerTypeWithId) => {
    const { selectedGroupManagers } = this.state
    if (!selectedGroupManagers.find(({ id }) => id === manager.id)) {
      this.setState({
        selectedGroupManagers: [manager, ...selectedGroupManagers],
      })
    }
  }

  unselectGroupManager = (manager: ManagerTypeWithId) => {
    this.setState(({ selectedGroupManagers }) => ({
      selectedGroupManagers: selectedGroupManagers.filter(({ id }) => manager.id !== id),
    }))
  }

  onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value
    this.setState({
      emailError: this.getEmailError(email, false),
      email: email.toLowerCase(),
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getEmailError = (email: string, exact: boolean) => {
    if (!email && !exact) {
      return ''
    }

    const regexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gim
    if (!email.trim().match(regexp)) {
      return 'Incorrect format'
    }

    return ''
  }

  renderStep = () => {
    const { group, groupBusinesses } = this.props
    const {
      currentStep,
      addBusinessToExistingManagers,
      selectedBusinesses,
      selectedGroupManagers,
      name,
      emailError,
      email,
    } = this.state

    switch (currentStep) {
      case 1: {
        return (
          <GroupDetails
            createGroupType="existing"
            groupId={group && group.id}
            groupName={name}
            onNameChange={this.onNameChange}
            onToggleBusiness={this.onToggleBusiness}
            selectedBusinesses={selectedBusinesses}
          />
        )
      }
      case 2: {
        return (
          <SelectGroupManagers
            addBusinessToExistingManagers={addBusinessToExistingManagers}
            toggleAddBusinessToExistingManagers={this.toggleAddBusinessToExistingManagers}
            selectedBusinesses={selectedBusinesses}
            selectedGroupManagers={selectedGroupManagers}
            groupBusinesses={groupBusinesses || []}
            onSelectGroupManager={this.selectGroupManager}
            onUnselectGroupManager={this.unselectGroupManager}
          />
        )
      }
      case 3: {
        // Only show invite manager by email field while editing group and if group is
        // interconnected
        if (group && group.type === 'interconnected') {
          return (
            <InviteManagerToGroup
              emailError={emailError}
              email={email}
              getEmailError={this.getEmailError}
              onEmailChange={this.onEmailChange}
            />
          )
        }
        // Otherwise keep it as 3 step process
        return (
          selectedGroupManagers && (
            <GroupPreview
              groupName={name}
              emailToBeInvited={email}
              selectedBusinesses={selectedBusinesses}
              selectedGroupManagers={selectedGroupManagers}
            />
          )
        )
      } // We could just have fallthrough here, but I find this more readable
      default:
      case 4: {
        return (
          selectedGroupManagers && (
            <GroupPreview
              groupName={name}
              emailToBeInvited={email}
              selectedBusinesses={selectedBusinesses}
              selectedGroupManagers={selectedGroupManagers}
            />
          )
        )
      }
    }
  }

  onNextStep = () => {
    this.setState((prevState) => ({ currentStep: prevState.currentStep + 1 }))
  }

  onPreviousStep = () => {
    this.setState((prevState) => ({ currentStep: prevState.currentStep - 1 }))
  }

  saveGroup = async () => {
    const { onClose, group, groupBusinesses } = this.props
    const { email, name, selectedBusinesses, selectedGroupManagers, addBusinessToExistingManagers } = this.state
    this.setState({
      isProcessing: true,
    })
    let data = {
      name,
      managerIds: selectedGroupManagers.map(({ id }) => id),
      businessesIds: selectedBusinesses.map(({ businessId }) => businessId),
      oldBusinessIds: (group && group.businesses) || [],
      addToExistingManagers: false,
    }
    try {
      if (group) {
        const isNewBusinessAdded = selectedBusinesses.some(({ businessId }) =>
          groupBusinesses.find((business) => business.id !== businessId)
        )
        if (isNewBusinessAdded && addBusinessToExistingManagers) {
          data = {
            ...data,
            addToExistingManagers: true,
          }
        }
        await updateGroup(group.id, data, group, selectedBusinesses, email)
        toast.success('Group updated successfully')
      } else {
        await createGroup(data)
        toast.success('Group created successfully')
      }
      onClose()
    } catch (e) {
      errorToast('Failed to save the group')
      console.error(e)
    } finally {
      this.setState({
        isProcessing: false,
      })
    }
  }

  render() {
    const { currentStep, emailError, name, selectedBusinesses, isProcessing } = this.state

    const { group, onClose } = this.props

    const nextStepDisabled =
      (currentStep === 1 && !(selectedBusinesses && selectedBusinesses.length > 0 && name && name.length > 2)) ||
      (currentStep === 3 && emailError)

    return (
      <Fragment>
        <ModalHeader close={onClose}>{group ? `Edit group: ${group.name}` : 'Create group'}</ModalHeader>
        <Box className={styles.modalContent} p={3}>
          {this.renderStep()}
        </Box>
        <Box pb={3}>
          {currentStep === 3 && isProcessing && (
            <Box pb={3} display="flex" justifyContent="center">
              <BeatLoader color="grey" />
            </Box>
          )}
          <Grid container spacing={2} justify="center">
            {currentStep > 1 && (
              <Grid item xs={4}>
                <Button fullWidth variant="contained" color="default" onClick={this.onPreviousStep}>
                  Previous step
                </Button>
              </Grid>
            )}
            {((currentStep < 4 && group && group.type === 'interconnected') ||
              // eslint-disable-next-line no-mixed-operators
              (currentStep < 3 && group && group.type !== 'interconnected') ||
              // eslint-disable-next-line no-mixed-operators
              (currentStep < 3 && this.props.groupBusinesses.length === 0)) && (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  disabled={!!nextStepDisabled}
                  variant="contained"
                  color="primary"
                  onClick={this.onNextStep}
                >
                  Next step
                </Button>
              </Grid>
            )}
            {((currentStep === 4 && group && group.type === 'interconnected') ||
              // eslint-disable-next-line no-mixed-operators
              (currentStep === 3 && group && group.type !== 'interconnected') ||
              // eslint-disable-next-line no-mixed-operators
              (currentStep === 3 && this.props.groupBusinesses.length === 0)) && (
              <Grid item xs={4}>
                <Button fullWidth disabled={isProcessing} variant="contained" color="primary" onClick={this.saveGroup}>
                  {group ? 'Save changes' : 'Create the group'}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Fragment>
    )
  }
}

export default connectFirestore(
  (db, { groupId }: Props) =>
    groupId
      ? {
          group: getGroupById(groupId),
          groupManagers: getGroupManagers(groupId),
          groupBusinesses: getGroupBusinesses(groupId),
        }
      : {},
  (props: Props) => {
    const { group, groupBusinesses, groupManagers } = props
    return !group || (groupBusinesses && groupManagers) ? (
      <CreateEditGroup {...props} groupBusinesses={groupBusinesses || []} />
    ) : (
      <BeatLoader />
    )
  }
)
