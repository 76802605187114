import { Badge, Box, Collapse, List, ListItem, Tooltip, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import type { ReactNode } from 'react'
import { Fragment, memo } from 'react'
import { Link } from 'react-router-dom'
import { TooltipDelays } from '../../../constants/tooltips'
import type { IconType, ItemType } from './itemCreator'
import { renderSidebarItems } from './itemCreator'
import styles from './sidebar.module.css'

type Props = {
  name: string
  activeItem: { activeParent: string; activeChild?: string }
  isSidebarOpen: boolean
  isCollapsibleChild?: boolean // true if is a collapsible child
  expandedItems: { [businessId: string]: boolean }
  toggleExpandableItem?: (event: string) => void
  icon?: IconType
  path?: string
  badge?: number | boolean
  collapsibleItems?: ItemType[] // collapsible items
}

type InteractiveListemitemProps = {
  children: ReactNode
  name: string
  isActive: boolean
  path?: string
  toggleExpandableItem?: (event: string) => void
  isCollapsible?: boolean
  isCollapsibleChild?: boolean
}

// Wraps the sidebar item in Link if there is a path any provided
// eslint-disable-next-line react/display-name
const InteractiveListItem = memo((props: InteractiveListemitemProps) => {
  const { children, isActive, name, path, toggleExpandableItem, isCollapsible, isCollapsibleChild } = props

  // eslint-disable-next-line no-nested-ternary
  const itemStyle = isActive
    ? isCollapsibleChild
      ? styles.childItemActive
      : styles.itemActive
    : isCollapsibleChild
    ? styles.childItem
    : styles.item

  // 2. Item with collapsible children
  if (toggleExpandableItem && isCollapsible) {
    return (
      <ListItem id={name} button className={itemStyle} onClick={() => toggleExpandableItem(name)}>
        {children}
      </ListItem>
    )
  }

  // 3. path redirection item
  if (path) {
    return (
      <Link to={path}>
        <ListItem id={name} className={itemStyle}>
          {children}
        </ListItem>
      </Link>
    )
  }
  // Fallback for @flow - but should never arrive here, should throw error first if neither path
  // nor collapsibleItems are provided (check main render method)
  return null
})

const SidebarItem = (props: Props) => {
  const {
    path,
    name,
    activeItem,
    isSidebarOpen,
    expandedItems,
    icon,
    badge,
    toggleExpandableItem,
    collapsibleItems,
    isCollapsibleChild = false,
  } = props

  const { activeParent, activeChild } = activeItem

  // Determines if is currently active from url
  const isActive = activeParent === name || activeChild === name
  // Determines if this element (parent) is collapsible
  const isCollapsible = collapsibleItems && collapsibleItems.length > 0
  // State representation of if it is a collapsible parent, whether it's expanded
  const isItemExpanded = expandedItems[name] || false

  /* Dev note: Since it may be a bit confusing from naming
   *   isCollapsible === parent that has collapsible children (true if it is)
   *   isCollapsibleChild === child of the collapsible parent (true if it is)
   */

  if (!path && !isCollapsible) {
    throw Error(`[SidebarItem.js]: Please provide either 'path' or 'collapsibleItems' to the SidebarItem [${name}]`)
  }

  if (isCollapsible && !toggleExpandableItem) {
    // eslint-disable-next-line max-len
    throw Error(
      `[SidebarItem.js]: Sidebar item needs to have specified 'toggleExpandableItem' prop if 'collapsibleItems' are provided [${name}]`
    )
  }

  return (
    <Fragment>
      <InteractiveListItem
        name={name}
        path={path}
        isActive={isActive}
        isCollapsible={isCollapsible}
        isCollapsibleChild={isCollapsibleChild}
        toggleExpandableItem={toggleExpandableItem}
      >
        <>
          {/* 1/2 - Icon (+badge) and Label */}
          <Box display="flex" alignItems="center" ml={isCollapsibleChild && isSidebarOpen ? 3 : 0}>
            <Box pr={2}>
              {isSidebarOpen ? (
                <Badge
                  color={badge === true ? 'error' : 'primary'}
                  variant={badge === true ? 'dot' : 'standard'}
                  badgeContent={badge}
                >
                  {icon}
                </Badge>
              ) : (
                <Tooltip leaveDelay={TooltipDelays.WITHOUT_LINKS} placement="right" title={name}>
                  <Badge
                    color={badge === true ? 'error' : 'primary'}
                    variant={badge === true ? 'dot' : 'standard'}
                    badgeContent={badge}
                  >
                    {icon}
                  </Badge>
                </Tooltip>
              )}
            </Box>
            {isSidebarOpen && <Typography>{name}</Typography>}
          </Box>
          {/* 2/2 - Badge & Collapsible arrow */}
          <Box display="flex" alignItems="center">
            {isCollapsible && (isItemExpanded ? <ExpandLess /> : <ExpandMore />)}
          </Box>
        </>
      </InteractiveListItem>
      {collapsibleItems && collapsibleItems.length > 0 && toggleExpandableItem && (
        <Collapse in={isItemExpanded} timeout="auto">
          <List disablePadding>
            {/* Recursive creation of nested child collapsibles (n levels deep) */}
            {renderSidebarItems(collapsibleItems, activeItem, isSidebarOpen, expandedItems, toggleExpandableItem, true)}
          </List>
        </Collapse>
      )}
    </Fragment>
  )
}

export default memo(SidebarItem)
