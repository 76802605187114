// NOTE: very similar logic as in src/screens/postJob/Addshift.js
import { Box, Button, Grid, Link, Switch, TextField, Tooltip, Typography } from '@material-ui/core'
import { Help as HelpIcon, Lock as LockIcon } from '@material-ui/icons'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import type { Duration, Moment } from 'moment'
import type { ChangeEvent } from 'react'
import React, { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import type { JobType, ShiftType, ShiftTypeBasic } from '../../../../src/types/jobs'
import { TooltipDelays } from '../../constants/tooltips'
import { detectUserBrowser } from '../../helpers/browser'
import { isHoliday } from '../../helpers/holidays'
import { timeStampFromDate } from '../../helpers/time'
import { errorToast } from '../../helpers/toast'
import moment from '../../util/moment'
import muiModalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import styles from './modals.module.css'

const MAX_JOB_DURATION_DAYS = 60

const JOB_SHIFT_MIN_POSITIONS = 1
const JOB_SHIFT_MAX_POSITIONS = 99
const MIN_TIME_BEFORE_START: Duration = moment.duration(1, 'hour')
const MIN_SHIFT_DURATION: Duration = moment.duration(30, 'minutes')
const MAX_SHIFT_DURATION: Duration = moment.duration(12, 'hours')

type Props = {
  saving?: boolean
  initialShift?: ShiftType | null
  isPrivate?: boolean
  job?: JobType
  shifts: Array<ShiftType>
  shiftIndex?: number
  closeModal?: () => any
  onSaveShift: (shift: ShiftTypeBasic) => any
  cancel?: () => any
  titlePrefix?: string
}

type State = {
  importedFromPlanday?: number
  breakDuration?: number
  positions: number
  positionsText: string
  timeStart: Moment | null
  timeEnd: Moment | null
}

class EditShift extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { initialShift } = props
    const defaultStart = moment().add(2, 'hour').startOf('hour')
    const defaultEnd = moment().add(6, 'hour').startOf('hour')

    const positions = initialShift ? initialShift.positions : JOB_SHIFT_MIN_POSITIONS
    this.state = {
      importedFromPlanday: initialShift?.importedFromPlanday,
      breakDuration: initialShift ? initialShift.breakDuration : undefined,
      positions,
      positionsText: positions.toString(),
      timeStart: initialShift ? moment(initialShift.timeStart) : defaultStart,
      timeEnd: initialShift ? moment(initialShift.timeEnd) : defaultEnd,
    }
  }

  render() {
    const { initialShift, isPrivate, saving, cancel, job, closeModal, shiftIndex, shifts, titlePrefix } = this.props

    if (saving) {
      return <BeatLoader color="gray" />
    }

    const { breakDuration, positions, positionsText, timeStart, timeEnd } = this.state

    const shiftDuration: Duration =
      timeStart && timeEnd
        ? moment.duration(timeEnd.diff(timeStart))
        : // @ts-ignore
          moment.duration.invalid() // invalid duration

    let shiftDurationText = null
    let shiftDurationValid = false
    if (shiftDuration.isValid()) {
      if (timeStart && moment(timeStart).subtract(MIN_TIME_BEFORE_START).isBefore()) {
        shiftDurationText = `Cannot start in less than ${MIN_TIME_BEFORE_START.humanize()}`
      } else if (shiftDuration.asMinutes() < 0) {
        shiftDurationText =
          'Cannot end before it starts. Are you sure that you put the correct date in “End of the shift”?'
      } else if (shiftDuration.asMinutes() < MIN_SHIFT_DURATION.asMinutes()) {
        shiftDurationText = `Cannot last less than ${MIN_SHIFT_DURATION.humanize()}.`
      } else if (shiftDuration.asMinutes() > MAX_SHIFT_DURATION.asMinutes()) {
        shiftDurationText = `Cannot last more than ${MAX_SHIFT_DURATION.humanize()}`
      } else if (
        shifts &&
        shifts.filter((shift) => Math.abs(moment(shift.timeStart).diff(timeStart, 'days')) >= MAX_JOB_DURATION_DAYS)
          .length
      ) {
        shiftDurationText = `Shifts can not be apart more than ${MAX_JOB_DURATION_DAYS} days`
      } else {
        const hours = Math.floor(shiftDuration.asHours())
        const minutes = shiftDuration.minutes()
        shiftDurationText = `${hours} hours ${minutes} minutes`
        shiftDurationValid = true
      }
    }

    const jobShift = !!shifts && typeof shiftIndex === 'number' && shifts[shiftIndex]
    const staffersConfirmed = (jobShift && jobShift.staffersConfirmed) || []

    // Use a different time picker because the native one is not well supported
    const isSafari = detectUserBrowser() === 'Safari'
    console.log('timeEnd', timeEnd?.format('HH:mm'))
    return (
      <React.Fragment>
        <ModalHeader close={closeModal} goBack={cancel}>
          <span className={muiModalStyles.modalSubtitle}>{titlePrefix && `${titlePrefix} - `}</span>
          {!initialShift ? 'Add Shift' : 'Edit Shift'}
          <Tooltip
            className={styles.editShiftTooltip}
            interactive
            leaveDelay={TooltipDelays.WITH_LINKS}
            title={
              <Box>
                <Typography variant="body2">
                  <Link
                    href="http://help.staffers.no/en/articles/4923441-how-to-set-the-date-and-time-frame-of-an-internal-shift"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {'Click here '}
                  </Link>
                  for more information on how to set date/time for an internal shift.
                </Typography>
              </Box>
            }
          >
            <HelpIcon fontSize="small" />
          </Tooltip>
        </ModalHeader>
        <Box p={3} className={muiModalStyles.modalContent}>
          <Grid container className={styles.EditShift} direction="column" justify="space-between">
            <Grid item>
              {(!initialShift || staffersConfirmed.length === 0) && (
                <Box display="flex">
                  <Box>
                    <Box>
                      <Typography variant="h6" color="primary" align="center">
                        Start of shift
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Box mx={1}>
                        <KeyboardDatePicker
                          autoOk
                          disablePast
                          format="DD/MM/YYYY"
                          id="date-picker-timeStart"
                          label="Date"
                          value={timeStart}
                          onChange={this.onChangeStartDate}
                          KeyboardButtonProps={{
                            'aria-label': 'Change time start',
                          }}
                          variant="inline"
                        />
                      </Box>
                      <Box mx={1}>
                        {!isSafari && (
                          <TextField
                            id="time-start"
                            type="time"
                            label="Time"
                            value={moment(timeStart ?? undefined).format('HH:mm')}
                            onChange={this.onChangeStartTime(isSafari)}
                          />
                        )}
                        {isSafari && (
                          <KeyboardTimePicker
                            ampm={false}
                            autoOk
                            id="time-start"
                            label="Time"
                            value={timeStart}
                            // @ts-ignore saferi date-picker behaves weirdly
                            onChange={this.onChangeStartTime(isSafari)}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box ml={15}>
                    <Box>
                      <Typography variant="h6" color="primary" align="center">
                        End of shift
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Box mx={1}>
                        <KeyboardDatePicker
                          autoOk
                          disablePast
                          format="DD/MM/YYYY"
                          id="date-picker-timeEnd"
                          label="Date"
                          value={timeEnd}
                          onChange={this.onChangeEndDate}
                          KeyboardButtonProps={{
                            'aria-label': 'Change time start',
                          }}
                          variant="inline"
                        />
                      </Box>
                      <Box mx={1}>
                        {!isSafari && (
                          <TextField
                            id="time-end"
                            label="Time"
                            type="time"
                            value={moment(timeEnd ?? undefined).format('HH:mm')}
                            onChange={this.onChangeEndTime(isSafari)}
                          />
                        )}
                        {isSafari && (
                          <KeyboardTimePicker
                            ampm={false}
                            id="time-end"
                            label="Time End"
                            value={timeEnd}
                            // @ts-ignore safari date-picker behaves weirdly
                            onChange={this.onChangeEndTime(isSafari)}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              {initialShift && staffersConfirmed.length > 0 && (
                <Box textAlign="center" px={10}>
                  <Typography variant="subtitle2">
                    This shift already has a confirmed staffer therefore it cannot be moved.
                  </Typography>
                </Box>
              )}
              {shiftDurationText && (
                <div className={`${styles.shiftDuration} ${!shiftDurationValid ? styles.textWarning : ' '}`}>
                  {`Shift time: ${shiftDurationText}`}
                  {((timeStart && isHoliday(timeStart)) || (timeEnd && isHoliday(timeEnd))) && shiftDurationValid && (
                    <div className={styles.textWarning}>holiday</div>
                  )}
                </div>
              )}
              <Grid container>
                <Grid item xs={6}>
                  <h2 style={{ color: '#2ECC71' }}>Number of positions</h2>
                  <TextField
                    name="number-of-positions-input"
                    className={styles.jobNumberInput}
                    inputProps={{ min: '0' }}
                    type="number"
                    onBlur={this.onPositionsBlur}
                    onChange={this.onPositionsChange}
                    placeholder={positions.toString()}
                    value={positionsText}
                  />
                </Grid>
                {isPrivate && (
                  <Grid item xs={6}>
                    <h2>Add 30 minute break</h2>
                    <Box display="flex">
                      <Switch
                        disabled={
                          !!job &&
                          !!initialShift &&
                          !!job.shifts.find((shift: ShiftType) => shift.shiftId === initialShift.shiftId)
                            ?.staffersConfirmed.length
                        }
                        checked={!!breakDuration}
                        onChange={this.onToggleBreakDuration}
                        color="primary"
                      />
                      {!!job &&
                        !!initialShift &&
                        !!job.shifts.find((shift: ShiftType) => shift.shiftId === initialShift.shiftId)
                          ?.staffersConfirmed.length && (
                          <Tooltip title="You cannot change the break after you have accepted a staffer.">
                            <div>
                              <LockIcon />
                            </div>
                          </Tooltip>
                        )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={2} justify="center">
              <Grid item xs={3}>
                <Button id="cancel-btn" fullWidth variant="contained" color="default" onClick={cancel}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  id="submit-shift"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!shiftDurationValid}
                  onClick={this.onSave}
                >
                  {!initialShift ? 'Add' : 'Edit'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    )
  }

  onToggleBreakDuration = () =>
    this.setState((prevState) => ({
      breakDuration: prevState.breakDuration ? undefined : 30,
    }))

  onPositionsChange = (positionsText: ChangeEvent<HTMLInputElement>) => {
    const positionsValue = positionsText.target.value
    let positions = parseInt(positionsValue, 10)
    // Adjusting the real value in the background (not the text)
    if (!Number.isFinite(positions) || positions < JOB_SHIFT_MIN_POSITIONS) {
      positions = JOB_SHIFT_MIN_POSITIONS
    } else if (positions > JOB_SHIFT_MAX_POSITIONS) {
      positions = JOB_SHIFT_MAX_POSITIONS
    }
    this.setState({
      positions,
      positionsText: positionsValue,
    })
  }

  onPositionsBlur = () => {
    const { positions } = this.state
    this.setState({
      positionsText: positions.toString(),
    })
  }

  checkStartAfterEnd = () => {
    const { timeStart, timeEnd } = this.state
    if (timeStart && timeEnd && moment(timeStart).isAfter(timeEnd)) {
      const diff = moment(timeStart).diff(timeEnd, 'hours')
      this.setState({
        timeEnd: moment(timeEnd).add(diff + 4, 'h'),
      })
      toast.info('Changed your end of shift time to be after its start time')
    }
  }

  onChangeStartDate = (timeStart: Moment | MaterialUiPickersDate) => {
    if (timeStart === null) {
      return
    }
    const newDate = timeStart.set({
      day: timeStart.get('day'),
      month: timeStart.get('month'),
      year: timeStart.get('year'),
    })
    this.setState(
      {
        timeStart: newDate,
      },
      this.checkStartAfterEnd
    )
  }

  onChangeStartTime =
    <T extends boolean>(isSafari: T) =>
    (event: T extends true ? Date | null : ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null) => {
      const { timeStart } = this.state

      if (isSafari) {
        const newTime = moment(event as Date)
        this.setState(
          {
            timeStart: newTime.isValid() ? newTime : timeStart,
          },
          this.checkStartAfterEnd
        )
      } else {
        const newTime = moment((event as ChangeEvent<HTMLInputElement>).target.value, 'HH:mm')
        if (newTime.isValid()) {
          const newDate = moment(timeStart ?? undefined).set({
            hour: newTime.get('hour'),
            minute: newTime.get('minute'),
          })
          this.setState(
            {
              timeStart: newDate,
            },
            this.checkStartAfterEnd
          )
        }
      }
    }

  onChangeEndDate = (timeEnd: Moment | MaterialUiPickersDate) => {
    if (timeEnd === null) {
      return
    }
    const newDate = timeEnd.set({
      day: timeEnd.get('day'),
      month: timeEnd.get('month'),
      year: timeEnd.get('year'),
    })
    this.setState({
      timeEnd: newDate,
    })
  }

  onChangeEndTime =
    <T extends boolean>(isSafari: T) =>
    (event: T extends true ? Date | null : ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null) => {
      const { timeEnd } = this.state

      if (isSafari) {
        const newTime = moment(event as Date)
        this.setState({
          timeEnd: newTime.isValid() ? newTime : timeEnd,
        })
      } else {
        const newTime = moment((event as ChangeEvent<HTMLInputElement>).target.value, 'HH:mm')
        if (newTime.isValid()) {
          const newDate = moment(timeEnd ?? undefined).set({
            hour: newTime.get('hour'),
            minute: newTime.get('minute'),
          })
          this.setState({
            timeEnd: newDate,
          })
        }
      }
    }

  onSave = () => {
    const { onSaveShift } = this.props
    const { importedFromPlanday, breakDuration, positions, timeStart, timeEnd } = this.state
    try {
      if (timeStart === null) {
        throw Error('You forgot to pick start date and time.')
      } else if (timeEnd === null) {
        throw Error('You forgot to pick end date and time.')
      }

      onSaveShift({
        importedFromPlanday,
        breakDuration,
        positions,
        timeStart: timeStampFromDate(timeStart.toDate()),
        timeEnd: timeStampFromDate(timeEnd.toDate()),
      })
    } catch (error) {
      errorToast((error as Error)?.message ?? 'Unknown error when saving shift')
    }
  }
}

export default EditShift
