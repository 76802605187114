import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import type { ChangeEvent, FocusEvent } from 'react'
import { Fragment } from 'react'
// @ts-ignore
import GooglePlacesAutocomplete from 'react-google-places-autocomplete/dist/GooglePlacesAutocomplete'
import type moment from '../../util/moment'
import { FormItem, FormSection } from '../mui/FormBlocks'
import styles from './CreateFulltimeJob.module.css'
import type { ErrorObject } from './fulltimeErrors'
import { ErrorHandler } from './fulltimeErrors'

type Props = {
  jobTypeOptions: string[]
  handleLocationChange: (event: ChangeEvent<{ name?: string; value: unknown }>) => any
  handleCustomLocationToggle: () => void
  handleInputChange: (event: ChangeEvent<{ name?: string; value: unknown }>) => any
  handleDateChange: (date?: ReturnType<typeof moment> | null) => any
  handleOptionChange: (event: ChangeEvent<{ name?: string; value: unknown }>) => any
  values: {
    contractCoverage: number
    customJobType: string
    jobType: string
    positions: number
    timeStart: Date | null
    seasonalJobInfo: string
    selectedJobType: string
    isSeasonalJob: boolean
  }
  formErrors: ErrorObject[]
  handleBlur: (event: ChangeEvent<{ name?: string; value: unknown }>) => void
  handleLocationBlur: (event: FocusEvent<{ name?: string; value: unknown }>) => void
  beautifulAddress?: string
  gmapsLoaded: boolean
  useCustomAddress: boolean
}

/*
 *  ---------------------
 *        COMPONENT
 *  ----------------------
 */
const CreateFulltimeJobForm = (props: Props) => {
  const {
    jobTypeOptions,
    formErrors,
    handleBlur,
    handleLocationChange,
    handleCustomLocationToggle,
    handleLocationBlur,
    handleDateChange,
    handleInputChange,
    handleOptionChange,
    values,
    beautifulAddress,
    gmapsLoaded,
    useCustomAddress,
  } = props

  const { contractCoverage, customJobType, selectedJobType, positions, seasonalJobInfo, timeStart, isSeasonalJob } =
    values

  return (
    <Fragment>
      <FormSection title="">
        <FormItem label="Position">
          <FormControl fullWidth variant="outlined">
            <Select
              displayEmpty
              fullWidth
              name="jobType"
              value={selectedJobType}
              onChange={handleOptionChange}
              onBlur={handleBlur}
              required
              renderValue={(value) => (
                <div className={value ? '' : styles.placeholderColor}>{(value as string) || 'Select...'}</div>
              )}
            >
              {jobTypeOptions.map((jobType) => (
                <MenuItem value={jobType} key={jobType}>
                  {jobType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormItem>
        {selectedJobType === 'Other' && (
          <FormItem label="">
            <FormControl fullWidth variant="outlined">
              <TextField
                variant="outlined"
                type="string"
                placeholder="Enter your new position..."
                name="customJobType"
                value={customJobType}
                onChange={handleInputChange}
                onBlur={handleBlur}
                required
              />
            </FormControl>
          </FormItem>
        )}
        <FormItem label="People needed">
          <TextField
            variant="outlined"
            type="number"
            value={positions}
            placeholder="Ex. 3"
            onChange={handleInputChange}
            name="positions"
            onBlur={handleBlur}
            required
          />
        </FormItem>
        <FormItem label="Contract">
          <TextField
            variant="outlined"
            type="number"
            name="contractCoverage"
            value={contractCoverage}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            onBlur={handleBlur}
            required
          />
        </FormItem>
        {formErrors.length > 0 && (
          <Grid item xs={12}>
            <ErrorHandler formErrors={formErrors} errorName="jobType" />
            <ErrorHandler formErrors={formErrors} errorName="customJobType" />
            <ErrorHandler formErrors={formErrors} errorName="contractCoverage" />
            <ErrorHandler formErrors={formErrors} errorName="positions" />
          </Grid>
        )}
      </FormSection>
      {gmapsLoaded && (
        <FormSection title="Job location">
          <FormItem label="Address">
            <FormControlLabel
              name="use-custom-location"
              label="Use Custom Location"
              control={<Checkbox name="kkt" checked={useCustomAddress} onChange={handleCustomLocationToggle} />}
            />
            {useCustomAddress && (
              <GooglePlacesAutocomplete
                error
                onSelect={handleLocationChange}
                initialValue={beautifulAddress}
                renderInput={(selectProps: Props) => (
                  <TextField
                    {...selectProps}
                    fullWidth
                    variant="outlined"
                    placeholder="Address"
                    type="text"
                    name="customLocation"
                    onBlur={handleLocationBlur}
                    required={useCustomAddress}
                    autoComplete="shipping street address"
                  />
                )}
              />
            )}
          </FormItem>
        </FormSection>
      )}
      <FormSection title="Job start">
        <FormItem label="What is the start date?">
          <KeyboardDatePicker
            fullWidth
            disabled={timeStart === null}
            clearable
            name="timeStart"
            value={timeStart}
            onChange={handleDateChange}
            onBlur={handleBlur}
            minDate={new Date()}
            required
            inputVariant="outlined"
            placeholder={timeStart === null ? 'ASAP' : ''}
          />
        </FormItem>
        <FormItem label="">
          <Box pb={1}>
            <FormGroup row>
              <FormControlLabel
                name="asap-toggler"
                label="As soon as possible"
                control={
                  <Switch
                    checked={timeStart === null}
                    onChange={() => handleDateChange(timeStart === null ? undefined : null)}
                    color="primary"
                  />
                }
              />
            </FormGroup>
          </Box>
        </FormItem>
        {formErrors.some(({ name }) => name === 'timeStart') && (
          <Grid item xs={12}>
            <ErrorHandler formErrors={formErrors} errorName="timeStart" />
          </Grid>
        )}
      </FormSection>
      <FormSection title="Seasonal job options">
        <FormItem label="">
          <FormControlLabel
            name="seasonal-job"
            label="Seasonal Job"
            control={
              <Checkbox checked={isSeasonalJob} name="isSeasonalJob" onChange={handleInputChange} color="primary" />
            }
          />
          {isSeasonalJob && (
            <TextField
              multiline
              fullWidth
              variant="outlined"
              placeholder="Short description about seasonal job"
              type="text"
              name="seasonalJobInfo"
              onChange={handleInputChange}
              inputProps={{
                maxLength: 50,
              }}
              onBlur={handleBlur}
              value={seasonalJobInfo}
              required={!!isSeasonalJob}
            />
          )}
          <ErrorHandler formErrors={formErrors} errorName="seasonalJobInfo" />
        </FormItem>
      </FormSection>
    </Fragment>
  )
}

export default CreateFulltimeJobForm
