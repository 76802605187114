import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import React, { Component as ReactComponent } from 'react'
import type { ManagerType } from '../../../src/types/manager'

type WrapperProps = {
  manager?: ManagerType
}

type Props = WrapperProps & {
  businessIds: string[] // businesses to be monitored
  // (by either the groupBusinesses or businessId field of the manager)
}

type ComponentProps = Props & {
  unreadMessagesSum: Record<string, number>
  unreadMessages: Record<string, Record<string, number>>
}

type State = {
  unreadCountSum: { [businessId: string]: number }
  unreadCountChats: {
    [businessId: string]: {
      [chatId: string]: number
    }
  }
}

const MessagesProviderWrapper = (Component: React.ComponentType<ComponentProps>) => {
  class MessagesProvider extends ReactComponent<Props & WrapperProps, State> {
    state = {
      unreadCountSum: {}, // counts by businessIds
      unreadCountChats: {}, // counts by businessId by chatIds
    }

    unreadCountListenerRefs: Record<string, firebase.database.Reference> = {} // refs by businessId

    componentDidMount() {
      this.suscribeUnreadCount(this.props.businessIds)
    }

    componentDidUpdate({ businessIds: oldBusinessIds }: Props) {
      const { businessIds } = this.props
      if (businessIds !== oldBusinessIds) {
        const removedIds = oldBusinessIds.filter((id) => !businessIds.includes(id))
        const addedIds = businessIds.filter((id) => !oldBusinessIds.includes(id))
        this.unsuscribeUnreadCount(removedIds)
        this.suscribeUnreadCount(addedIds)
      }
    }

    componentWillUnmount() {
      this.unsuscribeUnreadCount(this.props.businessIds)
    }

    suscribeUnreadCount(businessIds: string[]) {
      businessIds.forEach((businessId: string) => {
        this.unreadCountListenerRefs[businessId] = firebase.database().ref(`/unread-count/${businessId}`)
        this.unreadCountListenerRefs[businessId].on('value', (snap) => {
          const unreadCount = snap.val()

          this.setState(({ unreadCountSum, unreadCountChats }) => ({
            unreadCountSum: {
              ...unreadCountSum,
              [businessId]: (unreadCount && unreadCount.sum) || 0,
            },
            unreadCountChats: {
              ...unreadCountChats,
              [businessId]: (unreadCount && unreadCount.chats) || {},
            },
          }))
        })
      })
    }

    unsuscribeUnreadCount(businessIds: string[]) {
      businessIds.forEach((businessId) => {
        if (this.unreadCountListenerRefs[businessId]) {
          this.unreadCountListenerRefs[businessId].off()
        }
      })
    }

    render() {
      const { unreadCountSum, unreadCountChats } = this.state
      return <Component unreadMessagesSum={unreadCountSum} unreadMessages={unreadCountChats} {...this.props} />
    }
  }

  // Wrapper component exception
  // eslint-disable-next-line react/display-name
  return (props: WrapperProps) => {
    const { manager } = props
    if (!manager) {
      return null
    }
    return (
      <MessagesProvider
        businessIds={manager.groupBusinesses || manager.businessId ? [manager.businessId as string] : []}
        {...props}
      />
    )
  }
}

export default MessagesProviderWrapper
