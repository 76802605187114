import { Box, Button } from '@material-ui/core'
import { Component, Fragment } from 'react'
import styles from '../../mui/Modal.module.css'
import ModalHeader from '../../mui/ModalHeader'
import CreateEditGroup from './CreateEditGroup'
import CreateGroupWithEmpty from './CreateGroupWithEmpty'

type CreateGroupType = {
  buttonTitle: string
  name: string
}

type State = {
  selectedType: 'empty' | 'existing' | null
}

type Props = {
  onClose: () => void
}

const CREATE_GROUP_TYPES: CreateGroupType[] = [
  {
    buttonTitle: 'With empty businesses',
    name: 'empty',
  },
  {
    buttonTitle: 'With existing businesses',
    name: 'existing',
  },
]

class CreateGroupTypeSelector extends Component<Props, State> {
  state: State = {
    selectedType: null,
  }

  onCreateGroupTypeSelect = (selectedType: 'empty' | 'existing') => {
    this.setState({ selectedType })
  }

  onResetCreateGroupVariant = () => {
    this.setState({ selectedType: null })
  }

  renderCreateGroupType = () => {
    const { selectedType } = this.state
    const { onClose } = this.props

    switch (selectedType) {
      case 'existing': {
        return <CreateEditGroup onClose={onClose} onGoBack={this.onResetCreateGroupVariant} />
      }
      case 'empty': {
        return <CreateGroupWithEmpty onClose={onClose} />
      }
      default: {
        return null
      }
    }
  }

  render() {
    const { onClose } = this.props
    const { selectedType } = this.state

    if (selectedType) {
      return this.renderCreateGroupType()
    }

    return (
      <Fragment>
        <ModalHeader close={onClose}>Select a group type to create</ModalHeader>
        <Box className={styles.modalContent} p={3} display="flex" justifyContent="space-evenly" alignItems="center">
          {CREATE_GROUP_TYPES.map((type) => {
            const { name, buttonTitle } = type
            return (
              <Box
                key={name}
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="space-evenly"
                height="100px"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.onCreateGroupTypeSelect(name as 'empty' | 'existing')}
                >
                  {buttonTitle}
                </Button>
              </Box>
            )
          })}
        </Box>
      </Fragment>
    )
  }
}

export default CreateGroupTypeSelector
