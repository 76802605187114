import {
  Box,
  Card,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import type { ChangeEvent } from 'react'
import { Fragment } from 'react'
import type { SelectBusinessType } from './CreateEditGroup'
import SelectBusinesses from './SelectBusinesses'

type Props = {
  createGroupType: 'empty' | 'existing'
  groupId?: string
  groupName: string
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void
  onToggleBusiness: (selectedBusinessType: SelectBusinessType) => void
  selectedBusinesses: Array<SelectBusinessType>
}

const GroupDetails = (props: Props) => {
  const { createGroupType, groupId, groupName, onNameChange, onToggleBusiness, selectedBusinesses } = props

  return (
    <Fragment>
      {/* Enter group name */}
      <TextField
        fullWidth
        id="group-name"
        label="Group name"
        variant="outlined"
        value={groupName}
        onChange={onNameChange}
      />

      {createGroupType === 'existing' && (
        <Fragment>
          {/* Select group businesses */}
          <SelectBusinesses
            groupId={groupId}
            onToggleBusiness={onToggleBusiness}
            selectedBusinesses={selectedBusinesses}
          />
          {selectedBusinesses && selectedBusinesses.length > 0 && (
            <Box>
              <Typography variant="h2">Selected</Typography>
              <List>
                {selectedBusinesses.map((business) => (
                  <Box mt={2} key={business.businessId}>
                    <Card>
                      <ListItem>
                        <ListItemText primary={business.businessName} secondary={business.businessId} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => onToggleBusiness(business)}>
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Card>
                  </Box>
                ))}
              </List>
            </Box>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default GroupDetails
