import * as R from 'ramda'
import type { ChangeEvent, FocusEvent } from 'react'
import { Component } from 'react'
// @ts-ignore react-select has no exported @types/ module
import Select from 'react-select'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { errorToast } from '../../helpers/toast'
import styles from '../../screens/superadmin/notifications.module.css'
import { firestoreHttpsCallable } from '../../staffers/api/firestore/https/util'
import Button from '../button/Button'
import Checkbox from '../checkbox/CustomCheckbox'
import TextField from '../textInputField/TextInputField'

type OptionType = {
  value: string
  label: string
}

const getTextError = (text: string) => {
  if (!text) {
    return 'This field is required'
  }
  return ''
}

const getSelectError = (selectedOption: OptionType | null) => {
  if (!selectedOption) {
    return 'Please, select one of the options above.'
  }
  return ''
}

const redirectOptions = [
  {
    value: 'staffers://joblisting',
    label: 'Take staffer to browse jobs screen',
  },
  {
    value: 'staffers://preferred',
    label: 'Take staffer to preferred position selection',
  },
]

const promotionalOfferOptions = [
  {
    value: 'P7D',
    label: '1 week',
  },
  {
    value: 'P14D',
    label: '2 weeks',
  },
  {
    value: 'P1M',
    label: '1 month',
  },
]

const numberOfShiftsOptions = [
  {
    value: 1,
    label: '1 shift',
  },
  {
    value: 2,
    label: '2 shifts',
  },
]

type Props = {
  closeModal?: () => void
  isBusiness?: boolean
  resetSelectedUsers?: () => void
  selectedUsers: Set<string>
  showLoader?: (show: boolean) => void
}

type State = {
  inputErrors: {
    numberOfShifts?: string
    offerDuration?: string
    title: string
    text: string
  }
  isDisabled: boolean
  isLoading: boolean
  isPromotionalOffer: boolean
  redirectionSelected: OptionType
  selectedNumberOfFreeShifts: OptionType | null
  selectedOfferDuration: OptionType | null
  text: string
  title: string
}

class NotificationSender extends Component<Props, State> {
  state = {
    inputErrors: {
      numberOfShifts: '',
      offerDuration: '',
      text: '',
      title: '',
    },
    isDisabled: false,
    isLoading: false,
    isPromotionalOffer: false,
    redirectionSelected: redirectOptions[0],
    selectedNumberOfFreeShifts: null,
    selectedOfferDuration: null,
    text: '',
    title: '',
  }

  onBodyTextChange = (text: ChangeEvent<HTMLInputElement>) => {
    this.setState({ text: text.target.value })
  }

  onTitleChange = (title: ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: title.target.value })
  }

  handleOptionChange = (
    selectedOption: OptionType,
    optionName: 'redirectionSelected' | 'selectedNumberOfFreeShifts' | 'selectedOfferDuration'
  ) => {
    this.setState(
      {
        [optionName]: selectedOption,
      } as Pick<State, typeof optionName>,
      () => this.removeError()
    )
  }

  handleCheckboxChange = () => {
    this.setState(({ isPromotionalOffer }) => ({
      isPromotionalOffer: !isPromotionalOffer,
    }))
  }

  onTitleEndEdit = (title: FocusEvent<HTMLInputElement>) => {
    this.setState(({ inputErrors }) => ({
      inputErrors: {
        ...inputErrors,
        title: getTextError(title.target.value),
      },
    }))
  }

  onBodyTextEndEdit = (text: FocusEvent<HTMLInputElement>) => {
    this.setState(({ inputErrors }) => ({
      inputErrors: {
        ...inputErrors,
        text: getTextError(text.target.value),
      },
    }))
  }

  removeError = () => {
    const { inputErrors, selectedNumberOfFreeShifts, selectedOfferDuration } = this.state

    if (selectedNumberOfFreeShifts) {
      this.setState({
        inputErrors: {
          ...inputErrors,
          numberOfShifts: '',
        },
      })
    }

    if (selectedOfferDuration) {
      this.setState({
        inputErrors: {
          ...inputErrors,
          offerDuration: '',
        },
      })
    }
  }

  sendNotification = async () => this.send(false)

  sendSMS = async () => this.send(true)

  send = async (bySMS: boolean) => {
    const { isPromotionalOffer, redirectionSelected, selectedNumberOfFreeShifts, selectedOfferDuration, text, title } =
      this.state

    const { closeModal, isBusiness, resetSelectedUsers, showLoader } = this.props

    let inputErrors

    if (isPromotionalOffer) {
      inputErrors = {
        numberOfShifts: getSelectError(selectedNumberOfFreeShifts),
        offerDuration: getSelectError(selectedOfferDuration),
        text: getTextError(text),
        title: getTextError(title),
      }
    } else {
      inputErrors = {
        text: getTextError(text),
        title: getTextError(title),
      }
    }

    // Display the errors
    this.setState({ inputErrors })
    // If there are errors, abort the save and display a message
    if (R.any((error) => error !== '', R.values(inputErrors))) {
      console.error('Cannot update business profile:\nSome fields are invalid.', 3000)
      return
    }

    const selectedUsers = new Set(this.props.selectedUsers)
    selectedUsers.delete('selectAll')

    if (showLoader) {
      showLoader(true)
    } else {
      this.setState({ isLoading: true })
    }

    if (isBusiness && isPromotionalOffer && selectedNumberOfFreeShifts && selectedOfferDuration) {
      try {
        await firestoreHttpsCallable('makePromotionalOffer', {
          business: [...selectedUsers][0], // set => array => array[0] (only one business)
          bySMS,
          notificationBody: text,
          notificationTitle: title,
          numberOfFreeShifts: (selectedNumberOfFreeShifts as OptionType).value,
          promotionalOfferDuration: (selectedOfferDuration as OptionType).value,
        })
        toast.success('Promotional notification was successfully sent!')
      } catch (error) {
        errorToast((error as Error)?.message ?? 'Unknown error')
        console.error(error)
      }
    }

    if (!isPromotionalOffer) {
      try {
        if (isBusiness) {
          await firestoreHttpsCallable('sendNotificationToBusinessList', {
            business: [...selectedUsers], // set --> array
            body: text,
            bySMS,
            title,
          })
        } else {
          await firestoreHttpsCallable('sendNotificationToStaffersList', {
            staffers: [...selectedUsers], // set --> array
            target: redirectionSelected.value,
            body: text,
            bySMS,
            title,
          })
        }
        if (resetSelectedUsers) {
          resetSelectedUsers()
        }
        toast.success('Notification(s) successfully sent')
      } catch (error) {
        errorToast((error as Error)?.message ?? 'Unknown error')
        console.error(error)
      }
    }

    if (showLoader) {
      showLoader(false)
    } else {
      this.setState({ isLoading: false })
    }

    // If we are sending notification from modal, close this modal.
    if (closeModal) {
      closeModal()
    }
  }

  render() {
    const { isBusiness, selectedUsers } = this.props

    const {
      inputErrors,
      isDisabled,
      isLoading,
      isPromotionalOffer,
      redirectionSelected,
      selectedNumberOfFreeShifts,
      selectedOfferDuration,
      title,
      text,
    } = this.state

    if (isLoading) {
      return <BeatLoader />
    }

    const customSelectStyles = {
      control: (provided: Record<string, any>) => ({
        ...provided,
        height: 19,
        border: 'none',
        color: 'gray',
        width: '212',
        cursor: 'pointer',

        '&:hover': { borderBottom: '2px solid black' },
      }),
      placeholder: (provided: Record<string, any>) => ({ ...provided }),
    }

    return (
      <div className={styles.inputWrapper}>
        <h2 className={styles.h2}>Title</h2>
        <TextField
          className={styles.input}
          onChange={this.onTitleChange}
          onBlur={this.onTitleEndEdit}
          inputProps={{ maxLength: 60 }}
          placeholder="Notification title"
          value={title}
          type="text"
          error={!!inputErrors.title}
          helperText={inputErrors.title && inputErrors.title}
        />

        <h2 className={styles.h2}>Text</h2>
        <TextField
          className={styles.input}
          type="text"
          inputProps={{ maxLength: 200 }}
          multiline
          onChange={this.onBodyTextChange}
          onBlur={this.onBodyTextEndEdit}
          placeholder="Notification body text"
          value={text}
          error={!!inputErrors.text}
          helperText={inputErrors.text && inputErrors.text}
        />
        {isBusiness && (
          <div style={{ fontSize: '16px' }}>
            <Checkbox
              checked={isPromotionalOffer}
              onToggleCheckbox={this.handleCheckboxChange}
              title="Promotional notification"
            />
            {isPromotionalOffer && (
              <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="selectedOfferDurationOption" className={styles.borderRowWrapper}>
                  <div>Choose the duration of the promotional offer:</div>
                  <Select
                    id="selectedOfferDurationOption"
                    name="selectedOfferDurationOption"
                    value={selectedOfferDuration}
                    onChange={(selectedOption: OptionType) =>
                      this.handleOptionChange(selectedOption, 'selectedOfferDuration')
                    }
                    options={promotionalOfferOptions}
                    styles={customSelectStyles}
                    isSearchable={false}
                  />
                </label>
                {inputErrors.offerDuration && <div className={styles.error}>{inputErrors.offerDuration}</div>}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="selectedNumberOfFreeShiftsOption" className={styles.borderRowWrapper}>
                  <div>Choose number of free shifts:</div>
                  <Select
                    id="selectedNumberOfFreeShiftsOption"
                    name="selectedNumberOfFreeShiftsOption"
                    value={selectedNumberOfFreeShifts}
                    onChange={(selectedOption: OptionType) =>
                      this.handleOptionChange(selectedOption, 'selectedNumberOfFreeShifts')
                    }
                    options={numberOfShiftsOptions}
                    styles={customSelectStyles}
                    isSearchable={false}
                  />
                </label>
                {inputErrors.numberOfShifts && <div className={styles.error}>{inputErrors.numberOfShifts}</div>}
              </div>
            )}
          </div>
        )}
        {!isBusiness && (
          <Select
            className={styles.targetFilter}
            value={redirectionSelected}
            onChange={(selectedOption: OptionType) => this.handleOptionChange(selectedOption, 'redirectionSelected')}
            options={redirectOptions}
            isSearchable={false}
          />
        )}
        {isBusiness && <div className={styles.paddingBottom} />}
        {!!selectedUsers.size && (
          <div className={styles.paddingBottom}>
            Notification is going to be send to
            {`${selectedUsers.size} ${isBusiness ? 'businesses' : 'staffers'}.`}
          </div>
        )}
        <Button
          disabled={isDisabled || selectedUsers.size === 0}
          title="Send notification(s)"
          buttonType="primaryBlue"
          onClick={this.sendNotification}
        />
        <Button
          disabled={isDisabled || selectedUsers.size === 0}
          title="Send by SMS"
          buttonType="primaryBlue"
          onClick={this.sendSMS}
        />
        <Button
          disabled={isDisabled || selectedUsers.size === 0}
          title="Reset users"
          buttonType="primaryBlue"
          onClick={this.props.resetSelectedUsers}
        />
      </div>
    )
  }
}

export default NotificationSender
