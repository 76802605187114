import { IconButton } from '@material-ui/core'
import { EditRounded, SearchRounded } from '@material-ui/icons'
import moment from 'moment'
import type { Props as ModalProps } from 'react-modal'
import type { Shortlist } from '../../../../../src/types/shortlist'
import MuiModal from '../../mui/Modal'
import { ModalHeader, type ModalHeaderProps } from '../modal/Modal'
import styles from './ShortlistListModal.module.css'

type ShortlistListModalBaseProps = {
  shortlists: Shortlist[]
  onSearchShortlist: (shortlist: Shortlist) => void
  onEditShortlist: (shortlist: Shortlist) => void
} & Pick<ModalHeaderProps, 'onClose'>

const ShortlistListModalBase = ({
  shortlists,
  onClose,
  onSearchShortlist,
  onEditShortlist,
}: ShortlistListModalBaseProps) => (
  <div className={styles.modal}>
    {/* Header */}
    <ModalHeader title="My shortlists" onClose={onClose} />
    {/* Content */}
    <div className={styles.content}>
      {shortlists.map((shortlist) => (
        <div key={shortlist.id} className={styles.shortlistCardWrapper}>
          <button className={styles.shortlistCard} type="button" onClick={() => onSearchShortlist(shortlist)}>
            <div>
              <span className={styles.shortlistCardTitle}>{shortlist.name}</span>
              <div className={styles.shortlistCardInfo}>
                <span>{moment(shortlist.updatedAt?.toDate()).format('DD.MM.YYYY')}</span>
                <span className={styles.shortlistCardInfoDot} />
                <span>{`${shortlist.members.length} jobseeker${shortlist.members.length === 1 ? '' : 's'}`}</span>
              </div>
            </div>
            {/* <ChevronRightRounded className={styles.shortlistCardIcon} /> */}
            <SearchRounded className={styles.shortlistCardIcon} />
          </button>
          <IconButton className={styles.shortlistEditIconButton} onClick={() => onEditShortlist(shortlist)}>
            <EditRounded className={styles.shortlistEditIcon} />
          </IconButton>
        </div>
      ))}
    </div>
  </div>
)

const ShortlistListModal = ({
  isOpen,
  ...props
}: Pick<ShortlistListModalBaseProps, 'onClose' | 'shortlists' | 'onSearchShortlist' | 'onEditShortlist'> &
  Pick<ModalProps, 'isOpen'>) => (
  <MuiModal isOpen={isOpen} hasRoundedCorners enforcedWidth="extraLarge">
    <ShortlistListModalBase {...props} />
  </MuiModal>
)

export default ShortlistListModal
