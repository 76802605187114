import { Box, Button } from '@material-ui/core'
import { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import type { BusinessType } from '../../../../src/types/business'
import type { GroupStafferType } from '../../../../src/types/groups'
import type { TranslatedJobTypes } from '../../../../src/types/jobTypes'
import type { JobType } from '../../../../src/types/jobs'
import type { PermissionsType } from '../../../../src/types/pools'
import type { StafferTypePaymentDetails } from '../../../../src/types/staffer'
import { toNetHourlyWageText } from '../../helpers/jobs'
import { getStafferCustomWage } from '../../helpers/staffers'
import { errorToast } from '../../helpers/toast'
import { getTranslatedJobTypes } from '../../staffers/api/getters/common'
import { getGroupStaffer } from '../../staffers/api/getters/groups'
import { getStafferDetailsById, getStafferPermissions } from '../../staffers/api/getters/staffer'
import { connectFirestore } from '../../staffers/qman/connectFirestore'
import moment from '../../util/moment'
import ModalHeader from '../mui/ModalHeader'
import contractorAgreementNor from '../workAgreements/contractorAgreementNor'
import contractorAgreementSwe from '../workAgreements/contractorAgreementSwe'
import workAgreementNor from '../workAgreements/workAgreementNor'
import workAgreementSwe from '../workAgreements/workAgreementSwe'
import style from './modals.module.css'

type Props = {
  business: BusinessType
  // eslint-disable-next-line react/no-unused-prop-types
  stafferId: string // used in CF
  groupStaffer?: GroupStafferType
  isContractPreview?: true
  job?: JobType
  translatedJobTypes?: TranslatedJobTypes
  onAcceptStaffer: () => Promise<void>
  onClose: () => void
  paymentDetails?: StafferTypePaymentDetails
  stafferPermissions?: PermissionsType
}

type State = {
  isProcessing: boolean
  textLanguage: 'Norwegian' | 'Swedish' | 'English'
}

class AddStaffer extends Component<Props, State> {
  // @ts-ignore typescript considers assignment in state of type string instead of literal

  constructor(props: Props) {
    super(props)
    this.state = {
      isProcessing: false,
      textLanguage: props.job?.region?.countryName === 'Sweden' ? 'Swedish' : 'Norwegian',
    }
  }

  onAcceptStafferHandler = async () => {
    try {
      this.setState({ isProcessing: true })
      await this.props.onAcceptStaffer()
    } catch (error) {
      errorToast((error as Error).message)
      console.warn(error)
    } finally {
      this.setState({ isProcessing: false })
    }
  }

  render() {
    const { isProcessing, textLanguage } = this.state

    const {
      onClose,
      business,
      groupStaffer,
      isContractPreview,
      job,
      translatedJobTypes,
      paymentDetails,
      stafferPermissions,
    } = this.props

    if (isProcessing || !business || !paymentDetails || !job || !translatedJobTypes || !stafferPermissions) {
      return (
        <Box textAlign="center" p={4}>
          <BeatLoader />
        </Box>
      )
    }

    const {
      address: { city, country, postalCode, street },
      orgNumber,
      registeredName,
      businessName,
      payoutDay,
    } = business

    const {
      description,
      isFreelanceJob,
      timeEnd,
      timeStart,
      employmentReason,
      salaryCurrency,
      salaryHourly,
      jobType,
      options,
      usePermanentContractWage,
    } = job

    console.log('translatedJobTypes: ', translatedJobTypes)
    const jobRequirementsTranslated = {} as Record<'en' | 'no' | 'sv', string[]>
    const jobTypeTranslated = {} as Record<'en' | 'no' | 'sv', string>
    const enJobTypes = translatedJobTypes.find(({ id: lang }) => lang === 'en')
    if (enJobTypes) {
      translatedJobTypes.forEach(({ id: lang, values }) => {
        const jobTypeInfo = values.find((_, i) => enJobTypes.values[i].name === jobType)
        if (jobTypeInfo) {
          jobRequirementsTranslated[lang] = (options || []).map((id) => jobTypeInfo.options[id] || '')
          jobTypeTranslated[lang] = jobTypeInfo.name
        }
      })
    }

    const { identityNumber: stafferIdentityNumber, nameFirst, nameLast, regionalDetails } = paymentDetails

    const identityNumber =
      (regionalDetails || {})[business?.region?.countryName || 'Norway']?.identityNumber || stafferIdentityNumber

    const businessAddress = `${street}, ${postalCode} ${city}, ${country}`
    // Technically it's number | undefined which is okay, but moment is typed to only accept number
    const businessPayoutDate = moment()
      .date(payoutDay as number)
      .format('Do')
    const jobDateEnd = timeEnd ? moment(timeEnd).format('DD/MM/YYYY HH:mm') : '<end of the shift>'
    const jobDateStart = timeStart ? moment(timeStart).format('DD/MM/YYYY HH:mm') : '<start of the shift>'

    // Get custom wage
    const employerOrBusinessCustomWage = getStafferCustomWage({
      business,
      groupStaffer,
      stafferPermissions,
    })

    const jobHourlyWage = usePermanentContractWage
      ? `${employerOrBusinessCustomWage ? `${employerOrBusinessCustomWage} ${salaryCurrency}/h` : 'custom'}`
      : `${toNetHourlyWageText(salaryHourly, isFreelanceJob, false, job.region?.countryName)} ${salaryCurrency}/h`
    const stafferNameFull = `${nameFirst} ${nameLast}`

    const today = moment().format('DD/MM/YYYY')
    const endDate = timeEnd ? moment(timeEnd).format('DD/MM/YYYY') : '<end of the shift>'

    if (isFreelanceJob) {
      return (
        <div className={style.addStafferWrapper}>
          <ModalHeader close={onClose}>
            Contract:
            <span className={style.modalHeaderSubtext}>{` ${nameFirst} ${nameLast} - ${jobType} (${today})`}</span>
          </ModalHeader>
          <div className={style.languageSelectorWrapper}>
            {job.region?.countryName === 'Sweden' ? (
              <button
                type="button"
                onClick={() => this.setState({ textLanguage: 'Swedish' })}
                className={textLanguage === 'Swedish' ? style.languageButtonActive : style.languageButton}
              >
                Svenska
              </button>
            ) : (
              <button
                type="button"
                onClick={() => this.setState({ textLanguage: 'Norwegian' })}
                className={textLanguage === 'Norwegian' ? style.languageButtonActive : style.languageButton}
              >
                Norsk
              </button>
            )}
            <button
              type="button"
              onClick={() => this.setState({ textLanguage: 'English' })}
              className={textLanguage === 'English' ? style.languageButtonActive : style.languageButton}
            >
              English
            </button>
          </div>
          {job.region?.countryName === 'Sweden'
            ? contractorAgreementSwe[textLanguage as 'Swedish' | 'English']({
                today,
                description,
                jobType,
                jobRequirementsTranslated,
                jobTypeTranslated,
                registeredName,
                businessName,
                orgNumber,
                businessAddress,
                stafferNameFull,
                identityNumber,
                endDate,
                jobDateStart,
                jobDateEnd,
                jobHourlyWage,
                salaryHourly,
              })
            : // Norway region by default
              contractorAgreementNor[textLanguage as 'Norwegian' | 'English']({
                today,
                description,
                jobType,
                jobRequirementsTranslated,
                jobTypeTranslated,
                registeredName,
                businessName,
                orgNumber,
                businessAddress,
                stafferNameFull,
                identityNumber,
                endDate,
                jobDateStart,
                jobDateEnd,
                jobHourlyWage,
                salaryHourly,
              })}
          <div className={style.buttonWrapperColumn}>
            <Button onClick={onClose} className={style.grayButton} variant="contained">
              {isContractPreview ? 'Go Back' : 'Close'}
            </Button>
            {!isContractPreview && (
              <Button className={style.greenButton} onClick={this.onAcceptStafferHandler} variant="contained">
                Accept Contract
              </Button>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className={style.addStafferWrapper}>
        <ModalHeader close={onClose}>
          Work Agreement:
          <span className={style.modalHeaderSubtext}>{` ${nameFirst} ${nameLast} - ${jobType} (${today})`}</span>
        </ModalHeader>
        <div className={style.languageSelectorWrapper}>
          {job.region?.countryName === 'Sweden' ? (
            <button
              type="button"
              onClick={() => this.setState({ textLanguage: 'Swedish' })}
              className={textLanguage === 'Swedish' ? style.languageButtonActive : style.languageButton}
            >
              Svenska
            </button>
          ) : (
            <button
              type="button"
              onClick={() => this.setState({ textLanguage: 'Norwegian' })}
              className={textLanguage === 'Norwegian' ? style.languageButtonActive : style.languageButton}
            >
              Norsk
            </button>
          )}
          <button // english button for both norw and swed regions
            type="button"
            onClick={() => this.setState({ textLanguage: 'English' })}
            className={textLanguage === 'English' ? style.languageButtonActive : style.languageButton}
          >
            English
          </button>
        </div>
        {job.region?.countryName === 'Sweden'
          ? workAgreementSwe[textLanguage as 'Swedish' | 'English']({
              jobType,
              registeredName,
              businessName,
              businessAddress,
              identityNumber,
              orgNumber,
              stafferNameFull,
              jobDateStart,
              jobDateEnd,
              jobHourlyWage,
              employmentReason,
              businessPayoutDate,
              jobRequirementsTranslated,
            })
          : // fall back to norway even if the region is missing
            workAgreementNor[textLanguage as 'Norwegian' | 'English']({
              jobType,
              registeredName,
              businessName,
              businessAddress,
              identityNumber,
              orgNumber,
              stafferNameFull,
              jobDateStart,
              jobDateEnd,
              jobHourlyWage,
              employmentReason,
              businessPayoutDate,
            })}
        <div className={style.buttonWrapperColumn}>
          <Button onClick={onClose} className={style.grayButton} variant="contained">
            {isContractPreview ? 'Go Back' : 'Close'}
          </Button>
          {!isContractPreview && (
            <Button className={style.greenButton} onClick={this.onAcceptStafferHandler} variant="contained">
              Accept Work Agreement
            </Button>
          )}
        </div>
      </div>
    )
  }
}

export default connectFirestore(
  (_db, props: Props) => ({
    groupStaffer: props.business && props.business.groupId && getGroupStaffer(props.business.groupId, props.stafferId),
    translatedJobTypes: getTranslatedJobTypes(),
    paymentDetails: getStafferDetailsById(props.stafferId),
    stafferPermissions: getStafferPermissions(props.stafferId),
  }),
  AddStaffer
)
