import { Box } from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import * as R from 'ramda'
import { Component } from 'react'
// @ts-ignore react-select has no exported @types/ module
import Select, { components } from 'react-select'
import { BeatLoader } from 'react-spinners'
import type { ManagerTypeWithId } from '../../../../src/types/manager'
import { getGroupManagersByBusinessId, getManagersByBusinessId } from '../../staffers/api/getters/managers'
import { connectFirestore } from '../../staffers/qman/connectFirestore'

type Props = {
  id?: string
  uid?: string
  businessId?: string
  manager?: ManagerTypeWithId
  managers?: Array<ManagerTypeWithId>
  groupManagers?: Array<ManagerTypeWithId>
  selected: Array<any>
  onSelect: (managerIds: string[]) => any
}

type ManagerOption = {
  id: string
  email: string
  value: string
  label: string
}

const BuiltInOption = components.Option
const BuiltInMultiValueLabel = components.MultiValueLabel
const BuiltInMultiValueRemove = components.MultiValueRemove

class ManagerSelector extends Component<Props> {
  state = {}

  select = (values: ManagerOption[]) => {
    const { onSelect } = this.props
    onSelect(values.map(({ id }) => id))
  }

  deselect = (removedId: string) => {
    const { onSelect, selected } = this.props
    const updatedSelected = selected.filter((id) => id !== removedId).map((id) => id)
    onSelect(updatedSelected)
  }

  render() {
    const { uid, managers, groupManagers, selected } = this.props

    if (!managers || groupManagers === undefined) {
      return <BeatLoader />
    }

    const managerOptions = R.uniqBy(({ id }) => id, [...(groupManagers || []), ...managers])
    const options = managerOptions.map(
      ({ id, email }: ManagerTypeWithId): ManagerOption => ({
        id,
        email,
        value: id,
        label: email,
      })
    )
    const value = managerOptions.filter(({ id }) => selected.includes(id))

    const Option = (renderProps: Record<string, any> & { data: ManagerOption }) => {
      const { email, id } = renderProps.data
      return (
        <BuiltInOption key={id} {...renderProps}>
          {email}
          &nbsp;
          {uid === id ? <small> (you)</small> : null}
          {selected.includes(id) && <CheckIcon />}
        </BuiltInOption>
      )
    }

    const MultiValueLabel = (renderProps: Record<string, any> & { data: ManagerOption }) => {
      const { email, id } = renderProps.data
      return (
        <BuiltInMultiValueLabel {...renderProps}>
          {email}
          &nbsp;
          {uid === id ? <small> (you)</small> : null}
        </BuiltInMultiValueLabel>
      )
    }

    const MultiValueRemove = (renderProps: Record<string, any> & { data: ManagerOption }) => {
      const { data, innerProps } = renderProps

      return (
        <BuiltInMultiValueRemove
          {...renderProps}
          innerProps={{
            ...innerProps,
            onClick: () => this.deselect(data.id),
          }}
        />
      )
    }

    return (
      <Box mt={1} id={this.props.id}>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          components={{ Option, MultiValueLabel, MultiValueRemove }}
          placeholder="Please select"
          noOptionsMessage={() => 'No results found.'}
          styles={{
            option: (provided: Record<string, any>) => ({
              ...provided,
              color: 'black',
              display: 'flex',
              'flex-direction': 'row',
              'align-items': 'center',
            }),
          }}
          value={value}
          onChange={this.select}
          options={options}
        />
      </Box>
    )
  }
}

export default connectFirestore((db, { businessId, manager }: Props, uid: string) => {
  if (businessId) {
    return {
      uid,
      managers: getManagersByBusinessId(businessId),
      groupManagers: manager && manager.groupBusinesses ? getGroupManagersByBusinessId(businessId) : null,
    }
  }
  return { uid }
}, ManagerSelector)
