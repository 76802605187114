import { firestoreHttpsCallable } from './util'

export const accessGrant = async (accessType: 'staffer' | 'business', userId: string, sentFrom?: 'app' | 'web') => {
  const params = { accessType, userId, sentFrom }
  return firestoreHttpsCallable('accessGrant', params)
}

export const accessBlock = async (
  accessType: 'staffer' | 'manager' | 'business',
  userId: string,
  blocked?: boolean
) => {
  const params = blocked === undefined ? { accessType, userId } : { accessType, userId, blocked }
  return firestoreHttpsCallable('accessBlock', params)
}

export const sendAccessRequest = (accessType: 'staffer' | 'business') =>
  firestoreHttpsCallable('accessRequest', { accessType })

export const redeemInvite = (accessType: 'staffer' | 'manager', inviteCode: string) =>
  firestoreHttpsCallable('redeemInvite', { accessType, inviteCode })
