import type { BusinessPermissions, BusinessType } from '../../../src/types/business'
import { BUSINESS_PERMISSIONS } from '../constants/permissions'
import { canPostCustomLocationJobs } from './index'

export const hasPermissionByAdmin = (
  business: BusinessType, // | { permissions?: BusinessPermissions, businessType: string },
  permissionName: keyof BusinessPermissions
): boolean => {
  if (!business) {
    return false
  }

  // disallow posting temp jobs for Sweden
  // (purposefully not !== 'Norway' because of some testing countries on dev)
  if (business.address && business.address.country === 'Sweden' && permissionName === 'postTemporaryJob') {
    return false
  }

  // Special case - custom location jobs are requred for Event company regardless of the permission
  if (permissionName === 'postWithCustomAddress' && business.businessType === 'Event company') {
    return true
  }

  if (
    // if no permission object is defined, everything is allowed,
    // except freelance job which depends on businessType
    // (for backwards compatibility)
    !business.permissions ||
    !(permissionName && permissionName in business.permissions) // this line is only here to prevent visual bug
    // this case only apply in intermediate state when the permission object is created
    // but not yet fully filled by onWrite cf
  ) {
    if (permissionName === 'postFreelanceJob') {
      if (business.businessType === 'Other') {
        return !!(business.permissions && business.permissions.postFreelanceJob)
      }
    }

    // Disable 'postWithCustomAddress' by default for non-eligible businesses
    if (permissionName === 'postWithCustomAddress') {
      return canPostCustomLocationJobs(business)
    }

    return true
  }
  return business.permissions[permissionName] || false
}

// manager can disable some permissions if they don't want them
export const hasSelfPermission = (
  business:
    | BusinessType
    | {
        selfPermissions?: BusinessPermissions
        businessType: string
      },
  permissionName: keyof BusinessPermissions
) => {
  if (!business) {
    return false
  }
  if (
    // Special case - custom location jobs are requred for Event company regardless of the permission
    permissionName === 'postWithCustomAddress' &&
    business.businessType === 'Event company'
  ) {
    return true
  }

  if (!business.selfPermissions) {
    return true // allow by default
  }
  return business.selfPermissions[permissionName] === undefined
    ? true // allow by default
    : business.selfPermissions[permissionName] || false
}

// consider both permission by admins and permission by the manager
export const hasPermission = (
  business: BusinessType, // | { permissions?: BusinessPermissions, selfPermissions?: BusinessPermissions,  },
  permissionName: keyof BusinessPermissions
): boolean => hasPermissionByAdmin(business, permissionName) && hasSelfPermission(business, permissionName)

// list all permission that the business has
export const listAllPermissions = (business?: BusinessType): null | Array<keyof BusinessPermissions> => {
  if (!business) {
    return null
  }

  return (Object.keys as <T>(o: T) => Extract<keyof T, string>[])(BUSINESS_PERMISSIONS).filter((permissionName) =>
    hasPermission(business, permissionName)
  )
}
