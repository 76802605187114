import { Box, Button, Divider, Grid, Typography } from '@material-ui/core'
import { Fragment } from 'react'
import type { CalculatorParams } from '../../helpers/jobs'
import { costCalculator } from '../../helpers/jobs'
import { formatCurrency } from '../../helpers/localStringFormatter'
import { formatHours } from '../../helpers/time'
import muiModalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import styles from './modals.module.css'

type Props = {
  data: CalculatorParams
  onClose: () => void | Promise<void>
}

const JobCostBreakdownModal = (props: Props) => {
  const { data, onClose } = props
  const { constants, hourly, total } = costCalculator(data)

  const Row = ({ title, value }: { title: any; value: any }) => (
    <Grid item xs={12} container>
      <Grid item xs={6} alignItems="center">
        <Typography variant="h6" color="textPrimary">
          {title}:
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </Grid>
  )

  return (
    <Fragment>
      <ModalHeader close={onClose}>Cost breakdown</ModalHeader>
      <Box p={2} className={muiModalStyles.modalContent}>
        <Box pb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">This includes salary, social costs, service charge</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Staffers charges <strong>{hourly.serviceCharge}</strong> {constants.currency}
                /worked hour on top of the salary.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            {[
              [
                <Box key={1} display="inline-flex" alignItems="center">
                  Wage per hour &nbsp; <small className={styles.note}>{' (estimate) '}</small>
                </Box>,
                `${formatCurrency(hourly.salary)} ${constants.currency}`,
              ],
              ['Social costs', `+${formatCurrency(hourly.social)} ${constants.currency}`],
              ['Service charge per hour', `+${formatCurrency(hourly.serviceCharge)} ${constants.currency}`],
            ].map(([title, value]) => (
              <Row key={value as string} title={title} value={value} />
            ))}
          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <Row title="Total cost per hour" value={`= ${formatCurrency(hourly.total)} ${constants.currency}`} />
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <Row
            title="Total cost"
            value={`⇒ ${formatCurrency(hourly.total)} × ${formatHours(constants.hours)} = ${formatCurrency(
              total.total,
            )} ${constants.currency}${constants.hours === 0 ? ' *' : ''}`}
          />
        </Grid>
        <Grid container spacing={2}>
          {constants.hours === 0 && (
            <Grid item xs={12}>
              <Box py={2}>
                <Typography variant="caption">* Total cost will be calculated once you define shifts.</Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box py={2}>
              <Typography variant="caption" className={styles.note}>
                Minimum wages are higher on event jobs as all social costs are included (arbeidsgiveravgift). You will
                receive an invoice for all the hours.
              </Typography>
            </Box>
          </Grid>
          <Grid item container spacing={2} justify="center">
            <Grid item xs={3}>
              <Button fullWidth variant="contained" color="primary" onClick={onClose}>
                OK
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}

export default JobCostBreakdownModal
