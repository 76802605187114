import { query, schema } from 'qman'
import {
  canBusinessAccessDocumentsPromise,
  getAllStafferLogsById,
  getAllStaffers,
  getAllStaffersGranted,
  getAllStaffersPermissions,
  getAllStaffersRequested,
  getDeniedStaffers,
  getEvaluatedStaffers,
  getNewRequestsStaffers,
  getPendingTaxCardStafferDetails,
  getReferenceCallingStaffers,
  getStafferById,
  getStafferByIdPromise,
  getStafferCertificateDetails,
  getStafferDetailsByCountry,
  getStafferDetailsById,
  getStafferDocumentsById,
  getStafferEmployers,
  getStafferLogsById,
  getStafferLogsByJobId,
  getStafferPermissions,
  getStafferPermissionsByIdPromise,
  getStafferRatings,
  getStafferRatingsFromBusiness,
  getStafferReferrals,
  getStafferReferralsById,
  getStafferReferralsPromise,
  getStaffersByDocumentsUpdate,
  getStaffersByEmail,
  getStaffersByLastLogin,
  getStaffersByPhone,
  getStaffersByTotalShifts,
  getStaffersByUpdatedExperience,
  getStaffersOnCallingList,
  getStaffersReferralsOrdered,
  getStaffersShiftsByShiftIdPromise,
  getStaffersWithPermissions,
  getUnresolvedRegistrationsStaffers,
  getUpdatedStaffers,
  isEmployeeInPoolsOf,
  needsShiftReview,
} from '../api/getters/staffer'
import { collectionFn, documentFn, promiseFn, queryFn } from './schemaFunctions'

export const staffers = schema(
  'staffers',
  query('stafferReferrals', getStafferReferrals, documentFn),
  query('stafferReferralsPromise', getStafferReferralsPromise, promiseFn),
  query('stafferPermissions', getStafferPermissions, documentFn),
  query('stafferPermissionsByIdPromise', getStafferPermissionsByIdPromise, promiseFn),
  query('stafferById', getStafferById, documentFn),
  query('staffersByPhone', getStaffersByPhone, queryFn),
  query('staffersByEmail', getStaffersByEmail, query),
  query('allStaffers', getAllStaffers, queryFn),
  query('allStaffersGranted', getAllStaffersGranted, queryFn),
  query('allStaffersRequested', getAllStaffersRequested, queryFn),
  query('unresolvedRegistrationsStaffers', getUnresolvedRegistrationsStaffers, queryFn),
  query('evaluatedStaffers', getEvaluatedStaffers, queryFn),
  query('newRequestsStaffers', getNewRequestsStaffers, queryFn),
  query('updatedStaffers', getUpdatedStaffers, queryFn),
  query('referenceCallingStaffers', getReferenceCallingStaffers, queryFn),
  query('deniedStaffers', getDeniedStaffers, queryFn),
  query('pendingTaxCardStafferDetails', getPendingTaxCardStafferDetails, queryFn),
  query('stafferCertificateDetails', getStafferCertificateDetails, queryFn),
  query('stafferDetailsById', getStafferDetailsById, documentFn),
  query('staffersShiftsByShiftIdPromise', getStaffersShiftsByShiftIdPromise, promiseFn),
  query('allStafferLogsById', getAllStafferLogsById, collectionFn),
  query('stafferLogsById', getStafferLogsById, queryFn),
  query('stafferLogsByJobId', getStafferLogsByJobId, queryFn),
  query('canBusinessAccessDocumentsPromise', canBusinessAccessDocumentsPromise, promiseFn),
  query('stafferDocumentsById', getStafferDocumentsById, documentFn),
  query('stafferByIdPromise', getStafferByIdPromise, promiseFn),
  query('stafferRatings', getStafferRatings, collectionFn),
  query('stafferRatingsFromBusiness', getStafferRatingsFromBusiness, documentFn),
  query('staffersReferralsOrdered', getStaffersReferralsOrdered, queryFn),
  query('stafferReferralsById', getStafferReferralsById, queryFn),
  query('staffersByUpdatedExperience', getStaffersByUpdatedExperience, queryFn),
  query('staffersByTotalShifts', getStaffersByTotalShifts, queryFn),
  query('staffersByLastLogin', getStaffersByLastLogin, queryFn),
  query('staffersByDocumentsUpdate', getStaffersByDocumentsUpdate, queryFn),
  query('staffersOnCallingList', getStaffersOnCallingList, queryFn),
  query('staffersWithPermissions', getStaffersWithPermissions, queryFn),
  query('allStaffersPermissions', getAllStaffersPermissions, queryFn),
  query('stafferDetailsByCountry', getStafferDetailsByCountry, queryFn),
  query('stafferEmployers', getStafferEmployers, promiseFn),
  query('needsShiftReview', needsShiftReview, promiseFn),
  query('isEmployeeInPoolsOf', isEmployeeInPoolsOf, promiseFn)
)
