import { Divider, List } from '@material-ui/core'
import * as R from 'ramda'
import React, { PureComponent } from 'react'
// @ts-ignore react-firestore-connect has no exported @types/ module
import { connectFirestore } from 'react-firestore-connect'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import type { BusinessType } from '../../../../../src/types/business'
import type { Firestore } from '../../../../../src/types/firebase'
import type { GroupJobTypesType } from '../../../../../src/types/groups'
import type { JobType } from '../../../../../src/types/jobs'
import type { PoolType } from '../../../../../src/types/pools'
import EditCompanyProfile from '../../../components/modals/EditCompanyProfile'
import InviteStafferToPool from '../../../components/modals/InviteStafferToPool'
import InviteToFavoritePool from '../../../components/modals/InviteToFavoritePool'
import EditJob from '../../../components/modals/JobVariantSelector'
import RedirectCustomPosition from '../../../components/modals/RedirectCustomPosition'
import Modal from '../../../components/mui/Modal'
import type { IntegrationsCtx } from '../../../components/providers/IntegrationsProvider'
import { withIntegrations } from '../../../components/providers/IntegrationsProvider'
import { isCompanyProfileComplete } from '../../../helpers/business'
import { getGroupCustomJobTypes } from '../../../staffers/api/getters/groups.legacy'
import SidebarHeader from './SidebarHeader'
import { getActiveItem } from './getActiveItem'
import { getActionItems, getSidebarItems, renderActionItems, renderSidebarItems } from './itemCreator'
import styles from './sidebar.module.css'

type Props = {
  business: BusinessType
  externalJob: [JobType] | null // a single job fetched, to check if business has ext. job
  tempJob?: [JobType] | null // a single job fetched, to check if business has temp. job
  eventJob?: [JobType] | null // a single job fetched, to check if business has freelance job
  pool: PoolType | null
  groupJobTypes?: GroupJobTypesType
  integrationsCtx: IntegrationsCtx
  unreadMessages: number
  unseenTemporary: number
  unseenFreelance: number
  unseenJobAds: number
  unresolvedTempJobs: number
  unresolvedFreelanceJobs: number
  unseenTemporaryCancellations: number
  unseenFreelanceCancellations: number
} & RouteComponentProps

type State = {
  isSidebarOpen: boolean
  expandedItems: {
    // this is generated dynamically in constructor
    [buttonName: string]: boolean
  }
  isModalOpen: {
    completeProfile: boolean
    createExternalJob: boolean
    createCustomPosition: boolean
    createInternalJob: boolean
    createJob: boolean
    inviteToPool: boolean
    inviteToFavoritePool: boolean
  }
}

class Sidebar extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    // Calculates notification badges for individual items
    const badges = {
      unseenTemporary: props.unseenTemporary,
      unseenFreelance: props.unseenFreelance,
      unseenJobAds: props.unseenJobAds,
      unreadMessages: props.unreadMessages,
      unresolvedTempJobs: props.unresolvedTempJobs,
      unresolvedFreelanceJobs: props.unresolvedFreelanceJobs,
      unseenTemporaryCancellations: props.unseenTemporaryCancellations,
      unseenFreelanceCancellations: props.unseenFreelanceCancellations,
    }
    // Generating sidebar content
    const menuItems = getSidebarItems(badges, props.pool, props.business)
    // Getting active item (navigation helper - activeParent / activeChild)
    const activeItem = getActiveItem(props.location, menuItems)
    // Fetches the defined sidebar items defined in './itemCreator.js'
    this.state = {
      isSidebarOpen: true,
      isModalOpen: {
        completeProfile: false,
        createExternalJob: false,
        createCustomPosition: false,
        createInternalJob: false,
        createJob: false,
        inviteToPool: false,
        inviteToFavoritePool: false,
      },
      // We generate state for expandible items and expand the one that's currently active (if any)
      expandedItems: menuItems
        .filter(({ collapsibleItems }) => !!collapsibleItems)
        .reduce((stateObject, { name }) => R.assoc(name, activeItem.activeParent === name, stateObject), {}),
    }
  }

  toggleSidebar = () =>
    this.setState((prevState) => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }))

  toggleModal = (modalName: string) =>
    this.setState((prevState: State) => ({
      isModalOpen: {
        ...prevState.isModalOpen,
        [modalName as keyof State['isModalOpen']]: !prevState.isModalOpen[modalName as keyof State['isModalOpen']],
      },
    }))

  toggleExpandableItem = (buttonName: string) => {
    this.setState((prevState: State) => ({
      expandedItems: R.assoc(buttonName, !prevState.expandedItems[buttonName], prevState.expandedItems),
    }))
  }

  render() {
    const {
      business,
      externalJob,
      tempJob,
      eventJob,
      groupJobTypes,
      location,
      pool,
      unreadMessages,
      unseenTemporary,
      unseenFreelance,
      unseenJobAds,
      unresolvedTempJobs,
      unresolvedFreelanceJobs,
      unseenTemporaryCancellations,
      unseenFreelanceCancellations,
      integrationsCtx,
    } = this.props

    const { expandedItems, isSidebarOpen, isModalOpen } = this.state

    const { businessName, businessId, isFromAppOnboarding, groupId } = business

    const hasExternalJob = !!externalJob && externalJob.length > 0
    const hasTempJob = !!tempJob && tempJob.length > 0
    const hasEventJob = !!eventJob && eventJob.length > 0

    const integrationsFetchFailed: true | undefined = (!integrationsCtx.list && !integrationsCtx.loading) || undefined

    const badges = {
      unseenTemporary,
      unseenFreelance,
      unseenJobAds,
      unresolvedTempJobs,
      unresolvedFreelanceJobs,
      unseenTemporaryCancellations,
      unseenFreelanceCancellations,
      integrations: integrationsFetchFailed,
      unreadMessages,
    }
    const menuItems = getSidebarItems(badges, pool, business, hasExternalJob, hasTempJob, hasEventJob)
    const activeItem = getActiveItem(location, menuItems)
    const actionItems = getActionItems(pool, activeItem, business, groupId, groupJobTypes)

    const shouldCompleteProfile = isFromAppOnboarding ? !isCompanyProfileComplete(business) : false

    return (
      <React.Fragment>
        {/* Sidebar definitions */}
        <List disablePadding className={isSidebarOpen ? styles.sidebar : styles.collapsedSideBar}>
          {/* Top of the navbar - logo */}
          <SidebarHeader onCollapse={this.toggleSidebar} isSidebarOpen={isSidebarOpen} businessName={businessName} />
          {/* Renders individual sidebar items defined in './itemCreator.js */}
          {renderSidebarItems(menuItems, activeItem, isSidebarOpen, expandedItems, this.toggleExpandableItem)}
          {/* Sidebar action items */}
          <Divider />
          {renderActionItems(actionItems, isSidebarOpen, shouldCompleteProfile, this.toggleModal)}
        </List>
        {/* Action item modals */}
        {/* Create External Job */}
        <Modal isOpen={isModalOpen.createExternalJob} contentLabel="Create job" enforcedWidth="extraLarge">
          <EditJob
            business={business}
            businessId={business.businessId || null}
            jobId={null}
            closeModal={() => this.toggleModal('createExternalJob')}
            defaultCloseModal={() => this.toggleModal('createExternalJob')}
            preSelectedPoolAccess="PUBLIC"
            integrationsCtx={integrationsCtx}
          />
        </Modal>
        {/* Create Internal Job */}
        <Modal isOpen={isModalOpen.createInternalJob} contentLabel="Create job">
          <EditJob
            business={business}
            businessId={business.businessId || null}
            jobId={null}
            closeModal={() => this.toggleModal('createInternalJob')}
            defaultCloseModal={() => this.toggleModal('createInternalJob')}
            preSelectedPoolAccess="PRIVATE"
            integrationsCtx={integrationsCtx}
          />
        </Modal>
        <Modal isOpen={isModalOpen.createCustomPosition} contentLabel="Create custom position">
          <RedirectCustomPosition groupId={business.groupId} onClose={() => this.toggleModal('createCustomPosition')} />
        </Modal>
        <Modal isOpen={isModalOpen.inviteToPool} contentLabel="Invite to internal staff">
          <InviteStafferToPool
            businessId={business.businessId}
            groupId={business.groupId}
            closeModal={() => this.toggleModal('inviteToPool')}
          />
        </Modal>
        <Modal isOpen={isModalOpen.inviteToFavoritePool} contentLabel="Invite to favorite pool">
          <InviteToFavoritePool
            closeModal={() => this.toggleModal('inviteToFavoritePool')}
            businessId={business.businessId}
          />
        </Modal>
        <Modal contentLabel="Complete profile" isOpen={this.state.isModalOpen.completeProfile}>
          <EditCompanyProfile
            type="complete-profile"
            closeModal={() => this.toggleModal('completeProfile')}
            businessId={businessId}
          />
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(
  withIntegrations(
    connectFirestore(
      (db: Firestore, props: Props) => ({
        groupJobTypes: props.business.groupId ? getGroupCustomJobTypes(db, props.business.groupId) : null,
      }),
      Sidebar
    )
  )
)
