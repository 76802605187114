import VerifiedUserIcon from '../../img/VerifiedUserIcon.svg'
import styles from './Avatar.module.css'

export type AvatarSize = 'small' | 'medium' | 'large'

type Props = {
  imageUrl?: string
  avatarSize?: AvatarSize
  verified?: boolean
}

const verifiedBadgeStyles = {
  small: styles['verifiedBadge--small'],
  medium: styles['verifiedBadge--medium'],
  large: styles['verifiedBadge--large'],
} as const

const Avatar = ({ verified, avatarSize = 'small', imageUrl }: Props) => {
  const imageSource = imageUrl || require('../../img/profileAvatar.png')

  return (
    <div className={styles[avatarSize]}>
      <img alt="Avatar" src={imageSource} className={styles.image} />
      {verified && (
        <img
          src={VerifiedUserIcon}
          alt="verified user"
          className={`${styles.verifiedBadge} ${verifiedBadgeStyles[avatarSize]}`}
        />
      )}
    </div>
  )
}

export default Avatar
