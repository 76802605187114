import { Box, Card, CardContent, Typography } from '@material-ui/core'
import * as R from 'ramda'
import { Fragment } from 'react'
import type { CustomPositionType } from '../../../../../src/types/groups'

type Props = {
  country: string
  groupName: string
  numberOfBusinesses: number | string
  positions: CustomPositionType[]
}

const EmptyGroupPreview = (props: Props) => {
  const { country, groupName, numberOfBusinesses, positions } = props

  const businessesArray = numberOfBusinesses ? R.range(1, parseInt(numberOfBusinesses as string, 10) + 1) : []
  const filteredPositions = positions.filter(({ name }: { name: string }) => !!name)

  return (
    <Fragment>
      <Typography component="h1">Summary</Typography>
      <Box my={2}>
        <Card>
          <CardContent>
            <Typography component="h2">Group name:</Typography>
            <Typography>
              {groupName} <em>({country})</em>
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {filteredPositions.length > 0 && (
        <Box my={2}>
          <Card>
            <CardContent>
              <Typography component="h2">Positions:</Typography>
              {positions.map((position, index) => (
                <Typography key={index}>
                  &#xb7;
                  {position.name}
                </Typography>
              ))}
            </CardContent>
          </Card>
        </Box>
      )}

      {businessesArray.length > 0 && (
        <Box my={2}>
          <Card>
            <CardContent>
              <Typography component="h2">Businesses in the group:</Typography>
              <Box display="flex" flexDirection="column">
                {businessesArray.map((businessNum) => (
                  <Typography key={businessNum}>
                    &#xb7; {groupName} {businessNum}
                  </Typography>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </Fragment>
  )
}

export default EmptyGroupPreview
