import firebase from 'firebase/compat/app'
import type { ChatMetadata } from '../../../../src/types/chats'

type ChatMetadataWithId = ChatMetadata & { id: string }

export const getChatsOfParticipants = (uids: Array<string>): Promise<Array<ChatMetadataWithId>> =>
  new Promise((resolve) => {
    const firebaseDB = firebase.database().ref('/')
    const ref = firebaseDB.child('chat-metadata')
    ref
      .orderByChild(`users/${uids[0]}`)
      .equalTo(true)
      .once('value', (snap) => {
        const chats = (snap.val() || {}) as Record<string, ChatMetadata>
        const listedChatsWithIds = Object.entries(chats).map(
          ([id, chat]) =>
            ({
              ...chat,
              id,
            }) as ChatMetadataWithId
        )
        const filteredChats = listedChatsWithIds.filter(
          ({ eventId, users }) =>
            !eventId && uids.every((uid) => users[uid]) && Object.values(users).length === uids.length
        )
        resolve(filteredChats)
      })
  })
