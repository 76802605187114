import MomentUtils from '@date-io/moment'
import { blueGrey } from '@material-ui/core/colors'
import indigo from '@material-ui/core/colors/indigo'
import { StylesProvider, ThemeProvider, createTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import firebase from 'firebase/compat/app'
import 'firebase/compat/performance'
import Gleap from 'gleap'
import 'moment/locale/en-gb'
import { render } from 'react-dom'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { toast } from 'react-toastify'
// @ts-ignore package with no exported-types
import { initializeFirebase } from 'react-firestore-connect'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { AddBusinessWebsite } from './components/modals/AddBusinessWebsiteModal'
import { Confirmation } from './components/modals/ConfirmationModal'
import { OptionPicker } from './components/modals/OptionPickerModal'
import { RejectionMessage } from './components/modals/RejectionMessageModal'
import { PerfProvider, initPerf } from './components/providers/PerfProvider' // must be after firebase init
import { config, gleapKey } from './config'
import './index.css'
import * as serviceWorker from './serviceWorkerRegistration'

firebase.initializeApp(config)
firebase.firestore()
initPerf()

// firebase.firestore().enablePersistence()
//   .catch((err) => {
//     if (err.code === 'failed-precondition') {
//       console.warn('Unfortunately, offline persistence works just in one tab at the time.')
//     } else if (err.code === 'unimplemented') {
//       console.warn('This browser unfortunately
//       does not support features requied to enable offline persistence')
//     }
//   })

const theme = createTheme({
  palette: {
    primary: {
      main: '#2ECC71',
      contrastText: '#fff',
    },
    secondary: blueGrey,
    // @ts-ignore MUI Overrides
    default: {
      main: '#A8A8A',
      contrastText: '#fff',
    },
  },
  overrides: {
    // @ts-ignore MUI Overrides
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: indigo.A700,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: indigo.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: indigo.A700,
        '&:hover': {
          backgroundColor: indigo.A200,
        },
      },
      dayDisabled: {
        color: indigo['100'],
      },
      current: {
        color: indigo.A700,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: indigo['400'],
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '10px',
      },
    },
  },
})

initializeFirebase(firebase)

const tagManagerArgs = {
  gtmId: 'G-7JZNDFZ43K',
}

TagManager.initialize(tagManagerArgs)

// optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const advancedMatching = {} as ReactPixel.AdvancedMatching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}

ReactPixel.init('495594900896821', advancedMatching, options)

const app = document.getElementById('root')

render(
  <ThemeProvider theme={theme}>
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {/* Router expects direct Route components as children, they are defined inside App */}
        {/* but they are dynamic because of nature of compoany/super-aadmin */}
        {/* @ts-expect-error */}
        <Router>
          <PerfProvider>
            <App />
          </PerfProvider>
        </Router>
        <Confirmation />
        <RejectionMessage />
        <AddBusinessWebsite />
        <OptionPicker />
      </MuiPickersUtilsProvider>
    </StylesProvider>
  </ThemeProvider>,
  app
)
serviceWorker.register({ skipWaiting: true, reload: true })

Gleap.initialize(gleapKey)

// eslint-disable-next-line no-undef
if (window.matchMedia('only screen and (max-width: 1200px)').matches) {
  const currentLocation = global.location.pathname
  if (!currentLocation.includes('integration-auth-done')) {
    toast.info(
      'Using unsupported device size. Some features might not work correctly. If you are on mobile, please download our app "STAFFERS".'
    )
  }
}
