import type { ChangeEvent, ComponentType } from 'react'
import { Component } from 'react'
// @ts-ignore ReactTool has no exported @types/ module
import ReactTooltip from 'react-tooltip'
import type { BusinessType } from '../../../src/types/business'
import type { CountryNameType, SuperAdminType } from '../../../src/types/common'
import Header from '../components/header/header'
import Menu from '../components/menu/Menu'
import RegionSelectorContainer from '../components/regionSelectorContainer/RegionSelectorContainer'
import { exampleRegions } from '../helpers/regions'
import styles from './basicLayout.module.css'
type Props = {
  business?: BusinessType
  selectedCountry: CountryNameType
  superAdmin?: SuperAdminType
}

type State = {
  selectableCountries: Array<CountryNameType>
  // selectableRegions: Array<string>,
  // selectedCity: string,
  selectedCountry: CountryNameType
}

const regions = exampleRegions

export const BasicLayout = (Layout: ComponentType<Props>) =>
  // Exception: Wrapper component
  // eslint-disable-next-line react/display-name
  class extends Component<Props, State> {
    constructor(props: Props) {
      super(props)

      const storedCountry = global.localStorage.getItem('selectedCountry') as CountryNameType | null

      this.state = {
        selectableCountries: regions.map((region) => region.countryName),
        // selectableRegions: regions.map(region => region.cityName),
        // selectedCity: regions[0].cityName,
        selectedCountry: storedCountry || regions[0].countryName,
      }
    }

    onCountryChange = (selectedCountry: ChangeEvent<HTMLSelectElement> | string) => {
      const {
        // This is weird - once we're treating it as changeevent and once as value.
        // But rather not touching it given we've been using it for long time,
        // albeit there is likely something broken here, but unless it gets reported
        // will rather leave it untouched
        // @ts-ignore
        target: { value },
      } = selectedCountry
      if (selectedCountry !== this.state.selectedCountry) {
        // Refresh the list of regions for the newly selected country
        // const selectableRegions = getSelectableRegionsForCountry(regions, value)

        global.localStorage.setItem('selectedCountry', value)
        this.setState({
          selectedCountry: value,
          // selectedCity: R.head(selectableRegions) || '',
          // selectableRegions,
        })
      }
    }

    // onCityChange = (selectedCity: string) => {
    //   this.setState({ selectedCity })
    // }

    render() {
      const {
        selectableCountries,
        // selectableRegions,
        // selectedCity,
        selectedCountry,
      } = this.state

      const { business, superAdmin } = this.props
      if (!business && !superAdmin) {
        return null
      }
      // NOTE this is no longer used for company admin (refer to company/CompanyLayout)

      // Place all problematic ReactTooltips into this render method to render correctly
      return (
        <div className={styles.wrapper}>
          <Header isLoggedIn isSuperAdmin business={business} />
          <ReactTooltip id="oneWayInterview" />
          <ReactTooltip id="notificationToggler" />
          <div className={styles.body}>
            <div className={styles.menuWrapper}>
              <div>
                <div>
                  <RegionSelectorContainer
                    // onCityChange={this.onCountryChange}
                    onCountryChange={this.onCountryChange}
                    selectableCountries={selectableCountries}
                    // selectableRegions={selectableRegions}
                    // selectedCity={selectedCity}
                    selectedCountry={selectedCountry}
                  />
                </div>
                <Menu
                  // @ts-ignore incorrect error, prop exists
                  isSuperAdmin
                  superAdmin={superAdmin}
                  business={business}
                  selectedCountry={selectedCountry}
                />
              </div>
            </div>
            <div className={styles.layoutWrapper}>
              <Layout {...this.props} selectedCountry={selectedCountry} />
            </div>
          </div>
        </div>
      )
    }
  }
