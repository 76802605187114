/* eslint-disable react/jsx-pascal-case */
import { createInMemoryCache } from '@algolia/cache-in-memory'
import algoliasearch from 'algoliasearch/lite'
import { Configure, Index, InstantSearch } from 'react-instantsearch'
import { Route, Switch } from 'react-router-dom'
import type { CountryNameType, SuperAdminType } from '../../../../src/types/common'
import { SuperAdminModules } from '../../LoadableModules'
import type { ContextProps } from '../../components/providers/InvoicePreviewProvider'
import InvoicePreviewProvider from '../../components/providers/InvoicePreviewProvider'
import ShortlistsProvider from '../../components/providers/ShortlistsProvider'
import { algoliaInfo } from '../../config'
import { MAIN_BUSINESS_INDEX, MAIN_STAFFERS_INDEX, UNRESOLVED_SHIFTS_INDEX } from '../../constants/algolia'
import { PATHS } from '../../constants/routes'
import { BasicLayout } from '../BasicLayout'

type Props = {
  // Superadmin is actually always present, but flow complain otherwise
  selectedCountry: CountryNameType
  superAdmin?: SuperAdminType
} & ContextProps

const algoliaCache = createInMemoryCache()

export const searchClient = algoliasearch(algoliaInfo.APP_ID, algoliaInfo.SEARCH_KEY, {
  responsesCache: algoliaCache,
})

export const clearAlgoliaCache = async () => {
  await algoliaCache.clear()
}

const SuperAdmin = (props: Props) => {
  const { selectedCountry = 'Norway', superAdmin } = props

  if (!superAdmin || !selectedCountry) {
    return null
  }
  const { backOfficeAccess } = superAdmin

  return (
    <Switch>
      <Route
        exact
        path={PATHS.payrolls}
        component={() => <SuperAdminModules.payrolls countryName={selectedCountry} />}
        // there could be render instead of component here, to prevent remounting
        // but it somehow breaks connectFirestore in TemporaryPayroll component
      />
      <Route exact path={PATHS.groupPositions} component={SuperAdminModules.groupPositions} />
      <Route exact path={PATHS.businessTempPayroll} component={SuperAdminModules.businessTempPayroll} />
      <Route exact path={PATHS.businessEventJobsPayroll} component={SuperAdminModules.businessEventJobsPayroll} />
      <Route exact path={PATHS.regions} component={SuperAdminModules.regions} />
      <Route path={PATHS.regionDetail} component={SuperAdminModules.regionDetail} />
      <Route exact path={PATHS.companyRequests} component={SuperAdminModules.companyRequests} />
      <Route path={PATHS.companyRequestDetail} component={SuperAdminModules.companyRequestDetail} />
      <Route
        exact
        path={PATHS.companies}
        // @ts-ignore react-loadable cannot directly infer props
        component={() => <SuperAdminModules.companies countryName={selectedCountry} />}
      />
      <Route path={PATHS.groupDetail} component={SuperAdminModules.groupDetail} />
      <Route exact path={PATHS.groups} component={SuperAdminModules.groups} />
      <Route exact path={PATHS.companyPaymentCycles} component={SuperAdminModules.companyPaymentCycles} />
      <Route
        path={PATHS.companyDetail}
        component={() => <SuperAdminModules.companyDetail countryName={selectedCountry} />}
      />
      <Route path={PATHS.paymentDetail} component={SuperAdminModules.companyPaymentDetails} />
      <Route exact path={PATHS.reports} component={SuperAdminModules.unpaidReports} />
      <Route
        exact
        path={PATHS.stafferRequests}
        // @ts-ignore react-loadable cannot directly infer props
        component={() =>
          selectedCountry && (
            // @ts-ignore react-loadable cannot directly infer props
            <SuperAdminModules.stafferRequests countryName={selectedCountry} />
          )
        }
      />
      <Route path={PATHS.stafferRequestDetail} component={SuperAdminModules.stafferRequestDetail} />
      <Route
        exact
        path={PATHS.staffers}
        // @ts-ignore react-loadable cannot directly infer props
        component={() => <SuperAdminModules.staffers countryName={selectedCountry} />}
      />
      <Route
        path={PATHS.staffersSearch}
        component={() => (
          <Index indexName={MAIN_STAFFERS_INDEX}>
            <SuperAdminModules.search />
          </Index>
        )}
      />
      <Route
        path={PATHS.companiesSearch}
        component={() => (
          <Index indexName={MAIN_BUSINESS_INDEX}>
            <SuperAdminModules.search />
          </Index>
        )}
      />
      <Route
        path={PATHS.unresolvedShifts}
        component={() => (
          <Index indexName={UNRESOLVED_SHIFTS_INDEX}>
            <Configure hitsPerPage={2000} />
            <SuperAdminModules.unresolvedShifts />
          </Index>
        )}
      />
      {/* Passing a component like this forces remount route changes, needed because of CDM */}
      <Route path={PATHS.stafferDetail} component={() => <SuperAdminModules.stafferDetail />} />
      <Route exact path={PATHS.jobs} component={() => <SuperAdminModules.jobs countryName={selectedCountry} />} />
      {/* Passing a component like this forces remount route changes, needed because of CDM */}
      <Route path={PATHS.superadminJobDetail} component={() => <SuperAdminModules.jobDetail />} />
      <Route
        path={PATHS.jobDeletedDetail}
        // @ts-ignore react-loadable cannot directly infer props
        component={() => <SuperAdminModules.jobDetail deleted />}
      />
      <Route
        path={PATHS.jobUnpublishedDetail}
        // @ts-ignore react-loadable cannot directly infer props
        component={() => <SuperAdminModules.jobDetail unpublished />}
      />
      <Route path={PATHS.jobAdDetail} component={() => <SuperAdminModules.jobAdDetail />} />
      <Route
        path={PATHS.unpublishedAdDetail}
        // @ts-ignore react-loadable cannot directly infer props
        component={() => <SuperAdminModules.jobAdDetail unpublished />}
      />
      {/* @ts-ignore react-loadable cannot directly infer props */}
      <Route
        path={PATHS.integrations}
        component={() => <SuperAdminModules.integrations countryName={selectedCountry} />}
      />
      <Route path={PATHS.inviteCodes} component={SuperAdminModules.inviteCodes} />
      {backOfficeAccess && <Route exact path={PATHS.ambassadors} component={SuperAdminModules.ambassadors} />}
      <Route exact path={PATHS.notifications} component={SuperAdminModules.notifications} />
      {backOfficeAccess && <Route path={PATHS.referrals} component={SuperAdminModules.referrals} />}
      <Route path={PATHS.errorLogs} component={SuperAdminModules.errorLogs} />
      <Route path={PATHS.settings} component={SuperAdminModules.settings} />
      <Route render={() => <h1>Page not found</h1>} />
    </Switch>
  )
}

// @ts-ignore react-loadable cannot directly infer props
const SuperAdminWithLayout = BasicLayout(SuperAdmin)

const SuperAdminWithProviders = (props: Props) => (
  <InstantSearch searchClient={searchClient}>
    <InvoicePreviewProvider>
      <ShortlistsProvider>
        <SuperAdminWithLayout {...props} />
      </ShortlistsProvider>
    </InvoicePreviewProvider>
  </InstantSearch>
)

export default SuperAdminWithProviders
