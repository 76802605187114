import type { Timestamp } from 'firebase/firestore'
import { getDoc } from 'firebase/firestore'
import type { JobStaffer } from '../../../../../src/types/jobs'
import type { PermissionsTypeWithId, PoolType } from '../../../../../src/types/pools'
import type { StafferTypeWithId } from '../../../../../src/types/staffer'
import { getJobStafferDetailById } from './jobs'
import { getConnectedPoolsByManagerId } from './pools'
import { getStafferById, getStafferPermissions } from './staffer'

export type IStaffer = {
  requestedAt: Timestamp
} & JobStaffer

export type DefaultData = {
  connectedPools?: Array<PoolType>
  pool?: PoolType
  staffers?: Array<IStaffer>
  staffersPermissions?: Array<PermissionsTypeWithId>
  staffersProfiles?: Array<StafferTypeWithId>
}

const getStaffersListingData = async ({
  stafferIds,
  uid,
  jobId,
}: {
  stafferIds: Array<string>
  uid: string | null
  jobId: string
}): Promise<DefaultData> => {
  // Manager does not exist
  if (!uid) {
    return {}
  }

  // jobStaffer docs
  const staffers: (IStaffer | null)[] = await Promise.allSettled(
    stafferIds.map(async (stafferId) => {
      const stafferDoc = await getDoc(getJobStafferDetailById(jobId, stafferId))
      if (stafferDoc.exists()) {
        return { ...stafferDoc.data(), id: stafferDoc.id } as IStaffer
      }
      return null
    })
  ).then((results) =>
    results.map((result) => {
      if (result.status === 'fulfilled') {
        return result.value
      }
      return null
    })
  )

  // stafferPermissions docs
  const staffersPermissions: (PermissionsTypeWithId | null)[] = await Promise.allSettled(
    stafferIds.map(async (stafferId) => {
      const stafferPermissionsDoc = await getDoc(getStafferPermissions(stafferId))
      if (stafferPermissionsDoc.exists()) {
        const stafferPermissions = stafferPermissionsDoc.data()
        return { ...stafferPermissions, id: stafferPermissionsDoc.id }
      }

      return null
    })
  ).then((results) =>
    results.map((result) => {
      if (result.status === 'fulfilled') {
        return result.value
      }
      return null
    })
  )

  // stafferPermissions docs
  const staffersProfiles: (StafferTypeWithId | null)[] = await Promise.allSettled(
    stafferIds.map(async (stafferId) => {
      const stafferDoc = await getDoc(getStafferById(stafferId))
      if (stafferDoc.exists()) {
        const stafferData = stafferDoc.data()
        return { ...stafferData, id: stafferDoc.id }
      }
      return null
    })
  ).then((results) =>
    results.map((result) => {
      if (result.status === 'fulfilled') {
        return result.value
      }
      return null
    })
  )

  // pools
  const connectedPools = await getConnectedPoolsByManagerId(uid)
  const pool = (connectedPools && connectedPools[0]) || null

  return {
    connectedPools,
    pool,
    staffers: staffers.filter((staffer): staffer is IStaffer => staffer !== null),
    staffersPermissions: staffersPermissions.filter((staffer): staffer is PermissionsTypeWithId => staffer !== null),
    staffersProfiles: staffersProfiles.filter((staffer): staffer is StafferTypeWithId => staffer !== null),
  }
}

export default getStaffersListingData
