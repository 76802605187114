import { limit, where } from 'firebase/firestore'
import type { BusinessType } from '../../../../../src/types/business'
import type { InviteType } from '../../../../../src/types/common'
import type { PoolType } from '../../../../../src/types/pools'
import { findStafferSkillLevel } from '../../../helpers/staffers'
import {
  getCollectionRef,
  getDataFromCollectionRef,
  getDataFromDocumentRef,
  getDocumentRef,
  getQueryRef,
} from '../wrappers'
import { getBusinessByIdPromise } from './business'
import { getManagerByIdPromise } from './managers'
import { getStafferPermissions } from './staffer'

export const getPoolById = (poolId: string) => getDocumentRef<PoolType>('pools', poolId)

export const getConnectedPools = async (businessId: string): Promise<PoolType[]> => {
  const poolData = await getDataFromDocumentRef(getPoolById(businessId))
  if (!poolData) {
    return []
  }
  const connectedPoolsData: PoolType[] = (
    await Promise.all([...poolData.connectedBusinesses.map((id) => getDataFromDocumentRef(getPoolById(id)))])
  ).filter((x): x is PoolType => x !== null)

  return [poolData, ...connectedPoolsData]
}

export const getConnectedPoolsByManagerId = async (managerId: string) => {
  const managerData = await getManagerByIdPromise(managerId)
  return managerData && managerData.businessId ? getConnectedPools(managerData.businessId) : []
}

export const getAllPools = () => getCollectionRef<PoolType>('pools')

export const getPoolByManagerId = async (userId: string) => {
  const managerData = await getManagerByIdPromise(userId)
  return managerData && managerData.businessId ? getPoolById(managerData.businessId) : []
}

export const getInvitedStafferByCode = (code: string) => getDocumentRef<InviteType>('staffersInvites', code)

export const getInvitedStaffersByPhone = (phone: string) =>
  getQueryRef<InviteType>('staffersInvites', where('used', '==', false), where('phone', '==', phone))

export const getFavoritedStaffersByUsedId = (id: string) =>
  getQueryRef<InviteType>('staffersInvites', where('usedBy', '==', id))

export const getFavoriteInvitedStaffers = (favoritedBy: string) =>
  getQueryRef<InviteType>('staffersInvites', where('favoritedBy', '==', favoritedBy), where('used', '==', false))

export const getInvitedStaffersByPoolId = (poolId: string) =>
  getQueryRef<InviteType>('staffersInvites', where('poolId', '==', poolId))

export const getPendingInvitedStaffersByPoolId = async (poolId: string) =>
  getQueryRef<InviteType>('staffersInvites', where('poolId', '==', poolId), where('used', '==', false))

export const getStafferSkillLevel = async (stafferId: string, businessId: string): Promise<Record<string, string>> => {
  const connectedPools = await getConnectedPools(businessId)
  const stafferPermissions = await getDataFromDocumentRef(getStafferPermissions(stafferId))
  if (connectedPools && connectedPools.length && stafferPermissions && stafferPermissions.businessData) {
    return findStafferSkillLevel(connectedPools, stafferPermissions)
  }
  return {}
}

export const getCustomWage = async (stafferId: string, businessId: string): Promise<number | null> => {
  const stafferPermissions = await getDataFromDocumentRef(getStafferPermissions(stafferId))
  if (stafferPermissions && stafferPermissions.businessData && stafferPermissions.businessData[businessId]) {
    return stafferPermissions.businessData[businessId].customWage || null
  }
  return null
}

export const getConnectedBusinesses = async (businessId: string): Promise<Array<BusinessType | null>> => {
  const connectedPools = await getConnectedPools(businessId)
  return Promise.all(connectedPools.map(async (pool) => getBusinessByIdPromise(pool.businessId)))
}

export const getPoolsByEmployeeId = (stafferId: string) =>
  getQueryRef<PoolType>('pools', where('employees', 'array-contains', stafferId))

export const getEmployeesInPoolsOf = async (businessId: string): Promise<Array<string>> => {
  const poolData = await getDataFromDocumentRef(getPoolById(businessId))
  if (!poolData) {
    return []
  }
  const stafferIds: Set<string> = new Set()
  const { employees } = poolData
  employees.forEach((stafferId) => {
    stafferIds.add(stafferId)
  })

  // check connected pools
  await Promise.all(
    poolData.connectedBusinesses.map(async (connectedBusinessId) => {
      const connectedPoolData = await getDataFromDocumentRef(getPoolById(connectedBusinessId))
      if (!connectedPoolData) {
        return
      }
      connectedPoolData.employees.forEach((stafferId) => {
        stafferIds.add(stafferId)
      })
    })
  )

  return [...stafferIds]
}

export const getUsedInvitedStaffersByPhone = (phone: string) =>
  getQueryRef<InviteType>('staffersInvites', where('used', '==', true), where('phone', '==', phone), limit(1))

export const getPoolIntegrationEmployees = (poolId: string, integrationName: string) =>
  getCollectionRef('pools', poolId, 'integrations', integrationName, 'employees')

export const getPoolIntegration = (poolId: string, integrationName: string) =>
  getDocumentRef('pools', poolId, 'integrations', integrationName)

export const getExistingInviteByUsedBy = async (usedBy: string) => {
  const invited = await getDataFromCollectionRef(
    getQueryRef<InviteType>('staffersInvites', where('usedBy', '==', usedBy))
  )
  return invited && invited.length ? invited[0] : null
}
