import firebase from 'firebase/compat/app'
import type { Moment, unitOfTime } from 'moment'
import type { Timestamp } from '../../../src/types/firebase'
import moment from '../util/moment'

export type TimeUnit = unitOfTime.DurationConstructor

export const getAgeFromBirthday = (birthday: Date | Moment | Timestamp): number =>
  moment().diff(moment(birthday), 'years')

export const getTimeDiffHours = (timeStart: Date | Moment | Timestamp, timeEnd: Date | Moment | Timestamp): number =>
  moment(timeEnd).diff(moment(timeStart), 'hours', true)

// H is past => now is after H
export const isPast = (time: Date | Moment | Timestamp): boolean => moment().isAfter(moment(time))

// H is past since X => now is after H+X
export const isPastSince = (time: Date | Moment | Timestamp, duration: number, unit: TimeUnit): boolean =>
  moment().isAfter(moment(time).add(duration, unit))

// H is coming within X => now is after H-X
export const isComingWithin = (time: Date | Moment, duration: number, unit: TimeUnit): boolean =>
  moment().isAfter(moment(time).subtract(duration, unit))

// return next date with given day of the month
export const nextDateWithDay = (dayOfMonth: number): Moment => {
  const today = moment().startOf('day')
  const todaysDayOfMonth = today.date()
  return todaysDayOfMonth <= dayOfMonth ? today.date(dayOfMonth) : today.add(1, 'month').date(dayOfMonth)
}

export const formatHours = (hours: number, spaced?: boolean) => {
  const h = Math.floor(hours)
  const m = Math.round((hours * 60) % 60)
  if (spaced) {
    return !m ? `${h}\u00a0h` : `${h}\u00a0h ${m}\u00a0m`
  }
  return !m ? `${h}h` : `${h}h${m}m`
}

export const formatHoursLong = (hours: number) => {
  const h = Math.floor(hours)
  const m = Math.round((hours * 60) % 60)
  return !m
    ? h + (h === 1 ? ' hour' : ' hours')
    : h + (h === 1 ? ' hour ' : ' hours ') + m + (m === 1 ? ' minute' : ' minutes')
}

export const formatDateForEndpoint = (
  date: Date | Timestamp | firebase.firestore.Timestamp | number,
  keepOffset = false
): string => moment(date).toISOString(keepOffset)

export const toMillis = (time: Date | Timestamp | firebase.firestore.Timestamp | number): number =>
  (time as Timestamp).toMillis ? (time as Timestamp).toMillis() : +(time as Date)

export const timeStampFromDate = (date: Date): Timestamp => firebase.firestore.Timestamp.fromDate(date)
