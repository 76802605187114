import type { Duration } from 'moment'
import type { Firestore } from '../../../../../src/types/firebase'
import moment from '../../../util/moment'

export const getUserById = (db: Firestore, uid: string | null) => db.collection('users').doc(uid || undefined)

export const getUserTags = (db: Firestore, uid: string | null) =>
  db
    .collection('users')
    .doc(uid || undefined)
    .collection('tags')

export const getRecentNotifications = (db: Firestore, uid: string | null, maximumAge: Duration) =>
  db
    .collection('users')
    .doc(uid || undefined)
    .collection('notifications')
    .where('content.shouldBeShown', '==', true)
    .where('metadata.emittedAt', '>', moment().subtract(maximumAge).toDate())
    .orderBy('metadata.emittedAt', 'desc')

export const getAdminNotifications = (db: Firestore, uid: string | null) =>
  db
    .collection('users')
    .doc(uid || undefined)
    .collection('notifications')
    .where('content.isAdmin', '==', true)
    .orderBy('metadata.emittedAt', 'desc')
