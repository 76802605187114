import type { Firestore } from '../../../../../src/types/firebase'
import type { ActivityType, MandatoryActivityParams, PossibleActivityTypes } from '../../../../../src/types/settings'

export const getAdminByIdPromise = async (db: Firestore, adminId: string) =>
  db
    .collection('admins')
    .doc(adminId)
    .get()
    .then((doc) => (doc.exists ? doc.data() : null))

export const getAdminById = (db: Firestore, adminId: string) => db.collection('admins').doc(adminId)

export const isAdminEmailPromise = (db: Firestore, email: string): Promise<boolean> =>
  db
    .collection('admins')
    .where('email', '==', email)
    .limit(1)
    .get()
    .then(({ docs }) => docs.length === 1)

export const getAdminActivities = async (db: Firestore, adminId: string) =>
  db
    .collection('admins')
    .doc(adminId)
    .collection('activities')
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => doc.data()))

// Flow sucks and cant accept generic in async arrow syntax
// (causes parsing error), so has to be this way
export async function getAdminActivityType<T extends PossibleActivityTypes>(
  db: Firestore,
  adminId: string,
  activityType: MandatoryActivityParams['name'],
  order?: 'asc' | 'desc'
): Promise<ActivityType<T>[]> {
  return db
    .collection('admins')
    .doc(adminId)
    .collection('activities')
    .where('name', '==', activityType)
    .orderBy('time', order || 'desc')
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => doc.data() as ActivityType<T>))
}
