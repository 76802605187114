import { firestoreHttpsCallable } from './util'

export const createCustomChat = async ({
  jobId,
  recipients,
  shiftId,
}: {
  jobId: string
  recipients: Record<string, boolean>
  shiftId: string
}) =>
  firestoreHttpsCallable('rtCreateCustomChat', {
    jobId,
    recipients,
    shiftId,
  })

export const addUsersToChat = async ({ chatId, recipients }: { chatId: string; recipients: Record<string, boolean> }) =>
  firestoreHttpsCallable('rtAddUsersToChat', {
    chatId,
    recipients,
  })

export const sendMail = async (data: {
  attachments?: any[]
  cc?: string[]
  from: string
  html?: string
  subject?: string
  template?: string
  text?: string
  to: string[]
}) => firestoreHttpsCallable('httpsSendEmail', data)
