import Loadable from 'react-loadable'
import { BeatLoader } from 'react-spinners'
import type { IntegrationType } from '../../src/types/integrations'
import DynamicLoader from './components/DynamicLoader'
import Empty from './components/mui/Empty'

const CompanyDashboardModule = Loadable({
  loader: () => import('./screens/company/dashboard/index'),
  loading: DynamicLoader,
})

const CompanyProfileModule = Loadable({
  loader: () => import('./screens/company/CompanyProfile'),
  loading: DynamicLoader,
})

const IntegrationsModule = Loadable({
  loader: () => import('./screens/company/Integrations'),
  loading: DynamicLoader,
})

const IntegrationPage = Loadable({
  loader: () => import('./screens/company/integrations/IntegrationPage'),
  loading: DynamicLoader,
})

const CompanyStaffersModule = Loadable({
  loader: () => import('./screens/company/staffers/StaffersWrapper'),
  loading: DynamicLoader,
})

const CompanyStafferDetailModule = Loadable({
  loader: () => import('./screens/company/stafferProfile/StafferProfile'),
  loading: DynamicLoader,
})

const CompanyChatsModule = Loadable({
  loader: () => import('./screens/company/chats/Chats'),
  loading: DynamicLoader,
})

const CompanyJobsModule = Loadable({
  loader: () => import('./screens/company/jobs/Jobs'),
  loading: DynamicLoader,
})

const CompanySettingsModule = Loadable({
  loader: () => import('./screens/company/Settings'),
  loading: DynamicLoader,
})

const CompanyManagersModule = Loadable({
  loader: () => import('./screens/company/Managers'),
  loading: DynamicLoader,
})

const CompanyGroupDashboardModule = Loadable({
  loader: () => import('./screens/company/groupSidebar/GroupDashboard/GroupDashboard'),
  loading: DynamicLoader,
})

const CompanyGroupPositionsModule = Loadable({
  loader: () => import('./screens/company/groupSidebar/GroupPositions/GroupPositions'),
  loading: DynamicLoader,
})

const CompanyPayrollsModule = Loadable({
  loader: () => import('./screens/company/Payrolls'),
  loading: DynamicLoader,
})

const CompanyPayrollDetailModule = Loadable({
  loader: () => import('./screens/company/PayrollDetail'),
  loading: DynamicLoader,
})

const CompanyPayrollCostModule = Loadable({
  loader: () => import('./screens/company/PayrollCost'),
  loading: DynamicLoader,
})

// TODO - move to separate constant, i.e. CommonModules

const JobsModule = Loadable({
  loader: () => import('./screens/Jobs'),
  loading: DynamicLoader,
})

const JobAdDetailModule = Loadable({
  loader: () => import('./screens/JobAdDetail'),
  loading: DynamicLoader,
})

const CompanyContractsModule = Loadable({
  loader: () => import('./screens/company/Contracts'),
  loading: DynamicLoader,
})

const ManageJob = Loadable({
  loader: () => import('./screens/company/ManageJob'),
  loading: DynamicLoader,
})

const ManageJobAdModule = Loadable({
  loader: () => import('./screens/company/ManageJobAd'),
  loading: DynamicLoader,
})

export const getSettingsComponent = (integration: IntegrationType) =>
  Loadable({
    loader: () => {
      const name = integration.name.toLowerCase()
      return (
        {
          // to avoid dynamic require we import all options and choose one of them
          planday: import('./screens/company/integrations/planday/Settings'),
        }[name as 'planday'] ||
        new Promise((resolve) => {
          resolve(() => <Empty label={`${name} has no settings page`} />)
        })
      )
    },
    loading: () => <BeatLoader color="gray" />,
  })

// Company admin modules
export const CompanyAdminModules = {
  companyProfile: CompanyProfileModule,
  contracts: CompanyContractsModule,
  dashboard: CompanyDashboardModule,
  staffers: CompanyStaffersModule,
  stafferDetail: CompanyStafferDetailModule,
  chats: CompanyChatsModule,
  jobs: CompanyJobsModule,
  jobDetail: ManageJob,
  jobAdDetail: ManageJobAdModule,
  settings: CompanySettingsModule,
  payrolls: CompanyPayrollsModule,
  payrollDetail: CompanyPayrollDetailModule,
  payrollCost: CompanyPayrollCostModule,
  managers: CompanyManagersModule,
  groupDashboard: CompanyGroupDashboardModule,
  groupPositions: CompanyGroupPositionsModule,
  integrations: IntegrationsModule,
  integrationPage: IntegrationPage,
}

const SuperAdminDashboardModule = Loadable({
  loader: () => import('./screens/superadmin/dashboard/index'),
  loading: DynamicLoader,
})

const SuperAdminRegionsModule = Loadable({
  loader: () => import('./screens/superadmin/Regions'),
  loading: DynamicLoader,
})

const SuperAdminCompanyRequestsModule = Loadable({
  loader: () => import('./screens/superadmin/CompanyRequests'),
  loading: DynamicLoader,
})

const SuperAdminCompanyRequestDetailModule = Loadable({
  loader: () => import('./screens/superadmin/CompanyRequestDetail'),
  loading: DynamicLoader,
})

const SuperAdminCompaniesModule = Loadable({
  loader: () => import('./screens/superadmin/Companies'),
  loading: DynamicLoader,
})

const SuperAdminStaffersModule = Loadable({
  loader: () => import('./screens/superadmin/Staffers'),
  loading: DynamicLoader,
})

const SuperAdminStafferDetailModule = Loadable({
  loader: () => import('./screens/StafferDetail'),
  loading: DynamicLoader,
})

const SuperAdminStafferRequestsModule = Loadable({
  loader: () => import('./screens/superadmin/StafferRequests'),
  loading: DynamicLoader,
})

const SuperAdminStafferRequestDetailModule = Loadable({
  loader: () => import('./screens/superadmin/StafferRequestDetail'),
  loading: DynamicLoader,
})

const SuperAdminSettingsModule = Loadable({
  loader: () => import('./screens/superadmin/Settings'),
  loading: DynamicLoader,
})

const SuperAdminRegionDetailModule = Loadable({
  loader: () => import('./screens/superadmin/RegionDetail'),
  loading: DynamicLoader,
})

const SuperAdminCompanyDetailModule = Loadable({
  loader: () => import('./screens/superadmin/CompanyDetail'),
  loading: DynamicLoader,
})

const SuperAdminCompanyPaymentCycles = Loadable({
  loader: () => import('./screens/superadmin/CompanyPaymentCycles'),
  loading: DynamicLoader,
})

const SuperAdminInviteCodesModule = Loadable({
  loader: () => import('./screens/superadmin/Invites'),
  loading: DynamicLoader,
})

const SuperAdminErrorLogsModule = Loadable({
  loader: () => import('./screens/superadmin/Errors'),
  loading: DynamicLoader,
})

const SuperAdminCompanyPaymentDetailsModule = Loadable({
  loader: () => import('./screens/PaymentDetail'),
  loading: DynamicLoader,
})

const SuperAdminNotificationsModule = Loadable({
  loader: () => import('./screens/superadmin/Notifications'),
  loading: DynamicLoader,
})

const SuperAdminAmbassadorsModule = Loadable({
  loader: () => import('./screens/superadmin/Ambassadors'),
  loading: DynamicLoader,
})

const SuperAdminReferralsModule = Loadable({
  loader: () => import('./screens/superadmin/Referrals'),
  loading: DynamicLoader,
})

const SuperAdminReportsModule = Loadable({
  loader: () => import('./screens/superadmin/Reports'),
  loading: DynamicLoader,
})

const JobDetailModule = Loadable({
  loader: () => import('./screens/JobDetail'),
  loading: DynamicLoader,
})

const SuperAdminGroupsModule = Loadable({
  loader: () => import('./screens/superadmin/Groups'),
  loading: DynamicLoader,
})

const SuperAdminGroupDetailModule = Loadable({
  loader: () => import('./screens/superadmin/GroupDetail'),
  loading: DynamicLoader,
})

const SuperAdminPayrollModule = Loadable({
  loader: () => import('./screens/superadmin/payrolls/Payrolls'),
  loading: DynamicLoader,
})

const SuperAdminBusinessTempModule = Loadable({
  loader: () => import('./screens/superadmin/payrolls/temp-jobs/TemporaryBusinessDetail'),
  loading: DynamicLoader,
})

const SuperAdminBusinessEventJobsModule = Loadable({
  loader: () => import('./screens/superadmin/payrolls/event-jobs/EventJobsBusinessDetail'),
  loading: DynamicLoader,
})

const SuperAdminIntegrationsModule = Loadable({
  loader: () => import('./screens/superadmin/integrations/index'),
  loading: DynamicLoader,
})

const SuperAdminSearch = Loadable({
  loader: () => import('./screens/superadmin/search'),

  loading: DynamicLoader,
})

const SuperAdminUnresolvedShifts = Loadable({
  loader: () => import('./screens/superadmin/unresolvedShifts'),
  loading: DynamicLoader,
})

// Super admin modules
export const SuperAdminModules = {
  dashboard: SuperAdminDashboardModule,
  regions: SuperAdminRegionsModule,
  regionDetail: SuperAdminRegionDetailModule,
  ambassadors: SuperAdminAmbassadorsModule,
  companyRequests: SuperAdminCompanyRequestsModule,
  companyRequestDetail: SuperAdminCompanyRequestDetailModule,
  companies: SuperAdminCompaniesModule,
  companyPaymentCycles: SuperAdminCompanyPaymentCycles,
  companyDetail: SuperAdminCompanyDetailModule,
  companyPaymentDetails: SuperAdminCompanyPaymentDetailsModule,
  groups: SuperAdminGroupsModule,
  groupDetail: SuperAdminGroupDetailModule,
  groupDashboard: CompanyGroupDashboardModule,
  groupPositions: CompanyGroupPositionsModule,
  payrolls: SuperAdminPayrollModule,
  businessTempPayroll: SuperAdminBusinessTempModule,
  businessEventJobsPayroll: SuperAdminBusinessEventJobsModule,
  staffers: SuperAdminStaffersModule,
  stafferRequests: SuperAdminStafferRequestsModule,
  stafferRequestDetail: SuperAdminStafferRequestDetailModule,
  stafferDetail: SuperAdminStafferDetailModule,
  jobs: JobsModule,
  jobDetail: JobDetailModule,
  jobAdDetail: JobAdDetailModule,
  integrations: SuperAdminIntegrationsModule,
  errorLogs: SuperAdminErrorLogsModule,
  inviteCodes: SuperAdminInviteCodesModule,
  notifications: SuperAdminNotificationsModule,
  unpaidReports: SuperAdminReportsModule,
  referrals: SuperAdminReferralsModule,
  settings: SuperAdminSettingsModule,
  search: SuperAdminSearch,
  unresolvedShifts: SuperAdminUnresolvedShifts,
}
