import moment from 'moment'
import ImageZoom from 'react-medium-image-zoom'
import type { StafferType } from '../../../../../src/types/staffer'
import StarIcon from '../../../img/StarIcon.svg'
import VerifiedUserIcon from '../../../img/VerifiedUserIcon.svg'
import BlockedIcon from '../../../img/algoliaIcons/BlockedIcon.svg'
import profileAvatar from '../../../img/profileAvatar.png'
import Button from '../../mui/redesignedComponents/Button'
import styles from './StafferPopover.module.css'

type Props = {
  staffer: StafferType
  isSuperAdmin?: boolean
  onGetPaymentDetails?: () => void
  onEditPermissions?: () => void
  onEditNameOrAge: () => void
  onPromotionToggle: () => void
  onCallingList: () => void
  onTestingOn?: () => void
  onAddCancellation?: () => void
  onAddLateCancellation?: () => void
  onRemoveCancellation?: () => void
  onRemoveLateCancellation?: () => void
  stafferTags: string[]
  appVersion: { deviceVersion: string | null; buildNumber: string | null; os: string | null }
  onBlockingStaffer: () => void
  onViewStafferRatings: () => void
}

const StafferPopoverSidebar = ({
  staffer,
  isSuperAdmin,
  onGetPaymentDetails,
  onEditPermissions,
  onEditNameOrAge,
  onPromotionToggle,
  onCallingList,
  onTestingOn,
  onAddCancellation,
  onAddLateCancellation,
  onRemoveCancellation,
  onRemoveLateCancellation,
  stafferTags,
  appVersion,
  onBlockingStaffer,
  onViewStafferRatings,
}: Props) => {
  const stafferRating =
    staffer.ratingsCount > 0 ? (staffer.ratingsTotal / staffer.ratingsCount).toFixed(2) : 'No ratings'
  const totalPayout = Math.round(staffer.totalPayout || 0).toLocaleString('ru-RU')
  const stafferAdminCancellations = staffer.strikes || 0
  return (
    <div className={styles.sidebar}>
      {isSuperAdmin && staffer.accessBlocked && (
        <div className={styles.blockedStatus}>
          <div className={styles.contentRow}>
            <img src={BlockedIcon} alt="blocked status" className={styles.blockedIcon} />
            <span className={styles.textBlocked}>Staffer is blocked</span>
          </div>
          <Button size="small" style={{ padding: 5 }} onClick={onBlockingStaffer}>
            Unblock
          </Button>
        </div>
      )}
      <div className={styles.avatarWrapper}>
        <div style={{ position: 'relative' }}>
          <ImageZoom
            image={{
              className: styles.avatar,
              src: staffer.photoUrl || profileAvatar,
              alt: `Avatar of user ${staffer.nameFirst} ${staffer.nameLast}`,
            }}
            zoomImage={{
              src: String(staffer.photoUrl).startsWith('https://graph.facebook.com/')
                ? `${String(staffer.photoUrl)}?type=large`
                : staffer.photoUrl,
            }}
            shouldHandleZoom={() => Boolean(staffer.photoUrl)}
            defaultStyles={{
              overlay: { background: 'rgba(240, 240, 240, 0.9)' },
            }}
          />
          {(staffer.bankId || staffer.documentsVerified) && (
            <img src={VerifiedUserIcon} alt="verified user" className={styles.verifiedUser} />
          )}
        </div>
        <span className={styles.textBold}>
          {staffer.nameFirst} {staffer.nameLast}
        </span>
        <div className={styles.ratingWrapper}>
          <img src={StarIcon} className={styles.ratingIcon} />
          <span className={styles.greenText}>{stafferRating}</span>
          {staffer.ratingsTotal > 0 && (
            <Button
              size="small"
              variant="outlined"
              style={{ padding: 0, marginLeft: 6 }}
              onClick={onViewStafferRatings}
            >
              See all
            </Button>
          )}
        </div>
      </div>
      <div className={styles.rowWrapper}>
        <span className={styles.greyText}>Shifts done</span>
        <span className={styles.textBold}>{staffer.totalShifts}</span>
      </div>
      <div className={styles.rowWrapper}>
        <span className={styles.greyText}>Cancellations</span>
        <span className={styles.textBold}>
          {staffer.cancellations || 0}
          {stafferAdminCancellations > 0 && ` + ${staffer.strikes}`}
        </span>
      </div>
      <div className={styles.rowWrapper}>
        <span className={styles.greyText}>Late cancellations</span>
        <span className={styles.textBold}>{staffer.heavyStrikes || 0}</span>
      </div>
      {isSuperAdmin && (
        <div className={styles.rowWrapper}>
          <span className={styles.greyText}>Total payouts</span>
          <span className={styles.textBold}>{`${totalPayout} Kr`}</span>
        </div>
      )}
      {isSuperAdmin ? (
        <div className={styles.columnWrapper}>
          <Button variant="outlined" className={styles.button} onClick={onGetPaymentDetails}>
            Get payments details
          </Button>
          <Button variant="outlined" className={styles.button} onClick={onEditPermissions}>
            Edit permissions
          </Button>
          {!!staffer.documentsVerified && (
            <Button variant="outlined" className={styles.button} onClick={onEditNameOrAge}>
              Edit name or age
            </Button>
          )}
          <Button variant="outlined" className={styles.button} onClick={onPromotionToggle}>
            {staffer.isSkilled2 ? 'Demote' : 'Promote'}
          </Button>
          <Button variant="outlined" className={styles.button} onClick={onCallingList}>
            {staffer.callingList ? 'Remove from calling list' : 'Add to calling list'}
          </Button>
          <Button variant="outlined" className={styles.button} onClick={onTestingOn}>
            Testing on
          </Button>
          <Button variant="outlined" className={styles.button} onClick={onAddCancellation}>
            Add a cancellation
          </Button>
          <Button variant="outlined" className={styles.button} onClick={onAddLateCancellation}>
            Add a late cancellation
          </Button>
          <Button
            variant="contained"
            focusVisibleClassName={styles.buttonRed}
            className={styles.buttonRed}
            onClick={onRemoveCancellation}
          >
            Remove cancellation
          </Button>
          <Button
            variant="contained"
            focusVisibleClassName={styles.buttonRed}
            className={styles.buttonRed}
            onClick={onRemoveLateCancellation}
          >
            Remove late cancellation
          </Button>
          <div className={styles.contentRow} style={{ marginTop: 32 }}>
            <span>
              <span className={styles.sidebarInfo}>App version:</span>
              <span className={styles.greyText} style={{ fontSize: 14 }}>
                {appVersion.deviceVersion && appVersion.buildNumber ? (
                  `${appVersion.deviceVersion} (${appVersion.buildNumber}) ${appVersion.os}`
                ) : (
                  <span style={{ fontStyle: 'italic' }}>No data</span>
                )}
              </span>
            </span>
          </div>
          <div className={styles.contentRow} style={{ marginTop: 16 }}>
            <span>
              <span className={styles.sidebarInfo}>Staffer tags:</span>
              <span className={styles.greyText} style={{ fontSize: 14 }}>
                {stafferTags.length > 0 ? (
                  stafferTags.map((stafferTag, index) =>
                    index !== stafferTags.length - 1 ? `${stafferTag}, ` : stafferTag
                  )
                ) : (
                  <span style={{ fontStyle: 'italic' }}>No tags</span>
                )}
              </span>
            </span>
          </div>
        </div>
      ) : (
        <div className={styles.columnWrapper}>
          <div className={styles.contentRow}>
            <span className={styles.textBold} style={{ marginRight: 5 }}>
              Start date:
            </span>
            <span>{moment(staffer.createdAt.toMillis()).format('YYYY.MM.DD HH:mm')}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default StafferPopoverSidebar
