import type firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import type { Firestore, PossibleFirestores } from '../../../../../src/types/firebase'
import type { JobType, StafferDetailJobType } from '../../../../../src/types/jobs'
import moment from '../../../util/moment'
import { getManagerByIdPromise } from './managers.legacy'

type Query = firebase.firestore.Query

export type OrderDirection = 'asc' | 'desc'

export const getJobById = (db: PossibleFirestores, jobId: string) => db.collection('jobs').doc(jobId)

export const getUnseenRequestJobsByManagerId = async (db: Firestore, managerId: string) => {
  const managerData = await getManagerByIdPromise(db, managerId)
  return db
    .collection('jobs')
    .where('businessId', '==', managerData?.businessId)
    .where('unseenRequests', '>', 0)
}

export const getSimilarJobs = (db: Firestore, businessId: string, jobType: string) =>
  db
    .collection('jobs')
    .where('isAvailable', '==', true)
    .where('businessId', '==', businessId)
    .where('jobType', '==', jobType)
    .where('isPublic', '==', true)

export const getAllJobs = (db: Firestore) => db.collection('jobs').orderBy('timeStart', 'desc')

export const getAllJobsAfterManymore = (db: Firestore) =>
  db
    .collection('jobs')
    .where('timeStart', '>', moment('01/08/2021', 'DD/MM/YYYY').toDate())
    .orderBy('timeStart', 'desc')

export const getAllNonFreelanceJobsDeleted = (db: Firestore) =>
  db.collection('jobsDeleted').where('isFreelanceJob', '==', false).orderBy('timeStart', 'desc')

export const getAllFreelanceJobsDeleted = (db: Firestore) =>
  db.collection('jobsDeleted').where('isFreelanceJob', '==', true).orderBy('timeStart', 'desc')

export const getAllNonFreelanceJobs = (db: Firestore) =>
  db.collection('jobs').where('isFreelanceJob', '==', false).orderBy('timeStart', 'desc')

export const getAllFreelanceJobs = (db: Firestore) =>
  db.collection('jobs').where('isFreelanceJob', '==', true).orderBy('timeStart', 'desc')

export const getUpcomingFreelanceJobs = (db: Firestore) =>
  db
    .collection('jobs')
    .where('isFreelanceJob', '==', true)
    .where('nextShiftEnd', '>', moment().toDate()) // use nextShiftEnd to include shifts which already started
    .orderBy('nextShiftEnd', 'asc')

export const getUpcomingFreelanceJobsByBusinessId = (db: Firestore, businessId: string) =>
  db
    .collection('jobs')
    .where('isFreelanceJob', '==', true)
    .where('businessId', '==', businessId)
    .where('nextShiftEnd', '>', moment().toDate()) // use nextShiftEnd to include shifts which already started
    .orderBy('nextShiftEnd', 'asc')

export const getAvailableJobs = (db: Firestore) =>
  db.collection('jobs').where('isAvailable', '==', true).orderBy('timeStart', 'desc')

export const getUnavailableJobs = (db: Firestore, amountToQuery: number) =>
  db.collection('jobs').where('isAvailable', '==', false).orderBy('lastShift', 'desc').limit(amountToQuery)

export const getAllJobsByPosition = (db: Firestore, jobType: string) =>
  db.collection('jobs').where('jobType', '==', jobType).where('salaryHourly', '<', 1000).limit(30)

export const getJobDetailById = (db: PossibleFirestores, jobId: string) => db.collection('jobs').doc(jobId)

export const getDeletedJobDetailById = (db: Firestore, jobId: string) => db.collection('jobsDeleted').doc(jobId)

export const getUnpublishedDetailById = (db: Firestore, jobId: string) => db.collection('unpublished').doc(jobId)

// note: doc keys are uri encoded use encodeURIComponent when accesing,
// it wont work for jobTypes containing slashes otherwise
export const getJobDraftsByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('drafts').doc(businessId).collection('jobDrafts')

// note: doc keys are uri encoded use encodeURIComponent when accesing,
// it wont work for jobTypes containing slashes otherwise
export const getJobDraftsByManagerId = async (db: Firestore, managerId: string) => {
  const managerData = await getManagerByIdPromise(db, managerId)
  return managerData && managerData.businessId ? getJobDraftsByBusinessId(db, managerData.businessId) : []
}

export const getJobDetailByIdPromise = (db: Firestore, jobId: string) =>
  db
    .collection('jobs')
    .doc(jobId)
    .get()
    .then((doc) => (doc.exists ? doc.data() : null))

export const getUpcJobsByStafferAndBusinessIdStartingFrom = (
  db: Firestore,
  stafferId: string,
  businessId: string,
  startingPayDate: Date,
  limit?: number
) => {
  const queryRef = db
    .collection('jobs')
    .where('businessId', '==', businessId)
    .where('staffersConfirmed', 'array-contains', stafferId)
    .where('status', '==', 'upcoming')
    .where('timeEnd', '>', startingPayDate)
    .orderBy('timeEnd', 'asc')

  if (limit) {
    return queryRef.limit(limit)
  }
  return queryRef
}

export const getCompletedJobsByStafferId = (db: Firestore, stafferId: string) =>
  db.collection('jobs').where('nextShift', '==', null).where('staffersConfirmed', 'array-contains', stafferId)

export const fetchOneRecentJobForBusiness = (db: Firestore, stafferId: string, businessId: string) =>
  db
    .collection('jobs')
    .where('businessId', '==', businessId)
    .where('nextShift', '==', null)
    .where('staffersConfirmed', 'array-contains', stafferId)
    .where('timeEnd', '>', moment().subtract(3, 'months').toDate())
    .limit(1)

export const getUpcomingJobsByStafferId = (db: Firestore, stafferId: string) =>
  db.collection('jobs').where('nextShiftEnd', '>', new Date()).where('staffersConfirmed', 'array-contains', stafferId)

export const fetchOneUpcomingJobForBusiness = (db: Firestore, stafferId: string, businessId: string) =>
  db
    .collection('jobs')
    .where('businessId', '==', businessId)
    .where('nextShiftEnd', '>', new Date())
    .where('staffersConfirmed', 'array-contains', stafferId)
    .limit(1)

export const fetchOneAppliedJobForBusiness = (db: Firestore, stafferId: string, businessId: string) =>
  db
    .collection('jobs')
    .where('businessId', '==', businessId)
    .where('nextShiftEnd', '>', new Date())
    .where('staffersPending', 'array-contains', stafferId)
    .limit(1)

export const getCompletedJobsByStafferAndBusinessId = (
  db: Firestore,
  stafferId: string,
  businessId: string,
  limit?: number
) => {
  const queryRef = db
    .collection('jobs')
    .where('businessId', '==', businessId)
    .where('staffersConfirmed', 'array-contains', stafferId)
    .where('nextShift', '==', null)

  if (limit) {
    return queryRef.limit(limit)
  }
  return queryRef
}

export const getJobsByStatus = (db: Firestore, jobStatus: string) =>
  db.collection('jobs').where('status', '==', jobStatus)

export const getAllJobsByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('jobs').where('businessId', '==', businessId)

export const getLastYearJobsByBusinessId = (db: Firestore, businessId: string) =>
  db
    .collection('jobs')
    .where('businessId', '==', businessId)
    .where('createdAt', '>', moment().subtract(12, 'months').toDate())

export const getAllNonFreelanceJobsByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('jobs').where('isFreelanceJob', '==', false).where('businessId', '==', businessId)

export const getAllFreelanceJobsByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('jobs').where('businessId', '==', businessId).where('isFreelanceJob', '==', true)

export const getUpcomingJobsByBusIdPromise = async (db: Firestore, businessId: string) =>
  db
    .collection('jobs')
    .where('businessId', '==', businessId)
    .where('nextShift', '>', moment().toDate())
    .get()
    .then((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
    )

export const getRecentJobsByBusIdPromise = async (db: Firestore, businessId: string) =>
  db
    .collection('jobs')
    .where('businessId', '==', businessId)
    .where('lastShift', '>', moment().subtract(2, 'weeks').toDate())
    .get()
    .then((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
    )

export const getJobsByStafferId = (
  db: Firestore,
  stafferId: string,
  orderBy?: string,
  orderDirection?: OrderDirection
) => {
  const unorderedQuery = db.collection('jobs').where('staffersConfirmed', 'array-contains', stafferId)
  return orderBy ? unorderedQuery.orderBy(orderBy, orderDirection) : unorderedQuery
}

export const getJobsByStafferBusinessId = (db: Firestore, stafferId: string, businessId: string) =>
  db.collection('jobs').where('businessId', '==', businessId).where('staffersConfirmed', 'array-contains', stafferId)

export const getJobsByStatusAndStafferId = (
  db: PossibleFirestores,
  jobStatus: string,
  stafferId: string,
  orderBy?: string,
  orderDirection?: OrderDirection
) => {
  const unorderedQuery = db
    .collection('jobs')
    .where('status', '==', jobStatus)
    .where('staffersConfirmed', 'array-contains', stafferId)
  return orderBy ? unorderedQuery.orderBy(orderBy, orderDirection) : unorderedQuery
}

export const getJobDetailByBusinessId = (
  db: Firestore,
  businessId: string,
  jobStatus: string,
  orderBy?: string,
  orderDirection?: OrderDirection
) => {
  const unorderedQuery = db.collection('jobs').where('businessId', '==', businessId).where('status', '==', jobStatus)
  return orderBy ? unorderedQuery.orderBy(orderBy, orderDirection) : unorderedQuery
}

export const getJobDetailByBusinessIdCustomQuery = (
  db: Firestore,
  businessId: string,
  jobQuery: (query: Query) => Query,
  orderBy?: string,
  orderDirection?: OrderDirection
) => {
  const unorderedQuery = jobQuery(db.collection('jobs').where('businessId', '==', businessId))
  return orderBy ? unorderedQuery.orderBy(orderBy, orderDirection) : unorderedQuery
}

export const getStaffersByJobId = (db: Firestore, jobId: string) =>
  db.collection('jobs').doc(jobId).collection('staffers')

export const getJobStafferDetailById = (db: PossibleFirestores, jobId: string, stafferId: string) =>
  db.collection('jobs').doc(jobId).collection('staffers').doc(stafferId)

// Company admin data crunching
export const getAllJobsByBusinessIdStartingFrom = (db: Firestore, businessId: string, startingPayDate: Date) =>
  db.collection('jobs').where('businessId', '==', businessId).where('timeEnd', '>', startingPayDate)

// Payroll
export const getAllJobsByBusinessIdWithinRelativeTimeFrame = (
  db: Firestore,
  businessId: string,
  startingPayDate: Date,
  endingPayDate: Date
) =>
  db
    .collection('jobs')
    .where('businessId', '==', businessId)
    // Mostly for potential rounding errors/corner cases (4 weeks should be good enough)
    .where('timeStart', '>', moment(startingPayDate).subtract(5, 'weeks').toDate())
    // Mostly for potential rounding errors/corner cases (1 day should be enough)
    .where('timeStart', '<', moment(endingPayDate).add('2', 'days').toDate())

export const getAllJobsStartingFrom = (db: Firestore, startingPayDate: Date) =>
  db.collection('jobs').where('timeEnd', '>', startingPayDate).where('isTestJob', '==', false)

// JOB ADS
export const getJobAdById = (db: Firestore, id: string) => db.collection('jobAds').doc(id)

export const getAllJobAds = (db: Firestore, integrationJobs?: boolean) =>
  integrationJobs
    ? db
        .collection('jobAds')
        .where('integrations', '!=', null)
        .orderBy('integrations', 'desc')
        .orderBy('createdAt', 'desc')
    : db.collection('jobAds').orderBy('createdAt', 'desc')

export const getAllNonIntegrationJobAds = (db: Firestore) =>
  db.collection('jobAds').where('integrations', '==', null).orderBy('createdAt', 'desc')

export const getJobAdsSinceLastMonth = (db: Firestore) =>
  db.collection('jobAds').where('createdAt', '>=', moment().subtract(4, 'months').toDate()).orderBy('createdAt', 'desc')

export const getAvailableJobAds = (db: Firestore) =>
  db.collection('jobAds').where('isAvailable', '==', true).orderBy('timeStart', 'asc')

export const getAllJobAdStaffers = (db: Firestore, jobAdId: string) =>
  db.collection('jobAds').doc(jobAdId).collection('staffers')

export const getJobAdStaffersByStatus =
  (status: 'new' | 'goodfit' | 'notfit' | 'maybefit') => (db: Firestore, jobAdId: string) =>
    db
      .collection('jobAds')
      .doc(jobAdId)
      .collection('staffers')
      .where(status === 'new' ? 'status' : 'fitness', '==', String(status).replace('fit', ''))

export const getJobAdStafferById = (db: Firestore, jobAdId: string, stafferId: string) =>
  db.collection('jobAds').doc(jobAdId).collection('staffers').doc(stafferId)

export const getAllJobAdsByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('jobAds').where('businessId', '==', businessId)

export const getAllEventJobs = (db: Firestore) => db.collection('jobs').where('isFreelanceJob', '==', true)

export const getAppliedJobAdsByStafferBusinessId = (db: Firestore, stafferId: string, businessId: string) =>
  db.collection('jobAds').where('businessId', '==', businessId).where('staffersNew', 'array-contains', stafferId)

export const getContactedJobAdsByStafferBusinessId = (db: Firestore, stafferId: string, businessId: string) =>
  db.collection('jobAds').where('businessId', '==', businessId).where('staffersContacted', 'array-contains', stafferId)

export const getHiredJobAdsByStafferBusinessId = (db: Firestore, stafferId: string, businessId: string) =>
  db.collection('jobAds').where('businessId', '==', businessId).where('staffersHired', 'array-contains', stafferId)

export const getAllUnpublishedJobsByBusinessId = (db: Firestore, businessId: string) =>
  db
    .collection('unpublished')
    .where('businessId', '==', businessId)
    .where('timeStart', '>', new Date())
    .orderBy('timeStart', 'desc')

export const getUnpublishedNonEventJobs = (db: Firestore) =>
  db.collection('unpublished').where('isFreelanceJob', '==', false)

export const getUnpublishedNonEventJobsByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('unpublished').where('businessId', '==', businessId).where('isFreelanceJob', '==', false)

export const getUnpublishedEventJobs = (db: Firestore) =>
  db.collection('unpublished').where('isFreelanceJob', '==', true)

export const getUnpublishedEventJobsByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('unpublished').where('businessId', '==', businessId).where('isFreelanceJob', '==', true)

export const getUnpublishedJobAds = (db: Firestore, integrationJobs?: boolean) =>
  integrationJobs
    ? db
        .collection('unpublished')
        .where('isJobAd', '==', true)
        .where('integrations', '!=', null)
        .orderBy('integrations', 'desc')
        .orderBy('timeStart', 'desc')
    : db.collection('unpublished').where('isJobAd', '==', true).orderBy('timeStart', 'desc')

export const getUnpublishedJobAdsByBusinessId = (db: Firestore, businessId: string) =>
  db
    .collection('unpublished')
    .where('businessId', '==', businessId)
    .where('isJobAd', '==', true)
    .orderBy('timeStart', 'desc')

export const getPoolJobsByBusinessId = (db: Firestore, businessId: string) =>
  db.collection('jobs').where('businessId', '==', businessId).where('nextShift', '>', new Date())

/**
 * @warning This does not exclude legacy jobs (where all were previously public)
 */
export const getPoolPublicJobs = (db: Firestore, businessId: string) =>
  db.collection('jobs').where('businessId', '==', businessId).where('isPublic', '==', true).orderBy('timeStart', 'desc')

export const getAllInternalJobsByBusinessId = (db: Firestore, businessId: string) =>
  db
    .collection('jobs')
    .where('businessId', '==', businessId)
    .where('nextShift', '>', new Date())
    .where('isPublic', '==', false)

export const getTotalHoursWorked = async (db: Firestore, jobId: string): Promise<number> => {
  const jobRef = getJobById(db, jobId)
  const jobDoc = await jobRef.get()
  if (!jobDoc.exists) {
    throw Error(`Job with id: ${jobId} doesn't exist`)
  }
  const job = jobDoc.data() as JobType
  if (!job.shifts || job.shifts.length === 0) {
    return 0
  }
  const jobStaffers = await jobRef.collection('staffers').get()
  if (!jobStaffers || jobStaffers.docs.length === 0) {
    return 0
  }
  // @ts-ignore
  return jobStaffers.docs.reduce(
    // @ts-ignore
    (sum: number, doc) => {
      // looping per staffer per job
      const { shifts } = doc.data() as StafferDetailJobType
      return (
        sum +
        shifts.reduce((hoursWorked: number, { clockIn, clockOut }) => {
          // looping per shift per staffer per job
          if (!clockIn || !clockOut) {
            return hoursWorked
          }
          const clockInMoment = moment(clockIn.timeEdited || clockIn.time)
          const clockOutMoment = moment(clockOut.timeEdited || clockOut.time)
          if (
            !moment.isDate(clockIn.timeEdited || clockIn.time) ||
            !moment.isDate(clockOut.timeEdited || clockOut.time)
          ) {
            return hoursWorked
          }
          const diff = moment.duration(clockOutMoment.diff(clockInMoment)).asHours()
          return hoursWorked + diff
        }, 0)
      )
    },
    0
  )
}

// Used for checking if business has an external jobs, stops after first encounter
export const getSingleExternalJobByBusiness = (db: Firestore, businessId: string) =>
  db.collection('jobs').where('businessId', '==', businessId).where('isPublic', '==', true).limit(1)

// Used for checking if business has a freelance job
export const getSingleEventJobByBusiness = (db: Firestore, businessId: string) =>
  db.collection('jobs').where('businessId', '==', businessId).where('isFreelanceJob', '==', true).limit(1)

// Used for checking if business has a temporary job
export const getSingleTemporaryJobByBusiness = (db: Firestore, businessId: string) =>
  db.collection('jobs').where('businessId', '==', businessId).where('isFreelanceJob', '==', false).limit(1)

// Get all jobs which already started
export const getPendingOrCompletedJobsByBusiness = (db: Firestore, businessId: string) =>
  db.collection('jobs').where('businessId', '==', businessId).where('timeStart', '<', moment().toDate())
