import { InputAdornment, Modal, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { updateDoc } from 'firebase/firestore'
import moment from 'moment'
import { useState } from 'react'
import { toast } from 'react-toastify'
import type { Timestamp } from '../../../../../src/types/firebase'
import type { StafferType } from '../../../../../src/types/staffer'
import { errorToast } from '../../../helpers/toast'
import CalendarIcon from '../../../img/algoliaIcons/CalendarIcon.svg'
import type { Error } from '../../../staffers/api/CrashReporting'
import { getStafferById, getStafferDocumentsById } from '../../../staffers/api/getters/staffer'
import Button from '../../mui/redesignedComponents/Button'
import { ModalHeader } from '../modal/Modal'
import styles from './StafferPopover.module.css'
import type { StafferPopoverModalType } from './StafferPopoverWrapper'

type Props = {
  modalType: StafferPopoverModalType | null
  staffer: StafferType
  birthday?: Timestamp | null
  isOpen: boolean
  onClose: () => void
}

const StafferPopoverApprovalModal = ({ modalType, staffer, birthday, isOpen, onClose }: Props) => {
  const [stafferBirthday, setStafferBirthday] = useState<Date>(
    moment(birthday?.toMillis()).toDate() || moment().toDate()
  )
  const [stafferName, setStafferName] = useState<string>(staffer.nameFirst)
  const [stafferLastName, setStafferLastName] = useState<string>(staffer.nameLast)
  const stafferRef = getStafferById(staffer.userId)
  const stafferDocumentsRef = getStafferDocumentsById(staffer.userId)

  const handleChangeDate = (date: MaterialUiPickersDate) => {
    if (date === null) {
      return
    }
    const formatedDate = date.toDate()
    setStafferBirthday(formatedDate)
  }

  const handleDenyDocuments = async () => {
    try {
      await updateDoc(stafferRef, {
        documentsVerified: false,
      })
      await updateDoc(stafferDocumentsRef, {
        passport: [],
        drivinglicense: [],
        taxcard: [],
      })
    } catch (e) {
      errorToast(`Something went wrong with denying the documents: ${(e as Error).message}`)
    }
  }

  const handleVerifyDocuments = async () => {
    try {
      await updateDoc(stafferRef, {
        documentsVerified: true,
        birthday: stafferBirthday,
        nameFirst: stafferName,
        nameLast: stafferLastName,
      })
      toast.success(staffer.documentsVerified ? 'Name or age changed successfully' : 'Documents have been approved')
      onClose()
    } catch (e) {
      errorToast(`Something went wrong with documents approval: ${(e as Error).message}`)
    }
  }

  return (
    <Modal hideBackdrop open={isOpen} style={{ backgroundColor: 'rgba(0,0,0,0.35)' }} className={styles.modal}>
      {modalType === 'approveDocuments' ? (
        <div className={styles.smallContainer} style={{ minHeight: 500 }}>
          <ModalHeader title="Confirm name and birthday" onClose={onClose} />
          <div className={styles.approvalModalContent}>
            <div className={styles.approvalModalRow}>
              <span className={styles.approvalRowTitle}>First name</span>
              <TextField
                variant="outlined"
                defaultValue={stafferName}
                onChange={(e) => setStafferName(e.target.value)}
              />
            </div>
            <div className={styles.approvalModalRow}>
              <span className={styles.approvalRowTitle}>Lastname</span>
              <TextField
                variant="outlined"
                defaultValue={stafferLastName}
                onChange={(e) => setStafferLastName(e.target.value)}
              />
            </div>
            <div className={styles.approvalModalRow}>
              <span className={styles.approvalRowTitle}>Birthday</span>
              <DatePicker
                value={stafferBirthday}
                format="DD.MM.YYYY"
                disableFuture
                onChange={(date) => handleChangeDate(date)}
                inputVariant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={CalendarIcon} className={styles.largeIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={styles.approvalModalRow}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: 8 }}
                onClick={() => handleVerifyDocuments()}
              >
                Approve
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.smallContainer} style={{ maxWidth: 280 }}>
          <div className={styles.approvalModalContent}>
            <div className={styles.approvalModalRow} style={{ alignItems: 'center', padding: 20 }}>
              <span className={styles.titleBold}>Are you sure?</span>
              <p className={styles.denyInfo}>
                After denying, this can not be changed until staffer uploads new documents
              </p>
            </div>
            <div className={styles.approvalModalRow}>
              <Button variant="contained" className={styles.denyButton} onClick={() => handleDenyDocuments()}>
                Deny
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default StafferPopoverApprovalModal
