import 'firebase/compat/firestore'
import type { BusinessType } from '../../../../../src/types/business'
import type { RegionType } from '../../../../../src/types/common'
import type { PossibleFirestores } from '../../../../../src/types/firebase'
import type { StafferType } from '../../../../../src/types/staffer'

export const getErrorById = (db: PossibleFirestores, errorId: string) => db.collection('errors').doc(errorId)

export const getErrorLogs = (db: PossibleFirestores, limit: number) =>
  db.collection('errors').where('isFixed', '==', false).orderBy('reportedAt', 'desc').limit(limit)

export const getDeviceById = (db: PossibleFirestores, deviceId: string) => db.collection('devices').doc(deviceId)

export const getAllCountries = (db: PossibleFirestores) => db.collection('countries')

export const getAllRegions = (db: PossibleFirestores) => db.collection('regions')

export const getAvailableRegions = (db: PossibleFirestores) =>
  db.collection('regions').where('enabled', '==', true).where('isTestingEnabled', '==', false)

export const getAvailableRegionsPromise = (db: PossibleFirestores) =>
  getAvailableRegions(db)
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => ({
        ...(doc.data() as RegionType),
        id: doc.id,
      }))
    )

export const getTestRegions = (db: PossibleFirestores) =>
  db.collection('regions').where('enabled', '==', true).where('isTestingEnabled', '==', true)

export const getTestRegionsPromise = (db: PossibleFirestores) =>
  getTestRegions(db)
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => ({
        ...(doc.data() as RegionType),
        id: doc.id,
      }))
    )

export const getAllRegionsPromise = (db: PossibleFirestores) =>
  db
    .collection('regions')
    .get()
    .then((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({
        ...(doc.data() as RegionType),
        id: doc.id,
      }))
    )

export const getRegionById = (db: PossibleFirestores, uid: string | null) =>
  db.collection('regions').doc(uid || undefined)

export const getBusinessTypes = (db: PossibleFirestores) => db.collection('enums').doc('businessTypes')

export const getJobTypes = (db: PossibleFirestores) => db.collection('enums').doc('jobTypes')

export const getJobSkills = (db: PossibleFirestores) => db.collection('enums').doc('jobSkills')

export const getTranslatedJobTypes = (db: PossibleFirestores) =>
  db.collection('enums').doc('jobTypes').collection('langs')

export const getExperienceJobTypes = (db: PossibleFirestores) => db.collection('enums').doc('jobTypesExperience')

export const getPositionSkillLevels = (db: PossibleFirestores) => db.collection('enums').doc('positionSkillLevels')

export const getJobAdFees = (db: PossibleFirestores) => db.collection('enums').doc('jobAdFees')

export const getServiceCharge = (db: PossibleFirestores) => db.collection('enums').doc('serviceCharge')

export const getAllInviteCodes = (db: PossibleFirestores) => db.collection('inviteCodes')

export const getUsedInviteCodes = (db: PossibleFirestores) => db.collection('inviteCodes').where('used', '==', true)

export type ICQueryType = {
  property: string
  value: any
}

export const getInviteCodesCustomQuery = (db: PossibleFirestores, options: Array<ICQueryType>) => {
  switch (options.length) {
    case 1:
      return db.collection('inviteCodes').where(options[0].property, '==', options[0].value)
    case 2:
      return db
        .collection('inviteCodes')
        .where(options[0].property, '==', options[0].value)
        .where(options[1].property, '==', options[1].value)
    case 3:
      return db
        .collection('inviteCodes')
        .where(options[0].property, '==', options[0].value)
        .where(options[1].property, '==', options[1].value)
        .where(options[2].property, '==', options[2].value)
    case 4:
      return db
        .collection('inviteCodes')
        .where(options[0].property, '==', options[0].value)
        .where(options[1].property, '==', options[1].value)
        .where(options[2].property, '==', options[2].value)
        .where(options[3].property, '==', options[3].value)
    default:
      return db.collection('inviteCodes')
  }
}

export const getReferralsOf = (db: PossibleFirestores, referredBy: string) =>
  db.collection('referrals').where('referredBy', '==', referredBy)

export const getReferralFromBusiness = (db: PossibleFirestores, businessId: string) =>
  db
    .collection('business')
    .doc(businessId)
    .get()
    .then((businessDoc) => {
      const businessData = businessDoc.data() as BusinessType | undefined
      return businessData && businessData.referringId ? db.collection('referrals').doc(businessData.referringId) : null
    })

export const getReferralFromStaffer = (db: PossibleFirestores, stafferId: string) =>
  db
    .collection('staffers')
    .doc(stafferId)
    .get()
    .then((stafferDoc) => {
      const stafferData = stafferDoc.data() as StafferType | undefined
      return stafferData && stafferData.referringId ? db.collection('referrals').doc(stafferData.referringId) : null
    })

export const getReferralById = (db: PossibleFirestores, referringId: string) =>
  db.collection('referrals').doc(referringId)

export const getNewReferral = (db: PossibleFirestores) => db.collection('referrals').doc()

export const getEmployeePoolsByStafferId = (db: PossibleFirestores, stafferId: string) =>
  db.collection('pools').where('employees', 'array-contains', stafferId)

export const getStafferPoolsByStafferId = (db: PossibleFirestores, stafferId: string) =>
  db.collection('pools').where('staffers', 'array-contains', stafferId)

export const getFreelanceJobFees = (db: PossibleFirestores) => db.collection('enums').doc('freelanceJobFees')
