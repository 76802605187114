import { orderBy, where } from 'firebase/firestore'
import type { BusinessType } from '../../../../../src/types/business'
import type { ManagerInviteType } from '../../../../../src/types/common'
import type { ManagerType } from '../../../../../src/types/manager'
import type { ManagerReferralsType } from '../../../../../src/types/stafferReferrals'
import { getDataFromDocumentRef, getDocumentRef, getQueryRef } from '../wrappers'
import { getBusinessByIdPromise } from './business'

export const getManagerById = (userId: string) => getDocumentRef<ManagerType>('managers', userId)

export const getManagerByIdPromise = (userId: string) => getDataFromDocumentRef(getManagerById(userId))

export const getManagersByPhone = (phone: string) => getQueryRef<ManagerType>('managers', where('phone', '==', phone))

export const getAllBusinessManagers = (businessId: string) =>
  getQueryRef<ManagerType>('managers', where('businessId', '==', businessId || ''))

export const getGroupManagers = (groupId: string) =>
  getQueryRef<ManagerType>('managers', where('groupId', '==', groupId))

export const getGroupBusinesses = (groupId: string) =>
  getQueryRef<BusinessType>('business', where('groupId', '==', groupId))

export const getInvitedGroupManagers = (groupId: string) =>
  getQueryRef<ManagerInviteType>('managersInvites', where('groupId', '==', groupId), where('used', '==', false))

// Most of the time we care more about the business data than the manager
// This function returns both at the same time in an array
export const getManagerAndBusinessByManagerId = async (userId: string) => {
  const managerData = await getManagerByIdPromise(userId)
  return [
    managerData,
    managerData && managerData.businessId ? await getBusinessByIdPromise(managerData.businessId) : null,
  ]
}

export const getManagersInvites = () => getQueryRef<ManagerInviteType>('managersInvites', where('used', '==', false))

export const getManagersInviteByBusinessId = (businessId: string) =>
  getQueryRef<ManagerInviteType>('managersInvites', where('businessId', '==', businessId), where('used', '==', false))

export const getManagerInviteById = async (uid: string) => {
  const managerData = await getManagerByIdPromise(uid)
  if (!managerData) {
    return null
  }
  return getQueryRef<ManagerInviteType>('managersInvites', where('email', '==', managerData.email))
}

export const getManagerInviteByInviteId = (inviteId: string) =>
  getDocumentRef<ManagerInviteType>('managersInvites', inviteId)

export const getManagersReferralsOrdered = () =>
  getQueryRef<ManagerReferralsType>('managersReferrals', orderBy('due', 'desc'))

export const getManagerReferrals = (uid: string) => getDocumentRef<ManagerReferralsType>('managersReferrals', uid)

export const getManagerReferralsPromise = (uid: string) => getDataFromDocumentRef(getManagerReferrals(uid))

export const getManagersByBusinessId = (businessId: string) =>
  getQueryRef<ManagerType>('managers', where('businessId', '==', businessId))

export const getGroupManagersByBusinessId = (businessId: string) =>
  getQueryRef<ManagerType>('managers', where('groupBusinesses', 'array-contains', businessId))
