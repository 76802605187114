import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import type { Integration } from '../../../../src/types/integrations'
import { integrationApiUrl } from '../../config'
import { getJWT } from '../../integrations/auth'

// IMPORTANT:
// for the api of specific integration (eg. planday) which requires an authentication
// you should use integrationCtx provided by withIntegrations wrapper,
// instead of using this post/get methods directly

const request = (method: 'POST' | 'GET' | 'PUT') => async (endpoint: string, payload?: Record<string, any>) => {
  const user = firebase.auth().currentUser
  if (!user) {
    throw Error('No firebase user logged')
  }
  const token = await getJWT(user)
  const isCompleteUrl = endpoint.startsWith('http')
  const path = endpoint.startsWith('/') ? endpoint : `/${endpoint}`
  const url = isCompleteUrl ? endpoint : `${integrationApiUrl}${path}`

  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: payload && JSON.stringify(payload),
    })
    let data
    try {
      data = {
        status: response.status,
        ...(await response.json()),
      }
    } catch (e) {
      data = {
        status: response.status,
        detail: 'Failed to parse the response',
      }
    }
    if (response.status >= 200 && response.status < 300) {
      // eslint-disable-next-line no-console
      console.log(`integration ${method} ${path} (${response.status}) :>> `, data)
      return data
    }
    if (response.status >= 400 && response.status < 500) {
      // eslint-disable-next-line no-console
      console.warn(`integration ${method} ${path} (${response.status}) :>> `, data)
      return data
    }
    if (response.status >= 500) {
      return {
        ...data,
        status: response.status,
        detail: `Server error ${response.status}`,
      }
    }
    return undefined
  } catch (error) {
    console.error(`error ${method} ${path} :>> `, error)
    throw error
  }
}

// Authorize business against the integration api using OAuth2.0
const auth = async (integration: Integration, state: string, override?: () => void): Promise<boolean> => {
  if (!integration.loginUrl) {
    throw new Error('Missing auth url')
  }

  if (override) {
    override()
    return true
  }

  const authDonePage = `${global.location.origin}/integration-auth-done/${integration.name}`
  const authUrl = `${integration.loginUrl}&state=${state}_${authDonePage}/`

  global.open(authUrl, `auth_${integration.name}`, 'norefferer,width=500,height=680') // this new window should redirect to authDonePage after authroization from planday is done

  // The authDonePage comunicates with this origin window by writing to localStorage
  // That way we can update state of the origin window
  const STORAGE_AUTH_DONE_KEY = `authDone-${integration.name}`
  return new Promise((resolve) => {
    const onStorageChange = (event: StorageEvent) => {
      const { key, newValue } = event
      if (key === STORAGE_AUTH_DONE_KEY && newValue) {
        global.removeEventListener('storage', onStorageChange)
        global.localStorage.removeItem(key)
        resolve(true)
      }
      // TODO handle auth fail state?
    }
    global.addEventListener('storage', onStorageChange)
  })
}

// Finish the process of the authorization
// must be called inside the authDonePage opened from the auth methoda above
const authDone = (integrationId: string) => {
  const STORAGE_AUTH_DONE_KEY = `authDone-${integrationId}`
  global.localStorage[STORAGE_AUTH_DONE_KEY] = true
  return () => {
    // return cleanup function - to clean the localStorege when testing
    global.localStorage.removeItem(STORAGE_AUTH_DONE_KEY)
  }
}

const get = request('GET')
const post = request('POST')
const put = request('PUT')

const deauth = async (integrationId: string): Promise<boolean> => {
  const { status } = await post(`/integrations/${integrationId}/logout`)
  return status === 'OK'
}

const integrationsAPI = {
  get,
  post,
  put,
  auth,
  authDone,
  deauth,
}

export default integrationsAPI
