// contractor (freelance) work agreement for Norway region (both English and Norwegian language)
import { Typography } from '@material-ui/core'
import { Fragment } from 'react'
import style from '../modals/modals.module.css'

type Values = {
  today: string
  description: string
  jobType: string
  registeredName?: string
  businessName: string
  orgNumber: string
  businessAddress: string
  stafferNameFull: string
  identityNumber: string
  endDate: string
  jobDateStart: string
  jobDateEnd: string
  jobHourlyWage: string
  jobRequirementsTranslated: Record<'en' | 'no', Array<string>>
  jobTypeTranslated: Record<'en' | 'no', string>
  salaryHourly: number
}

const English = ({
  today,
  description,
  jobType,
  jobRequirementsTranslated,
  registeredName,
  businessName,
  orgNumber,
  businessAddress,
  stafferNameFull,
  identityNumber,
  endDate,
  jobDateStart,
  jobDateEnd,
  jobHourlyWage,
}: Values) => (
  <Fragment>
    <Typography component="h6" variant="h6" className={style.agreementHeader}>
      Contractor agreement
    </Typography>
    <div className={style.agreementWrapper}>
      <p>
        This contractor agreement (the agreement) is signed on {today} between:
        <br />
        <br />
        the principal which is apparent in <strong>appendix 1</strong> and
        <br />
        <br />
        the contractor which is apparent in <strong>appendix 2</strong>
        ,
        <br />
        <br />
        according to, at all time, applicable terms, apparent in
        <strong> appendix 3</strong>
        .
        <br />
        <br />
        {description && (
          <p>
            <strong>Introductory comment</strong>
            <br />
            {description}
            <br />
            <br />
          </p>
        )}
        <strong>The scope of the agreement</strong>
        <br />
        The contractor has by agreeing to this agreement, agreed to complete the assignment of {jobType} for the
        principal.
        <br />
        <br />
        {jobRequirementsTranslated.en && jobRequirementsTranslated.en.length > 0 && (
          <Fragment>
            The assignment involves that the contractor is going to perform the following tasks for the principal:
            <br />
            {jobRequirementsTranslated.en.map((text: string) => `\t - ${text} \n`)}
            <br />
          </Fragment>
        )}
        The contractor commits to deliver the services in this agreement In accordance with best practice for similar
        services. The contractor confirms to have the necessary qualifications and relevant experience to deliver the
        services.
        <br />
        <br />
        <strong>The agreement’s length and termination</strong>
        <br />
        This agreement is valid until {endDate}
        , alternatively until the services are delivered and the correct compensation is received. If the contractor
        wishes to cancel the agreement at an earlier time, this has to be done at least 24 hours by written notice. If
        one of the parties breaches their duties of the contract, the other party can at once terminate the agreement.
        <br />
        <br />
        <strong>Price/invoicing</strong>
        <br />
        Assignments according to this agreement are calculated after time used and hourly wage per hour / excluding mva,
        based on the specified hours for each day of the contract. The compensation is not to be regulated within the
        agreement period, and includes all costs of the contractor unless otherwise is specified.
        <br />
        <br />
        <strong>Insurance</strong>
        <br />
        The Contractor is covered by ManyMore AS&apos; Occupational Injury and Disease insurance policy provided by LY
        Forsikring. This insurance (yrkeskadeforsikring/yrkesykdom) covers all incidents that might occur during the
        assignment. The contractor will be deducted NOK 1,6/h to cover the insurance cost. The contractor is not a part
        of the Principal&apos;s insurance plan.
        <br />
        <br />
        <strong>Bi-costs</strong>
        <br />
        <strong>a)</strong>
        {'\t'}
        Documented expenses and travel/living expenses related to the assignment are to be refunded to the contractor.
        <br />
        <strong>b)</strong>
        {'\t'}
        The contractor is responsible to invoice their hours worked. Invoicing should take place when the assignment is
        completed with a maturity date of 14 days from the invoice date.
        <br />
        <br />
        <strong>Confidentiality</strong>
        <br />
        Both parties shall obtain mutual confidentiality about the information they obtain about the other party in
        connection with the completion of this agreement. The confidentiality clause does not apply to public known
        information. The confidentiality clause applies also after the assignment is complete.
        <br />
        <br />
        <strong>Force Majeure</strong>
        <br />
        The parties are not responsible for delays or un-completion of the assignment due to circumstances that are
        outside of the parties’ control.
        <br />
        <br />
        <strong>Passivity</strong>
        <br />
        No demands associated with the agreement can be put forward later than 2 years after the reason has been put
        forward.
        <br />
        <br />
        <strong>Governing law and venue</strong>
        <br />
        By disputes that occur in relation the agreement, Norwegian law applies, and the dispute is to be resolved by
        the Norwegian courts.
        <br />
        <br />
        This agreement is formed and signed in 2 copies, on to each party.
        <br />
        <br />
        <strong>Appendix 1</strong>
        <br />
        <br />
        &lt;The employer&gt; <strong>{registeredName || businessName}</strong>
        <br />
        &lt;Org. no.&gt; <strong>{orgNumber}</strong>
        <br />
        &lt;Address&gt; <strong>{businessAddress}</strong>
        <br />
        <br />
        <strong>Appendix 2</strong>
        <br />
        <br />
        &lt;The employee&gt; <strong>{stafferNameFull}</strong>
        <br />
        &lt;Person no.&gt; <strong>{identityNumber}</strong>
        <br />
        <br />
        <strong>Appendix 3</strong>
        <br />
        <br />
        Job information:
        <br />
        &lt;Location&gt; <strong>{businessAddress}</strong>
        <br />
        &lt;Start time&gt; <strong>{jobDateStart}</strong>
        <br />
        &lt;End time&gt; <strong>{jobDateEnd}</strong>
        <br />
        &lt;Wage&gt; <strong>{jobHourlyWage}</strong>
        <br />
        &lt;Position&gt; <strong>{jobType}</strong>
      </p>
    </div>
  </Fragment>
)

const Norwegian = ({
  today,
  description,
  jobType,
  jobRequirementsTranslated,
  jobTypeTranslated,
  registeredName,
  businessName,
  orgNumber,
  businessAddress,
  stafferNameFull,
  identityNumber,
  endDate,
  jobDateStart,
  jobDateEnd,
  jobHourlyWage,
}: Values) => (
  <Fragment>
    <Typography component="h6" variant="h6" className={style.agreementHeader}>
      OPPDRAGSAVTALE
    </Typography>
    <div className={style.agreementWrapper}>
      <p>
        Denne oppdragsavtalen (Avtalen) er inngått den {today} mellom
        <br />
        <br />
        Oppdragsgiver som fremgår av <strong>vedlegg 1</strong> og
        <br />
        <br />
        Oppdragstaker som fremgår av <strong>vedlegg 2</strong>
        ,
        <br />
        <br />i henhold til enhver tid gjeldende vilkår som fremgår av
        <strong> vedlegg 3</strong>
        .
        <br />
        <br />
        {description && (
          <p>
            <strong>Innledende kommentar</strong>
            <br />
            {description}
            <br />
            <br />
          </p>
        )}
        <strong>Avtalens omfang</strong>
        <br />
        Oppdragstaker har ved inngåelse av Avtalen akseptert å utføre oppdrag om {jobTypeTranslated.no} for
        Oppdragsgiver.
        <br />
        <br />
        {jobRequirementsTranslated.no && jobRequirementsTranslated.no.length > 0 && (
          <Fragment>
            Oppdragetinnebærer at Oppdragstaker skalutførefølgende oppgaverfor Oppdragsgiver:
            <br />
            {jobRequirementsTranslated.no.map((text) => `\t - ${text} \n`)}
            <br />
          </Fragment>
        )}
        Oppdragstaker forplikter seg til å levere tjenestene under denne avtale i samsvar med beste praksis for
        tilsvarende tjenester. Oppdragstaker bekrefter å innehade nødvendige kvalifikasjoner og relevant erfaring for
        levering av tjenestene.
        <br />
        <br />
        <strong>Avtalens varighet og opphør</strong>
        <br />
        Denne avtalen gjelder frem til {endDate}
        , alternativt til tjenestene er levert og rett oppgjør er mottatt. Dersom noen av partene ønsker å si opp
        avtalen på et tidligere tidspunkt, skal dette skje med 14 dagers skriftlig varsel. Oppdragstaker har i tilfelle
        avbestilling rett til ethvert opptjent vederlag eller pådratt utlegg i forbindelse med avtalen, frem til
        oppsigelsestidens utløp. Eventuelt: Dersom en av partene vesentlig misligholder sine plikter etter Avtalen,
        kanden andre parten umiddelbart si oppAvtalen med skriftlig varsel.
        <br />
        <br />
        <strong>Pris/fakturering</strong>
        <br />
        Oppdragi henhold til denne avtalen avregnes etter medgått tid og beløp ikr/t ekskl. mva, basert på spesifiserte
        timelister for hver dag det er arbeidet. Honorarsatsen er ikke gjenstand for regulering i den planlagte
        avtaleperioden, og inkluderer alle-kostnader for Oppdragstaker med mindre annet er særskilt spesifisert
        nedenfor.
        <br />
        <br />
        <strong>Forskiring</strong>
        <br />
        Oppdragstakerer dekket av ManyMore AS sin yrkeskadeforsikring/yrkesykdomlevert av LY Forsikring. Oppdragstaker
        vil bli trukket NOK 1,6/t forå dekke denne forsikringen. Oppdragstaker er ikke dekket av oppdragsgivers
        yrkeskadeforskring.
        <br />
        <br />
        <strong>Bi-omkostninger</strong>
        <br />
        <strong>a)</strong>
        {'\t'}
        Dokumenterte utlegg og reise-/oppholdskostnader relatert til oppdraget refunderes uten påslag dersom dette er
        avtalt mellom partene.
        <br />
        <strong>b)</strong>
        {'\t'}
        Bruk og parkering av egen bil godtgjøres etter Statens satser og registrert parkeringsavgift.
        <br />
        <br />
        <strong>Skattemessige-og avgiftsmessige forhold</strong>
        <br />
        Oppdragstaker er selv ansvarlig for å oppfylle lovmessige plikter ved utførelse av Oppdraget, herunder betale
        lovpålagte skatter og avgifter.
        <br />
        <br />
        <strong>Forsikring</strong>
        <br />
        Oppdragstaker er selv ansvarlig for å tegne nødvendige forsikringer – herunder forsikringer knyttet til
        utføringen av Oppdraget. Oppdragstaker tar således ikke del av noen forsikringsordninger hos Oppdragsgiver.
        <br />
        <br />
        <strong>Konfidensialitet</strong>
        <br />
        Partene har gjensidig konfidensialitetsplikt med hensyn til all informasjon de erverver om den annen part i
        forbindelse med inngåelse og utførelse av denne avtale. Konfidensialitetsplikten gjelder ikke forhold som er
        alminnelig kjent. Konfidensialitetsplikten gjelder også etter utløpet av avtalen.
        <br />
        <br />
        <strong>Force Majeure</strong>
        <br />
        Partene er ikkeansvarligefor forsinkelser eller ikke-utførtehandlinger som skyldes forhold utenfor partneres
        kontroll.
        <br />
        <br />
        <strong>Passivitet</strong>
        <br />
        Ingen krav knyttet til Avtalen kan fremsettes mer enn to år etter at grunnen for å fremsette kravet oppstod.
        <br />
        <br />
        <strong>Lovvalg og verneting</strong>
        <br />
        Ved eventuelle tvister som oppstår i relasjon til Avtalen, gjelder norsk rett, og tvisten skal avgjøres ved
        norskedomstoler.
        <br />
        <br />
        Denne avtalen er utformet og signert i 2 eksemplarer; ett til hver av partene.
        <br />
        <br />
        <strong>Appendix 1</strong>
        <br />
        <br />
        &lt;Arbeidsgiver&gt; <strong>{registeredName || businessName}</strong>
        <br />
        &lt;Org. nr.&gt; <strong>{orgNumber}</strong>
        <br />
        &lt;Adresse&gt; <strong>{businessAddress}</strong>
        <br />
        <br />
        <strong>Appendix 2</strong>
        <br />
        <br />
        &lt;Arbeidstaker&gt; <strong>{stafferNameFull}</strong>
        <br />
        &lt;Presonnr.&gt; <strong>{identityNumber}</strong>
        <br />
        <br />
        <strong>Appendix 3</strong>
        <br />
        <br />
        Jobb informasjon:
        <br />
        &lt;Jobbsted&gt; <strong>{businessAddress}</strong>
        <br />
        &lt;Starttid&gt; <strong>{jobDateStart}</strong>
        <br />
        &lt;Sluttid&gt; <strong>{jobDateEnd}</strong>
        <br />
        &lt;Lønn&gt; <strong>{jobHourlyWage}</strong>
        <br />
        &lt;Tittel&gt; <strong>{jobType}</strong>
      </p>
    </div>
  </Fragment>
)

export default {
  English,
  Norwegian,
}
