import type { ButtonProps, TooltipProps } from '@material-ui/core'
import { Box, Button, Tooltip } from '@material-ui/core'
import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
  reasons: { [reason: string]: string } // i.e. { required: 'Field X cannot be empty' }
  reason: string
  disabled: boolean
  placement?: TooltipProps['placement']
  buttonprops?: ButtonProps
} & ButtonProps

const ButtonWithDisabledTooltip = (props: Props) => {
  const { children, placement, reasons, reason, disabled, ...buttonprops } = props

  // fullWidth prop does not work if encapsulated by a Tooltip
  // needs to be faked like this
  const ScaledFullWidth = ({ children: content }: { children: ReactNode }) => {
    // @ts-ignore
    if (buttonprops.fullWidth) {
      return <Box width="100%">{content}</Box>
    }
    return <Box>{content}</Box>
  }

  if (disabled) {
    return (
      <ScaledFullWidth>
        <Tooltip title={reasons[reason]} placement={placement || 'top'}>
          <div>
            <Button disabled {...buttonprops}>
              {children}
            </Button>
          </div>
        </Tooltip>
      </ScaledFullWidth>
    )
  }

  return <Button {...buttonprops}>{children}</Button>
}

export default ButtonWithDisabledTooltip
