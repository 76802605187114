import { IconButton, List, ListItem, Tooltip } from '@material-ui/core'
import { Dashboard } from '@material-ui/icons'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { PureComponent } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { Link, withRouter } from 'react-router-dom'
import type { ManagerTypeWithId } from '../../../../../src/types/manager'

import { generateUniqueColors } from '../../../helpers/styling'
import { getManagerById } from '../../../staffers/api/getters/managers.legacy'

import { PATHS, PATHS_NAMES } from '../../../constants/routes'
import { errorToast } from '../../../helpers/toast'
import BusinessIcon from './BusinessIcon'
import styles from './GroupSidebar.module.css'

type Props = RouteComponentProps & {
  manager: ManagerTypeWithId
  getBadgeCount: (badgeCount: string) => number
  sortedBusinesses: string[]
}

type State = {
  switchingTo?: string
}

class GroupBusinessSelector extends PureComponent<Props, State> {
  state = {
    switchingTo: undefined,
  }

  switchToBusiness = async (businessId: string) => {
    const { history, manager } = this.props
    const database = firebase.firestore()
    const managerRef = getManagerById(database, manager.id)

    this.setState({
      switchingTo: businessId,
    })

    try {
      await history.replace({ pathname: '/' })
      await managerRef.update({
        businessId,
      })
      // Reset notifications count so app is consistent for this manager
      await firebase.firestore().collection('users').doc(manager.id).update('notifications.unseen', 0)
    } catch (e) {
      errorToast('Cant switch')
      console.error(e)
    } finally {
      this.setState({
        switchingTo: undefined,
      })
    }
  }

  render() {
    const { history, manager, getBadgeCount, sortedBusinesses } = this.props
    const { switchingTo } = this.state
    const { groupBusinesses = [] } = manager
    const colors = generateUniqueColors(groupBusinesses, 'web-friendly-global', {
      saturation: 80,
      lightness: 70,
    })
    const isDashboardSelected = history.location.pathname.includes('/group')

    return (
      <List className={styles.sidebar}>
        <ListItem className={styles.listItem} key={PATHS_NAMES[PATHS.groupDashboard]}>
          <Tooltip title={PATHS_NAMES[PATHS.groupDashboard]} placement="right">
            <Link to={PATHS.groupDashboard} className={styles.settingsLink}>
              <IconButton>
                <Dashboard className={isDashboardSelected ? styles.dashboardIconActive : styles.dashboardIcon} />
              </IconButton>
            </Link>
          </Tooltip>
        </ListItem>
        {sortedBusinesses.map((businessId) => {
          const badgeCount = getBadgeCount(businessId)
          return (
            <ListItem className={styles.listItem} key={businessId}>
              <BusinessIcon
                badge={badgeCount}
                businessId={businessId}
                color={colors[businessId]}
                isDashboardSelected={isDashboardSelected}
                manager={manager}
                onClick={this.switchToBusiness}
                switchingTo={switchingTo}
              />
            </ListItem>
          )
        })}
      </List>
    )
  }
}

export default withRouter(GroupBusinessSelector)
