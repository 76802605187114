import {
  Avatar,
  Box,
  Chip,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  Cancel as CancelIcon,
  Email as EmailIcon,
  ExpandMore as ExpandMoreIcon,
  Favorite as FavoriteIcon,
  Link as LinkIcon,
  Person as PersonIcon,
} from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as R from 'ramda'
import type { ChangeEvent } from 'react'
import { Fragment } from 'react'
// @ts-ignore package has no exported types
import AsyncSelect from 'react-select/lib/Async'
// @ts-ignore package has no exported types
import { components } from 'react-select'
import type { BusinessType } from '../../../../../src/types/business'
import type { ImportStafferType, ParseResults } from '../../../../../src/types/parsing'
import type { PoolType } from '../../../../../src/types/pools'
import type { StafferType } from '../../../../../src/types/staffer'
import { TooltipDelays } from '../../../constants/tooltips'
import { searchText } from '../../../staffers/api/firestore/searchText.legacy'
import moment from '../../../util/moment'
import Importer from '../../uploaders/ImportCsv'
import oldStyles from '../modals.module.css'
import type { OptionFieldType, OptionType } from './CreateEditPool'

type Props = {
  handleImportResults: (data: ParseResults) => any
  imported: {
    existing: Array<StafferType>
    invites: Array<ImportStafferType>
  }
  isProcessing: boolean
  step: number
  steps: Array<string>
  business: BusinessType
  poolName: string
  removeInvite: (idToRemove: string | number, from: 'invites' | 'existing', isNewImport?: boolean) => Promise<void>
  setPoolName: (event: ChangeEvent<HTMLInputElement>) => void
  pool?: PoolType
  sendSms: boolean
  toggleSendSms: () => void
  connectedBusinesses: OptionType[]
  employees: OptionType[]
  staffers: OptionType[]
  selectOption: (option: OptionType, fieldName: OptionFieldType) => any
  removeOption: (removeId: string, fieldName: OptionFieldType) => any
}

const QUERY_MIN_LENGTH = 2 // Threshold at which we start fetching results for query

const CreateEditPoolSteps = (props: Props) => {
  const {
    handleImportResults,
    imported,
    isProcessing,
    removeOption,
    selectOption,
    pool,
    business,
    poolName,
    removeInvite,
    setPoolName,
    steps,
    sendSms,
    toggleSendSms,
  } = props
  const { businessId, businessName } = business
  const isSearchable = (input: string) => input && input.length >= QUERY_MIN_LENGTH

  // Returns selectable options for search query
  const getSelectableBusinesses = async (input: string) => {
    if (!isSearchable(input)) {
      return undefined
    }

    const queryWords = input.split(' ')
    const potentialQueryWords = [input]
    queryWords.forEach((word) => {
      // Include search term written
      potentialQueryWords.push(word)
      // Include lowercase variant
      potentialQueryWords.push(word.toLocaleLowerCase())
      // Include Uppercase
      potentialQueryWords.push(word.charAt(0).toLocaleUpperCase() + word.substring(1))
    })

    const db = firebase.firestore()
    const allBusinesses = db.collection('business')
    const queries = [
      ...potentialQueryWords.map((word) => searchText(allBusinesses, 'businessName', word)),
      ...potentialQueryWords.map((word) => searchText(allBusinesses, 'businessId', word)),
    ]

    // Queries for businesses beginning with input
    const queryResults: OptionType[] = []
    await Promise.all(
      queries.map(async (query) => {
        try {
          const queryDocs = await query.get()
          return queryDocs.docs.forEach((doc) => {
            const data: BusinessType = doc.data() as BusinessType
            if (data) {
              queryResults.push({
                label: data.businessName,
                id: doc.id,
                photoUrl: data.photoUrl,
              })
            }
          })
        } catch (error) {
          console.warn(error)
          return undefined
        }
      })
    )

    const options: Array<OptionType & { id: string }> = queryResults || []
    const { connectedBusinesses } = props
    // sort alphabetically & filter out already connected businesses (+hide own business)
    const selectableBusinesses = options
      .filter(({ id }) => ![...connectedBusinesses.map((connected) => connected.id)].includes(id) && id !== businessId)
      .sort((itemA, itemB) => itemA.label.localeCompare(itemB.label))
    // @ts-ignore
    return R.uniqBy(({ id }) => id)(selectableBusinesses)
  }

  const getSelectableStaffers = async (input: string) => {
    if (!isSearchable(input)) {
      return undefined
    }

    const db = firebase.firestore()
    const allStaffers = db.collection('staffers')
    const queryWords = input.split(' ')
    const potentialQueryWords = [input]
    queryWords.forEach((word) => {
      // Include search term written
      potentialQueryWords.push(word)
      // Include lowercase variant
      potentialQueryWords.push(word.toLocaleLowerCase())
      // Include Uppercase
      potentialQueryWords.push(word.charAt(0).toLocaleUpperCase() + word.substring(1))
    })

    const queries = [
      ...potentialQueryWords.map((word) => searchText(allStaffers, 'nameFirst', word)),
      ...potentialQueryWords.map((word) => searchText(allStaffers, 'nameLast', word)),
      ...potentialQueryWords.map((word) => searchText(allStaffers, 'userId', word)),
    ]

    const queryResults: OptionType[] = []
    await Promise.all(
      queries.map(async (query) => {
        try {
          const queryDocs = await query.get()
          return queryDocs.docs.forEach((doc) => {
            const data: StafferType = doc.data() as StafferType
            if (data) {
              queryResults.push({
                label: `${data.nameFirst} ${data.nameLast}`,
                id: data.userId,
                photoUrl: data.photoUrl,
              })
            }
          })
        } catch (error) {
          console.warn(error)
          return undefined
        }
      })
    )

    const options: OptionType[] = queryResults || []
    const { employees, staffers } = props
    const selectableStaffers = options
      .filter(
        ({ id: optionId }) => ![...employees.map(({ id }) => id), ...staffers.map(({ id }) => id)].includes(optionId)
      )
      .sort((itemA, itemB) => itemA.label.localeCompare(itemB.label))
    // @ts-ignore§
    return R.uniqBy(({ id }) => id)(selectableStaffers)
  }

  const renderOption = (optionProps: Record<string, unknown>) => {
    const { label, id } = optionProps.data as Record<string, string>
    const { Option } = components
    return (
      <Option {...optionProps}>
        <span>{label} &nbsp;</span>
        <span className={oldStyles.info}>{id}</span>
      </Option>
    )
  }

  const renderStep = (step: number) => {
    // reusable async select, prefer writing it once than 3 times
    const renderAsyncSelect = (fieldName: OptionFieldType) => {
      const loadOptions = fieldName === 'connectedBusinesses' ? getSelectableBusinesses : getSelectableStaffers
      return (
        <AsyncSelect
          components={{ Option: renderOption }}
          placeholder={fieldName === 'connectedBusinesses' ? 'Search companies' : 'Search staffers'}
          noOptionsMessage={({ inputValue }: Record<string, string>) =>
            isSearchable(inputValue)
              ? 'No results found'
              : `Enter the name or id of the ${
                  fieldName === 'connectedBusinesses' ? 'company' : 'staffer'
                }. Special characters must match`
          }
          styles={{
            option: (provided: Record<string, string>) => ({
              ...provided,
              color: 'black',
              display: 'flex',
              'flex-direction': 'row',
              'align-items': 'center',
              width: '100%',
            }),
          }}
          value={null} // handled internally onChange
          loadOptions={loadOptions}
          onChange={(option: OptionType) => selectOption(option, fieldName)}
          isSearchable
        />
      )
    }
    const renderSelectedOption = (option: OptionType, fieldName: OptionFieldType) => {
      const { id, label, photoUrl } = option
      return (
        <Grid item xs={6} key={id}>
          <ListItem>
            <ListItemAvatar>
              <Avatar alt={label} src={photoUrl} />
            </ListItemAvatar>
            <ListItemText primary={label} secondary={`id: ${id}`} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="remove" onClick={() => removeOption(id, fieldName)}>
                <CancelIcon color="error" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Grid>
      )
    }
    // have to use string literals, since there is optional import employee step
    switch (steps[step]) {
      // pool name
      case 'Enter Pool Name': {
        return (
          <Fragment>
            <TextField
              type="text"
              value={poolName}
              onChange={setPoolName}
              variant="outlined"
              label="Pool name:"
              placeholder="My custom pool name"
              error={!poolName}
              required
              fullWidth
            />
          </Fragment>
        )
      }
      // connectedBusinesses
      case 'Add connected businesses': {
        const { connectedBusinesses } = props
        return (
          <Fragment>
            {renderAsyncSelect('connectedBusinesses')}
            <Box py={2}>
              <List>
                <Grid container spacing={3}>
                  {connectedBusinesses.map((option) => renderSelectedOption(option, 'connectedBusinesses'))}
                </Grid>
              </List>
            </Box>
          </Fragment>
        )
      }
      // employees
      case 'Add employees': {
        const { employees } = props
        return (
          <Fragment>
            {renderAsyncSelect('employees')}
            <Box py={2}>
              <List>
                <Grid container spacing={3}>
                  {employees.map((option) => renderSelectedOption(option, 'employees'))}
                </Grid>
              </List>
            </Box>
            <Box py={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Typography color="textSecondary">Or import employees via csv</Typography>
              <Box mt={2} width="100%">
                <Importer handleResults={handleImportResults} isProcessing={isProcessing} />
              </Box>
            </Box>
            <Box mt={2}>
              <FormControlLabel
                label="Send sms to newly invited staffers"
                control={<Switch checked={sendSms} onChange={toggleSendSms} name="sendSms" color="primary" />}
              />
              {!sendSms && (
                <Alert severity="info">
                  Activate toggle to send out SMS. By not activating the toggle, the companies can send out an SMS from
                  their Staffers list.
                </Alert>
              )}
            </Box>
          </Fragment>
        )
      }
      case 'Verify imported employees': {
        return (
          <Grid container spacing={3}>
            {imported.existing.length !== 0 && (
              <Grid item xs>
                <Typography variant="h6">Existing staffers: </Typography>
                <List>
                  {imported.existing.map((staffer) => (
                    <ListItem key={staffer.userId || staffer.phone}>
                      <ListItemAvatar>
                        <Avatar alt={staffer.nameFirst} src={staffer.photoUrl} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${staffer.nameFirst} ${staffer.nameLast}`}
                        secondary={`Phone: ${staffer.phone} \n Email: ${staffer.email}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="remove"
                          onClick={() => removeInvite(staffer.userId, 'existing')}
                        >
                          <CancelIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
            {imported.invites.length !== 0 && (
              <Grid item xs>
                <Typography variant="h6">New invites: </Typography>
                <List>
                  {imported.invites.map(({ email, id, name, phone, isNewImport, permittedPositions }) => {
                    const permittedPositionsString = permittedPositions
                      ? `\n Positions: ${permittedPositions.join(', ')}`
                      : ''
                    return (
                      <ListItem key={id}>
                        <ListItemAvatar>
                          <Avatar alt={name} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={name}
                          secondary={`Phone: ${phone} \n Email: ${email} ${permittedPositionsString}`}
                        />
                        {!isNewImport && (
                          <Tooltip
                            leaveDelay={TooltipDelays.WITHOUT_LINKS}
                            title="This person already received an invitation to this pool"
                          >
                            <EmailIcon color="primary" />
                          </Tooltip>
                        )}
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="remove"
                            onClick={() => removeInvite(id, 'invites', !isNewImport)}
                          >
                            <CancelIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  })}
                </List>
              </Grid>
            )}
          </Grid>
        )
      }
      // favourite staffers
      case 'Add favourite staffers': {
        const { staffers } = props
        return (
          <Fragment>
            {renderAsyncSelect('staffers')}
            <Box py={2}>
              <List>
                <Grid container spacing={3}>
                  {staffers.map((option) => renderSelectedOption(option, 'staffers'))}
                </Grid>
              </List>
            </Box>
          </Fragment>
        )
      }
      default: {
        const { staffers, employees, connectedBusinesses } = props
        return (
          <Fragment>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="h2">Business: </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>{businessName}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="h2">Created At: </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  {pool
                    ? moment(pool.createdAt).format('Do MMMM YYYY HH:mm:ss')
                    : moment().format('Do MMMM YYYY HH:mm:ss')}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {connectedBusinesses.length !== 0 && (
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h2">Connected Businesses: </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {connectedBusinesses.map(({ label, id }) => (
                    <Box mx={1} key={id}>
                      <Chip icon={<LinkIcon />} label={label} size="small" />
                    </Box>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
            {(employees.length !== 0 || imported.existing.length !== 0) && (
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h2">Employees: </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {employees.map(({ label, id }) => (
                    <Box mx={1} key={id}>
                      <Chip icon={<PersonIcon />} label={label} size="small" color="primary" />
                    </Box>
                  ))}
                  {imported.existing.map(({ nameFirst, nameLast, userId }) => (
                    <Box mx={1} key={userId}>
                      <Chip icon={<PersonIcon />} label={`${nameFirst} ${nameLast}`} size="small" color="primary" />
                    </Box>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
            {staffers.length !== 0 && (
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h2">Favourites: </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {staffers.map(({ label, id }) => (
                    <Box mx={1} key={id}>
                      <Chip icon={<FavoriteIcon />} label={label} size="small" color="secondary" />
                    </Box>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
            {imported.invites.length !== 0 && (
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography component="h2">Invites: </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {imported.invites.map(({ name, phone }) => (
                    <Box mx={1} key={phone}>
                      <Chip
                        icon={<EmailIcon className={oldStyles.svgIcon} />}
                        size="small"
                        label={name}
                        className={oldStyles.inviteChip}
                      />
                    </Box>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
          </Fragment>
        )
      }
    }
  }

  const { step } = props
  const currentStep = renderStep(step)

  return <Fragment>{currentStep}</Fragment>
}

export default CreateEditPoolSteps
