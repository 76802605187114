import firebase from 'firebase/compat/app'

// Gets the JSON Web Token from the current user
export const getJWT = async (user?: firebase.User) => {
  const u = user || firebase.auth().currentUser
  if (!u) {
    return null
  }
  const token = await u.getIdToken(true)
  return token
}
