import { DeleteOutline } from '@material-ui/icons'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { LinearProgress } from '@mui/material'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useState, type FC } from 'react'
import styles from './ImageList.module.css'

type Props = {
  imageUrl: string
  createdAt: Dayjs
  imageSize: number
  imageName?: string
  imageFormat?: string
  galleryItem?: boolean
  onRemoveItem: () => void
}

const ImageListItem: FC<Props> = ({
  imageUrl,
  imageSize,
  imageFormat,
  imageName,
  createdAt,
  galleryItem = false,
  onRemoveItem,
}) => {
  const [progress] = useState(0)
  const isGalleryProgress = galleryItem && progress > 0
  const isUploading = progress > 0 && progress < 100

  const imageNameText =
    imageName && imageName.length > 25
      ? `${imageName.slice(0, isGalleryProgress ? 15 : 25)}....${imageFormat?.toLowerCase()}`
      : imageName || imageUrl
  const imageSizeText =
    imageSize >= 1000000
      ? `${(imageSize / 1000000).toFixed(1)} MB`
      : imageSize >= 1000 && imageSize < 1000000
      ? `${(imageSize / 1000).toFixed(0)} KB`
      : `${imageSize} KB`
  const date = dayjs(createdAt).format('DD MMM, YYYY H:mm')
  return (
    <div className={styles.listItemContainer}>
      {galleryItem && <DragIndicatorIcon className={styles.dragIcon} />}
      <div className={styles.imageContainer}>
        <img src={imageUrl} className={styles.image} alt="Business banner" />
        <div className={styles.imageNameContainer}>
          <div className={styles.imageName}>
            <span>{imageNameText}</span>
            {isGalleryProgress && <span>{progress <= 100 ? progress.toFixed(0) : '100'}%</span>}
          </div>
          {isGalleryProgress && (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                backgroundColor: '#D4D4D4',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#26C64B',
                },
              }}
              className={styles.progressBar}
            />
          )}
        </div>
      </div>
      <div className={styles.actionsContainer}>
        {imageSize > 0 && <span className={styles.actionsText}>{imageSizeText}</span>}
        {date !== 'Invalid Date' && <span className={styles.actionsText}>{date}</span>}
        {galleryItem && isUploading ? (
          <CloseRoundedIcon className={styles.icon} onClick={onRemoveItem} />
        ) : (
          <DeleteOutline className={styles.icon} onClick={onRemoveItem} />
        )}
      </div>
    </div>
  )
}

export default ImageListItem
