import { Button } from '@material-ui/core'
import { BeatLoader } from 'react-spinners'
import type { ShiftTypeBasic } from '../../../../../../src/types/jobs'
import { colors } from '../../../../constants/styles'
import { errorToast } from '../../../../helpers/toast'
import moment from '../../../../util/moment'
import styles from './InternalShiftImportList.module.css'

type Props = {
  shifts: Array<ShiftTypeBasic & { jobType?: string }>
  onImportShift: (shift: ShiftTypeBasic) => any
  onAddImportedShift: (jobId: string, shiftId: string) => any
  importedShifts: Array<string | undefined>
  activityJobType?: string
  shiftsLimit: number
  onSeeMore: () => void
  plandayNext: string
}

type ShiftDetailsFormattedProps = {
  shift: ShiftTypeBasic & { jobId?: string }
  jobTypeText?: string
}

const InternalShiftImportList = ({
  shifts,
  onImportShift,
  onAddImportedShift,
  importedShifts,
  activityJobType,
  shiftsLimit,
  onSeeMore,
  plandayNext,
}: Props) => {
  if (!shifts) {
    return <BeatLoader />
  }

  const handleOnImport = (shift: ShiftTypeBasic & { jobId?: string }) => {
    try {
      if (shift.shiftId) {
        if (shift.jobId) {
          onAddImportedShift(shift.jobId, shift.shiftId)
          onImportShift(shift)
        } else {
          onImportShift(shift)
        }
      }
    } catch (error) {
      errorToast((error as Error)?.message ?? 'Unknown error when saving shift')
    }
  }

  const ShiftDetailsFormatted = ({ shift, jobTypeText }: ShiftDetailsFormattedProps) => {
    if (!shift) {
      return <BeatLoader />
    }

    const { timeStart, timeEnd } = shift
    const day = moment(timeStart).format('dddd')
    const date = moment(timeStart).format('Do MMMM')
    const startTime = moment(timeStart).format('HH:mm')
    const endTime = moment(timeEnd).format('HH:mm')

    return (
      <div className={styles.positionDetailsContainer}>
        <div className={styles.positionDetails}>
          <div className={styles.positionText}>
            {
              // Staffers shift
              shift.jobId && `${day}, ${jobTypeText}, ${date}, ${startTime} - ${endTime}`
            }
            {
              // Planday shift
              !shift.jobId && `${day}, ${date}, ${startTime} - ${endTime}`
            }
          </div>
          <div className={styles.shiftOriginText}>
            {shift.jobId && 'Staffers'}
            {!shift.jobId && 'Planday'}
            {shift.plandayDepartment && (
              <>
                {' » '}
                <span title="Department">{shift.plandayDepartment}</span>
              </>
            )}
            {shift.plandayEmployeeGroup && (
              <>
                {' » '}
                <span title="Employee group">{shift.plandayEmployeeGroup}</span>
              </>
            )}
          </div>
        </div>
        {shift.shiftId && !importedShifts.includes(shift.shiftId) ? (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleOnImport(shift)}
            className={styles.button}
          >
            Add shift
          </Button>
        ) : (
          <span className={styles.planday}>SHIFT ADDED</span>
        )}
      </div>
    )
  }

  const sortedShifts = shifts.sort((a, b) => moment(a.timeStart).diff(moment(b.timeStart)))

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {sortedShifts.map(
          (shift, index) =>
            index <= shiftsLimit - 1 && (
              // Not sure why eslint errors here, the shifts array is correctly typed.
              // eslint-disable-next-line react/prop-types
              <ShiftDetailsFormatted shift={shift} jobTypeText={shift.jobType || activityJobType} key={index} />
            )
        )}
        {shifts.length >= 5 && (shiftsLimit < shifts.length || plandayNext) && (
          <Button size="small" variant="outlined" style={{ color: colors.primary }} onClick={onSeeMore}>
            See more
          </Button>
        )}
      </div>
    </div>
  )
}

export default InternalShiftImportList
