import { query, schema } from 'qman'
import {
  getAllPools,
  getConnectedBusinesses,
  getConnectedPoolsByManagerId,
  getCustomWage,
  getEmployeesInPoolsOf,
  getExistingInviteByUsedBy,
  getFavoriteInvitedStaffers,
  getFavoritedStaffersByUsedId,
  getInvitedStafferByCode,
  getInvitedStaffersByPhone,
  getInvitedStaffersByPoolId,
  getPendingInvitedStaffersByPoolId,
  getPoolById,
  getPoolByManagerId,
  getPoolIntegration,
  getPoolIntegrationEmployees,
  getPoolsByEmployeeId,
  getStafferSkillLevel,
  getUsedInvitedStaffersByPhone,
} from '../api/getters/pools'
import { collectionFn, documentFn, promiseFn, queryFn } from './schemaFunctions'

export const pools = schema(
  'pools',
  query('poolById', getPoolById, documentFn),
  query('connectedPools', getConnectedPoolsByManagerId, promiseFn),
  query('connectedPoolsByManagerId', getConnectedPoolsByManagerId, promiseFn),
  query('allPools', getAllPools, collectionFn),
  query('poolByManagerId', getPoolByManagerId, promiseFn),
  query('invitedStafferByCode', getInvitedStafferByCode, documentFn),
  query('invitedStaffersByPhone', getInvitedStaffersByPhone, queryFn),
  query('favoritedStaffersByUsedId', getFavoritedStaffersByUsedId, queryFn),
  query('invitedStaffersByPoolId', getInvitedStaffersByPoolId, queryFn),
  query('pendingInvitedStaffersByPoolId', getPendingInvitedStaffersByPoolId, promiseFn),
  query('stafferSkillLevel', getStafferSkillLevel, promiseFn),
  query('customWage', getCustomWage, promiseFn),
  query('connectedBusinesses', getConnectedBusinesses, promiseFn),
  query('poolsByEmployeeId', getPoolsByEmployeeId, queryFn),
  query('employeesInPoolsOf', getEmployeesInPoolsOf, promiseFn),
  query('usedInvitedStaffersByPhone', getUsedInvitedStaffersByPhone, queryFn),
  query('poolIntegrationEmployees', getPoolIntegrationEmployees, queryFn),
  query('poolIntegration', getPoolIntegration, documentFn),
  query('existingInviteByUsedBy', getExistingInviteByUsedBy, promiseFn),
  query('favoriteInvitedStaffers', getFavoriteInvitedStaffers, queryFn)
)
