import { Paper, Typography } from '@material-ui/core'
import styles from './Footer.module.css'

const Footer = () => (
  <Paper className={styles.footer} square>
    <Typography variant="body2">&copy; {new Date().getFullYear()} - Staffers App</Typography>
  </Paper>
)

export default Footer
