import { Box, Button, Step, StepLabel, Stepper } from '@material-ui/core'
import { NavigateNext as NextIcon, NavigateBefore as PrevIcon } from '@material-ui/icons'
import firebase from 'firebase/compat/app'
import * as R from 'ramda'
import type { ChangeEvent } from 'react'
import { Component, Fragment } from 'react'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import type { BusinessType } from '../../../../../src/types/business'
import type { InviteType } from '../../../../../src/types/common'
import type { ImportStafferType, ParseResults } from '../../../../../src/types/parsing'
import type { PoolType } from '../../../../../src/types/pools'
import type { StafferType } from '../../../../../src/types/staffer'
import { getStafferInviteData, parseCsv } from '../../../helpers/csvParsing'
import { standardized } from '../../../helpers/phoneNumbers'
import { errorToast } from '../../../helpers/toast'
import { createEditPool } from '../../../staffers/api/firestore/https/pools'
import { firestoreHttpsCallable } from '../../../staffers/api/firestore/https/util'
import { getBusinessById } from '../../../staffers/api/getters/business'
import { getInvitedStaffersByPoolId } from '../../../staffers/api/getters/pools'
import { getStafferById, getStaffersByEmail, getStaffersByPhone } from '../../../staffers/api/getters/staffer'
import { getDataFromCollectionRef, getDataFromDocumentRef } from '../../../staffers/api/wrappers'
import { connectFirestore } from '../../../staffers/qman/connectFirestore'
import styles from '../../mui/Modal.module.css'
import ModalHeader from '../../mui/ModalHeader'
import CreateEditPoolSteps from './CreateEditPoolSteps'

export type OptionType = {
  label: string // name of option
  id: string // id of option
  photoUrl?: string // photourl
}

type ImportedType = {
  existing: Array<StafferType>
  invites: Array<ImportStafferType>
}

export type OptionFieldType = 'connectedBusinesses' | 'employees' | 'staffers'

type Props = {
  // IMPORTANT: dont forget to update LoaderProps when updating props here
  onClose: () => void
  businessId: string
  business: BusinessType
  pool?: PoolType // undefined = new pool
  redirectToStep?: number
}

// Using OptionType to display names, more user-friendly than using ids'
type State = {
  imported: ImportedType // imported csv data
  connectedBusinesses: Array<OptionType> // selected connected businesses
  employees: Array<OptionType> // selected employees
  staffers: Array<OptionType> // selected staffers
  steps: Array<string> // different steps to render for CreateEditPoolSteps
  poolName: string // custom pool name
  isProcessing: boolean
  step: number // pointer to current rendered step
  sendSms: boolean // determines whether we send sms to newly invited staffers
}

const QUERY_MIN_LENGTH = 2 // Threshold at which we start fetching results for query

class CreateEditPool extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      imported: {
        existing: [],
        invites: [],
      },
      isProcessing: false,
      step: props.redirectToStep ? props.redirectToStep : 0,
      steps: ['Enter Pool Name', 'Add connected businesses', 'Add employees', 'Add favourite staffers'],
      staffers: [],
      employees: [],
      connectedBusinesses: [],
      poolName: props.business.businessName,
      sendSms: false,
    }
  }

  /* If we are passing pool data, we need to fetch relevant information
   * only performing the fetch once (hence cdm)
   * new additions handled in async select */
  /* eslint-disable react/no-did-mount-set-state */
  async componentDidMount() {
    const { pool, businessId } = this.props
    if (pool) {
      try {
        this.setState({ isProcessing: true })
        const [fetchedConnectedBusinesses, fetchedEmployees, fetchedStaffers, fetchedInvites] = await Promise.all([
          Promise.all(
            pool.connectedBusinesses.map(async (connectedBusinessId) =>
              getDataFromDocumentRef(getBusinessById(connectedBusinessId))
            )
          ),
          Promise.all(
            pool.employees.map(async (employeeId) => {
              const employeeData = await getDataFromDocumentRef(getStafferById(employeeId))
              return { ...employeeData, id: employeeId } as StafferType & { id: string }
            })
          ),
          Promise.all(
            pool.staffers.map(async (stafferId) => {
              const stafferData = await getDataFromDocumentRef(getStafferById(stafferId))
              return { ...stafferData, id: stafferId } as StafferType & { id: string }
            })
          ),
          await getDataFromCollectionRef(getInvitedStaffersByPoolId(businessId)).then((invites) =>
            (invites ?? []).filter(Boolean).map((invite) => ({
              // @ts-ignore invite types has hardcoded id in type
              id: invite.id,
              ...(invite as InviteType),
            }))
          ),
        ])

        const connectedBusinesses: OptionType[] = (fetchedConnectedBusinesses.filter(Boolean) as BusinessType[]).map(
          (business) => ({
            label: business.businessName,
            id: business.businessId,
            photoUrl: business.photoUrl,
          })
        )
        const employees: OptionType[] = fetchedEmployees.map((employee) => ({
          label: `${employee.nameFirst} ${employee.nameLast}`,
          id: employee.id,
          photoUrl: employee.photoUrl,
        }))
        const staffers: OptionType[] = fetchedStaffers.map((staffer) => ({
          label: `${staffer.nameFirst} ${staffer.nameLast}`,
          id: staffer.id,
          photoUrl: staffer.photoUrl,
        }))
        const invites: ImportStafferType[] = fetchedInvites
          .filter(({ used }) => !used)
          .map(({ id, name, phone, email, positions, used }) => ({
            name: name || '',
            phone: phone || '',
            email: email || '',
            used,
            permittedPositions: positions,
            id,
            isNewImport: false,
          }))

        this.setState(
          {
            isProcessing: false,
            connectedBusinesses,
            employees,
            staffers,
            poolName: pool.name,
            imported: {
              existing: [],
              invites,
            },
          },
          this.checkImportStatus
        )
      } catch (error) {
        errorToast((error as Error).message)
      } finally {
        this.setState({
          isProcessing: false,
        })
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  checkExistingStaffers = async (
    stafferToInvite: ImportStafferType
  ): Promise<StafferType | ImportStafferType | null> => {
    const { name, phone, email } = stafferToInvite
    const existingStaffersPhone = (await getDataFromCollectionRef(getStaffersByPhone(phone))) ?? []

    // found exact match on phone
    if (existingStaffersPhone.length === 1) {
      return existingStaffersPhone[0]
    }

    // otherwise we'll query on e-mail
    const existingStaffersEmail = (await getDataFromCollectionRef(getStaffersByEmail(email))) ?? []

    // no staffer phone and no staffer email found => new invite
    if (!existingStaffersPhone.length && !existingStaffersEmail.length) {
      return stafferToInvite
    }

    // found exact match on email
    if (existingStaffersEmail.length === 1) {
      return existingStaffersEmail[0]
    }

    // otherwise we got multiple emails and multiple phone query results
    const queryIntersection = R.intersection(existingStaffersPhone, existingStaffersEmail)

    // narrowed down to 1 exact match
    if (queryIntersection.length === 1) {
      return queryIntersection[0]
    }

    // unlikely corner case - too many matches on both email and phone
    errorToast(
      `Found too many matches for staffer ${name} (phone: ${phone} and email: ${email}). Please add staffer manually with id or name`
    )
    return null
  }

  handleImportResults = async (data: ParseResults) => {
    try {
      // transform csv data to unified staffer invite type
      const parseResults = parseCsv(data) as Record<string, string>[]
      const importedStaffers = getStafferInviteData(parseResults)
      if (importedStaffers) {
        const existingCheck = await Promise.all(importedStaffers.map(this.checkExistingStaffers))
        const staffersToInvite = R.uniq(existingCheck.filter(Boolean) as Array<StafferType | ImportStafferType>)

        // reduce the parsed csv data to { existing: [], invites: [] }
        const imported = R.reduceBy(
          // @ts-ignore ramda has weird typing
          (group, docOrInvite) => group.concat(docOrInvite),
          [],
          ({ isNewImport }: InviteType & { isNewImport: boolean }) =>
            isNewImport === undefined ? 'existing' : 'invites',
          // @ts-ignore - this does not look safe, but it worked, before the firebase migration
          staffersToInvite as Array<InviteType & { isNewImport: boolean }>
        )

        const existing = imported.existing || []
        const invites = imported.invites || []

        // next we check if imported existing staffer wasn't already manually added
        const { employees } = this.state
        const alreadyAddedExisting = R.intersection(
          existing.map(({ userId }) => userId),
          employees.map(({ id }) => id)
        )
        const newExistingStaffers = existing.filter(({ userId }) => !alreadyAddedExisting.includes(userId))

        this.setState(
          (prevState) => ({
            imported: {
              existing: R.uniq([...newExistingStaffers, ...prevState.imported.existing]),
              invites: R.uniqBy(
                ({ email, phone }) => [email, phone],
                [...(invites || []), ...prevState.imported.invites]
              ),
            },
          }),
          this.checkImportStatus
        )
        toast.success('Successfully imported new staffers')
      } else {
        throw Error
      }
    } catch (error) {
      errorToast(
        'Parsing of staffers failed, check if uploaded file has correct format and check console for more info'
      )
      console.warn('error', error)
    } finally {
      this.setState({ isProcessing: false })
    }
  }

  checkImportStatus = () => {
    const { imported, step, steps } = this.state
    // add verification step if we have any staffers imported
    if (R.values(imported).some((array) => R.length(array) !== 0) && !steps.includes('Verify imported employees')) {
      this.addImportStep()
      return // important, or it also removes the step at the same time
    }
    // if there aren't any imported staffers, attempt to remove step (as long not on same screen)
    if (steps[step] !== 'Verify imported employees' && R.values(imported).every((array) => R.length(array) === 0)) {
      this.removeImportStep()
    }
  }

  removeInvite = async (idToRemove: string | number, from: 'invites' | 'existing', createdAlready?: boolean) => {
    if (from === 'invites') {
      this.setState(
        (prevState) => ({
          imported: {
            ...prevState.imported,
            invites: prevState.imported.invites.filter(({ id }) => id !== idToRemove),
          },
        }),
        this.checkImportStatus
      )
      // If invite was already created, delete the firestore reference as well
      if (createdAlready) {
        try {
          await firebase
            .firestore()
            .collection('staffersInvites')
            .doc(idToRemove as string)
            .delete()
        } catch (e) {
          errorToast('We were unable to delete this invite.')
        }
      }
    }
    this.setState(
      (prevState) => ({
        imported: {
          ...prevState.imported,
          existing: prevState.imported.existing.filter(({ userId }) => userId !== idToRemove),
        },
      }),
      this.checkImportStatus
    )
  }

  // eslint-disable-next-line class-methods-use-this
  isSearchable = (input: string) => input && input.length >= QUERY_MIN_LENGTH

  selectOption = (option: OptionType, fieldName: OptionFieldType) => {
    const { id } = option

    // special case, if manually added employee is in invites.existing, move him to employees state
    if (fieldName === 'employees') {
      this.setState((prevState) => ({
        imported: {
          ...prevState.imported,
          existing: prevState.imported.existing.filter(({ userId }) => userId !== id),
        },
        employees: R.uniq([...prevState.employees, option]),
      }))
    }

    // @ts-ignore dynamic state mapping
    this.setState((prevState) => ({
      [fieldName]: R.uniq([...prevState[fieldName], option]),
    }))
  }

  removeOption = (removedId: string, fieldName: OptionFieldType) => {
    // @ts-ignore dynamic state mapping
    this.setState((prevState) => ({
      [fieldName]: prevState[fieldName].filter(({ id }) => id !== removedId),
    }))
  }

  saveChanges = async () => {
    const { business, onClose, pool } = this.props
    const { businessId, groupId } = business
    const { employees, staffers, connectedBusinesses, poolName, imported, sendSms } = this.state

    const { existing, invites } = imported

    try {
      this.setState({ isProcessing: true })
      // technically these two should already be unique to each other, but just in case
      const mergedEmployeesInvites = R.uniq([...employees.map(({ id }) => id), ...existing.map(({ userId }) => userId)])
      // 1. Edit / Create Pool
      if (pool) {
        const { createdAt, createdBy } = pool
        await createEditPool({
          name: poolName,
          businessId,
          createdAt,
          createdBy,
          connectedBusinesses: connectedBusinesses.map(({ id }) => id),
          employees: mergedEmployeesInvites,
          staffers: staffers.map(({ id }) => id),
        })
      } else {
        await createEditPool({
          name: poolName,
          businessId,
          connectedBusinesses: connectedBusinesses.map(({ id }) => id),
          employees: mergedEmployeesInvites,
          staffers: staffers.map(({ id }) => id),
        })
      }
      // 2. Send invitation to all newly invited staffers
      await Promise.all(
        invites.map(async ({ name, permittedPositions, phone, email }) =>
          firestoreHttpsCallable('inviteStafferToPool', {
            email,
            poolId: businessId,
            name,
            phone: standardized(phone),
            permittedPositions: permittedPositions || [],
            sendSms,
            isExternalHire: false,
          })
        )
      )
      // 3. Update group with the added pool staffers (if group exists)
      if (groupId) {
        await firestoreHttpsCallable('addGroupStaffers', {
          groupId,
          businessIds: [businessId],
        })
      }
      toast.success(`Pool was successfully ${pool ? 'edited' : 'created'}`)
      onClose()
    } catch (error) {
      errorToast((error as Error).message)
    } finally {
      this.setState({ isProcessing: false })
    }
  }

  addImportStep = () =>
    this.setState((prevState) => ({
      steps: R.insert(3, 'Verify imported employees', prevState.steps),
    }))

  removeImportStep = () =>
    this.setState((prevState) => ({
      steps: prevState.steps.filter((step) => step !== 'Verify imported employees'),
    }))

  nextStep = () =>
    this.setState(
      (prevState) => ({
        step: prevState.step + 1,
      }),
      this.checkImportStatus
    )

  prevStep = () =>
    this.setState(
      (prevState) => ({
        step: prevState.step - 1,
      }),
      this.checkImportStatus
    )

  setPoolName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState({
      poolName: value,
    })
  }

  toggleSendSms = () =>
    this.setState((prevState) => ({
      sendSms: !prevState.sendSms,
    }))

  render() {
    const { onClose, business, pool } = this.props
    const { imported, isProcessing, step, connectedBusinesses, staffers, employees, poolName, steps, sendSms } =
      this.state

    if (isProcessing) {
      return (
        <Box p={3} display="flex" justifyContent="center">
          <BeatLoader color="gray" />
        </Box>
      )
    }

    return (
      <Fragment>
        <ModalHeader close={onClose}>{`Create a pool: ${poolName || business.businessName}`}</ModalHeader>
        <Box p={3} className={styles.modalContent}>
          {/*  Stepper navigation */}
          <Stepper activeStep={step}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* Rendering of current step */}
          <Box py={2} justifyContent="center" alignItems="center">
            <CreateEditPoolSteps
              handleImportResults={this.handleImportResults}
              imported={imported}
              isProcessing={isProcessing}
              step={step}
              steps={steps}
              poolName={poolName}
              removeInvite={this.removeInvite}
              setPoolName={this.setPoolName}
              business={business}
              pool={pool}
              connectedBusinesses={connectedBusinesses}
              employees={employees}
              staffers={staffers}
              sendSms={sendSms}
              toggleSendSms={this.toggleSendSms}
              selectOption={this.selectOption}
              removeOption={this.removeOption}
            />
          </Box>
          {/*  Action buttons */}
          <Box display="flex" alignItems="center">
            {step !== 0 && (
              <Box mr={2}>
                <Button startIcon={<PrevIcon />} variant="contained" onClick={this.prevStep} disabled={isProcessing}>
                  Back
                </Button>
              </Box>
            )}
            {step === steps.length ? (
              <Button variant="contained" color="primary" onClick={this.saveChanges} disabled={isProcessing}>
                Save
              </Button>
            ) : (
              <Button
                endIcon={<NextIcon />}
                variant="contained"
                color="primary"
                disabled={!poolName || isProcessing}
                onClick={this.nextStep}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </Fragment>
    )
  }
}

type LoaderProps = {
  onClose: () => void
  businessId: string
  business?: BusinessType
  pool?: PoolType
}

const CreateEditPoolLoader = (props: LoaderProps) => {
  const { business } = props
  if (business === undefined) {
    return (
      <Box p={3} display="flex" justifyContent="center">
        <BeatLoader color="gray" />
      </Box>
    )
  }

  return <CreateEditPool {...(props as Required<LoaderProps>)} />
}

export default connectFirestore(
  (db, props: Props) => ({
    business: getBusinessById(props.businessId),
  }),
  CreateEditPoolLoader
)
