/* eslint-disable import/no-named-as-default-member */
import type { User } from 'firebase/auth'
import { getAuth, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as React from 'react'
import Modal from 'react-modal'
import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch, withRouter } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import type { SuperAdminType } from '../../src/types/common'
import styles from './app.module.css'
import { PATHS } from './constants/routes'
import { errorToast } from './helpers/toast'
import IntegrationAuthDone from './screens/IntegrationAuthDone'
import LinkWithFacebook from './screens/LinkWithFacebook'
import LogIn from './screens/LogIn'
import CompanyAdmin from './screens/company'
import SuperAdmin from './screens/superadmin'
import { updateLastVisited } from './staffers/api/firestore/business.legacy'
import { logBusinessEvent } from './staffers/api/firestore/logBusinessEvent.legacy'
import { getServerTimestamp } from './staffers/api/firestore/util.legacy'
import { getAdminById } from './staffers/api/getters/admins.legacy'
import { getBusinessById } from './staffers/api/getters/business.legacy'
import { getManagerById } from './staffers/api/getters/managers.legacy'
import { getStafferById } from './staffers/api/getters/staffer.legacy'

type AppStatus =
  | 'loading'
  | 'login'
  | 'superAdmin'
  | 'companyAdmin'
  | 'linkFacebook'
  | 'verifyPhone'
  | 'setupBusiness'
  | 'integrationAuthDone'

type Props = RouteComponentProps

type State = {
  superAdmin: null | SuperAdminType
  status: AppStatus
  businessId: string | null
}

class App extends React.Component<Props, State> {
  // state: Readonly<State> = {
  state: State = {
    superAdmin: null,
    status: 'loading',
    businessId: null,
  }

  componentDidMount() {
    const auth = getAuth()
    Modal.setAppElement('#root') // to prevent nesting divs inside modals into <tbody>
    onAuthStateChanged(auth, async (user) => {
      const status = await this.getAppStatus(user)
      if (user) {
        // @ts-ignore
        global.clarity?.('identify', user.uid)
      }
      this.setState({ status })
    })
  }

  getAppStatus = async (appUser: User | null): Promise<AppStatus> => {
    const auth = getAuth()
    const currentLocation = this.props.location && this.props.location.pathname
    let user = appUser
    let loggedInWithToken = false
    if (currentLocation.includes('loginWithToken')) {
      const token = currentLocation.replace('/loginWithToken/', '')
      try {
        const userCredential = await signInWithCustomToken(auth, token)
        // eslint-disable-next-line prefer-destructuring
        user = userCredential.user
        this.props.history.push(PATHS.companyProfile)
        loggedInWithToken = true
      } catch (e) {
        // If token is not valid, it will silently crash and does whatever it is supposed to do
        console.warn('Error:', e)
      }
    }

    if (currentLocation.includes('integration-auth-done')) {
      return 'integrationAuthDone'
    }

    if (!user) {
      return 'login'
    }

    const userId = user.uid
    const database = firebase.firestore()

    const adminRef = await getAdminById(database, userId)
    const adminDoc = await adminRef.get()
    if (adminDoc.exists) {
      this.setState({
        superAdmin: adminDoc.data() as SuperAdminType,
      })
      return 'superAdmin'
    }

    const managerRef = await getManagerById(database, userId)
    const managerDoc = await managerRef.get()
    const managerData = managerDoc.data()
    if (managerData) {
      if (managerData.accessBlocked) {
        errorToast('This account has been blocked by an admin.')
        return 'login'
      }

      if (managerData.businessId) {
        const businessRef = await getBusinessById(database, managerData.businessId)
        const businessDoc = await businessRef.get()
        const businessData = businessDoc.data()

        if (
          businessData &&
          (!businessData.accessRequested || !businessData.businessType || !businessData.businessName) &&
          managerData.businessId === businessData.businessId &&
          !loggedInWithToken && // If user was logged in via token don't require company finish
          !businessData.isFromAppOnboarding
        ) {
          if (currentLocation && !currentLocation.includes('register-company')) {
            this.props.history.push('/register-company')
          }
          this.setState({ businessId: businessData.businessId })
          return 'setupBusiness'
        }
        // This should be shown only if user was not logged in via token
        if (!loggedInWithToken) {
          const storedDate = new Date(businessData?.accessRequested)
          const nowDate = new Date()
          const elapsedTime = nowDate.getTime() - storedDate.getTime()
          if (elapsedTime <= 60000) {
            toast.success('Your profile was successfully created!')
          }
        }
        try {
          await updateLastVisited(database, managerData.businessId)
          await managerRef.update({
            lastLoggedAt: getServerTimestamp(),
          })
          await logBusinessEvent(managerData.businessId, `Manager ${managerData.email} Logged in admin.`).catch(
            console.warn
          )
        } catch (error) {
          errorToast((error as Error).message)
        }
        return 'companyAdmin'
      }

      if (!managerData.phone && !managerData.businessId) {
        if (currentLocation && !currentLocation.includes('phone-verification')) {
          this.props.history.push('/phone-verification')
        }
        return 'verifyPhone'
      }

      errorToast('You do not have a registered business.')
      return 'login'
    }

    const stafferRef = await getStafferById(database, userId)
    const stafferDoc = await stafferRef.get()
    if (stafferDoc.exists) {
      toast.success('This account is already associated with a staffer.')
      return 'linkFacebook'
    }
    if (this.state.status !== 'login') {
      errorToast('This account does not seem to exist. It may have been deleted.')
    } else {
      // This is here to bypass corner case race condition which should not exist. In case
      // we end up here, wait a bit and recalculate the status...
      setTimeout(this.recalculateAppStatus, 1000)
    }
    return 'login'
  }

  render() {
    return (
      <div className={styles.app}>
        <ToastContainer
          // @ts-ignore this is correct, just incorrectly typed in package
          position="top-right"
          autoClose={8000}
          closeOnClick
          newestOnTop
          draggable
        />
        {this.renderApp()}
      </div>
    )
  }

  recalculateAppStatus = async () => {
    const auth = getAuth()
    const { currentUser } = auth
    const status = await this.getAppStatus(currentUser)
    this.setState({ status })
  }

  renderApp() {
    const { superAdmin, status } = this.state

    if (status === 'integrationAuthDone') {
      return (
        <Switch>
          <Route path={PATHS.integrationAuthDone}>
            <IntegrationAuthDone />
          </Route>
        </Switch>
      )
    }

    if (status === 'linkFacebook') {
      return <LinkWithFacebook />
    }

    if (status === 'companyAdmin') {
      return <CompanyAdmin />
    }

    if (status === 'superAdmin' && superAdmin) {
      // @ts-ignore prop is there, incorrect error
      return <SuperAdmin superAdmin={superAdmin} />
    }

    if (status === 'login') {
      return <LogIn recalculateAppStatus={this.getAppStatus} />
    }

    if (status === 'verifyPhone' || status === 'setupBusiness') {
      return <LogIn signUpStep={status} businessId={this.state.businessId} recalculateAppStatus={this.getAppStatus} />
    }

    return (
      <div className={styles.loaderWrapper}>
        <BeatLoader color="gray" />
      </div>
    )
  }
}

export default withRouter(App)
