import styles from './StafferCard.module.css'

type Props = {
  businessWorked: string[]
}

const StafferCardExperiences = ({ businessWorked }: Props) => (
  <div className={styles.contentWrapper}>
    <ul className={styles.list}>
      {businessWorked.slice(0, 3).map((business, index) => (
        <li key={index} className={styles.experiences}>
          {business}
        </li>
      ))}
    </ul>
  </div>
)

export default StafferCardExperiences
