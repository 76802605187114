import {
  Box,
  Card,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
} from '@material-ui/core'
import { Cancel as CancelIcon } from '@material-ui/icons'
import * as R from 'ramda'
// @ts-ignore package has no exported types
import Select, { components } from 'react-select'
import { BeatLoader } from 'react-spinners'
import type { ManagerType, ManagerTypeWithId } from '../../../../../src/types/manager'
import { getManagersByBusinessId } from '../../../staffers/api/getters/managers'
import { connectFirestore } from '../../../staffers/qman/connectFirestore'
import styles from '../modals.module.css'
import type { SelectBusinessType } from './CreateEditGroup'

type Props = {
  managers?: Array<ManagerTypeWithId>
  selectedGroupManagers?: Array<ManagerTypeWithId>
  selectedBusinesses: Array<SelectBusinessType & { id: string }>
  groupBusinesses: Array<SelectBusinessType & { id: string }>
  addBusinessToExistingManagers: boolean
  toggleAddBusinessToExistingManagers: () => void
  onSelectGroupManager: (manager?: ManagerTypeWithId) => any
  onUnselectGroupManager: (manager?: ManagerTypeWithId) => any
}

const ReactSelectBuiltInOption = components.Option

const SelectGroupManagers = (props: Props) => {
  const {
    onSelectGroupManager,
    onUnselectGroupManager,
    selectedGroupManagers,
    selectedBusinesses,
    groupBusinesses,
    addBusinessToExistingManagers,
    toggleAddBusinessToExistingManagers,
  } = props

  if (!props.managers) {
    return <BeatLoader />
  }

  const managers = R.flatten(props.managers).filter((manager) => !!manager)

  const renderOption = (renderProps: { data: ManagerType }) => {
    const { email } = renderProps.data
    return <ReactSelectBuiltInOption {...renderProps}>{email}</ReactSelectBuiltInOption>
  }

  const businessNameOfManager = (manager: ManagerTypeWithId) =>
    (selectedBusinesses.find(({ businessId }) => businessId === manager.businessId) || {}).businessName

  const isNewBusinessAdded = selectedBusinesses.some(({ businessId }) =>
    groupBusinesses.find((business) => business.id !== businessId)
  )

  return (
    <Box mt={3}>
      <Typography variant="h2">Group manager</Typography>
      <Box className={styles.search} mt={1}>
        <Select
          components={{ Option: renderOption }}
          placeholder="Select a manager who you want to be upgraded to a group manager"
          noOptionsMessage={() => 'No results found.'}
          styles={{
            option: (provided: Record<string, string>) => ({
              ...provided,
              color: 'black',
              display: 'flex',
              'flex-direction': 'row',
              'align-items': 'center',
            }),
          }}
          value={null} // Don't store the selected value
          onChange={onSelectGroupManager}
          className={styles.searchInput}
          isSearchable
          options={managers}
        />
      </Box>
      {isNewBusinessAdded && (
        <Box my={2}>
          <FormControlLabel
            control={
              <Switch
                checked={addBusinessToExistingManagers}
                color="primary"
                onClick={toggleAddBusinessToExistingManagers}
              />
            }
            label="Add new business to existing managers"
          />
        </Box>
      )}
      {selectedGroupManagers && (
        <List>
          {selectedGroupManagers.map((manager) => (
            <Box mt={2} key={manager.id}>
              <Card>
                <ListItem>
                  <ListItemText primary={manager.email} secondary={`Manager of ${businessNameOfManager(manager)}`} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => onUnselectGroupManager(manager)}>
                      <CancelIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Card>
            </Box>
          ))}
        </List>
      )}
    </Box>
  )
}

export default connectFirestore((db, props: Props) => {
  if (props.selectedBusinesses) {
    return {
      managers: props.selectedBusinesses.map((business) => getManagersByBusinessId(business.businessId)),
    }
  }
  return {}
}, SelectGroupManagers)
