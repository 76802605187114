import { Box, IconButton, Typography } from '@material-ui/core'
import { ChevronLeft, Close } from '@material-ui/icons'
import type { ReactNode } from 'react'
import styles from './Modal.module.css'

type Props = {
  goBack?: () => any
  close?: () => any
  children: ReactNode
}

const ModalHeader = ({ goBack, close, children }: Props) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="between"
    alignItems="center"
    p={0.5}
    className={styles.modalHeader}
  >
    {!!goBack && (
      <IconButton onClick={goBack}>
        <ChevronLeft className={styles.headerIcon} />
      </IconButton>
    )}
    <Box flexGrow={1} pl={goBack ? 0 : 2}>
      <Typography component="h1" variant="h6" className={styles.modalTitle}>
        {children}
      </Typography>
    </Box>
    {close && (
      <IconButton onClick={close}>
        <Close className={styles.headerIcon} />
      </IconButton>
    )}
  </Box>
)

export default ModalHeader
