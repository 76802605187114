export const colors = {
  bg: '#FFFFFF',
  border: '#E5E7EB', // gray/200
  placeholder: '#9CA3AF',
  text: '#6B7280', // gray/500
  disabled: '#D1D5DB',
  heading: '#2C3E50',
  title: '#0E141A',
  primary: '#26C64B',
  success: '#BDEEC9', // Brand green/200
  warning: '#FFDEBB',
  info: '#F3F4F6',
  error: '#EF4E4E',
  modal: 'rgba(0, 0, 0, 0.5)', // 50% opacity

  gray600: '#2C3E50',
  gray500: '#6B7280',
  gray400: '#616E7C',
  gray300: '#959EA7',
  gray200: '#E5E7EB',
  gray100: '#E0E2E5',
  gray70: '#F4F5F6',
  gray50: '#F9FAFB',

  green50: '#F4FCF6',
  green100: '#DFF7E4',
  green500: '#26C64B',

  orange50: '#FFF9F4',
  orange500: '#FF931E',

  red500: '#EF4E4E',

  neutral50: '#FAFAFA',
  neutral200: '#E5E5E5',
  neutral300: '#D4D4D4',
  neutral400: '#A3A3A3',
  neutral500: '#737373',
  neutral600: '#525252',
  neutral800: '#262626',
  neutral900: '#171717',
}

export const spacing = {
  xs: 6,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
}

export const Theme = {
  colors,
  spacing,
}

export default Theme
