import { Box, Button, IconButton, Snackbar } from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'
import Facebook from '@material-ui/icons/Facebook'
import FileCopy from '@material-ui/icons/FileCopy'
import LinkedIn from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import WhatsApp from '@material-ui/icons/WhatsApp'
import React, { Component } from 'react'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { jobsUrl } from '../../config'
import muiModalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import styles from './modals.module.css'

type Props = {
  closeModal: () => any
  jobAdId: string
}

type State = {
  isSnackbarOpen: boolean
}

const filledIconStyle = {
  backgroundColor: '#303d4f',
  color: 'white',
  padding: '2px',
}

class ShareJob extends Component<Props, State> {
  state = {
    isSnackbarOpen: false,
  }

  openSnackbar = () => this.setState({ isSnackbarOpen: true })

  closeSnackbar = (event: any, reason: string) => reason === 'clickaway' || this.setState({ isSnackbarOpen: false })

  // eslint-disable-next-line class-methods-use-this
  copyToClipboard = (toCopy: string) => navigator.clipboard.writeText(toCopy)

  handleCopyClick = (url: string) => {
    this.copyToClipboard(url)
    this.openSnackbar()
  }

  render() {
    const { closeModal, jobAdId } = this.props
    const { isSnackbarOpen } = this.state

    const url = `${jobsUrl}/en/job-ads/${jobAdId}`
    const shortenedUrl = url.split('//')[1]

    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={isSnackbarOpen}
          autoHideDuration={1000}
          onClose={this.closeSnackbar}
          message="Copied to clipboard"
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={(e: any) => this.closeSnackbar(e, '')}>
              <Cancel />
            </IconButton>
          }
        />
        <ModalHeader close={closeModal}>Share this job link</ModalHeader>
        <Box p={3} className={muiModalStyles.modalContent}>
          <Button fullWidth onClick={() => this.handleCopyClick(shortenedUrl)}>
            <Box className={styles.copyButton}>
              <code>{shortenedUrl}</code>
              <FileCopy />
            </Box>
          </Button>
          <section className={styles.shareRow}>
            <WhatsappShareButton title="See more about this job opportunity in the link below!" url={url}>
              <WhatsApp style={filledIconStyle} />
            </WhatsappShareButton>
            <TwitterShareButton title="See more about this job opportunity in the link below!" url={url}>
              <TwitterIcon style={filledIconStyle} />
            </TwitterShareButton>
            <FacebookShareButton quote="See more about this job opportunity in the link below!" url={url}>
              <Facebook style={filledIconStyle} />
            </FacebookShareButton>
            <LinkedinShareButton
              title="See more about this job opportunity in the link below!"
              source="Staffers"
              url={url}
            >
              <LinkedIn style={filledIconStyle} />
            </LinkedinShareButton>
          </section>
        </Box>
      </React.Fragment>
    )
  }
}

export default ShareJob
