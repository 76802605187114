import {
  Avatar,
  Badge,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { Component } from 'react'
import type { Firestore } from '../../../../src/types/firebase'
import { formatChatParticipantsLimited } from '../../helpers/stringFormatter'
import { isEmployeeInPoolsOf } from '../../staffers/api/getters/staffer.legacy'
import moment from '../../util/moment'
import styles from './ChatList.module.css'

type State = {
  isEmployeeChat: boolean
}

export type ChatParticipant = {
  nameFirst: string
  nameLast: string
  photoUrl: string
  businessName: string
  uid: string
}

type Props = {
  chatId: string
  businessId: string
  isSelected?: boolean
  lastMessageCreatedAt: string
  unreadMessages?: number
  participants: Array<ChatParticipant>
  eventId: string
  onClick: (chatId: string) => any
  lastReopenedAt: Date
  lastMessageText: string
}

class ChatListItem extends Component<Props, State> {
  state = {
    isEmployeeChat: false,
  }

  componentDidMount = async () => {
    const { businessId, participants } = this.props
    if (participants) {
      const staffersData = participants // without business and unnamed staffers
        .filter(({ uid, nameFirst }) => uid !== businessId && nameFirst)
      const stafferIds = staffersData.map((staffer) => staffer.uid)
      const areStaffersEmployees = await Promise.all(
        stafferIds.map(async (stafferId) =>
          isEmployeeInPoolsOf(firebase.firestore() as Firestore, stafferId, businessId)
        )
      )
      const hasExternalStaffer = !!areStaffersEmployees.find((bool) => bool === false)
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        isEmployeeChat: !hasExternalStaffer,
      })
    }
  }

  render() {
    const { isEmployeeChat } = this.state
    const {
      chatId,
      businessId,
      isSelected,
      onClick,
      eventId,
      lastReopenedAt,
      lastMessageCreatedAt,
      participants,
      lastMessageText,
      unreadMessages,
    } = this.props

    const NAMES_LIMIT = 2

    if (!participants) {
      return null
    }

    const staffersData = participants // without business and unnamed staffers
      .filter(({ uid, nameFirst }) => uid !== businessId && nameFirst)

    if (staffersData.length === 0) {
      return null
    }

    const names = formatChatParticipantsLimited(staffersData)

    const isLegacy = !!eventId
    const isInactive = isEmployeeChat
      ? false
      : (moment().isAfter(moment(lastMessageCreatedAt).add(2, 'days')) &&
          moment().isAfter(moment(lastReopenedAt).add(2, 'days'))) ||
        isLegacy

    const lastMessageFormatted =
      lastMessageText && (lastMessageText.length > 15 ? `${lastMessageText.slice(0, 15)}...` : lastMessageText)

    const messageText = isLegacy
      ? 'Legacy. Will be removed soon.'
      : isInactive
      ? 'Closed. Click to open.'
      : lastMessageFormatted || (
          <i>
            <small>no messages</small>
          </i>
        )

    return (
      <ListItem
        button
        selected={isSelected}
        onClick={() => onClick && onClick(chatId)}
        style={{ opacity: isInactive ? 0.5 : 1 }}
      >
        <ListItemAvatar>
          <AvatarGroup
            max={NAMES_LIMIT}
            classes={{ avatar: staffersData.length > 1 ? styles.avatarSmall : styles.avatarLarge }}
          >
            {staffersData.map((staffer: ChatParticipant) => (
              <Avatar key={staffer.uid} alt={`${staffer.nameFirst} ${staffer.nameLast}`} src={staffer.photoUrl} />
            ))}
          </AvatarGroup>
        </ListItemAvatar>
        <ListItemText
          primary={names}
          primaryTypographyProps={{
            className: styles.names,
          }}
          secondary={
            <Badge
              invisible={!unreadMessages}
              badgeContent={unreadMessages}
              color="error"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              variant="dot"
            >
              {messageText}
            </Badge>
          }
        />
        <ListItemSecondaryAction>
          <Typography variant="caption" display="block" align="right">
            {moment(lastMessageCreatedAt).format('Do')}
            <br />
            {moment(lastMessageCreatedAt).format('MMM')}
            <br />
            {!moment(lastMessageCreatedAt).isSame(moment(), 'y') && moment(lastMessageCreatedAt).format('YYYY')}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

export default ChatListItem
