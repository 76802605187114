import { collection, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore'
import type { Moment } from 'moment'
import type {
  ActivityInternalToExternalType,
  ActivityType,
  BusinessLogType,
  BusinessOnboarding,
  BusinessType,
} from '../../../../../src/types/business'
import type { BusinessStaffer } from '../../../../../src/types/businessStaffer'
import type { CountryNameType, TimeoutsType } from '../../../../../src/types/common'
import type { CollectionReference, Query } from '../../../../../src/types/firebase'
import type { JobType } from '../../../../../src/types/jobs'
import type { FavoriteStafferListingObject } from '../../../components/companyStaffersListing/FavoriteStaffersListing'
import moment from '../../../util/moment'
import { getCollectionRef, getDataFromDocumentRef, getDocumentRef, getQueryRef } from '../wrappers'
import { getManagerByIdPromise } from './managers'

export const getRecentBusinessRequests = () =>
  getQueryRef<BusinessType>(
    'business',
    where('accessRequested', '>', moment().subtract(7, 'days').toDate()),
    where('accessGranted', '==', false)
  )

export const getBusinessById = (businessId?: string) => getDocumentRef<BusinessType>('business', businessId || '')

export const getBusinessesByGroupId = (groupId: string) =>
  getQueryRef<BusinessType>('business', where('groupId', '==', groupId))

export const getBusinessOnboarding = (businessId: string) =>
  getDocumentRef<BusinessOnboarding>('businessOnboarding', businessId)

export const getAllBusinesses = () =>
  getQueryRef<BusinessType>(
    'business',
    where('accessGranted', '==', true),
    // Not orderby to avoid businesses with empty businessNames on dev
    where('businessName', '>', '')
  )

export const getAllActiveBusinesses = () =>
  getQueryRef<BusinessType>(
    'business',
    where('accessGranted', '==', true),
    where('isArchived', '==', false),
    // Not orderby to avoid businesses with empty businessNames on dev
    where('businessName', '>', '')
  )

export const getUnclaimedBusinesses = () =>
  getQueryRef<BusinessType>(
    'business',
    where('unclaimed', '==', true),
    where('isArchived', '==', false)
    // Not orderby to avoid businesses with empty businessNames on dev
    // where('businessName', '>', ''),
  )

export const getClaimedBusinesses = () =>
  getQueryRef<BusinessType>('business', where('unclaimed', '==', false), where('isArchived', '==', false))

export const getBusinessRating = (businessId: string, stafferId: string) =>
  getDocumentRef<{ comment?: string; ratingsCount: number; ratingsTotal: number }>(
    'business',
    businessId,
    'ratings',
    stafferId
  )

export const getAllBusinessRequests = () =>
  getQueryRef<BusinessType>(
    'business',
    where('accessGranted', '==', false),
    where('accessRequested', '>', new Date(0)),
    orderBy('accessRequested', 'desc')
  )

export const getUnresolvedBusinessRequests = () =>
  getQueryRef<BusinessType>('business', where('accessRequested', '==', false), orderBy('createdAt', 'desc'))

export const getBusinessesByLastActivity = () =>
  getQueryRef<BusinessType>(
    'business',
    where('accessGranted', '==', true),
    where('isArchived', '==', false),
    orderBy('lastVisited', 'desc')
  )

export const getBusinessesByTotalPosted = () =>
  getQueryRef<BusinessType>('business', where('totalPosted', '>=', 1), orderBy('totalPosted', 'desc'))

export const getBusinessesByTotalShifts = () =>
  getQueryRef<BusinessType>(
    'business',
    where('accessGranted', '==', true),
    where('isArchived', '==', false),
    orderBy('totalShifts', 'desc')
  )

export const getBusinessesByTotalStaffers = () =>
  getQueryRef<BusinessType>(
    'business',
    where('accessGranted', '==', true),
    where('isArchived', '==', false),
    orderBy('totalStaffers', 'desc')
  )

export const getBusinessesByAccessGrantedAndCountry = (country: string) =>
  getQueryRef<BusinessType>(
    'business',
    where('accessGranted', '==', true),
    where('isArchived', '==', false),
    where('region.countryName', '==', country),
    orderBy('accessGrantedAt', 'desc')
  )

export const getBusinessesByRegion = (country: CountryNameType) =>
  getQueryRef<BusinessType>('business', where('region.countryName', '==', country))

export const getBusinessesByCountry = (country: string) =>
  getQueryRef<BusinessType>('business', where('address.country', '==', country))

export const getJobsByLastUnapproved = () =>
  getQueryRef<JobType>(
    'jobs',
    where('lastUnapproved', '<', moment().subtract(8, 'hours').toDate()),
    orderBy('lastUnapproved', 'asc')
  )

export const getStaffersByBusinessId = (businessId: string) =>
  collection(getFirestore(), 'business', businessId, 'staffers') as CollectionReference<BusinessStaffer>

export const getFavoriteStaffersByBusinessId = (businessId: string) =>
  collection(
    getFirestore(),
    'business',
    businessId,
    'favoriteStaffers'
  ) as CollectionReference<FavoriteStafferListingObject>

export const getStaffersByBusinessIdSortedByLastShift = (businessId: string) =>
  query(getStaffersByBusinessId(businessId), orderBy('timeStart', 'desc')) as Query<BusinessStaffer>

export const getLogsByBusinessId = (businessId: string) =>
  query(getCollectionRef<BusinessLogType>('business', businessId, 'logs'), orderBy('time', 'desc'), limit(15))

export const getBusinessByIdPromise = async (businessId: string): Promise<BusinessType | null> =>
  getDataFromDocumentRef<BusinessType>(getDocumentRef('business', businessId))

export const getBusinessByManagerIdPromise = async (userId: string) => {
  const managerData = await getManagerByIdPromise(userId)
  // eslint-disable-next-line no-nested-ternary
  return !managerData
    ? null
    : managerData.businessId
    ? getDataFromDocumentRef<BusinessType>(getDocumentRef('business', managerData.businessId))
    : null
}

export const getBusinessByManagerId = async (userId: string) => {
  const managerData = await getManagerByIdPromise(userId)
  return managerData && managerData.businessId ? getBusinessById(managerData.businessId) : []
}

export const getBusinessActivityById = (businessId: string, activityId: string) =>
  getDocumentRef<ActivityType | ActivityInternalToExternalType>('business', businessId, 'activities', activityId)

export const getUnseenBusinessActivitiesById = (businessId: string, managerId: string) =>
  query(
    getCollectionRef<ActivityType>('business', businessId, 'activities'),
    where('unseenBy', 'array-contains', managerId || ''),
    where('notifyUntil', '>', new Date())
  )

export const getBusinessActivitiesWithActionToBeTakenById = (businessId: string, managerId: string) =>
  query(
    getCollectionRef<ActivityType>('business', businessId, 'activities'),
    where('showTo', 'array-contains', managerId || ''), // Only show to managers which should handle it
    where('actionToBeTaken', '==', true), // Only take actions with remaining actions
    orderBy('createdAt', 'desc') // Order by when they were created
  )

export const getUnresolvedRequestsByBusinessId = (businessId: string, managerId: string) =>
  query(
    getCollectionRef<ActivityType>('business', businessId, 'activities'),
    where('unseenBy', 'array-contains', managerId || ''),
    where('type', '==', 'unresolved')
  )

export const getUnseenRequestsByJobId = (businessId: string, managerId: string, jobId: string) =>
  query(
    getCollectionRef<ActivityType>('business', businessId, 'activities'),
    where('jobId', '==', jobId),
    where('unseenBy', 'array-contains', managerId || '')
  )

export const getSpecificActivitiesByJobShiftStafferId = (
  businessId: string,
  jobId: string,
  shiftId: string,
  stafferId: string,
  type: string
) =>
  query(
    getCollectionRef<ActivityType>('business', businessId, 'activities'),
    where('actionToBeTaken', '==', true),
    where('jobId', '==', jobId),
    where('shiftId', '==', shiftId),
    where('stafferId', '==', stafferId),
    where('type', '==', type)
  )

export const getUnseenRequestsByJobAdId = (businessId: string, managerId: string, jobId: string) =>
  query(
    getCollectionRef<ActivityType>('business', businessId, 'activities'),
    where('jobAdId', '==', jobId),
    where('unseenBy', 'array-contains', managerId || '')
  )

export const getUnseenActivitiesByManagerId = async (userId: string) => {
  const managerData = await getManagerByIdPromise(userId)
  return managerData && managerData.businessId ? getUnseenBusinessActivitiesById(managerData.businessId, userId) : []
}

export const getAllBusinessesByRegionId = (regionId: string) =>
  getQueryRef<BusinessType>('business', where('regionId', '==', regionId))

export const getBusinessesByOrgNumberPromise = async (orgNumber: string) =>
  getDocs(getQueryRef<BusinessType>('business', where('orgNumber', '==', orgNumber))).then((querySnapshot) =>
    querySnapshot.docs.map((snapshot) => ({
      id: snapshot.id,
    }))
  ) as Promise<BusinessType[]>

export const getAllBusinessesSince = (from: Moment) => () =>
  getQueryRef<BusinessType>(
    'business',
    where('accessGranted', '==', true),
    where('accessGrantedAt', '>', from.toDate())
  )

export const getBusinessesOnCallingList = () => getQueryRef<BusinessType>('business', where('callingList', '==', true))

export const getActiveBusinessesOnCallingList = () =>
  getQueryRef<BusinessType>('business', where('callingList', '==', true), where('isArchived', '==', false))

export const getArchivedBusinesses = () => getQueryRef<BusinessType>('business', where('isArchived', '==', true))

export const getTimeouts = () => getDocumentRef<TimeoutsType>('enums', 'timeouts')

export const getCustomTypedBusinessesByCountry = (country: string) =>
  getQueryRef<BusinessType>('business', where('address.country', '==', country), orderBy('customBusinessType'))

export const getPreviousDuties = async (businessId: string) => {
  const business = await getDataFromDocumentRef<BusinessType>(getDocumentRef('business', businessId))
  return business ? business.duties : []
}
