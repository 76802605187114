import { Box, Divider, IconButton, ListItem, Typography, Zoom } from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import React from 'react'
import styles from './sidebar.module.css'

type Props = {
  businessName: string
  isGroupManager?: boolean
  isSidebarOpen: boolean
  onCollapse: () => void
}

const SidebarHeader = (props: Props) => {
  const { businessName, isGroupManager, isSidebarOpen, onCollapse } = props
  return (
    <React.Fragment>
      <ListItem className={styles.header}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box>
            {/* Logo (S) */}
            <Typography component="span" style={{ fontSize: '32px', color: 'white', fontWeight: 'bold' }}>
              S
            </Typography>
          </Box>
          {/* We don't display the text on collapsed sidebar */}
          {isSidebarOpen && (
            <Box display="flex" flexDirection="column">
              <Typography variant="h5" style={{ fontSize: '16px', color: 'white' }}>
                {isGroupManager ? 'GROUP' : 'COMPANY'}
              </Typography>
              <Typography variant="h5" style={{ fontSize: '16px', color: 'white' }}>
                ADMIN
              </Typography>
            </Box>
          )}
          <Box>
            {/* Collapse button */}
            <IconButton onClick={onCollapse} color="inherit">
              <KeyboardArrowLeft className={isSidebarOpen ? styles.iconClose : styles.iconOpen} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <Divider />
      <Zoom in={isSidebarOpen} unmountOnExit>
        <Box p={2} justifyContent="center" alignItems="center" display="flex">
          <Typography style={{ fontSize: '20px' }}>{businessName}</Typography>
        </Box>
      </Zoom>
      <Divider />
    </React.Fragment>
  )
}

export default React.memo(SidebarHeader)
