import { Box, ListItem, Tooltip, Typography } from '@material-ui/core'
import type { ReactNode } from 'react'
import { memo } from 'react'
import { TooltipDelays } from '../../../constants/tooltips'
import type { IconType } from './itemCreator'
import styles from './sidebar.module.css'

type Props = {
  name: string // displayed text
  modalId: string // should match the isModalOpen in Sidebar.js
  children?: ReactNode // optional, if provided overrides styles and what is displayed in ListItem
  isSidebarOpen: boolean
  icon?: IconType
  isHighlighted: boolean
  shouldCompleteProfile: boolean
  toggleModal: (modalName: string) => void
}

// eslint-disable-next-line react/display-name
const SidebarActionItem = memo((props: Props) => {
  const { name, modalId, children, isSidebarOpen, icon, isHighlighted, shouldCompleteProfile, toggleModal } = props

  return (
    <ListItem
      id={name}
      button
      onClick={shouldCompleteProfile ? () => toggleModal('completeProfile') : () => toggleModal(modalId)}
      className={isHighlighted ? styles.itemHighlighted : styles.item}
    >
      <Box display="flex" alignItems="center">
        {icon && (
          <Box pr={2}>
            {isSidebarOpen ? (
              icon
            ) : (
              <Tooltip leaveDelay={TooltipDelays.WITHOUT_LINKS} placement="right" title={name}>
                {icon}
              </Tooltip>
            )}
          </Box>
        )}
        {isSidebarOpen && (children || <Typography>{name}</Typography>)}
      </Box>
    </ListItem>
  )
})

export default SidebarActionItem
