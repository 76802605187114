import { Box, Button, Grid } from '@material-ui/core'
import React, { Component } from 'react'
// @ts-ignore react-select has no exported @types/ module
import Select from 'react-select'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { errorToast } from '../../helpers/toast'
import { firestoreHttpsCallable } from '../../staffers/api/firestore/https/util'
import { logStafferEvent } from '../../staffers/api/firestore/logStafferEvent.legacy'
import modalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'

type Props = {
  closeModal: () => any
  stafferId?: string
}

const notificationOptions: OptionType[] = [
  {
    label: 'You need to add a Norwegian phone number(s) to your references to be evaluated',
    status: 'evaluated',
    value: 'You need to add a Norwegian phone number(s) to your references to be evaluated',
  },
  {
    label: 'You need to add more information in your profile to be evaluated in to the platform.',
    status: 'evaluated',
    value: 'You need to add more information in your profile to be evaluated in to the platform.',
  },
  {
    label:
      'Unfortunately at this moment we do not have enough jobs that suits your prior experience. You will remain in our queue system and your request will be evaluated once we have more such jobs available.',
    status: 'evaluated',
    value:
      'Unfortunately at this moment we do not have enough jobs that suits your prior experience. You will remain in our queue system and your request will be evaluated once we have more such jobs available.',
  },
  {
    label:
      'The information you listed in your request was invalid or incorrect. We cannot accept you into the platform.',
    status: 'denied',
    value:
      'The information you listed in your request was invalid or incorrect. We cannot accept you into the platform.',
  },
  {
    label:
      'We have tried to call your references several times without getting a reply. Are you sure you entered correct contact information? If you did, It could help if you contact them and tell them that Staffers is trying to contact them.',
    status: 'evaluated',
    value:
      'We have tried to call your references several times without getting a reply. Are you sure you entered correct contact information? If you did, It could help if you contact them and tell them that Staffers is trying to contact them.',
  },
  {
    label:
      'We need you to add a video description and tell us more about your experience from the restaurant industry, because you did not add a reference with Norwegian phone number.',
    status: 'evaluated',
    value:
      'We need you to add a video description and tell us more about your experience from the restaurant industry, because you did not add a reference with Norwegian phone number.',
  },
  {
    label:
      'One or more of the number you listed does not belong to a manager/owner, please update it so we can call your previous employer.',
    status: 'evaluated',
    value:
      'One or more of the number you listed does not belong to a manager/owner, please update it so we can call your previous employer.',
  },
]

type OptionType = {
  value: any
  label: string
  status: 'applied' | 'evaluated' | 'denied' | 'updated' | 'granted'
}

type State = {
  isLoading: boolean
  selectedNotification: OptionType
}

class SendNotifications extends Component<Props, State> {
  state = {
    isLoading: false,
    selectedNotification: notificationOptions[0],
  }

  sendStafferAccessNotification = async () => {
    const { stafferId } = this.props
    const {
      selectedNotification: { status, value },
    } = this.state
    try {
      if (stafferId) {
        this.setState({ isLoading: true })
        await firestoreHttpsCallable('sendUpdateExperienceNotification', {
          notificationText: value,
          stafferId,
          status,
        })
        toast.success('Notification was sent successfully.')
        await logStafferEvent(stafferId, `Push notification sent: "${value}"`)
        this.setState({ isLoading: false })
      }
    } catch (error) {
      errorToast((error as Error)?.message ?? error)
    } finally {
      this.props.closeModal()
    }
  }

  handleChange = (selectedOption: OptionType) => {
    const {
      selectedNotification: { value },
    } = this.state
    if (value !== selectedOption.value) {
      this.setState({
        selectedNotification: selectedOption,
      })
    }
  }

  render() {
    const { closeModal } = this.props
    const { isLoading, selectedNotification } = this.state

    if (isLoading) {
      return (
        <Box p={3} className={modalStyles.modalContent} textAlign="center">
          <BeatLoader />
        </Box>
      )
    }

    return (
      <React.Fragment>
        <ModalHeader close={closeModal}>Notification text</ModalHeader>
        <Box p={3} className={modalStyles.modalContent}>
          <Box mb={3}>
            <Select
              value={selectedNotification}
              onChange={this.handleChange}
              options={notificationOptions}
              isSearchable={false}
              styles={{
                menu: (provided: Record<string, string>) => ({
                  ...provided,
                  position: 'relative',
                }),
              }}
            />
          </Box>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={3}>
              <Button fullWidth variant="contained" onClick={closeModal}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button fullWidth color="primary" variant="contained" onClick={this.sendStafferAccessNotification}>
                Send
              </Button>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    )
  }
}

export default SendNotifications
