import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import * as R from 'ramda'
import { Fragment } from 'react'
import type { Timestamp } from '../../../../src/types/firebase'
import type { StafferTypeWithId } from '../../../../src/types/staffer'
import { pools } from '../../staffers/qman/pools'
import { staffers } from '../../staffers/qman/staffers'
import moment from '../../util/moment'
import ModalHeader from '../mui/ModalHeader'

import styles from '../companyJobsListing/JobsListing.module.css'
import modalStyles from '../mui/Modal.module.css'

type Props = {
  staffer: StafferTypeWithId
  onRequestClose: () => void
}

const ViewStafferRatings = (props: Props) => {
  const { staffer, onRequestClose } = props

  const { nameFirst, nameLast } = staffer

  const stafferRatings = staffers.get('stafferRatings', [staffer.id])
  const poolByStafferId = pools.get('poolsByEmployeeId', [staffer.id]) ?? []

  const Heading = <ModalHeader close={onRequestClose}>{`Previously worked jobs: ${nameFirst} ${nameLast}`}</ModalHeader>

  if (stafferRatings === null) {
    return (
      <Fragment>
        {Heading}
        <Box p={3}>
          <h2>This staffer did not previously work on our platform.</h2>
        </Box>
      </Fragment>
    )
  }

  if (stafferRatings === undefined) {
    return (
      <Fragment>
        {Heading}
        <LinearProgress color="primary" />
        <Box p={3} display="flex" justifyContent="center" alignItems="center">
          {`Fetching ratings for ${nameFirst} ${nameLast}.`}
        </Box>
      </Fragment>
    )
  }

  const allJobsWithRatings = R.flatten(
    stafferRatings
      .filter((rating) => poolByStafferId.findIndex((pool) => pool.businessId === rating.businessId) < 0)
      .map((ratingDoc) =>
        ratingDoc.ratings.map((rating) => ({
          ...rating,
          businessId: ratingDoc.businessId,
          businessName: ratingDoc.businessName,
        }))
      )
  )

  const previousJobs = allJobsWithRatings.map((jobRating) => {
    const { timeStart, timeEnd } = jobRating
    const timeText =
      timeStart && timeEnd
        ? `${moment(jobRating.timeStart as Timestamp).format('Do MMM YYYY HH:mm')} - ${moment(
            jobRating.timeEnd as Timestamp
          ).format('HH:mm')}`
        : '-'
    return (
      <TableRow key={jobRating.jobId} className={styles.tableRow}>
        <TableCell className={styles.tableCell}>
          <strong>{jobRating.businessName || '-'}</strong>
        </TableCell>
        <TableCell className={styles.tableCell}>
          <strong>{jobRating.jobType || '-'}</strong>
        </TableCell>
        <TableCell className={styles.tableCell}>
          <strong>
            {jobRating.value || '-'}{' '}
            <span role="img" aria-label="star-emoji">
              &#11088;
            </span>
          </strong>
        </TableCell>
        <TableCell className={styles.tableCell}>{timeText}</TableCell>
        <TableCell className={styles.tableCell}>
          <strong>{jobRating.comment || '-'}</strong>
        </TableCell>
      </TableRow>
    )
  })

  return (
    <Box className={modalStyles.modalContent}>
      {Heading}
      <Box p={3} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <TableContainer component={Paper}>
          <Table aria-label="Previous jobs">
            <TableHead className={styles.tableHead}>
              <TableRow>
                <TableCell className={styles.tableHeadCell}>Business name</TableCell>
                <TableCell className={styles.tableHeadCell}>Position</TableCell>
                <TableCell className={styles.tableHeadCell}>Rating</TableCell>
                <TableCell className={styles.tableHeadCell}>Time start - Time end</TableCell>
                <TableCell className={styles.tableHeadCell}>Comment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{previousJobs}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default ViewStafferRatings
