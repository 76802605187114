import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import type { Firestore } from '../../../../../src/types/firebase'

export const getDatabase = () => firebase.firestore() as Firestore

export const getServerTimestamp = () => firebase.firestore.FieldValue.serverTimestamp()

export const getCurrentUserId = () => {
  const { currentUser } = firebase.auth()
  return currentUser && currentUser.uid
}
