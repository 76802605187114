import type { SelectProps } from '@material-ui/core'
import { FormControl, InputLabel, Select as MuiSelect, withStyles } from '@material-ui/core'
import { KeyboardArrowDownRounded } from '@material-ui/icons'
import type { PropsWithChildren } from 'react'
import { colors } from '../../../constants/styles'

export const CustomFormControl = withStyles({
  root: {
    '& .MuiSelect-select:focus': {
      borderRadius: 8,
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.neutral200,
    },
  },
})(FormControl)

export const CustomSelect = withStyles({
  root: {},
  select: {
    padding: '12px',
    borderRadius: '8px',
  },
  icon: {
    color: colors.neutral400,
  },
})((props: SelectProps) => <MuiSelect IconComponent={KeyboardArrowDownRounded} {...props} />)

const Select = ({ children, ...rest }: PropsWithChildren<SelectProps>) => (
  <CustomFormControl fullWidth size="small">
    <InputLabel>{rest.label}</InputLabel>
    <CustomSelect {...rest} variant="outlined">
      {children}
    </CustomSelect>
  </CustomFormControl>
)

export default Select
