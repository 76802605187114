import { Button } from '@material-ui/core'
import React, { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import Modal from '../mui/Modal'
import styles from './modals.module.css'

const modalRef = React.createRef() as React.MutableRefObject<WrappedConfirmModal | null>

type Props = {
  closeModal: () => any
  confirmationTitle: string
  additionalTextLines?: Array<string>
  isLoading: boolean
  onClick: () => any
}

const ConfirmationModal = (props: Props) => {
  const { closeModal, isLoading, onClick, confirmationTitle, additionalTextLines } = props

  if (isLoading) {
    return <BeatLoader />
  }

  return (
    <div className={styles.confirmationModalWrapper}>
      <h1 className={styles.removeHeader}>{confirmationTitle}</h1>
      {additionalTextLines && additionalTextLines.map((line, i) => <p key={i}> {line} </p>)}
      <div className={styles.buttonWrapperColumn}>
        <Button onClick={closeModal} className={styles.grayButton} variant="contained">
          No
        </Button>
        <Button className={styles.confirmationGreenButton} onClick={onClick} variant="contained">
          Yes
        </Button>
      </div>
    </div>
  )
}

// Folowing components allow alternative simplified self-contained usage
// eg:
// import { confirm } from '../ConfirmationModal'
// ...
// const ok = await confirm('Do stuff?')

type State = {
  isModalOpen: boolean
  title: string
  text?: Array<string>
}

class WrappedConfirmModal extends Component<{ ref: React.RefCallback<WrappedConfirmModal> }, State> {
  promiseInfo: { resolve: any; reject: any } = {
    resolve: () => {},
    reject: () => {},
  }

  state = {
    isModalOpen: false,
    title: '',
    text: [],
  }

  show = async (title = 'Really?', text?: Array<string>) =>
    new Promise((resolve, reject) => {
      this.promiseInfo = {
        resolve,
        reject,
      }
      this.setState({
        isModalOpen: true,
        title,
        text,
      })
    })

  onConfirm = () => {
    const { resolve } = this.promiseInfo
    if (resolve) {
      resolve(true)
    }
    this.setState({ isModalOpen: false })
  }

  onCancel = () => {
    const { resolve } = this.promiseInfo
    if (resolve) {
      resolve(false)
    }
    this.setState({ isModalOpen: false })
  }

  render() {
    const { isModalOpen, title, text } = this.state

    return (
      <Modal
        zIndex={25} // make it on top of any other modal
        isOpen={isModalOpen}
        contentLabel="Confirmation modal"
        onRequestClose={this.onCancel}
      >
        <ConfirmationModal
          onClick={this.onConfirm}
          closeModal={this.onCancel}
          confirmationTitle={title}
          additionalTextLines={text}
          isLoading={false}
        />
      </Modal>
    )
  }
}

const confirm = async (title: string, text?: Array<string>): Promise<boolean> => {
  if (!modalRef.current) {
    return false
  }
  const modal = modalRef.current as WrappedConfirmModal
  return modal.show(title, text) as Promise<boolean>
}

const ModalWithRef = () => (
  <WrappedConfirmModal
    ref={(ref) => {
      modalRef.current = ref
    }}
  />
)

export default ConfirmationModal
export { ModalWithRef as Confirmation, confirm }
