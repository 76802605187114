import { query, schema } from 'qman'
import {
  getAdminNotifications,
  getRecentNotifications,
  getUserById,
  getUserDevices,
  getUserTags,
} from '../api/getters/user'
import { collectionFn, documentFn, queryFn } from './schemaFunctions'

export const users = schema(
  'users',
  query('userById', getUserById, documentFn),
  query('userTags', getUserTags, collectionFn),
  query('userDevices', getUserDevices, queryFn),
  query('recentNotifications', getRecentNotifications, queryFn),
  query('adminNotifications', getAdminNotifications, queryFn)
)
