// Functions specific to the superadmin
import { getDoc, setDoc, updateDoc } from 'firebase/firestore'
import type { InfoMessagesType } from '../../../../../src/types/settings'
import type { ManagerReferralsType, StafferReferralsTypeLegacy } from '../../../../../src/types/stafferReferrals'
import { standardized } from '../../../helpers/phoneNumbers'
import { getManagerReferrals } from '../getters/managers'
import { getStafferReferrals } from '../getters/staffer'
import { getDocumentRef } from '../wrappers'

const MAX_QUESTIONS = 6 // maximum amount of questions for sign-up video

export const blockStaffer = async (stafferId: string) =>
  setDoc(
    getDocumentRef('staffers', stafferId),
    {
      blocked: true,
    },
    { merge: true }
  )

export const promoteStaffer = async (stafferId: string) =>
  updateDoc(getDocumentRef('staffers', stafferId), { isSkilled2: true })

export const demoteStaffer = async (stafferId: string) =>
  updateDoc(getDocumentRef('staffers', stafferId), { isSkilled2: false })

export const validateInviteCode = async ({
  businessId,
  email,
  isBusiness,
  inviteCode,
  invitedBy,
  isSkilled,
  phoneNumber,
}: {
  businessId?: string
  email: string
  isBusiness?: boolean
  inviteCode: string
  invitedBy: string
  isSkilled: boolean
  phoneNumber: string
}) => {
  let inviteCodeObject
  if (isBusiness) {
    inviteCodeObject = businessId
      ? {
          businessId,
          code: inviteCode,
          email,
          invitedBy,
          isBusiness,
          isSkilled,
          phone: phoneNumber,
          used: false,
        }
      : {
          code: inviteCode,
          email,
          invitedBy,
          isBusiness,
          isSkilled,
          phone: phoneNumber,
          used: false,
        }
  } else {
    inviteCodeObject = {
      code: inviteCode,
      email,
      invitedBy,
      isSkilled,
      phone: phoneNumber,
      used: false,
    }
  }

  return setDoc(getDocumentRef('inviteCodes', inviteCode), inviteCodeObject, { merge: true })
}

export const stafferRefMarkAsPaid = async (
  stafferId: string,
  due: number // just to be on safe side
) => {
  const currentReferralsSnapshot = await getDoc(getStafferReferrals(stafferId))
  if (!currentReferralsSnapshot.exists()) {
    throw Error(`StafferReferrals with id ${stafferId} does not exists`)
  }
  const currentReferrals = currentReferralsSnapshot.data() as StafferReferralsTypeLegacy
  const newPaydate = {
    amount: due,
    date: new Date(),
  }
  // In case we accidentally shown wrong due for some reason
  if (due > currentReferrals.due) {
    throw Error('Due amount mismatch. Due tried to be paid is higher than actual due to the staffer.')
  }
  const newDue = currentReferrals.due - due
  return updateDoc(getDocumentRef('staffersReferrals', stafferId), {
    due: newDue,
    payouts: [...currentReferrals.payouts, newPaydate],
    lastPaydate: new Date(),
  })
}

export const updateSignupQuestions = async (questions: string[]) => {
  // would cause text overflow on mobile screen
  if (questions.length > MAX_QUESTIONS) {
    throw Error(`Exceeded the maximum amount of questions (${MAX_QUESTIONS})`)
  }

  questions.forEach((question, index) => {
    if (!question) {
      throw Error(`Question ${index + 1} cannot be empty!`)
    }
  })

  return updateDoc(getDocumentRef('settings', 'signup'), {
    signupQuestions: questions,
  })
}

export const updateInfoMessages = async (data: InfoMessagesType) =>
  setDoc(getDocumentRef('settings', 'infoMessages'), { ...data }, { merge: true })

export const updateBusinessOnboardedSms = async (businessOnboardedSms: string[]) => {
  const numbers = businessOnboardedSms
    .map((num) => num.trim())
    .filter(Boolean)
    .map(standardized)
  return setDoc(getDocumentRef('settings', 'signup'), { businessOnboardedSms: numbers }, { merge: true })
}

export const updateNewBusinessNotifSms = async (newBusinessSms: string[]) => {
  const numbers = newBusinessSms
    .map((num) => num.trim())
    .filter(Boolean)
    .map(standardized)
  return setDoc(getDocumentRef('settings', 'signup'), { newBusinessSms: numbers }, { merge: true })
}

export const managerRefMarkAsPaid = async (
  userId: string,
  due: number // just to be on safe side
) => {
  const currentReferralsSnap = await getDoc(getManagerReferrals(userId))
  if (!currentReferralsSnap.exists()) {
    throw Error(`ManagerReferrals with id ${userId} does not exist`)
  }
  const newPaydate = {
    amount: due,
    date: new Date(),
  }
  const currentReferrals = currentReferralsSnap.data() as ManagerReferralsType
  // In case we accidentally shown wrong due for some reason
  if (due > currentReferrals.due) {
    throw Error('Due amount mismatch. Due tried to be paid is higher than actual due to the staffer.')
  }
  const newDue = currentReferrals.due - due
  return updateDoc(getDocumentRef('managersReferrals', userId), {
    due: newDue,
    payouts: [...currentReferrals.payouts, newPaydate],
    lastPaydate: new Date(),
  })
}
