import { Box, List } from '@material-ui/core'
import { ChatListProvider } from '@webscopeio/react-firebase-messenger-web'
import { getDatabase, ref } from 'firebase/database'
import * as R from 'ramda'
import { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import moment from '../../util/moment'
import Empty from '../mui/Empty'
import styles from './ChatList.module.css'
import type { ChatParticipant } from './ChatListItem'
import ChatListItem from './ChatListItem'

type ChatData = {
  chatId: string
  lastReopenedAt: Date
  lastMessageText: string
  eventId: string
  lastMessageCreatedAt: string
  unreadMessages?: number
  participants: Array<ChatParticipant>
}

type Props = {
  businessId: string
  selected?: string
  unreadMessages?: { [chatId: string]: number }
  onSelect: (id: string) => any
}

type ChatProviderProps = {
  chatListProps: {
    chatsData: ChatData[]
    userChats: Record<string | number, unknown>[]
    loading: boolean
  }
  chatListDataFetch: ({ uid }: { uid: string }) => Promise<void>
  unsubscribeChatsData: ({ uid }: { uid: string }) => Promise<void>
}

class ChatList extends Component<Props & ChatProviderProps> {
  componentDidMount() {
    const { businessId, chatListDataFetch } = this.props
    setTimeout(() => {
      chatListDataFetch({ uid: businessId })
    })
  }

  componentDidUpdate = async ({ businessId: prevBusinessId }: { businessId: string }) => {
    const { businessId, unsubscribeChatsData, chatListDataFetch } = this.props
    if (businessId !== prevBusinessId) {
      unsubscribeChatsData({ uid: prevBusinessId })
      setTimeout(() => {
        chatListDataFetch({ uid: businessId })
      })
    }
  }

  componentWillUnmount() {
    const { businessId, unsubscribeChatsData } = this.props
    unsubscribeChatsData({ uid: businessId })
  }

  render() {
    const {
      businessId,
      selected,
      onSelect,
      chatListProps: { chatsData, loading },
      unreadMessages,
    } = this.props

    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
          <BeatLoader />
        </Box>
      )
    }

    return chatsData.length > 0 ? (
      <List className={styles.chatList}>
        {R.compose(
          R.map(({ chatId, ...rest }: ChatData) => (
            <ChatListItem
              key={chatId}
              chatId={chatId}
              isSelected={chatId === selected}
              onClick={onSelect}
              businessId={businessId}
              unreadMessages={unreadMessages ? unreadMessages[chatId] : 0}
              {...rest}
            />
          )),
          R.sort(({ lastMessageCreatedAt: A }, { lastMessageCreatedAt: B }) => (moment(A).isAfter(moment(B)) ? -1 : +1))
        )(chatsData)}
      </List>
    ) : (
      <Box className={styles.emptyWrapper}>
        <Empty label="No conversations yet" className={styles.empty} />
      </Box>
    )
  }
}

// Wrapper component exception
// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <ChatListProvider
    firebaseDBRef={ref(getDatabase())}
    {...props}
    // @ts-ignore
    component={ChatList}
  />
)
