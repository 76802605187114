// TODO moment.js doesn't have a 'short' version of moment.fromNow() so we make our own.
import type { Duration } from 'moment'

export const getElapsedTimeText = (duration: Duration) => {
  if (duration.asSeconds() < 15) {
    return 'now'
  }

  if (duration.asMinutes() < 1) {
    return `${5 * Math.round(duration.asSeconds() / 5)}s` // 15s 20s 30s 35s
  }

  if (duration.asHours() < 1) {
    return `${Math.round(duration.asMinutes())}m` // 1m 2m 3m 4m
  }

  if (duration.asDays() < 1) {
    return `${Math.round(duration.asHours())}h` // 1h 2h 3h 4h
  }

  return `${Math.round(duration.asDays())}d` // 1d 2d 3d 4d
}
