import { Box, TextField, Typography } from '@material-ui/core'
import { InfoOutlined as InfoIcon } from '@material-ui/icons'
import { Fragment, createRef, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { BeatLoader } from 'react-spinners'
import { errorToast } from '../../helpers/toast'
import { updateBusinessProfile } from '../../staffers/api/firestore/business'
import { ModalFooter } from '../elasticSearch/modal/Modal'
import MuiModal from '../mui/Modal'
import ModalHeaderLegacy from '../mui/ModalHeader'
import Button from '../mui/redesignedComponents/Button'

// string => website
// undefined => do nothing (cancel)
// null => do nothing (cancel)
type ResultType = string | null | undefined

type AddBusinessWebsiteModalRef = {
  prompt: (businessId: string) => Promise<ResultType>
}

const modalRef = createRef() as React.MutableRefObject<AddBusinessWebsiteModalRef | null>

const AddBusinessWebsiteModal = forwardRef<AddBusinessWebsiteModalRef, any>(
  function AddBusinessWebsiteModal(props, ref) {
    const [visible, setVisible] = useState(false)
    const [website, setWebsite] = useState('')
    const [businessId, setBusinessId] = useState<string | null>(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const resolveRef = useRef<((res: ResultType) => void) | null>(null)

    const onWebsiteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setWebsite(e.target.value)
    }

    const send = async () => {
      setIsProcessing(true)
      if (businessId && businessId?.length > 0) {
        try {
          await updateBusinessProfile(businessId, {
            website,
          })
        } catch (error) {
          console.error(error)
          return errorToast('Setting website was not successful')
        }
      }
      resolveRef.current?.(website)
      setIsProcessing(false)
      setVisible(false)
    }

    const cancel = async () => {
      resolveRef.current?.(undefined)
      setVisible(false)
    }

    const prompt = async (_businessId: string): Promise<ResultType> => {
      setVisible(true)
      setBusinessId(_businessId)
      return new Promise((resolve) => {
        resolveRef.current = resolve
      })
    }

    useImperativeHandle(ref, () => ({
      prompt,
    }))

    if (isProcessing) {
      return (
        <Box m={4} display="flex" justifyContent="center">
          <BeatLoader />
        </Box>
      )
    }

    // If the manager requests the cancellation, this form will be displayed
    return (
      <MuiModal isOpen={visible} zIndex={30}>
        <Fragment>
          <ModalHeaderLegacy close={cancel}>Fill in your business website to continue</ModalHeaderLegacy>
          <Box p={3}>
            <Box py={2}>
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Your business website"
                placeholder="https://staffers.com"
                inputProps={{
                  maxLength: 2000,
                }}
                value={website}
                onChange={onWebsiteChange}
              />
            </Box>
            <Box py={2} display="flex" flexDirection="row" gridGap={8}>
              <InfoIcon fontSize="small" />
              <Typography variant="body2">We need your business website to properly generate content.</Typography>
            </Box>
          </Box>
          <ModalFooter>
            <Button onClick={cancel} color="inherit" variant="outlined">
              Not now
            </Button>
            <Button onClick={send} color="primary" variant="contained" disabled={!website.trim()}>
              Save and generate
            </Button>
          </ModalFooter>
        </Fragment>
      </MuiModal>
    )
  }
)

const promptAddBusinessWebsiteModal = async (businessId: string): Promise<ResultType> => {
  if (!modalRef.current) {
    console.error('Function promptAddBusinessWebsiteModal was called before rendering AddBusinessWebsite component')
    return
  }
  const modal = modalRef.current
  return modal.prompt(businessId) as Promise<ResultType>
}

const AddBusinessWebsite = () => <AddBusinessWebsiteModal ref={modalRef} />

export default AddBusinessWebsiteModal
export { AddBusinessWebsite, promptAddBusinessWebsiteModal }
