import { BeatLoader } from 'react-spinners'

type LoaderProps = {
  error: boolean
  timedOut: boolean
  pastDelay: boolean
}

const DynamicLoader = ({ error, timedOut, pastDelay }: LoaderProps) => {
  if (error) {
    // When the loader has errored
    return <div>Something went wrong, please reload the page.</div>
  }
  if (timedOut) {
    // When the loader has taken longer than the timeout
    return <div>Taking a long time...</div>
  }
  if (pastDelay) {
    // When the loader has taken longer than the delay
    return <BeatLoader color="gray" />
  }
  // When the loader has just started
  return null
}

export default DynamicLoader
