import { Box, TextField, Typography } from '@material-ui/core'
import { InfoOutlined as InfoIcon } from '@material-ui/icons'
import { Fragment, createRef, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { BeatLoader } from 'react-spinners'
import { colors } from '../../constants/styles'
import { ModalFooter } from '../elasticSearch/modal/Modal'
import MuiModal from '../mui/Modal'
import ModalHeaderLegacy from '../mui/ModalHeader'
import Button from '../mui/redesignedComponents/Button'

// this uses similar pattern as confirm from ConfirmationModal, except it is not class-based

const defaultMessage = `
Thank you for applying.
We regret to inform you that we have decided to move forward with other candidates at this time.
We encourage you to apply for future positions and wish you good luck.
`

// string => send
// undefined => do nothing (cancel)
// null => do not ask anymore
type ResultType = string | null | undefined

type RejectionMessageModalRef = {
  prompt: (name: string, showNeverAsk: boolean) => Promise<ResultType>
}

const modalRef = createRef() as React.MutableRefObject<RejectionMessageModalRef | null>

const RejectionMessageModal = forwardRef<RejectionMessageModalRef, any>(function RejectionMessageModal(props, ref) {
  const [visible, setVisible] = useState(false)
  const [message, setMessage] = useState(defaultMessage)
  const [name, setName] = useState('')
  const [showNeverAsk, setShowNeverAsk] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const resolveRef = useRef<((res: ResultType) => void) | null>(null)

  const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const send = async () => {
    setIsProcessing(true)
    resolveRef.current?.(message)
    setIsProcessing(false)
    setVisible(false)
  }

  const cancel = async () => {
    resolveRef.current?.(undefined)
    setVisible(false)
  }

  const never = async () => {
    resolveRef.current?.(null)
    setVisible(false)
  }

  const prompt = async (_name: string, _showNeverAsk: boolean): Promise<ResultType> => {
    setName(_name)
    setShowNeverAsk(_showNeverAsk)
    setVisible(true)
    return new Promise((resolve) => {
      resolveRef.current = resolve
    })
  }

  useImperativeHandle(ref, () => ({
    prompt,
  }))

  if (isProcessing) {
    return (
      <Box m={4} display="flex" justifyContent="center">
        <BeatLoader />
      </Box>
    )
  }

  // If the manager requests the cancellation, this form will be displayed
  return (
    <MuiModal isOpen={visible} zIndex={30}>
      <Fragment>
        <ModalHeaderLegacy close={cancel}>Send rejection message?</ModalHeaderLegacy>
        <Box p={3}>
          <Box py={2}>
            <TextField
              multiline
              required
              fullWidth
              variant="outlined"
              label="Rejection reason"
              placeholder="Please write the reason of your rejection."
              rows={5}
              inputProps={{
                maxLength: 2000,
              }}
              value={message}
              onChange={onMessageChange}
            />
          </Box>
          <Box py={2} display="flex" flexDirection="row" gridGap={8}>
            <InfoIcon fontSize="small" />
            <Typography variant="body2">
              By sending this message, you will no longer be able to move {name} from the <i>not a fit</i> group.
            </Typography>
          </Box>
        </Box>
        <ModalFooter>
          <div>
            <Button onClick={cancel} color="inherit" variant="outlined">
              Not now
            </Button>
            {showNeverAsk && (
              <Button
                onClick={never}
                color="primary"
                variant="outlined"
                style={{ color: colors.red500, borderColor: colors.red500, marginLeft: 8 }}
              >
                Never ask for this job
              </Button>
            )}
          </div>
          <Button onClick={send} color="primary" variant="contained" disabled={!message.trim()}>
            Send to {name}
          </Button>
        </ModalFooter>
      </Fragment>
    </MuiModal>
  )
})

const promptRejectionMessage = async (name: string, showNeverAsk?: boolean): Promise<ResultType> => {
  if (!modalRef.current) {
    console.error('Function promptRejectionMessage was called before rendering RejectionMessage component')
    return
  }
  const modal = modalRef.current
  return modal.prompt(name, !!showNeverAsk) as Promise<ResultType>
}

const RejectionMessage = () => <RejectionMessageModal ref={modalRef} />

export default RejectionMessageModal
export { RejectionMessage, promptRejectionMessage }
