import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import type { ManagerType } from '../../../../../src/types/manager'
import type { UserType } from '../../../../../src/types/user'
import { standardized } from '../../../helpers/phoneNumbers'
import { redeemInvite } from '../firestore/https/onboarding'
import { getServerTimestamp } from '../firestore/util.legacy'

type FieldValue = firebase.firestore.FieldValue

export const checkManagerInviteByPhone = async (phone: string) => {
  try {
    const phoneStandardized = standardized(phone)
    const inviteRef = firebase.firestore().collection('managersInvites').where('phone', '==', phoneStandardized)
    const inviteDoc = await inviteRef.get().then((res) => res.docs[0])
    const inviteData = inviteDoc.data()
    if (inviteData && inviteData.used) {
      throw Error('checkManagerInviteByPhone error - This invite code does not exist or has already been used.')
    }
    if (inviteDoc) {
      await redeemInvite('manager', inviteDoc.id).catch(console.warn)
    }
  } catch (error) {
    console.warn(error, 'checkManagerInviteByPhone', { phone })
  }
}

export const checkManagerInviteByEmail = async (email: string) => {
  try {
    const inviteRef = firebase.firestore().collection('managersInvites').where('email', '==', email)
    const inviteDoc = await inviteRef.get().then((res) => res.docs[0])
    const inviteData = inviteDoc.data()
    if (inviteData && inviteData.used) {
      throw Error('checkManagerInviteByEmail error - This invite code does not exist or has already been used.')
    }
    if (inviteDoc) {
      await redeemInvite('manager', inviteDoc.id).catch(console.warn)
    }
  } catch (error) {
    console.warn(error, 'checkManagerInviteByEmail', { email })
  }
}

export const createManagerProfile = async () => {
  const database = firebase.firestore()

  const { currentUser } = firebase.auth()
  if (!currentUser || !currentUser.uid) {
    throw Error('Internal error - Firebase user is not defined.')
  }

  const { email, emailVerified, phoneNumber, uid: userId } = currentUser

  // Initializing manager account data
  const managerData:
    | ManagerType
    | {
        createdAt: FieldValue
        lastLoggedAt: FieldValue
      } = {
    accessBlocked: false,
    businessId: null,
    createdAt: getServerTimestamp(),
    email: email || '',
    emailVerified: emailVerified || false,
    isOwner: false,
    lastLoggedAt: getServerTimestamp(),
    messagesUnseen: 0,
    phone: phoneNumber || '',
    updatedAt: null,
    userId,
  }

  // Initializing user settings
  const userData: UserType = {
    notifications: {
      enabled: true,
      messages: 0,
      topics: [],
      unseen: 0,
    },
  }

  try {
    await database.runTransaction(async (transaction) => {
      await transaction.set(database.collection('users').doc(userId), userData)
      await transaction.set(database.collection('managers').doc(userId), managerData)
    })
    // await checkManagerInviteByEmail(email)
  } catch (error) {
    console.warn(error)
    throw Error('Database error. Your account could not be created.')
  }
}
