import { Box, Button, Grid, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import muiModalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import TextField from '../textInputField/TextInputField'
import styles from './modals.module.css'

type Props = {
  jobType: string
  onSave: (templateName: string) => any
  onClose: () => any
}

type State = {
  templateName: string
}

class SaveJobTemplate extends Component<Props, State> {
  state = {
    templateName: '',
  }

  changeName = (templateName: string) => {
    this.setState({
      templateName,
    })
  }

  save = () => {
    this.props.onSave(this.state.templateName)
  }

  render() {
    const { templateName } = this.state
    const { onClose, jobType } = this.props
    return (
      <React.Fragment>
        <ModalHeader close={onClose}>Save template</ModalHeader>
        <Box p={2} className={muiModalStyles.modalContent}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography>
                All fields with a blue header and all shifts added in this job post will be saved in the template.
                <br />
                This includes Position, Wage/Toggle for wage, Uniform, tasks, additional, location, shifts and number of
                people you need.
                <br />
                Add a name for this template to be able to save it.
              </Typography>
            </Grid>
            <Grid item container spacing={2} justify="center">
              <Grid item>
                <h2 className={styles.subtitle}>Job position</h2>
                <TextField value={jobType} inputProps={{ readOnly: true }} />
              </Grid>
              <Grid item>
                <h2 className={styles.subtitle}>Name of the template</h2>
                <TextField onChangeValue={this.changeName} placeholder="Name the template" value={templateName} />
              </Grid>
            </Grid>
            <Grid item container spacing={2} justify="center">
              <Grid item xs={3}>
                <Button fullWidth variant="contained" color="default" onClick={onClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button fullWidth variant="contained" color="primary" disabled={!templateName} onClick={this.save}>
                  Save template
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    )
  }
}

export default SaveJobTemplate
