/* eslint-disable @typescript-eslint/no-empty-function */
import type { ComponentType, ReactNode } from 'react'
import { Component, createContext } from 'react'
import type { BusinessType } from '../../../../src/types/business'

export type SheetDataType = {
  header?: string[]
  rows?: string[][]
  isInvoiced?: boolean
}

export type PreviewType = {
  [businessId: string]: {
    invoices: {
      event?: SheetDataType
      period?: SheetDataType
    }
    jobs: {
      tempJobs?: string[]
      eventJobs?: string[]
      jobAds?: string[]
      active?: string[]
      periods?: { [periodId: string]: string[] }
    }
    business: BusinessType
    warnings?: string[]
  }
}

export type PreviewContextType = {
  preview: PreviewType
  onPreviewChange: (data: PreviewType) => void
}

export type ContextProps = {
  previewContext: PreviewContextType
}

const PreviewContext = createContext({
  preview: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPreviewChange: (data: PreviewType) => {},
})

type Props = {
  children: ReactNode
}

type State = {
  preview: PreviewType
}

class InvoicePreviewProvider extends Component<Props & Partial<ContextProps>, State> {
  state = {
    preview: {},
  }

  onPreviewChange = (newPreviewData: PreviewType) => {
    this.setState({ preview: newPreviewData })
  }

  render() {
    const { children } = this.props
    const { preview } = this.state

    return (
      <PreviewContext.Provider
        value={{
          preview,
          onPreviewChange: this.onPreviewChange,
        }}
      >
        {children}
      </PreviewContext.Provider>
    )
  }
}

export const withInvoicePreview =
  (ChildComponent: ComponentType<any>) =>
  // Wrapper component
  // eslint-disable-next-line react/display-name
  (props: any) => (
    <PreviewContext.Consumer>
      {(previewContext) => <ChildComponent previewContext={previewContext} {...props} />}
    </PreviewContext.Consumer>
  )

export default InvoicePreviewProvider
