import { Box, FormControl, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import type { ChangeEvent } from 'react'
import { Fragment } from 'react'
import Checkbox from '../../checkbox/CustomCheckbox'
import styles from './CreateEditGroup.module.css'

type Props = {
  country: string
  groupName: string
  numberOfBusinesses: number
  onCountryChange: (event: ChangeEvent<HTMLInputElement>) => void
  onEmptyBusinessesCountChange: (event: ChangeEvent<HTMLInputElement>) => void
  toggleTestingOn: () => void
  testingOn: boolean
}

const EmptyBusinessesStep = (props: Props) => {
  const {
    country,
    groupName,
    numberOfBusinesses,
    onCountryChange,
    onEmptyBusinessesCountChange,
    testingOn,
    toggleTestingOn,
  } = props

  return (
    <Fragment>
      <Box display="flex" alignItems="center" mb={2}>
        <Box mr={1}>
          <Typography>How many companies would you like to add? (max 500)</Typography>
        </Box>
        <TextField variant="outlined" value={numberOfBusinesses} onChange={onEmptyBusinessesCountChange} />
      </Box>
      <Typography className={styles.infoNote}>
        A list of companies will be generated with numbers as names (for example {groupName} 1 to {groupName} 10).
      </Typography>
      <Box display="flex" alignItems="center" my={2}>
        <Box mr={3}>
          <Typography>What&apos;s the group&apos;s country of origin?</Typography>
        </Box>
        <FormControl>
          <Select
            id="country"
            value={country}
            // @ts-ignore MUI Select has bad typing
            onChange={onCountryChange}
          >
            <MenuItem value="Norway">Norway</MenuItem>
            <MenuItem value="Sweden">Sweden</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" alignItems="center" my={2}>
        <Checkbox
          title="Toggle here to setup testing companies."
          checked={testingOn}
          onToggleCheckbox={toggleTestingOn}
          disabled={false}
        />
      </Box>
    </Fragment>
  )
}

export default EmptyBusinessesStep
