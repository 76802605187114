import type { GridProps, GridSpacing } from '@material-ui/core'
import { Box, FormLabel, Grid, Typography } from '@material-ui/core'
import type { ReactNode } from 'react'

import styles from './FormBlocks.module.css'

export const FormSection = ({
  children,
  title = '',
  spacing = 4,
  ...props
}: {
  children: ReactNode
  spacing?: GridSpacing
  title: string
}) => (
  <section className={styles.section}>
    <span>{}</span>
    {title && <h3 className={styles.sectionHeader}>{title}</h3>}
    <Grid container spacing={spacing} alignItems="stretch" justify="flex-end" {...props}>
      {children}
    </Grid>
  </section>
)

export const FormItem = ({
  children,
  label = '',
  highlighted,
  ...props
}: {
  children: ReactNode
  label: string
  highlighted?: boolean
} & GridProps) => (
  <Grid className={styles.item} item xs {...props}>
    <Box mb={1}>
      <FormLabel>
        <Typography noWrap className={highlighted ? styles.highlighted : ''}>
          {label}
        </Typography>
      </FormLabel>
    </Box>
    {children}
  </Grid>
)
