import { Component, Fragment } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { PATHS } from '../../constants/routes'
// import AccountCartSmall from '../accountCartSmall/AccountCartSmall'
import type { ActivityRequestType, BusinessType } from '../../../../src/types/business'
import type { SuperAdminType } from '../../../../src/types/common'
import { HIRED_FROM_ADS_FEE } from '../../constants/fees'
import { getRecentBusinessRequests } from '../../staffers/api/getters/business'
import { connectFirestore } from '../../staffers/qman/connectFirestore'
import MenuButton from './MenuButton'
import MenuLowerButtons from './MenuLowerButtons'
import styles from './menu.module.css'

// left toggle functionality in like this as I do not know yet how it will interact
// while integrated to navigation will function differently with navigation
// also styles will be changed after we have more specific documentation

type Props = {
  isSuperAdmin: boolean
  // TODO: Change to record (or maybe some even better type) once we update all of web
  recentBusinessRequests?: Array<ActivityRequestType> // Record<string, string>[],
  superAdmin?: SuperAdminType
  business?: BusinessType
  selectedCountry: string
} & RouteComponentProps

type State = {
  businessId: string | null
  forceButtonRemount: boolean // In case some prop in connectFirestore changes
  lastSearchPath: string
}

class Menu extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const currentLocation = props.location && props.location.pathname
    const businessId = currentLocation.includes('/companies/') ? currentLocation.replace('/companies/', '') : null
    this.state = {
      businessId,
      forceButtonRemount: false,
      lastSearchPath: PATHS.staffersSearch,
    }
  }

  componentDidMount(): void {
    const { location, history } = this.props
    const currentLocation = location?.pathname || ''
    if (currentLocation === '/' || currentLocation === '') {
      history.replace('/jobs')
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { location } = this.props
    const currentLocation = location && location.pathname
    const currentBusinessId = currentLocation.includes('/companies/')
      ? currentLocation.replace('/companies/', '')
      : null
    const prevLocation = prevProps.location && prevProps.location.pathname
    const prevBusinessId = prevLocation.includes('/companies/') ? prevLocation.replace('/companies/', '') : null
    // Dirty hack because if we change props, connectFirestore doesn't update for some reason
    // when we change businessId by route change, we change forceButtonRemount to true, and
    // set it to false on callback (so this component remounts)
    if (currentLocation !== prevLocation) {
      console.log('location.pathname', location.pathname)
      const lastSearchPath = [PATHS.staffersSearch, PATHS.companiesSearch].includes(location.pathname)
        ? location.pathname
        : this.state.lastSearchPath
      this.setState({
        lastSearchPath,
      })

      if (currentBusinessId !== prevBusinessId) {
        // Since we do it after we check whether route changed, this is pretty safe :)
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(
          {
            lastSearchPath,
            businessId: currentBusinessId,
            forceButtonRemount: true,
          },
          () =>
            this.setState({
              forceButtonRemount: false,
            })
        )
      }
    }
  }

  render() {
    const { location, isSuperAdmin, recentBusinessRequests, superAdmin, business, selectedCountry } = this.props
    const currentLocation = location && location.pathname
    const recentBusinessRequestCount = recentBusinessRequests ? recentBusinessRequests.length : 0
    const { businessId, forceButtonRemount } = this.state
    return (
      <Fragment>
        <div className={styles.container}>
          {isSuperAdmin && (
            <MenuButton
              isSelected={
                currentLocation.startsWith(PATHS.companiesSearch) || currentLocation.startsWith(PATHS.staffersSearch)
              }
              title="Search"
              routeTo={this.state.lastSearchPath}
            />
          )}
          {isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.payrolls)}
              title="Payrolls"
              routeTo={PATHS.payrolls}
            />
          )}
          {isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.unresolvedShifts)}
              title="Unresolved shifts"
              routeTo={PATHS.unresolvedShifts}
            />
          )}
          {!isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.companyProfile)}
              title="Company profile"
              routeTo={PATHS.companyProfile}
            />
          )}
          {isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.companyRequests)}
              title={
                recentBusinessRequestCount > 0 ? `Company requests (${recentBusinessRequestCount})` : 'Company requests'
              }
              routeTo={PATHS.companyRequests}
            />
          )}
          {isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.companies) && !currentLocation.includes(PATHS.companiesSearch)}
              title="Companies"
              routeTo={PATHS.companies}
            />
          )}
          {isSuperAdmin && (
            <MenuButton isSelected={currentLocation.includes(PATHS.groups)} title="Groups" routeTo={PATHS.groups} />
          )}
          {!isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.contracts)}
              title="Contracts"
              routeTo={PATHS.contracts}
            />
          )}
          {isSuperAdmin && (
            <MenuButton isSelected={currentLocation.includes(PATHS.regions)} title="Regions" routeTo={PATHS.regions} />
          )}
          {isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.companyPaymentCycles)}
              title="First payment cycles"
              routeTo={PATHS.companyPaymentCycles}
            />
          )}
          {isSuperAdmin && (
            <MenuButton isSelected={currentLocation.includes(PATHS.reports)} title="Reports" routeTo={PATHS.reports} />
          )}
          {isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.stafferRequests)}
              title="Staffer requests"
              routeTo={PATHS.stafferRequests}
            />
          )}
          <MenuButton
            isSelected={currentLocation.includes(PATHS.staffers) && !currentLocation.includes(PATHS.staffersSearch)}
            title="Staffers"
            routeTo={PATHS.staffers}
          />
          <MenuButton
            isSelected={currentLocation.includes(PATHS.jobs) || currentLocation.includes(PATHS.jobAds)}
            title="Jobs"
            routeTo={PATHS.jobs}
          />
          {isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.integrations)}
              title="Integrations"
              routeTo={PATHS.integrations}
            />
          )}
          {!isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.payments)}
              title="Salary information"
              routeTo={PATHS.payments}
            />
          )}
          {isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.errorLogs)}
              title="Error Logs"
              routeTo={PATHS.errorLogs}
            />
          )}
          {isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.inviteCodes)}
              title="Invite Codes"
              routeTo={PATHS.inviteCodes}
            />
          )}
          {isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.notifications)}
              title="Notifications"
              routeTo={PATHS.notifications}
            />
          )}
          {isSuperAdmin && superAdmin && superAdmin.backOfficeAccess && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.ambassadors)}
              title="Ambassadors"
              routeTo={PATHS.ambassadors}
            />
          )}
          {isSuperAdmin && superAdmin && superAdmin.backOfficeAccess && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.referrals)}
              title="Referrals"
              routeTo={PATHS.referrals}
            />
          )}
          <MenuButton isSelected={currentLocation.includes(PATHS.settings)} title="Settings" routeTo={PATHS.settings} />
          {!isSuperAdmin && (
            <MenuButton
              isSelected={currentLocation.includes(PATHS.managers)}
              title="Managers"
              routeTo={PATHS.managers}
            />
          )}
        </div>
        {
          // Dirty hack because if we change props, connectFirestore doesn't update for some reason
          // when we change businessId by route change, we change forceButtonRemount to true, and
          // set it to false on callback (so this component remounts)
          !forceButtonRemount && (
            <MenuLowerButtons
              // @ts-ignore property does exist - weird typescript error
              currentLocation={currentLocation}
              isSuperAdmin={isSuperAdmin}
              superAdmin={superAdmin}
              business={business}
              businessId={businessId}
              selectedCountry={selectedCountry}
            />
          )
        }
        {!isSuperAdmin && (currentLocation.includes(PATHS.payments) || currentLocation.includes(PATHS.jobs)) && (
          <div className={styles.messageContainer}>
            <div className={styles.messageText}>
              {`Staffers AS takes care of finding and recruiting qualified people to fill your shifts. If you would like to hire a staffer for a permanent/temporary position outside the platform, Staffers AS charges a fee of ${HIRED_FROM_ADS_FEE} NOK if the hire is reported within 1 month of the hire.`}
            </div>
            <br />
            <div className={styles.messageImportant}>
              {'If you fail to report the hire within 1 month, '}
              Staffers AS has the right to invoice 10000 NOK as a penalty.
            </div>
            <br />
          </div>
        )}
      </Fragment>
    )
  }
}

export default withRouter(
  connectFirestore((db, { isSuperAdmin }: Props) => {
    if (isSuperAdmin) {
      return {
        recentBusinessRequests: getRecentBusinessRequests(),
      }
    }
    return {}
  }, Menu)
)
