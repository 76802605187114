import { Box, Button } from '@material-ui/core'
import React, { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import Modal from '../mui/Modal'
import styles from './modals.module.css'

const modalRef = React.createRef() as React.MutableRefObject<WrappedOptionPickerModal | null>

type Option = {
  name: string
  value: any
}

type Props = {
  title: string
  additionalTextLines?: Array<string>
  options: Option[]
  isLoading: boolean
  onPick: (val: any) => any
}

const OptionPickerModal = (props: Props) => {
  const { isLoading, onPick, title, additionalTextLines, options } = props

  if (isLoading) {
    return <BeatLoader />
  }

  return (
    <div className={styles.confirmationModalWrapper}>
      <h1 className={styles.removeHeader}>{title}</h1>
      {additionalTextLines && additionalTextLines.map((line, i) => <p key={i}>{line}</p>)}
      <Box display="flex" justifyContent="center" flexDirection="column">
        {options.map(({ name, value }) => (
          <Box p={1} key={name}>
            <Button
              fullWidth
              onClick={() => {
                onPick(value)
              }}
              variant="outlined"
              color="default"
            >
              {name}
            </Button>
          </Box>
        ))}
      </Box>
    </div>
  )
}

// Folowing components allow alternative simplified self-contained usage
// eg:
// import { pick } from '../OptionPickerModal'
// ...
// const fruit = await pick('What you want to eat?', [
//  { value: 'apple', name: '🍏'},
//  { value: 'banana', name: '🍌'},
// ])

type State = {
  isModalOpen: boolean
  title: string
  text: Array<string>
  options: Option[]
}

class WrappedOptionPickerModal extends Component<{ ref: React.RefCallback<WrappedOptionPickerModal> }, State> {
  promiseInfo: { resolve: any; reject: any } = {
    resolve: () => {},
    reject: () => {},
  }

  state = {
    isModalOpen: false,
    title: '',
    text: [],
    options: [{ name: 'nothing', value: null }],
  }

  show = async (title = 'Pick', text: string[], options: Option[]) =>
    new Promise((resolve, reject) => {
      this.promiseInfo = {
        resolve,
        reject,
      }
      this.setState({
        isModalOpen: true,
        title,
        text,
        options,
      })
    })

  onPick = (selectedValue: any) => {
    const { resolve } = this.promiseInfo
    if (resolve) {
      resolve(selectedValue)
    }
    this.setState({ isModalOpen: false })
  }

  render() {
    const { isModalOpen, title, text, options } = this.state

    return (
      <Modal
        zIndex={5} // make it on top of any other modal
        isOpen={isModalOpen}
        contentLabel="Option picker modal"
      >
        <OptionPickerModal
          onPick={this.onPick}
          title={title}
          options={options}
          additionalTextLines={text}
          isLoading={false}
        />
      </Modal>
    )
  }
}

const pick = async (title: string, text: Array<string>, options: Option[]): Promise<any> => {
  if (!modalRef.current) {
    return null
  }
  if (options.length === 0) {
    return null
  }
  const modal = modalRef.current as WrappedOptionPickerModal
  return modal.show(title, text, options) as Promise<boolean>
}

const ModalWithRef = () => (
  <WrappedOptionPickerModal
    ref={(ref) => {
      modalRef.current = ref
    }}
  />
)

export default OptionPickerModal
export { ModalWithRef as OptionPicker, pick }
