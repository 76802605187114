import * as R from 'ramda'
import type { ChangeEvent } from 'react'
import { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import type { EnumJobType } from '../../../../src/types/jobTypes'
import { errorToast } from '../../helpers/toast'
import { firestoreHttpsCallable } from '../../staffers/api/firestore/https/util'
import { getJobTypes } from '../../staffers/api/getters/common'
import { connectFirestore } from '../../staffers/qman/connectFirestore'
import Checkbox from '../checkbox/CustomCheckbox'
import TextField from '../textInputField/TextInputField'
import styles from './modals.module.css'

type Props = {
  jobTypes?: { values: EnumJobType[] } | null
  closeModal: () => any
}

type State = {
  isProcessing: boolean
  emailError: string
  email: string
  phoneError: string
  phonePrefix: string
  phone: string
  positionsFetched: boolean
  positions: string[]
}

class InviteStaffer extends Component<Props, State> {
  state = {
    isProcessing: false,
    emailError: '',
    email: '',
    phoneError: '',
    phonePrefix: '+47',
    phone: '',
    // eslint-disable-next-line react/no-unused-state
    positionsFetched: false,
    positions: [] as string[],
  }

  static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
    const { positionsFetched } = state
    const { jobTypes } = props

    if (jobTypes && !positionsFetched) {
      return {
        positionsFetched: true,
        positions: jobTypes.values.filter((type) => type.skillCategory === 1).map((type) => type.name),
      }
    }

    return null
  }

  render() {
    const { jobTypes, closeModal } = this.props

    const { email, phone, emailError, phoneError, phonePrefix, isProcessing } = this.state

    if (isProcessing || !jobTypes) {
      return (
        <div className={styles.loaderWrapper}>
          <BeatLoader />
        </div>
      )
    }

    const allTypes = R.sort((type1, type2) => type1.name.localeCompare(type2.name), jobTypes.values)
    const allTypesLeft = allTypes.filter((_, index) => index % 2 === 0)
    const allTypesRight = allTypes.filter((_, index) => index % 2 === 1)

    return (
      <div>
        <h1 className={styles.textCenter}>Invite staffer</h1>
        <div className={styles.columns}>
          <div className={styles.columnLeft}>
            <h2>Email</h2>
            <TextField
              type="text"
              inputProps={{ maxLength: 40 }}
              className={styles.descriptionTextArea}
              placeholder="Enter staffer's email"
              onChange={this.onEmailChange}
              helperText={emailError}
              error={!!emailError}
              value={email}
            />
          </div>
          <div className={styles.columnRight}>
            <h2>Phone number</h2>
            <TextField
              className={styles.descriptionTextArea}
              type="text"
              inputProps={{ maxLength: 20 }}
              onChange={this.onPhoneChange}
              placeholder=" 00 00 00 00"
              helperText={phoneError}
              error={!!phoneError}
              prefix={phonePrefix}
              value={phone}
            />
          </div>
        </div>
        <br />
        <h2>Positions</h2>
        <div className={styles.columns}>
          <div className={styles.columnLeft}>
            {allTypesLeft.map((jobType) => (
              <div key={jobType.name}>
                <Checkbox
                  checked={this.isAllowedPosition(jobType)}
                  onToggleCheckbox={() => this.onTogglePosition(jobType)}
                  title={jobType.name}
                />
              </div>
            ))}
          </div>
          <div className={styles.columnRight}>
            {allTypesRight.map((jobType) => (
              <div key={jobType.name}>
                <Checkbox
                  checked={this.isAllowedPosition(jobType)}
                  onToggleCheckbox={() => this.onTogglePosition(jobType)}
                  title={jobType.name}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <button type="button" className={styles.buttonClose} disabled={isProcessing} onClick={closeModal}>
            Close
          </button>
          <button
            type="button"
            className={styles.buttonSave}
            disabled={isProcessing || !this.isFormValid()}
            onClick={this.sendInvite}
          >
            Send invite
          </button>
        </div>
      </div>
    )
  }

  isAllowedPosition = (jobType: EnumJobType): boolean => {
    const { positions } = this.state
    return positions.includes(jobType.name)
  }

  onTogglePosition = (jobType: EnumJobType) => {
    const { positions } = this.state
    if (this.isAllowedPosition(jobType)) {
      this.setState({
        positions: positions.filter((position) => position !== jobType.name),
      })
    } else {
      this.setState({
        positions: [...positions, jobType.name],
      })
    }
  }

  onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      phoneError: this.getPhoneError(e.target.value),
      phone: e.target.value,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getPhoneError = (phone: string) => {
    if (!phone) {
      return ''
    }

    if (!phone.match(/^[0-9\s]+$/)) {
      return 'This number contains invalid characters.'
    }

    if (phone.replace(/\s/g, '').length < 8) {
      return 'This number is too short.'
    }

    if (phone.replace(/\s/g, '').length > 8) {
      return 'This number is too long.'
    }

    return ''
  }

  onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      emailError: this.getEmailError(event.target.value),
      email: event.target.value,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getEmailError = (email: string) => {
    if (!email) {
      return ''
    }

    if (!email.trim().match(/^\S+@([-a-zA-Z0-9]+\.)+[a-zA-Z]+$/)) {
      return 'This email is invalid.'
    }

    return ''
  }

  isFormValid = (): boolean => {
    const { email, phone } = this.state
    return !!(email || phone) && !this.getEmailError(email) && !this.getPhoneError(phone)
  }

  sendInvite = async () => {
    const { closeModal } = this.props
    // eslint-disable-next-line object-curly-newline
    const { email, phone, phonePrefix, positions, isProcessing } = this.state
    if (this.isFormValid() && !isProcessing) {
      try {
        this.setState({ isProcessing: true })
        await firestoreHttpsCallable('inviteStaffer', {
          phone: `${phonePrefix}${phone}`.replace(/\s/g, ''),
          email: email.trim(),
          positions,
        })
        toast.success('Invitation sent successfully.')
        await closeModal()
      } catch (error) {
        errorToast((error as Error)?.message)
        console.warn(error)
      } finally {
        this.setState({ isProcessing: false })
      }
    }
  }
}

export default connectFirestore(
  () => ({
    jobTypes: getJobTypes(),
  }),
  InviteStaffer
)
