import { Box, Grid, TextField, Typography } from '@material-ui/core'
import type { ChangeEvent } from 'react'
import 'react-phone-input-2/lib/bootstrap.css'

type Props = {
  emailError: string
  email: string
  getEmailError: (value: string, check: boolean) => void
  onEmailChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const InviteManager = (props: Props) => {
  const { email, emailError, getEmailError, onEmailChange } = props

  return (
    <Box mt={3}>
      <Box mb={1}>
        <Typography variant="h2">Invite non registered group manager</Typography>
      </Box>
      <Grid item xs={6}>
        <TextField
          type="text"
          label="Email"
          fullWidth
          variant="outlined"
          onChange={onEmailChange}
          onBlur={() => getEmailError(email, true)}
          inputProps={{ maxLength: 80 }}
          placeholder={"Enter manager's email."}
          helperText={emailError}
          error={!!emailError}
          value={email}
        />
      </Grid>
    </Box>
  )
}

export default InviteManager
