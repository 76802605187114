import React from 'react'
import { BeatLoader } from 'react-spinners'
import type { BusinessType } from '../../../../src/types/business'
import type { JobAdType } from '../../../../src/types/jobAds'
import { jobsUrl } from '../../config'
import ModalHeader from '../mui/ModalHeader'
import styles from './JobAdPreview.module.css'

type Props = {
  business: BusinessType
  jobAd: Partial<JobAdType>
  imageBlobs: (Blob | string)[] | null
  coverImageBlob: Blob | null
  togglePreview: () => any
}

type State = {
  isLoading: boolean
}
class JobAdPreview extends React.Component<Props, State> {
  state = {
    isLoading: true,
  }

  onMsg = (event: MessageEvent) => {
    const { jobAd, business, imageBlobs, coverImageBlob } = this.props
    const ENDPOINT: string = jobsUrl
    if (event.origin !== ENDPOINT) {
      return
    }
    if (event.data?.getJobAdPreviewData) {
      // job ad data vas requested from the frame
      const jobAdPreviewData = {
        jobAd,
        imageBlobs,
        coverImageBlob,
        business,
      }
      // @ts-ignore
      event.source?.postMessage({ jobAdPreviewData }, ENDPOINT)
    }
  }

  componentDidMount = async () => {
    global.addEventListener('message', this.onMsg)
  }

  componentWillUnmount = () => {
    global.removeEventListener('message', this.onMsg)
  }

  render() {
    const { togglePreview } = this.props
    const { isLoading } = this.state

    const language = 'en'

    const previewUrl = `${jobsUrl}/${language}/job-ads/_PREVIEW_`

    return (
      <React.Fragment>
        <ModalHeader goBack={togglePreview}>Post a Job Ad - Preview</ModalHeader>
        <div className={styles.wrapper}>
          {isLoading && (
            <div className={styles.loaderWrapper}>
              <BeatLoader />
            </div>
          )}
          <iframe
            className={isLoading ? styles.frame : styles.frameLoaded}
            title="Job ad Preview"
            src={previewUrl}
            onLoad={() => this.setState({ isLoading: false })}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default JobAdPreview
