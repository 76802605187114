// (non freelance) work agreement for Norway region (both English and Norwegian language)
import { Typography } from '@material-ui/core'
import { Fragment } from 'react'
import style from '../modals/modals.module.css'

type Values = {
  jobType: string
  registeredName?: string
  businessName: string
  orgNumber: string
  businessAddress: string
  stafferNameFull: string
  identityNumber: string
  jobDateStart: string
  jobDateEnd: string
  jobHourlyWage: string
  employmentReason: string
  businessPayoutDate: string
}

const English = ({
  jobType,
  registeredName,
  businessName,
  orgNumber,
  businessAddress,
  stafferNameFull,
  identityNumber,
  jobDateStart,
  jobDateEnd,
  jobHourlyWage,
  employmentReason,
  businessPayoutDate,
}: Values) => (
  <Fragment>
    <Typography component="h6" variant="h6" className={style.agreementHeader}>
      Contract of temporary employment
    </Typography>
    <div className={style.agreementWrapper}>
      <p>
        The employment contract is entered this day between:
        <br />
        <br />
        The Employer as stated in <strong>Appendix 1</strong> and
        <br />
        <br />
        The Employee as stated in <strong>Appendix 2</strong> and
        <br />
        <br />
        in accordance with the at all time applicable salary and working conditions set out in{' '}
        <strong>Appendix 3</strong>
        .
        <br />
        <br />
        This employment contract, when completed with the Appendix 1, Appendix 2 and Appendix 3, is in compliance with
        the Norwegian Working Environment Act.
        <br />
        <br />
        <strong>1. Employment and start of the employment</strong>
        <br />
        <br />
        The Employee’s position and date of commencement of the employment and presumed duration of the assignment are
        as stated in Appendix 3.
        <br />
        <br />
        The employment ends without prior notice at latest the date of termination. See Appendix 3.
        <br />
        <br />
        The legal basis for temporary employment is the Working Environment Act § 14-9 section one as stated in the
        Appendix 3.
        <br />
        <br />
        Agreement on temporary employment is entered by the parties due to the following reasons:
        <br />
        <br />
        - The work is of a temporary nature according to WEA § 14-9 (1)letter a (seasonal work, seasonal variations
        etc.)
        <br />
        - Work as a temporary replacement for another person or persons according to WEA § 14-9 (1) letter b (sickness,
        leave of absence, holidays etc.)
        <br />
        The agreement lasts for a maximum period of twelve months. Such agreements may apply to a maximum of 15 percent
        of the employees of the company, rounded off upwards, according to WEA § 14-9 (1) letter f.
        <br />
        <br />
        If an employee who is temporarily hired under this clause is not offered a continued contract of employment by
        the end of the agreement period, the employer shall be subjected to a quarantine period of twelve months in
        according to WEA § 14-9 (7).
        <br />
        <br />
        Unless otherwise agreed in writing or regulated in a collective agreement, contracts of temporary employment
        shall expire by the end of the agreed period or when the specific work is completed. During the agreement
        period, the provisions of the WEA concerning termination of employment shall apply. This is according to WEA §
        14-9 (5).
        <br />
        <br />
        If the position is part-time, this will be stated in Appendix 3.
        <br />
        <br />
        <strong>2. Job description</strong>
        <br />
        <br />
        The Employee shall at all time perform the applicable duties and work tasks that are part of the position.
        <br />
        <br />
        The Employer can prepare a job instruction, routines for the work etc.
        <br />
        <br />
        The Employer has the right to make changes in work tasks, job title and areas of responsibilities when this is
        justified on the Employer’s needs or can be related to circumstances on the part of the employee.
        <br />
        <br />
        <strong>3. Place of work</strong>
        <br />
        <br />
        The place of work is currently at The Employer’s office in which the address is as stated in Appendix 1. The
        employee must be prepared to travel if the position requires it.
        <br />
        <br />
        If there is no permanent place of work, the main meeting place will be stated in Appendix 3.
        <br />
        <br />
        The employee works in different locations. It is stated in Appendix 3 where the Employee has a permanent meeting
        point. The employer&#39;s business address is stated in Appendix 1.
        <br />
        <br />
        <strong>4. Working hours</strong>
        <br />
        <br />
        Working hours and overtime are regulated by the applicable terms and conditions as set out in Appendix 3.
        <br />
        <br />
        <strong>5. Salary and other remuneration</strong>
        <br />
        <br />
        Salary and other remuneration are as set out in Appendix 3.
        <br />
        <br />
        <strong>6. Deduction in salary</strong>
        <br />
        <br />
        In the event of any error in the payment of salary, bonus, commission, holiday allowance, travel allowance,
        etc., the Employer may make necessary deductions from, or adjustments to, any payments made after the error has
        been discovered. Such deductions may also be made in respect of any loan payments due to, or goods purchased
        from, the firm. Besides, deductions may be made in accordance with Section 14-15 of the Working Environment Act.
        <br />
        <br />
        <strong>7. Pension and insurances</strong>
        <br />
        <br />
        The Employee is covered by the Employer’s at all time applicable pension and insurance coverages as long as the
        Employee is qualified for this. The Employer is free to change or dismantle those coverages within the
        boundaries of the applicable law.
        <br />
        <br />
        <strong>8. Holiday leave and holiday pay</strong>
        <br />
        <br />
        The right to holidays and holiday allowance, as well as the provisions for the determination of holiday periods,
        is governed by the Annual Holidays Act.
        <br />
        <br />
        <strong>9. Probationary period</strong>
        <br />
        <br />
        In the event it is agreed that the Employee is to serve a probationary period, the duration and mutual period of
        notice are as stated in Appendix 3.
        <br />
        <br />
        If the Employee has been absent from work during the probationary period, the Employer can extend the agreed
        probationary period with a period corresponding to the duration of such absence. The Employer shall in such case
        inform the Employee in writing of such extension prior to the expiry of the probationary period. The
        probationary period shall not be extended on the basis of any absence caused by circumstances on the part of the
        Employer.
        <br />
        <br />
        <strong>10. Termination of employment</strong>
        <br />
        <br />
        The Employment ends without prior notice at the end of the contract period. See section 1 and Appendix 3.
        <br />
        <br />
        During the employment and after the probationary period a mutual notice period is in accordance with Appendix 3,
        calculated from the end of the month to which the notice is given, unless a longer notice period is given in
        accordance with the Working Environment law.
        <br />
        <br />
        Upon resignation, the Employee shall return/hand over all materials that belong to the Employer. This includes
        documents and other materials that can be stored electronically.
        <br />
        <br />
        <strong>11. Confidentiality</strong>
        <br />
        <br />
        The Employee undertakes to refrain from disclosing, as well as to prevent others from gaining access to, or
        knowledge of, confidential information, unless otherwise stipulated in specific legislation.
        <br />
        <br />
        By confidential information is meant all information which has come to the knowledge of the Employee, which is
        not in the public domain, and which the Employee ought to understand, based on a prudent assessment, that it may
        be of importance for the Employer to keep confidential.
        <br />
        <br />
        No form of reproduction and/or copying of business-related documents for private use is permitted without the
        permission of superior officers representing the Employer.
        <br />
        <br />
        The duty of confidentiality shall remain in full force and effect after termination of the employment. Nor shall
        the Employee use or exploit any information falling within the scope of the duty of confidentiality in his/her
        own business or in any service or work performed for others.
        <br />
        <br />
        <strong>12. Collective agreement</strong>
        <br />
        <br />
        In the event collective agreement should applies, please see attachment 3.
        <br />
        <br />
        <strong>Appendix 1</strong>
        <br />
        <br />
        &lt;The employer&gt; <strong>{registeredName || businessName}</strong>
        <br />
        &lt;Org. no.&gt; <strong>{orgNumber}</strong>
        <br />
        &lt;Address&gt; <strong>{businessAddress}</strong>
        <br />
        &lt;Reason for employment&gt; <strong>{employmentReason}</strong>
        <br />
        &lt;Salary date&gt; <strong>{businessPayoutDate}</strong>
        <br />
        <br />
        <strong>Appendix 2</strong>
        <br />
        <br />
        &lt;The employee&gt; <strong>{stafferNameFull}</strong>
        <br />
        &lt;Person no.&gt; <strong>{identityNumber}</strong>
        <br />
        <br />
        <strong>Appendix 3</strong>
        <br />
        <br />
        Job information:
        <br />
        &lt;Location&gt; <strong>{businessAddress}</strong>
        <br />
        &lt;Start time&gt; <strong>{jobDateStart}</strong>
        <br />
        &lt;End time&gt; <strong>{jobDateEnd}</strong>
        <br />
        &lt;Wage&gt; <strong>{jobHourlyWage}</strong>
        <br />
        &lt;Position&gt; <strong>{jobType}</strong>
      </p>
    </div>
  </Fragment>
)

const Norwegian = ({
  jobType,
  registeredName,
  businessName,
  orgNumber,
  businessAddress,
  stafferNameFull,
  identityNumber,
  jobDateStart,
  jobDateEnd,
  jobHourlyWage,
  employmentReason,
  businessPayoutDate,
}: Values) => (
  <Fragment>
    <Typography component="h6" variant="h6" className={style.agreementHeader}>
      ARBEIDSAVTALE FOR MIDLERTIDIG ANSETTELSE
    </Typography>
    <div className={style.agreementWrapper}>
      <p>
        Denne avtale <strong>(Avtalen)</strong> er inngått den dato mellom
        <br />
        Arbeidsgiver som fremgår av vedlegg 1 og
        <br />
        Arbeidstaker som fremgår av vedlegg 2 og
        <br />
        i henhold til enhver tid gjeldende lønns- og arbeidsvilkår som fremgår av vedlegg 3.
        <br />
        Denne avtalen, når vedlegg 1, vedlegg 2 og vedlegg 3 er utfylt og vedlagt, er utarbeidet i samsvar med
        arbeidsmiljøloven.
        <br />
        <br />
        <strong>Ansettelse og tiltredelse</strong>
        <br />
        Arbeidstakers stilling og tiltredelsesdato og oppdragets antatte varighet vil fremgå av vedlegg 3.
        <br />
        Arbeidsforholdet opphører uten oppsigelse senest den opphørsdato. Se vedlegg 3.
        <br />
        Avtale om midlertidig ansettelse er inngått fordi: — Velg aktuelle grunnlag – nedtrekksmeny?
        <br />
        <br />
        - Arbeidet er av midlertidig karakter jf arbeidsmiljøloven § 14-9 (1) bokstav a( eks sesongmessig arbeid,
        sesongmessige svingninger osv)
        <br />
        - Arbeider i stedet for en annen eller andre(vikariat) jf arbeidsmiljøloven § 14-9 (1) bokstav b(eks sykdom,
        permisjon, ferieavvikling osv)
        <br />
        Oppdraget gjelder for en periode på inntil tolv måneder. Slike avtaler kan omfatte inntil 15 prosent av
        arbeidstakerne i virksomheten, avrundet oppover jf arbeidsmiljøloven § 14-9 (1) bokstav
        <br />
        Når en arbeidstaker som er midlertidig ansatt under dette vilkåret, ikke får videre ansettelse ved
        avtaleperiodens utløp, starter en karanteneperiode på tolv måneder for arbeidsgiveren jf arbeidsmiljøloven §
        14-9 (7).
        <br />
        Denne avtalen opphører ved det avtalte tidsrommets utløp, eller når det bestemte arbeidet er avsluttet, med
        mindre noe annet er skriftlig avtalt eller fastsatt i tariffavtale. I avtaleperioden gjelder lovens regler om
        opphør av arbeidsforhold jf arbeidsmiljøloven § 14-9 (5).
        <br />
        <em>Eventuell angivelse av deltid vil fremgå av vedlegg 3.</em>
        <br />
        <br />
        <strong>Arbeidsoppgaver</strong>
        <br />
        Arbeidstaker skal utføre de ansvars- og arbeidsoppgaver som til enhver tid hører inn under stillingen.
        Arbeidsgiver kan utarbeide en stillingsinstruks, rutiner for arbeidet m.v.
        <br />
        Arbeidsgiver har rett til å foreta endringer i arbeidsoppgaver, stillingsbetegnelse og ansvarsområde når dette
        er begrunnet i virksomhetens behov eller arbeidstakers forhold.
        <br />
        <br />
        <strong>Arbeidssted</strong>
        <br />
        Arbeidsstedet er p.t. Arbeidsgivers lokaler i adresse som fremgår av vedlegg 1. Arbeidstaker må påregne reising
        i arbeidet i den grad stillingen krever det.
        <br />
        Dersom arbeidstaker ikke har fast arbeidssted vil det fremgå av vedlegg 3 hvor arbeidstaker skal ha sitt faste
        oppmøtested.
        <br />
        <br />
        Arbeidstaker arbeider på forskjellige steder. Det fremgår av vedlegg 3 hvor Arbeidstaker har fast oppmøtested.
        Arbeidsgivers forretningsadresse fremgår av vedlegg 1.
        <br />
        <br />
        <strong>Arbeidstid</strong>
        <br />
        Arbeidstid og overtid reguleres i henhold til de til enhver tid gjeldende regler som fremgår av vedlegg 3.
        <br />
        <br />
        <strong>Lønn og andre godtgjørelser</strong>
        <br />
        Lønn og andre godtgjørelser fremgår av vedlegg 3.
        <br />
        <br />
        <strong>Trekk i lønn</strong>
        <br />
        Dersom det blir utbetalt feil i lønn, bonus, provisjon, feriepenger, reisepenger eller lignende, kan
        Arbeidsgiver foreta nødvendig trekk eller justering ved utbetalinger etter at feilen ble oppdaget. Slikt trekk
        kan også gjøres for forfalte lån eller varekjøp fra bedriften. For øvrig gjelder den trekkadgang som følger av
        arbeidsmiljøloven § 14-15.
        <br />
        <br />
        <strong>Pensjons- og forsikringsordninger</strong>
        <br />
        Arbeidstaker er omfattet av Arbeidsgivers til enhver tid gjeldende pensjons- og forsikringsordninger så fremt
        Arbeidstaker kvalifiserer for dette. Arbeidsgiver står fritt til å endre, herunder avvikle, disse ordningene,
        innenfor de begrensninger som følger av lovgivningen.
        <br />
        <br />
        <strong>Ferie og feriepenger</strong>
        <br />
        Retten til ferie og feriepenger og reglene for fastsettelse av ferietidspunktet følger ferielovens bestemmelser.
        <br />
        <br />
        <strong>Prøvetid</strong>
        <br />
        Dersom det er avtalt prøvetid vil varighet og gjensidig oppsigelsestid i prøvetiden fremgå av vedlegg 3.
        <br />
        Dersom Arbeidstaker har vært fraværende fra arbeidet i prøvetiden, kan Arbeidsgiver forlenge den avtalte
        prøvetiden med en periode som tilsvarer lengden av fraværet. Arbeidsgiver må i tilfelle orientere Arbeidstaker
        skriftlig om forlengelsen innen utløpet av prøvetiden. Det er ikke adgang til forlengelse ved fravær som er
        forårsaket av arbeidsgiver.
        <br />
        <br />
        <br />
        <strong>1. Opphør av arbeidsforholdet - Oppsigelse</strong>
        <br />
        Arbeidsforholdet opphører uten oppsigelse ved utløpet av kontraktsperioden, jf. punkt 1 og Vedlegg 3.
        <br />
        Under arbeidsforholdet og etter utløpet av prøvetiden gjelder en gjensidig oppsigelsesfrist i henhold til
        vedlegg 3, regnet fra og med utløpet av kalendermåneden oppsigelsen fant sted, med mindre lenger frist følger av
        arbeidsmiljølovens regler.
        <br />
        Ved fratredelse skal Arbeidstaker tilbakelevere alt materiale som tilhører Arbeidsgiver. Dette gjelder også for
        dokumenter og annet materiale som er lagret elektronisk.
        <br />
        <br />
        <strong>2. Taushetsplikt og konfidensialitet</strong>
        <br />
        Arbeidstaker forplikter seg til å iaktta taushet og hindre at andre får adgang eller kjennskap til fortrolige
        opplysninger, med mindre annet følger av særskilt lovgivning.
        <br />
        Med fortrolige opplysninger menes all informasjon som Arbeidstaker har fått kjennskap til, som ikke er
        alminnelig kjent, og som Arbeidstaker etter en forsiktig vurdering bør forstå kan ha betydning for Arbeidsgiver
        å hemmeligholde.
        <br />
        Ingen form for reproduksjon og/eller kopiering av virksomhetsrelaterte dokumenter til privat bruk er tillatt
        uten etter tillatelse fra foresatte i Arbeidsgiver.
        <br />
        Taushetsplikten gjelder også etter arbeidsforholdets avslutning. Arbeidstaker kan heller ikke bruke eller
        utnytte opplysninger som er omfattet av taushetsplikten i egen virksomhet eller i tjeneste eller arbeid hos
        andre.
        <br />
        <br />
        <strong>3. Tariffavtaler</strong>
        <br />
        Eventuell tariffavtale som regulerer arbeidsforholdet vil fremgå av vedlegg 3.
        <br />
        <br />
        <strong>Appendix 1</strong>
        <br />
        <br />
        &lt;Arbeidsgiver&gt; <strong>{registeredName || businessName}</strong>
        <br />
        &lt;Org. nr.&gt; <strong>{orgNumber}</strong>
        <br />
        &lt;Adresse&gt; <strong>{businessAddress}</strong>
        <br />
        &lt;Ansettelsesgrunnlag&gt; <strong>{employmentReason}</strong>
        <br />
        &lt;Lønningsdato&gt; <strong>{businessPayoutDate}</strong>
        <br />
        <br />
        <br />
        <strong>Appendix 2</strong>
        <br />
        <br />
        &lt;Arbeidstaker&gt; <strong>{stafferNameFull}</strong>
        <br />
        &lt;Presonnr.&gt; <strong>{identityNumber}</strong>
        <br />
        <br />
        <strong>Appendix 3</strong>
        <br />
        <br />
        Jobb informasjon:
        <br />
        &lt;Jobbsted&gt; <strong>{businessAddress}</strong>
        <br />
        &lt;Starttid&gt; <strong>{jobDateStart}</strong>
        <br />
        &lt;Sluttid&gt; <strong>{jobDateEnd}</strong>
        <br />
        &lt;Lønn&gt; <strong>{jobHourlyWage}</strong>
        <br />
        &lt;Tittel&gt; <strong>{jobType}</strong>
      </p>
    </div>
  </Fragment>
)

export default {
  English,
  Norwegian,
}
