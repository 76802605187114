import { IoIosCheckmark, IoIosClose, IoMdWarning } from 'react-icons/io'
import styles from './button.module.css'

export const ButtonTypes = {
  BUTTON_DEFAULT: 'defaultGrey',
  BUTTON_GREEN: 'defaultMenu',
  BUTTON_RED: 'dangerMenu',
  BUTTON_WARNING: 'warning',
  BUTTON_BLUE: 'primaryBlue',
}

type ButtonTypeKeys = keyof typeof ButtonTypes
type ButtonTypeValues = (typeof ButtonTypes)[ButtonTypeKeys]

type Props = {
  onClick?: any
  disabled?: boolean
  buttonType: ButtonTypeValues
  htmlButtonType?: 'button' | 'submit' | 'reset'
  buttonWrapperStyle?: string
  title: string
  showIcon?: boolean
}

const Button = (props: Props) => {
  const {
    onClick,
    disabled,
    buttonType,
    buttonWrapperStyle,
    title,
    showIcon = false,
    htmlButtonType = 'button',
  } = props

  const renderIcon = (type: string) =>
    ({
      warning: <IoMdWarning />,
      danger: <IoIosClose />,
    })[type] || <IoIosCheckmark />

  return (
    <div className={styles[buttonWrapperStyle || ''] || buttonWrapperStyle}>
      <button
        // eslint-disable-next-line react/button-has-type
        type={htmlButtonType}
        disabled={disabled}
        onClick={onClick}
        className={disabled ? styles.disabled : styles[buttonType]}
      >
        <div className={styles.buttonContainer}>
          {title}
          {showIcon && renderIcon(buttonType)}
        </div>
      </button>
    </div>
  )
}

export default Button
