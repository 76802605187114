import { Button } from '@material-ui/core'
import styles from './StafferCard.module.css'

type DefaultVariant = {
  variant: 'default' | 'invite' | 'offer'
  onAddToShortlist: () => void
  onOfferShift?: never
  onSendInvite?: never
  onSendMessage?: never
}

type InviteVariant = {
  variant: 'default' | 'invite' | 'offer'
  onAddToShortlist?: never
  onOfferShift?: never
  onSendInvite: () => void
  onSendMessage: () => void
}

type OfferVariant = {
  variant: 'default' | 'invite' | 'offer'
  onAddToShortlist?: never
  onOfferShift: () => void
  onSendInvite?: never
  onSendMessage: () => void
}

type Props = DefaultVariant | InviteVariant | OfferVariant

const StafferCardFooter = ({ variant, onAddToShortlist, onOfferShift, onSendInvite, onSendMessage }: Props) => (
  <div className={styles.contentWrapper}>
    {variant === 'default' ? (
      <Button
        variant="outlined"
        color="primary"
        style={{ flex: 1, borderRadius: 8, textTransform: 'unset' }}
        onClick={onAddToShortlist}
      >
        + Add to shortlist
      </Button>
    ) : (
      <div className={styles.buttonsWrapper}>
        <Button
          variant="contained"
          color="primary"
          style={{ flex: 1, marginRight: 6, borderRadius: 8, textTransform: 'unset' }}
          onClick={variant === 'invite' ? onSendInvite : onOfferShift}
        >
          {variant === 'invite' ? 'Send invite' : 'Offer shift'}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ flex: 1, marginLeft: 6, borderRadius: 8, textTransform: 'unset' }}
          onClick={onSendMessage}
        >
          Message
        </Button>
      </div>
    )}
  </div>
)

export default StafferCardFooter
