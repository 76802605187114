import type { SvgIcon } from '@material-ui/core'
import { Add, Home, SupervisorAccount } from '@material-ui/icons'
import type { ReactElement } from 'react'
import type { BusinessType } from '../../../../../src/types/business'
import type { GroupJobTypesType } from '../../../../../src/types/groups'
import type { PoolType } from '../../../../../src/types/pools'
import { GROUP_SIDEBAR_PATHS } from '../../../constants/routes'
import { hasPermission } from '../../../helpers/permissions'

export type IconType = ReactElement<typeof SvgIcon>

/* -------------------------------------------------------------------------- */
/*                        1. Default sidebar item type                        */
/* -------------------------------------------------------------------------- */

export type ItemType = {
  name: string // rendered title + state reference
  icon: IconType // icon of the button
  path?: string // redirect url
  altPaths?: string[] // alternative paths the active item can have
  badge?: number // number shown in the badge (eg. unseen requests)
  collapsibleItems?: ItemType[] // items that can be collapsed
  hidden?: boolean // override, if condition is met does not render
}

/* eslint-disable dot-notation */
/**
 * Generates item objects to help with the render and state
 */
export const getGroupSidebarItems = (): ItemType[] => [
  {
    name: GROUP_SIDEBAR_PATHS['Dashboard'].name,
    icon: <Home />,
    path: GROUP_SIDEBAR_PATHS['Dashboard'].path,
    altPaths: GROUP_SIDEBAR_PATHS['Dashboard'].altPaths,
  },
  {
    name: GROUP_SIDEBAR_PATHS['Positions'].name,
    icon: <SupervisorAccount />,
    path: GROUP_SIDEBAR_PATHS['Positions'].path,
    altPaths: GROUP_SIDEBAR_PATHS['Positions'].altPaths,
  },
]

/* -------------------------------------------------------------------------- */
/*                         2. Action sidebar item type                        */
/* -------------------------------------------------------------------------- */

export type ActionItemType = {
  modalId: string // should match the isModalOpen in Sidebar.js
  name: string // displayed text
  icon?: IconType // Icon to be displayed in collapsed items
  isHighlighted?: boolean
  hidden?: boolean // override - does not render if condition is met
}

export const getActionItems = (
  pool: PoolType | null,
  activeItem: { activeParent: string; activeChild?: string },
  business: BusinessType,
  groupId?: string,
  groupJobTypes?: GroupJobTypesType
): ActionItemType[] => {
  const customPositions = (groupJobTypes && groupJobTypes.values) || []
  return [
    {
      modalId: 'createInternalJob',
      name: 'Create Internal Job',
      icon: <Add color="primary" />,
      isHighlighted: true,
      hidden: !!(
        pool === null ||
        (business.businessType === 'Other' &&
          ((groupId && !customPositions) || (groupId && customPositions && customPositions.length === 0)))
      ),
    },
    // Fake variant of create job - requires you to create position
    // displays when businessType is Other and has no custom position set up
    {
      modalId: 'createCustomPosition',
      name: 'Create Internal Job',
      icon: <Add color="primary" />,
      isHighlighted: true,
      // Do not display the button until the groupJobTypes are fetched (either something or null)
      hidden: !!(
        pool === null ||
        groupJobTypes === undefined ||
        business.businessType !== 'Other' ||
        !groupId ||
        (groupId && customPositions && customPositions.length > 0)
      ),
    },
    {
      modalId: 'createExternalJob',
      name: 'Create External Job',
      icon: <Add color="primary" />,
      isHighlighted: true,
      hidden: !(
        // we dont check 'postTemporaryJob' here, because those no longer can be external
        (hasPermission(business, 'postFreelanceJob') || hasPermission(business, 'postJobAd'))
      ),
    },
    // {
    //   modalId: 'inviteToPool',
    //   name: 'Invite an Employee',
    //   icon: <PersonAdd color="primary" />,
    //   hidden: pool === null || activeItem.activeParent !== 'People',
    // },
  ]
}
