import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { firestoreHttpsCallable } from './https/util'

export const logStafferEvent = async (stafferId: string, message: string) =>
  firebase.firestore().collection('staffers').doc(stafferId).collection('logs').add({
    time: firebase.firestore.FieldValue.serverTimestamp(),
    message,
  })

export const logProfileView = async (data: { stafferId: string; jobId: string }) => {
  firestoreHttpsCallable('onProfileView', { ...data })
}
