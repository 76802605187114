// Detect browser function
export const detectUserBrowser = (): string => {
  if (global && global.navigator && global.navigator.userAgent) {
    const { userAgent, vendor } = global.navigator

    // Detect Chrome
    let chromeAgent = userAgent.indexOf('Chrome') > -1

    // Detect Internet Explorer
    const IExplorerAgent = userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('rv:') > -1

    // Detect Firefox
    const firefoxAgent = userAgent.indexOf('Firefox') > -1

    // Detect Safari
    let safariAgent =
      userAgent.indexOf('Safari') > -1 ||
      (vendor.match(/apple/i) && !userAgent.match(/crios/i) && !userAgent.match(/fxios/i))

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false

    // Detect Opera
    const operaAgent = userAgent.indexOf('OP') > -1
    // Discard Chrome since it also matches Opera
    if (chromeAgent && operaAgent) chromeAgent = false

    if (chromeAgent) return 'Chrome'
    if (IExplorerAgent) return 'IExplorer'
    if (firefoxAgent) return 'Firefox'
    if (safariAgent) return 'Safari'
    if (operaAgent) return 'Opera'
  }
  return ''
}
