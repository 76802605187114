import * as R from 'ramda'

export const applyFormat = (format: string, text: string) => {
  if (!format) return text // No format to apply
  let [i, j] = [0, 0]
  let formattedText = ''
  while (format.charAt(j) && text.charAt(i)) {
    if (format.charAt(j).match(/[a-zA-Z0-9]/)) {
      formattedText += text.charAt(i)
      i += 1
    } else {
      formattedText += format.charAt(j)
    }
    j += 1
  }
  return formattedText
}

export const unapplyFormat = (text: string) => text.replace(/[-\s]/g, '')

export const getCountryPrefix = (country: string) => {
  switch (country) {
    case 'Tsjekkia':
    case 'Czech Republic':
      return '+420'
    case 'France':
      return '+33'
    case 'Norway':
    case 'Norge':
      return '+47'
    case 'Sweden':
    case 'Sverige':
      return '+46'
    default:
      return ''
  }
}

export const getPhoneNumberFormat = (country: string) => {
  switch (country) {
    case 'Tsjekkia':
    case 'Czech Republic':
      return '000 000 000'
    case 'France':
      return '0 00 00 00 00'
    case 'Norway':
    case 'Norge':
      return '00 00 00 00'
    default:
      return '(000) 000 00'
  }
}

export const getPostalCodeFormat = (country: string) => {
  switch (country) {
    case 'Czech Republic':
      return '000 00'
    case 'France':
      return '00000'
    case 'Norway':
    case 'Norge':
      return '0000'
    default:
      return ''
  }
}

export const getOrgNumberFormat = (country: string) => {
  switch (country) {
    case 'Tsjekkia':
    case 'Czech Republic':
      return '0000 0000'
    case 'France':
      return '000 000 000'
    case 'Norway':
    case 'Norge':
      return '000 000 000'
    case 'Sweden':
    case 'Sverige':
      return '000000-0000'
    default:
      return ''
  }
}

export const replaceInvalidOrgNum = (orgNum: string, country: string) => {
  switch (country) {
    case 'Norway': {
      return orgNum.replace(/\D/g, '')
    }
    case 'Sweden': {
      return orgNum.replace(/[^0-9-]/g, '')
    }
    default: {
      return orgNum.replace(/\D/g, '')
    }
  }
}

export const capitalizeFirst = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

export const linguisticJoin = (list: string[], clutch = ' and ', sep = ', ') =>
  [...R.dropLast(2)(list), R.takeLast(2)(list).join(clutch)].join(sep)

// format the number as a locale number with fixed number of decimal places to 2 (for cents)

// formatCurrency(123.5) === '123,50' // adds leading zero
// formatCurrency(123.456) === '123,46' // trim and round
export const formatCurrency = (n: number, locale: 'nn-NO' = 'nn-NO') =>
  n.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

// toCurrencyInt(1234.56, false) === '1 235'
export const formatCurrencyInt = (n: number, locale: 'nn-NO' = 'nn-NO') =>
  n.toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
