import * as R from 'ramda'

type ColorProps = { lightness: number; saturation: number }

// generates literally any random color possible
export const generateRandomColor = (): string =>
  `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`

export const generateColor = (
  from?: number,
  isFromIndex = false,
  { saturation, lightness }: ColorProps = { saturation: 50, lightness: 50 }
): string => {
  if (from !== undefined && isFromIndex) {
    const GOLDEN_RATIO = 1.618
    return `hsl(${(from * 360 * GOLDEN_RATIO) % 360}, ${saturation || 50}%, ${lightness}%)`
  }
  const hue = from ?? Math.floor(Math.random() * 360) + 1
  return `hsl(${hue % 360}, ${saturation || 50}%, ${lightness}%)`
}

export const encodeUIDtoColorHue = (uid: string): number =>
  // just throw some random prime numbers for better distribution of values
  (uid.charCodeAt(0) * 23 + uid.charCodeAt(1) * 109 + uid.charCodeAt(2) * 227) % 360

/**
 * Generates unique colors for each member of passed array
 * @param {string[]} arrayOfIds - Array of elements to generate unique colors for
 * @param {string} mode -
 *  - `web-friendly-global` - Consistent unique global colors through deterministic algorithm
 *  - `web-friendly` - Deterministic on array - based on index
 *  - `web-friendly-random` - Random Web-friendly colors (HSL visible white text),
 *  - `any` - Can generate all possible rgb colors
 * @warning the `web-friendly-global` mode must have passed ids with 4 characters or longer
 * @returns returns an object styled `{ [key: array[index]]: colorString }`
 */
export const generateUniqueColors = (
  arrayOfIds: string[],
  mode?: 'web-friendly-global' | 'web-friendly' | 'web-friendly-random' | 'any',
  colorProps?: ColorProps
): { [key: string]: string } => {
  const colorArray = []

  const generateColorWithMode = (currentIndex: number) => {
    switch (mode) {
      case 'web-friendly-global': {
        // must be at least 4 chracters or longer or charCodeAt returns NaN
        // probably should throw error instead, but didnt wanan wrap on try { } on every call
        const hue = arrayOfIds[currentIndex].length >= 3 ? encodeUIDtoColorHue(arrayOfIds[currentIndex]) : null
        if (hue === null) {
          console.warn(
            `[generateUniqueColors]: id "${arrayOfIds[currentIndex]}" is not a valid id or is not at least 3 characters long. Switching to web-friendly mode`
          )
        }
        return hue !== null ? generateColor(hue) : generateColor()
      }
      case 'web-friendly': {
        return generateColor(currentIndex, true, colorProps)
      }
      case 'web-friendly-random': {
        return generateColor(undefined, false, colorProps)
      }
      default: {
        return generateRandomColor()
      }
    }
  }

  for (let i = 0; i < arrayOfIds.length; i++) {
    // eslint-disable-line no-plusplus
    colorArray.push(generateColorWithMode(i))
  }
  return colorArray.reduce((result, color, index) => R.assoc(arrayOfIds[index], color, result), {})
}
