/* eslint-disable react/jsx-pascal-case */

import Gleap from 'gleap'
import { useEffect } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
// @ts-ignore react-firestore-connect has no exported types
import { connectFirestore } from 'react-firestore-connect'
import type {
  ActivityInternalToExternalType,
  ActivityRequestType,
  ActivityType,
  BusinessTypeWithId,
} from '../../../../src/types/business'
import type { Firestore, Timestamp } from '../../../../src/types/firebase'
import type { GroupType } from '../../../../src/types/groups'
import type { JobType } from '../../../../src/types/jobs'
import type { ManagerTypeWithId } from '../../../../src/types/manager'
import type { PoolTypeWithId } from '../../../../src/types/pools'
import { CompanyAdminModules } from '../../LoadableModules'
import MessagesCountProvider from '../../components/MessagesCountProvider'
import { config } from '../../config'
import { PATHS } from '../../constants/routes'
import { getInternalToExternalActivitiesByBusinessId } from '../../staffers/api/getters/activities.legacy'
import {
  getBusinessById,
  getBusinessesByGroupId,
  getUnresolvedRequestsByBusinessId,
  getUnseenBusinessActivitiesById,
} from '../../staffers/api/getters/business.legacy'
import { getGroupById } from '../../staffers/api/getters/groups.legacy'
import {
  getSingleEventJobByBusiness,
  getSingleExternalJobByBusiness,
  getSingleTemporaryJobByBusiness,
  getUnpublishedEventJobsByBusinessId,
  getUnpublishedNonEventJobsByBusinessId,
} from '../../staffers/api/getters/jobs.legacy'
import { getManagerById } from '../../staffers/api/getters/managers.legacy'
import { getPoolById } from '../../staffers/api/getters/pools.legacy'
import CompanyLayout from './CompanyLayout'

type Props = {
  business: BusinessTypeWithId
  group: GroupType
  manager: ManagerTypeWithId
  pool: PoolTypeWithId
  externalJob: [JobType] | null
  unseenActivities: ActivityType[][]
  unseenActivitiesCurrentBusiness?: ActivityType[][]
  unreadMessagesCurrentBusiness?: ActivityType[][]
  unresolvedRequestsCurrentBusiness?: ActivityRequestType[][]
  unresolvedRequests?: ActivityRequestType[][]
  unseenActivitiesInternalToExternal?: ActivityInternalToExternalType[]
} & RouteComponentProps

const CompanyAdmin = ({
  business,
  manager,
  pool,
  group,
  externalJob,
  unseenActivities,
  unseenActivitiesCurrentBusiness, // picked from unseenActivities prop inside Layout provider
  unreadMessagesCurrentBusiness, // picked from unseenMessages prop inside Layout provider
  unresolvedRequests,
  unresolvedRequestsCurrentBusiness, // picked from unresolvedRequests prop inside Layout provider
  unseenActivitiesInternalToExternal,
}: Props) => {
  useEffect(() => {
    // set gleap identity
    if (manager) {
      // metadata for chat application
      const businessId = business.businessId
      const customData: Record<string, any> | undefined = business
        ? {
            isTestUser: !!business.isTestUser,
            superadmin_url:
              config.projectId === 'staffers-production'
                ? `https://admin.staffersapp.com/companies/${businessId}`
                : `https://staffers-985f5.firebaseapp.com/companies/${businessId}`,
          }
        : undefined
      const { email, phone } = manager
      const name = email // managers do not have names for now
      console.log('customData', customData)
      Gleap.identify(manager.userId, {
        name,
        email,
        phone,
        companyId: manager.businessId,
        companyName: business?.businessName,
        customData,
        createdAt: (manager.createdAt as Timestamp).toDate(),
      })
    }
  }, [manager, business])

  if (!business || !manager || pool === undefined || externalJob === undefined) return null

  return (
    <Switch>
      <Route
        exact
        path={PATHS.dashboard}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.companyProfile business={business} externalJob={externalJob} />}
      />
      <Route
        path={PATHS.companyProfile}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.companyProfile business={business} />}
      />
      <Route
        exact
        path={PATHS.contracts}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.contracts business={business} />}
      />
      <Route
        exact
        path={PATHS.staffers}
        render={() => (
          <CompanyAdminModules.staffers
            // @ts-ignore react-loadable cant infer types from dynamic imports
            business={business}
            manager={manager}
            pool={pool}
          />
        )}
      />
      <Route
        path={PATHS.stafferDetail}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.stafferDetail business={business} manager={manager} />}
      />
      <Route
        exact
        path={PATHS.chats}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.chats business={business} unreadMessages={unreadMessagesCurrentBusiness} />}
      />
      <Route
        path={PATHS.chat}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.chats business={business} unreadMessages={unreadMessagesCurrentBusiness} />}
      />
      <Route
        exact
        path={PATHS.jobs}
        render={() => (
          <CompanyAdminModules.jobs
            // @ts-ignore react-loadable cant infer types from dynamic imports
            pool={pool}
            business={business}
            manager={manager}
            // @ts-ignore react-loadable cant infer types from dynamic imports
            unseenActivities={unseenActivitiesCurrentBusiness || []}
            // @ts-ignore react-loadable cant infer types from dynamic imports
            unresolvedRequests={unresolvedRequestsCurrentBusiness || []}
          />
        )}
      />
      <Route
        path={PATHS.jobDetail}
        render={() => <CompanyAdminModules.jobDetail business={business} group={group} pool={pool} />}
      />
      <Route
        path={PATHS.jobAdDetail}
        render={
          // @ts-ignore react-loadable cant infer types from dynamic imports
          (props) => <CompanyAdminModules.jobAdDetail key={props.location.key} business={business} />
        }
      />
      <Route
        path={PATHS.jobUnpublishedDetail}
        render={() => <CompanyAdminModules.jobDetail business={business} unpublished />}
      />
      <Route
        path={PATHS.unpublishedAdDetail}
        render={(props) => (
          <CompanyAdminModules.jobAdDetail
            key={props.location.key}
            // @ts-ignore react-loadable cant infer types from dynamic imports
            business={business}
            unpublished
          />
        )}
      />
      <Route
        exact
        path={PATHS.payments}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.payments business={business} />}
      />
      <Route
        exact
        path={PATHS.paymentDetail}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.paymentDetails business={business} />}
      />
      <Route exact path={PATHS.payrolls} render={() => <CompanyAdminModules.payrolls business={business} />} />
      <Route
        exact
        path={PATHS.payrollDetail}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.payrollDetail business={business} />}
      />
      <Route
        exact
        path={PATHS.payrollCost}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.payrollCost business={business} />}
      />
      <Route
        path={PATHS.settings}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.settings business={business} />}
      />
      <Route
        exact
        path={PATHS.integrations}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.integrations manager={manager} business={business} />}
      />
      <Route
        exact
        path={[PATHS.integrationPage, PATHS.integrationGuide, PATHS.integrationEmployees]}
        render={() => <CompanyAdminModules.integrationPage manager={manager} business={business} />}
      />
      <Route
        path={PATHS.managers}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.managers business={business} />}
      />
      <Route
        path={PATHS.groupDashboard}
        render={() => (
          <CompanyAdminModules.groupDashboard
            // @ts-ignore react-loadable cant infer types from dynamic imports
            business={business}
            manager={manager}
            unseenActivities={unseenActivities}
            unresolvedRequests={unresolvedRequests || []}
          />
        )}
      />
      <Route
        path={PATHS.groupPositions}
        // @ts-ignore react-loadable cant infer types from dynamic imports
        render={() => <CompanyAdminModules.groupPositions manager={manager} unseenActivities={unseenActivities} />}
      />
      <Route
        render={() => (
          <CompanyAdminModules.companyProfile
            // @ts-ignore react-loadable cant infer types from dynamic imports
            business={business}
            unseenActivitiesInternalToExternal={unseenActivitiesInternalToExternal}
          />
        )}
      />
    </Switch>
  )
}

export default connectFirestore(
  (db: Firestore, props: Props, uid: string) => ({
    manager: getManagerById(db, uid),
  }),
  connectFirestore(
    // nest second connect because we want the documents to be refetched
    // after businessId of the group manager changes
    (db: Firestore, { manager }: Props, uid: string) => ({
      business: manager && manager.businessId ? getBusinessById(db, manager.businessId) : undefined,
      businesses: manager && manager.groupId ? getBusinessesByGroupId(db, manager.groupId) : undefined,
      businessId: manager ? manager.businessId : undefined,
      group: manager && manager.groupId ? getGroupById(db, manager.groupId) : undefined,
      pool: manager && manager.businessId ? getPoolById(db, manager.businessId) : undefined,
      externalJob: manager && manager.businessId ? getSingleExternalJobByBusiness(db, manager.businessId) : undefined,
      tempJob: manager && manager.businessId ? getSingleTemporaryJobByBusiness(db, manager.businessId) : undefined,
      eventJob: manager && manager.businessId ? getSingleEventJobByBusiness(db, manager.businessId) : undefined,
      unpublishedTempJob:
        manager && manager.businessId
          ? getUnpublishedNonEventJobsByBusinessId(db, manager.businessId).limit(1)
          : undefined,
      unpublishedEventJob:
        manager && manager.businessId
          ? getUnpublishedEventJobsByBusinessId(db, manager.businessId).limit(1)
          : undefined,
      unseenActivitiesInternalToExternal:
        manager && manager.businessId ? getInternalToExternalActivitiesByBusinessId(db, manager.businessId) : undefined,
      unseenActivities:
        manager && manager.businessId
          ? (manager.groupBusinesses || [manager.businessId]).map((id: string) =>
              getUnseenBusinessActivitiesById(db, id, uid)
            )
          : null,
      unresolvedRequests:
        manager && manager.businessId
          ? (manager.groupBusinesses || [manager.businessId]).map((id: string) =>
              getUnresolvedRequestsByBusinessId(db, id, uid)
            )
          : null,
    }),
    // @ts-ignore complicated type with semi-fetched firestore is not affirming to Provider wrapped Props
    MessagesCountProvider(CompanyLayout(CompanyAdmin))
  )
)
