import { orderBy, query, where } from 'firebase/firestore'
import type { Duration } from 'moment'
import type { NotificationType } from '../../../../../src/types/notifications'
import type { UserTagType, UserType } from '../../../../../src/types/user'
import moment from '../../../util/moment'
import { getCollectionRef, getDocumentRef } from '../wrappers'

export const getUserById = (uid: string) => getDocumentRef<UserType>('users', uid)

export const getUserTags = (uid: string) => getCollectionRef<UserTagType>('users', uid, 'tags')

export const getUserDevices = (uid: string) =>
  query(getCollectionRef('devices'), where('userId', '==', uid), orderBy('loginAt', 'desc'))

export const getRecentNotifications = (uid: string, maximumAge: Duration) => {
  const collectionRef = getCollectionRef<NotificationType>('users', uid, 'notifications')
  return query(
    collectionRef,
    where('content.shouldBeShown', '==', true),
    where('metadata.emittedAt', '>', moment().subtract(maximumAge).toDate()),
    orderBy('metadata.emittedAt', 'desc')
  )
}

export const getAdminNotifications = (uid: string) =>
  query(
    getCollectionRef<NotificationType>('users', uid, 'notifications'),
    where('content.isAdmin', '==', true),
    orderBy('metadata.emittedAt', 'desc')
  )
