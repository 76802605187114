// eslint-disable-next-line import/no-extraneous-dependencies
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { distance } from 'geokit'
import { pick } from 'ramda'
import type { CountryNameType, RegionBasicType, RegionType } from '../../../src/types/common'
import type { GeoPoint } from '../../../src/types/firebase'
import { getAvailableRegionsPromise } from '../staffers/api/getters/common.legacy'

export const exampleRegions = [
  {
    cityName: 'Oslo',
    coordinates: new firebase.firestore.GeoPoint(59.9138688, 10.752245399999993),
    countryId: 'gf187Xt32Bf2sN8b8B5V',
    countryName: 'Norway',
    id: '4QPaCiW5gOxXp9fff8ws',
  },
  {
    cityName: 'Stockholm',
    coordinates: new firebase.firestore.GeoPoint(59.33036209999999, 18.0586789),
    countryId: '',
    countryName: 'Sweden',
    id: '',
  },
] as Array<RegionBasicType & { countryId: '' }>

// Returns the country name from the region id, or undefined if not a valid country name
// eg 'Norway:Oslo' => 'Norway'
export const countryNameFromRegionId = (regionId?: string): CountryNameType | undefined => {
  const countryNames: Record<CountryNameType, CountryNameType> = {
    Norway: 'Norway',
    Sweden: 'Sweden',
  }
  return (countryNames as Record<string, CountryNameType>)[(regionId?.split(':') || [])[0]] as
    | CountryNameType
    | undefined
}

export const getDistanceKm = (pointA: GeoPoint | null, pointB: GeoPoint | null) =>
  pointA && pointB
    ? distance({ lat: pointA.latitude, lng: pointA.longitude }, { lat: pointB.latitude, lng: pointB.longitude })
    : NaN
export const pickBasicRegionInfo = (region: RegionType): RegionBasicType =>
  pick(['coordinates', 'cityName', 'countryName', 'id'], region)

/**
 * Fetches the closest (available - non-test) region for a given location.
 * @param lat - The location latitude to find the closest region for.
 * @param lng - The location longitude to find the closest region for.
 * @param countryName - (Optional) The country name to filter regions by.
 * @returns The closest region by distance
 */
export const getClosestRegionFromCoordinates = async (
  lat: number,
  lng: number,
  countryName?: string
): Promise<RegionType> => {
  const coordinates = new firebase.firestore.GeoPoint(lat, lng)
  const regions = await getAvailableRegionsPromise(firebase.firestore())
  const filteredRegions = countryName ? regions.filter((region) => region.countryName === countryName) : regions

  if (filteredRegions.length === 0) {
    throw Error(`Sorry, we do not support any regions in ${countryName} yet.`)
  }

  // Find the region closest to the location, in case they overlap
  return filteredRegions.reduce((currentRegion: RegionType, region: RegionType) => {
    const distanceToCurrentRegion = getDistanceKm(coordinates, currentRegion.coordinates)
    const distanceToRegion = getDistanceKm(coordinates, region.coordinates)
    return distanceToRegion < distanceToCurrentRegion ? region : currentRegion
  }, filteredRegions[0])
}

export const getCountryFromAddress = (address: string) => {
  const country = address.split(', ').pop()
  return country?.trim() || ''
}
