import { googlePlacesApiKey } from '../config'

export const loadGoogleMaps = (ready: () => any) => {
  const window = global as any
  if (window.google?.maps) {
    // prevent multiple inserts
    ready()
    return
  }
  window.initMap = ready
  const gmapScriptEl = window.document.createElement('script')
  gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${googlePlacesApiKey}&libraries=places&language=en&callback=initMap`
  const body = window.document.querySelector('body')
  if (body) {
    body.insertAdjacentElement('beforeend', gmapScriptEl)
  }
}
