import { Divider, List } from '@material-ui/core'
import React, { PureComponent } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import type { BusinessType } from '../../../../../src/types/business'
import type { GroupJobTypesType, GroupType } from '../../../../../src/types/groups'
import type { PoolType } from '../../../../../src/types/pools'
import EditJob from '../../../components/modals/JobVariantSelector'
import Modal from '../../../components/mui/Modal'
import { withIntegrations, type IntegrationsCtx } from '../../../components/providers/IntegrationsProvider'
import { getGroupById, getGroupCustomJobTypes } from '../../../staffers/api/getters/groups'
import { connectFirestore } from '../../../staffers/qman/connectFirestore'
import SidebarHeader from '../sidebar/SidebarHeader'
import { getActiveItem } from '../sidebar/getActiveItem'
import { renderActionItems, renderSidebarItems } from '../sidebar/itemCreator'
import { getActionItems, getGroupSidebarItems } from './groupItemCreator'

type Props = RouteComponentProps & {
  business: BusinessType
  group?: GroupType
  pool: PoolType | null
  groupJobTypes?: GroupJobTypesType
  integrationsCtx: IntegrationsCtx
}

type State = {
  isSidebarOpen: boolean
  isModalOpen: {
    completeProfile: boolean
    createCustomPosition: boolean
    createExternalJob: boolean
    createInternalJob: boolean
    inviteToPool: boolean
  }
}

class GroupSidebar extends PureComponent<Props, State> {
  state = {
    isModalOpen: {
      completeProfile: false,
      createExternalJob: false,
      createInternalJob: false,
      inviteToPool: false,
      createCustomPosition: false,
    },
    isSidebarOpen: true,
  }

  toggleSidebar = () =>
    this.setState((prevState) => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }))

  toggleModal = (modalName: string) =>
    this.setState((prevState: State) => ({
      isModalOpen: {
        ...prevState.isModalOpen,
        [modalName as keyof State['isModalOpen']]: !prevState.isModalOpen[modalName as keyof State['isModalOpen']],
      },
    }))

  render() {
    const { business, group, groupJobTypes, location, pool, integrationsCtx } = this.props

    const { isSidebarOpen, isModalOpen } = this.state

    if (!group) {
      return <BeatLoader />
    }

    const menuItems = getGroupSidebarItems()
    const activeItem = getActiveItem(location, menuItems)
    const actionItems = getActionItems(pool, activeItem, business, group.id, groupJobTypes)

    return (
      <React.Fragment>
        {/* Sidebar definitions */}
        <List disablePadding>
          {/* Top of the navbar - logo */}
          <SidebarHeader
            businessName={group.name}
            isGroupManager
            isSidebarOpen={isSidebarOpen}
            onCollapse={this.toggleSidebar}
          />
          {/* Renders individual sidebar items defined in './itemCreator.js */}
          {renderSidebarItems(menuItems, activeItem, isSidebarOpen, {})}
          <Divider />
          {/* Sidebar action items */}
          {renderActionItems(actionItems, isSidebarOpen, false, this.toggleModal)}
        </List>
        {/* Create External Job */}
        <Modal isOpen={isModalOpen.createExternalJob} contentLabel="Create job">
          <EditJob
            business={business}
            businessId={business.businessId || null}
            jobId={null}
            closeModal={() => this.toggleModal('createExternalJob')}
            defaultCloseModal={() => this.toggleModal('createExternalJob')}
            preSelectedPoolAccess="PUBLIC"
            integrationsCtx={integrationsCtx}
          />
        </Modal>
        {/* Create Internal Job */}
        <Modal isOpen={isModalOpen.createInternalJob} contentLabel="Create job">
          <EditJob
            business={business}
            businessId={business.businessId || null}
            jobId={null}
            closeModal={() => this.toggleModal('createInternalJob')}
            defaultCloseModal={() => this.toggleModal('createInternalJob')}
            preSelectedPoolAccess="PRIVATE"
            integrationsCtx={integrationsCtx}
          />
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(
  withIntegrations(
    connectFirestore(
      (_, { business, group }: Props) => ({
        group: business.groupId && !group ? getGroupById(business.groupId) : group || null,
        groupJobTypes: business.groupId ? getGroupCustomJobTypes(business.groupId) : null,
      }),
      GroupSidebar
    )
  )
)
