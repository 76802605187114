import { Box, LinearProgress, Typography } from '@material-ui/core'
import type { RouteComponentProps } from 'react-router-dom'
import { Link, withRouter } from 'react-router-dom'
// @ts-ignore package with no exported types/
import { connectFirestore } from 'react-firestore-connect'
import type { Firestore } from '../../../../src/types/firebase'
import type { ManagerType } from '../../../../src/types/manager'
import { getManagerById } from '../../staffers/api/getters/managers.legacy'
import ModalHeader from '../mui/ModalHeader'

type Props = {
  groupId?: string
  manager?: ManagerType
  onClose: () => void
} & RouteComponentProps

const RedirectCustomPosition = (props: Props) => {
  const { manager, groupId, onClose } = props

  // modal shouldnt show whatsoever if group or manager does not exist
  if (manager === null || !groupId) {
    return null
  }

  return (
    <Box>
      <ModalHeader close={onClose}>Setup custom postions</ModalHeader>
      <Box p={3} display="flex" justifyContent="center" alignItems="center">
        {/* eslint-disable-next-line no-nested-ternary */}
        {manager === undefined ? (
          <LinearProgress color="primary" />
        ) : manager.groupId ? (
          // If is also a grouper manager, ask him to create one
          // otherwise request to contact group manager
          <Typography variant="h5" component="i" style={{ color: 'silver' }}>
            To post a job, your group needs to <Link to="/group/dashboard">create at least one custom position</Link>.
          </Typography>
        ) : (
          <Typography variant="h5" component="i" style={{ color: 'silver' }}>
            To post a job, your group needs to create at least one custom position. Please contact your group manager to
            create one.
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default withRouter<Props, any>(
  connectFirestore(
    (db: Firestore, props: Props, uid: string) => ({
      manager: getManagerById(db, uid),
    }),
    RedirectCustomPosition
  )
)
