import moment from 'moment-timezone'
import type { ShiftTypeBasic } from '../../../src/types/jobs'
import { TIMEZONE } from '../constants'
import { getShiftId } from './jobs'
import { timeStampFromDate } from './time'

export type PlandayShift = {
  comment: string | null
  createdAt: string
  department: {
    id: number
    name: string
  }
  employeeGroup: {
    id: number
    name: string
  }
  endTime: string
  id: string
  jobId: string | null
  plandayCreatedAt: string
  plandayDate: string // YYYY-MM-DD
  plandayModifiedAt: string
  plandayShiftId: number
  shiftId: string
  shiftType: string | null
  startTime: string
  status: string
  updatedAt: string
}

export const transformPlandayShifts = (shifts: PlandayShift[]): ShiftTypeBasic[] => {
  const transformedShifts = shifts
    // if a Planday shift has a jobId, it's a shift that has been created on Staffers
    .filter((shift) => !!shift && !shift?.jobId)
    .map((shift) => {
      const timeStart = moment(shift.startTime).tz(TIMEZONE).toDate()
      const timeEnd = moment(shift.endTime).tz(TIMEZONE).toDate()
      return {
        positions: 1,
        timeStart: timeStampFromDate(timeStart),
        timeEnd: timeStampFromDate(timeEnd),
        importedFromPlanday: shift.plandayShiftId,
        plandayDepartment: shift.department?.name,
        plandayEmployeeGroup: shift.employeeGroup?.name,
      }
    }) as ShiftTypeBasic[]

  if (shifts) {
    return transformedShifts.map((shift) => ({
      ...shift,
      shiftId: getShiftId(shift),
    }))
  }
  return []
}
