import { Link, Button as MUIButton, TextField } from '@material-ui/core'
import { updateDoc } from 'firebase/firestore'
import * as R from 'ramda'
// eslint-disable-next-line import/no-extraneous-dependencies
import ISO6391 from 'iso-639-1'
import moment from 'moment'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import type { JobType } from '../../../../../src/types/jobs'
import type {
  ExperiencesType,
  StafferDocumentsType,
  StafferLogType,
  StafferRatingsType,
  StafferType,
} from '../../../../../src/types/staffer'
import type {
  StafferReferralType,
  StafferReferralsType,
  StafferReferralsTypeLegacy,
} from '../../../../../src/types/stafferReferrals'
import { errorToast } from '../../../helpers/toast'
import VerifiedUserIcon from '../../../img/VerifiedUserIcon.svg'
import FileIcon from '../../../img/algoliaIcons/FileIcon.svg'
import ShortlistIcon from '../../../img/algoliaIcons/ShortlistIcon.svg'
import SkilledBadgeIcon from '../../../img/algoliaIcons/SkilledBadgeIcon.svg'
import { getDocumentRef } from '../../../staffers/api/wrappers'
import Button from '../../mui/redesignedComponents/Button'
import styles from './StafferPopover.module.css'
import StafferPopoverInvites from './StafferPopoverInvites'
import StafferPopoverJobsListing from './StafferPopoverJobsListing'
import StafferPopoverReferralsListing from './StafferPopoverReferralsListing'
import StafferPopoverVerification from './StafferPopoverVerification'

type Props = {
  stafferId: string
  stafferReferrals?: StafferReferralsType | StafferReferralsTypeLegacy | null
  staffer: StafferType
  invitedByBusinessId?: string
  stafferLogs?: StafferLogType[] | null
  stafferDocuments?: StafferDocumentsType
  completedJobs?: JobType[] | null
  stafferRatings?: StafferRatingsType[] | null
  shortlists?: string[]
  isSuperAdmin?: boolean
  onApproveDocuments: () => void
  onDenyDocuments: () => void
}

const DOCUMENT_TYPES = {
  cv: 'CV',
  drivinglicense: 'Driving license',
  passport: 'Passport',
  taxcard: 'TaxCard',
  hmskurs: 'HMS kurs',
  ikmat: 'IK-Mat',
  identity: 'Identity',
} as const

type DocumentType = keyof typeof DOCUMENT_TYPES

const documents = Object.keys(DOCUMENT_TYPES) as DocumentType[]
// documents to be hidden after approval
const HIDDEN_DOCUMENT_TYPES: DocumentType[] = ['passport', 'taxcard', 'identity']
// sort for staffers experiences
const sortExperience = R.sortBy(R.compose((a: any) => -a, R.prop('startDate')))

const ChipJobVariant = ({ variant }: { variant?: string }) => {
  if (!variant) {
    return <div>None</div>
  }
  let jobVariant = ''
  if (variant === 'SEASONAL') {
    jobVariant = 'Seasonal'
  }
  if (variant === 'EXTRA_WORK') {
    jobVariant = 'Extra'
  }
  if (variant === 'PART_TIME') {
    jobVariant = 'Part-time'
  }
  if (variant === 'FULL_TIME') {
    jobVariant = 'Full-time'
  }
  return <div className={styles.jobChip}>{jobVariant}</div>
}

type ChipDocsProps = {
  stafferDocuments: StafferDocumentsType
  documentType: DocumentType
  hasVerifiedDocuments?: boolean
}

const ChipDocs = ({ stafferDocuments, documentType, hasVerifiedDocuments }: ChipDocsProps) => (
  <div style={{ display: 'flex' }}>
    {stafferDocuments &&
      stafferDocuments[documentType] &&
      stafferDocuments[documentType]?.map((document, index) => {
        const showLink =
          document.url &&
          (!hasVerifiedDocuments || (hasVerifiedDocuments && !HIDDEN_DOCUMENT_TYPES.includes(documentType)))

        return (
          <div className={styles.chip} key={index}>
            <img src={FileIcon} alt="Document" className={styles.smallIcon} />
            <span>{DOCUMENT_TYPES[documentType]}</span>
            {showLink && (
              <>
                <span style={{ color: '#D4D4D4', paddingLeft: 12, paddingRight: 12 }}>|</span>
                <Link className={styles.greenText} href={document.url} target="_blank">
                  View
                </Link>
              </>
            )}
            {hasVerifiedDocuments &&
              (HIDDEN_DOCUMENT_TYPES.includes(documentType) || documentType === 'drivinglicense') && (
                <img src={VerifiedUserIcon} alt="Document" className={styles.xsIcon} />
              )}
          </div>
        )
      })}
  </div>
)

const StafferPopoverContent = ({
  stafferId,
  stafferReferrals,
  staffer,
  invitedByBusinessId,
  stafferLogs,
  stafferDocuments,
  completedJobs,
  stafferRatings,
  shortlists,
  isSuperAdmin = false,
  onApproveDocuments,
  onDenyDocuments,
}: Props) => {
  const [stafferComment, setStafferComment] = useState<string>('')

  const { drivinglicense, passport, taxcard } = stafferDocuments || {}

  const isVerified = staffer.documentsVerified || staffer.bankId

  const onChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setStafferComment(e.target.value)
  }

  const stafferLanguages = ISO6391.getLanguages(staffer.languages).map((language) => language.name)

  const onSaveComment = async () => {
    try {
      const stafferRef = getDocumentRef('staffers', stafferId)
      await updateDoc(stafferRef, {
        comment: stafferComment,
      })
      toast.success('Comment was updated successfully!')
    } catch (error) {
      errorToast((error as Error).message)
    }
  }

  const referrals = (stafferReferrals?.referrals as StafferReferralType[]) || []
  const referredStaffers: string[] = referrals
    ? (referrals.map((referral: StafferReferralType) =>
        referral.stafferId ? referral.stafferId : referral.phone
      ) as string[])
    : []

  const age = staffer.birthday ? `${moment().diff(moment(staffer.birthday.toMillis()), 'years')} years` : '-'
  const hometown = staffer.region ? `${staffer.region?.countryName}, ${staffer.region?.cityName}` : '-'
  const email = staffer.email ? staffer.email.toLowerCase() : '-'
  const phone = staffer.phone || '-'
  const hasDocumentsUploaded =
    stafferDocuments &&
    ((drivinglicense && drivinglicense.length > 0) || (passport && passport.length > 0)) &&
    taxcard &&
    taxcard.length > 0
  const sortedExperiences = sortExperience(staffer.experiences || []) as ExperiencesType[]

  return (
    <div className={styles.contentWrapper}>
      {(staffer.isSkilled2 || staffer.bankId || (shortlists && shortlists.length > 0)) && (
        <div className={styles.contentColumnWrapper}>
          {staffer.isSkilled2 && (
            <div className={styles.contentRow}>
              <img src={SkilledBadgeIcon} alt="skilled staffer" className={styles.smallIcon} />
              <span>Skilled staffer</span>
            </div>
          )}
          {staffer.bankId && (
            <div className={styles.contentRow} style={{ marginTop: 24 }}>
              <img src={VerifiedUserIcon} alt="verified bankId" className={styles.smallIcon} />
              <span>User has been verified by BankID</span>
            </div>
          )}
          {shortlists && shortlists.length > 0 && (
            <div className={styles.contentRow} style={{ marginTop: 24 }}>
              <img src={ShortlistIcon} alt="shortlists" className={styles.smallIcon} />
              <span style={{ marginRight: 5 }}>Shortlisted:</span>
              <span className={styles.greyText}>
                {shortlists.map((shortlist: string, index: number) =>
                  index !== shortlists.length - 1 ? `${shortlist}, ` : shortlist
                )}
              </span>
            </div>
          )}
        </div>
      )}
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>About me</span>
        <div className={styles.contentRow}>
          {staffer.description ? (
            <span style={{ marginTop: 16 }}>{staffer.description}</span>
          ) : (
            <div className={styles.emptySection}>No description</div>
          )}
        </div>
      </div>
      {staffer.jobsCompleted && completedJobs && stafferRatings && (
        <div className={styles.contentColumnWrapper}>
          <div className={styles.contentRow}>
            <span className={styles.title}>Previous jobs</span>
            <div className={styles.chipSmall}>{staffer.jobsCompleted.length} Jobs</div>
          </div>
          <StafferPopoverJobsListing
            stafferId={stafferId}
            completedJobs={completedJobs}
            stafferRatings={stafferRatings}
          />
        </div>
      )}
      {isSuperAdmin && !staffer.documentsVerified && (
        <div className={styles.contentColumnWrapper}>
          <div className={styles.rowContainer}>
            <div className={styles.contentRow}>
              <span className={styles.title}>Documents for verification</span>
            </div>
            {!isVerified && hasDocumentsUploaded && (
              <div className={styles.buttonContainer}>
                <Button variant="outlined" onClick={onDenyDocuments}>
                  Deny documents
                </Button>
                <Button color="primary" variant="contained" style={{ marginLeft: 10 }} onClick={onApproveDocuments}>
                  Approve documents
                </Button>
              </div>
            )}
          </div>
          {hasDocumentsUploaded && staffer.documentsVerified !== undefined ? (
            <StafferPopoverVerification
              documentsVerified={staffer.documentsVerified}
              stafferDocuments={stafferDocuments}
            />
          ) : (
            <div className={styles.noDocuments}>
              <span>{'No documents :-('}</span>
              <span className={styles.greyText} style={{ marginTop: 8 }}>
                Waiting for staffer to upload documents.
              </span>
            </div>
          )}
        </div>
      )}
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>Personal information</span>
        <div className={styles.contentRow}>
          <div className={styles.contentColumn}>
            <span className={styles.greyText}>Age</span>
            <span className={styles.infoText}>{age}</span>
          </div>
          <div className={styles.contentColumn}>
            <span className={styles.greyText}>Hometown</span>
            <span className={styles.infoText}>{hometown}</span>
          </div>
        </div>
        <div className={styles.contentRow}>
          <div className={styles.contentColumn}>
            <span className={styles.greyText}>Email</span>
            <span className={styles.infoText}>{email}</span>
          </div>
          <div className={styles.contentColumn}>
            <span className={styles.greyText}>Phone number</span>
            <span className={styles.infoText}>{phone}</span>
          </div>
        </div>
        {invitedByBusinessId && <StafferPopoverInvites invitedByBusinessId={invitedByBusinessId} />}
      </div>
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>Previous experience</span>
        {staffer.experiences && staffer.experiences.length > 0 ? (
          <div className={styles.contentColumn}>
            {sortedExperiences.map(
              ({ startDate, endDate, jobCompany, jobTitle, reference, phone: refPhone, referenceEmail }, index) => {
                const startTime = moment(startDate.toMillis()).format('MM.YYYY')
                const endTime = endDate ? moment(endDate.toMillis()).format('MM.YYYY') : 'Present'
                return (
                  <span key={index}>
                    [{startTime} - {endTime}]: {jobCompany} - {jobTitle} {reference ? `| ${reference}` : ''}{' '}
                    {refPhone && referenceEmail
                      ? `(${refPhone} | ${referenceEmail})`
                      : refPhone
                      ? `(${refPhone})`
                      : referenceEmail
                      ? ` | (${referenceEmail})`
                      : ''}
                  </span>
                )
              }
            )}
          </div>
        ) : (
          <div className={styles.emptySection}>No previous experience</div>
        )}
      </div>
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>What type of work am I looking for?</span>
        <div className={styles.contentRow}>
          {staffer.wantedJobVariants && staffer.wantedJobVariants?.length > 0 ? (
            staffer.wantedJobVariants.map((wantedJobVariant, index) => (
              <ChipJobVariant key={index} variant={wantedJobVariant} />
            ))
          ) : (
            <div className={styles.emptySection}>No selected type</div>
          )}
        </div>
      </div>
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>Documents</span>
        <div className={styles.contentRow}>
          {stafferDocuments ? (
            documents.map(
              (documentType, index) =>
                stafferDocuments[documentType] && (
                  <ChipDocs
                    stafferDocuments={stafferDocuments}
                    documentType={documentType}
                    hasVerifiedDocuments={!!staffer.documentsVerified || !!staffer.bankId}
                    key={index}
                  />
                )
            )
          ) : (
            <div className={styles.emptySection}>No documents</div>
          )}
        </div>
      </div>
      <div className={styles.contentColumnWrapper}>
        <span className={styles.title}>Languages</span>
        {stafferLanguages.length > 0 ? (
          <div className={styles.contentRow}>
            {stafferLanguages.map((language) => (
              <div className={styles.chip} key={language}>
                <span>{language}</span>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.emptySection}>No selected languages</div>
        )}
      </div>
      {isSuperAdmin && (
        <div className={styles.contentColumnWrapper}>
          <span className={styles.title}>Referrals</span>
          {referredStaffers.length > 0 ? (
            referredStaffers.map((referralIdOrPhone, index) => (
              <StafferPopoverReferralsListing
                referralIdOrPhone={referralIdOrPhone}
                isPhone={referralIdOrPhone.includes('+')}
                key={index}
              />
            ))
          ) : (
            <div className={styles.emptySection}>No referrals</div>
          )}
        </div>
      )}
      {isSuperAdmin && (
        <div className={styles.contentColumnWrapper}>
          <span className={styles.title} style={{ marginBottom: 16 }}>
            Comment
          </span>
          <TextField
            variant="outlined"
            style={{ borderColor: '#D4D4D4' }}
            placeholder="Write a comment..."
            onChange={onChangeComment}
            defaultValue={staffer.comment}
            multiline
            minRows={5}
          />
          <div className={styles.contentRow} style={{ justifyContent: 'space-between', marginTop: 6 }}>
            <span style={{ fontSize: 14 }}>Available only for Superadmin</span>
            <MUIButton
              size="small"
              variant="contained"
              color="primary"
              style={{ borderRadius: 8, textTransform: 'unset' }}
              onClick={onSaveComment}
            >
              Save
            </MUIButton>
          </div>
        </div>
      )}
      {isSuperAdmin && stafferLogs && stafferLogs.length > 0 && (
        <div className={styles.contentColumnWrapper}>
          <span className={styles.title}>Logs</span>
          {isSuperAdmin &&
            stafferLogs &&
            stafferLogs.map((log, index) => (
              <div className={styles.contentRow} key={index} style={{ marginTop: index === 0 ? 16 : 0 }}>
                <span style={{ marginRight: 5 }}>{`[${moment(log.time.toMillis()).format('DD/MM/YYYY HH:mm')}]`}</span>
                <span>{log.message}</span>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default StafferPopoverContent
