import type { Moment } from 'moment'
import moment from 'moment'
import type { Timestamp as TimestampType } from '../../../../../src/types/firebase'
import type { ExperiencesType } from '../../../../../src/types/staffer'

/**
 * Calculate staffer's years of experience based on the provided experience array.
 * `Attention`: this function has a sister in the 'functions' package.
 * @param experiences Array of experiences
 * @returns Number of years of experiences (Not rounded! (e.g. 1.5))
 */
export const countExperienceYears = (experiences: ExperiencesType[]): number => {
  // Sort array to be able correctly set ranges
  experiences.sort((a, b) => a.startDate.toMillis() - b.startDate.toMillis())

  const currentTime = moment()
  const workingRanges: { start: Moment; end: Moment }[] = []

  // Set all ranges based on the experiences
  experiences.forEach((experience) => {
    const range = workingRanges[workingRanges.length - 1]
    const { startDate, endDate, ongoing } = experience
    const start = moment(startDate.toMillis())
    const end = endDate && !ongoing ? moment(endDate.toMillis()) : currentTime

    // For all conditions we assume that the experiences are sorted by start date
    // So start.isAfter(range.start) is always true

    // Set initial range from first experience
    if (workingRanges.length === 0) {
      workingRanges.push({ start, end })
      return
    }
    // current experience starts after the range (insert new one)
    if (start.isAfter(range.end)) {
      workingRanges.push({ start, end })
      return
    }
    // current experience is within range (do nothing)
    if (end.isBefore(range.end)) {
      return
    }
    // experiece starts within the previous experience (or same day) but lasts longer(set new end)
    if ((start.isBefore(range.end) || start.isSame(range.end)) && end.isAfter(range.end)) {
      workingRanges[workingRanges.length - 1] = { start: range.start, end: end }
      return
    }
  })
  // Calculate total years of experience
  return workingRanges.reduce((result, range) => result + range.end.diff(range.start, 'months'), 0) / 12
}

/**
 * @param experienceYears Years of experience (can be float)
 * @returns String representation of the experience
 */
export const getExperience = (experienceYears: number) => {
  if (!experienceYears) {
    return 'None'
  }
  const roundedMonths = Math.round(experienceYears * 12)
  if (roundedMonths >= 18) {
    return `${Math.round(experienceYears)} years`
  }
  if (roundedMonths >= 12 && roundedMonths <= 17) {
    return `1 year`
  }
  if (roundedMonths >= 2 && roundedMonths <= 12) {
    return `${roundedMonths} months`
  }
  if (roundedMonths === 1) {
    return `1 month`
  }
  return '< 1 month'
}

export const getActivity = (lastLoggedAt: TimestampType | Date | null) => {
  if (!lastLoggedAt) {
    return `-`
  }
  const lastActivityDate = moment(
    (lastLoggedAt as TimestampType).toMillis ? (lastLoggedAt as TimestampType).toMillis() : lastLoggedAt
  )
  const now = moment()
  const diffYears = Math.floor(now.diff(lastActivityDate, 'years'))
  const diffMonths = Math.floor(now.diff(lastActivityDate, 'months'))
  const diffDays = Math.floor(now.diff(lastActivityDate, 'days'))
  const diffHours = Math.floor(now.diff(lastActivityDate, 'hours'))
  const diffMinutes = Math.floor(now.diff(lastActivityDate, 'minutes'))
  if (diffYears >= 1) {
    return `${diffYears} year ago`
  }
  if (diffMonths >= 1) {
    return `${diffMonths} mon ago`
  }
  if (diffDays >= 1) {
    return `${diffDays}d ago`
  }
  if (diffHours >= 1) {
    return `${diffHours}h ago`
  }
  if (diffMinutes >= 1) {
    return `${diffMinutes}m ago`
  }
  return `minute ago`
}
