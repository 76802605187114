import type { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import { query as firestoreQuery, getDocs, limit, startAfter } from 'firebase/firestore'
import { collectionUpdater, docUpdater, queryUpdater } from 'qman/firebase'
import { data, subscribe, use } from 'qman/swr'
import { useRef } from 'react'
import type { CollectionReference, DocumentReference, Query } from '../../../../src/types/firebase'

// Functions to be used in `qman` schema
export type DocumentWithId<D extends DocumentData> = D & { id: string }
type Data<Q> =
  Q extends Query<infer T>
  ? T
  : Q extends DocumentReference<infer T>
  ? T
  : Q extends CollectionReference<infer T>
  ? T
  : never

const usePaginate = async <Q extends Query<DocumentData>, D extends Data<Q>>(
  ref: Q,
  pageNum: number,
  pageSize: number,
) => {
  // Fallback if somebody stars paginating from 0
  const page = pageNum <= 0 ? 1 : pageNum
  const lastDoc = useRef<QueryDocumentSnapshot | null>(null)
  const result = useRef<DocumentWithId<D>[]>([] as DocumentWithId<D>[])
  const hasNext = useRef<boolean>(true)
  const firstPage = firestoreQuery(ref, limit(pageSize))
  // If hasNext is false, there's no need to fetch more data. Just keep returning the last response
  if (hasNext.current === false) {
    return { result: result.current, hasNext: false }
  }
  // This entire if happens only on the first run of the function. Afterwards we use lastDoc to query from said document
  if (!lastDoc.current) {
    const snap = await getDocs(firstPage)
    if (snap.empty) {
      hasNext.current = false
      return {
        result: [],
        hasNext: false,
      }
    }
    const res = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as DocumentWithId<D>[]
    lastDoc.current = snap.docs[snap.docs.length - 1]
    result.current = res
    hasNext.current = [...result.current].slice((page - 1) * pageSize, page * pageSize).length === pageSize
    return {
      result: [...result.current].slice(0, page * pageSize),
      hasNext: hasNext.current,
    }
  }
  const nextPage = firestoreQuery(ref, startAfter(lastDoc.current), limit(pageSize))
  const nextCache = await getDocs(nextPage)
  if (nextCache.empty) {
    // This is bit of 🐒 programming, since SWR re-runs queries multiple times, we essentially need to track
    // internally how many pages are displayed, sicne the result.current can be AHEAD of the page number
    // The hasNext has to be determined based on what's currently hydrated in the client UI
    hasNext.current = [...result.current].slice((page - 1) * pageSize, page * pageSize).length === pageSize
    return {
      result: [...result.current].slice(0, page * pageSize),
      hasNext: hasNext.current,
    }
  }
  lastDoc.current = nextCache.docs[nextCache.docs.length - 1]
  hasNext.current = [...result.current].slice((page - 1) * pageSize, page * pageSize).length === pageSize
  const res = nextCache.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as DocumentWithId<D>[]
  // We're calling flat again because swr tends to split array results by 100
  result.current = [...result.current, ...res].flat() as DocumentWithId<D>[]
  return {
    result: [...result.current].slice(0, page * pageSize),
    hasNext: hasNext.current,
  }
}

export const collectionFn = <T extends DocumentData>(ref: CollectionReference<T>, queryKey: [string, ...unknown[]]) =>
  data(subscribe(ref, queryKey, (value, next) => collectionUpdater(value, next)))

export const documentFn = <T extends DocumentData>(ref: DocumentReference<T>, queryKey: [string, ...unknown[]]) =>
  data(subscribe(ref, queryKey, (value, next) => docUpdater(value, next)))

export const queryFn = <T extends DocumentData>(ref: Query<T>, queryKey: [string, ...unknown[]]) =>
  data(subscribe(ref, queryKey, (value, next) => queryUpdater(value, next)))

export const promiseFn = <T>(value: Promise<T>, queryKey: [string, ...unknown[]]): T => data(use(value, queryKey))

export const infiniteFn = <T extends DocumentData>(ref: Query<T>, queryKey: [string, number, number, ...unknown[]]) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  data(use(usePaginate(ref, queryKey[1], queryKey[2]), queryKey))
