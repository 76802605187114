import { Box, List } from '@material-ui/core'
import { Component } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import type { BusinessTypeWithId } from '../../../../src/types/business'
import type { NotificationType } from '../../../../src/types/notifications'
import { getRedirectionLink } from '../../helpers/notifications'
import { getCurrentUserId, getDatabase, getServerTimestamp } from '../../staffers/api/firestore/util.legacy'
import Empty from '../mui/Empty'
import styles from './NotificationList.module.css'
import NotificationListItem from './NotificationListItem'

type Props = {
  business?: BusinessTypeWithId
  notifications?: Array<NotificationType>
  shouldCompleteProfile: boolean
  openCompleteProfile: () => void
}

class NotificationList extends Component<Props & RouteComponentProps> {
  onNotificationClick = (notification: NotificationType) => {
    const { openCompleteProfile, shouldCompleteProfile } = this.props
    if (shouldCompleteProfile) {
      openCompleteProfile()
      return
    }
    const {
      content: { redirection },
    } = notification

    const userId = getCurrentUserId()
    if (userId && !notification.metadata.openedAt) {
      // Do not await this call
      getDatabase()
        .collection('users')
        .doc(userId)
        .collection('notifications')
        .doc(notification.id)
        .update('metadata.openedAt', getServerTimestamp())
        .catch((error) => console.warn(`[onNotificationOpened] Error: ${error.message}`))
    }

    // Even for notifications without redirection we need to disregard the NEW notification badge
    // after clicking on it, therefore we return only after we cancel the notif. badge
    if (!redirection) {
      if (userId) {
        getDatabase()
          .collection('users')
          .doc(userId)
          .collection('notifications')
          .doc(notification.id)
          .update('content.shouldBeShown', false)
          .catch((error) => console.warn(`[onNotificationOpened] Error: ${error.message}`))
      }
      return
    }

    const { props, target } = redirection
    if (!props || !target) {
      console.error(`[onNotificationOpened] Error: empty redirection data: ${notification}`)
      return
    }
    const redirectionAddress = getRedirectionLink(target, props)
    this.props.history.push(redirectionAddress)
  }

  render() {
    const { business, notifications } = this.props

    if (!notifications) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
          <BeatLoader />
        </Box>
      )
    }

    const filteredNotifications = notifications.filter(
      ({ content: { redirection } }) =>
        !redirection || (redirection.props && redirection.props.businessName === business?.businessName)
    )

    return filteredNotifications.length > 0 ? (
      <List className={styles.chatList}>
        {notifications.map((notification: NotificationType, index: number) => (
          <NotificationListItem
            key={index}
            onClick={() => this.onNotificationClick(notification)}
            notification={notification}
          />
        ))}
      </List>
    ) : (
      <Box className={styles.emptyWrapper}>
        <Empty label="No recent notifications" className={styles.empty} />
      </Box>
    )
  }
}

export default withRouter(NotificationList)
