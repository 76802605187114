import { firestoreHttpsCallable } from './util'

export const executeBigquery = async ({ query }: { query: string }) =>
  firestoreHttpsCallable('executeBigquery', {
    query, // query to be executed
  })

export const logEvent = async ({ eventName, eventData }: { eventName: string; eventData: Record<string, any> }) =>
  firestoreHttpsCallable('logEvent', {
    // Name of the event you want to track. If you are setting up a new one, create table in bigQuery
    eventName,
    // Object with all the data you want to have in bigQury
    eventData,
  })
