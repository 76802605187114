import { Modal } from '@material-ui/core'
import type { FC } from 'react'
import Button from '../../mui/redesignedComponents/Button'
import styles from './CreateFulltimeJobGallery.module.css'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  fileName: string
}

const GalleryRemoveModal: FC<Props> = ({ isOpen, onClose, onSubmit, fileName }) => (
  <Modal open={isOpen} onClose={onClose} style={{ display: 'flex', flexDirection: 'column' }}>
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h1 className={styles.modalTitle}>Are you sure?</h1>
        <p className={styles.modalInfo}>You’re going to delete the {fileName} file. Are you sure?</p>
        <div className={styles.modalButtons}>
          <Button variant="outlined" color="secondary" onClick={onClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} className={styles.deleteButton}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  </Modal>
)

export default GalleryRemoveModal
