import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, TextField } from '@material-ui/core'
import type { ChangeEvent } from 'react'
import { getCountryAdjective } from '../../../helpers/regionSelector'
import styles from '../modals.module.css'

type Props = {
  checkForErrors: () => void
  country: string
  defaultWage: number
  DEFAULT_MINIMUM_AGE: number
  includeSkillLevels: boolean
  minAge: number
  name: string
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const CreateEditPositionFields = (props: Props) => {
  const { checkForErrors, country, defaultWage, includeSkillLevels, name, minAge, onInputChange } = props

  return (
    <Box p={4} display="flex" flexDirection="column">
      <FormControl className={styles.inputWrapper}>
        <FormLabel>Position name</FormLabel>
        <TextField id="position-name" name="name" onBlur={checkForErrors} onChange={onInputChange} value={name} />
        <FormHelperText>This field is required. (min. length: 2 characters, max. length: 25 characters)</FormHelperText>
      </FormControl>
      <FormControl className={styles.inputWrapper}>
        <FormLabel>Base wage</FormLabel>
        <TextField
          id="default-wage"
          InputLabelProps={{
            shrink: true,
          }}
          name="defaultWage"
          onBlur={checkForErrors}
          onChange={onInputChange}
          type="number"
          value={defaultWage}
        />
        {country === 'Norway' ? (
          <FormHelperText>
            {`This field is required. We expect that you follow ${getCountryAdjective(
              country
            )} laws about minimum wage, read more `}
            <a
              href="https://www.arbeidstilsynet.no/en/working-conditions/pay-and-minimum-rates-of-pay/minimum-wage/"
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </a>
          </FormHelperText>
        ) : (
          <FormHelperText>
            {`This field is required. We expect that you follow ${getCountryAdjective(
              country
            )} laws about minimum wage.`}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl className={styles.inputWrapper}>
        <FormLabel>Minimum age</FormLabel>
        <TextField
          id="minimum-age"
          name="minAge"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: props.DEFAULT_MINIMUM_AGE,
            max: 99,
          }}
          onBlur={checkForErrors}
          onChange={onInputChange}
          type="number"
          value={minAge}
        />
        <FormHelperText>This field is required.</FormHelperText>
      </FormControl>
      <FormControl className={styles.inputWrapper}>
        <FormControlLabel
          control={
            <Checkbox checked={includeSkillLevels} color="primary" name="includeSkillLevels" onChange={onInputChange} />
          }
          label="Include experience levels?"
        />
        <FormHelperText>
          {'Skill levels are optional tags (Junior, Medium, Senior) '}
          with which you&apos;ll be able to specify your employee&apos;s experience with this position.
        </FormHelperText>
      </FormControl>
    </Box>
  )
}

export default CreateEditPositionFields
