import { Avatar, Badge, Box, Button, IconButton, Paper, Popover, Tooltip, Typography } from '@material-ui/core'
import {
  Add as AddIcon,
  ChatBubbleOutlineOutlined,
  NotificationsNoneOutlined,
  PowerSettingsNewOutlined,
  SearchOutlined,
} from '@material-ui/icons'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import * as R from 'ramda'
import type { MouseEventHandler, PropsWithChildren } from 'react'
import React, { PureComponent } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
// @ts-ignore react-select has no exported types/
import { components } from 'react-select'
// @ts-ignore
import AsyncSelect from 'react-select/lib/Async'
// @ts-ignore react-selecfct has no exported types/
import { connectFirestore } from 'react-firestore-connect'
import { compareTwoStrings } from 'string-similarity'
import type { BusinessType } from '../../../../../src/types/business'
import type { Firestore } from '../../../../../src/types/firebase'
import type { GroupStafferType } from '../../../../../src/types/groups'
import type { JobType } from '../../../../../src/types/jobs'
import type { NotificationType } from '../../../../../src/types/notifications'
import type { StafferType } from '../../../../../src/types/staffer'
import ChatList from '../../../components/chatList/ChatList'
import EditCompanyProfile from '../../../components/modals/EditCompanyProfile'
import Modal from '../../../components/mui/Modal'
import modalStyles from '../../../components/mui/Modal.module.css'
import NotificationList from '../../../components/notificationList/NotificationList'
import { PATHS_NAMES } from '../../../constants/routes'
import { TooltipDelays } from '../../../constants/tooltips'
import { isCompanyProfileComplete } from '../../../helpers/business'
import { errorToast } from '../../../helpers/toast'
import { getChatsOfParticipants } from '../../../staffers/api/chats'
import { createCustomChat } from '../../../staffers/api/firestore/https/messaging'
import { searchText } from '../../../staffers/api/firestore/searchText.legacy'
import { getGroupStaffers } from '../../../staffers/api/getters/groups.legacy'
import { getStafferById } from '../../../staffers/api/getters/staffer.legacy'
import { getRecentNotifications } from '../../../staffers/api/getters/user.legacy'
import moment from '../../../util/moment'
import CreateNewChat from '../chats/CreateNewChat'
import styles from './Header.module.css'

type OptionType = {
  label: string
  value: {
    business?: BusinessType // If manager
    invited?: boolean
    accessGranted: boolean
    type: 'business' | 'staffer' | 'manager' | 'reference' | 'referral-staffer' | 'referral-business'
    id: string
    stafferId: string
  }
}

type Props = {
  business: BusinessType
  externalJob: [JobType] | null // a single job fetched, to check if business has ext. job
  managerEmail?: string
  unreadMessages?: { [chatId: string]: number }
  unreadMessagesSum?: number
  groupStaffers: Array<GroupStafferType & { id: string }>
  groupStaffersProfiles: StafferType[]
  notifications: NotificationType[]
} & RouteComponentProps

type State = {
  isChatOpen: boolean
  isCompleteProfileOpen: boolean
  isNotifTooltip: boolean
  messagesAnchorEl: HTMLButtonElement | null
}

type DerivedMouseEventHandler = (
  event: Parameters<MouseEventHandler<HTMLButtonElement | null>>[0],
  isNotif?: boolean
) => ReturnType<MouseEventHandler<HTMLButtonElement>> | MouseEventHandler<HTMLButtonElement>

const selectStyles = {
  option: (provided: Record<string, string>) => ({
    ...provided,
    color: 'black',
    display: 'flex',
    'flex-direction': 'row',
    'align-items': 'center',
  }),
  control: (provided: Record<string, string>) => ({
    ...provided,
    border: 'none',
    'border-radius': 0,
    cursor: 'text',
  }),
  placeholder: (provided: Record<string, string>) => ({
    ...provided,
    'margin-left': '2.5em',
    color: '#AAA',
    'font-size': '14px',
  }),
}

// Minimum length of the query to begin searching
// Prevents wasting reads on queries that are too small
const SEARCH_QUERY_MIN_LENGTH = 3

const ReactSelectBuiltInOption = components.Option
const ReactSelectBuiltInValueContainer = components.ValueContainer

class Header extends PureComponent<Props, State> {
  state: State = {
    isChatOpen: false,
    isCompleteProfileOpen: false,
    isNotifTooltip: false,
    messagesAnchorEl: null,
  }

  // eslint-disable-next-line class-methods-use-this
  getTitle = (currentLocation: string) => {
    const title = PATHS_NAMES[currentLocation]
    if (title) {
      return title
    }
    // Due to dynamic nature of IDs in those paths, we cannot determine from constant where are we.
    // Because of this we need to use this. If someone has better idea without unneccessarily
    // complicating logic, feel free to refactor.
    if (currentLocation.includes('staffer-requests')) {
      return 'Staffer requests'
    }
    if (currentLocation.includes('staffers')) {
      return 'Staffer detail'
    }
    if (currentLocation.includes('company-requests')) {
      return 'Company requests'
    }
    if (currentLocation.includes('companies')) {
      return 'Company detail'
    }
    if (currentLocation.includes('jobs')) {
      return 'Job detail'
    }
    if (currentLocation.includes('job-ads')) {
      return 'Job ad detail'
    }
    if (currentLocation.includes('unpublished-ads')) {
      return 'Job ad detail'
    }
    if (currentLocation.includes('regions')) {
      return 'Region detail'
    }
    if (currentLocation.includes('invites')) {
      return 'Invite codes'
    }
    if (currentLocation.includes('payments')) {
      return 'Payment detail'
    }
    if (currentLocation.includes('errors')) {
      return 'Error logs'
    }
    if (currentLocation.includes('ambassadors')) {
      return 'Ambassadors'
    }
    return 'Page not found'
  }

  // eslint-disable-next-line class-methods-use-this
  handleLogout = () => {
    firebase.auth().signOut()
  }

  // eslint-disable-next-line class-methods-use-this
  renderOption = (props: { data: OptionType }) => {
    const { label } = props.data

    return <ReactSelectBuiltInOption {...props}>{label}</ReactSelectBuiltInOption>
  }

  // eslint-disable-next-line class-methods-use-this
  renderValueContainer = (props: PropsWithChildren<Record<string, never>>) => {
    const { children } = props
    return (
      <ReactSelectBuiltInValueContainer {...props}>
        <SearchOutlined color="action" />
        {children}
      </ReactSelectBuiltInValueContainer>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  isSearchable = (searchQuery: string): boolean => searchQuery.length >= SEARCH_QUERY_MIN_LENGTH

  getSearchResults = async (searchQueries: string): Promise<OptionType[] | void> => {
    const { business, groupStaffersProfiles } = this.props
    const searchResults = [] as OptionType[]
    const queryWords = searchQueries.split(' ')
    // Include initial, whole expression in search as well
    const potentialQueryWords = [searchQueries]
    queryWords.forEach((word) => {
      if (this.isSearchable(word)) {
        // First include searchterm as written
        potentialQueryWords.push(word)
        // Second include searchterm as lowercase
        potentialQueryWords.push(word.toLowerCase())
        // Third include searchterm with first letter in upper case
        potentialQueryWords.push(word.charAt(0).toUpperCase() + word.substring(1))
      }
    })

    await Promise.all(
      potentialQueryWords.map(async (searchQuery) => {
        if (groupStaffersProfiles) {
          groupStaffersProfiles
            .filter(
              (staffer) =>
                staffer &&
                ((staffer.nameFirst && staffer.nameFirst.includes(searchQuery)) ||
                  (staffer.nameFirst && staffer.nameLast.includes(searchQuery)))
            )
            .forEach(
              (staffer) =>
                searchResults.find((result) => result.value.id === staffer.userId) ||
                searchResults.push({
                  label: `${staffer.nameFirst} ${staffer.nameLast}`,
                  value: {
                    stafferId: staffer.userId,
                    id: staffer.userId,
                    accessGranted: true,
                    type: 'staffer',
                  },
                })
            )
        }

        if (this.isSearchable(searchQuery)) {
          if (business) {
            const database = firebase.firestore()
            const businessDocument = database.collection('business').doc(business.businessId)
            const staffersCollection = businessDocument.collection('staffers')

            const queries = [
              searchText(staffersCollection, 'stafferInfo.nameFirst', searchQuery),
              searchText(staffersCollection, 'stafferInfo.nameLast', searchQuery),
            ]

            await Promise.all(
              queries.map(async (query) => {
                try {
                  const results = await query.get()
                  results.docs.forEach((doc) => {
                    // Don't show duplicates
                    if (!searchResults.find((item) => item.value.id === doc.id)) {
                      const data = doc.data()
                      searchResults.push({
                        label: `${data.stafferInfo.nameFirst} ${data.stafferInfo.nameLast}`,
                        value: {
                          stafferId: doc.id,
                          accessGranted: true,
                          type: 'staffer',
                          id: doc.id,
                        },
                      })
                    }
                  })
                } catch (error) {
                  console.warn(error)
                  errorToast((error as Error).message)
                }
              })
            )
          }
        }
      })
    )

    // @ts-ignore ramda has broken typing
    const getUniqueSearchResults = (searchQuery: OptionType[]): OptionType[] =>
      // @ts-ignore ramda has broken typing
      R.uniqBy(({ value: { id } }) => id)(searchQuery)

    searchResults.sort((itemA, itemB) => {
      if (compareTwoStrings(itemA.label, searchQueries) < compareTwoStrings(itemB.label, searchQueries)) {
        return 1
      }
      return -1
    })
    return getUniqueSearchResults([...searchResults])
  }

  onSearchSelect = (selectedOption: OptionType) => {
    const optionType = selectedOption.value.type
    switch (optionType) {
      case 'business': {
        const { accessGranted, id: businessId } = selectedOption.value
        if (accessGranted) {
          this.redirect(`/companies/${businessId}`)
        } else {
          this.redirect(`/company-requests/${businessId}`)
        }
        break
      }

      case 'referral-staffer':
      case 'staffer': {
        const { accessGranted, id, stafferId } = selectedOption.value
        if (accessGranted) {
          this.redirect(`/staffers/${stafferId || id}`)
        } else {
          this.redirect(`/staffer-requests/${stafferId || id}`)
        }
        break
      }

      case 'referral-business':
      case 'manager': {
        const { business } = selectedOption.value
        if (business) {
          const { accessGranted, businessId } = business
          if (accessGranted) {
            this.redirect(`/companies/${businessId}`)
          } else {
            this.redirect(`/company-requests/${businessId}`)
          }
        }
        break
      }

      case 'reference': {
        const { accessGranted, stafferId } = selectedOption.value
        if (accessGranted) {
          this.redirect(`/staffers/${stafferId}`)
        } else {
          this.redirect(`/staffer-requests/${stafferId}`)
        }
        break
      }

      default:
        console.warn(`Unhandled option type: ${optionType}`)
    }
  }

  redirect = (newLocation: string) => {
    const { location, history } = this.props
    if (newLocation !== location.pathname) {
      history.push(newLocation)
    }
  }

  openPopup: DerivedMouseEventHandler = (event, isNotif?: boolean) => {
    this.setState({
      messagesAnchorEl: event.currentTarget,
      isNotifTooltip: !!isNotif,
    })
  }

  closePopup = () => {
    this.setState({ messagesAnchorEl: null })
  }

  selectChat = (chatId: string) => {
    const { history } = this.props
    history.push(`/chats/${chatId}`)
    this.closePopup()
  }

  toggleCreateChatModal = () => {
    this.setState((prevState) => ({ isChatOpen: !prevState.isChatOpen }))
    this.closePopup()
  }

  toggleCompleteProfileModal = () => {
    this.setState((prevState) => ({ isCompleteProfileOpen: !prevState.isCompleteProfileOpen }))
    this.closePopup()
  }

  createNewChat = async (participantsData: { id: string; name: string }[]) => {
    const { business } = this.props as { business: BusinessType & { id: string } }

    // check if chat with the same staff already exists
    const existingChats = await getChatsOfParticipants([...participantsData.map(({ id }) => id), business.id])
    const nonEventExistingChats = existingChats.filter(({ eventId }: { eventId: string }) => !eventId)
    if (nonEventExistingChats.length > 0) {
      this.toggleCreateChatModal()
      this.selectChat(nonEventExistingChats[0].id)
      return
    }
    const recipients = {
      [business.id]: true,
    }

    participantsData.forEach(({ id }) => {
      recipients[id] = true
      return true
    })
    const response = await createCustomChat({
      shiftId: '',
      jobId: '',
      recipients,
    })

    this.toggleCreateChatModal()
    this.selectChat(response.data.chatId)
  }

  render() {
    const { managerEmail, notifications, unreadMessages, unreadMessagesSum, business } = this.props
    const { isChatOpen, messagesAnchorEl, isNotifTooltip, isCompleteProfileOpen } = this.state
    const id = messagesAnchorEl ? 'messages-popover' : undefined

    const highlightChatIcon = global.location.pathname.startsWith('/chats/')

    const { isFromAppOnboarding } = business

    const shouldCompleteProfile = isFromAppOnboarding ? !isCompanyProfileComplete(business) : false
    return (
      <Paper className={styles.header} square>
        <div className={styles.searchWrapper}>
          <AsyncSelect
            components={{
              Option: this.renderOption,
              IndicatorsContainer: () => null,
              ValueContainer: this.renderValueContainer,
            }}
            placeholder="Search employees, staffers..."
            noOptionsMessage={({ inputValue }: { inputValue: string }) =>
              this.isSearchable(inputValue)
                ? 'No results found.'
                : 'Enter the name of a staffer. Case and special characters must match.'
            }
            styles={selectStyles}
            value={null} // Don't store the selected value
            loadOptions={this.getSearchResults}
            onChange={this.onSearchSelect}
            className={styles.searchInput}
            classNamePrefix={styles.searchInput}
            isSearchable
          />
        </div>
        <div className={styles.notificationsWrapper}>
          <Box mr={2} display="flex" flexDirection="column">
            <Typography className={styles.callText}>
              <strong>Need support?</strong>
            </Typography>
            <Typography className={styles.callText}>Call us +47 70 31 01 11</Typography>
          </Box>
          {/* <Chip label={isPro ? 'PRO' : 'TRIAL'}
              size="medium" classes={{ root: styles.chip }} /> */}
          <Box mx={1} className={highlightChatIcon ? styles.highlightedButtonWrapper : undefined}>
            <IconButton onClick={this.openPopup as unknown as MouseEventHandler<HTMLButtonElement>}>
              <Box>
                <Badge color="error" badgeContent={unreadMessagesSum}>
                  <ChatBubbleOutlineOutlined />
                </Badge>
                <Typography className={styles.notificationBtnLabel}>Messages</Typography>
              </Box>
            </IconButton>
          </Box>
          <Box mx={1} className={highlightChatIcon ? styles.highlightedButtonWrapper : undefined}>
            <IconButton onClick={(e) => this.openPopup(e, true)}>
              <Box>
                <Badge
                  badgeContent={
                    (notifications || [])
                      .filter(({ metadata: { openedAt } }) => !openedAt)
                      // In case, there is a redirection, we only show the notification badge to those, where tbe
                      // businessName matches the selected one - this should always match the condition
                      // at the bottom of NotificationList.js, otherwise the badge won't match
                      // what's displayed in the actual Notifications Popover
                      .filter(
                        ({ content: { redirection } }) =>
                          !redirection ||
                          (redirection.props && redirection.props.businessName === business.businessName)
                      ).length
                  }
                  color="error"
                >
                  <NotificationsNoneOutlined />
                </Badge>
                <Typography className={styles.notificationBtnLabel}>Notifications</Typography>
              </Box>
            </IconButton>
          </Box>
          <Popover
            id={id}
            open={!!messagesAnchorEl}
            anchorEl={messagesAnchorEl}
            onClose={this.closePopup}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {!!messagesAnchorEl && (
              <div className={styles.popoverWrapper}>
                {isNotifTooltip ? (
                  <React.Fragment>
                    <div style={{ flexGrow: 1, position: 'relative' }}>
                      <NotificationList
                        business={business as BusinessType & { id: string }}
                        notifications={notifications}
                        shouldCompleteProfile={shouldCompleteProfile}
                        openCompleteProfile={this.toggleCompleteProfileModal}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div style={{ flexGrow: 1, position: 'relative' }}>
                      <ChatList
                        businessId={this.props.business.businessId}
                        onSelect={this.selectChat}
                        unreadMessages={unreadMessages}
                      />
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<AddIcon />}
                      onClick={this.toggleCreateChatModal}
                    >
                      New chat
                    </Button>
                  </React.Fragment>
                )}
              </div>
            )}
          </Popover>
          <Tooltip leaveDelay={TooltipDelays.WITHOUT_LINKS} title={managerEmail || 'unknown email'}>
            <IconButton>
              <Avatar alt={managerEmail} className={styles.avatar}>
                {managerEmail && managerEmail.toUpperCase().charAt(0)}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Tooltip leaveDelay={TooltipDelays.WITHOUT_LINKS} title="Logout">
            <IconButton onClick={this.handleLogout} aria-label="logout">
              <PowerSettingsNewOutlined />
            </IconButton>
          </Tooltip>
        </div>
        <Modal isOpen={isChatOpen} onRequestClose={this.toggleCreateChatModal} className={modalStyles.createChatModal}>
          <CreateNewChat businessId={business.id} onClose={this.toggleCreateChatModal} onConfirm={this.createNewChat} />
        </Modal>
        <Modal contentLabel="Complete profile" isOpen={isCompleteProfileOpen}>
          <EditCompanyProfile
            type="complete-profile"
            closeModal={this.toggleCompleteProfileModal}
            businessId={business.id}
          />
        </Modal>
      </Paper>
    )
  }
}

export default withRouter(
  connectFirestore(
    (db: Firestore, { business }: Props, uid: string) => ({
      notifications: getRecentNotifications(db, uid, moment.duration(4, 'weeks')),
      groupStaffers: business.groupId && getGroupStaffers(db, business.groupId),
    }),
    connectFirestore(
      (db: Firestore, { groupStaffers }: Props) => ({
        groupStaffersProfiles: groupStaffers && groupStaffers.map(({ id }) => getStafferById(db, id)),
      }),
      Header
    )
  )
)
