// indexes in Algolia used to search in specific sorting
export enum StafferSortBy {
  staffers = 'Last login', // default index is sorted by login
  staffers_rating = 'Rating',
  staffers_nameFirst = 'Name', // value must be created as 'standard replica' in Algolia
  staffers_workedShifts = 'Worked shifts',
  staffers_yearsExperience = 'Experience time',
}

export enum BusinessSortBy {
  businesses, // only one index for businesses so far
}

export const MAIN_STAFFERS_INDEX: keyof typeof StafferSortBy = 'staffers'
export const MAIN_BUSINESS_INDEX: keyof typeof BusinessSortBy = 'businesses'
export const UNRESOLVED_SHIFTS_INDEX = 'unresolved_shifts'
