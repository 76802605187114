import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import React from 'react'
import type { CountryData } from 'react-phone-input-2'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { BeatLoader } from 'react-spinners'
import type { Firestore } from '../../../../src/types/firebase'
import { fixCountryDialCode, standardized } from '../../helpers/phoneNumbers'
import { errorToast } from '../../helpers/toast'
import { createBusinessProfile } from '../../staffers/api/auth/businessProfile'
import { checkManagerInviteByEmail, checkManagerInviteByPhone } from '../../staffers/api/auth/managerProfile'
import { updateManagerProfile } from '../../staffers/api/firestore/managers'
import { getManagerById } from '../../staffers/api/getters/managers.legacy'
import styles from './VerifyCart.module.css'

const MANAGER_VERSION = 4

type Props = {
  phone: string
}

type State = {
  phone: string
  agreeToTermsAndConditions: boolean
  agreeToPrivacyPolicy: boolean
  inProgress: boolean
  storageUrls: {
    privacyPolicy: string
    termsAndConditions: string
  }
}

const getStorageFileUrl = async (filename: string): Promise<string> => {
  try {
    const storage = firebase.storage()
    const storageRef = storage.ref(filename)
    return storageRef.getDownloadURL()
  } catch (error) {
    console.warn(`Unable to fetch the ${filename} file from storage`)
  }
  return '#'
}

class VerifyCart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      phone: props.phone || '',
      agreeToTermsAndConditions: false,
      agreeToPrivacyPolicy: false,
      inProgress: false,
      storageUrls: {
        termsAndConditions: '',
        privacyPolicy: '',
      },
    }
  }

  recaptcha: HTMLElement | null = null
  recaptchaVerifier: firebase.auth.RecaptchaVerifier | null = null

  async componentDidMount() {
    // Keep recaptcha first, so we are sure it loads correctly
    try {
      // Rendering recaptcha
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha, {
        size: 'invisible', // captcha appears after the user tries to send the sms
        // may be implemented in the future
        // callback(response) {
        // },
        // 'expired-callback': function () {
        // },
      })
      this.recaptchaVerifier.render().then((widgetId) => {
        // @ts-ignore
        window.recaptchaWidgetId = widgetId
      })
    } catch (error) {
      console.warn('Unable to load recaptcha')
    }
    try {
      // Document fetching
      const termsAndConditions = await getStorageFileUrl('StaffersTermsAndConditions.pdf')
      const privacyPolicy = await getStorageFileUrl('privacy-policy.pdf')

      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        storageUrls: {
          privacyPolicy,
          termsAndConditions,
        },
      })
    } catch (error) {
      console.warn('Unable to fetch file from storage', error)
    }
  }

  toggleTermsAndConditions = () =>
    this.setState(({ agreeToTermsAndConditions }) => ({
      agreeToTermsAndConditions: !agreeToTermsAndConditions,
    }))

  togglePrivacyPolicy = () =>
    this.setState(({ agreeToPrivacyPolicy }) => ({
      agreeToPrivacyPolicy: !agreeToPrivacyPolicy,
    }))

  // Checks whether the user agreed to the necessary conditions and also entered a number
  isFormValid = () => {
    const { agreeToTermsAndConditions, agreeToPrivacyPolicy, phone } = this.state
    return !!(phone && agreeToPrivacyPolicy && agreeToTermsAndConditions)
  }

  onPhoneChange = (phone: string, countryData: Partial<CountryData>) => {
    const realPhone = fixCountryDialCode(phone, countryData.dialCode)
    this.setState({
      phone: realPhone,
    })
  }

  onPhoneBlur = () => {
    const { phone } = this.state
    if (phone === '+') {
      this.setState({
        phone: '',
      })
    }
  }

  resetState = () => {
    this.setState({
      phone: '',
      agreeToTermsAndConditions: false,
      agreeToPrivacyPolicy: false,
      inProgress: false,
    })
  }

  save = async () => {
    const database = firebase.firestore() as Firestore
    const phone = standardized(this.state.phone)
    try {
      const { currentUser } = firebase.auth()
      if (!currentUser) {
        throw Error('Could not get the current user')
      }
      // TODO call cloud function for updating thenumber
      this.setState({ inProgress: true })

      // Updates the manager profile with a phone number and TOS version they agreed to
      await updateManagerProfile(currentUser.uid || '', { phone, tosVersion: MANAGER_VERSION })
      await checkManagerInviteByPhone(phone)
      await checkManagerInviteByEmail(currentUser.email || '')
      const managerDoc = await getManagerById(database, currentUser.uid).get()
      const managerData = managerDoc.data()
      if (managerData && managerData.businessId === null) {
        // Creates a new business profile for the manager to use
        await createBusinessProfile(currentUser.uid)
      }
    } catch (e) {
      errorToast((e as Error).message)
      console.warn(e)
    } finally {
      // Reload the page to continue to 'Create Business Profile' step
      await global.location.replace('/register-company') // also remove the invite managar query string from url
    }
  }

  render() {
    const { inProgress, storageUrls } = this.state

    const privacyPolicy = (
      <p>
        I have read and agree to the{' '}
        <a href={storageUrls.privacyPolicy} rel="noopener noreferrer" target="_blank">
          Privacy Policy
        </a>
      </p>
    )

    const termsAndConditions = (
      <p>
        I have read and agree to the{' '}
        <a href={storageUrls.termsAndConditions} rel="noopener noreferrer" target="_blank">
          Terms & Conditions
        </a>
      </p>
    )

    if (inProgress) {
      return <BeatLoader />
    }

    return (
      <Box className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <h2>Phone Number</h2>
          <Box className={styles.inputWrapper}>
            <PhoneInput
              autoFormat
              country="no"
              value={this.state.phone}
              inputClass={styles.phoneInput}
              onBlur={this.onPhoneBlur}
              onChange={this.onPhoneChange}
            />
          </Box>
          <div
            ref={(ref) => {
              this.recaptcha = ref
            }}
          />
          <FormGroup aria-required className={styles.checkboxContainer}>
            <FormControlLabel
              className={styles.checkbox}
              labelPlacement="end"
              control={
                <Checkbox
                  name="privacy-policy"
                  checked={this.state.agreeToPrivacyPolicy}
                  onChange={this.togglePrivacyPolicy}
                />
              }
              label={privacyPolicy}
            />
            <FormControlLabel
              className={styles.checkbox}
              labelPlacement="end"
              control={
                <Checkbox
                  name="terms-and-conditions"
                  checked={this.state.agreeToTermsAndConditions}
                  onChange={this.toggleTermsAndConditions}
                />
              }
              label={termsAndConditions}
            />
          </FormGroup>
          {/* Differentiate between sending the SMS for the first time and then resending */}
          <Box className={styles.buttonContainer}>
            <Button
              variant="contained"
              className={styles.menuButton}
              color="primary"
              type="submit"
              disabled={!this.isFormValid()}
              onClick={this.save}
            >
              Register
            </Button>
          </Box>
        </div>
      </Box>
    )
  }
}

export default VerifyCart
