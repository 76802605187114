import type { BoxProps } from '@material-ui/core'
import { Box, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Announcement } from '@material-ui/icons'
import type { ReactNode } from 'react'

type Props = {
  label?: string | ReactNode
  Icon?: typeof Announcement
  slim?: boolean
  className?: string
} & BoxProps

const Empty = ({ Icon = Announcement, label, slim, ...rest }: Props) => (
  <Box my={slim ? 0 : 4} display="flex" flexDirection="column" justifyContent="center" alignItems="center" {...rest}>
    <Icon color="disabled" />
    <Box mx={2} alignContent="center">
      <Typography style={{ color: grey[500] }}>{label || 'Empty'}</Typography>
    </Box>
  </Box>
)

export default Empty
