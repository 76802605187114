import { Box } from '@material-ui/core'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as R from 'ramda'
// @ts-ignore react-select has no exported types/
import { components } from 'react-select'
// @ts-ignore react-select has no exported types/
import AsyncSelect from 'react-select/lib/Async'
import { searchText } from '../../../staffers/api/firestore/searchText.legacy'
import styles from '../modals.module.css'
import type { SelectBusinessType } from './CreateEditGroup'

type Props = {
  onToggleBusiness: (selectedBusinessType: SelectBusinessType) => void
  selectedBusinesses: Array<SelectBusinessType>
  groupId?: string
}

type SearchResult = {
  businessId: string
  businessName: string
  isDisabled: boolean
}

// Minimum length of the query to begin searching
// Prevents wasting reads on queries that are too small
const SEARCH_QUERY_MIN_LENGTH = 2

const ReactSelectBuiltInOption = components.Option

const SelectBusinesses = (props: Props) => {
  const { groupId, selectedBusinesses } = props
  const isSearchable = (searchQuery: string): boolean => searchQuery.length >= SEARCH_QUERY_MIN_LENGTH

  const getSearchResults = async (searchQuery: string): Promise<SelectBusinessType[] | void> => {
    if (isSearchable(searchQuery)) {
      const database = firebase.firestore()
      const businessCollection = database.collection('business')
      const searchResults: SearchResult[] = []

      const queries = [searchText(businessCollection, 'businessName', searchQuery)]

      await Promise.all(
        queries.map(async (query) => {
          try {
            const results = await query.get()
            results.docs.forEach((doc) => {
              if (
                !searchResults.find((item) => item.businessId === doc.id) &&
                !selectedBusinesses.find((item) => item.businessId === doc.id)
              ) {
                // Don't show duplicates
                const data = doc.data()
                const alreadyGrouped = data.groupId && data.groupId !== groupId
                if (data.accessRequested) {
                  searchResults.push({
                    businessName: data.businessName,
                    businessId: doc.id,
                    isDisabled: alreadyGrouped,
                  })
                }
              }
            })
          } catch (error) {
            console.warn(error)
          }
        })
      )

      return R.sort((itemA, itemB) => itemA.businessName.localeCompare(itemB.businessName), searchResults)
    }

    return undefined
  }

  const alreadyAdded = (inputValue: string) => selectedBusinesses.find((item) => item.businessName === inputValue)

  const renderOption = (renderProps: { data: SearchResult }) => {
    const { businessName, isDisabled } = renderProps.data
    if (isDisabled) {
      return (
        <ReactSelectBuiltInOption {...renderProps} isDisabled>
          {businessName}
          &nbsp;
          <small>(already in another group)</small>
        </ReactSelectBuiltInOption>
      )
    }
    return <ReactSelectBuiltInOption {...renderProps}>{businessName}</ReactSelectBuiltInOption>
  }

  const { onToggleBusiness } = props

  return (
    <Box mt={3}>
      <Box className={styles.search} mt={1}>
        <AsyncSelect
          components={{ Option: renderOption }}
          placeholder="Search for businesses you want to select"
          noOptionsMessage={({ inputValue }: { inputValue: string }) =>
            isSearchable(inputValue)
              ? `${alreadyAdded(inputValue) ? 'Already added' : 'No results found'}.`
              : 'Enter the name of a company. Case and special characters must match.'
          }
          styles={{
            option: (provided: Record<string, string>) => ({
              ...provided,
              color: 'black',
              display: 'flex',
              'flex-direction': 'row',
              'align-items': 'center',
            }),
            menuPortal: (base: Record<string, string>) => ({ ...base, zIndex: 9999 }),
          }}
          menuPortalTarget={global.document.body}
          value={null} // Don't store the selected value
          loadOptions={getSearchResults}
          onChange={onToggleBusiness}
          className={styles.searchInput}
          isSearchable
        />
      </Box>
    </Box>
  )
}

export default SelectBusinesses
