import { getAuth } from 'firebase/auth'
import { Timestamp, addDoc } from 'firebase/firestore'
import { useState } from 'react'
import type { Props as ModalProps } from 'react-modal'
import { toast } from 'react-toastify'
import type { Shortlist } from '../../../../../src/types/shortlist'
import { errorToast } from '../../../helpers/toast'
import { getCollectionRef } from '../../../staffers/api/wrappers'
import MuiModal from '../../mui/Modal'
import Button from '../../mui/redesignedComponents/Button'
import TextField from '../../mui/redesignedComponents/TextField'
import type { ModalHeaderProps } from '../modal/Modal'
import { ModalHeader } from '../modal/Modal'
import styles from './CreateShortlistModal.module.css'

type CreateShortlistModalBaseProps = {
  // also redirect to add to shortlist modal if needed
  onCreate: (shortlistName: Shortlist['name']) => void
  isProcessing: boolean
  error?: string
} & Pick<ModalHeaderProps, 'onClose'>

export const CreateShortlistModalBase = ({ onClose, onCreate, isProcessing, error }: CreateShortlistModalBaseProps) => {
  const [shortlistName, setShortlistName] = useState<Shortlist['name']>('')

  const submitNewShortlist = () => {
    onCreate(shortlistName)
    setShortlistName('')
  }

  return (
    <div className={styles.modal}>
      {/* Header */}
      <ModalHeader title="Create new shortlist" onClose={onClose} />
      {/* Content */}
      <div className={styles.content}>
        <span className={styles.inputTitle}>{'Shortlist name'}</span>
        <TextField
          value={shortlistName}
          onChange={({ target }) => setShortlistName(target.value)}
          required
          variant="outlined"
          size="small"
          fullWidth
          error={!!error}
        />
        {error && <span className={styles.error}>{error}</span>}
        {/* Actions */}
        <div className={styles.actions}>
          <Button
            disabled={isProcessing}
            onClick={submitNewShortlist}
            color="primary"
            variant="contained"
            size="medium"
            fullWidth
          >
            Create shortlist
          </Button>
          <Button onClick={onClose} variant="text" size="medium" fullWidth disabled={isProcessing}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

const CreateShortlistModal = ({
  isOpen,
  onClose,
  shortlists,
  ...props
}: { shortlists: Shortlist[] } & Pick<ModalProps, 'isOpen'> & Pick<CreateShortlistModalBaseProps, 'onClose'>) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const createShortlist = async (shortlistName: string) => {
    setError(undefined)
    const adminId = getAuth().currentUser?.uid
    if (!adminId || !shortlistName) return

    if (shortlists.some((shortlist) => shortlist.name === shortlistName.trim())) {
      setError('Shortlist with this name already exists')
      return
    }

    try {
      setIsProcessing(true)
      const shortlistsCollection = getCollectionRef('shortlists')
      await addDoc(shortlistsCollection, {
        name: shortlistName.trim(),
        members: [],
        createdBy: adminId,
        createdAt: Timestamp.now(),
      })
      onClose()
      toast.success('Shortlist created successfully!')
    } catch (_) {
      errorToast("Couldn't create shortlist")
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <MuiModal isOpen={isOpen} hasRoundedCorners enforcedWidth="medium">
      <CreateShortlistModalBase
        {...props}
        error={error}
        isProcessing={isProcessing}
        onCreate={createShortlist}
        onClose={onClose}
      />
    </MuiModal>
  )
}

export default CreateShortlistModal
