import * as R from 'ramda'
import type {
  ActivityCancelJobType,
  ActivityRequestType,
  ActivityType,
  ActivityTypeUnresolved,
  BusinessType,
} from '../../../src/types/business'
import type { CountryNameType, RegionBasicType, RegionType } from '../../../src/types/common'
import type { Timestamp } from '../../../src/types/firebase'
import type { CustomPositionType } from '../../../src/types/groups'
import type { EnumJobType, JobPositionType } from '../../../src/types/jobTypes'
import type {
  JobType,
  ShiftType,
  ShiftTypeBasic,
  ShiftTypeWithJob,
  ShiftTypeWithJobAndJobStaffer,
} from '../../../src/types/jobs'
import type { RegionalPaymentDetails, StafferTypePaymentDetails } from '../../../src/types/staffer'
import { getHourlyServiceChargeByCountry, socialCostRateByCountry } from '../staffers/api/JobCostInfo'
import moment from '../util/moment'
import { isHoliday } from './holidays'
import { formatCurrency, formatCurrencyInt } from './localStringFormatter'
import { getCurrencyByCountry } from './regionSelector'

export const filterShiftsWithStatus = (status: string, stafferId?: string | null) => {
  if (stafferId) {
    // Filtering for staffer
    return (shift: ShiftTypeWithJobAndJobStaffer): boolean => {
      const { staffer } = shift
      const isClockedOut = !!(staffer && staffer.clockOut && (staffer.clockOut.time || staffer.clockOut.timeEdited))

      const isBeforeShiftEnd = moment().isBefore(moment(shift.timeEnd).add('1', 'day'))
      switch (status) {
        case 'upcoming':
          return (
            !!staffer &&
            (staffer.status === 'requested' ||
              staffer.status === 'accepted' ||
              (staffer.status === 'confirmed' && !isClockedOut && isBeforeShiftEnd))
          )
        case 'completed':
          return !!staffer && (staffer.status === 'missed' || isClockedOut || !isBeforeShiftEnd)
        case 'available':
          return moment().isBefore(moment(shift.timeEnd))
        case 'unpublished':
          return true
        default:
          return false
      }
    }
  }

  // Filtering for business
  return (shift: ShiftTypeWithJob): boolean => {
    const isIncoming = moment().subtract(8, 'hours').isBefore(moment(shift.timeEnd))
    const isConfirmed = shift.staffersConfirmed.length > 0
    // Shift cannot be incoming in order to be approved, otherwise it won't be displayed at all
    const isApproved = shift.isApproved && !isIncoming

    switch (status) {
      case 'upcoming':
        // this was previously separated to pending and upcoming
        return isIncoming && (!isConfirmed || (isConfirmed && !isApproved))
      case 'completed':
        return !isIncoming || !!(isConfirmed && isApproved)
      case 'available':
        return isIncoming
      case 'unpublished':
      case 'deleted':
        return true
      default:
        return false
    }
  }
}

export const getActivityBadgeCounts = (unseenActivities?: ActivityType[]) => {
  const unseenTemporary = unseenActivities
    ? (unseenActivities as ActivityRequestType[]).filter(
        ({ jobType, type }) => type === 'job_request' && jobType === 'temporary'
      ).length
    : 0
  const unseenFreelance = unseenActivities
    ? (unseenActivities as ActivityRequestType[]).filter(
        ({ jobType, type }) => type === 'job_request' && jobType === 'freelance'
      ).length
    : 0
  const unseenJobAds = unseenActivities
    ? (unseenActivities as ActivityRequestType[]).filter(
        ({ jobType, type }) => type === 'job_request' && jobType === 'ad'
      ).length
    : 0
  const unresolvedTempJobs = unseenActivities
    ? (unseenActivities as ActivityTypeUnresolved[]).filter(
        ({ jobType, type }) => type === 'unresolved' && jobType === 'temporary'
      ).length
    : 0
  const unresolvedFreelanceJobs = unseenActivities
    ? (unseenActivities as ActivityTypeUnresolved[]).filter(
        ({ jobType, type }) => type === 'unresolved' && jobType === 'freelance'
      ).length
    : 0
  const unseenTemporaryCancellations = unseenActivities
    ? (unseenActivities as ActivityCancelJobType[]).filter(
        ({ jobType, type }) => type === 'job_cancel' && jobType === 'temporary'
      ).length
    : 0
  const unseenFreelanceCancellations = unseenActivities
    ? (unseenActivities as ActivityCancelJobType[]).filter(
        ({ jobType, type }) => type === 'job_cancel' && jobType === 'freelance'
      ).length
    : 0
  return {
    unseenTemporary,
    unseenFreelance,
    unseenJobAds,
    unresolvedTempJobs,
    unresolvedFreelanceJobs,
    unseenTemporaryCancellations,
    unseenFreelanceCancellations,
  }
}

export const getAllJobTypes = (jobTypes: Array<EnumJobType>): Array<string> =>
  R.sort((typeA: string, typeB: string): number => typeA.localeCompare(typeB))(
    (jobTypes || []).reduce(
      (allTypes: Array<string>, { name, subTypes }) =>
        allTypes.concat(subTypes && subTypes.length > 0 ? subTypes : name),
      []
    )
  )

// adds social costs fee
// important: !use only in the job creation
//   for rendering wage of existing job use one of the stored values:
//     job.calculatedCost.hourly.salaryHourly
//     job.calculatedCost.total.total
export const withSocialCost = (netSalaryHourly: number, countryName: CountryNameType = 'Norway') => {
  const rate = socialCostRateByCountry[countryName] || socialCostRateByCountry.Norway
  const result = netSalaryHourly * (1 + rate)
  return result
}

// reverse function of withSocialCost
// important: !use only in the job creation
//   for rendering wage of existing job use one of the stored values:
//     job.calculatedCost.hourly.salary (same as just job.salaryHourly)
//     job.calculatedCost.total.salary
export const withoutSocialCost = (salaryHourly: number, countryName: CountryNameType = 'Norway') => {
  const rate = socialCostRateByCountry[countryName] || socialCostRateByCountry.Norway
  const result = salaryHourly / (1 + rate)
  return result
}

// adjust the number in a way that if withousSocialCost is applied the result should be round
// eg: withoutSocialCost(240) === 199.2 but
// withoutSocialCost(adjustForRoundness(240)) === withoutSocialCost(239.75903614457832) === 199
export const adjustForRoundness = (n: number, countryName: CountryNameType = 'Norway') =>
  withSocialCost(Math.floor(withoutSocialCost(n, countryName)), countryName)

export const getManHours = (shifts: Array<ShiftType>): number =>
  shifts.reduce(
    (sum: number, shift: ShiftType) =>
      sum + shift.positions * moment(shift.timeEnd).diff(moment(shift.timeStart), 'hours', true),
    0
  )

export const toNetHourlyWageText = (
  n: number,
  isFreelanceJob: boolean,
  round?: boolean,
  countryName: CountryNameType = 'Norway'
) => (round ? formatCurrencyInt : formatCurrency)(isFreelanceJob ? withoutSocialCost(n, countryName) : n)

// Determines whether payment details should be hidden
export const shouldHidePaymentDetails = (
  business: BusinessType | null,
  completedJobs: JobType[] | null,
  isAlumniOf: string[],
  upcomingJobs: JobType[] | null
) => {
  if (!completedJobs || !upcomingJobs || !business) {
    return true // anonymize before the data are fetched, to prevent accidental "info leaks"
  }
  // Only temp. jobs are checked here, since freelance jobs are invoiced by Staffers AS
  const hadJobRecently = completedJobs
    .filter((job) => job && !job.isFreelanceJob)
    .some(
      (job) =>
        job.businessId === business.id &&
        moment(job.timeEnd || undefined)
          .add(3, 'months')
          .isAfter(moment())
    )
  const hasUpcomingJob = upcomingJobs
    .filter((job) => job && !job.isFreelanceJob)
    .some((job) => job.businessId === business.id && moment(job.timeEnd || undefined).isAfter(moment()))

  return isAlumniOf.length === 0 && !hadJobRecently && !hasUpcomingJob
}

export const isStafferEligibleForWorkInCountry = (country: string, stafferDetails: StafferTypePaymentDetails) => {
  const detailsCountry = (stafferDetails.regionalDetails || {})[country]
  const detailsCheck = (details: StafferTypePaymentDetails | RegionalPaymentDetails) =>
    details &&
    details.accountNumber &&
    details.identityNumber &&
    details.address &&
    details.address.country &&
    details.address.city &&
    details.address.postalCode &&
    details.address.street
  const correctlyFilledInPaymentDetails = detailsCheck(detailsCountry)
  const verifiedTaxCard = !!(detailsCountry && detailsCountry.taxCardVerified)
  const passedLegacyCheck = country === 'Norway' ? detailsCheck(stafferDetails) : false
  return !!(correctlyFilledInPaymentDetails || verifiedTaxCard || passedLegacyCheck)
}

export const isHolidayJob = (shifts: ShiftType[]) =>
  shifts.length > 0 && shifts.every(({ timeStart, timeEnd }) => isHoliday(timeStart) || isHoliday(timeEnd))

export type CalculatorParams = Pick<JobType, 'shifts' | 'region' | 'isFreelanceJob' | 'isPublic'> & {
  netSalary: number // === withoutSocialCost(salaryHourly, region.countryName)
  createdAt?: Timestamp
}

export const costCalculator = ({
  shifts,
  netSalary, // salaryHourly without social costs
  region,
  isFreelanceJob,
  isPublic,
  createdAt,
}: CalculatorParams) => {
  // Constants: These are given
  const { countryName = 'Norway' } = region || {}
  const hours = getManHours(shifts)
  const currency = getCurrencyByCountry(countryName)

  // Hourly calculations
  const serviceChargeHourly = getHourlyServiceChargeByCountry(
    countryName,
    isPublic === true && isHolidayJob(shifts),
    moment(createdAt)
  )

  const freeShifts = shifts.reduce((res, { isFree }) => (isFree ? res + 1 : res), 0)
  const noServiceCharge =
    freeShifts > 0 ? `${freeShifts} / ${shifts.length} are free of serviceCharge as a promotional offer.` : ''

  const salaryHourly = withSocialCost(netSalary, countryName)
  const socialCost = isFreelanceJob ? salaryHourly - netSalary : 0
  const hourlyTotal: number = netSalary + socialCost + (freeShifts ? 0 : serviceChargeHourly)

  const chargedHours = getManHours(shifts.filter(({ isFree }) => !isFree))

  // Total calculations:
  const salary = netSalary * hours
  const salaryCost = salaryHourly * hours
  const social = socialCost * hours
  const serviceCharge = serviceChargeHourly * chargedHours
  const total = salary + social + serviceCharge

  return {
    constants: {
      hours,
      currency,
      countryName,
      noServiceCharge,
      isHoliday: isHolidayJob(shifts),
      isPrivate: !isPublic,
      socialCostRate: isFreelanceJob ? socialCostRateByCountry[countryName] : 0,
    },
    hourly: {
      salary: netSalary,
      salaryCost: withSocialCost(netSalary, countryName), // somewhat misleading name, but used all over the app - legacy
      social: socialCost,
      serviceCharge: serviceChargeHourly,
      total: hourlyTotal,
    },
    total: {
      salary,
      social,
      salaryCost,
      serviceCharge,
      total,
    },
  }
}

export const minEventWage = (
  jobTypeInfo: JobPositionType | CustomPositionType,
  region: RegionBasicType | RegionType
): number => {
  const { countryName } = region
  if ('skillLevels' in ((jobTypeInfo || {}) as CustomPositionType)) {
    // check if this is a custom position
    return jobTypeInfo.freelanceWage || jobTypeInfo.wage
  }

  const { regionalMinWage } = jobTypeInfo as JobPositionType
  return regionalMinWage[countryName] || regionalMinWage.Norway
}

export const defaultEventWage = (
  jobTypeInfo: JobPositionType | CustomPositionType,
  region: RegionBasicType | RegionType | { countryName: 'Norway' | 'Sweden' }
): number => {
  const { countryName } = region
  if ('skillLevels' in ((jobTypeInfo || {}) as CustomPositionType)) {
    // check if this is a custom position
    return jobTypeInfo.freelanceWage || jobTypeInfo.wage
  }

  const { regionalDefaultWage } = jobTypeInfo as JobPositionType
  return regionalDefaultWage[countryName] || regionalDefaultWage.Norway
}

export const getShiftId = (shift: ShiftTypeBasic): string => {
  const timeStart = moment.utc(shift.timeStart.toMillis()).startOf('minute')
  const timeEnd = moment.utc(shift.timeEnd.toMillis()).startOf('minute')
  return timeStart.format('YYYYMMDDHHmm') + timeEnd.format('MMDDHHmm')
}
