import { getAuth } from 'firebase/auth'
import { deleteDoc } from 'firebase/firestore'
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useState } from 'react'
import { useRefinementList } from 'react-instantsearch'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { Shortlist } from '../../../../src/types/shortlist'
import { PATHS } from '../../constants/routes'
import { errorToast } from '../../helpers/toast'
import { getShortlistById } from '../../staffers/api/getters/shortlists'
import { shortlists } from '../../staffers/qman/shortlists'
import AddToShortlistModal from '../elasticSearch/shortlist/AddToShortlistModal'
import CreateShortlistModal from '../elasticSearch/shortlist/CreateShortlistModal'
import DeleteConfirmationModal from '../elasticSearch/shortlist/DeleteConfirmationModal'
import ShortlistDetailModal from '../elasticSearch/shortlist/ShortlistDetailModal'
import ShortlistListModal from '../elasticSearch/shortlist/ShortlistListModal'

type ShortlistsContextType = {
  // undefined is for the case when shortlists is being fetched
  shortlists?: Array<Shortlist> | null
  showAllShortlists: VoidFunction
  addToShortlist: (stafferId: string) => void
  openEditShortlist: (shortlist: Shortlist) => void
  filterByShortlist: (shortlist: Shortlist | null) => void
  filteredByShortlist: Shortlist | null
}

// eslint-disable-next-line no-shadow
enum ModalType {
  Create,
  List,
  Detail,
  Add,
  Remove,
}

const DEFAULT_SHORTLISTS_CONTEXT: ShortlistsContextType = {
  shortlists: null,
  showAllShortlists: () => null,
  addToShortlist: () => null,
  openEditShortlist: () => {},
  filterByShortlist: () => {},
  filteredByShortlist: null,
}

const ShortlistsContext = createContext<ShortlistsContextType>(DEFAULT_SHORTLISTS_CONTEXT)

export const useShortlists = () => {
  const shortlistsCtx = useContext(ShortlistsContext)

  if (!shortlistsCtx) {
    throw new Error('useShortlists has to be used within <ShortlistsProvider>')
  }

  return shortlistsCtx
}

// Provider mainly handles opening and closing modals
const ShortlistsProvider = ({ children, location, history }: PropsWithChildren<object> & RouteComponentProps) => {
  const [openedModal, setOpenedModal] = useState<ModalType | null>(null)
  const [currentlyAddingStafferIds, setCurrentlyAddingStafferIds] = useState<string | Array<string> | undefined>(
    undefined
  )

  const [currentlyEditingShortlist, setCurrentlyEditingShortlist] = useState<Shortlist | null>(null)
  const [currentlySearchingShortlist, setCurrentlySearchingShortlist] = useState<Shortlist | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [shortList, setSelectedShortlist] = useState<Shortlist | null>(null)

  const shortlistFilter = useRefinementList({
    attribute: 'shortlist',
  })

  // fetched shortlists
  const data = shortlists.get('shortlists', [])

  // if there are no shortlists, open create shortlist modal
  const shouldOpenCreateModal = (data ?? []).length === 0

  // if there are shortlists, show list modal, otherwise show create shortlist
  const showAllShortlists = () => {
    setOpenedModal(shouldOpenCreateModal ? ModalType.Create : ModalType.List)
  }

  const addToShortlist = (stafferIds: string | Array<string>) => {
    setCurrentlyAddingStafferIds(stafferIds)
    setOpenedModal(ModalType.Add)
  }

  const onRedirectToCreate = () => {
    if (currentlyAddingStafferIds) {
      setOpenedModal(ModalType.Create)
    }
  }

  const openEditShortlist = (list: Shortlist) => {
    setCurrentlyEditingShortlist(list)
    setOpenedModal(ModalType.Detail)
  }

  const closeCreateModal = () => {
    setOpenedModal(currentlyAddingStafferIds ? ModalType.Add : null)
  }

  const filterByShortlist = (shortlist: Shortlist | null) => {
    setCurrentlySearchingShortlist(shortlist)
    setOpenedModal(null)
    if (shortlist && currentlySearchingShortlist !== null) {
      // toggle of the current one first
      shortlistFilter.refine(currentlySearchingShortlist.id)
    }
    shortlistFilter.refine(
      !shortlist
        ? currentlySearchingShortlist?.id ?? '' // off
        : shortlist.id // on
    )
    if (!location.pathname.includes(PATHS.staffersSearch)) {
      history.push(PATHS.staffersSearch)
    }
  }

  const deleteShortlist = async () => {
    try {
      const adminId = getAuth().currentUser?.uid
      if (!adminId || !shortList?.id) return

      // assume that shortlistId and stafferId are valid and documents exist

      setIsProcessing(true)
      const shortlistRef = getShortlistById(shortList.id)
      await deleteDoc(shortlistRef)
      toast.success('Shortlist has been deleted')
      setOpenedModal(null)
    } catch (error) {
      errorToast('Shortlist could not be deleted')
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <ShortlistsContext.Provider
      value={{
        showAllShortlists,
        addToShortlist,
        shortlists: data,
        filterByShortlist,
        openEditShortlist,
        filteredByShortlist: currentlySearchingShortlist,
      }}
    >
      <CreateShortlistModal
        isOpen={openedModal === ModalType.Create}
        onClose={closeCreateModal}
        shortlists={data ?? []}
      />
      <AddToShortlistModal
        isOpen={openedModal === ModalType.Add}
        onClose={() => {
          setCurrentlyAddingStafferIds(undefined)
          setOpenedModal(null)
        }}
        shortlists={data ?? []}
        onRedirectToCreate={onRedirectToCreate}
        stafferIds={currentlyAddingStafferIds}
        onDeleteShortlist={(shortList) => {
          setSelectedShortlist(shortList)
          setOpenedModal(ModalType.Remove)
          return
        }}
      />
      <ShortlistListModal
        onSearchShortlist={filterByShortlist}
        onEditShortlist={openEditShortlist}
        isOpen={openedModal === ModalType.List}
        shortlists={data ?? []}
        onClose={() => setOpenedModal(null)}
      />
      <ShortlistDetailModal
        key={currentlyEditingShortlist?.id} // avoid hook order error
        isOpen={openedModal === ModalType.Detail && currentlyEditingShortlist !== null}
        onClose={() => {
          setOpenedModal(null)
          setCurrentlyEditingShortlist(null)
        }}
        shortlist={currentlyEditingShortlist}
      />
      <DeleteConfirmationModal
        isOpen={openedModal === ModalType.Remove}
        onClose={() => {
          setOpenedModal(null)
        }}
        onConfirm={() => {
          deleteShortlist()
          return
        }}
        shortlistName={shortList?.name ?? ''}
        isProcessing={isProcessing}
      />
      {children}
    </ShortlistsContext.Provider>
  )
}

export default withRouter(ShortlistsProvider)
