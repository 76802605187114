/* eslint-disable react/jsx-one-expression-per-line */
// (non freelance) work agreement for Sweden region (both English and Swedish language)

import { Typography } from '@material-ui/core'
import { Fragment } from 'react'
import style from '../modals/modals.module.css'

type Values = {
  jobType: string
  registeredName?: string
  businessName: string
  orgNumber: string
  businessAddress: string
  stafferNameFull: string
  identityNumber: string
  jobDateStart: string
  jobDateEnd: string
  jobHourlyWage: string
  employmentReason: string
  businessPayoutDate: string
  jobRequirementsTranslated: Record<'en' | 'no', Array<string>>
}

const English = ({
  jobType,
  registeredName,
  businessName,
  orgNumber,
  businessAddress,
  stafferNameFull,
  identityNumber,
  jobDateStart,
  jobDateEnd,
  jobHourlyWage,
  jobRequirementsTranslated,
}: Values) => (
  <Fragment>
    <Typography component="h6" variant="h6" className={style.agreementHeader}>
      Contract of fixed term employment
    </Typography>
    <div className={style.agreementWrapper}>
      <p>
        The Employment Contract is entered this day between the Employer as stated in <strong>Appendix 1</strong> and
        the Employee as stated in <strong>Appendix 2</strong> and in accordance with the at all-time applicable salary
        and working conditions set out in <strong>Appendix 3</strong>.
        <br />
        <br />
        <strong>1. Employment and start of the employment</strong>
        <br />
        <br />
        The Employee’s position is stated in Appendix 3.
        <br />
        <br />
        The employment is a special fixed term employment (Sw. särskild visstidsanställning).
        <br />
        <br />
        The employment begins on the start date and ends on the end date as set out in Appendix 3 without prior notice.
        <br />
        <br />
        <strong>2. Job description</strong>
        <br />
        <br />
        The Employee shall at all-time perform the applicable duties and work tasks that are part of the position set
        out in Appendix 3.The Employee’s job description is not intended to constitute a complete list of the Employee’s
        duties and the Employee may be required to carry out other duties consistent with the Employee’s position.
        <br />
        <br />
        <strong>3. Place of work</strong>
        <br />
        <br />
        The place of work is currently at the Employer’s premises in the place stated in Appendix 3. The Employee must
        be prepared to travel both in Sweden and abroad if the position requires it.
        <br />
        <br />
        <strong>4. Working hours</strong>
        <br />
        <br />
        Working hours are regulated by the applicable terms and conditions as set out in Appendix 3.
        <br />
        <br />
        <strong>5. Salary and other remuneration</strong>
        <br />
        <br />
        Salary and other remuneration are as set out in Appendix 3. The salary is paid at the end of each month into the
        bank account designated by the Employee. The Employer will pay statutory social charges (Sw.
        arbetsgivaravgifter) in accordance with the Swedish Social Fee Act (Sw. socialavgiftslagen).
        <br />
        <br />
        The Employee shall be entitled to sick pay in accordance with the Swedish Sick Pay Act (Sw. sjuklönelagen).
        <br />
        <br />
        <strong>6. Deduction in salary</strong>
        <br />
        <br />
        The Employer shall be entitled at any time during the employment, or in any event on termination, to deduct from
        the remuneration hereunder any monies due from the Employee to the Employer, including but not limited to any
        outstanding loans, advances, taxes, the cost of repairing any damage or loss to the Employer’s property caused
        by the Employee (and of recovering the same), and any other monies owed by the Employee to the Employer. The
        Employee shall be notified before such deduction is made.
        <br />
        <br />
        <strong>7. Pension and insurances</strong>
        <br />
        <br />
        The Employee is covered by the Employer’s at all-time applicable pension and insurance coverages in accordance
        with the Employer’s policy as applicable from time to time, at all times provided that theEmployee is qualified
        for the coverages.
        <br />
        <br />
        <strong>8. Holiday leave and holiday pay</strong>
        <br />
        <br />
        [IF THE TERM IS LONGER THAN 3 MONTHS: The right to holidays and holiday allowance, as well as the provisions for
        the determination of holiday periods, is governed by the Swedish Annual Leave Act (Sw. semesterlagen). Holiday
        scheduling shall be approved in advance by the Employer.] [IF THE TERM IS UP TO3 MONTHS: Due to the short term
        of employment, the Employee is not entitled to time off for vacation. Instead, compensation for accrued vacation
        will be paid out in connection with the expiration of the employment.]
        <br />
        <br />
        <strong>9. Confidentiality</strong>
        <br />
        <br />
        The Employee undertakes to refrain from disclosing, as well as to prevent others from gaining access to, or
        knowledge of, confidential information, unless otherwise stipulated in specific legislation.
        <br />
        <br />
        By confidential information is meant all information which has come to the knowledge of the Employee, which is
        not in the public domain, and which the Employee ought to understand, based on a prudent assessment, that it may
        be of importance for the Employer to keep confidential.
        <br />
        <br />
        No form of reproduction and/or copying of business-related documents for private use is permitted without the
        permission of superior officers representing the Employer.
        <br />
        The duty of confidentiality shall remain in full force and effect after termination of the employment. Nor shall
        the Employee use or exploit any information falling within the scope of the duty of confidentiality in his/her
        own business or in any service or work performed for others.
        <br />
        <br />
        <strong>10. Miscellaneous</strong>
        <br />
        <br />
        Upon termination of the employment, or earlier if so requested by the Employer, the Employee will immediately to
        the Employer return any and all property belonging to the Employer, such as access keys, mobile phone, uniform,
        etc.
        <br />
        <br />
        <strong>11. Collective bargaining agreement</strong>
        <br />
        <br />
        No collective agreement is applicable to the employment.
        <br />
        <br />
        <strong>12. Entire agreement and amendments</strong>
        <br />
        <br />
        This Agreement constitutes the entire agreement between the parties and supersedes any written or oral agreement
        between the Employer and the Employee on the matters dealt with in this Agreement. Amendments to this Agreement
        shall be in writing and signed by both parties to be valid.
        <br />
        <br />
        <strong>13. Governing law and dispute resolution</strong>
        <br />
        <br />
        This Agreement shall be governed by Swedish law. Any controversy arising out of this Agreement shall be solved
        in Swedish General Courts with the Stockholm City Court as the court of first instance.
        <br />
        <br />
        <strong>Appendix 1</strong>
        <br />
        <br />
        &lt;The employer&gt; <strong>{registeredName || businessName}</strong>
        <br />
        &lt;Org. no.&gt; <strong>{orgNumber}</strong>
        <br />
        &lt;Address&gt; <strong>{businessAddress}</strong>
        <br />
        <br />
        <strong>Appendix 2</strong>
        <br />
        <br />
        &lt;The employee&gt; <strong>{stafferNameFull}</strong>
        <br />
        &lt;Personal identity no.&gt; <strong>{identityNumber}</strong>
        <br />
        <br />
        <strong>Appendix 3</strong>
        <br />
        <br />
        Job information:
        <br />
        &lt;Position&gt; <strong>{jobType}</strong>
        <br />
        {jobRequirementsTranslated.en?.length > 0 && (
          <Fragment>
            &lt;Description of work tasks&gt;
            <p>{jobRequirementsTranslated.en.map((text: string) => `\t - ${text} \n`)}</p>
            <br />
          </Fragment>
        )}
        &lt;Place of Work&gt; <strong>{businessAddress}</strong>
        <br />
        &lt;Start Date&gt; <strong>{jobDateStart}</strong>
        <br />
        &lt;End Date&gt; <strong>{jobDateEnd}</strong>
        <br />
        &lt;Hourly salary&gt; <strong>{jobHourlyWage}</strong>
        SEK
      </p>
    </div>
  </Fragment>
)

const Swedish = () => (
  <Fragment>
    <strong>Please Select English</strong>
    {/* TODO @Draho */}
  </Fragment>
)

export default {
  English,
  Swedish,
}
