import { Button, IconButton, Tooltip } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import { Close as Remove } from '@material-ui/icons'
import { arrayRemove, updateDoc } from 'firebase/firestore'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import type { AlgoliaStafferType } from '../../../../../src/algolia/staffer'
import { errorToast } from '../../../helpers/toast'
import StarIcon from '../../../img/StarIcon.svg'
import VerifiedUserIcon from '../../../img/VerifiedUserIcon.svg'
import accessBlockedImg from '../../../img/accessBlocked.png'
import BriefcaseIcon from '../../../img/algoliaIcons/BriefcaseIcon.svg'
import ClockIcon from '../../../img/algoliaIcons/ClockIcon.svg'
import { getShortlistById } from '../../../staffers/api/getters/shortlists'
import { staffers as staffersSchema } from '../../../staffers/qman/staffers'
import Avatar from '../../avatar/Avatar'
import { confirm } from '../../modals/ConfirmationModal'
import { useShortlists } from '../../providers/ShortlistsProvider'
import styles from './StafferCard.module.css'
import StafferCardExperiences from './StafferCardExperiences'
import StafferCardFooter from './StafferCardFooter'
import { countExperienceYears, getActivity, getExperience } from './time'

type Props = {
  staffer: AlgoliaStafferType // Type for passing staffer from either Algolia or Qman
  shortlistId?: string
  variant?: 'default' | 'shortlist' | 'invite' | 'offer'
  onSeeMore: () => void
  onStafferDelete?: () => void
}

const StafferCard = ({ staffer, variant = 'default', shortlistId, onSeeMore, onStafferDelete }: Props) => {
  const iconStyle = {
    width: 24,
    height: 24,
    paddingRight: 6,
  }
  const { addToShortlist } = useShortlists()

  const stafferPermissions = staffersSchema.get('stafferPermissions', [staffer.id]) || null

  if (!staffer || stafferPermissions === undefined) {
    return <BeatLoader />
  }

  // eslint-disable-next-line consistent-return
  const onRemoveStaffer = async (stafferIds?: string | string[]) => {
    const ok = await confirm('Are you sure?')
    if (!ok) {
      return 'Canceled'
    }
    if (!shortlistId) {
      return 'No shortlist id'
    }
    try {
      const shortlistRef = getShortlistById(shortlistId)
      // check if already there, if yes display different message
      await updateDoc(shortlistRef, {
        members: arrayRemove(stafferIds),
      })
      onStafferDelete && onStafferDelete()
      toast.success('Jobseeker has been removed from your shortlist')
    } catch (e) {
      errorToast(`Couldn't delete staffer from shortlist: ${e}`)
    }
  }
  const variants = {
    default: {
      variant: 'default' as 'default' | 'invite' | 'offer',
      onAddToShortlist: () => addToShortlist(staffer.objectID),
    },
    invite: {
      variant: 'invite' as 'default' | 'invite' | 'offer',
      onSendInvite: () => {},
      onSendMessage: () => {},
    },
    offer: {
      variant: 'offer' as 'default' | 'invite' | 'offer',
      onOfferShift: () => {},
      onSendMessage: () => {},
    },
  }

  const rating = staffer.rating
    ? staffer.rating.toFixed(2)
    : staffer.ratingsCount > 0
    ? (staffer.ratingsTotal / staffer.ratingsCount).toFixed(2)
    : '-'

  const positions = staffer.positions ? staffer.positions : stafferPermissions ? stafferPermissions.positions : null
  const positionsTotal = positions ? positions.length : stafferPermissions ? stafferPermissions.positions.length : 0
  const lastActivity = getActivity(staffer.lastLoggedAt)

  const experienceYears = staffer.yearsExperience ?? countExperienceYears(staffer.experiences ?? [])
  const experienceYearsStringRepresentation = getExperience(experienceYears)

  const businessWorked = staffer.businessWorked
    ? staffer.businessWorked
    : (staffer.experiences ?? []).map(({ jobCompany, jobTitle }) => `${jobCompany} - ${jobTitle}`)
  const stafferVerified = staffer.verified || staffer.bankId || staffer.documentsVerified

  return (
    <div className={styles.container}>
      {variant === 'shortlist' && (
        <Tooltip title="Remove staffer from shortlist" placement="top-start">
          <IconButton
            size="small"
            style={{
              position: 'absolute',
              top: '2%',
              right: '2%',
              backgroundColor: '#FDE5E5',
              color: '#EF4E4E',
            }}
            onClick={() => onRemoveStaffer(staffer.id)}
          >
            <Remove />
          </IconButton>
        </Tooltip>
      )}
      <div className={styles.content}>
        <div className={styles.rowWrapper} style={{ position: 'relative' }}>
          <Avatar imageUrl={staffer.photoUrl} avatarSize="medium" />
          {staffer.accessBlocked && <img src={accessBlockedImg} alt="access blocked" className={styles.blockedIcon} />}
          <div className={styles.headerContent}>
            <span className={styles.name}>
              {staffer.nameFirst} {staffer.nameLast}
              {stafferVerified && <img className={styles.badge} src={VerifiedUserIcon} alt="verified user" />}
            </span>
            <div className={styles.rowWrapper}>
              <span className={styles.rating}>
                <img src={StarIcon} alt="rating star" /> {rating}
              </span>
              <span className={styles.dot}>·</span>
              <span className={styles.greyText}>{staffer.totalShifts || 0} jobs</span>
              <span className={styles.dot}>·</span>
              <Button onClick={onSeeMore} className={styles.seeMore}>
                See more
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.rowWrapper}>
            <div className={styles.rowWrapper} style={{ paddingRight: 24 }}>
              <img className={styles.badge} src={BriefcaseIcon} alt="experience icon" style={iconStyle} />
              <span className={styles.greyText}>
                Exp.: <span className={styles.boldText}>{experienceYearsStringRepresentation}</span>
              </span>
            </div>
            <div className={styles.rowWrapper}>
              <img className={styles.badge} src={ClockIcon} alt="clock icon" style={iconStyle} />
              <span className={styles.blackText}>{lastActivity}</span>
            </div>
          </div>
        </div>
        <StafferCardExperiences businessWorked={businessWorked} />
        <div className={styles.contentWrapper}>
          <div className={styles.rowWrapper}>
            {positions &&
              positions.map(
                (position: string, index: number) =>
                  index <= 2 && (
                    <Chip
                      label={position}
                      key={index}
                      style={{
                        color: '#737373',
                        backgroundColor: '#F5F5F5',
                        borderRadius: 6,
                        fontWeight: 400,
                        marginRight: index === positionsTotal - 1 ? 0 : 5,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        flexGrow: positionsTotal > 3 ? 1 : 'unset',
                      }}
                    />
                  )
              )}
            {positions && positions.length > 3 && (
              <Chip
                label={`+${positionsTotal - 3}`}
                style={{
                  color: '#737373',
                  backgroundColor: '#F5F5F5',
                  borderRadius: 6,
                  fontWeight: 400,
                }}
              />
            )}
          </div>
        </div>
        {variant !== 'shortlist' && <StafferCardFooter {...variants[variant]} />}
      </div>
    </div>
  )
}

export default StafferCard
