import type { Timestamp } from '../../../../../../src/types/firebase'
import { firestoreHttpsCallable } from './util'

export const editStafferPoolPermissions = async (data: {
  stafferId: string
  businessId: string
  poolPermissions: string[]
  skillLevels?: { [position: string]: string }
  customWage?: number
  contractPercentage?: number
}): Promise<Record<string, any>> => firestoreHttpsCallable('editStafferPoolPermissions', data)

export const createEditPool = async (poolData: {
  name?: string
  businessId: string
  createdAt?: Timestamp | Date
  createdBy?: string
  connectedBusinesses: string[]
  staffers: string[]
  employees: string[]
}) => firestoreHttpsCallable('createEditPool', poolData)

export const editPermissionBusinessData = async (data: {
  stafferId: string
  businessId: string
  skillLevels?: { [position: string]: string }
  customWage?: number
}) => firestoreHttpsCallable('editPermissionBusinessData', data)

export const removeFromPool = async (data: { stafferId: string; businessId?: string }) =>
  firestoreHttpsCallable('removeStafferFromPool', data)

export const markAsAlumni = async (data: { stafferId: string; businessId?: string }) =>
  firestoreHttpsCallable('markAsAlumni', data)

export const changeReviewPermissions = async (data: {
  poolId: string
  reviewPermissions: {
    [businessId: string]: boolean
  }
}) => firestoreHttpsCallable('changeReviewPermissions', data)
