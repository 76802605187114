import { IconButton } from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import type { PropsWithChildren, ReactNode } from 'react'
import styles from './Modal.module.css'

export const ModalFooter = ({ children }: PropsWithChildren<unknown>) => (
  <div className={styles.modalFooter}>{children}</div>
)

export type ModalHeaderProps = {
  onClose: VoidFunction
  title: string
  rightActions?: ReactNode
  leftActions?: ReactNode
}

export const ModalHeader = ({ title, onClose, rightActions, leftActions }: ModalHeaderProps) => (
  <div className={styles.modalHeader}>
    {leftActions && <div className={styles.modalHeaderLeftActions}>{leftActions}</div>}
    <h4 className={styles.modalHeaderTitle}>{title}</h4>
    <div className={styles.modalHeaderRightActions}>
      {rightActions}
      <IconButton onClick={onClose}>
        <CloseRounded />
      </IconButton>
    </div>
  </div>
)
