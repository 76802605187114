import type { InputProps as InputPropsType } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField/TextField'
import { withStyles } from '@material-ui/core/styles'
import type { ChangeEvent, FocusEventHandler, ReactNode, Ref } from 'react'
import { PureComponent } from 'react'

type Props = {
  // The optional - helps users to fill forms faster, especially on mobile devices
  autoComplete?: string
  // If true, the input will be focused during the first mount.
  autoFocus?: boolean
  // Override or extend the styles applied to the component.
  classes?: Record<string, string>
  // The optional CSS class name of the wrapper element.
  className?: string
  // The default input value, useful when not controlling the component.
  children?: ReactNode
  defaultValue?: string | number
  disabled?: boolean
  // If true, the input will indicate an error.
  error?: boolean
  // If true, the input will take up the full width of its container.
  fullWidth?: boolean
  helperText?: string
  // The id of the input element.
  id?: string
  // Properties applied to the input element.
  inputRef?: Ref<any>
  disableUnderline?: boolean
  // props for native input element
  inputProps?: Record<string, unknown>
  label?: string
  // If dense, will adjust vertical spacing. This is normally obtained via context from FormControl.
  margin?: 'dense' | 'none'
  // on true allows user to write multiline text
  multiline?: boolean
  // Name attribute of the input element.
  name?: string
  onChangeValue?: (targetValue: string) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  placeholder?: string
  prefix?: string
  // It prevents the user from changing the value of the field
  readOnly?: boolean
  // If true, the input will be required.
  required?: boolean
  // Number of rows to display when multiline option is set to true.
  rows?: string | number
  // Maximum number of rows to display when multiline option is set to true.
  rowsMax?: string | number
  // The optional input container padding
  select?: boolean
  type?: string
  value?: string | number
}

const styles = {
  root: {
    marginRight: 3,
  },
}

// eslint-disable-next-line react/prefer-stateless-function
class TextInputField extends PureComponent<Props> {
  static defaultProps = {
    placeholder: '',
    disableUnderline: false,
    multiline: false,
  }

  render() {
    const {
      disableUnderline,
      autoComplete,
      autoFocus,
      classes,
      className,
      defaultValue,
      disabled,
      error,
      helperText,
      fullWidth,
      id,
      inputRef,
      inputProps,
      label,
      margin,
      multiline,
      name,
      placeholder,
      prefix,
      readOnly,
      required,
      rows,
      rowsMax,
      select,
      type,
      value,
      onBlur,
      children,
    } = this.props

    const InputProps = {} as InputPropsType

    if (disableUnderline) {
      InputProps.disableUnderline = true
    }

    if (prefix) {
      InputProps.startAdornment = (
        <InputAdornment position="start" classes={{ root: classes && (classes as Record<string, string>).root }}>
          <div>{prefix}</div>
        </InputAdornment>
      )
    }

    return (
      <TextField
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        classes={classes}
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        fullWidth={fullWidth}
        helperText={helperText}
        id={id}
        inputProps={inputProps}
        // inputProps and InputProps are actually different props
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={InputProps}
        inputRef={inputRef}
        label={label}
        margin={margin}
        multiline={multiline}
        name={name}
        onChange={this.onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        // @ts-ignore missing read-only prop in declaration
        readOnly={readOnly}
        required={required}
        rows={rows}
        rowsMax={rowsMax}
        select={select}
        type={type}
        value={value}
      >
        {children}
      </TextField>
    )
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, onChangeValue } = this.props

    if (onChange) {
      onChange(event)
    }

    if (onChangeValue) {
      onChangeValue(event.target.value)
    }
  }
}

export default withStyles(styles)(TextInputField)
