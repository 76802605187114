import styles from './checkbox.module.css'

type Props = {
  name?: string
  checked: boolean
  disabled?: boolean
  onToggleCheckbox: (...args: any) => any
  title?: string
}

const Checkbox = (props: Props) => {
  const { name, checked, disabled, onToggleCheckbox, title } = props

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      name={name}
      className={`${styles.container} ${!title ? styles.noTitle : ''}`}
      onClick={onToggleCheckbox}
      disabled={disabled}
    >
      <input type="checkbox" checked={checked} disabled={disabled} readOnly />
      <span className={`${styles.checkmark} ${!title ? styles.noTitleCheckmark : ''}`} />
      {title}
    </button>
  )
}

export default Checkbox
