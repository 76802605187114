import { Box, Button, Typography } from '@material-ui/core'
import type { Moment } from 'moment'
import React, { Component } from 'react'
import type { DayModifiers } from 'react-day-picker'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import moment from '../../util/moment'
import Modal from '../mui/Modal'
import muiModalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import scheduleStyles from '../mui/Schedule.module.css'
import '../mui/schedule.css'
import { confirm } from './ConfirmationModal'
import styles from './modals.module.css'

type BaseShift = {
  start: Moment
  end: Moment
  positions: number
  breakDuration: number
}

const modalRef = React.createRef()

type Props = {
  closeModal: () => any
  shifts: Array<BaseShift>
  onSelect: (shifts: Array<BaseShift>) => any
}

type State = {
  selectedDay: Moment
}

class TemplateScheduleModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedDay: props.shifts.length > 0 ? moment(props.shifts[0].start).startOf('day') : moment().startOf('day'),
    }
  }

  selectDay = (selectedDay: Date, { isSelectable }: DayModifiers) => {
    if (isSelectable) {
      this.setState({
        selectedDay: moment(selectedDay),
      })
    }
  }

  save = async () => {
    const { onSelect } = this.props

    const adjustedShifts = this.getAddjustedShifts()

    const isInPast = adjustedShifts.some(({ start }) => start.isBefore(moment(), 'day'))
    if (isInPast) {
      const ok = await confirm(
        'Some shifts in the schedule would already passed this week.\nDo you want to continue?',
        ['If yes, passed shifts will be ignored.']
      )
      if (!ok) {
        return
      }
    }

    onSelect(adjustedShifts)
  }

  getAddjustedShifts = () => {
    const { shifts } = this.props
    const { selectedDay } = this.state
    const startTime = moment(shifts[0].start)
    const weekOffset = -startTime.startOf('day').diff(selectedDay.startOf('day'), 'week') // how maky weeks shifted to the future
    return shifts.map(({ start, end, ...shift }) => ({
      ...shift,
      start: moment(start).add(weekOffset, 'week'),
      end: moment(end).add(weekOffset, 'week'),
    }))
  }

  render() {
    const { shifts, closeModal } = this.props
    const { selectedDay } = this.state

    if (shifts.length === 0) {
      return null
    }

    const startTime = moment(shifts[0].start)
    const startWeekDayNumber = startTime.weekday() // non-iso - for compatibility with the DayPicker
    const startWeekDayName = startTime.format('dddd')

    const shiftDates = this.getAddjustedShifts().map(({ start }) => start.toDate())

    const startDay = (day: Date) => selectedDay.isSame(moment(day), 'day')
    const isSelectable = (day: Date) => startWeekDayNumber === moment(day).weekday()

    return (
      <React.Fragment>
        <ModalHeader goBack={closeModal}>Select start day of the schedule</ModalHeader>
        <Box p={3} display="flex" flexDirection="column" className={muiModalStyles.modalContent}>
          <Typography align="center" variant="body1" paragraph>
            {'This template has '}
            {shifts.length === 1 ? (
              <span className={scheduleStyles.hasShift}>{`${shifts.length} shift`}</span>
            ) : (
              <span className={scheduleStyles.hasShift}>{`${shifts.length} shifts`}</span>
            )}
            {`, starting on a ${startWeekDayName}.`}
          </Typography>
          <Typography align="center" variant="body1" paragraph>
            {'Please choose '}
            <span className={scheduleStyles.startDay}>{`which ${startWeekDayName}`}</span>
            {` of the month you want ${shifts.length > 1 ? 'the job' : 'the shift'} to start on.`}
          </Typography>
          <Box alignSelf="center">
            <DayPicker
              modifiers={{
                startDay, // selected day
                isSelectable,
                hasShift: shiftDates,
              }}
              fromMonth={new Date()} // do not allow to go to past
              onDayClick={this.selectDay}
              disabledDays={(day) => !isSelectable(day)}
              firstDayOfWeek={1} // monday
            />
          </Box>
          <Box className={styles.buttonWrapperColumn}>
            <Button onClick={closeModal} className={styles.grayButton} variant="contained">
              cancel
            </Button>
            <Button className={styles.confirmationGreenButton} onClick={this.save} variant="contained">
              Select
            </Button>
          </Box>
        </Box>
      </React.Fragment>
    )
  }
}

// Folowing components allow alternative simplified self-contained usage
// eg:
// import { selectScheduleStart, TemplateScheduleModal } from '../TemplateScheduleModal'
// ...
// const date = await selectScheduleStart(shifts)
// ...
// <TemplateScheduleModal />

type WrapperState = {
  isModalOpen: boolean
  shifts?: Array<BaseShift>
}

// eslint-disable-next-line react/no-multi-comp
class WrappedTemplateScheduleModal extends Component<unknown, WrapperState> {
  promiseInfo: {
    resolve?: (val: any) => any
    reject?: (err?: any) => any
  } = {}

  state = {
    isModalOpen: false,
    shifts: undefined,
  }

  show = async (shifts?: Array<BaseShift>): Promise<null | Array<BaseShift>> =>
    new Promise((resolve, reject) => {
      this.promiseInfo = {
        resolve,
        reject,
      }
      this.setState({
        isModalOpen: true,
        shifts,
      })
    })

  select = (selected: Array<BaseShift>) => {
    const { resolve } = this.promiseInfo
    resolve?.(selected)
    this.setState({ isModalOpen: false })
  }

  cancel = () => {
    const { reject } = this.promiseInfo
    reject?.()
    this.setState({ isModalOpen: false })
  }

  render() {
    const { isModalOpen, shifts } = this.state

    return (
      <Modal
        zIndex={4} // on top of normal modal, but under confirmation modals
        isOpen={isModalOpen}
        contentLabel="Confirmation modal"
        onRequestClose={this.cancel}
      >
        <TemplateScheduleModal onSelect={this.select} closeModal={this.cancel} shifts={shifts || []} />
      </Modal>
    )
  }
}

const selectScheduleStart = async (shifts: Array<BaseShift>): Promise<null | Array<BaseShift>> => {
  if (!modalRef.current) {
    return null
  }
  const modal = modalRef.current as WrappedTemplateScheduleModal
  return modal.show(shifts)
}

const ModalWithRef = () => (
  <WrappedTemplateScheduleModal
    ref={(ref) => {
      ;(modalRef.current as WrappedTemplateScheduleModal | null) = ref
    }}
  />
)

export { ModalWithRef as TemplateScheduleModal, selectScheduleStart }
