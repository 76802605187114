import { Box, Grid, Typography } from '@material-ui/core'
import { Component } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import integrationsAPI from '../staffers/api/integrations'

type Props = RouteComponentProps<{ integrationId: string }>

class IntegrationAuthDone extends Component<Props> {
  componentDidMount() {
    const { match } = this.props
    const { integrationId } = match.params
    const cleanup = integrationsAPI.authDone(integrationId)
    setTimeout(() => {
      global.close()
      cleanup()
    }, 1000)
  }

  render() {
    const { match } = this.props
    const { integrationId } = match.params
    return (
      <Box display="flex" alignItems="center" justifyContent="center" p={6}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Successfully authorized by {integrationId}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center">
              This window might be closed.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default withRouter(IntegrationAuthDone)
