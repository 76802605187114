import { Button, IconButton, Modal } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { updateDoc } from 'firebase/firestore'
import moment from 'moment'
import { useState } from 'react'
import { BeatLoader } from 'react-spinners'
import type { BusinessType } from '../../../../../src/types/business'
import type { EnumJobType } from '../../../../../src/types/jobTypes'
import type { ShiftType } from '../../../../../src/types/jobs'
import type { ManagerType } from '../../../../../src/types/manager'
import { getBusinessActivityById } from '../../../staffers/api/getters/business'
import { getJobTypes } from '../../../staffers/api/getters/common'
import { connectFirestore } from '../../../staffers/qman/connectFirestore'
import CreateEditJob from '../CreateEditJob'
import styles from './PushInternalShiftToExternal.module.css'

type Props = {
  business: BusinessType
  businessId: string
  jobId: string
  manager: ManagerType
  jobType: string
  customLabel?: string
  jobTypes: { values: Array<EnumJobType> }
  shifts: ShiftType[]
  description: string
}

const PushInternalShiftToExternal = ({
  business,
  businessId,
  jobId,
  manager,
  jobType,
  jobTypes,
  customLabel,
  shifts,
  description,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true)
  const [createFreelanceJob, setCreateFreelanceJob] = useState(false)

  if (!business || !manager || !jobTypes) {
    return <BeatLoader />
  }

  const startTime = shifts[0].timeStart?.toDate()
  const startTimeFormated = moment(startTime).format('HH:mm')
  const endTime = shifts[0].timeEnd?.toDate()
  const endTimeFormated = moment(endTime).format('HH:mm')
  const date = moment(startTime).format('DD MMMM')

  const onPressNo = async () => {
    const activityRef = await getBusinessActivityById(businessId, `${jobId}:offer`)
    await updateDoc(activityRef, {
      popUpAnswered: true,
    })
  }

  return !createFreelanceJob ? (
    <Modal open={isOpen}>
      <div className={styles.modalContent}>
        <IconButton onClick={() => setIsOpen(false)} className={styles.close}>
          <Close />
        </IconButton>
        <div>
          <h1 className={styles.title}>Do you want to try posting this job to our external marketplace?</h1>
          <p className={styles.position}>
            <span className={styles.subtitle}>Position details:</span>
            <br />
            <span className={styles.positionDetails}>
              {`${jobType}, ${date}, ${startTimeFormated} - ${endTimeFormated}`}
            </span>
          </p>
          <p className={styles.description}>Unfortunately you have not found anyone inside the organization.</p>
          <div className={styles.buttonContainer}>
            <Button color="secondary" variant="outlined" onClick={() => onPressNo()} className={styles.button}>
              No
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setCreateFreelanceJob(true)}
              className={styles.button}
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <Modal open={isOpen}>
      <div className={styles.modal}>
        <CreateEditJob
          createFreelanceJob={createFreelanceJob}
          manager={manager}
          jobTypes={jobTypes}
          closeModal={() => setIsOpen(false)}
          cancel={() => setIsOpen(false)}
          hideBackButton
          onSave={() => setIsOpen(false)}
          managerId={manager.userId}
          businessId={businessId}
          internalToExternalShifts={shifts}
          internalToExternalJobType={jobType}
          internalToExternalCustomLabel={customLabel}
          internalToExternalActivityId={`${jobId}:offer`}
          internalToExternalDescription={description}
        />
      </div>
    </Modal>
  )
}

export default connectFirestore(
  () => ({
    jobTypes: getJobTypes(),
  }),
  PushInternalShiftToExternal
)
