import type { Timestamp } from '../../../src/types/firebase'
import moment from '../util/moment'

export const formatChatParticipantsLimited = (
  participants: Array<{
    nameFirst: string
    nameLast: string
    photoUrl: string
    businessName: string
    uid: string
  }>
): string => {
  const orderedParticipants = [
    ...participants.filter(({ businessName }) => businessName),
    ...participants.filter(({ businessName }) => !businessName),
  ].filter(({ businessName, nameFirst, nameLast }) => businessName || (nameFirst && nameLast))
  if (orderedParticipants.length === 1) {
    const { businessName, nameFirst, nameLast } = orderedParticipants[0]
    return businessName || `${nameFirst} ${nameLast[0]}.`
  }
  if (orderedParticipants.length === 2) {
    const { businessName, nameFirst, nameLast } = orderedParticipants[0]
    const firstParticipant = businessName || `${nameFirst} ${nameLast[0]}.`
    const secondParticipant =
      orderedParticipants[1].businessName ||
      `${orderedParticipants[1].nameFirst} ${orderedParticipants[1].nameLast[0]}.`
    return `${firstParticipant} and ${secondParticipant}`
  }

  if (orderedParticipants.length > 2) {
    const { businessName, nameFirst, nameLast } = orderedParticipants[0]
    const firstParticipant = businessName || `${nameFirst} ${nameLast[0]}.`
    const secondParticipant =
      orderedParticipants[1].businessName ||
      `${orderedParticipants[1].nameFirst} ${orderedParticipants[1].nameLast[0]}.`
    return `${firstParticipant}, ${secondParticipant} + ${orderedParticipants.length - 2}`
  }

  return ''
}

export const formatReducedList = (items: Array<string>, limit = 3, more = 'more') =>
  items
    .filter((_, i, { length }) => length <= limit || i < limit - 1)
    .reduce(
      (sublist, name, i, { length }) =>
        (i === 0 &&
          `${name}${length === 1 && items.length > length ? ` + ${items.length - limit + 1} ${more}` : ''}`) ||
        (i < length - 1 && `${sublist}, ${name}`) ||
        `${sublist} and ${name}${items.length > length ? ` + ${items.length - limit + 1} ${more}` : ''}`,
      ''
    )

export const getDeleteInTime = (deleteAt: Timestamp | null) => {
  const deleteAtTime = deleteAt && moment(deleteAt.toMillis?.() || deleteAt)
  if (deleteAtTime) {
    if (deleteAtTime.diff(moment(), 'day') > 2) {
      return `${deleteAtTime.diff(moment(), 'day')} days`
    }
    if (deleteAtTime.diff(moment(), 'hour') > 1) {
      return `${deleteAtTime.diff(moment(), 'hour')} hours`
    }
    return `${deleteAtTime.diff(moment(), 'hour')} hour`
  }
  return ''
}

export const getDeleteStafferMessage = (deleteAt: Timestamp | null) => {
  if (deleteAt) {
    return `Due to the fact that the staffer has deleted their profile,
            their personal information is about to be limited in ${getDeleteInTime(deleteAt)}.
            In case there are any, we advise you to resolve their payments as soon as possible.`
  }
  return "This staffer's information is now limited."
}
