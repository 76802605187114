import { limit, orderBy, where } from 'firebase/firestore'
import { type IDatabaseCode } from '../../../../../packages/functions/src/types/IDatabaseCodes'
import {
  type RegionType,
  type ServiceChargeType,
  type businessTypes,
  type jobTypes,
} from '../../../../../src/types/common'
import { type IDeviceInfoType } from '../../../../../src/types/devices'
import type {
  FreelanceJobFees,
  JobAdFees,
  JobTypesExperience,
  PositionSkillLevels,
} from '../../../../../src/types/enums'
import { type IErrorType } from '../../../../../src/types/errors'
import { type PoolType } from '../../../../../src/types/pools'
import type { StafferType } from '../../../../../src/types/staffer'
import { type ReferralType } from '../../../../../src/types/stafferReferrals'
import {
  getCollectionRef,
  getDataFromCollectionRef,
  getDataFromDocumentRef,
  getDocumentRef,
  getQueryRef,
} from '../wrappers'

export const getErrorById = (errorId: string) => getDocumentRef<IErrorType>('errors', errorId)

export const getErrorLogs = (docNumberLimit: number) =>
  getQueryRef<IErrorType>('errors', where('isFixed', '==', false), orderBy('reportedAt', 'desc'), limit(docNumberLimit))

export const getDeviceById = (deviceId: string) => getDocumentRef<IDeviceInfoType>('devices', deviceId)

export const getAllCountries = () => getCollectionRef('countries')

export const getAllRegions = () => getCollectionRef<RegionType>('regions')

export const getAvailableRegions = () =>
  getQueryRef<RegionType>('regions', where('enabled', '==', true), where('isTestingEnabled', '==', false))

export const getAvailableRegionsPromise = () =>
  getDataFromCollectionRef<RegionType>(getAvailableRegions(), (doc) => ({
    ...doc.data(),
    id: doc.id,
  }))

export const getTestRegions = () =>
  getQueryRef<RegionType>('regions', where('enabled', '==', true), where('isTestingEnabled', '==', true))

export const getTestRegionsPromise = () =>
  getDataFromCollectionRef<RegionType>(getTestRegions(), (doc) => ({
    ...doc.data(),
    id: doc.id,
  }))

export const getAllRegionsPromise = () =>
  getDataFromCollectionRef<RegionType>(getCollectionRef<RegionType>('regions'), (doc) => ({
    ...doc.data(),
    id: doc.id,
  }))

export const getRegionById = (uid: string) => getDocumentRef<RegionType>('regions', uid)

export const getBusinessTypes = () => getDocumentRef<businessTypes>('enums', 'businessTypes')

export const getJobTypes = () => getDocumentRef<jobTypes>('enums', 'jobTypes')

export const getTranslatedJobTypes = () => getCollectionRef<jobTypes>('enums', 'jobTypes', 'langs')

export const getExperienceJobTypes = () => getDocumentRef<JobTypesExperience>('enums', 'jobTypesExperience')

export const getPositionSkillLevels = () => getDocumentRef<PositionSkillLevels>('enums', 'positionSkillLevels')

export const getJobAdFees = () => getDocumentRef<JobAdFees>('enums', 'jobAdFees')

export const getServiceCharge = () => getDocumentRef<ServiceChargeType>('enums', 'serviceCharge')

export const getFreelanceJobFees = () => getDocumentRef<FreelanceJobFees>('enums', 'freelanceJobFees')

export const getAllInviteCodes = () => getCollectionRef<IDatabaseCode>('inviteCodes')

export const getUsedInviteCodes = () => getQueryRef<IDatabaseCode>('inviteCodes', where('used', '==', true))

export type ICQueryType = {
  property: string
  value: any
}

export const getInviteCodesCustomQuery = (options: Array<ICQueryType>) => {
  switch (options.length) {
    case 1:
      return getQueryRef<IDatabaseCode>('inviteCodes', where(options[0].property, '==', options[0].value))
    case 2:
      return getQueryRef<IDatabaseCode>(
        'inviteCodes',
        where(options[0].property, '==', options[0].value),
        where(options[1].property, '==', options[1].value)
      )
    case 3:
      return getQueryRef<IDatabaseCode>(
        'inviteCodes',
        where(options[0].property, '==', options[0].value),
        where(options[1].property, '==', options[1].value),
        where(options[2].property, '==', options[2].value)
      )
    case 4:
      return getQueryRef<IDatabaseCode>(
        'inviteCodes',
        where(options[0].property, '==', options[0].value),
        where(options[1].property, '==', options[1].value),
        where(options[2].property, '==', options[2].value),
        where(options[3].property, '==', options[3].value)
      )
    default:
      return getAllInviteCodes()
  }
}

export const getReferralsOf = (referredBy: string) =>
  getQueryRef<ReferralType>('referrals', where('referredBy', '==', referredBy))

export const getReferralFromBusiness = (businessId: string) =>
  getDataFromDocumentRef(getDocumentRef('business', businessId)).then((businessData) =>
    businessData && businessData.referringId
      ? getDocumentRef<ReferralType>('referrals', businessData.referringId)
      : null
  )

export const getReferralFromStaffer = (stafferId: string) =>
  getDataFromDocumentRef(getDocumentRef<StafferType>('staffers', stafferId)).then((stafferData) =>
    stafferData && stafferData.referringId ? getDocumentRef<ReferralType>('referrals', stafferData.referringId) : null
  )

export const getReferralById = (referringId: string) => getDocumentRef<ReferralType>('referrals', referringId)

export const getNewReferral = () => getDocumentRef<ReferralType>('referrals')

export const getEmployeePoolsByStafferId = (stafferId: string) =>
  getQueryRef<PoolType>('pools', where('employees', 'array-contains', stafferId))

export const getStafferPoolsByStafferId = (stafferId: string) =>
  getQueryRef<PoolType>('pools', where('staffers', 'array-contains', stafferId))
