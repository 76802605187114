import { Box } from '@material-ui/core'
import {
  Code,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  Title,
} from '@material-ui/icons'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { GridLoader as BeatLoader } from 'react-spinners'
// @ts-ignore
import { EditListPlugin } from '@productboard/slate-edit-list'
import isHotkey from 'is-hotkey'
import type { SyntheticEvent } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import markdown from 'remark-parse'
import slate, { serialize } from 'remark-slate'
import type { Descendant } from 'slate'
import { Element as SlateElement, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import type { RenderElementProps, RenderLeafProps } from 'slate-react'
import { Editable, Slate, useSlate, withReact } from 'slate-react'
import { unified } from 'unified'
import type { CustomText, EmptyText } from '../../custom-types.d'

import mdStyles from '../markdown/Markdown.module.css'
import styles from './RichTextEditor.module.css'

type InlineFormat = 'bold' | 'italic' | 'underline' | 'code'
type BlockFormat = 'heading_one' | 'heading_two' | 'ul_list' | 'ol_list'

const [
  withEditList, // applies normalization to editor
  onKeyDown, // keyDown handler for keyboard shortcuts
  { Editor, Transforms }, // Slate classes with added utility functions and transforms this package provides
] = EditListPlugin()

const HOTKEYS: Record<string, InlineFormat> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES: BlockFormat[] = ['ul_list', 'ol_list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const parseMarkdown = (md: string): any => {
  const nodes = unified().use(markdown).use(slate).processSync(md).result as Descendant[]

  return nodes && nodes.length > 0 ? nodes : [{ type: 'paragraph', children: [{ text: '' }] }]
}

const isBlockActive = (editor: typeof Editor, format: BlockFormat, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  if (LIST_TYPES.includes(format)) {
    const currentListType = (Editor.getCurrentList(editor) || [{}])[0].type
    return format === currentListType
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: any) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        // @ts-ignore
        n[blockType] === format,
    })
  )

  return !!match
}

const toggleBlock = (editor: typeof Editor, format: BlockFormat) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
  const isList = LIST_TYPES.includes(format)

  if (isList) {
    const isSelectedList = Editor.isSelectionInList(editor)
    if (isSelectedList) {
      const currentListType = (Editor.getCurrentList(editor) || [{}])[0].type
      Transforms.unwrapList(editor)
      if (currentListType !== format) {
        // changing list type
        Transforms.wrapInList(editor, format)
      }
    } else {
      Transforms.wrapInList(editor, format)
    }
    return
  }

  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      // @ts-ignore
      type: isActive ? 'paragraph' : format,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)
}

const isMarkActive = (editor: typeof Editor, format: InlineFormat) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format as keyof Omit<CustomText | EmptyText, 'text'>] === true : false
}

const toggleMark = (editor: typeof Editor, format: InlineFormat) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
  onKeyDown(editor)
}

const Element = ({ attributes, children, element }: any) => {
  const style = { textAlign: element.align }
  switch (element.type) {
    case 'heading_one':
      return (
        <h1 className={mdStyles.heading} style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading_two':
      return (
        <h2 className={mdStyles.heading} style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list_item':
      return (
        <li className={mdStyles.li} style={style} {...attributes}>
          {children}
        </li>
      )
    case 'ul_list':
      return (
        <ul className={mdStyles.ul} style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'ol_list':
      return (
        <ol className={mdStyles.ol} style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p className={mdStyles.paragraph} style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }: any) => {
  let wrappedChildren = children
  if (leaf.bold) {
    wrappedChildren = <strong>{wrappedChildren}</strong>
  }

  if (leaf.code) {
    wrappedChildren = <code className={mdStyles.code}>{wrappedChildren}</code>
  }

  if (leaf.italic) {
    wrappedChildren = <em>{wrappedChildren}</em>
  }

  if (leaf.underline) {
    wrappedChildren = <u>{wrappedChildren}</u>
  }

  return <span {...attributes}>{wrappedChildren}</span>
}

const BlockButton = ({ format, icon }: { format: BlockFormat; icon: any }) => {
  const editor = useSlate()
  return (
    <ToggleButton
      className={styles.toggleButton}
      size="small"
      selected={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onMouseDownCapture={(e) => {
        e.preventDefault() // don't focus the button
        toggleBlock(editor, format)
      }}
    >
      {icon}
    </ToggleButton>
  )
}

const MarkButton = ({ format, icon }: { format: InlineFormat; icon: any }) => {
  const editor = useSlate()
  return (
    <ToggleButton
      className={styles.toggleButton}
      size="small"
      selected={isMarkActive(editor, format)}
      onMouseDownCapture={(e) => {
        e.preventDefault() // don't focus the button
        toggleMark(editor, format)
      }}
    >
      {icon}
    </ToggleButton>
  )
}

type Props = {
  onBlur: (event: SyntheticEvent<HTMLDivElement>) => void
  onChange: (markdownValue: string) => any
  placeholder: string
  initValue: string // markdown format
  isLoading: boolean
}

const RichTextEditor = (props: Props) => {
  const { onBlur, placeholder, initValue, onChange, isLoading } = props
  const renderElement = useCallback((elProps: RenderElementProps) => <Element {...elProps} />, [])
  const renderLeaf = useCallback((elProps: RenderLeafProps) => <Leaf {...elProps} />, [])
  const editor = useMemo(() => withEditList(withReact(withHistory(createEditor()))), [])

  const [value, setValue] = useState(() => parseMarkdown(initValue))
  const [focused, setFocused] = useState(false)

  return (
    <Box className={styles.wrapper}>
      <Slate
        editor={editor}
        value={value}
        onChange={(nodes = []) => {
          setValue(nodes)
          const md = nodes.map((node) => serialize(node)).join('')
          onChange(md.replace(/<br>/gi, '\n'))
        }}
      >
        <div className={focused ? styles.focusedTextArea : styles.textArea}>
          <Box mb={2} className={styles.toolbar}>
            <ToggleButtonGroup size="small">
              <MarkButton format="bold" icon={<FormatBold />} />
              <MarkButton format="italic" icon={<FormatItalic />} />
              <MarkButton format="underline" icon={<FormatUnderlined />} />
              <MarkButton format="code" icon={<Code />} />
            </ToggleButtonGroup>
            <ToggleButtonGroup size="small">
              <BlockButton format="heading_one" icon={<Title />} />
              <BlockButton format="heading_two" icon={<Title fontSize="small" />} />
            </ToggleButtonGroup>
            <ToggleButtonGroup size="small" exclusive>
              <BlockButton format="ul_list" icon={<FormatListBulleted />} />
              <BlockButton format="ol_list" icon={<FormatListNumbered />} />
            </ToggleButtonGroup>
          </Box>
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <BeatLoader />
            </Box>
          ) : (
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder || ''}
              spellCheck
              onFocus={() => setFocused(true)}
              onBlur={(e) => {
                setFocused(false)
                onBlur(e)
              }}
              onKeyDown={(event) => {
                let wasHandledByDefaultHotkey = false
                Object.entries(HOTKEYS).forEach(([hotkey, mark]) => {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault()
                    toggleMark(editor, mark)
                    wasHandledByDefaultHotkey = true
                  }
                })
                // If event wasn't handled by default hotkey, let it go to the list handling method
                if (!wasHandledByDefaultHotkey) {
                  onKeyDown(editor)(event)
                }
              }}
            />
          )}
        </div>
      </Slate>
    </Box>
  )
}

const RichTextEditorWrapped = (props: Props) => {
  const [key, setKey] = useState('key')
  useEffect(() => {
    setKey(`key_${Math.floor(Math.random() * 100000)}`)
  }, [props.isLoading])
  return <RichTextEditor key={key} {...props} />
}

export default RichTextEditorWrapped
