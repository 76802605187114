import type { BusinessPermissions } from '../../../src/types/business'

export const BUSINESS_PERMISSIONS: {
  [key in keyof BusinessPermissions]: string
} = {
  postTemporaryJob: 'post temporary shift',
  postFreelanceJob: 'post event job',
  postJobAd: 'post job ad',
  postWithCustomAddress: 'use custom address for jobs',
}

// Those businesses can post jobs with custom address.
// It also determines the default permission for posting jobs with custom location
// (canPostCustomLocationJobs) if not specified by the toggle in super admin
export const CUSTOM_ADDRESS_BUSINESS_TYPES = ['Catering company', 'Catering', 'Event company', 'Festival']
