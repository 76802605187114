import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { Delete, SearchOutlined } from '@material-ui/icons'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as R from 'ramda'
import type { ReactNode } from 'react'
import { Component, Fragment } from 'react'
// @ts-ignore package with no exported types
import AsyncSelect from 'react-select/lib/Async'
// @ts-ignore package with no exported types
import { connectFirestore } from 'react-firestore-connect'
// @ts-ignore package with no exported types
import { components } from 'react-select'
import { BeatLoader } from 'react-spinners'
import type { Firestore } from '../../../../../src/types/firebase'
import type { JobType } from '../../../../../src/types/jobs'
import type { StafferType } from '../../../../../src/types/staffer'
import styles from '../../../components/modals/modals.module.css'
import ModalHeader from '../../../components/mui/ModalHeader'
import { errorToast } from '../../../helpers/toast'
import { searchText } from '../../../staffers/api/firestore/searchText.legacy'
import { getAllJobsByBusinessIdStartingFrom } from '../../../staffers/api/getters/jobs.legacy'
import { getEmployeesInPoolsOf } from '../../../staffers/api/getters/pools.legacy'
import { getStafferByIdPromise } from '../../../staffers/api/getters/staffer.legacy'

type State = {
  isProcessing: boolean
  selectedRecipients: Array<OptionType>
}

type Props = {
  businessId: string
  existingRecipients?: string[]
  newJobs?: JobType[]
  poolEmployees?: string[]
  onClose: () => void
  onConfirm: (data: Array<{ id: string; name: string }>) => void
  children: ReactNode
}

type OptionType = {
  label: string
  value: {
    accessGranted: boolean
    type: string
    id: string
    photoUrl: string
  }
}

const selectStyles = {
  option: (provided: Record<string, string>) => ({
    ...provided,
    color: 'black',
    display: 'flex',
    'flex-direction': 'row',
    'align-items': 'center',
  }),
  control: (provided: Record<string, string>) => ({
    ...provided,
    cursor: 'text',
  }),
  placeholder: (provided: Record<string, string>) => ({
    ...provided,
    'margin-left': '2.5em',
    color: '#AAA',
    'font-size': '14px',
  }),
  menuPortal: (provided: Record<string, string>) => ({
    ...provided,
    zIndex: 999,
  }),
}

const SEARCH_QUERY_MIN_LENGTH = 3

const ReactSelectBuiltInOption = components.Option
const ReactSelectBuiltInValueContainer = components.ValueContainer

class CreateNewChat extends Component<Props, State> {
  state = {
    isProcessing: false,
    selectedRecipients: [],
  }

  // eslint-disable-next-line class-methods-use-this
  renderOption = (props: { data: { label: string } }) => {
    const { label } = props.data

    return <ReactSelectBuiltInOption {...props}>{label}</ReactSelectBuiltInOption>
  }

  // eslint-disable-next-line class-methods-use-this
  renderValueContainer = (props: Props) => {
    const { children } = props
    return (
      <ReactSelectBuiltInValueContainer {...props}>
        <SearchOutlined color="action" />
        {children}
      </ReactSelectBuiltInValueContainer>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  isSearchable = (searchQuery: string): boolean => searchQuery.length >= SEARCH_QUERY_MIN_LENGTH

  getSearchResults = async (searchQueries: string): Promise<OptionType[] | void> => {
    const { businessId, existingRecipients, newJobs, poolEmployees } = this.props
    const { selectedRecipients } = this.state
    const searchResults = [] as OptionType[]
    const queryWords = searchQueries.split(' ')
    // Include initial, whole expression in search as well
    const potentialQueryWords = [searchQueries]
    queryWords.forEach((word) => {
      if (this.isSearchable(word)) {
        // First include searchterm as written
        potentialQueryWords.push(word)
        // Second include searchterm as lowercase
        potentialQueryWords.push(word.toLowerCase())
        // Third include searchterm with first letter in upper case
        potentialQueryWords.push(word.charAt(0).toUpperCase() + word.substring(1))
      }
    })

    await Promise.all(
      potentialQueryWords.map(async (searchQuery) => {
        if (this.isSearchable(searchQuery)) {
          const database = firebase.firestore()
          const businessDocument = database.collection('business').doc(businessId)
          const businessStaffersCollectionRef = businessDocument.collection('staffers')
          const staffersCollection = database.collection('staffers')

          // get businessStaffersCollection stafferIds
          const businessStaffersCollection = await businessStaffersCollectionRef.get()
          const businessStaffersCollectionIds = businessStaffersCollection.docs.map((doc) => doc.id)
          const searchableStaffers = R.uniq([
            ...R.flatten((newJobs || []).map((job) => job.staffersPending)),
            ...(poolEmployees || []),
            ...businessStaffersCollectionIds,
          ]).filter((staffer) => !(existingRecipients || []).includes(staffer))

          const queries = [
            searchText(businessStaffersCollectionRef, 'stafferInfo.nameFirst', searchQuery),
            searchText(businessStaffersCollectionRef, 'stafferInfo.nameLast', searchQuery),
            searchText(staffersCollection, 'nameLast', searchQuery),
            searchText(staffersCollection, 'nameFirst', searchQuery),
          ]

          await Promise.all(
            queries.map(async (query) => {
              try {
                const results = await query.get()
                await Promise.all(
                  results.docs.map(async (doc) => {
                    const data = { id: doc.id, ...doc.data() } as StafferType & {
                      id: string
                      stafferInfo?: Record<string, string>
                    }
                    const staffer = data.stafferInfo
                      ? ((await getStafferByIdPromise(database, doc.id)) as StafferType & { id: string })
                      : data
                    // Don't show duplicates
                    if (
                      staffer &&
                      !searchResults.find((item) => item.value.id === staffer.id) &&
                      searchableStaffers.includes(staffer.id)
                    ) {
                      searchResults.push({
                        label: `${staffer.nameFirst} ${staffer.nameLast}`,
                        value: {
                          accessGranted: true,
                          type: 'staffer',
                          id: doc.id,
                          photoUrl: staffer.photoUrl || '',
                        },
                      })
                    }
                  })
                )
              } catch (error) {
                console.warn(error)
                errorToast((error as Error).message)
              }
            })
          )
        }
      })
    )

    // @ts-ignore ramda has broken typing
    const getUniqueSearchResults = (searchQuery: OptionType[]): OptionType[] =>
      // @ts-ignore ramda has broken typing
      R.uniqBy(({ value: { id } }) => id)(searchQuery)

    const queryResults = R.compose(
      R.sort((itemA, itemB) => (itemA as OptionType).label.localeCompare((itemB as OptionType).label)),
      // @ts-ignore ramda has broken typing
      R.filter((result) => !selectedRecipients.find((recipient) => recipient.value.id === result.value.id))
    )(searchResults)

    return getUniqueSearchResults(queryResults as OptionType[])
  }

  onSearchSelect = (selectedOption: OptionType) => {
    this.setState((prevState) => ({ selectedRecipients: [...prevState.selectedRecipients, selectedOption] }))
  }

  confirm = async () => {
    const { onConfirm } = this.props
    const { selectedRecipients } = this.state
    const recipients = selectedRecipients.map((recipient: OptionType) => ({
      id: recipient.value.id,
      name: recipient.label,
    }))
    this.setState({ isProcessing: true })
    try {
      await onConfirm(recipients)
    } catch (error) {
      console.warn(error)
      errorToast('Failed to create the chat.')
    } finally {
      this.setState({ isProcessing: false })
    }
  }

  removeRecipient = (recipientId: string) => {
    const { selectedRecipients } = this.state
    const updatedRecipients = selectedRecipients.filter((recipient: OptionType) => recipient.value.id !== recipientId)
    this.setState({ selectedRecipients: updatedRecipients })
  }

  render() {
    const { existingRecipients, onClose } = this.props
    const { isProcessing, selectedRecipients } = this.state

    if (isProcessing) {
      return (
        <Box p={3} textAlign="center">
          <BeatLoader />
        </Box>
      )
    }

    return (
      <Fragment>
        <ModalHeader close={onClose}>{existingRecipients ? 'Add new participants' : 'Create new chat'}</ModalHeader>
        <Box p={3} display="flex" flexDirection="column" justifyContent="space-between">
          {existingRecipients && (
            <Box px={3} mb={2} textAlign="center">
              <Typography className={styles.textWarning} variant="caption">
                By adding new people into this chat, they will see all the previous messages sent between you and the
                other participants in this chat.
              </Typography>
            </Box>
          )}
          <AsyncSelect
            components={{
              Option: this.renderOption,
              IndicatorsContainer: () => null,
              ValueContainer: this.renderValueContainer,
            }}
            placeholder="Search staffers or employees..."
            noOptionsMessage={({ inputValue }: { inputValue: string }) =>
              this.isSearchable(inputValue)
                ? 'No results found.'
                : 'Enter the name of a staffer or an email of a manager. Case and special characters must match.'
            }
            styles={selectStyles}
            value={null} // Don't store the selected value
            loadOptions={this.getSearchResults}
            onChange={this.onSearchSelect}
            className={styles.searchInput}
            classNamePrefix={styles.searchInput}
            isSearchable
            menuPortalTarget={global.document.body}
          />
          {selectedRecipients.length > 0 && (
            <Box display="flex" flexDirection="column" justifyContent="center" mt={3}>
              <Typography component="h1" variant="h6">
                Create new chat with:
              </Typography>
              <List style={{ maxHeight: '30vh', overflow: 'auto' }}>
                {selectedRecipients.map((recipient: OptionType) => (
                  <ListItem key={recipient.value.id}>
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          width: 40,
                          height: 40,
                        }}
                        src={recipient.value.photoUrl}
                      />
                    </ListItemAvatar>
                    <ListItemText style={{ marginLeft: '12px' }} primary={recipient.label} />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => this.removeRecipient(recipient.value.id)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          <Box my={2} display="flex" alignItems="center" justifyContent="space-evenly">
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={selectedRecipients.length === 0}
              variant="contained"
              color="primary"
              onClick={this.confirm}
            >
              {existingRecipients ? 'Add participants' : 'Create new chat'}
            </Button>
          </Box>
        </Box>
      </Fragment>
    )
  }
}

export default connectFirestore(
  (db: Firestore, props: Props) => ({
    newJobs: getAllJobsByBusinessIdStartingFrom(db, props.businessId, new Date()),
    poolEmployees: getEmployeesInPoolsOf(db, props.businessId),
  }),
  CreateNewChat
)
