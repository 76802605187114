import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import Button from '../components/button/Button'
import Header from '../components/header/header'
import { errorToast } from '../helpers/toast'
import styles from './LinkWithFacebook.module.css'

type Props = Record<string, never>

type State = {
  loading: boolean
}

export default class LinkWithFacebook extends Component<Props, State> {
  state = {
    loading: false,
  }

  render() {
    const { loading } = this.state

    return (
      <div className={styles.wrapper}>
        <Header explicitTitle="Login" />
        <div className={styles.body}>
          <div className={styles.cartWrapper}>
            <div className={styles.contentWrapper}>
              {loading && (
                <div className={styles.loaderWrapper}>
                  <BeatLoader color="gray" />
                </div>
              )}
              {!loading && (
                <form onSubmit={this.onLinkWithFacebook} className={styles.loginForm}>
                  Click here to link your email account with Facebook.
                  <br />
                  You can then use Facebook to connect to the app.
                  <div className={styles.buttonWrapper}>
                    <Button
                      title="Link with Facebook"
                      onClick={this.onLinkWithFacebook}
                      buttonWrapperStyle="buttonWrapper"
                      buttonType="default"
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  onLinkWithFacebook = async () => {
    try {
      this.setState({ loading: true })
      const { currentUser } = firebase.auth()
      if (currentUser) {
        const facebookProvider = new firebase.auth.FacebookAuthProvider()
        await currentUser.linkWithPopup(facebookProvider)
        await firebase.auth().signOut()
      }
    } catch (error) {
      errorToast((error as Error).message)
      console.warn(error)
    } finally {
      this.setState({ loading: false })
    }
  }
}
