import firebase from 'firebase/compat/app'
import 'firebase/compat/performance'
import type { ComponentType, ReactNode } from 'react'
import React, { createContext } from 'react'

export type Performance = firebase.performance.Performance
export type Trace = firebase.performance.Trace

let fperf: Performance | null = null
const PerfContext = createContext<Performance | null>(null)

type BaseProps = Record<string, unknown> & { perf?: Performance }

// Initializes the performance provider.
// Must be called before any consumer is used, but after the firebaseApp is initialized.
export const initPerf = () => {
  fperf = firebase.performance()
}

/**
 * Provides the perf context for the children elements
 */
export const PerfProvider = ({ children }: { children: ReactNode }) => (
  <PerfContext.Provider value={fperf}>{children}</PerfContext.Provider>
)

/**
 * Wraps component with tracking context from index.ts (works differently from app)
 * @param PassedComponent
 * @returns
 */
export const withTracking =
  (PassedComponent: ComponentType<any>) =>
  // Wrapper component exception
  // eslint-disable-next-line react/display-name
  (props: any) => (
    <PerfContext.Consumer>
      {(perf?: Performance | null) =>
        perf === null ? (
          (console.error('Performance provider not initialized, call initPerf()'), null)
        ) : (
          <PassedComponent perf={perf} {...props} />
        )
      }
    </PerfContext.Consumer>
  )

type State = {
  trace: Trace | null
}

/**
 * Begins immediately tracking a component and passes trace as props
 * to component to manually stop tracking
 * @param PassedComponent
 * @param names
 */
export const startTracking = <P extends BaseProps>(PassedComponent: ComponentType<Readonly<P>>, name: string) => {
  class PerfProviderWrapper extends React.Component<P, State> {
    state = {
      trace: null,
    }

    componentDidMount() {
      const { perf } = this.props
      if (perf) {
        const trace = perf.trace(name)
        trace.start()
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          trace,
        })
      }
    }

    render() {
      const { trace } = this.state
      if (trace === null) {
        return null
      }
      // console.log('trace render', trace)
      return <PassedComponent trace={trace} {...(this.props as unknown as P)} />
    }
  }

  return withTracking(PerfProviderWrapper)
}

export default {
  startTracking,
  withTracking,
}
