import { updateDoc } from 'firebase/firestore'
import { useState } from 'react'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import type { Shortlist } from '../../../../../src/types/shortlist'
import type { StafferTypeWithId } from '../../../../../src/types/staffer'
import { errorToast } from '../../../helpers/toast'
import { accessBlock } from '../../../staffers/api/firestore/https/onboarding'
import { firestoreHttpsCallable } from '../../../staffers/api/firestore/https/util'
import { demoteStaffer, promoteStaffer } from '../../../staffers/api/firestore/superadmin'
import { getStafferById } from '../../../staffers/api/getters/staffer'
import { jobs as jobsSchema } from '../../../staffers/qman/jobs'
import { pools as poolsSchema } from '../../../staffers/qman/pools'
import { shortlists as shortlistsSchema } from '../../../staffers/qman/shortlists'
import { staffers as staffersSchema } from '../../../staffers/qman/staffers'
import { users as usersSchema } from '../../../staffers/qman/users'
import ConfirmationModal from '../../modals/ConfirmationModal'
import EditStafferPermissions from '../../modals/EditStafferPermissions'
import StafferPaymentDetails from '../../modals/StafferPaymentDetails'
import ViewStafferRatings from '../../modals/ViewStafferRatings'
import Modal from '../../mui/Modal'
import { useShortlists } from '../../providers/ShortlistsProvider'
import StafferPopover from './StafferPopover'
import StafferPopoverApprovalModal from './StafferPopoverApprovalModal'

type Props = {
  stafferId: string
  variant: 'shortlist' | 'invite' | 'offer'
  isOpen: boolean
  onClose: () => void
  afterUpdate: () => Promise<void> // called to do clean (algolia cache)
  isSuperAdmin?: boolean
  goToPrevStaffer?: () => any
  goToNextStaffer?: () => any
}

export type StafferPopoverModalType =
  | 'getPaymentDetails'
  | 'editPermissions'
  | 'promote'
  | 'callingList'
  | 'blocking'
  | 'removeUser'
  | 'approveDocuments'
  | 'denyDocuments'
  | 'viewRatings'

const StafferPopoverWrapper = ({
  stafferId,
  variant,
  isOpen,
  onClose,
  afterUpdate,
  isSuperAdmin = false,
  goToNextStaffer,
  goToPrevStaffer,
}: Props) => {
  const { addToShortlist } = useShortlists()
  const [modalMenuType, setModalMenuType] = useState<StafferPopoverModalType | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const staffer: StafferTypeWithId = staffersSchema.get('stafferById', [stafferId])
  const stafferDocuments = staffersSchema.get('stafferDocumentsById', [stafferId]) || null
  const stafferReferrals = (isSuperAdmin && staffersSchema.get('stafferReferrals', [stafferId])) || null
  const stafferPaymentDetails = (isSuperAdmin && staffersSchema.get('stafferDetailsById', [stafferId])) || null
  const stafferLogs = (isSuperAdmin && staffersSchema.get('stafferLogsById', [stafferId])) || null
  const stafferInvites = poolsSchema.get('favoritedStaffersByUsedId', [stafferId]) || null
  const completedJobs = jobsSchema.get('completedJobsByStafferId', [stafferId]) || null
  const stafferRatings = staffersSchema.get('stafferRatings', [stafferId]) || null
  const allShortlists = shortlistsSchema.get('shortlists', []) || null
  const userTags = usersSchema.get('userTags', [stafferId])
  const userDevices = usersSchema.get('userDevices', [stafferId])
  const invitedByBusinessId = (stafferInvites && stafferInvites.find((invite) => invite.poolId)?.poolId) || ''

  if (!staffer) {
    return <div>No staffer document found</div>
  }

  if (
    stafferReferrals === undefined ||
    stafferDocuments === undefined ||
    stafferInvites === undefined ||
    completedJobs === undefined ||
    stafferRatings === undefined ||
    allShortlists === undefined ||
    invitedByBusinessId === undefined ||
    (isSuperAdmin &&
      (stafferReferrals === undefined ||
        stafferLogs === undefined ||
        userTags === undefined ||
        userDevices === undefined))
  ) {
    return <BeatLoader />
  }

  const stafferRef = getStafferById(stafferId)

  const stafferTags = (userTags && userTags.map((userTag) => userTag.tagName)) || null

  const onAddToShortlist = () => {
    addToShortlist(stafferId)
    afterUpdate()
    onClose()
  }

  const appVersion: { deviceVersion: string | null; buildNumber: string | null; os: string | null } = {
    deviceVersion: userDevices?.[0]?.version || null,
    buildNumber: userDevices?.[0]?.buildNumber || null,
    os: userDevices?.[0]?.os === 'ios' ? 'iOS' : userDevices?.[0]?.os === 'android' ? 'Android' : null,
  }

  const markAsTesting = async () => {
    try {
      await updateDoc(stafferRef, { isTestUser: !staffer.isTestUser })
      await afterUpdate()
    } catch (e) {
      errorToast(`Error while trying to mark staffer as test user: ${e}`)
    }
  }

  const handleCancellation = async (increment: 1 | -1, severity: 'normal' | 'heavy' = 'normal') => {
    const fieldName = severity === 'heavy' ? 'heavyStrikes' : 'strikes'
    try {
      if (severity === 'normal' && increment === -1 && (staffer.strikes ?? 0) <= 0) {
        return errorToast('You can not remove any more cancellations')
      }
      if (severity === 'heavy' && increment === -1 && (staffer.heavyStrikes ?? 0) <= 0) {
        return errorToast('You can not remove any more late cancellations')
      }
      await updateDoc(stafferRef, {
        [fieldName]: ((staffer || {})[fieldName] || 0) + increment,
      })
      await afterUpdate()
      toast.success(
        increment === 1
          ? severity === 'heavy'
            ? 'Late cancellation was added successfully'
            : 'Cancellation was added successfully'
          : 'Cancellation was removed successfully'
      )
    } catch (e) {
      errorToast(`Error while trying to add cancellation: ${e}`)
    }
  }

  const handlePromotion = (type: 'promote' | 'demote') => async () => {
    try {
      setIsLoading(true)
      type === 'promote' ? await promoteStaffer(stafferId) : await demoteStaffer(stafferId)
      await afterUpdate()
      toast.success(type === 'promote' ? 'Staffer was promoted successfully.' : 'Staffer was demoted successfully.')
    } catch (error) {
      errorToast((error as Error)?.message ?? error)
    } finally {
      setModalMenuType(null)
      setIsLoading(false)
    }
  }

  const handleCallingList = async () => {
    const { callingList } = staffer
    try {
      updateDoc(stafferRef, {
        callingList: !callingList,
      })
      await afterUpdate()
      toast.success(
        !callingList ? 'Staffer was moved to the Calling list.' : 'Staffer was removed from the Calling list.'
      )
    } catch (error) {
      errorToast((error as Error).message ?? error)
    }
  }

  const handleBlockStaffer = () => async () => {
    try {
      setIsLoading(true)
      await accessBlock('staffer', stafferId)
      await afterUpdate()
      toast.success(`Access was ${staffer.accessBlocked ? 'unblocked' : 'blocked'} successfully.`)
    } catch (error) {
      errorToast((error as Error)?.message ?? error)
    } finally {
      setModalMenuType(null)
      setIsLoading(false)
    }
  }

  const handleRemoveStaffer = () => async () => {
    try {
      setIsLoading(true)
      await firestoreHttpsCallable('deleteUser', { userId: stafferId })
      await afterUpdate()
      setIsLoading(false)
      setModalMenuType(null)
    } catch (error) {
      errorToast((error as Error)?.message ?? error)
      setModalMenuType(null)
    }
  }

  const variants = {
    shortlist: {
      variant: 'shortlist' as 'shortlist' | 'invite' | 'offer',
      onAddToShortlist: () => onAddToShortlist(),
      onTestingOn: () => markAsTesting(),
      onAddCancellation: () => handleCancellation(1),
      onAddLateCancellation: () => handleCancellation(1, 'heavy'),
      onRemoveCancellation: () => handleCancellation(-1),
      onRemoveLateCancellation: () => handleCancellation(-1, 'heavy'),
    },
    invite: {
      variant: 'invite' as 'shortlist' | 'invite' | 'offer',
      onMessage: () => {},
      onInviteToJob: () => {},
      onShowContactInfo: () => {},
    },
    offer: {
      variant: 'offer' as 'shortlist' | 'invite' | 'offer',
      onMessage: () => {},
      onOfferJob: () => {},
    },
  }

  const confirmationModalTypes = {
    promote: {
      handler: handlePromotion(staffer.isSkilled2 ? 'demote' : 'promote'),
      title: `Are you sure you want to ${staffer.isSkilled2 ? 'demote' : 'promote'} this staffer?`,
    },
    blocking: {
      handler: handleBlockStaffer(),
      title: `Are you sure you want to ${staffer.accessBlocked ? 'unblock' : 'block'} this staffer?`,
    },
    removeUser: {
      handler: handleRemoveStaffer(),
      title: 'Are you sure you want to delete this staffer account and all associated data? (CANNOT BE REVERTED)',
    },
  }

  const stafferShortlists =
    allShortlists &&
    allShortlists
      .filter((shortlist: Shortlist) => shortlist.members.includes(stafferId))
      .map((shortlist) => shortlist.name)

  return (
    <>
      <StafferPopover
        staffer={staffer}
        invitedByBusinessId={invitedByBusinessId}
        stafferDocuments={stafferDocuments}
        stafferLogs={stafferLogs}
        stafferReferrals={stafferReferrals}
        completedJobs={completedJobs}
        stafferRatings={stafferRatings}
        variantType={variants[variant]}
        isOpen={isOpen}
        onClose={onClose}
        isSuperAdmin={isSuperAdmin}
        stafferTags={stafferTags}
        appVersion={appVersion}
        shortlists={stafferShortlists}
        goToPrevStaffer={goToPrevStaffer}
        goToNextStaffer={goToNextStaffer}
        onGetPaymentDetails={() => setModalMenuType('getPaymentDetails')}
        onEditPermissions={() => setModalMenuType('editPermissions')}
        onPromotionToggle={() => setModalMenuType('promote')}
        onCallingList={() => handleCallingList()}
        onBlockingStaffer={() => setModalMenuType('blocking')}
        onRemoveStaffer={() => setModalMenuType('removeUser')}
        onApproveDocuments={() => setModalMenuType('approveDocuments')}
        onDenyDocuments={() => setModalMenuType('denyDocuments')}
        onViewStafferRatings={() => setModalMenuType('viewRatings')}
      />
      {/* Sidebar buttons / actions */}
      <Modal
        isOpen={!!modalMenuType && modalMenuType !== 'approveDocuments' && modalMenuType !== 'denyDocuments'}
        onRequestClose={() => setModalMenuType(null)}
        ariaHideApp={false}
        zIndex={2000}
      >
        {modalMenuType === 'getPaymentDetails' && (
          <StafferPaymentDetails
            currentCountry={staffer.region?.countryName}
            stafferId={stafferId}
            onRequestClose={() => setModalMenuType(null)}
          />
        )}
        {modalMenuType === 'editPermissions' && (
          <EditStafferPermissions stafferId={stafferId} closeModal={() => setModalMenuType(null)} />
        )}
        {(modalMenuType === 'promote' || modalMenuType === 'blocking' || modalMenuType === 'removeUser') && (
          <ConfirmationModal
            closeModal={() => setModalMenuType(null)}
            confirmationTitle={confirmationModalTypes[modalMenuType].title}
            isLoading={isLoading}
            onClick={confirmationModalTypes[modalMenuType].handler}
          />
        )}
        {modalMenuType === 'viewRatings' && (
          <ViewStafferRatings staffer={staffer} onRequestClose={() => setModalMenuType(null)} />
        )}
      </Modal>
      {/* Documents approval */}
      <StafferPopoverApprovalModal
        staffer={staffer}
        modalType={modalMenuType}
        birthday={staffer.birthday || stafferPaymentDetails?.birthday}
        isOpen={modalMenuType === 'approveDocuments' || modalMenuType === 'denyDocuments'}
        onClose={() => setModalMenuType(null)}
      />
    </>
  )
}

export default StafferPopoverWrapper
