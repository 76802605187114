import type { BusinessType } from '../../../src/types/business'
import type { GroupStafferType } from '../../../src/types/groups'
import type { PermissionsType, PoolType } from '../../../src/types/pools'

interface GetStafferCustomWageData {
  business: BusinessType
  groupStaffer?: GroupStafferType
  stafferPermissions?: PermissionsType | (PermissionsType & { id: string })
}

export const getStafferCustomWage = (data: GetStafferCustomWageData) => {
  const { business, groupStaffer, stafferPermissions } = data

  const permissionsBusinessData = stafferPermissions && stafferPermissions.businessData

  // Gets the main employer if there is one
  const mainEmployer = groupStaffer && groupStaffer.globalContract && groupStaffer.globalContract.mainEmployer

  return (
    (permissionsBusinessData &&
      business &&
      permissionsBusinessData[business.businessId] &&
      permissionsBusinessData[business.businessId].customWage) ||
    ((permissionsBusinessData &&
      mainEmployer &&
      permissionsBusinessData[mainEmployer] &&
      permissionsBusinessData[mainEmployer].customWage) as number | 'custom' | undefined)
  )
}

export const findStafferSkillLevel = (connectedPools: PoolType[] | null, stafferPermissions: PermissionsType) => {
  if (connectedPools) {
    // Returns the first skill level found out of the connected pools
    // first pool is always the company pool itself
    const foundSkillLevelFor: PoolType | typeof undefined = connectedPools.find(
      (pool: PoolType) =>
        !!(
          stafferPermissions.businessData &&
          stafferPermissions.businessData[pool.businessId] &&
          stafferPermissions.businessData[pool.businessId].skillLevels
        )
    )
    if (foundSkillLevelFor) {
      return (
        (stafferPermissions.businessData &&
          stafferPermissions.businessData[foundSkillLevelFor.businessId] &&
          stafferPermissions.businessData[foundSkillLevelFor.businessId].skillLevels) ||
        {}
      )
    }
  }

  return {}
}
