import { getAuth } from 'firebase/auth'
import { deleteDoc } from 'firebase/firestore'
import { useMemo, useState } from 'react'
import type { Props as ModalProps } from 'react-modal'
import { toast } from 'react-toastify'
import type { AlgoliaStafferType } from '../../../../../src/algolia/staffer'
import type { Shortlist } from '../../../../../src/types/shortlist'
import type { StafferTypeWithId } from '../../../../../src/types/staffer'
import { colors } from '../../../constants/styles'
import { errorToast } from '../../../helpers/toast'
import { clearAlgoliaCache } from '../../../screens/superadmin'
import { getShortlistById } from '../../../staffers/api/getters/shortlists'
import { staffers as staffersSchema } from '../../../staffers/qman/staffers'
import MuiModal from '../../mui/Modal'
import Button from '../../mui/redesignedComponents/Button'
import StafferCard from '../StafferCard/StafferCard'
import StafferPopoverWrapper from '../StafferPopover/StafferPopoverWrapper'
import TrashOutlined from '../icons/TrashOutlined'
import { ModalHeader, type ModalHeaderProps } from '../modal/Modal'
import DeleteConfirmationModal from './DeleteConfirmationModal'
import styles from './ShortlistDetailModal.module.css'

type ShortlistDetailModalBaseProps = {
  shortlist: Shortlist
  onDeleteShortlist: VoidFunction
  // fetched staffers based on shortlist.members
  staffers: Array<StafferTypeWithId | undefined>
  // used for staffercard
  onStafferDelete: (stafferId: StafferTypeWithId['id']) => void
  isProcessing: boolean
} & Pick<ModalHeaderProps, 'onClose'>

export const ShortlistDetailModalBase = ({
  shortlist,
  staffers,
  onClose,
  onDeleteShortlist,
  isProcessing,
}: ShortlistDetailModalBaseProps) => {
  const [stafferPopoverId, setStafferPopoverId] = useState<string | undefined | null>(null)
  const [staffersRemoved, setStaffersRemoved] = useState<string[]>([])

  const filteredStaffers = useMemo(
    () =>
      staffers
        .filter((x): x is AlgoliaStafferType => x !== undefined)
        .filter((staffer) => !staffersRemoved.includes(staffer.id)),
    [staffers, staffersRemoved]
  )
  const hasStaffers = filteredStaffers.length > 0

  const onSeeMore = (stafferId: string) => {
    setStafferPopoverId(stafferId)
  }

  const onClosePopover = () => {
    setStafferPopoverId(null)
  }

  const paginateStaffers = (direction: 'next' | 'previous') => {
    const currentStafferIndex = staffers.findIndex((staffer) => staffer?.id === stafferPopoverId)
    const nextIndex = (currentStafferIndex + 1) % staffers.length
    const previousIndex = (currentStafferIndex - 1 + staffers.length) % staffers.length
    let newId = stafferPopoverId
    if (currentStafferIndex === -1) {
      errorToast('Could not find next staffer')
    } else {
      newId = staffers[direction === 'next' ? nextIndex : previousIndex]?.id
    }
    setStafferPopoverId(newId)
  }

  return (
    <div className={styles.modal}>
      {/* Header */}
      <ModalHeader
        title={shortlist.name}
        onClose={onClose}
        leftActions={
          <Button
            disabled={isProcessing}
            onClick={onDeleteShortlist}
            size="large"
            startIcon={<TrashOutlined />}
            variant="text"
            style={{ color: colors.red500, padding: '8px', fontWeight: 600 }}
          >
            Delete shortlist
          </Button>
        }
      />
      {/* Content */}
      {hasStaffers && (
        <div className={styles.content}>
          {filteredStaffers.map((staffer, index) => (
            <StafferCard
              staffer={staffer}
              key={index}
              shortlistId={shortlist.id}
              variant="shortlist"
              onStafferDelete={() => setStaffersRemoved([...staffersRemoved, staffer.id])}
              onSeeMore={() => onSeeMore(staffer.id)}
            />
          ))}
        </div>
      )}
      {!hasStaffers && <div className={styles.noStaffers}>No staffers yet</div>}
      {/* staffer popover modal */}
      {hasStaffers && stafferPopoverId && (
        <StafferPopoverWrapper
          stafferId={stafferPopoverId}
          variant="shortlist"
          isOpen={!!stafferPopoverId}
          onClose={onClosePopover}
          afterUpdate={clearAlgoliaCache}
          isSuperAdmin
          goToNextStaffer={() => paginateStaffers('next')}
          goToPrevStaffer={() => paginateStaffers('previous')}
        />
      )}
    </div>
  )
}

const ShortlistDetailModal = ({
  shortlist,
  onClose,
  isOpen,
}: { shortlist: Shortlist | null } & Pick<ShortlistDetailModalBaseProps, 'onClose'> & Pick<ModalProps, 'isOpen'>) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const fetchedStaffers = shortlist?.members.map((memberId) => staffersSchema.get('stafferById', [memberId]))

  const deleteShortlist = async () => {
    const adminId = getAuth().currentUser?.uid
    if (!adminId || !shortlist) return

    // assume that shortlistId and stafferId are valid and documents exist
    try {
      setIsProcessing(true)
      const shortlistRef = getShortlistById(shortlist.id)
      await deleteDoc(shortlistRef)
      // close modal after successful deletion
      onClose()
      toast.success('Shortlist deleted successfully!')
    } catch (_) {
      errorToast("Couldn't delete shortlist")
      setShowDeleteConfirmation(false)
    } finally {
      setIsProcessing(false)
    }
  }

  if (!fetchedStaffers || !shortlist) return null

  return (
    <>
      <DeleteConfirmationModal
        isOpen={showDeleteConfirmation}
        shortlistName={shortlist.name}
        onClose={() => setShowDeleteConfirmation(false)}
        isProcessing={isProcessing}
        onConfirm={deleteShortlist}
      />
      <MuiModal isOpen={isOpen} hasRoundedCorners>
        <ShortlistDetailModalBase
          shortlist={shortlist}
          onClose={onClose}
          staffers={fetchedStaffers}
          onDeleteShortlist={() => setShowDeleteConfirmation(true)}
          onStafferDelete={() => null}
          isProcessing={isProcessing}
        />
      </MuiModal>
    </>
  )
}

export default ShortlistDetailModal
