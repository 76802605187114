import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip,
} from '@material-ui/core'
import {
  Assignment as AssignmentIcon,
  Cancel as CancelIcon,
  VerifiedUser as VerifiedUserIcon,
} from '@material-ui/icons'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as R from 'ramda'
import React from 'react'
// @ts-ignore
import AsyncSelect from 'react-select/lib/Async'
// @ts-ignore
import { components } from 'react-select'
import type { BusinessType } from '../../../../src/types/business'
import type { Timestamp } from '../../../../src/types/firebase'
import type { JobType } from '../../../../src/types/jobs'
import type { PermissionsType, PoolJobType, PoolType } from '../../../../src/types/pools'
import type { StafferType } from '../../../../src/types/staffer'
import { searchText } from '../../staffers/api/firestore/searchText.legacy'
import ButtonWithDisabledTooltip from '../ButtonWithDisabledTooltip'
import Modal from '../mui/Modal'
import ModalHeader from '../mui/ModalHeader'
import AddStaffer from './AddStaffer'
import type { OptionType } from './CreateEditJob'
import styles from './modals.module.css'

type Props = {
  businessId: string
  business: BusinessType
  connectedPools: Array<PoolType>
  createdJob: {
    businessId: string
    description: string
    isFreelanceJob: boolean
    location: { lat: number; lng: number } | null
    timeEnd: Timestamp
    timeStart: Timestamp
    employmentReason: string
    presentStaffers: Array<string>
    salaryCurrency: string
    salaryHourly: string | number
    jobType: string
    options: Array<string>
    usePermanentContractWage: boolean
  }
  disabled: boolean
  disabledReason: string
  goBack: () => void
  hasJobPermission: (
    invite: OptionType,
    jobType: string,
    businessId: string,
    toastRemoved?: boolean,
    usePublicPermissions?: boolean
  ) => boolean
  invites: Array<OptionType>
  invalidFormReasons: { [reason: string]: string }
  job?: JobType | PoolJobType
  jobType: string
  limitedToInvitedUntil: boolean | Timestamp
  needContract: boolean
  onClose: () => void
  onPressSaveJob: () => void
  pool: PoolType
  removeOption: (id: string) => void
  selectOption: (option: OptionType) => void
  togglelimitedToInvitedUntil: () => void
}

const SEARCH_QUERY_MIN_LENGTH = 2

type State = {
  activeStafferId: string | null
  acceptedWorkAgreements: boolean
}

class JobInvitesInternalModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      activeStafferId: null,
      acceptedWorkAgreements: !props.needContract,
    }
  }

  openContractPreview = (stafferId: string) =>
    this.setState({
      activeStafferId: stafferId,
    })

  hideContractModal = () =>
    this.setState({
      activeStafferId: null,
    })

  toggleacceptedWorkAgreements = () =>
    this.setState(({ acceptedWorkAgreements }) => ({
      acceptedWorkAgreements: !acceptedWorkAgreements,
    }))

  render() {
    const { activeStafferId, acceptedWorkAgreements } = this.state
    const {
      business,
      businessId,
      connectedPools,
      createdJob,
      disabled,
      disabledReason,
      goBack,
      hasJobPermission,
      invalidFormReasons,
      invites,
      job,
      jobType,
      limitedToInvitedUntil,
      needContract,
      onClose,
      onPressSaveJob,
      pool,
      removeOption,
      selectOption,
      togglelimitedToInvitedUntil,
    } = this.props

    const { presentStaffers } = createdJob

    const isSearchable = (searchQuery: string): boolean => searchQuery.length >= SEARCH_QUERY_MIN_LENGTH

    const renderOption = (option: { data: OptionType }) => {
      const { label } = option.data
      const { Option } = components
      return <Option {...option}>{label}</Option>
    }

    const getSearchResults = async (searchQueries: string): Promise<OptionType[] | void> => {
      try {
        const queryResults: Array<OptionType> = []
        const queryWords = searchQueries.split(' ')
        // Include initial, whole expression in search as well
        const potentialQueryWords = [searchQueries]
        queryWords.forEach((word) => {
          if (isSearchable(word)) {
            // First include searchterm as written
            potentialQueryWords.push(word)
            // Second include searchterm as lowercase
            potentialQueryWords.push(word.toLowerCase())
            // Third include searchterm with first letter in upper case
            potentialQueryWords.push(word.charAt(0).toUpperCase() + word.substring(1))
          }
        })

        const uniqueQueryWords = [...new Set([...potentialQueryWords])].filter((word) => isSearchable(word))

        const db = firebase.firestore()
        const allStaffers = db.collection('staffers')

        const queries = [
          ...uniqueQueryWords.map((word) => searchText(allStaffers, 'nameFirst', word)),
          ...uniqueQueryWords.map((word) => searchText(allStaffers, 'nameLast', word)),
          ...uniqueQueryWords.map((word) => searchText(allStaffers, 'userId', word)),
        ]

        await Promise.all(
          queries.map(async (query) => {
            try {
              const queryDocs = await query.get()
              return Promise.all(
                queryDocs.docs.map(async (doc) => {
                  const data = doc.data() as StafferType
                  const stafferPermissionsDoc = await db.collection('staffersPermissions').doc(doc.id).get()
                  const stafferPermissions = stafferPermissionsDoc.data() as PermissionsType
                  const poolLabel = (
                    (connectedPools || []).find(
                      (cPool: PoolType) => cPool.employees.includes(data.userId) || cPool.staffers.includes(data.userId)
                    ) || {}
                  ).name

                  const stafferBusinessData = stafferPermissions?.businessData?.[businessId]
                  const isWageSetup = !!stafferBusinessData?.contractPercentage && !!stafferBusinessData?.customWage

                  if (data) {
                    queryResults.push({
                      label: `${data.nameFirst} ${data.nameLast}`,
                      id: data.userId,
                      photoUrl: data.photoUrl,
                      stafferPermissions,
                      isWageSetup,
                      isAlumni: !!(
                        stafferPermissions &&
                        stafferPermissions.businessData &&
                        stafferPermissions.businessData[businessId] &&
                        stafferPermissions.businessData[businessId].isAlumni
                      ),
                      poolLabel,
                    })
                  }
                })
              )
            } catch (error) {
              console.error((error as Error)?.message ?? 'Failed to get staffers')
              return undefined
            }
          })
        )

        const employeesAndFavourites = Array.from(
          new Set([
            ...connectedPools.reduce(
              (result: string[], cPool: PoolType) => [...result, ...cPool.employees, ...cPool.staffers],
              []
            ),
            ...pool.employees,
            ...pool.staffers,
          ])
        )

        // Filter out non-pool staffers, already added staffers and sort them
        const results: Array<OptionType> = R.compose(
          R.sort((option1: OptionType, option2: OptionType) => option1.label.localeCompare(option2.label)),
          R.filter(
            (invite: OptionType) =>
              hasJobPermission(invite, jobType, businessId) &&
              employeesAndFavourites.includes(invite.id) &&
              !invites.find(({ id: eId }) => eId === invite.id) &&
              !presentStaffers.includes(invite.id)
          )
        )(queryResults)

        return R.uniqBy(({ id }: OptionType) => id)(results)
      } catch (error) {
        console.error((error as Error)?.message ?? 'Failed to get search results')
        return undefined
      }
    }

    const invitesWageValid = invites.filter((invite) => !invite.isWageSetup).length === 0

    return (
      <React.Fragment>
        <ModalHeader close={onClose} goBack={goBack}>
          Invite employees
        </ModalHeader>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2 className={styles.subtitle}>Assign employees</h2>
            </Grid>
            <Grid item xs={12}>
              <AsyncSelect
                disabled={!jobType}
                components={{
                  Option: renderOption,
                }}
                placeholder={jobType ? 'Search for internal employees...' : 'Please select a job type first'}
                noOptionsMessage={({ inputValue }: { inputValue: string }) =>
                  isSearchable(inputValue)
                    ? 'No results found. Employee must be added to your pool and have permissions for this job type'
                    : 'Please enter a valid name or id'
                }
                styles={{
                  option: (provided: Record<string, string>) => ({
                    ...provided,
                    color: 'black',
                    display: 'flex',
                    'flex-direction': 'row',
                    'align-items': 'center',
                    width: '100%',
                  }),
                }}
                value={null}
                loadOptions={getSearchResults}
                onChange={selectOption}
                isSearchable
              />
              {invites && invites.length > 0 && (
                <React.Fragment>
                  <List>
                    <Grid container spacing={3}>
                      {invites &&
                        invites.map(({ id, photoUrl, label, isAlumni, poolLabel, isWageSetup }, index) => (
                          <Grid item xs={6} key={index}>
                            <ListItem key={id}>
                              <ListItemAvatar>
                                <Avatar alt={label} src={photoUrl} />
                              </ListItemAvatar>
                              <ListItemText primary={label} secondary={poolLabel} />
                              {isAlumni && (
                                <Tooltip title="Alumni">
                                  <div>
                                    <Chip label="A" />
                                  </div>
                                </Tooltip>
                              )}
                              {needContract && (
                                <Tooltip title="Open contract preview for this employee">
                                  <IconButton
                                    edge="end"
                                    aria-label="Open contract preview"
                                    onClick={() => this.openContractPreview(id)}
                                  >
                                    <AssignmentIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {job && job.shifts.every((shift) => shift.staffersConfirmed.includes(id)) ? (
                                <Tooltip title="This employee has already accepted all shifts of this job">
                                  <div>
                                    <VerifiedUserIcon color="primary" />
                                  </div>
                                </Tooltip>
                              ) : (
                                <ListItemSecondaryAction>
                                  <IconButton edge="end" aria-label="remove" onClick={() => removeOption(id)}>
                                    <CancelIcon color="error" />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              )}
                            </ListItem>
                            {!isWageSetup && createdJob?.usePermanentContractWage && (
                              <span className={styles.errorText}>This staffer doesn&apos;t have wage setup</span>
                            )}
                          </Grid>
                        ))}
                    </Grid>
                  </List>
                </React.Fragment>
              )}
              <br />
              <FormControlLabel
                control={
                  <Switch
                    disabled={typeof limitedToInvitedUntil !== 'boolean' && !!limitedToInvitedUntil}
                    checked={!!limitedToInvitedUntil}
                    onChange={togglelimitedToInvitedUntil}
                    color="primary"
                  />
                }
                label="Make this job non-exclusive after 6 hours"
              />
              <br />
              {needContract && (
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!(invites && invites.length)}
                      checked={!!acceptedWorkAgreements}
                      onChange={this.toggleacceptedWorkAgreements}
                      color="primary"
                    />
                  }
                  label="I accept the work agreement(s)*"
                />
              )}
            </Grid>
            <Grid item container spacing={1} xs={12}>
              <Grid item xs>
                <Button onClick={goBack} variant="contained">
                  Go back
                </Button>
              </Grid>
              <Grid item xs>
                <ButtonWithDisabledTooltip
                  onClick={onPressSaveJob}
                  variant="contained"
                  color="primary"
                  reasons={{
                    ...invalidFormReasons,
                    ...(acceptedWorkAgreements
                      ? {}
                      : { disagreed: 'You need to accept the work agreement(s) before offering the job' }),
                  }}
                  reason={acceptedWorkAgreements ? disabledReason : 'disagreed'}
                  disabled={
                    disabled ||
                    !(invites && invites.length) ||
                    (!invitesWageValid && createdJob?.usePermanentContractWage) ||
                    !acceptedWorkAgreements
                  }
                >
                  {job && job.id ? 'Save job with invites' : 'Post job with invites'}
                </ButtonWithDisabledTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {activeStafferId && (
          <Modal
            isOpen={!!activeStafferId}
            onRequestClose={this.hideContractModal}
            contentLabel="AddStaffer"
            ariaHideApp={false}
          >
            <AddStaffer
              business={business}
              isContractPreview
              job={job || createdJob}
              onAcceptStaffer={this.hideContractModal}
              onClose={this.hideContractModal}
              stafferId={activeStafferId}
            />
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

export default JobInvitesInternalModal
