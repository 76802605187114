/* eslint-disable class-methods-use-this */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Help as HelpIcon } from '@material-ui/icons'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { getAuth } from 'firebase/auth'
import * as R from 'ramda'
import type { ChangeEvent, FocusEventHandler } from 'react'
import { Component, Fragment } from 'react'
import { toast } from 'react-toastify'
// @ts-ignore ReactTooltip has no exported @types/module
import ReactTooltip from 'react-tooltip'
import validator from 'validator'
// @ts-ignore react-google-places-autocomplete has no exported @types/ module
import GooglePlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId } from 'react-google-places-autocomplete'
import { BeatLoader } from 'react-spinners'
// @ts-ignore
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'
// @ts-ignore react-select has no exported @types/ module
import { components as reactSelectComponents } from 'react-select'
// @ts-ignore react-select has no exported @types/ module
import AsyncSelect from 'react-select/lib/Async'
import type { BusinessType } from '../../../../src/types/business'
import type { RegionType, businessTypes as businessTypesType } from '../../../../src/types/common'
import type { Firestore } from '../../../../src/types/firebase'
import type { PoolType } from '../../../../src/types/pools'
import { CUSTOM_ADDRESS_BUSINESS_TYPES } from '../../constants/permissions'
import { TooltipDelays } from '../../constants/tooltips'
import { containsNumber, getSelectableBusinessSubtypes, swedenOrgNumRegex } from '../../helpers'
import { getIncompleteProfileFields } from '../../helpers/business'
import { loadGoogleMaps } from '../../helpers/googleMaps'
import { applyFormat, getOrgNumberFormat, unapplyFormat } from '../../helpers/localStringFormatter'
import { errorToast } from '../../helpers/toast'
import { isValidEmail } from '../../helpers/validator'
import { updateBusinessProfile } from '../../staffers/api/firestore/business'
import { sendAccessRequest } from '../../staffers/api/firestore/https/onboarding'
import { firestoreHttpsCallable } from '../../staffers/api/firestore/https/util'
import { createBusinessForManager } from '../../staffers/api/firestore/managers'
import { getBusinessById } from '../../staffers/api/getters/business'
import { getAvailableRegions, getBusinessTypes, getTestRegions } from '../../staffers/api/getters/common'
import { getPoolById } from '../../staffers/api/getters/pools'
import integrationsAPI from '../../staffers/api/integrations'
import { connectFirestore } from '../../staffers/qman/connectFirestore'
import modalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import styles from './EditCompanyProfile.module.css'

type BusinessSearchOption = {
  businessAddress: {
    city: string | null
    country: string | null
    extra: string | null
    postalCode: string | null
    street: string | null
  }
  businessName: string
  businessNumber: string
}

type State = {
  address: string
  placeId: string
  businessDidFetch: boolean
  businessType: string
  customBusinessType?: string
  description: string
  efhInvoice: boolean
  name: string
  // related to ivnoice address
  invoiceIsSameAddress: boolean
  invoiceAddress: string
  invoiceCity: string
  invoiceCountry: string
  invoicePostalCode: string
  gmapsLoaded: boolean
  email: string
  registeredName: string
  orgNumber: string
  payoutDay: number
  receivePayoutInfoDay: number
  phoneNumber: string
  postalCode: string
  saveInProgress: boolean
  selectableBusinessTypes: Array<string>
  selectedBusiness: null | BusinessSearchOption
  selectedCity: string
  selectedCountry: string
  specificBusinessType?: string
  websiteName: string
  inputErrors: {
    name: string
    customBusinessType: string
    orgNumber: string
    registeredName: string
    websiteName: string
    email: string
  }
  inputWarnings: {
    streetNr: string
    streetInvNr: string
  }
}

type Props = {
  businessId: string
  business: BusinessType
  businessTypes: businessTypesType
  closeModal: () => void
  pool?: PoolType
  regions: Array<RegionType>
  isNewCompany?: boolean
  isSuperAdmin?: boolean
  type?: 'complete-profile'
}

const STREET_NR_WARNING =
  'We noticed you did not enter the street number, are you sure that the business address is full and correct?'

// Minimum length of the query to begin searching
// Prevents wasting reads on queries that are too small
const SEARCH_QUERY_MIN_LENGTH = 3

const ReactSelectBuiltInOption = reactSelectComponents.Option

class EditCompanyProfile extends Component<Props, State> {
  inputs = {
    companyName: null,
    description: null,
    websiteName: null,
    orgNumber: null,
    phoneNumber: null,
    postalCode: null,
    streetAddress: null,
  }

  static getDerivedStateFromProps = (props: Props, state: State) => {
    const { businessDidFetch } = state
    const { business, businessTypes, regions, businessId } = props
    if (business && business.id !== businessId) {
      // this prevents showing invalid data form business of previously logged in manager, cached by qman
      return {
        businessDidFetch: false,
      }
    }
    if (!businessDidFetch && business && businessTypes && regions) {
      const {
        address,
        businessName,
        businessType,
        customBusinessType,
        description,
        efhInvoice,
        email,
        invoice,
        orgNumber,
        payoutDay,
        payoutPeriodDay,
        phone,
        registeredName,
        specificBusinessType,
        website,
      } = business

      // Get the list of business types
      const selectableBusinessTypes = R.compose(
        R.sort((name) => (name === 'Other' ? 1 : -1)), // move 'Other' to the end
        R.sort((typeA: string, typeB) => typeA.localeCompare(typeB)) // sort alphabetically
      )(businessTypes.values) as string[]

      let selectedBusinessType = businessType
      if (!selectableBusinessTypes.includes(selectedBusinessType)) {
        selectedBusinessType = R.last<string>(selectableBusinessTypes) || ''
      }
      const websiteName = website && website.replace(/https?:\/\//, '')
      const selectedSubtype = specificBusinessType || getSelectableBusinessSubtypes(selectedBusinessType)[0] || ''

      const streetMsg = address && containsNumber(address.street) ? '' : STREET_NR_WARNING
      const streetInvMsg = invoice && !containsNumber(invoice.street) ? STREET_NR_WARNING : ''

      return {
        address: (address && address.street) || '',
        placeId: business.placeId || '',
        businessDidFetch: true,
        businessType: selectedBusinessType,
        customBusinessType,
        description: description || '',
        efhInvoice: efhInvoice || false,
        email: email || '',
        extraAddress: (address && address.extra) || '',
        invoiceIsSameAddress: !invoice,
        invoiceAddress: (invoice && invoice.street) || '',
        invoiceCity: (invoice && invoice.city) || '',
        invoiceCountry: (invoice && invoice.country) || '',
        invoicePostalCode: (invoice && invoice.postalCode) || '',

        name: businessName || '',
        orgNumber: orgNumber || '',
        payoutDay: payoutDay || 15,
        receivePayoutInfoDay: payoutPeriodDay || 1,
        phoneNumber: phone || '',
        postalCode: (address && address.postalCode) || '',
        selectedCity: (address && address.city) || '',
        selectedCountry: (address && address.country) || '',
        registeredName: registeredName || '',
        saveInProgress: false,
        selectableBusinessTypes,
        specificBusinessType: selectedSubtype,
        websiteName,
        inputWarnings: {
          streetNr: streetMsg,
          streetInvNr: streetInvMsg,
        },
      }
    }

    return null
  }

  state: Readonly<State> = {
    address: '',
    placeId: '',
    businessDidFetch: false,
    businessType: '',
    customBusinessType: undefined,
    description: '',
    efhInvoice: false,
    name: '',
    orgNumber: '',
    payoutDay: 1,
    phoneNumber: '',
    invoiceIsSameAddress: true,
    invoicePostalCode: '',
    invoiceCity: '',
    invoiceCountry: '',
    invoiceAddress: '',
    gmapsLoaded: false,
    email: '',
    postalCode: '',
    receivePayoutInfoDay: 1,
    registeredName: '',
    saveInProgress: false,
    selectableBusinessTypes: [],
    // for BusinessSearch
    selectedBusiness: null,
    selectedCity: '',
    selectedCountry: '',
    specificBusinessType: '',
    websiteName: '',
    inputErrors: {
      name: '',
      customBusinessType: '',
      orgNumber: '',
      registeredName: '',
      websiteName: '',
      email: '',
    },
    inputWarnings: {
      streetNr: '',
      streetInvNr: '',
    },
  }

  componentDidMount() {
    loadGoogleMaps(() => {
      this.setState({
        gmapsLoaded: true,
      })
    })
  }

  onLocationChange =
    (addressType?: string) =>
    async (place: { place_id: string } | null, address = '') => {
      const [details] = place ? await geocodeByPlaceId(place.place_id) : await geocodeByAddress(address)

      const components = details.address_components
      const getComponentByType = (type: string): string => {
        const component = components.find((item: { types: string[] }) =>
          item.types.some((itemType) => itemType === type)
        )
        return component ? component.long_name : ''
      }

      const city =
        getComponentByType('postal_town') || getComponentByType('locality') || getComponentByType('sublocality')
      const country = getComponentByType('country')
      const postalCode = getComponentByType('postal_code')
      const streetName = getComponentByType('route')
      const streetNumber = getComponentByType('street_number')
      const street = [streetName, streetNumber].filter((notEmpty) => notEmpty).join(' ')

      const streetMsg = streetNumber ? '' : STREET_NR_WARNING

      if (addressType === 'main') {
        this.setState({
          address: street,
          selectedCity: city,
          selectedCountry: country,
          postalCode,
          placeId: details.place_id,
          // @ts-ignore intentionally left out other part of setState
          inputWarnings: {
            streetNr: streetMsg,
          },
        })
      }
      if (addressType === 'invoice') {
        this.setState({
          invoiceAddress: street,
          invoiceCity: city,
          invoiceCountry: country,
          invoicePostalCode: postalCode,
          // @ts-ignore intentionally left out other part of setState
          inputWarnings: {
            streetInvNr: streetMsg,
          },
        })
      }
    }

  /* --- BusinessSearch AsyncSelector Components and Functions --- */
  renderOption = (renderProps: Record<string, unknown>) => {
    const { businessName, businessNumber } = renderProps.data as { businessName: string; businessNumber: string }

    return (
      <ReactSelectBuiltInOption {...renderProps}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography>{businessName}</Typography>
          <Typography variant="caption">
            <em>Org. number: {businessNumber}</em>
          </Typography>
        </Box>
      </ReactSelectBuiltInOption>
    )
  }

  isSearchable = (searchQuery: string): boolean => searchQuery.length >= SEARCH_QUERY_MIN_LENGTH

  getBusinessSearchResults = async (searchQuery: string): Promise<BusinessSearchOption[] | void> => {
    const searchResults = []

    if (this.isSearchable(searchQuery)) {
      try {
        const query = await integrationsAPI.get(`/company/search/?searchQuery=${searchQuery}`)
        if (query) {
          if (query.searchResults && query.searchResults.length > 0) {
            searchResults.push(...(query.searchResults || []))
          }
        }
      } catch (e) {
        console.error('[EditCompanyProfile] (getBusinessSearchResults) Error when fetching search results: ', e)
      }
    }

    return searchResults
  }

  getSearchResultsDebounced = AwesomeDebouncePromise(this.getBusinessSearchResults, 500)

  onBusinessSearchSelect = async (selectedOption: BusinessSearchOption) => {
    const { businessAddress, businessName, businessNumber } = selectedOption

    // Not every business has a complete address entry
    const { city, country, postalCode, street } = businessAddress

    // Only change the address if the address information is complete
    if (city && country && postalCode && street) {
      const address = `${street}, ${city}, ${postalCode}, ${country}`
      await this.onLocationChange('main')(null, address)
    }

    this.setState({
      name: businessName,
      orgNumber: businessNumber,
      registeredName: businessName,
      selectedBusiness: selectedOption,
    })
  }

  render() {
    const { business, closeModal, isSuperAdmin, pool } = this.props
    const {
      businessDidFetch,
      businessType,
      customBusinessType,
      description,
      efhInvoice,
      email,
      inputErrors,
      gmapsLoaded,
      name,
      orgNumber,
      payoutDay,
      phoneNumber,
      receivePayoutInfoDay,
      registeredName,
      selectableBusinessTypes,
      saveInProgress,
      specificBusinessType,
      websiteName,
      inputWarnings,
    } = this.state

    const { isNewCompany, type } = this.props

    if (!businessDidFetch || saveInProgress || !gmapsLoaded) {
      return (
        <Box p={4} textAlign="center">
          <BeatLoader />
        </Box>
      )
    }

    // Address specific state
    const {
      selectedCity,
      selectedCountry,
      postalCode,
      address,
      invoiceAddress,
      invoiceCity,
      invoicePostalCode,
      invoiceIsSameAddress,
      selectedBusiness,
    } = this.state

    const selectableSubtypes = getSelectableBusinessSubtypes(businessType)

    const addressTooltip =
      'The address needs to include: street name, street number, postal code, city (place) and country. In that order. You will get a drop-down menu when you write in your street name and address number, you can choose your full address from that drop-down menu.'

    const isEventCompanyOrVenue = businessType === 'Event company' || businessType === 'Event venue'
    const headerText =
      (isNewCompany && 'Create company profile') ||
      (type === 'complete-profile' && 'Complete your company profile') ||
      'Edit company profile'

    let incompleteFields: string[] = []
    if (type === 'complete-profile') {
      incompleteFields = getIncompleteProfileFields(business)
    }

    return (
      <Fragment>
        {!isNewCompany && <ModalHeader close={closeModal}>{headerText}</ModalHeader>}
        <Box p={3} className={modalStyles.modalContent}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="center">
            <Grid item container direction="column" alignItems="stretch" className={styles.placesInput}>
              <Grid item className={styles.gridRow}>
                <Box display="flex" justifyContent="space-between">
                  {type === 'complete-profile' && incompleteFields && incompleteFields.length > 0 && (
                    <Box my={2}>
                      <Typography color="error">
                        In order to access all the features in the company admin, you will need to fill out these
                        fields:
                      </Typography>
                      {incompleteFields.map((field) => (
                        <Box my={1} key={field}>
                          <Typography variant="subtitle1" color="error">
                            {field}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                  {type !== 'complete-profile' && <h1>{isNewCompany ? 'Create' : 'Edit'} Company Profile</h1>}
                  <Tooltip
                    interactive
                    leaveDelay={TooltipDelays.WITH_LINKS}
                    title={
                      <Box>
                        <Typography variant="body2">
                          <Link
                            href="http://help.staffers.no/en/articles/4878403-how-to-edit-your-detailed-company-information"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Click here
                          </Link>{' '}
                          for more information on how to edit your company details.
                        </Typography>
                      </Box>
                    }
                  >
                    <div>
                      <HelpIcon />
                    </div>
                  </Tooltip>
                </Box>
              </Grid>
              {isNewCompany && (
                <Grid item className={styles.gridRow}>
                  <Box
                    mt={3}
                    mb={5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    textAlign="center"
                  >
                    <FormControl className={styles.businessField}>
                      <FormLabel>Search for your business</FormLabel>
                      <AsyncSelect
                        components={{
                          Option: this.renderOption,
                        }}
                        placeholder="Enter a name..."
                        noOptionsMessage={({ inputValue }: { inputValue: string }) =>
                          this.isSearchable(inputValue)
                            ? 'No results found.'
                            : 'Enter the name of a business. ' +
                              'Selecting a business will fill out a business name, org. number and an address (optionally).'
                        }
                        value={null} // Don't store the selected value
                        loadOptions={this.getSearchResultsDebounced}
                        onChange={this.onBusinessSearchSelect}
                        className={styles.searchInput}
                        isSearchable
                      />
                    </FormControl>
                  </Box>
                </Grid>
              )}
              {selectedBusiness && (
                <Grid item className={styles.gridRow}>
                  <Box
                    mb={5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    textAlign="center"
                    flexDirection="column"
                  >
                    <Typography variant="subtitle1">Selected business:</Typography>
                    <Typography variant="h6">
                      <strong>{selectedBusiness.businessName}</strong>
                    </Typography>
                    <Typography variant="caption">Org. number: {selectedBusiness.businessNumber}</Typography>
                  </Box>
                </Grid>
              )}
              <Grid item className={styles.gridRow}>
                <FormControl className={styles.formField} required>
                  <FormLabel>Establishment address (street name and number, city, country)</FormLabel>
                  {isNewCompany ? (
                    <div className={styles.flexWrapper}>
                      <GooglePlacesAutocomplete
                        initialValue={address ? `${address}, ${selectedCity}, ${postalCode}, ${selectedCountry}` : ''}
                        onSelect={this.onLocationChange('main')}
                        renderInput={(props: Record<string, unknown>) => (
                          <Input
                            fullWidth
                            {...props}
                            name="establishment-address"
                            autoComplete="shipping street-address"
                          />
                        )}
                      />
                      <p data-tip={addressTooltip} className={styles.tooltip}>
                        <div>
                          <HelpIcon fontSize="small" />
                        </div>
                      </p>
                      <ReactTooltip />
                    </div>
                  ) : (
                    <GooglePlacesAutocomplete
                      initialValue={address ? `${address}, ${selectedCity}, ${postalCode}, ${selectedCountry}` : ''}
                      onSelect={this.onLocationChange('main')}
                      renderInput={(props: Record<string, unknown>) => (
                        <Input
                          fullWidth
                          {...props}
                          name="establishment-address"
                          autoComplete="shipping street-address"
                        />
                      )}
                    />
                  )}
                  <FormHelperText error={!!inputWarnings.streetNr}>{inputWarnings.streetNr}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item className={styles.gridRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="invoicing-address-checkbox"
                      onChange={this.toggleInvoiceIsSameAddress}
                      checked={invoiceIsSameAddress}
                    />
                  }
                  label="Invoicing address is the same as establishment address"
                />
              </Grid>
              {!invoiceIsSameAddress && (
                <Grid item className={styles.invoiceAddress}>
                  <FormControl className={styles.formField}>
                    <FormLabel>Invoice address (street name and number, city, country)</FormLabel>
                    <GooglePlacesAutocomplete
                      initialValue={
                        invoiceAddress
                          ? `${invoiceAddress}, ${invoiceCity}, ${invoicePostalCode}, ${selectedCountry}`
                          : ''
                      }
                      onSelect={this.onLocationChange('invoice')}
                      renderInput={(props: Record<string, unknown>) => (
                        <Input fullWidth {...props} name="billing-address" autoComplete="billing street-address" />
                      )}
                    />
                    <FormHelperText error={!!inputWarnings.streetInvNr}>{inputWarnings.streetInvNr}</FormHelperText>
                  </FormControl>
                </Grid>
              )}
              <Grid item className={styles.gridRow}>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={<Checkbox onChange={this.toggleEfhInvoice} checked={efhInvoice} />}
                    label="I would like EHF invoice"
                  />
                  <Tooltip
                    interactive
                    leaveDelay={TooltipDelays.WITHOUT_LINKS}
                    title="Check the box if you want to receive EHF Invoices."
                  >
                    <Box ml={1}>
                      <HelpIcon />
                    </Box>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
              <Grid className={styles.gridColumn}>
                <Grid item className={styles.gridItem}>
                  <FormControl className={styles.formField}>
                    <FormLabel>Form of establishment</FormLabel>
                    <TextField select id="job-type-selector" value={businessType} onChange={this.onBusinessTypeChange}>
                      {selectableBusinessTypes.map((title) => (
                        <MenuItem key={title} value={title}>
                          {title}
                          {title === 'Other' && <small> ...</small>}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item className={styles.gridItem}>
                  {businessType === 'Other' && (
                    <FormControl id="other-job-form" className={styles.formField}>
                      <FormLabel>Please specify the other form of establishment</FormLabel>
                      <TextField
                        name="other-job-input"
                        required
                        type="text"
                        value={customBusinessType}
                        onChange={this.onCustomBusinessTypeChange}
                        onBlur={this.onCustomBusinessTypeEdit}
                        error={!!inputErrors.customBusinessType}
                        helperText={inputErrors.customBusinessType}
                        placeholder="eg. grocery store, retail, ..."
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item className={styles.gridItem}>
                  {selectableSubtypes.length > 0 && (
                    <FormControl className={styles.formField}>
                      <FormLabel>{`Type of ${businessType.toLowerCase()}`}</FormLabel>
                      <TextField select value={specificBusinessType} onChange={this.onSpecificBusinessTypeChange}>
                        {selectableSubtypes.map((title) => (
                          <MenuItem key={title} value={title}>
                            {title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  )}
                </Grid>
                {!isEventCompanyOrVenue && (
                  <Fragment>
                    {!!pool && (
                      <Grid item className={styles.gridItem}>
                        <FormControl className={styles.formField}>
                          <FormLabel>Salary pay out day</FormLabel>
                          <TextField id="salary-payout-day" select value={payoutDay} onChange={this.onPayoutDayChange}>
                            {R.range(1, 32).map((day) => (
                              <MenuItem key={day} value={day}>
                                {day}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item className={styles.gridItem}>
                      <FormControl className={styles.formField}>
                        <FormLabel>Receive monthly salary details</FormLabel>
                        <TextField
                          id="salary-details"
                          select
                          value={receivePayoutInfoDay}
                          onChange={this.onReceivePayoutInfoDayChange}
                        >
                          {R.range(1, 32).map((day) => (
                            <MenuItem key={day} value={day}>
                              {day}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Fragment>
                )}
                <Grid item className={styles.gridItem}>
                  <FormControl className={styles.formField}>
                    <FormLabel>Company Name</FormLabel>
                    <TextField
                      name="company-name-input"
                      type="text"
                      inputProps={{
                        maxLength: 40,
                      }}
                      onChange={this.onBusinessNameChange}
                      onBlur={this.onBusinessNameEndEdit}
                      placeholder="Company name"
                      error={!!inputErrors.name}
                      helperText={inputErrors.name}
                      value={name}
                    />
                  </FormControl>
                </Grid>
                <Grid item className={styles.gridItem}>
                  <FormControl className={styles.formField}>
                    <FormLabel>Registered Company Name (as in proff.no)</FormLabel>
                    <TextField
                      name="register-company-name"
                      type="text"
                      inputProps={{
                        maxLength: 60,
                      }}
                      onChange={this.onRegisteredBusinessNameChange}
                      placeholder="Registered company name"
                      value={registeredName}
                      error={!!inputErrors.registeredName}
                      onBlur={this.onRegisteredNameEndEdit}
                      helperText={inputErrors.registeredName}
                    />
                  </FormControl>
                </Grid>
                <Grid item className={styles.gridItem}>
                  <FormControl className={styles.formField}>
                    <FormLabel>Website</FormLabel>
                    <TextField
                      name="website-input"
                      type="text"
                      inputProps={{
                        maxLength: 50,
                      }}
                      onChange={this.onWebsiteNameChange}
                      onBlur={this.onWebsiteEndEdit}
                      placeholder="yourwebsite.com"
                      prefix="https://"
                      error={!!inputErrors.websiteName}
                      helperText={inputErrors.websiteName}
                      value={websiteName}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid className={styles.gridColumn}>
                <Grid item className={styles.gridItem}>
                  <FormControl className={styles.formField}>
                    <FormLabel>Phone number</FormLabel>
                    <Box display="flex" justifyContent="center" width={1} mt={1}>
                      <PhoneInput
                        autoFormat
                        inputProps={{
                          autoFocus: true,
                        }}
                        // @ts-ignore it does not exist in the package typing, but dunno
                        error={!!inputErrors.phoneNumber}
                        country={selectedCountry === 'Sweden' ? 'se' : 'no'}
                        value={phoneNumber}
                        onChange={(phone) => this.setState({ phoneNumber: phone })}
                      />
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item className={styles.gridItem}>
                  <FormControl className={styles.formField}>
                    <FormLabel>Description</FormLabel>
                    <TextField
                      name="description-input"
                      type="text"
                      multiline
                      inputProps={{
                        maxLength: 2000,
                      }}
                      onChange={this.onDescriptionChange}
                      placeholder="Something about this business"
                      value={description}
                    />
                  </FormControl>
                </Grid>
                <Grid item className={styles.gridItem}>
                  <FormControl className={styles.formField}>
                    <FormLabel>Invoice email</FormLabel>
                    <TextField
                      name="invoice-email-field"
                      type="text"
                      multiline
                      inputProps={{
                        maxLength: 80,
                      }}
                      placeholder="Invoicing email address"
                      error={!!inputErrors.email}
                      helperText={inputErrors.email}
                      onChange={this.onEmailChange}
                      onBlur={this.onEmailEdit}
                      value={email}
                    />
                  </FormControl>
                </Grid>
                <Grid item className={styles.gridItem}>
                  <FormControl className={styles.formField}>
                    <FormLabel>Org number</FormLabel>
                    <TextField
                      name="org-number-field"
                      disabled={!isSuperAdmin && !selectedCountry}
                      type="text"
                      inputProps={{
                        maxLength: getOrgNumberFormat(selectedCountry || 'Norway').length || 20,
                      }}
                      onChange={this.onOrgNumberChange}
                      onBlur={this.onOrgNumberEndEdit}
                      placeholder={selectedCountry ? (getOrgNumberFormat(selectedCountry || 'Norway') as string) : ''}
                      error={!!inputErrors.orgNumber}
                      helperText={inputErrors.orgNumber}
                      value={this.formatOrgNumber(orgNumber, selectedCountry)}
                    />
                    {!isSuperAdmin && !selectedCountry && (
                      <Box mt={2}>
                        <Typography variant="subtitle2">
                          The org. number field format will be determined after an address has been entered.
                        </Typography>
                      </Box>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid spacing={4} container className={styles.submitBox} justifyContent="center">
              {!isNewCompany && (
                <Grid item>
                  <Button className={styles.closeButton} variant="contained" onClick={closeModal}>
                    Close
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  id="submit-btn"
                  className={styles.saveButton}
                  color="primary"
                  variant="contained"
                  onClick={this.handleSave}
                >
                  {isNewCompany ? 'Create' : 'Save'} profile
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    )
  }

  onEmailChange = (email: ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: email.target.value })
  }

  onEmailEdit: FocusEventHandler<HTMLInputElement> = (email) => {
    this.setState(({ inputErrors }) => ({
      inputErrors: {
        ...inputErrors,
        email: this.getEmailError(email?.target?.value || ''),
      },
    }))
  }

  getEmailError = (email: string): string => {
    if (!email) {
      return 'This field is mandatory'
    }
    if (!isValidEmail(email)) {
      return 'Incorrect format'
    }
    return ''
  }

  onSpecificBusinessTypeChange = (businessType: ChangeEvent<HTMLInputElement>) => {
    this.setState({ specificBusinessType: businessType.target.value })
  }

  toggleInvoiceIsSameAddress = () =>
    this.setState(({ invoiceIsSameAddress }) => ({ invoiceIsSameAddress: !invoiceIsSameAddress }))

  toggleEfhInvoice = () => this.setState(({ efhInvoice }) => ({ efhInvoice: !efhInvoice }))

  onBusinessTypeChange = ({ target: { value: businessType } }: ChangeEvent<HTMLInputElement>) => {
    this.setState(({ customBusinessType }) => ({
      specificBusinessType: businessType === 'Other' ? undefined : getSelectableBusinessSubtypes(businessType)[0] || '',
      businessType,
      customBusinessType: businessType !== 'Other' ? undefined : customBusinessType || '',
    }))
  }

  onCustomBusinessTypeChange = ({ target: { value: customBusinessType } }: ChangeEvent<HTMLInputElement>) => {
    this.setState({ customBusinessType })
  }

  onCustomBusinessTypeEdit: FocusEventHandler<HTMLInputElement> = ({ target: { value: customBusinessType } }) => {
    const { businessTypes } = this.props
    if (businessTypes && businessTypes.values.includes(customBusinessType)) {
      this.setState({
        businessType: customBusinessType,
      })
      return
    }
    this.setState(({ inputErrors }) => ({
      inputErrors: {
        ...inputErrors,
        customBusinessType: this.getCustomBusinessTypeError(customBusinessType),
      },
    }))
  }

  getCustomBusinessTypeError = (customBusinessType?: string) => {
    if (this.state.businessType !== 'Other') {
      return ''
    }
    if (!customBusinessType) {
      return 'This field is mandatory'
    }
    return ''
  }

  onPayoutDayChange = (payoutDay: ChangeEvent<HTMLInputElement>) => {
    this.setState({ payoutDay: payoutDay.target.value as unknown as number })
  }

  onReceivePayoutInfoDayChange = (receivePayoutInfoDay: ChangeEvent<HTMLInputElement>) => {
    this.setState({ receivePayoutInfoDay: receivePayoutInfoDay.target.value as unknown as number })
  }

  onDescriptionChange = (description: ChangeEvent<HTMLInputElement>) => {
    this.setState({ description: description.target.value })
  }

  onBusinessNameChange = (name: ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: name.target.value })
  }

  onRegisteredBusinessNameChange = (name: ChangeEvent<HTMLInputElement>) => {
    this.setState({ registeredName: name.target.value })
  }

  onRegisteredNameEndEdit: FocusEventHandler<HTMLInputElement> = async (orgNumberValue) => {
    const registeredName = orgNumberValue.target.value
    this.setState(({ inputErrors }) => ({
      inputErrors: {
        ...inputErrors,
        registeredName: this.getRegisteredNameError(registeredName),
      },
    }))
  }

  getRegisteredNameError = (orgNumber?: string) => {
    if (!orgNumber) {
      return 'This field is mandatory'
    }
    return ''
  }

  onOrgNumberChange = (orgNumber: ChangeEvent<HTMLInputElement>) => {
    const { selectedCountry } = this.state
    // A simple checker for Swedish org num
    if (selectedCountry === 'Sweden') {
      // Do not allow to write more than max.
      if (orgNumber.target.value.length > 11) {
        return
      }
      this.setState({ orgNumber: orgNumber.target.value.replace(/[^0-9-]/g, '') })
    } else {
      this.setState({ orgNumber: orgNumber.target.value.replace(/\D/g, '') })
    }
  }

  onWebsiteNameChange = (websiteName: ChangeEvent<HTMLInputElement>) => {
    this.setState({ websiteName: websiteName.target.value })
  }

  getBusinessNameError = (name: string) => {
    if (!name) {
      return 'This field is mandatory'
    }
    return ''
  }

  onBusinessNameEndEdit: FocusEventHandler<HTMLInputElement> = (name) => {
    this.setState(({ inputErrors }) => ({
      inputErrors: {
        ...inputErrors,
        name: this.getBusinessNameError(name.target.value),
      },
    }))
  }

  formatOrgNumber = (orgNumber: string, country: string) =>
    applyFormat(getOrgNumberFormat(country), unapplyFormat(orgNumber))

  getOrgNumberError = (orgNumber: string) => {
    const { selectedCountry } = this.state
    if (!orgNumber) {
      return 'This field is mandatory'
    }
    if (selectedCountry === 'Sweden') {
      const fieldRequired = !orgNumber ? 'This field is required.' : ''
      const invalidOrgNumber =
        orgNumber.match(swedenOrgNumRegex) ||
        applyFormat(getOrgNumberFormat('Sweden'), orgNumber).match(swedenOrgNumRegex)
          ? ''
          : 'Invalid org number for Sweden'
      return fieldRequired || invalidOrgNumber
    }
    if (!unapplyFormat(orgNumber).match(/^[0-9]{9}$/)) {
      return 'Incorrect format for Norway'
    }
    // Checking the validity of the org number (Norwegian format)
    // https://www.brreg.no/om-oss-nn/oppgavene-vare/registera-vare/om-einingsregisteret/organisasjonsnummeret/
    const digits = unapplyFormat(orgNumber)
      .split('')
      .map((x) => parseInt(x, 10))
    const sumCheck = digits.splice(-1, 1)[0] // Last digit is sumcheck
    const sumWeights = [3, 2, 7, 6, 5, 4, 3, 2] // P = n[0]*3 + n[1]*2 + n[2]*7...
    const sumProd = digits.reduce((total, value, index) => total + value * sumWeights[index], 0)
    if ((sumProd + sumCheck) % 11 !== 0) {
      return 'Invalid org number'
    }

    return ''
  }

  onOrgNumberEndEdit: FocusEventHandler<HTMLInputElement> = async (orgNumberValue) => {
    const orgNumber = orgNumberValue.target.value
    const { selectedCountry } = this.state
    if (selectedCountry === 'Sweden') {
      const fieldRequired = !orgNumber ? 'This field is required.' : ''
      const invalidOrgNumber = orgNumber.match(swedenOrgNumRegex) ? '' : 'Invalid org number for Sweden'
      this.setState(({ inputErrors }) => ({
        inputErrors: {
          ...inputErrors,
          orgNumber: fieldRequired || invalidOrgNumber,
        },
      }))
    } else {
      this.setState(({ inputErrors }) => ({
        inputErrors: {
          ...inputErrors,
          orgNumber: this.getOrgNumberError(orgNumber),
        },
      }))
    }
  }

  getWebsiteNameError = (websiteName?: string) => {
    if (!websiteName) {
      return ''
    }
    if (!validator.isURL(websiteName)) {
      return 'Incorrect format'
    }
    return ''
  }

  onWebsiteEndEdit: FocusEventHandler<HTMLInputElement> = (websiteNameValue) => {
    const websiteName = websiteNameValue.target.value
    this.setState(({ inputErrors }) => ({
      inputErrors: {
        ...inputErrors,
        websiteName: this.getWebsiteNameError(websiteName),
      },
    }))
  }

  getAddressError = (): string => {
    const {
      address,
      selectedCity,
      selectedCountry,
      postalCode,
      invoiceIsSameAddress,
      invoiceAddress,
      invoiceCity,
      invoiceCountry,
      invoicePostalCode,
    } = this.state
    if (!address) {
      return 'Cannot update business profile:\nAddress is missing street name or street number.'
    }
    if (!selectedCity) {
      return 'Cannot update business profile:\nAddress is missing city.'
    }
    if (!selectedCountry) {
      return 'Cannot update business profile:\nAddress is missing country.'
    }
    if (!postalCode) {
      return 'Cannot update business profile:\nThe address is invalid (unable to determine postal code)'
    }
    if (!invoiceIsSameAddress) {
      if (!invoiceAddress) {
        return 'Cannot update business profile:\nInvoicing address is missing street name or street number.'
      }
      if (!invoiceCity) {
        return 'Cannot update business profile:\nInvoicing address is missing city.'
      }
      if (!invoicePostalCode) {
        return 'Cannot update business profile:\nThe invoicing address is invalid (unable to determine postal code)'
      }
      if (invoiceCountry !== selectedCountry) {
        return 'Cannot update business profile:\nInvoicing address cannot be in a different country than the establishment address!'
      }
    }
    return ''
  }

  handleSave = async () => {
    const {
      name,
      businessType,
      customBusinessType,
      efhInvoice,
      invoiceIsSameAddress,
      invoiceAddress,
      invoiceCity,
      invoicePostalCode,
      orgNumber,
      address,
      placeId,
      email,
      postalCode,
      phoneNumber,
      description,
      selectedCity,
      selectedCountry,
      payoutDay,
      receivePayoutInfoDay,
      registeredName,
      specificBusinessType,
      websiteName,
    } = this.state

    const { regions, business, isNewCompany, isSuperAdmin } = this.props

    if (!isSuperAdmin) {
      const inputErrors = {
        name: this.getBusinessNameError(name),
        customBusinessType: this.getCustomBusinessTypeError(customBusinessType),
        orgNumber: this.getOrgNumberError(orgNumber),
        registeredName: this.getRegisteredNameError(registeredName),
        websiteName: this.getWebsiteNameError(websiteName),
        email: this.getEmailError(email),
      }
      // Display the errors
      this.setState({ inputErrors })
      // If there are errors, abort the save and display a message
      if (R.any((error) => error !== '', R.values(inputErrors))) {
        errorToast('Cannot update business profile:\nSome fields are invalid.')
        console.error('Cannot update business profile:\nSome fields are invalid.')
        return
      }
      const addressError = this.getAddressError()
      if (addressError) {
        errorToast(addressError)
        console.error(addressError)
        return
      }
    }

    const { uid } = getAuth().currentUser || {}
    if (!uid) {
      throw Error('User not authenticated, please refresh the page')
    }

    const selectedRegion = R.find(
      (region: RegionType) => region.cityName === selectedCity && region.countryName === selectedCountry
    )(regions) || { id: `${selectedCountry}:${selectedCity}` }

    const canPostJobsWithCustomAddress = CUSTOM_ADDRESS_BUSINESS_TYPES.includes(businessType)

    const profileData = {
      address: {
        city: selectedCity,
        country: selectedCountry,
        postalCode,
        street: address,
      },
      placeId,
      invoice: invoiceIsSameAddress
        ? null
        : {
            city: invoiceCity,
            country: selectedCountry,
            postalCode: invoicePostalCode,
            street: invoiceAddress,
            extra: '',
          },
      isArchived: false,
      businessName: name,
      businessType,
      ...(customBusinessType !== undefined ? { customBusinessType } : {}),
      ...(specificBusinessType !== undefined ? { specificBusinessType } : {}),
      efhInvoice,
      email,
      description,
      orgNumber: unapplyFormat(orgNumber),
      payoutDay,
      payoutPeriodDay: receivePayoutInfoDay,
      permissions: {
        ...((business && business.permissions) || {}),
        postWithCustomAddress: canPostJobsWithCustomAddress,
      },
      phone: phoneNumber,
      regionId: selectedRegion.id,
      registeredName,
      website:
        websiteName === 'http://'
          ? ''
          : !websiteName || websiteName.startsWith('http')
          ? websiteName
          : `http://${websiteName}`,
    }

    this.setState({ saveInProgress: true })

    const createOrUpdateProfile = async () => {
      if (business.businessId) {
        // If the business has an ID then it exists in DB
        return updateBusinessProfile(business.businessId, profileData)
      }
      return createBusinessForManager(uid, profileData)
    }

    // If new company is being created,
    // send the access request and refresh the page to update the user state
    if (isNewCompany) {
      try {
        await createOrUpdateProfile()
        await sendAccessRequest('business')
        // Update user with tag "Lead business" since they created the profile
        await firestoreHttpsCallable('updateUserWithTag', {
          tagName: 'External company Lead',
          userId: uid,
        })
        toast.success('Profile was successfully created!')
        // Reload and go to company profile page; once we have dashboard redirect there instead
        await global.location.replace('/company-profile')
      } catch (error) {
        this.setState({ saveInProgress: false })
        errorToast('There was a complication while creating the profile or requesting the access. Try again later.')
        console.error((error as Error).message)
      }
      return
    }
    try {
      await createOrUpdateProfile()
      toast.success('Profile was successfully edited')
    } catch (error) {
      errorToast('There was a complication while editing the profile')
      console.error('[EditCompanyProfile] handleSave', (error as Error).message)
    } finally {
      this.setState(
        {
          saveInProgress: false,
        },
        this.props.closeModal
      )
    }
  }
}

// If the business does not exist in DB, we create a local one filled with default params
// const ifNullUseDefaultBusiness = async (
//   businessThatMightBeNull: DocumentReference<DocumentData>,
// ) => (
//   (await businessThatMightBeNull) || {
//     address: {
//       city: '',
//       country: '',
//       postalCode: '',
//       street: '',
//     },
//     businessName: '',
//     businessType: '',
//     description: '',
//     orgNumber: '',
//     payoutDay: 15,
//     phone: '',
//     regionId: '',
//     website: '',
//   }
// )

export default connectFirestore(
  (db, props: Props) => ({
    business: getBusinessById(props.businessId),
    businessTypes: getBusinessTypes(),
    pool: getPoolById(props.businessId),
  }),
  connectFirestore(
    (db: Firestore, { business }: Props) => ({
      regions: business?.isTestUser ? getTestRegions() : getAvailableRegions(),
    }),
    EditCompanyProfile
  )
)
