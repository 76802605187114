// eslint-disable-next-line import/no-extraneous-dependencies
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import dayjs from 'dayjs'
import { useCallback, useState, type FC } from 'react'
import { useDropzone } from 'react-dropzone'
import type { Image } from '../../../../../src/types/common'
import styles from './CreateFulltimeJobGallery.module.css'
import GalleryRemoveModal from './GalleryRemoveModal'
import ImageList from './ImageList/ImageList'

type DragDropProps = {
  title: string
  galleryList?: boolean
}

type FileToRemove = {
  fileName: string
  index: number
}

type Props = {
  onCoverImageChange: (coverImageBlob: Blob | null, image?: Image) => void
  onImagesChange: (imgArray: Image[]) => void
  onBlobsChange: (imagesBlobs: (Blob | string)[]) => void
  coverImageData: Image | null
  galleryImages: Image[]
  galleryBlobs: (Blob | string)[] | null
  coverImageUrl?: string
}

const CreateFulltimeJobGallery: FC<Props> = ({
  onCoverImageChange,
  onImagesChange,
  onBlobsChange,
  coverImageData,
  galleryImages,
  galleryBlobs,
  coverImageUrl,
}) => {
  const DragDropContainer: FC<DragDropProps> = ({ title, galleryList = false }) => {
    const [imagesData, setImagesData] = useState<Image[]>(
      galleryList && galleryImages ? galleryImages : !galleryList && coverImageData ? [coverImageData] : []
    )

    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
    const [fileToRemove, setFileToRemove] = useState<FileToRemove | null>(null)

    const onImageDataChange = (_imagesData: Image[], _galleryBlobs: (Blob | string)[]) => {
      setImagesData(_imagesData)
      onImagesChange(_imagesData)
      onBlobsChange(_galleryBlobs)
    }

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        const filesInfo = acceptedFiles.map((file) => ({
          imageUrl: URL.createObjectURL(file),
          imageFormat: file.type,
          imageName: file.name,
          imageSize: file.size,
          createdAt: dayjs().toString(),
        }))

        const uniqueNewFiles = filesInfo.filter(
          (fileInfo) => !imagesData.some((image) => image.imageName === fileInfo.imageName)
        )
        const maxImagesToAdd = imagesData.length === 0 ? 2 : imagesData.length === 1 ? 1 : 0
        const filesToAdd = uniqueNewFiles.slice(0, maxImagesToAdd)

        if (filesToAdd.length > 0) {
          setImagesData((prevFiles) => [...prevFiles, ...filesToAdd])
          if (galleryList) {
            onImageDataChange(
              [...imagesData, ...filesToAdd],
              [...(galleryBlobs || []), ...acceptedFiles.slice(0, maxImagesToAdd)]
            )
          } else {
            onCoverImageChange(acceptedFiles[0], filesToAdd[0])
          }
        }
      },
      [galleryList, imagesData]
    )

    const onRemoveItem = (imageName: string, index: number) => {
      setFileToRemove({
        fileName: imageName,
        index,
      })
      setIsRemoveModalOpen(true)
    }

    const onRemoveCancel = () => {
      setFileToRemove(null)
      setIsRemoveModalOpen(false)
    }

    const onDelete = (_fileToRemove: FileToRemove | null) => {
      if (!_fileToRemove) return
      setImagesData((prevFiles) => prevFiles.filter((file) => file.imageName !== _fileToRemove.fileName))
      if (!galleryList) {
        onCoverImageChange(null)
      } else {
        onImageDataChange(
          imagesData.filter((_, index) => index !== _fileToRemove.index),
          galleryBlobs?.filter((_, index) => index !== _fileToRemove.index) || []
        )
      }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'video/mp4': [],
      },
    })

    return (
      <div>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.dragDrop} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <span>Drop image here</span>
          ) : (
            <>
              <FileUploadOutlinedIcon className={styles.uploadIcon} style={{ width: 36, height: 36 }} />
              <span className={styles.dropText}>
                Drag and Drop file or <span className={styles.uploadText}>Upload file</span>
              </span>
            </>
          )}
        </div>
        <span className={styles.infoText}>Max file size: 25MB • Support formats: JPG, PNG, MP4</span>
        <ImageList
          imagesData={imagesData}
          onImageDataChange={onImageDataChange}
          onRemoveItem={onRemoveItem}
          galleryList={galleryList}
          galleryBlobs={galleryBlobs}
          coverImageUrl={coverImageUrl}
        />
        <GalleryRemoveModal
          isOpen={isRemoveModalOpen}
          onClose={() => onRemoveCancel()}
          onSubmit={() => onDelete(fileToRemove)}
          fileName={fileToRemove?.fileName || ''}
        />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <DragDropContainer title="Cover photo" />
      <DragDropContainer title="Gallery" galleryList />
    </div>
  )
}

export default CreateFulltimeJobGallery
