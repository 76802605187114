import { Box, Button, Tab, Tabs } from '@material-ui/core'
import type { ChangeEvent } from 'react'
import { Component, Fragment } from 'react'
import type { BusinessType } from '../../../../src/types/business'
import { PREPAID_AD_FEE } from '../../constants/fees'
import { getCurrencyByBusiness } from '../../helpers/regionSelector'
import muiModalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import TabPanel from '../mui/TabPanel'
import CreateFulltimeJob from './CreateFulltimeJob'
import styles from './CreateFulltimeJob.module.css'

type Props = {
  business: BusinessType
  businessId: string
  closeModal: () => void
  goBack?: () => void
}

type State = {
  isPaidUpfront: boolean | null
  tab: number
}

export default class UpfrontWrapper extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isPaidUpfront: null,
      tab: props.business?.region?.countryName !== 'Norway' ? 1 : 0,
    }
  }

  handleSelection = (isPaidUpfront: boolean) => {
    if (isPaidUpfront) {
      this.payUpfront()
    } else {
      this.payAsYouGo()
    }
  }

  payAsYouGo = () =>
    this.setState({
      isPaidUpfront: false,
    })

  payUpfront = () =>
    this.setState({
      isPaidUpfront: true,
    })

  handleTabChange = (event: ChangeEvent<Record<string, never>>, newValue: number) =>
    this.setState({
      tab: newValue,
    })

  render() {
    const { business, businessId, closeModal, goBack } = this.props
    const { isPaidUpfront, tab } = this.state

    const { region } = business

    const isNorway = region?.countryName === 'Norway'
    const currency = getCurrencyByBusiness(business)

    if (isPaidUpfront === null) {
      return (
        <Fragment>
          <ModalHeader close={closeModal} goBack={goBack}>
            <span>Job Ad</span>
          </ModalHeader>
          {isNorway && (
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              value={tab}
              onChange={this.handleTabChange}
              aria-label="Language selection"
            >
              <Tab label="Norsk" />
              <Tab label="English" />
            </Tabs>
          )}
          {/* @ts-ignore weird css error */}
          <Box p={1} mb={2} className={muiModalStyles}>
            <TabPanel value={tab} index={0}>
              <p>{`Staffers tar ${currency} ${PREPAID_AD_FEE},- per annonse for eksponering i 8 uker.`}</p>
              <Box textAlign="center" width={1} mt={6}>
                <Button
                  name="accept-button"
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleSelection(true)}
                >
                  <span className={styles.thankyou}>Jeg godkjenner</span>
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <p>{`Staffers charges ${currency} ${PREPAID_AD_FEE},- per job ad for 8 weeks visibility.`}</p>
              <Box textAlign="center" width={1} mt={6}>
                <Button
                  name="accept-button"
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleSelection(true)}
                >
                  <span className={styles.thankyou}>I accept</span>
                </Button>
              </Box>
            </TabPanel>
          </Box>
        </Fragment>
      )
    }

    return <CreateFulltimeJob businessId={businessId} closeModal={closeModal} isPaidUpfront={isPaidUpfront} />
  }
}
