import * as R from 'ramda'
import type { BusinessType } from '../../../src/types/business'
import { hasPermission } from './permissions'

export const composeBusinessType = (
  business: {
    businessType: string
    customBusinessType?: string
    specificBusinessType?: string
  },
  join = ': '
): string =>
  [
    business.businessType,
    business.businessType === 'Other' ? business.customBusinessType : business.specificBusinessType,
  ]
    .filter(Boolean)
    .join(join)

export const isCompanyProfileComplete = (business: BusinessType) => {
  const { address, businessName, businessType, email, orgNumber, payoutDay, payoutPeriodDay, registeredName } = business

  if (!hasPermission(business, 'postTemporaryJob') && hasPermission(business, 'postFreelanceJob')) {
    // in this case busines don't need to have payout dad and payout period set
    return !!address && !!businessName && !!businessType && !!email && !!orgNumber && !!registeredName
  }

  return (
    !!address &&
    !!businessName &&
    !!businessType &&
    !!email &&
    !!orgNumber &&
    !!payoutDay &&
    !!payoutPeriodDay &&
    !!registeredName
  )
}

export const getIncompleteProfileFields = (business: BusinessType) => {
  const { address, businessName, businessType, email, orgNumber, payoutDay, payoutPeriodDay, registeredName } = business

  const requiredFields = {
    address,
    businessName,
    businessType,
    email,
    orgNumber,
    payoutDay,
    payoutPeriodDay,
    registeredName,
  }

  const isFaulty = (field: any) => !field
  const incompleteFields = R.filter(isFaulty, requiredFields)

  const fieldsFormatted = Object.keys(incompleteFields).map((field) => {
    switch (field) {
      case 'address': {
        return 'Establishment Address'
      }
      case 'businessName': {
        return 'Company Name'
      }
      case 'businessType': {
        return 'Form of Establishment'
      }
      case 'email': {
        return 'Invoice email'
      }
      case 'orgNumber': {
        return 'Org number'
      }
      case 'payoutDay': {
        return 'Salary pay out day'
      }
      case 'payoutPeriodDay': {
        return 'Receive monthly salary details'
      }
      case 'registeredName': {
        return 'Registered Company Name'
      }
      default:
        return ''
    }
  })
  return fieldsFormatted
}
