import type { QuerySnapshot } from 'firebase/firestore'
import { getDoc, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import * as R from 'ramda'
import type { BusinessType } from '../../../../../src/types/business'
import type { JobStaffer, JobType } from '../../../../../src/types/jobs'
import type { PermissionsType, PoolType } from '../../../../../src/types/pools'
import type {
  StafferDocumentsType,
  StafferLogType,
  StafferRatingsType,
  StafferType,
  StafferTypePaymentDetails,
} from '../../../../../src/types/staffer'
import type { StafferReferralsType, StafferReferralsTypeLegacy } from '../../../../../src/types/stafferReferrals'
import { getCollectionRef, getDataFromDocumentRef, getDocumentRef, getQueryRef } from '../wrappers'

export const getStafferReferrals = (uid: string) =>
  getDocumentRef<StafferReferralsType | StafferReferralsTypeLegacy>('staffersReferrals', uid)

export const getStafferReferralsPromise = (uid: string) =>
  getDataFromDocumentRef<StafferReferralsType>(getDocumentRef('staffersReferrals', uid))

export const getStaffersPermissions = () => getQueryRef<PermissionsType>('staffersPermissions')

export const getStafferPermissions = (uid: string) => getDocumentRef<PermissionsType>('staffersPermissions', uid)

export const getStafferPermissionsByIdPromise = async (uid: string): Promise<PermissionsType | undefined> => {
  const permissionsDoc = await getDoc(getDocumentRef<PermissionsType>('staffersPermissions', uid))
  return permissionsDoc.data() as PermissionsType | undefined
}

export const getStafferById = (uid?: string) => getDocumentRef<StafferType>('staffers', uid || '')

export const getStaffersByPhone = (phone: string) =>
  getQueryRef<StafferType>('staffers', where('phone', '==', phone || ''))

export const getStaffersByEmail = (email: string) => getQueryRef<StafferType>('staffers', where('email', '==', email))

export const getAllStaffers = () =>
  getQueryRef<StafferType>('staffers', where('accessGranted', '>', new Date(0)), orderBy('accessGranted', 'desc'))

export const getAllStaffersGranted = () =>
  getQueryRef<StafferType>(
    'staffers',
    // This effectively filters out documents where accessGranted is not of type Date
    where('accessStatus', '==', 'granted'),
    where('accessGranted', '>', new Date(0)),
    orderBy('accessGranted', 'desc')
  )

export const getAllStaffersRequested = () =>
  getQueryRef<StafferType>('staffers', where('accessGranted', '==', false), orderBy('updatedAt', 'desc'))

export const getUnresolvedRegistrationsStaffers = () =>
  getQueryRef<StafferType>(
    'staffers',
    where('accessGranted', '==', false),
    // Only staffers with unfinished onboarding have this step
    where('onboardingStep', 'in', [1, 2, 3, 4, 5]),
    orderBy('updatedAt', 'desc')
  )

export const getEvaluatedStaffers = () =>
  getQueryRef<StafferType>('staffers', where('accessStatus', '==', 'evaluated'), orderBy('updatedAt', 'desc'))

export const getNewRequestsStaffers = () =>
  getQueryRef<StafferType>('staffers', where('accessStatus', '==', 'applied'), orderBy('updatedAt', 'desc'))

export const getUpdatedStaffers = () =>
  getQueryRef<StafferType>(
    'staffers',
    where('accessGranted', '==', false),
    where('accessStatus', '==', 'updated'),
    orderBy('updatedAt', 'desc')
  )

export const getReferenceCallingStaffers = () =>
  getQueryRef<StafferType>('staffers', where('accessStatus', '==', 'calling'), orderBy('updatedAt', 'desc'))

export const getDeniedStaffers = () =>
  getQueryRef<StafferType>('staffers', where('accessStatus', '==', 'denied'), orderBy('updatedAt', 'desc'))

// stafferDetails/{id}/regionalDetails/{country}/verified === false
export const getPendingTaxCardStafferDetails = (countryName: string) =>
  getQueryRef<StafferTypePaymentDetails>(
    'staffersDetails',
    where(`regionalDetails.${countryName}.taxCardVerified`, '==', false),
    orderBy('updatedAt', 'desc')
  )

export const getStafferCertificateDetails = () =>
  getQueryRef<StafferDocumentsType>('staffersDocuments', where('cv', '!=', null))

export const getStafferDetailsById = (uid: string) => getDocumentRef<StafferTypePaymentDetails>('staffersDetails', uid)

export const getStaffersShiftsByShiftIdPromise = async (shiftId: string, job: JobType) =>
  getDocs(
    query(getCollectionRef<JobStaffer>('jobs', job.id, 'staffers'), where('shiftsConfirmed', 'array-contains', shiftId))
  ).then((querySnapshot) =>
    querySnapshot.docs.map((doc) => ({
      stafferId: doc.id,
      shift: (doc.data() as JobStaffer).shifts.find((shift) => shift.shiftId === shiftId),
      businessName: job.businessName,
      jobType: job.jobType,
      jobId: job.id,
      shiftId,
    }))
  )

export const getAllStafferLogsById = (stafferId: string) =>
  getCollectionRef<StafferLogType>('staffers', stafferId, 'logs')

export const getStafferLogsById = (stafferId: string) =>
  query(getCollectionRef<StafferLogType>('staffers', stafferId, 'logs'), orderBy('time', 'desc'), limit(150))

export const getStafferLogsByJobId = (stafferId: string, jobId: string) =>
  query(getCollectionRef<StafferLogType>('staffers', stafferId, 'logs'), where('jobId', '==', jobId), limit(15))

export const canBusinessAccessDocumentsPromise = (businessId: string, stafferId: string): Promise<boolean> =>
  getDoc(getDocumentRef<StafferType>('staffers', stafferId)).then(
    (stafferDoc) =>
      stafferDoc.exists() && ((stafferDoc.data() as StafferType).canBusinessAccessDocuments || {})[businessId]
  )

export const getStafferDocumentsById = (stafferId: string) =>
  getDocumentRef<StafferDocumentsType>('staffersDocuments', stafferId)

export const getStafferByIdPromise = async (uid: string): Promise<StafferType | null> =>
  getDoc(getDocumentRef<StafferType>('staffers', uid)).then((doc) => {
    if (doc.exists()) {
      const stafferData = doc.data() as StafferType
      if (!R.isEmpty(stafferData)) {
        return {
          ...stafferData,
          id: doc.id,
        }
      }
    }
    return null
  })

export const getStafferRatings = (stafferId: string) =>
  getCollectionRef<StafferRatingsType>('staffers', stafferId, 'ratings')

export const getStafferRatingsFromBusiness = (stafferId: string, businessId: string) =>
  getDocumentRef<StafferRatingsType>('staffers', stafferId, 'ratings', businessId)

export const getStaffersReferralsOrdered = () =>
  getQueryRef<StafferReferralsType>('staffersReferrals', orderBy('due', 'desc'))

export const getStafferReferralsById = (uid: string) =>
  getQueryRef<StafferType>('staffers', where('referralId', '==', uid), orderBy('accessGranted', 'desc'))

export const getStaffersByUpdatedExperience = () =>
  getQueryRef<StafferType>(
    'staffers',
    where('experiencesUpdatedAt', '>', new Date(0)),
    where('accessStatus', '==', 'granted'), // This might cause an issue where employees are not
    // showing up when they update their experience, question is - why should they?
    // If you are fixing this bug, please contact me beforehand @oliver
    orderBy('experiencesUpdatedAt', 'desc')
  )

export const getStaffersByTotalShifts = () => getQueryRef<StafferType>('staffers', orderBy('totalShifts', 'desc'))

export const getStaffersByLastLogin = () => getQueryRef<StafferType>('staffers', orderBy('lastLoggedAt', 'desc'))

export const getStaffersByDocumentsUpdate = () =>
  getQueryRef<StafferType>('staffers', where('accessStatus', '==', 'granted'), orderBy('documentsUpdate', 'desc'))

export const getStaffersOnCallingList = () => getQueryRef<StafferType>('staffers', where('callingList', '==', true))

export const getStaffersWithPermissions = (jobType: string) =>
  getQueryRef<PermissionsType>('staffersPermissions', where('positions', 'array-contains', jobType))

export const getAllStaffersPermissions = () =>
  getQueryRef<PermissionsType>('staffersPermissions', orderBy('businessPermissions', 'asc'))

export const getStafferDetailsByCountry = (country: string) =>
  getQueryRef<StafferTypePaymentDetails>('staffersDetails', where('address.country', '==', country))

export const getStafferEmployers = async (stafferId: string, connectedBusinesses: string[]) => {
  const allEmployerPools = (await getDocs(
    getQueryRef<PoolType>('pools', where('employees', 'array-contains', stafferId))
  )) as QuerySnapshot<PoolType> | null
  if (allEmployerPools) {
    const connectedEmployerPools: string[] = allEmployerPools.docs
      .filter((doc) => connectedBusinesses.includes(doc.id))
      .map((doc) => doc.id)
    if (connectedEmployerPools.length > 0) {
      const connectedEmployerBusinesses = await Promise.all(
        connectedEmployerPools.map(async (poolId) => {
          const businessData = await getDataFromDocumentRef<BusinessType>(getDocumentRef('business', poolId))
          return businessData
        })
      )
      return connectedEmployerBusinesses.filter((business) => !!business) || []
    }
    return []
  }
  return []
}

export const needsShiftReview = async (stafferId: string, connectedBusinesses: string[]) => {
  const stafferEmployerBusinesses = await getStafferEmployers(stafferId, connectedBusinesses)
  return (stafferEmployerBusinesses || []).some((business) => business && !!business.requiresShiftReview)
}

export const isEmployeeInPoolsOf = async (stafferId: string, businessId: string): Promise<boolean> => {
  const poolData = await getDataFromDocumentRef<PoolType>(getDocumentRef('pools', businessId))
  if (poolData) {
    // check connected pools
    const { employees } = poolData
    if (employees.includes(stafferId)) {
      return true
    }

    const connectedPools = await Promise.all(
      poolData.connectedBusinesses.map(
        async (connectedBusinessId) =>
          await getDataFromDocumentRef<PoolType>(getDocumentRef('pools', connectedBusinessId))
      )
    )
    return connectedPools.some((companyPoolData) => companyPoolData && companyPoolData.employees.includes(stafferId))
  }
  return false
}
