import { Button, ClickAwayListener, IconButton, MenuItem, Modal, Popover } from '@material-ui/core'
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded, Launch } from '@material-ui/icons'
import { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { BeatLoader } from 'react-spinners'
import type { JobType } from '../../../../../src/types/jobs'
import type {
  StafferDocumentsType,
  StafferLogType,
  StafferRatingsType,
  StafferTypeWithId,
} from '../../../../../src/types/staffer'
import type { StafferReferralsType, StafferReferralsTypeLegacy } from '../../../../../src/types/stafferReferrals'
import CopyToClipboardIcon from '../../../img/algoliaIcons/CopyToClipboardIcon.svg'
import { ModalHeader } from '../modal/Modal'
import styles from './StafferPopover.module.css'
import StafferPopoverContent from './StafferPopoverContent'
import StafferPopoverSidebar from './StafferPopoverSiderbar'
import { firestoreHttpsCallable } from '../../../staffers/api/firestore/https/util'
import { toast } from 'react-toastify'

type ShortlistVariant = {
  variant: 'shortlist' | 'invite' | 'offer'
  onAddToShortlist: () => void
  onMessage?: never
  onInviteToJob?: never
  onOfferJob?: never
  onShowContactInfo?: never
  onTestingOn: () => void
  onAddCancellation: () => void
  onAddLateCancellation: () => void
  onRemoveCancellation: () => void
  onRemoveLateCancellation: () => void
}

type InviteVariant = {
  variant: 'shortlist' | 'invite' | 'offer'
  onAddToShortlist?: never
  onMessage: () => void
  onInviteToJob: () => void
  onOfferJob?: never
  onShowContactInfo: () => void
  onTestingOn?: never
  onAddCancellation?: never
  onAddLateCancellation?: never
  onRemoveCancellation?: never
  onRemoveLateCancellation?: never
}

type OfferVariant = {
  variant: 'shortlist' | 'invite' | 'offer'
  onAddToShortlist?: never
  onMessage: () => void
  onInviteToJob?: never
  onOfferJob: () => void
  onShowContactInfo?: never
  onTestingOn?: never
  onAddCancellation?: never
  onAddLateCancellation?: never
  onRemoveCancellation?: never
  onRemoveLateCancellation?: never
}

type Props = {
  staffer: StafferTypeWithId
  invitedByBusinessId?: string
  stafferDocuments: StafferDocumentsType
  stafferReferrals?: StafferReferralsType | StafferReferralsTypeLegacy | null
  stafferLogs?: StafferLogType[] | null
  completedJobs?: JobType[] | null
  stafferRatings?: StafferRatingsType[] | null
  variantType: ShortlistVariant | InviteVariant | OfferVariant
  shortlists?: string[]
  stafferTags: string[]
  appVersion: { deviceVersion: string | null; buildNumber: string | null; os: string | null }
  isOpen: boolean
  onClose: () => void
  isSuperAdmin?: boolean
  goToPrevStaffer?: () => any
  goToNextStaffer?: () => any
  onGetPaymentDetails?: () => void
  onEditPermissions?: () => void
  onPromotionToggle: () => void
  onCallingList: () => void
  onBlockingStaffer: () => void
  onRemoveStaffer: () => void
  onApproveDocuments: () => void
  onDenyDocuments: () => void
  onViewStafferRatings: () => void
}

const StafferPopover = ({
  staffer,
  invitedByBusinessId,
  stafferDocuments,
  stafferReferrals,
  stafferLogs,
  completedJobs,
  stafferRatings,
  variantType,
  shortlists,
  stafferTags,
  appVersion,
  isOpen,
  onClose,
  isSuperAdmin,
  goToNextStaffer,
  goToPrevStaffer,
  onGetPaymentDetails,
  onEditPermissions,
  onPromotionToggle,
  onCallingList,
  onBlockingStaffer,
  onRemoveStaffer,
  onApproveDocuments,
  onDenyDocuments,
  onViewStafferRatings,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isGleapButtonDisabled, setGleapButtonDisabled] = useState(false)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  if (!staffer && !stafferDocuments) {
    return <BeatLoader />
  }

  const onCopyProfileLink = () => {
    navigator.clipboard.writeText(
      isSuperAdmin ? `${window.location.origin}/staffers/${staffer.userId}` : 'Copy profile link'
    )
  }

  const openGleapProfile = async () => {
    try {
      setGleapButtonDisabled(true)
      const response = await firestoreHttpsCallable('getCustomerSupportStafferProfile', {
        stafferId: staffer.id,
      })
      if (!response.data) {
        throw Error('No url fetched', response)
      }
      const { profile } = response.data
      if (profile) {
        const { profileUrl } = profile
        window.open(profileUrl)
      }
    } catch (e) {
      toast.error((e as Error).message)
    } finally {
      setGleapButtonDisabled(false)
    }
  }

  return (
    <Modal
      hideBackdrop
      open={isOpen}
      style={{ backgroundColor: 'rgba(0,0,0,0.35)', zIndex: 20 }}
      className={styles.modal}
    >
      <>
        <div className={styles.paginationContainer}>
          {goToPrevStaffer && (
            <div className={styles.arrowWrapperLeft}>
              <IconButton onClick={goToPrevStaffer} style={{ justifySelf: 'flex-start' }}>
                <KeyboardArrowLeftRounded className={styles.paginationArrow} />
              </IconButton>
            </div>
          )}
          {goToNextStaffer && (
            <div className={styles.arrowWrapperRight}>
              <IconButton onClick={goToNextStaffer} style={{ justifySelf: 'flex-end' }}>
                <KeyboardArrowRightRounded className={styles.paginationArrow} />
              </IconButton>
            </div>
          )}
        </div>
        <div className={styles.container}>
          <ModalHeader
            title="User detail"
            onClose={onClose}
            rightActions={
              isSuperAdmin &&
              variantType.variant === 'shortlist' && (
                <div>
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Button
                      variant="outlined"
                      onClick={handleClick}
                      style={{ textTransform: 'unset' }}
                      endIcon={<FaChevronDown style={{ fontSize: 10 }} />}
                    >
                      Actions
                    </Button>
                  </ClickAwayListener>
                  <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    style={{ marginTop: 5 }}
                  >
                    <MenuItem onClick={onBlockingStaffer}>
                      {staffer.accessBlocked ? 'Unblock user' : 'Block user'}
                    </MenuItem>
                    <MenuItem onClick={onRemoveStaffer}>Remove user</MenuItem>
                  </Popover>
                </div>
              )
            }
          />
          {staffer.isTestUser && <div className={styles.testingUser}>Test user</div>}
          <div className={styles.content}>
            <StafferPopoverSidebar
              staffer={staffer}
              isSuperAdmin={isSuperAdmin}
              onGetPaymentDetails={onGetPaymentDetails}
              onEditPermissions={onEditPermissions}
              onEditNameOrAge={onApproveDocuments}
              onPromotionToggle={onPromotionToggle}
              onCallingList={onCallingList}
              onTestingOn={variantType.onTestingOn}
              onAddCancellation={variantType.onAddCancellation}
              onAddLateCancellation={variantType.onAddLateCancellation}
              onRemoveCancellation={variantType.onRemoveCancellation}
              onRemoveLateCancellation={variantType.onRemoveLateCancellation}
              stafferTags={stafferTags}
              appVersion={appVersion}
              onBlockingStaffer={onBlockingStaffer}
              onViewStafferRatings={onViewStafferRatings}
            />
            <StafferPopoverContent
              stafferId={staffer.userId}
              stafferReferrals={stafferReferrals}
              stafferLogs={stafferLogs}
              staffer={staffer}
              invitedByBusinessId={invitedByBusinessId}
              stafferDocuments={stafferDocuments}
              completedJobs={completedJobs}
              stafferRatings={stafferRatings}
              isSuperAdmin={isSuperAdmin}
              shortlists={shortlists}
              onApproveDocuments={onApproveDocuments}
              onDenyDocuments={onDenyDocuments}
            />
          </div>
          <div className={styles.footer}>
            <Button onClick={() => onCopyProfileLink()} className={styles.copyToClipboard}>
              <img src={CopyToClipboardIcon} className={styles.largeIcon} style={{ marginRight: 10 }} />
              Copy profile link
            </Button>
            <Button
              endIcon={<Launch />}
              onClick={() => openGleapProfile()}
              className={styles.copyToClipboard}
              disabled={isGleapButtonDisabled}
            >
              Open Gleap profile 
            </Button>
            {variantType.variant === 'shortlist' && (
              <Button
                variant="contained"
                color="primary"
                className={styles.buttonFooter}
                onClick={variantType.onAddToShortlist}
              >
                Add to shortlist
              </Button>
            )}
            {(variantType.variant === 'invite' || variantType.variant === 'offer') && (
              <div className={styles.contentRow} style={{ justifyContent: 'flex-end' }}>
                {variantType.variant === 'invite' && (
                  <Button
                    variant="outlined"
                    color="primary"
                    className={styles.buttonFooter}
                    onClick={variantType.onShowContactInfo}
                  >
                    Show contact info
                  </Button>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  className={styles.buttonFooter}
                  onClick={variantType.onMessage}
                >
                  Message
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.buttonFooter}
                  onClick={variantType.variant === 'invite' ? variantType.onInviteToJob : variantType.onOfferJob}
                >
                  {variantType.variant === 'invite' ? 'Invite to job' : 'Offer job'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </>
    </Modal>
  )
}

export default StafferPopover
