import {
  Avatar,
  Badge,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import type { NotificationType } from '../../../../src/types/notifications'
import { getElapsedTimeText } from '../../helpers/getElapsedTimeText'
import moment from '../../util/moment'
import styles from './NotificationList.module.css'

type Props = {
  notification: NotificationType
  onClick?: (notification: NotificationType) => any
}

const NotificationListItem = (props: Props) => {
  const { notification, onClick } = props
  const {
    content: { title, photoUrl, body },
    metadata: { emittedAt, openedAt },
  } = notification

  const elapsedTime = moment.duration(moment().diff(moment(emittedAt)))
  const elapsedTimeText = getElapsedTimeText(elapsedTime)
  const isNew = !openedAt
  return (
    <ListItem button onClick={() => onClick && onClick(notification)}>
      <ListItemAvatar>
        <Avatar src={photoUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Badge
            invisible={!isNew}
            badgeContent={' '}
            color="error"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            variant="dot"
          >
            {title}
          </Badge>
        }
        primaryTypographyProps={{
          className: styles.names,
        }}
        secondary={body}
      />
      <ListItemSecondaryAction>
        <Typography variant="caption" display="block" align="right">
          {elapsedTimeText}
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default NotificationListItem
