import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  type DocumentData,
  type Query,
  type QueryConstraint,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { type CollectionReference, type DocumentReference } from '../../../../src/types/firebase'

/**
 * Collection names
 */
export type Collection =
  | 'admins'
  | 'algoliaMetadata'
  | 'business'
  | 'businessAmbassadors'
  | 'businessOnboarding'
  | 'businessPayments'
  | 'businessSearch'
  | 'counters'
  | 'countries'
  | 'dailyActivity'
  | 'devices'
  | 'drafts'
  | 'enums'
  | 'errors'
  | 'groups'
  | 'inviteCodes'
  | 'jobAds'
  | 'jobAdsSearch'
  | 'jobs'
  | 'jobsDeleted'
  | 'jobsSearch'
  | 'managers'
  | 'managersInvites'
  | 'managersReferrals'
  | 'pools'
  | 'preferredPositions'
  | 'referenceSearch'
  | 'referrals'
  | 'regions'
  | 'settings'
  | 'shortlists'
  | 'staffers'
  | 'staffersAmbassadors'
  | 'staffersBankId'
  | 'staffersDetails'
  | 'staffersDocuments'
  | 'staffersInvites'
  | 'staffersPayments'
  | 'staffersPermissions'
  | 'staffersReferrals'
  | 'staffersSearch'
  | 'unpublished'
  | 'users'

/**
 * Typed helper to obtain a reference to a collection
 */
export const getCollectionRef = <T extends DocumentData>(collectionName: Collection, ...pathSegments: string[]) =>
  collection(getFirestore(), collectionName, ...pathSegments) as CollectionReference<T>

/**
 * Typed helper to obtain a reference to a document
 */
export const getDocumentRef = <T extends DocumentData>(
  collectionName: Collection,
  documentId?: string,
  ...pathSegments: string[]
): DocumentReference<T> =>
  documentId
    ? doc(getCollectionRef<T>(collectionName), documentId, ...pathSegments)
    : doc(getCollectionRef<T>(collectionName), ...pathSegments)

/**
 * Typed helper to obtain query reference
 */
export const getQueryRef = <T extends DocumentData>(collectionName: Collection, ...constraints: QueryConstraint[]) => {
  const collectionRef = getCollectionRef<T>(collectionName)
  return query<T, T>(collectionRef, ...constraints)
}

/**
 * Function to obtain data from `document` reference. Returns `data()` if document exists, otherwise `null`.
 *
 * @param ref document reference
 * @returns
 */
export const getDataFromDocumentRef = async <T extends DocumentData>(ref: DocumentReference<T>): Promise<T | null> => {
  // obtain snapshot
  const response = await getDoc(ref)
  return response.exists() ? response.data() : null
}

/**
 * Function to obtain data from `collection` or `query` reference.
 * T stands for type of data in the snapshot (obtained from database).
 * D stands for optional (otherwise type is the same as snapshot) custom return type.
 * It is not necessary to specify type `T` and `D` as array as to the return type is by default added array.
 * ```
 * Example:
  getDataFromQuery<IErrorType, IErrorType & { newId: string }>(getErrorLogs(10), (doc) => ({
    newId: doc.id,
    ...(doc.data()),
  })).then((data) => {
    console.log('obtained 10 error logs:', data);
  });
  ```
 * @param ref query reference
 * @param mapFn custom map function to map the data from the snapshot
 * @returns Promise array of T or D types
 */
export const getDataFromCollectionRef = async <T extends DocumentData, D = T>(
  ref: Query<T> | CollectionReference<T>,
  mapFn: (item: QueryDocumentSnapshot<T>) => T | D = (snap: QueryDocumentSnapshot<T>) => snap.data()
): Promise<(T | D)[] | null> => {
  // obtain snapshot
  const querySnapshot = await getDocs(ref)
  // return data
  return !querySnapshot.empty ? querySnapshot.docs.map(mapFn) : null
}
