import type { Moment } from 'moment'
// I presume this errors because package is dated and uses the require() style CJS syntax? 🤷‍♂️
// eslint-disable-next-line import/default
import moment from 'moment-holiday'
import type { Timestamp } from '../../../src/types/firebase'
import HOLIDAYS from '../constants/holidays'

// @ts-ignore - the moment-holiday is not properly typed
moment.modifyHolidays.set(HOLIDAYS)

export const isHoliday = (date: Date | number | Timestamp | Moment): boolean =>
  // @ts-ignore - the moment-holiday is not properly typed
  !!moment(typeof date === 'number' ? date : (date as Timestamp).toMillis?.() || date).isHoliday()
