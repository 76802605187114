import Ionicon from '../Icon'
import styles from './badges.module.css'

const VerifiedBadge = ({ billingType }: { billingType: 'individual' | 'company' }) => (
  <div className={styles.wrapper}>
    <div className={styles.badgeSuccess}>
      {billingType === 'company' && <Ionicon icon="ios-briefcase-outline" color="#FF004D" fontSize="35" />}
      {billingType === 'individual' && <Ionicon icon="ios-person-outline" color="#00B841" fontSize="35" />}
    </div>
    <span className={styles.label}>
      Billing as
      {billingType}
    </span>
  </div>
)

export default VerifiedBadge
