import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import { config } from '../../../../config'

export const firestoreHttpsCallable = async (
  functionName: string,
  data: Record<string, any>
): Promise<Record<string, any>> =>
  config.projectId === 'staffers-production'
    ? firebase.app().functions('europe-west1').httpsCallable(functionName)(data)
    : config.projectId === 'staffers-testing'
    ? firebase.app().functions('europe-west3').httpsCallable(functionName)(data)
    : firebase.functions().httpsCallable(functionName)(data)
