import type { Moment } from 'moment'
import type { CountryNameType } from '../../../../src/types/common'
import type { Timestamp } from '../../../../src/types/firebase'
import type { ShiftTypeBasic } from '../../../../src/types/jobs'
import moment from '../../util/moment'

export const POLICY_START_DATE = '2019-09-15'
export const useNewPricing = (date: Date | Timestamp) => moment(POLICY_START_DATE).isBefore(moment(date))

export type ChargesOfCountry = {
  normal: number
  holiday: number
}

export const JobCostConstants = {
  MIN_SALARY_HOURLY: 195,
  MIN_POOL_SALARY_HOURLY: 185,
  MIN_HOLIDAY_SALARY_HOURLY: 250,
  MIN_FREELANCE_SALARY_HOURLY: 240,

  MANYMORE_CHARGE_RATE: 0.049, // per total hours
  INSURANCE_FEE: 1.6, // per hour, subtracted from staffer wage - (not actually being used)

  SOCIAL_RATE_NOR: 0.141, // norway 14.1% - added to business cost (without manymore fee)
  SOCIAL_RATE_SWE: 0.3142, // sweden 31.42% - added to business cost (without manymore fee)

  SERVICE_CHARGE: {
    // keep the keys in order starting from most recent on top
    '2024-06-02': {
      Norway: {
        normal: 84,
        holiday: 98,
      },
      Sweden: {
        normal: 69,
        holiday: 49,
      },
    } as Record<CountryNameType, ChargesOfCountry>,
    [POLICY_START_DATE]: {
      Norway: {
        normal: 69,
        holiday: 98,
      },
      Sweden: {
        normal: 69,
        holiday: 49,
      },
    } as Record<CountryNameType, ChargesOfCountry>,
  },
  // Service charge based on total wage (1.0 = 100%)
  SERVICE_CHARGE_RATE: 0.18, // no longer used
}

const getSocialCostRate = (countrySocialCost: number) =>
  Math.ceil(
    100 * // round up to 2 decimal places
      (1 + JobCostConstants.MANYMORE_CHARGE_RATE) *
      (1 + countrySocialCost)
  ) /
    100 -
  1
// this is what we present as socialCost for business
// but it actually also includes the Payout Partner fee
export const socialCostRateByCountry: Record<CountryNameType, number> = {
  Norway: getSocialCostRate(JobCostConstants.SOCIAL_RATE_NOR),
  Sweden: getSocialCostRate(JobCostConstants.SOCIAL_RATE_SWE),
}

type SinceType = keyof typeof JobCostConstants.SERVICE_CHARGE

export const getHourlyServiceChargeByCountry = (
  country = 'Norway',
  isHoliday = false,
  startDate: Date | Moment = new Date()
): number => {
  const since: SinceType =
    (Object.keys(JobCostConstants.SERVICE_CHARGE) as Array<SinceType>).find((key) =>
      moment(startDate).isAfter(moment(key))
    ) ?? (Object.keys(JobCostConstants.SERVICE_CHARGE)[0] as SinceType)
  const chargesOfCountry =
    JobCostConstants.SERVICE_CHARGE[since][country as CountryNameType] ?? JobCostConstants.SERVICE_CHARGE[since].Norway
  if (isHoliday) {
    return chargesOfCountry.holiday
  }
  return chargesOfCountry.normal
}

// This was previously under SOCIAL_RATE / SOCIAL_RATE_SWE
// should no longer be used
export const legacySocialCostRateByCountry: Record<CountryNameType, number> = {
  Norway: 0.2023, // 1.20481927711 on some places
  Sweden: 0.375,
}

export type JobTypeSkill = {
  wageMin: number
  wageMax: number
  skill: 'low' | 'medium' | 'high'
}

export type JobTypesSkills = {
  [key: string]: JobTypeSkill[]
}

export const SKILLED_JOB_TYPES = ['Chef', 'Bartender', 'Waiter']

type PartialJobType = {
  createdAt: Timestamp
  salaryCurrency: string
  salaryHourly: number
  isHolidayJob: boolean
}
export type JobDataType = PartialJobType & {
  shifts: Array<ShiftTypeBasic>
}

export type JobCostInfoType = {
  isFree?: boolean
  salaryCurrency: string
  salaryHourly: number
  serviceCharge: number
  serviceChargeRate: number // per hour
  serviceChargeTitle: string
  totalCostWithCharge: number
  totalSalary: number
  totalWorkingHours: number
}
