import type { ItemType } from './itemCreator'

const checkUrl = (sidebarPath: string, location: { pathname: string; search: string }) =>
  sidebarPath === location.pathname || sidebarPath === `${location.pathname}${location.search}`

const getAltPathMatch = (altPath: string, location: { pathname: string; search: string }) => {
  // Case X.1: Exact altPath match
  if (checkUrl(altPath, location)) {
    return true
  }

  // Case X.2: altPath match with dynamic id
  const locChunks = location.pathname.split('/')
  const altChunks = altPath.split('/')
  const fitsStyle = locChunks.length === altChunks.length && locChunks[1] === altChunks[1]
  return fitsStyle
}

export const getActiveItem = (
  location: { pathname: string; search: string },
  menuItems: ItemType[]
): { activeParent: string; activeChild?: string } => {
  let childMatch: ItemType | typeof undefined
  // We attempt to find exact url match to action item
  const exactMatch = menuItems.find(({ collapsibleItems, path, altPaths }) => {
    // !! We need to check collapsible children first, to make sure we dont return too early

    // Case 1: First we attempt to match collapsibleItems path
    childMatch =
      collapsibleItems && collapsibleItems.find(({ path: childPath }) => childPath && checkUrl(childPath, location))
    if (childMatch) {
      return true
    }

    // Case 2: We scan for altPaths in collapsibleItems
    childMatch =
      collapsibleItems &&
      collapsibleItems.find(
        ({ altPaths: childAltPaths }) =>
          childAltPaths && childAltPaths.find((childAltPath) => getAltPathMatch(childAltPath, location))
      )
    if (childMatch) {
      return true
    }

    // Case 3: We found exact match with root url
    if (path && checkUrl(path, location)) {
      return true
    }

    // Case 4: We scan for altPaths (eg. job/:jobId)
    const altPathMatch = altPaths && altPaths.find((altPath) => getAltPathMatch(altPath, location))
    if (altPathMatch) {
      return true
    }
    // If not a single match, move to next element
    return false
  })
  // Should work 99% of times, user should not be able to navigate content inaccessible in sidebar
  if (exactMatch) {
    return { activeParent: exactMatch.name, activeChild: childMatch ? childMatch.name : undefined }
  }
  // Fallback - If unable to match, we presume it's on default '/' Company Profile
  return { activeParent: 'Company Profile' }
}
