import { FormControl, MenuItem, Select as SelectMUI, TextField, makeStyles } from '@material-ui/core'
import { KeyboardArrowDownRounded, SearchOutlined } from '@material-ui/icons'
import styles from './SearchBar.module.css'

const selectStyles = {
  root: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  select: {
    color: '#fff',
    fontSize: 14,
    paddingLeft: 12,
  },
  menu: {
    backgroundColor: '#455361',
    color: 'white',
  },
}
const useSelectStyles = makeStyles(selectStyles)

const textStyles = {
  root: {
    display: 'flex',
    flex: 1,
    minWidth: 50,
  },
  input: {
    color: '#fff',
    fontSize: 14,
    paddingTop: 6,
    paddingRight: 4,
    paddingBottom: 6,
    paddingLeft: 4,
  },
}
const useTextFieldStyles = makeStyles(textStyles)

const SearchTypeValues = [
  { value: 'staffers', label: 'Jobseekers' },
  { value: 'companies', label: 'Companies' },
] as const

export type SearchType = (typeof SearchTypeValues)[number]['value']

type SearchBarProps = {
  value: string
  onChange: (value: string) => any
  searchType: SearchType
  onSearchTypeChange: (value: SearchType) => any
}

const SearchBar = ({ value, onChange, searchType, onSearchTypeChange }: SearchBarProps) => {
  const selectStyle = useSelectStyles()
  const textStyle = useTextFieldStyles()

  return (
    <div className={styles.container}>
      <div className={styles.searchWrapper}>
        <label htmlFor="angoliaSearchInput">
          <SearchOutlined style={{ color: 'white', marginRight: 4, marginTop: 4, width: 18, height: 18 }} />
        </label>
        <TextField
          variant="standard"
          id="angoliaSearchInput"
          placeholder="Search"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          classes={{ root: textStyle.root }}
          InputProps={{ disableUnderline: true, classes: { root: textStyle.input } }}
        />
      </div>
      <div className={styles.divider} />
      <FormControl variant="standard">
        <SelectMUI
          disableUnderline
          value={searchType || SearchTypeValues[0].value}
          IconComponent={(iconProps) => <KeyboardArrowDownRounded {...iconProps} style={{ color: 'white' }} />}
          onChange={(e) => onSearchTypeChange(e.target.value as SearchType)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            getContentAnchorEl: null,
            disablePortal: true,
            classes: { paper: selectStyle.menu },
          }}
          classes={{
            root: selectStyle.select,
          }}
        >
          {SearchTypeValues.map(({ value: searchValue, label }) => (
            <MenuItem key={searchValue} style={{ fontSize: 14 }} value={searchValue}>
              {label}
            </MenuItem>
          ))}
        </SelectMUI>
      </FormControl>
    </div>
  )
}

export default SearchBar
