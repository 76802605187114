import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import type { BusinessType } from '../../../../../src/types/business'
import type { Firestore, Timestamp } from '../../../../../src/types/firebase'
import type { ManagerType } from '../../../../../src/types/manager'
import { getServerTimestamp } from '../firestore/util.legacy'
import { getManagerById } from '../getters/managers.legacy'

export const getDefaultEmptyBusiness = (
  businessId: string,
  createdBy: string,
  businessName?: string,
  groupId?: string,
  isFromAppOnboarding?: boolean,
  country?: string,
  grantAccess?: boolean
): Omit<BusinessType, 'region'> => ({
  address: {
    city: '',
    country: country || '',
    extra: '',
    postalCode: '',
    street: '',
  },
  businessId,
  businessName: businessName || '',
  businessType: '',
  coordinates: null,
  createdAt: getServerTimestamp(),
  createdBy,
  description: '',
  isArchived: false,
  ...(isFromAppOnboarding ? { isFromAppOnboarding } : {}),
  ...(groupId ? { groupId } : {}),
  orgNumber: '',
  payoutDay: 15,
  payoutPeriodDay: 1,
  phone: '',
  ratingsCount: 0,
  ratingsTotal: 0,
  regionId: null,
  referralId: '',
  referringId: '',
  duties: {},
  specificBusinessType: '',
  website: '',
  ...(grantAccess
    ? {
        accessRequested: getServerTimestamp() as Timestamp,
        accessGranted: true,
        accessGrantedAt: getServerTimestamp() as Timestamp,
        accessGrantedBy: 'auto',
      }
    : {}),
})

export const createBusinessProfile = async (managerId: string): Promise<string> => {
  const database = firebase.firestore() as Firestore

  const managerDoc = await getManagerById(database, managerId)
  const businessDoc = database.collection('business').doc()
  const businessId = businessDoc.id

  if (!businessId) {
    throw Error('Database error - Could not create business profile.')
  }

  // Initializing business data
  const businessData = getDefaultEmptyBusiness(businessId, managerId)

  // Assigning the business to the manager
  const managerData: Partial<ManagerType> = {
    isOwner: true,
    businessId,
  }

  try {
    await database.runTransaction(async (transaction) => {
      await transaction.set(businessDoc, businessData)
      await transaction.update(managerDoc, managerData)
    })
  } catch (error) {
    console.warn(error)
    throw Error('Database error. Your business could not be created.')
  }

  return businessId
}

export const createEmptyBusinessProfile = async (
  adminId: string,
  businessName: string,
  groupId: string,
  country: string,
  grantAccess?: boolean
): Promise<string> => {
  const database = firebase.firestore()
  const businessDoc = database.collection('business').doc()
  const businessId = businessDoc.id

  if (!businessId) {
    throw Error('Database error - Could not create business profile.')
  }

  // Initializing business data
  const businessData = getDefaultEmptyBusiness(
    businessId,
    adminId,
    businessName,
    groupId,
    true, // So these businesses bypass initial setup screen
    country,
    grantAccess
  )

  try {
    await businessDoc.set(businessData)
  } catch (error) {
    console.warn(error)
    throw Error('Database error. Your business could not be created.')
  }

  return businessId
}
