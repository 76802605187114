import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@material-ui/core'
import { Clear as ClearIcon, Create as CreateIcon } from '@material-ui/icons'
import type { ChangeEvent } from 'react'
import { Fragment } from 'react'
import type { CustomPositionType } from '../../../../../src/types/groups'
import styles from './CreateEditGroup.module.css'
import EditPositionsModal from './EditPositionsModal'

type Props = {
  editingPositionIndex: number | null
  onAddPosition: (id: number) => void
  onChangePosition: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => void
  onRemovePosition: (positionIndex: number) => void
  onToggleEditPosition: (editingPositionIndex: number | null) => void
  positions: CustomPositionType[]
}

const CreatePositionsStep = (props: Props) => {
  const { editingPositionIndex, onAddPosition, onChangePosition, onRemovePosition, onToggleEditPosition, positions } =
    props

  if (editingPositionIndex !== null) {
    return (
      <EditPositionsModal
        onConfirm={onToggleEditPosition}
        onChangePosition={onChangePosition}
        position={positions[editingPositionIndex]}
      />
    )
  }

  const addPositionDisabled = positions.find(({ name }) => !name || name.length < 2)

  return (
    <Fragment>
      <Typography className={styles.infoNote}>The position has to contain at least 2 characters.</Typography>
      <Box mt={2}>
        {positions.map((position, index) => {
          const { name } = position
          const num = index + 1

          return (
            <Box display="flex" alignItems="center" key={index} mb={2}>
              <Box mr={1}>
                <Typography>{num}. Position name:</Typography>
              </Box>
              <TextField variant="outlined" name="name" value={name} onChange={(e) => onChangePosition(e, index)} />
              {positions.length > 1 && (
                <Box ml={2}>
                  <Tooltip title="Remove position">
                    <IconButton size="small" edge="start" onClick={() => onRemovePosition(index)}>
                      <ClearIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <Box ml={2}>
                <Tooltip title="Edit position">
                  <IconButton size="small" edge="start" onClick={() => onToggleEditPosition(index)}>
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )
        })}
      </Box>
      <Box>
        <Button
          // @ts-ignore
          disabled={addPositionDisabled}
          variant="contained"
          className={styles.addPositionsButton}
          onClick={onAddPosition}
        >
          + Add Position
        </Button>
      </Box>
    </Fragment>
  )
}

export default CreatePositionsStep
