import Box from '@material-ui/core/Box'
import type { ReactNode } from 'react'

// using inexact object type as we can pass other props in addition to required ones
type Props = {
  children: ReactNode
  value: string | number
  index: string | number
  p?: number
}

/**
 * TabPanel for MUI <Tabs>
 *
 * IMPORTANT: Unlike the one in docs, this one does not automatically wrap children
 * in <Typography> tag, so please if you intend to use only text, wrap the passed children instead.
 * (eg. <TabPanel><Typography>Hello</Typography></Tabepanel>)
 * This way we can pass also other things to tabs than only <Typography> content
 *
 * @see https://material-ui.com/components/tabs/
 */
const TabPanel = (props: Props) => {
  const { children, value, index, p = 3, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={p}>{children}</Box>}
    </div>
  )
}

export default TabPanel
