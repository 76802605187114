export function entries<O extends Record<string, unknown>>(obj: O) {
  return Object.entries(obj) as [keyof O, O[keyof O]][]
}

export function values<O extends Record<string, unknown>>(obj: O) {
  return Object.values(obj) as O[keyof O][]
}

export function keys<O extends Record<string, unknown>>(obj: O) {
  return Object.keys(obj) as (keyof O)[]
}
