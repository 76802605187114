import type firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

type CollectionReference = firebase.firestore.CollectionReference
type Query = firebase.firestore.Query

export const getSearchQueryNext = (searchQuery: string): string => {
  const searchQueryStart = searchQuery.slice(0, searchQuery.length - 1)
  const searchQueryEnd = String.fromCharCode(searchQuery.charCodeAt(searchQuery.length - 1) + 1)
  return searchQueryStart + searchQueryEnd
}

export const searchText = (query: CollectionReference | Query, searchByField: string, searchQuery: string): Query => {
  const searchQueryNext = getSearchQueryNext(searchQuery)
  return query.where(searchByField, '>=', searchQuery).where(searchByField, '<', searchQueryNext) as Query
}
