export const PATHS = {
  dashboard: '/',
  regions: '/regions',
  regionDetail: '/regions/:id',
  companyProfile: '/company-profile',
  companyRequests: '/company-requests',
  companyRequestDetail: '/company-requests/:id',
  companies: '/companies',
  companiesSearch: '/companies-search',
  companyDetail: '/companies/:id',
  companyPaymentCycles: '/company-payment-cycles/',
  contracts: '/contracts',
  chats: '/chats',
  chat: '/chats/:id',
  staffers: '/staffers',
  staffersSearch: '/staffers-search',
  stafferDetail: '/staffers/:id',
  companyPool: '/staffers?view=companyPool',
  poolInvites: '/staffers?view=poolInvites',
  previousStaffers: '/staffers?view=previousStaffers',
  favoriteStaffers: '/staffers?view=favoriteStaffers',
  stafferRequests: '/staffer-requests',
  referrals: '/referrals',
  stafferRequestDetail: '/staffer-requests/:id',
  reports: '/reports',
  inviteCodes: '/invites',
  jobs: '/jobs',
  jobsTemporary: '/jobs?view=temporary',
  jobsEvent: '/jobs?view=event',
  jobsAds: '/jobs?view=job-ads',
  jobsUnpublished: '/jobs-unpublished',
  jobDetail: '/jobs/:id/:shiftId',
  jobDeletedDetail: '/jobs-deleted/:id',
  jobUnpublishedDetail: '/jobs-unpublished/:id',
  jobAds: '/job-ads', // not actually used for routing, only for menu highlighting
  jobAdDetail: '/job-ads/:id',
  unresolvedShifts: '/unresolved-shifts',
  unpublishedAdDetail: '/unpublished-ads/:id',
  ambassadors: '/ambassadors',
  notifications: '/notifications',
  payrolls: '/payrolls',
  // TEMPORARY PAYROLL DETAIL
  businessTempPayroll: '/payrolls/temp/:businessId',
  businessEventJobsPayroll: '/payrolls/event-jobs/:businessId',
  payrollDetail: '/payrolls/:periodId',
  payrollCost: '/payrolls/:periodId/costs',
  payments: '/payments',
  paymentDetail: '/payments/:timespan',
  errorLogs: '/errors',
  settings: '/settings',
  superadminJobDetail: '/jobs/:id',
  managers: '/managers',
  groups: '/groups',
  groupDetail: '/groups/:id',
  groupDashboard: '/group/dashboard',
  groupPositions: '/group/positions',
  integrations: '/integrations',
  integrationPage: '/integrations/:integrationId',
  integrationGuide: '/integrations/:integrationId/guide',
  integrationEmployees: '/integrations/:integrationId/employees',
  // integrationLogs: '/integrations/:integrationId/logs',
  integrationAuthDone: '/integration-auth-done/:integrationId',
}

export const PATHS_NAMES = {
  [PATHS.regions]: 'Regions',
  [PATHS.companyProfile]: 'Company profile',
  [PATHS.companyPaymentCycles]: "Companies' first payment cycles",
  [PATHS.companies]: 'Companies',
  [PATHS.companiesSearch]: 'Companies search',
  [PATHS.contracts]: 'Contracts',
  [PATHS.staffers]: 'Staffers',
  [PATHS.staffersSearch]: 'Staffers search',
  [PATHS.stafferRequests]: 'Staffer requests',
  [PATHS.chats]: 'Messages',
  [PATHS.jobs]: 'Jobs',
  [PATHS.jobUnpublishedDetail]: 'Unpublished job',
  [PATHS.jobAdDetail]: 'Job ads',
  [PATHS.unpublishedAdDetail]: 'Unpublished job ad',
  [PATHS.reports]: 'Reports',
  [PATHS.notifications]: 'Notifications builder',
  [PATHS.payments]: 'Salary information',
  [PATHS.payrolls]: 'Salary information',
  [PATHS.referrals]: 'Referrals',
  [PATHS.settings]: 'Settings',
  [PATHS.managers]: 'Managers',
  [PATHS.groups]: 'Groups',
  [PATHS.groupDashboard]: 'Group Dashboard',
  [PATHS.groupPositions]: 'Positions',
  [PATHS.integrations]: 'Integrations',
  [PATHS.integrationPage]: 'Integration Page',
  [PATHS.integrationGuide]: 'Integration Guide',
}

type SidebarPathType = {
  name: string
  path: string // points to root path
  altPaths?: string[] // points to dynamic path
}

export const SIDEBAR_PATHS: { [item: string]: SidebarPathType } = {
  'Company Profile': {
    name: 'Company Profile',
    path: PATHS.companyProfile,
  },
  People: {
    name: 'People',
    path: PATHS.staffers,
    altPaths: [PATHS.stafferDetail],
  },
  // Collapsible children People
  Internal: {
    name: 'Internal',
    path: PATHS.companyPool,
  },
  Invites: {
    name: 'Invites',
    path: PATHS.poolInvites,
  },
  External: {
    name: 'External',
    path: PATHS.previousStaffers,
  },
  Favorites: {
    name: 'Favorite staffers',
    path: PATHS.favoriteStaffers,
  },
  Contracts: {
    name: 'Contracts',
    path: PATHS.contracts,
  },
  // End of collapsible children People
  Chats: {
    name: 'Messages',
    path: PATHS.chats,
    altPaths: [PATHS.chat],
  },
  Jobs: {
    name: 'Jobs',
    path: PATHS.jobs,
    altPaths: [PATHS.jobDetail, PATHS.jobAdDetail],
  },
  // Collapsible children Jobs
  Shifts: {
    name: 'Shifts',
    path: PATHS.jobsTemporary,
    altPaths: [PATHS.jobsEvent],
  },
  'Job ads': {
    name: 'Job ads',
    path: PATHS.jobsAds,
  },
  // End of collapsible children jobs
  Payrolls: {
    name: 'Payroll',
    path: PATHS.payrolls,
    altPaths: [PATHS.payrollCost, PATHS.payrollDetail],
  },
  Settings: {
    name: 'Settings',
    path: PATHS.settings,
  },
  Integrations: {
    name: 'Integrations',
    path: PATHS.integrations,
    altPaths: [PATHS.integrationPage, PATHS.integrationGuide],
  },
}

export const GROUP_SIDEBAR_PATHS: { [item: string]: SidebarPathType } = {
  Dashboard: {
    name: 'Group Dashboard',
    path: PATHS.groupDashboard,
  },
  Positions: {
    name: 'Positions',
    path: PATHS.groupPositions,
  },
}
