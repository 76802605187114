/* eslint-disable class-methods-use-this */
import { ButtonBase, IconButton, Tooltip } from '@material-ui/core'
import { BookmarkBorderRounded } from '@material-ui/icons'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IoIosLogOut } from 'react-icons/io'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSearchBox } from 'react-instantsearch'
import type { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import type { BusinessType } from '../../../../src/types/business'
import { PATHS, PATHS_NAMES } from '../../constants/routes'
import Logo from '../../img/logoWithNameNew.svg'
import SearchBar from '../elasticSearch/SearchBar/SearchBar'
import Button from '../mui/redesignedComponents/Button'
import { useShortlists } from '../providers/ShortlistsProvider'
import styles from './header.module.css'

type Props = {
  business?: BusinessType
  isSuperAdmin?: boolean
  isLoggedIn?: boolean
  creatingProfile?: boolean
  explicitTitle?: string
} & RouteComponentProps

// TODO: remove if not used before the release
const MyShortlistButton = () => {
  const { shortlists, showAllShortlists } = useShortlists()

  const isLoading = shortlists === undefined

  return (
    <Button
      onClick={showAllShortlists}
      style={{ color: 'white', fontSize: 16, marginLeft: 16, padding: 8 }}
      variant="text"
      startIcon={<BookmarkBorderRounded style={{ width: 20 }} />}
      disabled={isLoading}
      size="small"
    >
      My Shortlists
    </Button>
  )
}

// Wrap the stateless SeachBar with state from the algolia's react-instantsearch
const MySearchBar = withRouter((props: RouteComponentProps) => {
  const { location, history } = props
  const currentLocation = location.pathname
  const { refine: setSearchQueryText } = useSearchBox()

  const [searchValue, setSearchValue] = useState<string>('')
  const [searchType, setSearchType] = useState<'companies' | 'staffers'>(
    currentLocation.includes('companies') ? 'companies' : 'staffers'
  )
  useEffect(() => {
    // only change searchType if location changed to one of the two search paths
    if (currentLocation.includes(PATHS.companiesSearch)) {
      setSearchType('companies')
    }
    if (currentLocation.includes(PATHS.staffersSearch)) {
      setSearchType('staffers')
    }
  }, [currentLocation])

  const redirect = useCallback(
    (to: 'staffers' | 'companies') => {
      const newLocation = `/${to}-search`
      if (newLocation !== currentLocation) {
        history.push(to === 'staffers' ? PATHS.staffersSearch : PATHS.companiesSearch)
      }
    },
    [currentLocation, history]
  )

  const updateSearchDebounced = useMemo(
    () =>
      AwesomeDebouncePromise((text) => {
        redirect(searchType)
        setSearchQueryText(text)
      }, 600),
    [redirect, setSearchQueryText, searchType]
  )

  const changeSearchValue = (value: string) => {
    setSearchValue(value)
    updateSearchDebounced(value)
  }

  const changeSearchType = (newSearchType: 'staffers' | 'companies') => {
    redirect(newSearchType)
  }

  return (
    <SearchBar
      value={searchValue}
      onChange={changeSearchValue}
      searchType={searchType}
      onSearchTypeChange={changeSearchType}
    />
  )
})

const Header = (props: Props) => {
  const { location, isLoggedIn, isSuperAdmin, explicitTitle, creatingProfile } = props

  const currentLocation = location.pathname

  const adminEmail = firebase.auth().currentUser?.email ?? ''

  const title = useMemo(() => {
    if (explicitTitle) {
      return explicitTitle
    }
    const pathName = PATHS_NAMES[currentLocation]
    if (pathName) {
      return pathName
    }
    // Due to dynamic nature of IDs in those paths, we cannot determine from constant where are we.
    // Because of this we need to use this. If someone has better idea without unneccessarily
    // complicating logic, feel free to refactor.
    if (currentLocation.includes('staffer-requests')) {
      return 'Staffer requests'
    }
    if (currentLocation.includes('staffers')) {
      return 'Staffer detail'
    }
    if (currentLocation.includes('company-requests')) {
      return 'Company requests'
    }
    if (currentLocation.includes('payrolls')) {
      return 'Payrolls'
    }
    if (currentLocation.includes('companies')) {
      return 'Company detail'
    }
    if (currentLocation.includes('jobs')) {
      return 'Job detail'
    }
    if (currentLocation.includes('job-ads')) {
      return 'Job ad detail'
    }
    if (currentLocation.includes('unpublished-ads')) {
      return 'Job ad detail'
    }
    if (currentLocation.includes('regions')) {
      return 'Region detail'
    }
    if (currentLocation.includes('invites')) {
      return 'Invite codes'
    }
    if (currentLocation.includes('payments')) {
      return 'Payment detail'
    }
    if (currentLocation.includes('errors')) {
      return 'Error logs'
    }
    if (currentLocation.includes('ambassadors')) {
      return 'Ambassadors'
    }
    if (currentLocation.includes('groups')) {
      return 'Group info'
    }
    return 'Page not found'
  }, [currentLocation, explicitTitle])

  const handleLogout = async () => {
    await firebase.auth().signOut()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <a href="/" className={styles.logo}>
          <div>
            <img src={Logo} height="32px" alt="Staffers Logo" className={styles.logo} />
          </div>
        </a>
        <div className={styles.title} hidden aria-hidden>
          {title}
        </div>
        <div className={styles.title} hidden={isSuperAdmin}>
          {title}
        </div>
      </div>
      <div className={styles.search}>{isLoggedIn && <MySearchBar />}</div>
      <div className={styles.logoutWrapper}>
        <div>{isLoggedIn && <MyShortlistButton />}</div>
        <div className={styles.logout}>
          {(isLoggedIn || creatingProfile) && (
            <Tooltip title={adminEmail} enterDelay={500}>
              <ButtonBase onClick={handleLogout}>
                <div className={styles.logoutContainer}>
                  <span>Logout</span>
                  <span className={styles.superadminText}>Superadmin</span>
                </div>
                <IconButton className={styles.buttonWrapper}>
                  <IoIosLogOut size={24} />
                </IconButton>
              </ButtonBase>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}

export default withRouter(Header)
