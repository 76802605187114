import { ButtonBase } from '@material-ui/core'
import type { Moment } from 'moment'
import moment from 'moment'
import type { ReactElement } from 'react'
import { toast } from 'react-toastify'
import logEvent from './logEvent'
import Gleap from 'gleap'

type LoggedError = {
  time: Moment
  message: string
}

const latestsErrors: LoggedError[] = []
const trackedMessages: string[] = [] // list of messages for which the hadSameErrorRecently was already triggered

const withClickableLink = (message: string): ReactElement => {
  let content: string | ReactElement = message
  const urlMatch = message.match(/(https?:\/\/[^ ]*)( |$)/i)

  if (urlMatch !== null) {
    const url = urlMatch[1]
    const [before, after] = message.split(url)
    content = (
      <>
        {before}
        <a href={url} target="_blank" style={{ color: 'inherit', textDecoration: 'underline' }} rel="noreferrer">
          {url}
        </a>
        {after}
      </>
    )
  }
  return <div style={{ whiteSpace: 'pre-wrap' }}>{content}</div>
}

export const errorToast = (message: string, metadata?: Record<string, any>) => {
  const hasLink = message.includes('http')
  toast.error(withClickableLink(message), {
    closeOnClick: !hasLink,
    ...metadata,
  })

  const hadSameErrorRecently = latestsErrors.some((err: LoggedError) => {
    const diff = moment().diff(err.time, 'seconds')
    return err.message === message && diff > 2 && diff < 60 * 10
  })

  logEvent('error', {
    url: global.location.href,
    message,
    ...metadata,
  })

  latestsErrors.push({
    message,
    time: moment(),
  })

  if (hadSameErrorRecently && !trackedMessages.includes(message)) {
    trackedMessages.push(message)
    const openChat = () => {
      Gleap.open()
    }
    toast.info(
      <>
        Do you need help?
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <ButtonBase onClick={openChat}>
          <u>Contact our customer support</u>
        </ButtonBase>
      </>
    )
  }
}
