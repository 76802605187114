import 'firebase/compat/firestore'
import {
  limit,
  orderBy,
  query,
  where,
  type QueryFieldFilterConstraint,
  type QueryLimitConstraint,
  type QueryOrderByConstraint,
} from 'firebase/firestore'
import { type JobAdStafferType, type JobAdType } from '../../../../../src/types/jobAds'
import { type JobDraftType, type JobStaffer, type JobType } from '../../../../../src/types/jobs'
import moment from '../../../util/moment'
import {
  getCollectionRef,
  getDataFromCollectionRef,
  getDataFromDocumentRef,
  getDocumentRef,
  getQueryRef,
} from '../wrappers'
import { getManagerByIdPromise } from './managers'

export type OrderDirection = 'asc' | 'desc'

export const getJobById = (jobId: string) => getDocumentRef<JobType>('jobs', jobId)

export const getUnseenRequestJobsByManagerId = async (managerId: string) => {
  const managerData = await getManagerByIdPromise(managerId)
  return getQueryRef<JobType>(
    'jobs',
    where('businessId', '==', managerData?.businessId),
    where('unseenRequests', '>', 0)
  )
}

export const getSimilarJobs = (businessId: string, jobType: string) =>
  getQueryRef<JobType>(
    'jobs',
    where('isAvailable', '==', true),
    where('businessId', '==', businessId),
    where('jobType', '==', jobType),
    where('isPublic', '==', true)
  )

export const getAllJobs = () => getQueryRef<JobType>('jobs', orderBy('timeStart', 'desc'))

export const getAllJobsAfterManymore = () =>
  getQueryRef<JobType>(
    'jobs',
    where('timeStart', '>', moment('01/08/2021', 'DD/MM/YYYY').toDate()),
    orderBy('timeStart', 'desc')
  )

export const getAllNonFreelanceJobsDeleted = () =>
  getQueryRef<JobType>('jobsDeleted', where('isFreelanceJob', '==', false), orderBy('timeStart', 'desc'))

export const getAllFreelanceJobsDeleted = () =>
  getQueryRef<JobType>('jobsDeleted', where('isFreelanceJob', '==', true), orderBy('timeStart', 'desc'))

export const getAllNonFreelanceJobs = () =>
  getQueryRef<JobType>('jobs', where('isFreelanceJob', '==', false), orderBy('timeStart', 'desc'))

export const getAllFreelanceJobs = () =>
  getQueryRef<JobType>('jobs', where('isFreelanceJob', '==', true), orderBy('timeStart', 'desc'))

export const getUpcomingFreelanceJobs = () =>
  getQueryRef<JobType>(
    'jobs',
    where('isFreelanceJob', '==', true),
    where('nextShiftEnd', '>', moment().toDate()), // use nextShiftEnd to include shifts which already started
    orderBy('nextShiftEnd', 'asc')
  )

export const getUpcomingFreelanceJobsByBusinessId = (businessId: string) =>
  getQueryRef<JobType>(
    'jobs',
    where('isFreelanceJob', '==', true),
    where('businessId', '==', businessId),
    where('nextShiftEnd', '>', moment().toDate()), // use nextShiftEnd to include shifts which already started
    orderBy('nextShiftEnd', 'asc')
  )
export const getAvailableJobs = () =>
  getQueryRef<JobType>('jobs', where('isAvailable', '==', true), orderBy('timeStart', 'desc'))

export const getUnavailableJobs = (amountToQuery: number) =>
  getQueryRef<JobType>('jobs', where('isAvailable', '==', false), orderBy('lastShift', 'desc'), limit(amountToQuery))

export const getAllJobsByPosition = (jobType: string) =>
  getQueryRef<JobType>('jobs', where('jobType', '==', jobType), where('salaryHourly', '<', 1000), limit(30))

export const getJobDetailById = (jobId: string) => getDocumentRef<JobType>('jobs', jobId)

export const getDeletedJobDetailById = (jobId: string) => getDocumentRef<JobType>('jobsDeleted', jobId)

export const getUnpublishedDetailById = (jobId: string) => getDocumentRef<JobType>('unpublished', jobId)

// note: doc keys are uri encoded use encodeURIComponent when accesing,
// it wont work for jobTypes containing slashes otherwise
// type: ????
export const getJobDraftsByBusinessId = (businessId: string) =>
  getCollectionRef<JobDraftType>('drafts', businessId, 'jobDrafts')

// note: doc keys are uri encoded use encodeURIComponent when accesing,
// it wont work for jobTypes containing slashes otherwise
// TODO: dependant on managers.ts
export const getJobDraftsByManagerId = async (managerId: string) => {
  const managerData = await getManagerByIdPromise(managerId)
  return managerData && managerData.businessId ? getJobDraftsByBusinessId(managerData.businessId) : []
}

export const getJobDetailByIdPromise = (jobId: string) => getDataFromDocumentRef<JobType>(getDocumentRef('jobs', jobId))

export const getUpcJobsByStafferAndBusinessIdStartingFrom = (
  stafferId: string,
  businessId: string,
  startingPayDate: Date,
  limitNum?: number
) => {
  const constraints: (QueryFieldFilterConstraint | QueryOrderByConstraint | QueryLimitConstraint)[] = [
    where('businessId', '==', businessId),
    where('staffersConfirmed', 'array-contains', stafferId),
    where('status', '==', 'upcoming'),
    where('timeEnd', '>', startingPayDate),
    orderBy('timeEnd', 'asc'),
  ]

  if (limitNum !== undefined) {
    constraints.push(limit(limitNum))
  }

  return getQueryRef<JobType>('jobs', ...constraints)
}

export const getCompletedJobsByStafferId = (stafferId: string) =>
  getQueryRef<JobType>('jobs', where('nextShift', '==', null), where('staffersConfirmed', 'array-contains', stafferId))

export const fetchOneRecentJobForBusiness = (stafferId: string, businessId: string) =>
  getQueryRef<JobType>(
    'jobs',
    where('businessId', '==', businessId),
    where('nextShift', '==', null),
    where('staffersConfirmed', 'array-contains', stafferId),
    where('timeEnd', '>', moment().subtract(3, 'months').toDate()),
    limit(1)
  )

export const getUpcomingJobsByStafferId = (stafferId: string) =>
  getQueryRef<JobType>(
    'jobs',
    where('nextShiftEnd', '>', new Date()),
    where('staffersConfirmed', 'array-contains', stafferId)
  )

export const fetchOneUpcomingJobForBusiness = (stafferId: string, businessId: string) =>
  getQueryRef<JobType>(
    'jobs',
    where('businessId', '==', businessId),
    where('nextShiftEnd', '>', new Date()),
    where('staffersConfirmed', 'array-contains', stafferId),
    limit(1)
  )

export const fetchOneAppliedJobForBusiness = (stafferId: string, businessId: string) =>
  getQueryRef<JobType>(
    'jobs',
    where('businessId', '==', businessId),
    where('nextShiftEnd', '>', new Date()),
    where('staffersPending', 'array-contains', stafferId),
    limit(1)
  )

export const getCompletedJobsByStafferAndBusinessId = (stafferId: string, businessId: string, limitNum?: number) => {
  const constraints: (QueryFieldFilterConstraint | QueryOrderByConstraint | QueryLimitConstraint)[] = [
    where('businessId', '==', businessId),
    where('staffersConfirmed', 'array-contains', stafferId),
    where('nextShift', '==', null),
  ]
  if (limitNum !== undefined) {
    constraints.push(limit(limitNum))
  }
  return getQueryRef<JobType>('jobs', ...constraints)
}

export const getJobsByStatus = (jobStatus: string) => getQueryRef<JobType>('jobs', where('status', '==', jobStatus))

export const getAllJobsByBusinessId = (businessId: string) =>
  getQueryRef<JobType>('jobs', where('businessId', '==', businessId))

export const getLastYearJobsByBusinessId = (businessId: string) =>
  getQueryRef<JobType>(
    'jobs',
    where('businessId', '==', businessId),
    where('createdAt', '>', moment().subtract(12, 'months').toDate())
  )

export const getAllNonFreelanceJobsByBusinessId = (businessId: string) =>
  getQueryRef<JobType>('jobs', where('isFreelanceJob', '==', false), where('businessId', '==', businessId))

export const getAllFreelanceJobsByBusinessId = (businessId: string) =>
  getQueryRef<JobType>('jobs', where('businessId', '==', businessId), where('isFreelanceJob', '==', true))

export const getUpcomingJobsByBusIdPromise = (businessId: string) =>
  getDataFromCollectionRef(
    getQueryRef<JobType>('jobs', where('businessId', '==', businessId), where('nextShift', '>', moment().toDate())),
    (item) => ({ ...item.data(), id: item.id })
  )

export const getRecentJobsByBusIdPromise = (businessId: string) =>
  getDataFromCollectionRef(
    getQueryRef<JobType>(
      'jobs',
      where('businessId', '==', businessId),
      where('lastShift', '>', moment().subtract(2, 'weeks').toDate())
    ),
    (item) => ({ ...item.data(), id: item.id })
  )

export const getJobsByStafferId = (stafferId: string, orderByField?: string, orderDirection?: OrderDirection) => {
  const constraints: (QueryFieldFilterConstraint | QueryOrderByConstraint)[] = [
    where('staffersConfirmed', 'array-contains', stafferId),
  ]
  if (orderByField && orderDirection) {
    constraints.push(orderBy(orderByField, orderDirection))
  }
  return getQueryRef<JobType>('jobs', ...constraints)
}

export const getJobsByStafferBusinessId = (stafferId: string, businessId: string) =>
  getQueryRef<JobType>(
    'jobs',
    where('businessId', '==', businessId),
    where('staffersConfirmed', 'array-contains', stafferId)
  )

export const getJobsByStatusAndStafferId = (
  jobStatus: string,
  stafferId: string,
  orderByField?: string,
  orderDirection?: OrderDirection
) => {
  const constraints: (QueryFieldFilterConstraint | QueryOrderByConstraint)[] = [
    where('status', '==', jobStatus),
    where('staffersConfirmed', 'array-contains', stafferId),
  ]
  if (orderByField && orderDirection) {
    constraints.push(orderBy(orderByField, orderDirection))
  }
  return getQueryRef<JobType>('jobs', ...constraints)
}

export const getJobDetailByBusinessId = (
  businessId: string,
  jobStatus: string,
  orderByField?: string,
  orderDirection?: OrderDirection
) => {
  const constraints: (QueryFieldFilterConstraint | QueryOrderByConstraint)[] = [
    where('businessId', '==', businessId),
    where('status', '==', jobStatus),
  ]
  if (orderByField && orderDirection) {
    constraints.push(orderBy(orderByField, orderDirection))
  }
  return getQueryRef<JobType>('jobs', ...constraints)
}

export const getStaffersByJobId = (jobId: string) => getCollectionRef<JobStaffer>('jobs', jobId, 'staffers')

export const getJobStafferDetailById = (jobId: string, stafferId: string) =>
  getDocumentRef<JobStaffer>('jobs', jobId, 'staffers', stafferId)

// Company admin data crunching
export const getAllJobsByBusinessIdStartingFrom = (businessId: string, startingPayDate: Date) =>
  getQueryRef<JobType>('jobs', where('businessId', '==', businessId), where('timeEnd', '>', startingPayDate))

// Payroll
export const getAllJobsByBusinessIdWithinRelativeTimeFrame = (
  businessId: string,
  startingPayDate: Date,
  endingPayDate: Date
) =>
  getQueryRef<JobType>(
    'jobs',
    where('businessId', '==', businessId),
    // Mostly for potential rounding errors/corner cases (4 weeks should be good enough)
    where('timeStart', '>', moment(startingPayDate).subtract(5, 'weeks').toDate()),
    // Mostly for potential rounding errors/corner cases (1 day should be enough)
    where('timeStart', '<', moment(endingPayDate).add('2', 'days').toDate())
  )

export const getAllJobsStartingFrom = (startingPayDate: Date) =>
  getQueryRef<JobType>('jobs', where('timeEnd', '>', startingPayDate), where('isTestJob', '==', false))

// JOB ADS
export const getJobAdById = (id: string) => getDocumentRef<JobAdType>('jobAds', id)

export const getAllJobAds = () => getQueryRef<JobAdType>('jobAds', orderBy('createdAt', 'desc'))

export const getJobAdsSinceLastMonth = () =>
  getQueryRef<JobAdType>(
    'jobAds',
    where('createdAt', '>=', moment().subtract(4, 'months').toDate()),
    orderBy('createdAt', 'desc')
  )

export const getAvailableJobAds = () =>
  getQueryRef<JobAdType>('jobAds', where('isAvailable', '==', true), orderBy('timeStart', 'asc'))

export const getAllJobAdStaffers = (jobAdId: string) =>
  getCollectionRef<JobAdStafferType>('jobAds', jobAdId, 'staffers')

export const getJobAdStaffersByStatus = (status: 'new' | 'goodfit' | 'notfit' | 'maybefit') => (jobAdId: string) =>
  query(
    getCollectionRef<JobAdStafferType>('jobAds', jobAdId, 'staffers'),
    where(status === 'new' ? 'status' : 'fitness', '==', String(status).replace('fit', ''))
  )

export const getJobAdStafferById = (jobAdId: string, stafferId: string) =>
  getDocumentRef('jobAds', jobAdId, 'staffers', stafferId)

export const getAllJobAdsByBusinessId = (businessId: string) =>
  getQueryRef<JobAdType>('jobAds', where('businessId', '==', businessId))

export const getAllEventJobs = () => getQueryRef<JobType>('jobs', where('isFreelanceJob', '==', true))

export const getAppliedJobAdsByStafferBusinessId = (stafferId: string, businessId: string) =>
  getQueryRef<JobAdType>(
    'jobAds',
    where('businessId', '==', businessId),
    where('staffersNew', 'array-contains', stafferId)
  )

export const getContactedJobAdsByStafferBusinessId = (stafferId: string, businessId: string) =>
  getQueryRef<JobAdType>(
    'jobAds',
    where('businessId', '==', businessId),
    where('staffersContacted', 'array-contains', stafferId)
  )

export const getHiredJobAdsByStafferBusinessId = (stafferId: string, businessId: string) =>
  getQueryRef<JobAdType>(
    'jobAds',
    where('businessId', '==', businessId),
    where('staffersHired', 'array-contains', stafferId)
  )

export const getAllUnpublishedJobsByBusinessId = (businessId: string) =>
  getQueryRef<JobAdType>(
    'unpublished',
    where('businessId', '==', businessId),
    where('timeStart', '>', new Date()),
    orderBy('timeStart', 'desc')
  )

export const getUnpublishedNonEventJobs = () =>
  getQueryRef<JobAdType>('unpublished', where('isFreelanceJob', '==', false))

export const getUnpublishedNonEventJobsByBusinessId = (businessId: string) =>
  getQueryRef<JobAdType>('unpublished', where('businessId', '==', businessId), where('isFreelanceJob', '==', false))

export const getUnpublishedEventJobs = () => getQueryRef<JobAdType>('unpublished', where('isFreelanceJob', '==', true))

export const getUnpublishedEventJobsByBusinessId = (businessId: string) =>
  getQueryRef<JobAdType>('unpublished', where('businessId', '==', businessId), where('isFreelanceJob', '==', true))

export const getUnpublishedJobAds = () =>
  getQueryRef<JobAdType>('unpublished', where('isJobAd', '==', true), orderBy('timeStart', 'desc'))

export const getUnpublishedJobAdsByBusinessId = (businessId: string) =>
  getQueryRef<JobAdType>(
    'unpublished',
    where('businessId', '==', businessId),
    where('isJobAd', '==', true),
    orderBy('timeStart', 'desc')
  )

export const getPoolJobsByBusinessId = (businessId: string) =>
  getQueryRef<JobType>('jobs', where('businessId', '==', businessId), where('nextShift', '>', new Date()))

/**
 * @warning This does not exclude legacy jobs (where all were previously public)
 */
export const getPoolPublicJobs = (businessId: string) =>
  getQueryRef<JobType>(
    'jobs',
    where('businessId', '==', businessId),
    where('isPublic', '==', true),
    orderBy('timeStart', 'desc')
  )

export const getAllInternalJobsByBusinessId = (businessId: string) =>
  getQueryRef<JobType>(
    'jobs',
    where('businessId', '==', businessId),
    where('nextShift', '>', new Date()),
    where('isPublic', '==', false)
  )

export const getTotalHoursWorked = async (jobId: string): Promise<number> => {
  const job = await getDataFromDocumentRef(getJobById(jobId))
  if (!job) {
    throw Error(`Job with id: ${jobId} doesn't exist`)
  }
  if (!job.shifts || job.shifts.length === 0) {
    return 0
  }
  const jobStaffers = await getDataFromCollectionRef(getStaffersByJobId(jobId))
  // const jobStaffers = await jobRef.collection('staffers').get()
  if (!jobStaffers || jobStaffers.length === 0) {
    return 0
  }

  return jobStaffers.reduce((sum: number, doc) => {
    // looping per staffer per job
    const { shifts } = doc
    return (
      sum +
      shifts.reduce((hoursWorked: number, { clockIn, clockOut }) => {
        // looping per shift per staffer per job
        if (!clockIn || !clockOut) {
          return hoursWorked
        }
        const clockInMoment = moment(clockIn.timeEdited || clockIn.time)
        const clockOutMoment = moment(clockOut.timeEdited || clockOut.time)
        if (
          !moment.isDate(clockIn.timeEdited || clockIn.time) ||
          !moment.isDate(clockOut.timeEdited || clockOut.time)
        ) {
          return hoursWorked
        }
        const diff = moment.duration(clockOutMoment.diff(clockInMoment)).asHours()
        return hoursWorked + diff
      }, 0)
    )
  }, 0)
}

// Used for checking if business has an external jobs, stops after first encounter
export const getSingleExternalJobByBusiness = (businessId: string) =>
  getQueryRef<JobType>('jobs', where('businessId', '==', businessId), where('isPublic', '==', true), limit(1))

// Used for checking if business has a freelance job
export const getSingleEventJobByBusiness = (businessId: string) =>
  getQueryRef<JobType>('jobs', where('businessId', '==', businessId), where('isFreelanceJob', '==', true), limit(1))

// Used for checking if business has a temporary job
export const getSingleTemporaryJobByBusiness = (businessId: string) =>
  getQueryRef<JobType>('jobs', where('businessId', '==', businessId), where('isFreelanceJob', '==', false), limit(1))

// Get all jobs which already started
export const getPendingOrCompletedJobsByBusiness = (businessId: string) =>
  getQueryRef<JobType>('jobs', where('businessId', '==', businessId), where('timeStart', '<', moment().toDate()))
