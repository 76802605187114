import styles from './CreateFulltimeJob.module.css'

export const COVERAGE_MIN = 10
export const COVERAGE_MAX = 100
export const POSITION_MIN = 1
export const POSITION_MAX = 100
export const MIN_TEXT_LENGTH = 50
export const MIN_SEASONAL_TEXT_LENGTH = 5

// everytime a new element is added or removed in ALL_ERRORS, this should be updated as well!
export type PossibleErrorNames =
  | 'jobType'
  | 'contractCoverage'
  | 'customJobType'
  | 'customJobAdUrl'
  | 'positions'
  | 'timeStart'
  | 'description'
  | 'aboutUs'
  | 'oneWayQuestions'
  | 'isSeasonalJob'
  | 'seasonalJobInfo'
  | 'unsaved-images'
  | 'email'
  | 'wageRange'
  | 'phone'

export type ErrorObject = {
  name: PossibleErrorNames
  errorMessage: string
}

export const ALL_ERRORS: ErrorObject[] = [
  {
    name: 'unsaved-images',
    errorMessage: 'You have to save or cancel all of the opened images first.',
  },
  {
    name: 'jobType',
    errorMessage: 'You must select a job type',
  },
  {
    name: 'contractCoverage',
    errorMessage: `You must select a valid contract coverage for your job (${COVERAGE_MIN}-${COVERAGE_MAX}%)`,
  },
  {
    name: 'customJobType',
    errorMessage: 'You must enter a position name if you selected "Other".',
  },
  {
    name: 'positions',
    errorMessage: `You can only search for ${POSITION_MIN} to ${POSITION_MAX} employees per job ad.`,
  },
  {
    name: 'timeStart',
    errorMessage: 'You must select a start date for your job',
  },
  {
    name: 'description',
    errorMessage:
      'Please provide a job description, good description increases the chances of a staffer applying. You need to write at least 100 characters',
  },
  {
    name: 'aboutUs',
    errorMessage: 'Please provide some information about your company. You need to write at least 100 characters',
  },
  {
    name: 'oneWayQuestions',
    errorMessage: 'If you want to have a one way interview, you must provide the staffer with some questions',
  },
  {
    name: 'seasonalJobInfo',
    errorMessage: `Please, specify the necessary info about seasonal job. You need to write at least ${MIN_SEASONAL_TEXT_LENGTH} characters.`,
  },
  {
    name: 'email',
    errorMessage: 'Email address is not valid, please fix it or clear the field entirely.',
  },
  {
    name: 'phone',
    errorMessage: 'Phone number is not valid, please fix it or clear the field entirely.',
  },
  {
    name: 'wageRange',
    errorMessage: 'Please insert a valid wage range',
  },
  {
    name: 'customJobAdUrl',
    errorMessage: 'Please insert a valid url',
  },
]

/**
 * @returns {ErrorObject[]} error objects array based on the data provided in INITIAL_NAMES array
 */

export const INITIAL_ERRORS: ErrorObject[] = ALL_ERRORS.filter((errorObj: ErrorObject) => {
  const INITIAL_NAMES: string[] = []
  return INITIAL_NAMES.includes(errorObj.name)
})

type Props = {
  formErrors: ErrorObject[]
  errorName: PossibleErrorNames
}

export const ErrorHandler = (props: Props) => {
  const { formErrors, errorName } = props
  const error: ErrorObject | typeof undefined = formErrors.find((err: ErrorObject) => err.name === errorName)

  return error ? <div className={styles.error}>{error.errorMessage}</div> : null
}
