// Functions specific to the superadmin
import type { Firestore } from '../../../../../src/types/firebase'
import type { InfoMessagesType } from '../../../../../src/types/settings'
import type { ManagerReferralsType, StafferReferralsTypeLegacy } from '../../../../../src/types/stafferReferrals'
import { standardized } from '../../../helpers/phoneNumbers'
import { getManagerReferrals } from '../getters/managers.legacy'
import { getStafferReferrals } from '../getters/staffer.legacy'

const MAX_QUESTIONS = 6 // maximum amount of questions for sign-up video

export const blockStaffer = async (db: Firestore, stafferId: string) =>
  db.collection('staffers').doc(stafferId).set(
    {
      blocked: true,
    },
    { merge: true }
  )

export const promoteStaffer = async (db: Firestore, stafferId: string) =>
  db.collection('staffers').doc(stafferId).update({ isSkilled2: true })

export const demoteStaffer = async (db: Firestore, stafferId: string) =>
  db.collection('staffers').doc(stafferId).update({ isSkilled2: false })

export const validateInviteCode = async (
  db: Firestore,
  {
    businessId,
    email,
    isBusiness,
    inviteCode,
    invitedBy,
    isSkilled,
    phoneNumber,
  }: {
    businessId?: string
    email: string
    isBusiness?: boolean
    inviteCode: string
    invitedBy: string
    isSkilled: boolean
    phoneNumber: string
  }
) => {
  let inviteCodeObject
  if (isBusiness) {
    inviteCodeObject = businessId
      ? {
          businessId,
          code: inviteCode,
          email,
          invitedBy,
          isBusiness,
          isSkilled,
          phone: phoneNumber,
          used: false,
        }
      : {
          code: inviteCode,
          email,
          invitedBy,
          isBusiness,
          isSkilled,
          phone: phoneNumber,
          used: false,
        }
  } else {
    inviteCodeObject = {
      code: inviteCode,
      email,
      invitedBy,
      isSkilled,
      phone: phoneNumber,
      used: false,
    }
  }

  return db.collection('inviteCodes').doc(inviteCode).set(inviteCodeObject, { merge: true })
}

export const stafferRefMarkAsPaid = async (
  db: Firestore,
  stafferId: string,
  due: number // just to be on safe side
) => {
  const currentReferralsSnapshot = await getStafferReferrals(db, stafferId).get()
  if (!currentReferralsSnapshot.exists) {
    throw Error(`StafferReferrals with id ${stafferId} does not exists`)
  }
  const currentReferrals = currentReferralsSnapshot.data() as StafferReferralsTypeLegacy
  const newPaydate = {
    amount: due,
    date: new Date(),
  }
  // In case we accidentally shown wrong due for some reason
  if (due > currentReferrals.due) {
    throw Error('Due amount mismatch. Due tried to be paid is higher than actual due to the staffer.')
  }
  const newDue = currentReferrals.due - due
  return db
    .collection('staffersReferrals')
    .doc(stafferId)
    .update({
      due: newDue,
      payouts: [...currentReferrals.payouts, newPaydate],
      lastPaydate: new Date(),
    })
}

export const updateSignupQuestions = async (db: Firestore, questions: string[]) => {
  // would cause text overflow on mobile screen
  if (questions.length > MAX_QUESTIONS) {
    throw Error(`Exceeded the maximum amount of questions (${MAX_QUESTIONS})`)
  }

  questions.forEach((question, index) => {
    if (!question) {
      throw Error(`Question ${index + 1} cannot be empty!`)
    }
  })

  return db.collection('settings').doc('signup').update({
    signupQuestions: questions,
  })
}

export const updateInfoMessages = async (db: Firestore, data: InfoMessagesType) =>
  db
    .collection('settings')
    .doc('infoMessages')
    .set(
      {
        ...data,
      },
      { merge: true }
    )

export const updateBusinessOnboardedSms = async (db: Firestore, businessOnboardedSms: string[]) => {
  const numbers = businessOnboardedSms
    .map((num) => num.trim())
    .filter(Boolean)
    .map(standardized)
  return db.collection('settings').doc('signup').set(
    {
      businessOnboardedSms: numbers,
    },
    { merge: true }
  )
}

export const updateNewBusinessNotifSms = async (db: Firestore, newBusinessSms: string[]) => {
  const numbers = newBusinessSms
    .map((num) => num.trim())
    .filter(Boolean)
    .map(standardized)
  return db.collection('settings').doc('signup').set(
    {
      newBusinessSms: numbers,
    },
    { merge: true }
  )
}

export const updateShiftCancellationRecipients = async (db: Firestore, smsCancelShiftRecipients: string[]) => {
  const numbers = smsCancelShiftRecipients
    .map((num) => num.trim())
    .filter(Boolean)
    .map(standardized)
  return db.collection('settings').doc('shifts').set(
    {
      smsCancelShiftRecipients: numbers,
    },
    { merge: true }
  )
}

export const updateInactiveStaffersSmsRecipients = async (db: Firestore, inactiveStaffersSmsRecipients: string[]) => {
  const numbers = inactiveStaffersSmsRecipients
    .map((num) => num.trim())
    .filter(Boolean)
    .map(standardized)
  return db.collection('settings').doc('inactiveStaffers').set(
    {
      smsRecipients: numbers,
    },
    { merge: true }
  )
}

export const managerRefMarkAsPaid = async (
  db: Firestore,
  userId: string,
  due: number // just to be on safe side
) => {
  const currentReferralsSnap = await getManagerReferrals(db, userId).get()
  if (!currentReferralsSnap.exists) {
    throw Error(`ManagerReferrals with id ${userId} does not exist`)
  }
  const newPaydate = {
    amount: due,
    date: new Date(),
  }
  const currentReferrals = currentReferralsSnap.data() as ManagerReferralsType
  // In case we accidentally shown wrong due for some reason
  if (due > currentReferrals.due) {
    throw Error('Due amount mismatch. Due tried to be paid is higher than actual due to the staffer.')
  }
  const newDue = currentReferrals.due - due
  return db
    .collection('managersReferrals')
    .doc(userId)
    .update({
      due: newDue,
      payouts: [...currentReferrals.payouts, newPaydate],
      lastPaydate: new Date(),
    })
}
