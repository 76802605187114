import { Box, Button, FormControl, Grid, MenuItem, Select } from '@material-ui/core'
import type { ChangeEvent } from 'react'
import React, { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { SMS_CHARACTER_LIMIT } from '../../constants/util'
import { errorToast } from '../../helpers/toast'
import { firestoreHttpsCallable } from '../../staffers/api/firestore/https/util'
import Checkbox from '../checkbox/CustomCheckbox'
import modalStyles from '../mui/Modal.module.css'
import ModalHeader from '../mui/ModalHeader'
import TextField from '../textInputField/TextInputField'
import styles from './modals.module.css'

const PHONE_PREFIX = '+47'

type State = {
  country: string
  isProcessing: boolean
  phoneError: string
  emailError: string
  phone: string
  email: string
  customMessage: string
  useCustomMessage: boolean
}

type Props = {
  currentCountry: string
  onClose: () => any
}

class InviteBusiness extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      country: props.currentCountry || 'Norway',
      customMessage: '',
      email: '',
      phone: '',
      emailError: '',
      phoneError: '',
      isProcessing: false,
      useCustomMessage: false,
    }
  }

  inputs = {
    phone: null,
    email: null,
  }

  resetState = () => {
    const { currentCountry } = this.props
    this.setState({
      country: currentCountry || 'Norway',
      customMessage: '',
      email: '',
      phone: '',
      emailError: '',
      phoneError: '',
      isProcessing: false,
      useCustomMessage: false,
    })
  }

  onCountryChange = (event: ChangeEvent<{ name?: string; value: any }>) => {
    const country = event.target.value
    this.setState({ country })
  }

  render() {
    const { onClose } = this.props

    const { country, customMessage, useCustomMessage, email, phone, emailError, phoneError, isProcessing } = this.state

    if (isProcessing) {
      return (
        <Box p={3} className={modalStyles.modalContent} textAlign="center">
          <BeatLoader />
        </Box>
      )
    }

    const canSendInvite =
      (email || phone) && !(email && this.getEmailError(email, true)) && !(phone && this.getPhoneError(phone, true))

    return (
      <React.Fragment>
        <ModalHeader close={onClose}>Invite business</ModalHeader>
        <Box p={3} className={modalStyles.modalContent}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h2>Email</h2>
              <TextField
                multiline
                type="text"
                onChange={this.onEmailChange}
                inputProps={{ maxLength: 80 }}
                placeholder="Enter manager's email"
                className={styles.descriptionTextArea}
                helperText={emailError}
                error={!!emailError}
                value={email}
              />
            </Grid>
            <Grid item xs={6}>
              <h2>Phone number</h2>
              <TextField
                type="text"
                prefix={PHONE_PREFIX}
                placeholder=" 00 00 00 00"
                onChange={this.onPhoneChange}
                inputProps={{ maxLength: 20 }}
                className={styles.descriptionTextArea}
                helperText={phoneError}
                error={!!phoneError}
                value={phone}
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Country</h2>
              <FormControl>
                <Select id="country" value={country} onChange={this.onCountryChange}>
                  <MenuItem value="Norway">
                    <span role="img" aria-label="Norway">
                      🇳🇴
                    </span>
                    Norway
                  </MenuItem>
                  <MenuItem value="Sweden">
                    <span role="img" aria-label="Sweden">
                      🇸🇪
                    </span>
                    Sweden
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                title="Use custom message?"
                checked={useCustomMessage}
                onToggleCheckbox={this.onUseCustomMessageToggle}
              />
              {useCustomMessage && (
                <TextField
                  type="text"
                  placeholder="Invite link will be appended to the end of the message."
                  onChange={this.onCustomMessageChange}
                  inputProps={
                    { maxLength: SMS_CHARACTER_LIMIT - 100 } // minus space for the link
                  }
                  className={styles.descriptionTextArea}
                  value={customMessage}
                />
              )}
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={3}>
              <Button fullWidth variant="contained" disabled={isProcessing} onClick={onClose}>
                Close
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={isProcessing || !canSendInvite}
                onClick={this.sendInvite}
              >
                Send Invite
              </Button>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    )
  }

  onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value
    this.setState({
      emailError: this.getEmailError(email, false),
      email: email.toLowerCase(),
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getEmailError = (email: string, exact: boolean) => {
    if (!email && !exact) {
      return ''
    }

    const regexp = exact ? /^\w+([.]\w+)*@([\w-]+[.])+\w+$/ : /^\w+([.]\w+)*((@([\w-]+[.])*\w*)|[.])?$/
    if (!email.trim().match(regexp)) {
      return 'Incorrect format'
    }

    return ''
  }

  onPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value
    this.setState({
      phoneError: this.getPhoneError(phone, false),
      phone,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getPhoneError = (phone: string, exact: boolean): string => {
    if (!phone && !exact) {
      return ''
    }

    const regexp = exact ? /^[1-9][0-9]{7}$/ : /^[1-9][0-9]{0,7}$/
    if (!phone.replace(/\s/g, '').match(regexp)) {
      return 'Incorrect format'
    }

    return ''
  }

  onUseCustomMessageToggle = () => {
    const { useCustomMessage } = this.state

    this.setState({ useCustomMessage: !useCustomMessage })
  }

  onCustomMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const customMessage = event.target.value
    this.setState({
      customMessage,
    })
  }

  sendInvite = async () => {
    const { onClose } = this.props
    const { country, email, phone, isProcessing, customMessage } = this.state

    if (isProcessing || !(email || phone)) {
      return
    }

    const emailError = email && this.getEmailError(email, true)
    const phoneError = phone && this.getPhoneError(phone, true)

    if (emailError || phoneError) {
      this.setState({ emailError, phoneError })
      return
    }

    try {
      this.setState({ isProcessing: true })
      await firestoreHttpsCallable('inviteManager', {
        country,
        ...(email && !emailError ? { email: email.trim() } : {}),
        ...(phone && !phoneError ? { phone: `${PHONE_PREFIX}${phone.trim()}` } : {}),
        businessId: null,
        customMessage,
      })
      toast.success('Invitation sent successfully.')
      onClose()
    } catch (error) {
      errorToast((error as Error)?.message ?? error)
      console.warn(error)
    } finally {
      this.setState({ isProcessing: false })
    }
  }
}

export default InviteBusiness
