import { getFirestore, setDoc, writeBatch } from 'firebase/firestore'
import type { BusinessType } from '../../../../../src/types/business'
import { getDocumentRef } from '../wrappers'
import { getServerTimestamp } from './util.legacy'

export const updateManagerProfile = async (userId: string, profileUpdate: Record<string, any>) =>
  setDoc(
    getDocumentRef('managers', userId),
    {
      ...profileUpdate,
    },
    { merge: true }
  )

export const updateManagerPhone = async (userId: string, phone: string) => updateManagerProfile(userId, { phone })

export type GeneratedBusinessProperties =
  | 'businessId'
  | 'createdAt'
  | 'createdBy'
  | 'coordinates'
  | 'ratingsTotal'
  | 'ratingsCount'
  | 'referralId'
  | 'referringId'
  | 'region'

export const createBusinessForManager = async (
  userId: string,
  // we need to omit parameters which will be assigned automatically by the cloud function
  businessInfo: Omit<BusinessType, GeneratedBusinessProperties>
) => {
  // We will update the /managers node and create a new /business node at the same time
  const managerRef = getDocumentRef('managers', userId)
  const businessRef = getDocumentRef('business')

  // Binding the business to the manager (with owner status)
  const managerData = {
    businessId: businessRef.id,
    isOwner: true,
  }
  const {
    address: { city, country, extra, postalCode, street },
    invoice,
    businessName,
    businessType,
    description,
    email,
    orgNumber,
    phone,
    regionId,
    registeredName,
    specificBusinessType,
    website,
  } = businessInfo
  // Initializing business information with user input and default values
  const businessData = {
    accessRequested: false,
    address: {
      city: city?.trim() || '',
      country: country?.trim() || '',
      extra: extra?.trim() || '',
      postalCode: postalCode?.trim() || '',
      street: street?.trim() || '',
    },
    invoice: invoice || null,
    businessId: businessRef.id,
    businessName: businessName?.trim() || '',
    businessType: businessType?.trim() || '',
    coordinates: null,
    createdAt: getServerTimestamp(),
    createdBy: userId,
    description: description?.trim() || '',
    email: email?.trim() || '',
    isArchived: false,
    orgNumber: orgNumber?.trim() || '',
    payoutDay: 15,
    payoutPeriodDay: 1,
    phone: phone?.trim() || null,
    ratingsCount: 0,
    ratingsTotal: 0,
    regionId: regionId?.trim() || null,
    registeredName: registeredName?.trim() || '',
    specificBusinessType: specificBusinessType?.trim() || '',
    updatedAt: null,
    updatedBy: null,
    website: website?.trim() || '',
  }

  // Uploading to database using a batched write (atomic operation)
  const batch = writeBatch(getFirestore())
  batch.set(managerRef, managerData, { merge: true })
  batch.set(businessRef, businessData)
  return batch.commit()
}
